import {IconSizeScale} from "./IconSizeScale";
import {OpacitySetting} from "./OpacitySetting";
import {ProjectionOnMapSetting} from "./ProjectionOnMapSetting";


export const layerSettings = {
    IconSizeScale,
    OpacitySetting,
    ProjectionOnMapSetting,
}

