import moment from "moment";
import { DatePicker, Form } from "antd";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import {Controller, ErrorMessage, useFormContext} from "react-hook-form";
import {Styled} from "./styled/BaseInput.styled";
import 'moment/locale/ru';
import locale from 'antd/es/date-picker/locale/ru_RU';
import {useBaseInputSettings} from "./utils/hooks/useBaseInputSettings";


let StyledDatePicker = styled(DatePicker)`
  &.ant-calendar-picker{
    width: 100%;
  }

  .ant-input {
    height: 60px;
    background: ${props => props.theme.colors.backgroundNeutral};
    border-radius: 4px;
    padding-top: 25px;
    border: none;
    
    &::placeholder {
      color: transparent;
    }


  }
`;

const {BasePlaceholder, BaseErrorMessage} = Styled

const DateTimeInput = props => {
  const {errors, setValue } = useFormContext();
  const [placeholderState, setPlaceholderState] = useState(false);
  const [initialValue, setInitialValue] = useState(null);
  const { description, object, field, formProps } = props;
  const { showTime, dateTime, setDateTime, ref } = formProps || {};
  // const {} = useBaseInputSettings(description)
  function checkInputAndMakeAction(inputValue, actionFn) {
    if (!inputValue) {
      actionFn();
    }
  }
  if (object && object[field] && initialValue !== object[field]) {
    setInitialValue(object[field]);
  }
  useEffect(() => {
    if (initialValue) {
      setValue(field, moment(initialValue, 'YYYY-MM-DD'));
      setPlaceholderState(true)
      if(field === "datetime_start" ) {
        setDateTime && setDateTime({...dateTime, ["datetime_start"]: object.datetime_start})
      }
    }else if(!initialValue){
     setDateTime && setDateTime({ ...dateTime, ["datetime_start" || ""]: moment().format()})

    }
  }, [initialValue]);

  function onChange(date, dateString) {
    setDateTime && setDateTime({ ...dateTime, [field]: dateString });
  }

  function disabledDate(date) {
    if (field === 'datetime_start') {
      return Date.parse(date) > Date.parse(dateTime?.datetime_end);
    } else if (field === 'datetime_end') {
      return Date.parse(date) < Date.parse(dateTime?.datetime_start);
    }
  }

  function defaultValueDataTime (fieldName){
    if(fieldName === "datetime_start"){
      return moment(object?.datetime_start)
    }
    else if(fieldName === "datetime_end"){
      if(object?.datetime_end !== null && object?.datetime_end !==undefined){
        return moment(object.datetime_end)
      }
      return null
    }
    return null
  }

  return (
    <Form.Item key={"form_" + field}>
     <StyledDatePicker
         locale={locale}
        showTime={showTime && {
          defaultValue: moment('06:00', 'HH:mm'),
          format: 'HH:mm'
        }}
        onChange={onChange}
        defaultValue = {defaultValueDataTime(field)}
        disabledDate={disabledDate}
        defaultPickerValue={moment('23:59','HH:mm')} />
      <ErrorMessage as={BaseErrorMessage} errors={errors} name={field} />
      <BasePlaceholder
        className={`${placeholderState ? "opened" : ""}`}
      >
        {description.label}
      </BasePlaceholder>
    </Form.Item>
  );
};

export default DateTimeInput;
