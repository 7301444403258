import React, { useEffect, useState } from "react";
import { CollapsableItem } from "../../../../../../../library/UI/components/CollapsableItem/CollapsableItem";
import ButtonExtensions from "./parts/Extension/ButtonExtensions";
import { Styled } from "./ExtensionData.styled";
import useGlobal from "../../store";
import { getFromURL } from "../../../../../../../api/getFromURL";

const { LocalMainWrapper } = Styled;

const ExtensionData = ({ handleSetData }) => {
  const [globalState, globalActions] = useGlobal();
  const [extensions, setExtensions] = useState([]);

  useEffect(() => {
    (async () => {
      const response = await getFromURL.getDataFromURL(
        "reports/device_summary/movement_report/"
      );
      const reports = await response;
      const extensions = reports.mime_types;
      setExtensions(extensions);
    })();
  }, []);

  const handleSetExtension = (extensions) => {
    globalActions.actions.extensionActions(extensions);
  };

  return (
    <CollapsableItem titleContent={"В каком виде получить отчет?"}>
      <LocalMainWrapper>
        <ButtonExtensions
          handleSetExtension={handleSetExtension}
          extensions={extensions}
        />
      </LocalMainWrapper>
    </CollapsableItem>
  );
};

export default ExtensionData;
