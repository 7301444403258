import React from "react";
import { useForm, FormContext } from "react-hook-form";
import { Styled } from "./AutocompleteForm.styled";
import {preparePanelGroups} from "./utils/preparePanelGroups";
import {prepareSinglePanels} from "./utils/prepareSinglePanels";

const {
  ExtendedAutocompleteForm,
  LocalTitle,
  LocalPanelsWrapper,
} = Styled;

const AutocompleteForm = React.forwardRef((props, ref) => {
  const { model, data, handleSubmitForm, initClose, hideTitle, additionalRenders } = props;
  const methods = useForm();
  const groups = model.groups;
  let panelsRender = null;
  if (groups) {
    const preparedPanelGroups = preparePanelGroups({formProps: props,groups, data, model})
    panelsRender = [...preparedPanelGroups]
  } else {
    const preparedSinglePanels = prepareSinglePanels({formProps: props, data, model})
    panelsRender = [...preparedSinglePanels]
  }
  return (
    <FormContext {...methods}>
      <ExtendedAutocompleteForm
        onInitClose={() => {
          const data = methods.getValues();
          initClose(data);
        }}
        ref={ref}
        onSubmit={methods.handleSubmit((formData) => {
          handleSubmitForm(formData);
        })}
      >
        {!hideTitle ? <LocalTitle>Основные</LocalTitle> : null}
        <LocalPanelsWrapper>{panelsRender}</LocalPanelsWrapper>
      </ExtendedAutocompleteForm>
      {additionalRenders}
    </FormContext>
  );
});

export default AutocompleteForm;
