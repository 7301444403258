import {ReactComponent as LogoFull} from "./imgs/LogoFull.svg"
import {ReactComponent as LogoMini} from "./imgs/LogoMini.svg"
import {ReactComponent as LogoAuth} from "./imgs/LogoAuth.svg";
import {ReactComponent as ItsPrimary} from "./imgs/ItsPrimary.svg";

export default {
  logo: {
    full: LogoFull,
    mini: LogoMini,
    auth: LogoAuth,
    itsPrimary: ItsPrimary,
  },
  auth: {
    bgImgSrcLink: `${process.env.PUBLIC_URL + '/img/imgAuth/bg-auth-nipigaz.png'}`
  },
  decoration: {
    defaultBorderRadiusOnlyTop: "8px 8px 0 0",
    defaultBorderRadiusOnlyBottom: "0 0 8px 8px",
    defaultBorderRadius: "8px",
    defaultBoxShadow: "0px 2px 8px rgba(0, 130, 145, 0.08)",
    dangerBoxShadow: "0px 18px 40px rgba(235, 87, 87, 0.08)",
    warningBoxShadow: "box-shadow: 0px 18px 40px rgba(245, 191, 79, 0.08)",
    successBoxShadow: "box-shadow: 0px 18px 40px rgba(100, 197, 84, 0.08)",
    darkBoxShadow: "0px 18px 40px rgba(0, 130, 145, 0.08)",
    darkenBoxShadow: "0px 2px 10px rgba(0, 130, 145, 0.09)",
    defaultBackgroundFilter: "blur(18px)"
  },
  fontSettings: {
    sectionTitle: "1.285rem",
    large: "1.15rem",
    defaultLarge: "1.05rem",
    default: "1rem",
    defaultSmall: "0.95rem"
  },
  margins: {
    defaultMargin: "1.1rem"
  },
  paddings: {
    defaultLarge: "1.2rem",
    defaultPadding: "1.1rem",
    defaultSmall: "1rem",
    smallPadding: "0.6rem"
  },
  colors: {
    primary: "#0097A6",
    primaryLight: "#29A3B0",
    primaryLighter: "#9ACAD1",
    primaryDark: "#054a51",
    primaryDarker: "#1B3548",
    paragraph: "#495E5F",
    backgroundNeutral: "#f2f3f6",
    dangerDark: "#aa0202",
    danger: "#EB5757",
    warning: '#F2C94C',
    success: '#27AE60',
    defaultDark: "#1B3543",
    defaultDarker: "#000",
    defaultLight: "#f1f3f6",
    defaultLighter: "#ffffff",
    neutralDark: "#c1cbd8",
    neutralDarker: "#929fb5",
    neutralLight: "#c2ccd9",

  }
};