import {CompositeLayer} from "@deck.gl/core";
import {ScatterplotLayer, TextLayer} from "@deck.gl/layers";
import wkt from "wkt";
import {layerNamesEnum} from "../../../../enums/layerNamesEnum";
import {mapObjectsEnum} from "../../../../enums/mapObjectsEnum";


class TrackingCargoPointCompositeLayer extends CompositeLayer {
    updateState({changeFlags, props}) {
        // this.setState({data: props})
    }
    getPickingInfo(event) {
        if (event.mode === 'query'){
            if (this.props.onClickHandler) {
                let selectedObjectType = null;
                let selectedObjectLayerName = layerNamesEnum.cargoTrackingPoints;
                if (event.sourceLayer.id.includes("cargo-position-point")) {
                    selectedObjectType = mapObjectsEnum.cargoTrackingPoint;
                }
                this.props.onClickHandler(
                    event.info,
                    selectedObjectType,
                    selectedObjectLayerName
                );
            } else return null
        }
    }
    renderLayers() {
        if (!this.props.relatedData) return []
        return [
            new ScatterplotLayer(this.props, this.getSubLayerProps({id: 'cargo-position-point'}), {
                pickable: true,
                data: this.props.relatedData.cargoCityPoints,
                stroked: true,
                filled: true,
                radiusScale: 2,
                radiusMinPixels: 20,
                radiusMaxPixels: 40,
                lineWidthMinPixels: 1,
                getPosition: d => {
                    if (d?.location){
                        const parsed = wkt.parse(d?.location)
                        if (parsed){
                            return parsed.coordinates
                        } else {
                            return [100,100]
                        }
                    } else {
                        return [100,100]
                    }
                },
                getRadius: d => 20,
                opacity: 0.8,
                wireframe: true,
                getFillColor: [60, 140, 0, 100],
                getLineColor: [80, 80, 80, 100],

            }),
            new TextLayer(this.props, this.getSubLayerProps({id: 'monitoring-auto-number-layer'}), {
                data: this.props.relatedData.cargoCityPoints,
                // characterSet: '0123456789АБВГДЕЁЖЗИЙКЛМНОПРСТУФХЦЧШЩЪЬЫЭЮЯQWERTYUIOPASDFGHJKLZXCVBNM',
                getPosition: d => {
                    if (d?.location){
                        const parsed = wkt.parse(d?.location)
                        if (parsed){
                            return parsed.coordinates
                        } else {
                            return [100,100]
                        }
                    } else {
                        return [100,100]
                    }
                },
                getText: (d) => d?.name.toUpperCase(),
                fp64: true,
                getPolygonOffset: ({ layerIndex }) => [0, -layerIndex * 10000],
                backgroundColor: [255, 255, 255],
                billboard: true,
                getOpacity: 1,
                sizeScale: 1,
                getSize: 16,
                getAngle: 0,
                getTextAnchor: 'middle',
                getAlignmentBaseline: 'center',
                sizeMinPixels: 16,
                sizeMaxPixels: 16,
            }),
        ]
    }

}

const defaultProps = {
    // Inherit all of GeoJsonLayer's props
    ...ScatterplotLayer.defaultProps,
    // Label for each feature
    getLabel: {type: 'accessor', value: x => x},
    // Label size for each feature
    getLabelSize: {type: 'accessor', value: 8},
    // Label color for each feature
    getLabelColor: {type: 'accessor', value: [0, 0, 0, 255]},
    getCurrentTimeStamp: {type: 'accessor', value: 0},
    // Label always facing the camera
    billboard: true,
    // Label size units
    labelSizeUnits: 'pixels',
    // Label background color
    labelBackground: {type: 'color', value: [255, 255, 255], optional: true},
    // Label font
    fontFamily: 'Monaco, monospace',
}

TrackingCargoPointCompositeLayer.layerName = 'TrackingCargoPoint';
TrackingCargoPointCompositeLayer.defaultProps = defaultProps;

export default TrackingCargoPointCompositeLayer


