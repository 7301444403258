import {mapObjectsEnum} from "../../../../../../enums/mapObjectsEnum";
import {CommonSelectedItemContextMenuPrototype} from "../../prototypes/CommonSelectedItemContextMenuPrototype";

export class RoadWorksDescription extends CommonSelectedItemContextMenuPrototype {

    constructor(selectedObject,layerName,initialCoordinate) {
        super(selectedObject, mapObjectsEnum.roadWorks, layerName)
    }

    actionItems = [...this.actionItems]

}
