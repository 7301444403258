import React from 'react';
import {connect} from 'react-redux';
import {getSelectedMapboxLayer} from "../../../../../../reducers/map/mapboxLayers";
import {recalculate} from "../../../../../../reducers/app/actions/appActions";
import VisibleUnvisibleFilterPanel from "./VisibleUnvisibleFilterPanel";
import {toggleLayerVisibility} from "../../../../../../reducers/map/actions/mapboxLayersActions";


const VisibleUnvisibleFilterPanelContainer = (props) => {

    return <VisibleUnvisibleFilterPanel {...props} />;
};

VisibleUnvisibleFilterPanelContainer.propTypes = {};

const mapStateToProps = (state) => ({
    selectedLayer: getSelectedMapboxLayer(state),
    recalculated: state.app.recalculated,


});

const mapDispatchToProps = (dispatch) => ({
    recalculate: () => dispatch(recalculate()),
    toggleLayerVisibility: (layer) => dispatch(toggleLayerVisibility(layer)),
});

export default connect(mapStateToProps, mapDispatchToProps)(VisibleUnvisibleFilterPanelContainer);