import React, {useContext} from "react";
import LayersGallery from "./LayersGallery";
import { connect } from "react-redux";
import { toggleLayersGalleryVisibility } from "../../../../../../../../../../../reducers/map/actions/mapPanelsActions";
import {LayersItemsContext} from "../../../../../LayersMenu";

const LayersGalleryContainer = ({
  SelectedLayers,
  isGalleryVisible,
  toggleLayersGalleryVisibility
}) => {
  const SelectedLayersCompareField = SelectedLayers.map(item => item?.name);
  const layersItems = useContext(LayersItemsContext)
  const filteredLayersGalleryItems = layersItems.map(el => {
    const match = SelectedLayersCompareField.filter(item => item === el.name);
    if (!match.length) {
      return el
    }
    return null
  });
  return (
    <>
      <LayersGallery
        items={filteredLayersGalleryItems}
        isGalleryVisible={isGalleryVisible}
        toggleLayersGalleryVisibility={toggleLayersGalleryVisibility}
      />
    </>
  );
};

const mapStateToProps = state => {
  return {
    SelectedLayers: state.mapboxLayers,
    isGalleryVisible: state.mapPanels.layersGalleryVisible
  };
};

const mapDispatchToProps = dispatch => {
  return {
    toggleLayersGalleryVisibility: () =>
      dispatch(toggleLayersGalleryVisibility())
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LayersGalleryContainer);
