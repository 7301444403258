import {CLEAR_AUTO_CLOSURE_ID, SET_AUTO_CLOSURE_ID} from "../mapAutoCreateDetour";

export const onMapAutoCreateDetourMode_ON = (closureId) => {
    return{
        type: SET_AUTO_CLOSURE_ID,
        payload: {closureId}
    }
}

export const onMapAutoCreateDetourMode_OFF = () => {
    return{
        type: CLEAR_AUTO_CLOSURE_ID,
    }
}