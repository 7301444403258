import styled from "styled-components";

const LocalMainWrapper = styled.div`
  padding-top: 30px;
  width: 314px;
  height: 160px;
`;

const LocalModelTSWrapper = styled.div`
  //padding: 40px;
`;
export const Styled = { LocalMainWrapper, LocalModelTSWrapper };
