import React from 'react';
import {Styled} from "../Panel.styled"

const {LocalSectionWrap} = Styled

const PanelSection = ({children, ...other}) => {
    return (
        <LocalSectionWrap {...other}>
            {children}
        </LocalSectionWrap>
    );
};

export default PanelSection;