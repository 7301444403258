import {LayerPrototype} from "../description/prototype/LayerPrototype";
import {layerNamesEnum} from "../../../../enums/layerNamesEnum";
import {nipigazLayerCards} from "../description/cards/nipigaz";
import RoadSchemeItemSelection
    from "../../../../library/layers/SelectedObjectsRepresentations/RoadSchemeItemSelection/RoadSchemeItemSelection";
import {layerDataAccessors} from "../description/dataAccessors";
import {relatedLayerData} from "../description/relatedData";
import {layerSchemas} from "../description/layerSchemaPresets";

export class RoadJamSectorsLayer extends LayerPrototype{

    constructor() {
        super({
            name:  layerNamesEnum.trafficRoadsSections,
            editable : true,
            mainData : null,
            dataAccessor : new layerDataAccessors.nipigazLayerDataAccessors.TrafficSegmentsDataAccessor(),
            relatedData: [
                new relatedLayerData.nipigazRelatedDataForLayers.Segments(),
                new relatedLayerData.nipigazRelatedDataForLayers.TrafficSegments(),
            ],
            card: new nipigazLayerCards.TrafficSegmentsCard(),
            customSettings: [],
            layerSchema : [new layerSchemas.nipigazLayersSchema.TrafficSegmentsLayerSchema()],
            //TODO create selected obj prototype
            selectedObjectPrototype: RoadSchemeItemSelection,
        });
    }
}
