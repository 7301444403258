import React from "react";
import { LayerDataAccessorPrototype } from "../prototype/LayerDataAccessorPrototype";
import { mapObjectsEnum } from "../../../../../../enums/mapObjectsEnum";

// Внимание к этому пути
const FilterFromSelectedDataButton = React.lazy(() =>
    import(
        "../../../../../../library/actionsButtons/NIPIGAZ/Map/dataAccessors/customForLayers/filterButtons/FilterCargoTrackingOnMapButton"
        )
);


const RefreshCargoLocationDataButton = React.lazy(() =>
    import(
        "../../../../../../library/actionsButtons/NIPIGAZ/Map/dataAccessors/customForLayers/refreshButtons/RefreshCargoLocationOnMapButton"
        )
);

export class CargoRepresentationDataAccessor extends LayerDataAccessorPrototype {

  actionButtons = [FilterFromSelectedDataButton, RefreshCargoLocationDataButton]

  constructor() {
    super({
      urlData: "/layer_tmc_tracking/cargo/representation/",
      selectedChild: mapObjectsEnum.singleCargo,
      i18LabelPath: "cargosList",
      selectedType: mapObjectsEnum.singleCargo,
    });
  }
}
