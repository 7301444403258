import {RelatedDataPrototype} from "../prototype/RelatedDataPrototype";

export class RoadsCategories extends RelatedDataPrototype {

    constructor() {
        super({
            name: 'roadsCategories',
            filterLabel: 'Категории дорог',
            urlData: '/roads/road_categories',
        });
    }
}
