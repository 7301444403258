import React from "react";
import { Button } from "../../UI/components/Button/Button";
import { MenuIcon } from "../../icons/MenuIcon";
import { Styled } from "./SidebarHeader.styled";
import _ from "underscore";
import { theme } from "../../themes/rootTheme";

const { LocalMainWrapper, LocalLogoWrap } = Styled;

const SidebarHeader = ({ sidebarIsOpened, toggleSidebarPosition }) => {
  return (
    <LocalMainWrapper
      sidebarIsOpened={sidebarIsOpened}
      toggleSidebarPosition={toggleSidebarPosition}
    >
      <LocalLogoWrap
        className="sidebar-header__logo"
        sidebarIsOpened={sidebarIsOpened}
        onClick={toggleSidebarPosition}
      >
          {sidebarIsOpened ? <theme.logo.mini /> : <theme.logo.full />}
      </LocalLogoWrap>
      {!sidebarIsOpened && (
        <Button
          type={"unbounded"}
          p={0}
          key={_.uniqueId("btn")}
          style={{ display: "block" }}
          icon={<MenuIcon key={_.uniqueId("icon")} />}
          iconColor={"white"}
          onClick={toggleSidebarPosition}
        />
      )}
    </LocalMainWrapper>
  );
};

export default SidebarHeader;
