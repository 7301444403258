export const SET_MAP_SELECTED_OBJECT = "SET_MAP_SELECTED_OBJECT";
export const CLEAR_MAP_SELECTED_OBJECT = "CLEAR_MAP_SELECTED_OBJECT";
export const UPDATE_SELECTED_OBJECT = "UPDATE_SELECTED_OBJECT";

const initialState = {};

//temporally example of state structure {data: {geometry: {...}, properties: {...}}}

const mapSelectedObject = (state = initialState, action) => {
  switch (action.type) {
    case CLEAR_MAP_SELECTED_OBJECT: {
      return initialState;
    }
    case SET_MAP_SELECTED_OBJECT: {
      const enteredData = action.payload.data;
      const {data, type, selectedObject} = action.payload;
      return {
        ...state,
        data,
        type,
        selectedObject
      }
    }
    case UPDATE_SELECTED_OBJECT: {
      const enteredData = action.payload.data;
      if (!state.data || !enteredData) return state;
      try {
          return {
            ...state,
            data: {
              ...state.data,
              ...enteredData.data,
              geometry: {
                ...state.data.geometry,
                ...enteredData.geometry
              },
              properties: {
                ...state.data.properties,
                ...enteredData.properties
              }
            }
          };
      } catch (e) {
        console.log(action.payload);
      }
    }
    default:
      return state;
  }
};

export default mapSelectedObject;
