
import {LayerSchemaPrototype} from "../prototype/LayerSchemaPrototype";
import MonitoringLayer from "../../../../../../library/layers/CompositeLayers/Core/MonitoringCompositeLayer";
import TrackingCompositeLayer from "../../../../../../library/layers/CompositeLayers/Core/TrackingCompositeLayer";
import {mapModesEnum} from "../../../../../../enums/mapModesEnums";

export class TrackingLayerSchema extends LayerSchemaPrototype{

    constructor() {
        super({
            layerToRender: TrackingCompositeLayer,
            id: 'tracking-layer',
            mapMode: mapModesEnum.history,
            useTimePlayerForLayer: true
        });
    }
}
