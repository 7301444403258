import React, { useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { userLoginFetch } from "../../../../reducers/app/actions/authActions";
import { Button } from "antd";
import { UserOutlined } from "@ant-design/icons";
import { authApi } from "../../../../api/authApi";
import StringInput from "../Input/StringInput";
import { useTranslation } from "react-i18next";
import { Styled } from "./PasswordRemember.style";

const {
  LocalMainWrapper,
  LocalLoginForm,
  LocalLoginFormTitle,
  LocalFormContentWrapper,
  LocalInputWrapper,
  LocalPrimaryDescription,
  ExtendedButton,
  ExtendedNavLink,
} = Styled;

/**
 * Component provides Login user form and starts login process
 * @returns Login form
 * @constructor none
 */
const PasswordRemember = () => {
  const [userData, setUserData] = useState({
    email: "",
  });
  const [focused, setFocused] = useState({
    email: false,
  });
  const [completed, setCompleted] = useState(false);
  const { t } = useTranslation("common");

  const user = useSelector((state) => state.auth.user);
  const dispatch = useDispatch();
  const sessionUser = localStorage.user;

  const handleChange = (event) => {
    setUserData({
      ...userData,
      [event.target.name]: event.target.value,
    });
  };

  const handleFocus = (event) => {
    setFocused({
      ...userData,
      [event.target.name]: true,
    });
  };

  const handleBlur = (event) => {
    setFocused({
      ...userData,
      [event.target.name]: false,
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    authApi.passwordReset(userData.email).then(() => {
      setCompleted(true);
    });
  };

  const checkUser = () => {
    if (sessionUser && !user) {
      dispatch({
        type: "LOGIN_USER",
        payload: sessionUser,
      });
    }
  };

  checkUser();
  return (
    <LocalMainWrapper>
      <LocalLoginForm>
        <LocalLoginFormTitle>
          {t("guest.login.titleRemember")}
        </LocalLoginFormTitle>
        <LocalFormContentWrapper onSubmit={handleSubmit}>
          <LocalInputWrapper>
            <StringInput
              name="email"
              placeholder="Электронная почта"
              prefix={<UserOutlined />}
              onChange={handleChange}
              onFocus={handleFocus}
              onBlur={handleBlur}
              style={{ margin: "0 auto" }}
            />
            <span
              className={`inline-placeholder-login 
                        ${userData.email ? "open" : "close"}
                        ${focused.email ? "focused" : ""}
                        `}
            >
              {t("guest.login.email")}
            </span>
          </LocalInputWrapper>
          <ExtendedButton
            onClick={(e) => handleSubmit(e)}
            type={"primary"}
            hasShadow
            p={"1.3rem"}
            fontSize={"1.15rem"}
            width={"100%"}
          >
            {t("guest.login.rememberSubmit")}
          </ExtendedButton>
          <ExtendedNavLink to={"/guest/login"}>
            <LocalPrimaryDescription>
              {t("guest.login.rememberSuccessBackLink")}
            </LocalPrimaryDescription>
          </ExtendedNavLink>
        </LocalFormContentWrapper>
      </LocalLoginForm>
      {completed && (
        <div className={"login-form"}>
          <h3 className={"login-form__title"}>
            {t("guest.login.rememberSuccess")}
          </h3>
          <p>{t("guest.login.rememberSuccessMessage")}</p>
          <Button type="link" href={"/guest/login"}>
            {t("guest.login.rememberSuccessBackLink")}
          </Button>
        </div>
      )}
    </LocalMainWrapper>
  );
};
// Todo style and functional
const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch) => ({
  userLoginFetch: (userInfo) => dispatch(userLoginFetch(userInfo)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PasswordRemember);
