export const registeredReportsUrlsEnum = {
  mobilizationTS: "mobilizationTS",
  statisticTS: "statisticTS",
  trafficAndParking: "trafficAndParking",
  signalLoss: "signalLoss",
  crossingGeozones: "crossingGeozones",
  startAndStop: "startAndStop",
  movementReport: "movementReport",
  passedGeozones: "passedGeozones",
  vehicleMobilization: "vehicleMobilization",
};
