import React from "react";
import {layerNamesEnum} from "../../../enums/layerNamesEnum";
import {CURRENT_APP_URL, CURRENT_APP_URL_SOCKET} from "../../../constants/constants";
import {applicationLayers} from './index'

export const basePath = CURRENT_APP_URL;
export const baseWSPath = CURRENT_APP_URL_SOCKET;


const registeredLayersUrls = {
    [layerNamesEnum.monitoring]: new applicationLayers.coreLayers.MonitoringLayer(),
    [layerNamesEnum.roadsSchema]: new applicationLayers.nipigazLayers.RoadSchemaLayer(),
    [layerNamesEnum.tracking]: new applicationLayers.coreLayers.TrackingLayer(),
    [layerNamesEnum.geozones]: new applicationLayers.coreLayers.GeozonesLayer(),
    [layerNamesEnum.cargoTracking]: new applicationLayers.nipigazLayers.CargoTrackingLayer(),
    [layerNamesEnum.cargoTrackingPoints]: new applicationLayers.nipigazLayers.CargoTrackingPointsLayer(),
    [layerNamesEnum.railwayNetwork]: new applicationLayers.nipigazLayers.RailwayNetworkLayer(),
   /* [layerNamesEnum.trafficRoadsSections]: new applicationLayers.nipigazLayers.RoadJamSectorsLayer(),*/
}
export const getRegisteredLayer = (name) => {
    return registeredLayersUrls?.[name] ?? null
}

export const getAllRegisteredLayers = () => {
    return registeredLayersUrls
}

