import React from 'react';
import {Styled} from "./panelSlimCard.styled"

const {MainWrapper } = Styled

const PanelSlimCard = (props) => {
    return (
        <MainWrapper {...props}>
            {props.children}
        </MainWrapper>
    );
};

export default PanelSlimCard;
