import { mapObjectsEnum } from "../../../../../../enums/mapObjectsEnum";
import { CommonSelectedItemContextMenuPrototype } from "../../prototypes/CommonSelectedItemContextMenuPrototype";

export class SignsDescription extends CommonSelectedItemContextMenuPrototype {
  constructor(selectedObject, layerName, initialCoordinate) {
    super(selectedObject, mapObjectsEnum.signs, layerName);
  }

  actionItems = [...this.actionItems];
}
