import React, {useMemo} from "react";
import PropTypes from "prop-types";
import { TransferCardHeader } from "../../../../UI/components/TransferCard/Header/TransferCardHeader";
import { waggonsUnloadingStatusEnums } from "../../../../../enums/waggonsUnloadingStatusEnums";
import TransferCardFooter from "../../../../UI/components/TransferCard/Footer/TransferCardFooter";
import TransferCardList from "../../../../UI/components/TransferCard/List/TransferCardList";
import LogoNipigazAnimation from "../../../../icons/preloaders/LogoNipigazAnimation";
import TransferCard from "../../../../UI/components/TransferCard/TransferCard.jsx";

function decideStatus(status) {
  switch (status) {
    case waggonsUnloadingStatusEnums.NOT_UNLOADED: {
      return "danger";
    }
    case waggonsUnloadingStatusEnums.UNLOADING: {
      return "middle";
    }
    case waggonsUnloadingStatusEnums.UNLOADED: {
      return "successful";
    }
  }
}

const Card = ({
  moduleState,
  headerText,
  styleTheme,
  unloadStatus,
  cardHeight,
  headerCallbacks,
  footerCallbacks,
  contentCallbacks
}) => {
  const loadingStatus = moduleState.isFetching;
  const unBlockControls = !!moduleState[unloadStatus].data.filter(item => item.isSelected).length
  const decidedStatus = useMemo(() => decideStatus(unloadStatus), [
    unloadStatus
  ]);
  const onSelectChange = (id) => contentCallbacks.onItemSelectChange(unloadStatus, id);
  // <TransferCard />

  return (
    <TransferCard
      cardHeight={cardHeight}
      headerContent={
        <TransferCardHeader
          text={headerText}
          quantity={moduleState[unloadStatus].data.length}
          onCloseCallback={headerCallbacks.onCloseCallback || null}
        />
      }
      footerContent={
        <TransferCardFooter
          loading={loadingStatus}
          unBlockControls={unBlockControls}
          deleteCallback={footerCallbacks.deleteCallback || null}
          toUpCallback={footerCallbacks.toUpCallback || null}
          toDownCallback={footerCallbacks.toDownCallback || null}
        />
      }
    >
      <TransferCardList
        groupItemsStatus={decidedStatus}
        itemsData={moduleState[unloadStatus].data}
        styleTheme={styleTheme}
        loading={loadingStatus}
        onSelectChange={onSelectChange}
        loaderRender={<LogoNipigazAnimation color={styleTheme.colors.primary} />}
      />
    </TransferCard>
  );
};

export default Card;

Card.propTypes = {
  cardHeight: PropTypes.string,
  footerCallbacks: PropTypes.object,
  headerCallbacks: PropTypes.object,
  headerText: PropTypes.string.isRequired,
  moduleState: PropTypes.object.isRequired,
  unloadStatus: PropTypes.oneOf([
    waggonsUnloadingStatusEnums.NOT_UNLOADED,
    waggonsUnloadingStatusEnums.UNLOADING,
    waggonsUnloadingStatusEnums.UNLOADED
  ]).isRequired,
  styleTheme: PropTypes.any
};

Card.defaultProps = {
  cardHeight: "300px"
};
