import {mapObjectsEnum} from "../../../../../../enums/mapObjectsEnum";
import {CommonSelectedItemContextMenuPrototype} from "../../prototypes/CommonSelectedItemContextMenuPrototype";

export class DockSegmentDescription extends CommonSelectedItemContextMenuPrototype {

    constructor(selectedObject,layerName,initialCoordinate) {
        super(selectedObject, mapObjectsEnum.dockSegment, layerName)
    }

    actionItems = [...this.actionItems]

}
