export default function INNValidator(inn,mask="1111-111111") {
    let result = null;
    const regexpNumbers = /[0-9.]/g;
    const regexpAlphabetRu =  /[aA-zZ.]/g;
    const regexpAlphabetEn = /[аА-яЯ.]/g;
    const preparedRegexp = mask.replace(regexpNumbers,'').replace(regexpAlphabetEn,'').replace(regexpAlphabetRu,'')
    let preparedValue = inn;
    for (let it=0; it<preparedRegexp.length;it++){
        preparedValue = preparedValue.replace(preparedRegexp[it],'')
   }
    const prepared = preparedValue;
    let localInn = prepared.toString().replace(/[^0-9.]/g, "")
    if (/[^0-9]/.test(localInn)) {
        result = 'Individual Tax-payer Number for individual entrepreneurs must contain only digits';
    } else if ([10, 12].indexOf(localInn.length) === -1) {
        result = 'Individual Tax-payer Number for individual entrepreneurs must contain 10 or 12 digits';
    } else {
        let checkDigit = function (localInn, coefficients) {
            let n = 0;
            for (let i in coefficients) {
                n += coefficients[i] * localInn[i];
            }
            return parseInt(n % 11 % 10);
        };
        switch (prepared.length) {
            case 10:
                let n10 = checkDigit(localInn, [2, 4, 10, 3, 5, 9, 4, 6, 8]);
                if (n10 === parseInt(localInn[9])) {
                    result = true;
                }
                break;
            case 12:
                let n11 = checkDigit(localInn, [7, 2, 4, 10, 3, 5, 9, 4, 6, 8]);
                let n12 = checkDigit(localInn, [3, 7, 2, 4, 10, 3, 5, 9, 4, 6, 8]);
                if ((n11 === parseInt(localInn[10])) && (n12 === parseInt(localInn[11]))) {
                    result = true;
                }
                break;
        }
        if (!result) {
            result = 'failed check control sum';
        }
    }
    return result;
}
