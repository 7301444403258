import styled from "styled-components";
import { Styled as styleMixins } from "../../styleMixins/styleMixins.styled";

const { CutStringWithEllipsisOnTheEnd } = styleMixins.text;

function getStatusBarLineColor(props = {}) {
  const { status = null } = props;
  switch (status) {
    case "danger": {
      return props.theme.colors.danger;
    }
    case "middle": {
      return props.theme.colors.warning;
    }
    case "successful": {
      return props.theme.colors.success;
    }
    default: {
      return props.theme.colors.defaultDark;
    }
  }
}

const LocalMainWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: ${(props) => props.theme.decoration.defaultBorderRadius};
  padding: 7px;
  transition: all 0.3s;
  &:hover {
    background: ${(props) => props.theme.colors.defaultLight};
  }
`;

const LocalStatusBarLine = styled.div`
  display: block;
  width: 2px;
  height: auto;
  background: ${(props) => getStatusBarLineColor(props)};
`;

const LocalInnerSection = styled.div`
  flex: 1;
  display: flex;
  flex-wrap: nowrap;
  margin: 0px -4px;
  & > * {
    margin: 0 4px;
  }
`;

const LocalContentWrap = styled.article`
  flex: 1;
  max-width: 70%;
  text-align: left;
  ${CutStringWithEllipsisOnTheEnd};
  & > * {
    ${CutStringWithEllipsisOnTheEnd};
  }
`;

export const Styled = {
  LocalMainWrapper,
  LocalStatusBarLine,
  LocalInnerSection,
  LocalContentWrap,
};
