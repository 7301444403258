import {RoadSchemaCard} from "./RoadSchemaCard";
import {CargoTrackingCard} from "./CargoTrackingCard";
import {RailwayNetworkCard} from "./RailwayNetworkCard";
import {TrackingLocationPointCard} from "./TrackingLocationPointCard";
import {TrafficSegmentsCard} from "./TrafficSegmentsCard";


export const nipigazLayerCards = {
    RoadSchemaCard,
    CargoTrackingCard,
    RailwayNetworkCard,
    TrafficSegmentsCard,
    TrackingLocationPointCard
}

