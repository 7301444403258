import React from "react";
import { Styled } from "./PanelSlimCardContent.styled";

const PanelSlimCardContent = props => {
  return (
    <Styled.ContentWrapper {...props}>{props.children}</Styled.ContentWrapper>
  );
};

export default PanelSlimCardContent;
