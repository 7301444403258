import React from "react";
import LayerSettingsContent from "./LayerSettingsContent";
import { connect } from "react-redux";

const LayerSettingsContentContainer = ({ settingTypeDisplay }) => {
  return <LayerSettingsContent settingTypeDisplay={settingTypeDisplay} />;
};

LayerSettingsContentContainer.propTypes = {};

const mapStateToProps = (state) => {
  return {
    settingTypeDisplay: state.mapPanels.layerSettingType,
  };
};

const mapDispatchToProps = () => {
  return {};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LayerSettingsContentContainer);
