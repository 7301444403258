import styled from "styled-components";
import { Button } from "../../../../../../../../../library/UI/components/Button/Button";

const LocalMainWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

const ExtendedButton = styled(Button)`
  margin-right: 8px;
  width: 100px;
  height: 100px;
  color: #929fb5;
  &:hover {
    background: #008291;
    color: white;
  }
  &:focus {
    background: #008291;
    color: white;
  }
`;

export const Styled = { LocalMainWrapper, ExtendedButton };
