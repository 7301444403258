import moment from "moment";
import { createSelector } from "reselect";

export const SET_INITIAL_DATETIME = "SET_INITIAL_DATETIME";
export const SET_CURRENT_TIME = "SET_CURRENT_TIME";
export const RESET_TIMER = "RESET_TIMER";
export const TOGGLE_PLAYER_RUNNING_STATUS = "TOGGLE_PLAYER_RUNNING_STATUS";
export const  TOGGLE_PLAYER_ENDED_STATUS = "TOGGLE_PLAYER_ENDED_STATUS"

const initialState = {
  playerRunning: false,
  playerEnded: false,
  startDateTime: "10.05.2020 10:00",
  endTimeStamp: 60 * 60,
  endDateTime: "10.05.2020 11:00",
  currentTime: "10.05.2020 10:00",
  currentTimeStamp: 0
};

const mapboxTime = (state = initialState, action) => {
  switch (action.type) {
    case SET_INITIAL_DATETIME: {
      const { startDateTime, endDateTime } = action.payload;
      const initialDate = moment(startDateTime, "DD.MM.YYYY hh:mm").toDate();
      const endDate = moment(endDateTime, "DD.MM.YYYY hh:mm").toDate();
      const endTimeStamp = (endDate - initialDate) / 1000;
      return {
        ...state,
        startDateTime,
        endDateTime,
        endTimeStamp
      };
    }
    case RESET_TIMER : {
      return {...initialState}
    }
    case SET_CURRENT_TIME: {
      const { currentTimeStamp } = action.payload;
      return {
        ...state,
        currentTimeStamp,
        currentTime: moment(state.startDateTime).add(currentTimeStamp, 'seconds')
      };
    }
    case TOGGLE_PLAYER_RUNNING_STATUS: {
      if (action.payload && typeof action.payload?.newStatus === 'boolean') {
        return { ...state, playerRunning: action.payload.newStatus };
      }
      return { ...state, playerRunning: !state.playerRunning };
    }
    case TOGGLE_PLAYER_ENDED_STATUS: {
      if (action.payload && typeof action.payload?.newStatus === 'boolean') {
        return { ...state, playerEnded: action.payload.newStatus };
      }
      return { ...state, playerEnded: !state.playerEnded };
    }
    default:
      return state;
  }
};

const selectMapboxTimeState = state => {
  return state.mapboxTime;
};

const getMapboxTimeCurrentAndEndTimestamps = createSelector(
  [selectMapboxTimeState],
  mapboxTime => {
    const { currentTimeStamp, endTimeStamp } = mapboxTime;
    return { currentTimeStamp, endTimeStamp };
  }
);

export const mapboxTimeSelectors = {
  getMapboxTimeCurrentAndEndTimestamps
}

export default mapboxTime;
