import React from "react";
import styled from "styled-components";
import LayersContentSwitcherContainer from "../LayersContentSwitcher/LayersContentSwitcherContainer";
import {Button} from "../../../../../../../../../library/UI/components/Button/Button";
import {baseStyles} from "../../../../../../../../stylesRegistrator";
import {MenuIcon} from "../../../../../../../../../library/icons/MenuIcon";
import {useTranslation} from "react-i18next";

const { BaseMapPanelHeader, BaseMapPanelTopPart, BaseMapPanelPrimaryTitle } = baseStyles.modules.map;

const StyledPanelTopPart = styled(BaseMapPanelTopPart)`
  justify-content: center;
  & button {
    position: absolute;
    left: 10px;
  }
  & ${BaseMapPanelPrimaryTitle} {
    width: 70%;
    text-align: center;
  }
`;

const LayersMenuHeader = ({toggleMainPanelCollapse}) => {
    const { t } = useTranslation("common");
  return (
    <BaseMapPanelHeader>
      <StyledPanelTopPart>
        <Button onClick={toggleMainPanelCollapse} p={"none"} type={"unbounded"} icon={<MenuIcon key={'icon'}/>} />
        <BaseMapPanelPrimaryTitle>{t('app.menuMap.title')}</BaseMapPanelPrimaryTitle>
      </StyledPanelTopPart>
      <LayersContentSwitcherContainer />
    </BaseMapPanelHeader>
  );
};

export default LayersMenuHeader;
