import { Form } from "antd";
import MaskedInput from "antd-mask-input";
import React from "react";
import { Controller, ErrorMessage, useFormContext } from "react-hook-form";
import {Styled} from "./styled/BaseInput.styled";
import {useBaseInputPlaceholderControl} from "./utils/hooks/useBaseInputPlaceholderControl";
import {checkConditionAndCallFn} from "../../services/common/checkConditionAndCallFn";
import {useBaseInputSettings} from "./utils/hooks/useBaseInputSettings";

const {getBaseStylesForInput, BasePlaceholder, BaseErrorMessage} = Styled

const StyledMaskedInput = getBaseStylesForInput(MaskedInput)

const CustomMaskedInput = props => {
    const { control, errors, setValue } = useFormContext();
    const { description, object, field, mask, validator } = props;
    const {required, message} = useBaseInputSettings(description)

    const {
        placeholderState,
        setPlaceholderState,
    } = useBaseInputPlaceholderControl(field, object, setValue)

    return (
        <Form.Item key={"form_" + field} name={field}>
            <Controller
                name={field}
                as={StyledMaskedInput}
                mask={mask}
                key={"input_" + field}
                control={control}
                rules={{
                    required: { value: required, message: message },
                    validate: value => {
                        if(value && validator) {
                            return validator(value,mask);
                        }
                        else {
                            return true
                        }
                    }
                }}
                disabled={description.read_only}
                placeholder={description.label}
                onFocus={() => {
                    setPlaceholderState(true);
                }}
                onBlur={e =>
                    checkConditionAndCallFn(!e[0].target.value, () =>
                        setPlaceholderState(false)
                    )
                }
                onSubmit={e=>{
                    return e

                }}
            />
            <ErrorMessage as={BaseErrorMessage} errors={errors} name={field} />
            <BasePlaceholder className={`${placeholderState ? "opened" : ""}`}>
                {description.label}
            </BasePlaceholder>
        </Form.Item>
    );
};

export default CustomMaskedInput;
