import React, { useRef } from "react";
import { directoryHistoryActions } from "../../../../../../reducers/directory/actions/directoryHistoryActions";
// import { startUpload } from "../../../../../../reducers/directory/actions/directoryActions";
import { connect } from "react-redux";
import { resolveUrl } from "../../../../../../registrators/old/directoryRegistrator/directoryRegistrator";
import PropsPasser from "../../../../../../HOC/PropsPasser";
import { useWhyDidYouUpdate } from "react-recipes";
import { getLastHistoryRecord } from "../../../../../../reducers/directory/directoryHistory";
import { useHistory } from "react-router-dom";
import { submitObjectSaga } from "../../../../../../sagas/app/postObjectSaga";

const {
  postToInlineHistory,
  revertToInlineHistoryRecordByType,
} = directoryHistoryActions;

const InlineObjectTemplate = React.memo((props) => {
  useWhyDidYouUpdate("DirectoryObjectTemplate", props);

  const { folder, id, pushInlineHistory, lastInlineHistory } = props;
  const data = lastInlineHistory.data ? lastInlineHistory.data : null;
  const model = lastInlineHistory.model ? lastInlineHistory.model : null;
  const field = lastInlineHistory.field ? lastInlineHistory.field : null;

  //Callbacks for object panel
  const history = useHistory();
  const formRef = useRef(null);

  const initClose = (ref) => {
    revertToInlineHistoryRecordByType("includes", "main");
  };
  const initSubmitForm = (ref) => {
    ref.current.props.onSubmit();
  };

  const handleSubmitForm = (data) => {
    props.startPostWithSaga(
      lastInlineHistory.urlData,
      data,
      lastInlineHistory.data
    );
  };
  //

  const goToInline = (event) => {
    const relatedModel = lastInlineHistory.model.scheme[event.key];
    if (relatedModel?.type === "inline") {
      pushInlineHistory({
        type: "inline",
        urlData: relatedModel.url,
        url: lastInlineHistory.url + `/${event.key}/`,
        field: event.key,
      });
    } else {
      pushInlineHistory({
        type: "mainObject",
        urlData: "",
        url: "",
        field: "",
        data: "",
        model: "",
      });
    }
  };

  const ObjectRendererPrototype = resolveUrl(folder, "objectPanel");

  const ObjectRenderer = (
    <PropsPasser
      component={
        <ObjectRendererPrototype
          data={data}
          model={model}
          goToInline={goToInline}
          initClose={initClose}
          initSubmitForm={initSubmitForm}
          handleSubmitForm={handleSubmitForm}
          referenceToForm={formRef}
          lastInlineFieldName={field}
        />
      }
    />
  );

  return <div>{ObjectRenderer}</div>;
});

const mapStateToProps = (state) => ({
  lastInlineHistory: getLastHistoryRecord(state),
});

const mapDispatchToProps = (dispatch) => ({
  pushInlineHistory: (newHistoryItem) =>
    dispatch(postToInlineHistory(newHistoryItem)),
  startPostWithSaga: (url, data, initial) =>
    dispatch(submitObjectSaga(url, data, initial)),
  revertToInlineHistoryRecordByType: (type, typeName) =>
    dispatch(revertToInlineHistoryRecordByType(type, typeName)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(InlineObjectTemplate);
