import React, { useEffect, useState } from "react";
import { Styled } from "./PeriodicityData.styled";
import { CollapsableItem } from "../../../../../../../library/UI/components/CollapsableItem/CollapsableItem";
import SelectTime from "../../../../../../../library/UI/components/Calendar/SelectTime";
import useGlobal from "../../store";

const { LocalMainWrapper } = Styled;

const TimePeriodicityData = () => {
  const [globalState, globalActions] = useGlobal();

  const updateDataStart = (value) => {
    globalActions.actions.timeActionsStart(value);
  };

  const updateDataEnd = (value) => {
    globalActions.actions.timeActionsEnd(value);
  };

  // useEffect(() => {
  //   const updateDataStart = (value) => {
  //     globalActions.timeActions.timeActionsStart(value);
  //   };
  //
  //   const updateDataEnd = (value) => {
  //     globalActions.timeActions.timeActionsEnd(value);
  //   };
  // });

  return (
    <LocalMainWrapper>
      <CollapsableItem titleContent={"За какой период получить данные?"}>
        <SelectTime
          updateDataStart={updateDataStart}
          updateDataEnd={updateDataEnd}
        />
      </CollapsableItem>
    </LocalMainWrapper>
  );
};

export default TimePeriodicityData;
