/**
 * @deprecated
 * @type {{default: string, success: string, warning: string, error: string}}
 */
export const alertTypesEnum = {
  error: "error",
  success: "success",
  warning: "warning",
  default: "default",
};
