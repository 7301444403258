import { theme } from "../../themes/rootTheme";
import * as d3 from "d3";

const CutStringWithEllipsisOnTheEnd = () => `
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const HideScrollbar = () => `
  &::-webkit-scrollbar {
    display: none;
  }
`;

const GetDefaultBeatifiedScrollbar = (props) => `
    ::-webkit-scrollbar {
    width: 6px;
  }
  ::-webkit-scrollbar-track {
    border: none;
    width: 10px;
    border-radius: ${props.theme.decoration.defaultBorderRadius};
  }
  ::-webkit-scrollbar-thumb {
    background: ${props.theme.colors.neutralDark};
    border-radius: ${props.theme.decoration.defaultBorderRadius};
  }
`

const OpacifyColorInCertainProperty = (colorName, property, val) => `
    ${property}: ${d3.rgb(theme.colors[colorName]).copy({ opacity: val })}
`;

const firstLetterUppercase = () => `
  &:first-letter {
    text-transform: uppercase;
  }
`;

const AddMarginsProps = props =>
  `margin: ${props.m ?? ""};
    margin-top: ${props.mt ?? ""};
    margin-bottom: ${props.mb ?? ""};
    margin-left: ${props.ml ?? ""};
    margin-right: ${props.mr ?? ""};`;

const AddPaddingsProps = props => `
    padding: ${props.p ?? ""};
    padding-top: ${props.pt ?? ""};
    padding-bottom: ${props.pb ?? ""};
    padding-left: ${props.pl ?? ""};
    padding-right: ${props.pr ?? ""};
`;

const AddSizeProps = (props) => `
  width: ${props?.size?.width ?? 'auto'};
  max-width: ${props?.size?.maxWidth ?? '100%'};
  min-width: ${props?.size?.minWidth ?? 'auto'};
  height: ${props?.size?.height ?? 'auto'};
  min-height: ${props?.size?.minHeight ?? 'auto'};
  max-height: ${props?.size?.maxHeight ?? 'auto'};
`


export const Styled = {
  offset: {
    AddMarginsProps,
    AddPaddingsProps,
  },
  size: {
    AddSizeProps
  },
  text: {
    CutStringWithEllipsisOnTheEnd,
    firstLetterUppercase
  },
  color: {
    OpacifyColorInCertainProperty
  },
  other: {
    HideScrollbar,
    GetDefaultBeatifiedScrollbar
  },
};
