import {call, delay, put, select, takeEvery, takeLatest} from 'redux-saga/effects'
import {getFromURL} from "../../api/getFromURL";
import {
    getEditableLayerHistoryArraySize,
    PUSH_TO_EDITABLE_LAYERS_HISTORY,
    RELOAD_DATA_IN_LAYERS_HISTORY
} from "../../reducers/map/mapboxEditableLayersHistory";
import {
    defineDrawKeyOnModel,
    defineDrawModeOnModel,
    defineNestedDescriptionOnModel
} from "../../services/map/defineDrawMapOnModel";
import {cropPath} from "../../services/net/cropPath";
import {removeLastItemInEditableLayerHistory} from "../../reducers/map/actions/mapboxEditableLayersActions";

export const PUSH_TO_EDITABLE_LAYERS_HISTORY_SAGA = 'PUSH_TO_EDITABLE_LAYERS_HISTORY_SAGA'
export const REVERT_EDITABLE_LAYERS_HISTORY_SAGA = 'REVERT_EDITABLE_LAYERS_HISTORY_SAGA'
export const RELOAD_EDITABLE_LAYERS_HISTORY_SAGA = 'RELOAD_EDITABLE_LAYERS_HISTORY_SAGA'
export const INIT_EDIT_MAP_MODE_SAGA = 'INIT_EDIT_MAP_MODE_SAGA'

export const reloadLastInlineData = (lastHistoryRecord) => ({
    type:RELOAD_EDITABLE_LAYERS_HISTORY_SAGA,
    payload: {
        lastHistoryRecord
    }
})


export const launchEditMapModeSaga = (layerDescription) => ({
    type: INIT_EDIT_MAP_MODE_SAGA,
    payload: {
        layerDescription
    }
})

export const launchMapPushToHistorySaga = (layerDescription,lastHistoryRecord) => ({
    type: PUSH_TO_EDITABLE_LAYERS_HISTORY_SAGA,
    payload: {
        layerDescription,
        lastHistoryRecord
    }
})

export const launchRevertEditableLayersHistoryByType = (type) => ({
    type: REVERT_EDITABLE_LAYERS_HISTORY_SAGA,
    payload: {
        type
    }
})

export const getInlineHistory = (state) => state.directoryHistory


function getNewItemData() {

}

function* editableLayersHistorySaga(action) {
    yield put({type: 'RECEIVED_PUSH_TO_MAP_HISTORY_ATTEMPT'})
    const {lastHistoryRecord,layerDescription} = action.payload;
    const {urlData} =  lastHistoryRecord;
    const preparedChildUrl = cropPath(layerDescription.url)
    const preparedParentUrl = cropPath(urlData)
    const childModel = yield call(() => getFromURL.getModelFromURL(preparedChildUrl))
    let parentRelatedField = null ;
    for (let it in childModel.scheme){
        if (childModel.scheme[it].url && (cropPath(childModel.scheme[it].url) === preparedParentUrl)) {
            parentRelatedField =childModel.scheme[it];
            parentRelatedField.key = it;
        }
    }
    const childData = yield call(() => getFromURL.getDataByParent(preparedChildUrl,parentRelatedField.key,lastHistoryRecord.selectedInstance.id))
    const drawMode = defineDrawModeOnModel(childModel)
    const drawObjectKey = defineDrawKeyOnModel(childModel)
    const nestedObjectsDescription = defineNestedDescriptionOnModel(childModel)
    yield put({
        type: PUSH_TO_EDITABLE_LAYERS_HISTORY,
        payload: {
            name: layerDescription.key,
            parentId: lastHistoryRecord.selectedInstance.id,
            parentKey: parentRelatedField,
            drawData: {
                type: 'FeatureCollection',
                features: [
                    /* insert features here */
                ],
            },
            urlData: layerDescription.url,
            layerObjectModel: childModel,
            layerObjectData: childData.results,
            selectedInstance: null,
            nestedObjectsDescription,
            drawMode,
            drawObjectKey,
        }
    })
}

function* reloadLastEditableLayersHistoryDataSaga(action) {
    yield put({type: 'REFRESH_DATA_FOR_LAST_EDITABLE_LAYER_STARTED'})
    const {lastHistoryRecord} = action.payload;
    yield delay(200)
    if (!lastHistoryRecord.parentKey) {
        const data = yield call(() => getFromURL.getDataFromURL(lastHistoryRecord.urlData))
        yield put({
            type:RELOAD_DATA_IN_LAYERS_HISTORY,
            payload:{
                value:data.results
            }
        })
    } else {
        const data = yield call(() => getFromURL.getDataByParent(cropPath(lastHistoryRecord.urlData),lastHistoryRecord.parentKey.key,lastHistoryRecord.parentId))
        yield put({
            type:RELOAD_DATA_IN_LAYERS_HISTORY,
            payload:{
                value:data.results
            }
        })
    }
}


function* initializeMapEditModeSaga(action) {
    const {layerDescription} = action.payload;
    yield put({type: 'GOT_INITIALISE_OF_EDIT_MODE', payload: action})
    const model = yield call(() => getFromURL.getModelFromURL(layerDescription.urlData))
    const data = yield call(() => getFromURL.getDataFromURL(layerDescription.urlData))
    const drawMode = defineDrawModeOnModel(model)
    const drawObjectKey = defineDrawKeyOnModel(model)
    const nestedObjectsDescription = defineNestedDescriptionOnModel(model)
    yield put({
        type: PUSH_TO_EDITABLE_LAYERS_HISTORY,
        payload: {
            name: layerDescription.name,
            parentId: null,
            parentKey: null,
            drawData: {
                type: 'FeatureCollection',
                features: [
                    /* insert features here */
                ],
            },
            urlData: layerDescription.urlData,
            layerObjectModel: model,
            layerObjectData: data.results,
            selectedInstance: null,
            nestedObjectsDescription,
            drawMode,
            drawObjectKey
        }
    })
}


function* revertEditableLayersHistorySaga(action) {
    yield put({type: 'INIT_REVERT_EDITABLE_LAYER_HISTORY'})
    const {type} = action.payload
    switch (type) {
        case 'lastOfType': {
            const editableLayerHistorySize = yield  select(getEditableLayerHistoryArraySize)
            if(editableLayerHistorySize > 1) {
                yield put(removeLastItemInEditableLayerHistory())
                //TODO need logic for updating last item in editable layer history after revert

                // const currentHistoryItem = yield select(getLastItemInEditableLayersHistory);
                // const {parentId, parentKey, urlData} = currentHistoryItem
                // let newData = null
                // if(parentId && parentKey) {
                //     newData = yield call(() => getFromURL.getDataByParent(urlData,parentKey,parentId))
                // }
                // else {
                //     newData = yield call(() => getFromURL.getDataFromURL(urlData))
                // }
                // yield put(updateMapEditLayerHistoryLastItemData(newData))
            }
        }
    }
    yield put({type: 'ENDED_REVERT_EDITABLE_LAYER_HISTORY'})
}

//need to realize by model
export function* watchEditableLayersHistorySaga() {
    yield takeEvery(PUSH_TO_EDITABLE_LAYERS_HISTORY_SAGA, editableLayersHistorySaga)
}

//reload data Button
export function* watchReloadLastEditableLayersHistoryDataSaga() {
    yield takeEvery(RELOAD_EDITABLE_LAYERS_HISTORY_SAGA, reloadLastEditableLayersHistoryDataSaga)
}

//revert in history
export function* watchRevertEditableLayersHistoryByType() {
    yield takeLatest(REVERT_EDITABLE_LAYERS_HISTORY_SAGA, revertEditableLayersHistorySaga)

}
//ready
export function* watchInitializeMapEditModeSaga() {
    yield takeEvery(INIT_EDIT_MAP_MODE_SAGA, initializeMapEditModeSaga)
}
