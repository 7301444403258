import React from "react";
import { Styled } from "./ScrollableBoxArea.styled";

const { AreaWrapper, AreaContent } = Styled;

const ScrollableBoxArea = ({children, areaBackground}) => {
  return (
    <AreaWrapper>
      <AreaContent areaBackground={areaBackground}>
          {children}
      </AreaContent>
    </AreaWrapper>
  );
};

export default ScrollableBoxArea;
