import { Button, ConfigProvider } from "antd";
import { Form } from "antd";
import { useFormContext, Controller, ErrorMessage } from "react-hook-form";
import React, { useEffect, useRef, useState } from "react";
import { Styled } from "./styled/BaseInput.styled";
import { postToURLSingle } from "../../api/postToURLSingle";
import { cropPath } from "../../services/net/cropPath";
import { useBaseInputPlaceholderControl } from "./utils/hooks/useBaseInputPlaceholderControl";
import { checkConditionAndCallFn } from "../../services/common/checkConditionAndCallFn";
import { useBaseInputSettings } from "./utils/hooks/useBaseInputSettings";
import { makeRepresentationDataRequest } from "./utils/fns/makeRepresentationDataRequest";
import styled from "styled-components";

const {
  BaseErrorMessage,
  BaseSelectInput,
  BaseSelectPlaceholder,
  BaseSelectInputWrap,
} = Styled;

const StyledAntdButton = styled(Button)`
  width: 100%;
`
const CreatableRelatedFieldInput = (props) => {
  const { description, object, field, mode } = props;
  const { required, message } = useBaseInputSettings(description);

  const inputWrapRef = useRef(null);
  const { control, errors, setValue } = useFormContext();
  const [optionsToChoose, setOptionsToChoose] = useState(null);

  const {
    placeholderState,
    setPlaceholderState,
    setInitialValue,
  } = useBaseInputPlaceholderControl(field, object, setValue);

  useEffect(() => {
    makeRepresentationDataRequest({
      inputDescription: description,
    }).then((options) => setOptionsToChoose(options));
  }, []);

  let templateString = useRef('');

  const handleSetTemplates = (value) => {
    templateString.current = value;
  };

  const handleCreateNewByName = () => {

    postToURLSingle
      .postOrPutData(cropPath(description.url), {
        name: templateString.current
      })
      .then((response) => {
        //refresh data with this request
        makeRepresentationDataRequest({
          inputDescription: description,
        }).then((options) => setOptionsToChoose(options));

        setInitialValue(response.id);
        setValue({ [field]: response.name });
      })
      .catch((e) => console.error(e));
  };

  return (
    <Form.Item>
      <BaseSelectInputWrap ref={inputWrapRef}>
        <ConfigProvider
          renderEmpty={() => (
            <StyledAntdButton type="primary" ghost onClick={handleCreateNewByName}>Создать новый объект</StyledAntdButton>
          )}
        >
          <Controller
            dropdownClassName={"custom-dropdown"}
            allowClear={true}
            name={field}
            as={
              <BaseSelectInput
                onSearch={(value) => handleSetTemplates(value)}
                renderEmpty={() => <Button>Добавить</Button>}
              >
                {optionsToChoose}
              </BaseSelectInput>
            }
            control={control}
            disabled={!!description.read_only}
            mode={mode || null}
            showSearch
            optionFilterProp="children"
            rules={{ required: { value: required, message: message } }}
            getPopupContainer={() => inputWrapRef.current}
            onFocus={() => {
              setPlaceholderState(true);
            }}
            onBlur={(e) => {
              checkConditionAndCallFn(!e[0], () => setPlaceholderState(false));
            }}
          />
        </ConfigProvider>
        <ErrorMessage as={BaseErrorMessage} errors={errors} name={field} />
        <BaseSelectPlaceholder
          className={`${placeholderState ? "opened" : ""}`}
        >
          {description.label}
        </BaseSelectPlaceholder>
      </BaseSelectInputWrap>
    </Form.Item>
  );
};

export default CreatableRelatedFieldInput;
