import { groupTypes } from "../../contentGroups";
import { LayersPrototype } from "../prototypes/LayersPrototype";
import React from "react";
import { mapContextMenuContentTypesEnum } from "../../../../../enums/mapContextMenuContentTypesEnum";
import { initializeSingleObjectEditMode } from "../../../../../sagas/map/standaloneObjectEditableSaga";
import { MeasureAreaMode } from "@nebula.gl/edit-modes";
import { setRulerMode } from "../../../../../reducers/map/actions/mapPanelsActions";
import { layerNamesEnum } from "../../../../../enums/layerNamesEnum";
import { mapObjectsEnum } from "../../../../../enums/mapObjectsEnum";
import { GeozoneIcon } from "../../../../../library/icons/contextMenuIcons/geozoneIcon";
import { RulerIcon } from "../../../../../library/icons/objectIcons/RulerIcon";

const { ACTION_GROUP, ADD_GROUP } = groupTypes;

export class CargoTrackingPoints extends LayersPrototype {
  constructor() {
    super(layerNamesEnum.cargoTrackingPoints);
  }

  actionItems = [
    {
      name: mapContextMenuContentTypesEnum.openInNewDirectory,
      translatePath: "Создать точку груза",
      group: ADD_GROUP,
      dispatchedAction: () =>
        initializeSingleObjectEditMode(
          null,
          mapObjectsEnum.cargoTrackingPoint,
          this.layerName,
          null
        ),
      icon: <GeozoneIcon />,
    },
    {
      name: mapContextMenuContentTypesEnum.rulerLength,
      translatePath: "Линейка",
      group: ACTION_GROUP,
      dispatchedAction: () => setRulerMode(MeasureAreaMode),
      icon: <RulerIcon />,
    },
  ];
}
