import {applyMiddleware, createStore} from "redux";
import thunk from "redux-thunk";
import rootReducer from '../reducers';
import createSagaMiddleware from 'redux-saga'
import {compose} from "redux";
import rootSaga from "../sagas/rootSaga";
import {logger} from "redux-logger";
import {CURRENT_APP_MODE} from "../constants/constants";
import { createBrowserHistory } from 'history'
import { routerMiddleware } from 'connected-react-router';

const combineMiddleware = (baseMiddleware, conditionalMiddleware) => {
    if(CURRENT_APP_MODE === 'development') {
        return [...baseMiddleware, ...conditionalMiddleware]
    }
    else {
        return [...baseMiddleware]
    }
}

export const history = createBrowserHistory()

function configureStore(initialState) {

    const sageMiddleware = createSagaMiddleware();

    const composeEnhancers =
        typeof window === 'object' &&
        window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ?
            window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
            }) : compose;

    const baseMiddleware = [thunk, sageMiddleware, routerMiddleware(history)]
    const conditionalMiddleware = [logger]
    const combinedMiddleware = combineMiddleware(baseMiddleware, conditionalMiddleware)
    const enhancer = composeEnhancers(
        applyMiddleware(...combinedMiddleware),
    );
    const store =  createStore(
        rootReducer(history),
        initialState,
        enhancer,

    );
    sageMiddleware.run(rootSaga);

    return store;

}

export const store = configureStore();
