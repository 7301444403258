import styled from "styled-components";
import {Styled as styledMixins} from "../../../styleMixins/styleMixins.styled"

const {CutStringWithEllipsisOnTheEnd} = styledMixins.text
const {AddMarginsProps, AddPaddingsProps} = styledMixins.offset

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: space-between;
  ${CutStringWithEllipsisOnTheEnd};
  flex: 1;
  // margins section
  ${(props) => AddMarginsProps(props)}
  //paddings section
  ${(props) => AddPaddingsProps(props)}
  transition: all 0.3s;
`;

export const Styled = { ContentWrapper };
