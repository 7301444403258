import styled from "styled-components";
import {Styled as styledMixins} from "../../styleMixins/styleMixins.styled";
const { AddSizeProps } = styledMixins.size;
const { AddMarginsProps, AddPaddingsProps } = styledMixins.offset;

const LocalMainWrapper = styled.article`
  ${props => AddSizeProps(props)};
  ${props => AddMarginsProps(props)};
  ${props => AddPaddingsProps(props)};
  & .MuiAlert-action {
    align-items: start;
  }
`

export const Styled = {LocalMainWrapper}