/**
 * @deprecated
 * @type {{httpGetWithFilters: string, httpGetAll: string, websocket: string, httpPost: string, custom: string, delayedUpload: string, httpGetById: string, useLayerCallback: string, useReduxData: string, httpGet: string, httpGetWithParams: string}}
 */
export const dataAccessMethodsEnum = {
    websocket: "ws",
    httpGet: "http",
    httpGetById: "http by id",
    httpGetWithParams: "http get with params",
    httpPost: "http post",
    httpGetAll: "http all",
    httpGetWithFilters: "http get with filters",
    delayedUpload: "delayed upload",
    useReduxData: 'use Redux data',
    useLayerCallback: 'use Layer setData callback',
    custom: 'custom',
};
