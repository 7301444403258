import React from "react";

import { registeredReportsUrlsEnum } from "../../enums/registeredReportsUrlsEnum";
import { resolver } from "./resolver";
import CompendiumContainer from "../../library/compendiums/Common/Compendium/CompendiumContainer";

import CreateButton from "../../library/actionsButtons/common/CreateButton";
import RefreshButton from "../../library/actionsButtons/common/RefreshButton";
import DeleteButton from "../../library/actionsButtons/common/DeleteButton";
import ReportPanelContainer from "../../library/objectPanels/Report/ReportPanelContainer";

const basePath = `${
  process.env.REACT_APP_TYPE === "PROD" || process.env.REACT_APP_TYPE === "TEST"
    ? process.env.REACT_APP_URL
    : "http://127.0.0.1:8000/api"
}`;
const BASE_DIRECTORY_PATH = "/app/reports";
export const resolveUrl = resolver;

const REGISTERED_APP_URLS = registeredReportsUrlsEnum;

/***
 *register urls for application and custom renderers
 * register null if render is unnecessary
 */
export const registeredReportsUrls = [
  // {
  //   url: REGISTERED_APP_URLS.mobilizationTS,
  //   urlData: basePath + "/reports/test/test_report/",
  //   folderKey: "app.reports.monitoring",
  //   i18LabelPath: "app.links.reports.mobilizationTS",
  //   permissionToShow: [],
  //   description: "app.links.directories.description.descriptionUsers",
  // },
  {
    url: REGISTERED_APP_URLS.passedGeozones,
    urlData: basePath + "/reports/device_summary/passed_geozones/",
    folderKey: "app.reports.monitoring",
    i18LabelPath: "app.links.reports.passedGeozones",
    permissionToShow: [],
    description: "app.links.reports.description.passedGeozones",
  },
  // {
  //   url: REGISTERED_APP_URLS.vehicleMobilization,
  //   urlData: basePath + "/reports/device_summary/vehicle_mobilization/",
  //   folderKey: "app.reports.monitoring",
  //   i18LabelPath: "app.links.reports.vehicleMobilization",
  //   permissionToShow: [],
  //   description: "app.links.reports.description.vehicleMobilization",
  // },
  {
    url: REGISTERED_APP_URLS.signalLoss,
    urlData: basePath + "/reports/device_summary/lost_signal_report/",
    folderKey: "app.reports.monitoring",
    i18LabelPath: "app.links.reports.signalLoss",
    permissionToShow: [],
    description: "app.links.reports.description.signalLoss",
  },
  {
    url: REGISTERED_APP_URLS.startAndStop,
    urlData: basePath + "/reports/device_summary/stops_report/",
    folderKey: "app.reports.monitoring",
    i18LabelPath: "app.links.reports.startAndStop",
    permissionToShow: [],
    description: "app.links.reports.description.startAndStop",
  },
  {
    url: REGISTERED_APP_URLS.movementReport,
    urlData: basePath + "/reports/device_summary/movement_report/",
    folderKey: "app.reports.monitoring",
    i18LabelPath: "app.links.reports.statisticMovement",
    permissionToShow: [],
    description: "app.links.reports.description.statisticMovement",
  },
];

export const defaultRenderers = {
  generalRenderer: CompendiumContainer,
  reportObjectPanel: ReportPanelContainer,
  permissionToShow: [],
};
