import {lineSlice, length, along} from "@turf/turf"

export const cutLineStartAndEndByPercent = (lineGeometry, cutPercent = 0) => {
    if(lineGeometry) {
        const initialLineLength = length(lineGeometry)
        const cutLength = initialLineLength / 100 * cutPercent
        const start = along(lineGeometry, cutLength)
        const end = along(lineGeometry, initialLineLength - cutLength)
        return lineSlice(start, end, lineGeometry)
    }
    else {
        console.error('wrong data in cutLineStartAndEndByPercent fn')
    }
}