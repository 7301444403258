import React, {Component} from "react";
import {Tag} from "antd";
import {withTranslation} from "react-i18next";

 class BooleanCellRenderer extends Component {
    constructor(props) {
        super(props);

        this.invokeParentMethod = this.invokeParentMethod.bind(this);
    }

    invokeParentMethod() {
        const field = this.props.colDef.additionalData;
        const data = this.props.data[field];
    }

    render() {
        let drawElement = null;
        const {t} = this.props;
        if (this.props.value === '0'|| this.props.value === 0) {
            drawElement = <Tag color="volcano">{t('app.compendium.cellRenderers.boolean.defaultFalse')}</Tag>
        } else if (this.props.value === '1' || this.props.value === 1) {
            drawElement = <Tag color="green">{t('app.compendium.cellRenderers.boolean.defaultTrue')}</Tag>;
        } else drawElement = null
        return (
            <>
                {drawElement}
            </>
        );
    }
};

export default withTranslation('common')(BooleanCellRenderer)
