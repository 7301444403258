import styled from "styled-components";


const MainWraper = styled.div`
 position: absolute;
    right: 70px;
    bottom: 35px;
    
    z-index: 1;
    padding: 5px;
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(0, 130, 145, 0.08);
    background: #fff;
`;

const MainHeader = styled.div`
 color: #929fb5;
    font-size: 1rem;
    text-align: center;
    font-weight: 500;
    padding: 5px 30px 5px 5px;
  `;

const MainItem = styled.div`
   display: flex;
    align-items: center;
    padding: 2px 10px;
  `;

const SubItem = styled.div`
    padding: 5px 15px; 
  `;

const Buttom = styled.div`
margin-left: auto;
padding: 0;
  button {
  padding: 0;
  }
`;

const SubIcon = styled.div`
  path {fill: #0097A6;}   
  `;

export const Styled = {
    MainWraper,
    MainHeader,
    MainItem,
    SubItem,
    Buttom,
    SubIcon
};

