import React from "react";
import DirectoryFolder from "./DirectoryFolder";
import {useWhyDidYouUpdate} from "react-recipes";

const DirectoryFolderContainer = (props) => {
    //dev
    useWhyDidYouUpdate('DirectoryFolderContainer',props)

    return (<DirectoryFolder/>)
}

export default DirectoryFolderContainer
