import React from "react";
import {resolveUrl} from "../../registrators/old/directoryRegistrator/directoryRegistrator";
import PropsPasser from "../../HOC/PropsPasser";

export const useActionBarRenderer = (lastInDirectoryHistory, CRUDHandlers) => {
    let ActionBarRenderer = null;
    if (lastInDirectoryHistory) {
        const ActionBarPrototype = resolveUrl(
            lastInDirectoryHistory.urlData,
            "actions",
            "urlData"
        );
        ActionBarRenderer = ActionBarPrototype.map(PrototypeButton => (
            <PropsPasser component={<PrototypeButton />} CRUDHandlers={CRUDHandlers} />
        ));
    }
    return {ActionBarRenderer}
}
