import React from "react";
import { Icon } from "antd";

const CheckpointIconSVG = () => (
    <svg width="18" height="12" viewBox="0 0 18 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M15.6667 1.00016H5.85002C5.63111 0.756622 5.36606 0.558946 5.0702 0.418584C4.77435 0.278222 4.45358 0.197965 4.12649 0.182463C3.7994 0.16696 3.47248 0.216521 3.16468 0.328274C2.85688 0.440027 2.57431 0.61175 2.33335 0.833493C1.80002 1.30016 1.50002 1.96683 1.50002 2.66683V10.1668C1.27901 10.1668 1.06704 10.2546 0.910765 10.4109C0.754484 10.5672 0.666687 10.7791 0.666687 11.0002V11.8335H7.33335V11.0002C7.33335 10.7791 7.24556 10.5672 7.08928 10.4109C6.933 10.2546 6.72103 10.1668 6.50002 10.1668V4.33349H15.6667C16.1087 4.33349 16.5326 4.1579 16.8452 3.84534C17.1578 3.53278 17.3334 3.10885 17.3334 2.66683C17.3334 2.2248 17.1578 1.80088 16.8452 1.48832C16.5326 1.17575 16.1087 1.00016 15.6667 1.00016ZM5.25002 10.1668H2.75002V4.83349C3.52502 5.28349 4.47502 5.28349 5.25002 4.83349V10.1668ZM4.00002 3.91683C3.6685 3.91683 3.35056 3.78513 3.11614 3.55071C2.88172 3.31629 2.75002 2.99835 2.75002 2.66683C2.75002 2.33531 2.88172 2.01736 3.11614 1.78294C3.35056 1.54852 3.6685 1.41683 4.00002 1.41683C4.33154 1.41683 4.64948 1.54852 4.8839 1.78294C5.11832 2.01736 5.25002 2.33531 5.25002 2.66683C5.25002 2.99835 5.11832 3.31629 4.8839 3.55071C4.64948 3.78513 4.33154 3.91683 4.00002 3.91683ZM7.75002 3.50016L6.50002 1.83349H7.75002L9.00002 3.50016H7.75002ZM11.0834 3.50016L9.83335 1.83349H11.0834L12.3334 3.50016H11.0834ZM14.4167 3.50016L13.1667 1.83349H14.4167L15.6667 3.50016H14.4167Z" fill="#1B3548"/>
    </svg>

);

export const CheckpointIcon = props => (
    <Icon component={CheckpointIconSVG} {...props} />
);
