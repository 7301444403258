import _ from "underscore";

function parseCoordinatesToDesc(selectedMapItem) {
  if (
    selectedMapItem?.selectedObject?.coordinate &&
    !_.isEmpty(selectedMapItem?.selectedObject?.coordinate)
  ) {
    const [
      coordinatesX,
      coordinatesY,
    ] = selectedMapItem.selectedObject.coordinate;
    return `${coordinatesX} / ${coordinatesY}`;
  }
}

function parseCoordinatesToDescForMonitoring(selectedMapItem) {
  if (
    selectedMapItem?.selectedObject?.coordinates &&
    !_.isEmpty(selectedMapItem?.selectedObject?.coordinates)
  ) {
    const [
      coordinatesX,
      coordinatesY,
    ] = selectedMapItem.selectedObject.coordinates;
    return `${coordinatesX} / ${coordinatesY}`;
  }
}

const commonIconSizeForCurrentSection = { width: "12px", height: "12px" };

export const commonSelectedObjectProtoUtils = {
  parseCoordinatesToDesc,
  parseCoordinatesToDescForMonitoring,
  commonIconSizeForCurrentSection,
};
