
import {LayerSchemaPrototype} from "../prototype/LayerSchemaPrototype";

import TrackingCargoPointCompositeLayer
    from "../../../../../../library/layers/CompositeLayers/NIPIGAZ/TrackingCargoPointCompositeLayer";

export class CargoTrackingPointsLayerSchema extends LayerSchemaPrototype{
    constructor() {
        super({
            layerToRender: TrackingCargoPointCompositeLayer,
            id: 'trackingLocationPoints',
        });
    }
}
