import React from "react";
import LayersMenuHeaderContainer from "./LayersMenuHeader/LayersMenuHeaderContainer";
import LayersSearchBarContainer from "./LayersSearchBar/LayersSearchBarContainer";
import LayersMenuContentContainer from "./LayersMenuContent/LayersMenuContentContainer";
import {baseStyles} from "../../../../../../../stylesRegistrator";

const { BaseMapLayerPanelWrapperPrimary } = baseStyles.modules.map;

const LayerMainPanel = ({
  isGalleryVisible,
}) => {
  return (
    <BaseMapLayerPanelWrapperPrimary>
      <LayersMenuHeaderContainer />
      <LayersSearchBarContainer />
      <LayersMenuContentContainer isGalleryVisible={isGalleryVisible} />
    </BaseMapLayerPanelWrapperPrimary>
  );
};

LayerMainPanel.propTypes = {};

export default LayerMainPanel;
