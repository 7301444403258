import {dataAccessMethodsEnum} from "../../../../../../enums/dataAccessMethodsEnum";
import {basePath, baseWSPath} from "../../../layersRegistrator";

export class RelatedDataPrototype {
    name;
    filterLabel;
    isHidden = false;
    urlData;
    dataAccessMethod;
    layerFilterKey;
    dataGetterComponent;
    selectedObjectName;

    basePathTemplate = basePath;
    baseWSPathTemplate = baseWSPath;

    constructor({
                    name,
                    filterLabel = '',
                    urlData,
                    dataAccessMethod = dataAccessMethodsEnum.httpGetAll,
                    layerFilterKey = "id",
                    dataGetterComponent = null,
                    selectedObjectName = null,
                }) {
        this.name = name;
        this.filterLabel = filterLabel ? filterLabel : name;
        this.dataAccessMethod = dataAccessMethod;
        if (dataAccessMethod === dataAccessMethodsEnum.websocket) {
            this.urlData = this.baseWSPathTemplate + urlData;
        } else {
            this.urlData = this.basePathTemplate + urlData;
        }
        this.dataGetterComponent = dataGetterComponent;
        this.layerFilterKey = layerFilterKey;
        this.selectedObjectName = selectedObjectName;
    }

    getName() {
        return this.name;
    }

    getFilterLabel() {
        return this.filterLabel;
    }

    toggleVisible(value) {
        return (this.isHidden = value ?? !this.isHidden);
    }

    getIsHidden() {
        return this.isHidden;
    }

    getUrlData() {
        return this.urlData;
    }

    getDataAccessMethod() {
        return this.dataAccessMethod;
    }

    getLayerFilterKey() {
        return this.layerFilterKey;
    }

    getSelectedObjectName() {
        return this.selectedObjectName;
    }

    getDataGetterComponent() {
        return this.dataGetterComponent;
    }
}
