import React from "react";
import { Styled } from "./PromptNotifications.styled";
import PropTypes from "prop-types";
import { promptTypesEnum } from "../../../../../enums/promptTypesEnum";
import { QuestionIcon } from "../../../../icons/objectIcons/QuestionIcon";
import { Button } from "../../Button/Button";
import ScreenPortal from "../../ScreenCornerPortal/ScreenPortal";

const {
  LocalMainWrapper,
  LocalIcon,
  LocalDescription,
  LocalExtensionFunctional,
} = Styled;

const PromptNotifications = ({ promptData, isCreate, ...otherProps }) => {
  if (promptData) {
    const { message } = promptData;
    return (
      <LocalMainWrapper {...otherProps}>
        <LocalIcon>
          <QuestionIcon />
        </LocalIcon>
        <LocalDescription>{message}</LocalDescription>
        {isCreate && (
          <LocalExtensionFunctional>
            <Button type={"primaryReverse"}>Создать</Button>
            <Button type={"unboundedPrimary"}>Использовать тот же</Button>
          </LocalExtensionFunctional>
        )}
      </LocalMainWrapper>
    );
  }
};

const Usage = ({ isOpen, portalSide, ...otherProps }) => {
  return (
    <ScreenPortal isOpen={isOpen} portalSide={portalSide} {...otherProps}>
      <PromptNotifications {...otherProps} />
    </ScreenPortal>
  );
};

export default Usage;

Usage.propTypes = {
  message: PropTypes.node,
  type: PropTypes.oneOf([promptTypesEnum.default]),
  portalSide: PropTypes.oneOf([
    "top-right",
    "top-center",
    "top-left",
    "bottom-right",
    "bottom-center",
    "bottom-left",
    "middle-left",
    "middle-center",
    "middle-right",
  ]),
};
