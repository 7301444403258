import styled from "styled-components";

const LocalMainWrapper = styled.aside`
  height: calc(100% - 40px);
  width: 52px;
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
`

const LocalItemsGroup = styled.section`
  & + & {
    margin-top: ${props => props.theme.margins.defaultMargin};
  }
  & > * {
    & + * {
      margin-top: ${props => props.theme.margins.defaultMargin};
    }
  }
`

export const Styled = {LocalMainWrapper, LocalItemsGroup}
