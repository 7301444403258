import React, {useCallback} from "react";
import { resolveUrl } from "../../../../../../registrators/old/directoryRegistrator/directoryRegistrator";
import PropsPasser from "../../../../../../HOC/PropsPasser";
import { connect } from "react-redux";
import { directoryHistoryActions } from "../../../../../../reducers/directory/actions/directoryHistoryActions";
import { useWhyDidYouUpdate } from "react-recipes";
import { Styled } from "./TemplateBaseStyle.styled";
import {useActionBarRenderer} from "../../../../../../customHooks/directoryTemplates/useActionBarRenderer";

const {postToInlineHistory} = directoryHistoryActions

const DirectoryDataListTemplate = props => {
  useWhyDidYouUpdate("DirectoryDataListTemplate", props);
  const { lastInHistory, folder, pushInlineHistory, data, model, urlData } = props;

  const proceedData = useCallback(selectedRow => {
    const resolvedDataUrl = resolveUrl(folder, "urlData");
    pushInlineHistory({
      type: "mainObject",
      ids: selectedRow.data.id,
      urlData: resolvedDataUrl + `${selectedRow.data.id}/`,
      url: `/app/directory/${folder}/${selectedRow.data.id}/`,
      field: null
    });
  }, []);

  const createObject = () => {
    const resolvedDataUrl = resolveUrl(folder, "urlData");
    pushInlineHistory({
      type: "mainObject",
      ids: null,
      urlData: resolvedDataUrl,
      url: `/app/directory/${folder}/new/`,
      field: null,
      isNewObject: true
    });
  };

  const proceedInline = useCallback(() => {}, []);

  //create compendium with directoryResolver actions
  let CompendiumRenderer = null;
  if (urlData) {
    const CompendiumPrototype = resolveUrl(
      urlData,
      "generalRenderer",
      "urlData"
    );
    CompendiumRenderer = (
      <PropsPasser
        component={
          <CompendiumPrototype
            data={data}
            model={model}
            proceedData={proceedData}
            createObject={createObject}
            proceedInline={proceedInline}
            withPagination={false}
            agGridThemeName={'ag-theme-custom-base'}
          />
        }
      />
    );
  }
  //create action panel with directoryResolver actions
  const CRUDHandlers = {
    createObject
  }
  const {ActionBarRenderer} = useActionBarRenderer(lastInHistory, CRUDHandlers)

  return (
    <Styled.BaseTemplateMainWrapper>
      <Styled.BaseTemplateActionBarWrapper>
        {ActionBarRenderer}
      </Styled.BaseTemplateActionBarWrapper>
      {CompendiumRenderer}
    </Styled.BaseTemplateMainWrapper>
  );
};

const mapStateToProps = state => ({});

const mapDispatchToProps = dispatch => ({
  pushInlineHistory: newHistoryItem =>
    dispatch(postToInlineHistory(newHistoryItem))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DirectoryDataListTemplate);
