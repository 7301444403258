import { CommonSelectedItemContextMenuPrototype } from "../../prototypes/CommonSelectedItemContextMenuPrototype";
import React from "react";
import { groupTypes } from "../../../contentGroups";
import { mapObjectsEnum } from "../../../../../../enums/mapObjectsEnum";

// const {} = groupTypes;

export class CargoTrackingPointsDescription extends CommonSelectedItemContextMenuPrototype {
    initialCoordinate = null;
    constructor(selectedObject, layerName, initialCoordinate) {
        super(selectedObject, mapObjectsEnum.cargoTrackingPoint, layerName);
        this.initialCoordinate = initialCoordinate;
    }
    actionItems = [
        ...this.actionItems,
    ];
}
