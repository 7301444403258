import React from "react";
import MapStandaloneEditMode from "./MapStandaloneEditMode";
import {revertToDefaultMapMode, setCurrentMapMode} from "../../../../reducers/map/actions/mapModeActions";
import {connect} from "react-redux";
import {useFnsCallingOnMountAndUnmount} from "../../../../customHooks/common/useFnsCallingOnMountAndUnmount";

/**
 * Компонент редактирования единичного объекта
 * @param {string} currentMode
 * @param {function} setCurrentMapMode
 * @param {function} revertToDefaultMapMode
 * @return {JSX.Element} MapStandaloneEditMode
 * @see MapStandaloneEditMode
 */
const MapStandaloneEditModeContainer = ({currentMode, setCurrentMapMode, revertToDefaultMapMode}) => {

  useFnsCallingOnMountAndUnmount(() => setCurrentMapMode('standaloneEdit'), () => revertToDefaultMapMode())

  return <MapStandaloneEditMode currentMode={currentMode} />;
};

function mapStateToProps(state) {
  return {
    currentMode: state.mapMode.currentMode
  }
}

function mapDispatchToProps(dispatch) {
  return {
    setCurrentMapMode: (modeType) =>
        dispatch(setCurrentMapMode(modeType)),
    revertToDefaultMapMode: () => dispatch(revertToDefaultMapMode())
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(MapStandaloneEditModeContainer);
