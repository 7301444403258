import styled from "styled-components";

const LocalWrapper = styled.h3`
  padding: ${(props) => props.theme.paddings.smallPadding};
  color: ${(props) => props.color ?? props.theme.colors.defaultDark};
  font-size: ${(props) =>
    props.fontSize ?? props.theme.fontSettings.defaultSmall};
  text-align: left;
  width: 100%;
  margin: 0;
  &:first-letter {
    text-transform: uppercase;
  }
  &:hover {
    cursor: default;
  }
  & + * {
    margin-top: ${(props) =>
      props.offsetAfter ?? props.theme.margins.defaultMargin};
  }
`;

export const Styled = { LocalWrapper };
