import React, { useMemo } from "react";
import { SelectableItem } from "../../SelectableItem/SelectableItem";
import PropTypes from "prop-types";
import { waggonsUnloadingStatusEnums } from "../../../../../enums/waggonsUnloadingStatusEnums";

export const TransferCardItem = ({
  styleTheme,
  itemStatus,
  children,
  isSelected,
  onSelectChange,
}) => {
  return (
    <SelectableItem
      onSelectChange={onSelectChange}
      theme={styleTheme}
      isSelected={isSelected}
      status={itemStatus}
    >
      {children}
    </SelectableItem>
  );
};

TransferCardItem.propTypes = {
  children: PropTypes.node,
  isSelected: PropTypes.bool,
  onItemSelectChange: PropTypes.func,
  styleTheme: PropTypes.object.isRequired,
  itemStatus: PropTypes.oneOf(["danger", "middle", "successful"]).isRequired
};

TransferCardItem.defaultProps = {
  isSelected: false
};
