import styled from "styled-components";
import { Collapse, Form } from "antd";

const ExtendedAutocompleteForm = styled(Form)`
  padding: 2.5rem;
`;

const LocalTitle = styled.h2`
  font-size: 1.25rem;
  text-align: left;
`;

const LocalPanelColumn = styled.div`
  margin: 0 20px;
  width: calc(100% / 12 * 4 - 40px);
  min-width: 250px;
  
  & + .workInProgress {
    display: none;
  }
`;

const ExtendedAntdCollapse = styled(Collapse)`
  background: transparent;
`;

const ExtendedAntdCollapseItem = styled(Collapse.Panel)`
  border: none !important;
  width: 100%;
  padding: 0 !important;
  & .ant-collapse-header {
    color: ${(props) => props.theme.colors.neutralDarker} !important;
    padding-left: 0 !important;
    padding-bottom: 2.1rem !important;
    font-size: 1rem;
    font-weight: bold;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    width: fit-content;
    & i {
      margin-left: 0.6rem;
      position: static !important;
      transform: none !important;
    }
  }
  & .ant-collapse-content-box {
    padding: 0;
  }
`;

const LocalPanelsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: wrap;
  width: 95%;
  margin: 0 -20px;
`;

export const Styled = {
  ExtendedAutocompleteForm,
  LocalTitle,
  LocalPanelsWrapper,
  LocalPanelColumn,
  ExtendedAntdCollapse,
  ExtendedAntdCollapseItem,
};
