import {useEffect} from "react";
import {prepareWaggonsForStateByUnloadingStatus} from "../fns/prepareWaggonsForStateByUnloadingStatus";
import {waggonsUnloadingStatusEnums} from "../../../../../../enums/waggonsUnloadingStatusEnums";
import {getWaggonsByUrl} from "../fns/getWaggonsByUrl";

export function useDischargeZoneWaggonsData({initialData, dischargeZonesUrl, handleChangeFetchingStatus, handleChangeNeedReloadingStatus,  setModuleState, deps = []}) {
    useEffect(() => {
        if (initialData) {
            getWaggonsByUrl(dischargeZonesUrl, initialData?.id, handleChangeFetchingStatus).then(
                waggonsData => {
                    const preparedInProgressWaggons = prepareWaggonsForStateByUnloadingStatus(
                        waggonsData,
                        waggonsUnloadingStatusEnums.UNLOADING
                    );
                    const preparedCompletedWaggons = prepareWaggonsForStateByUnloadingStatus(
                        waggonsData,
                        waggonsUnloadingStatusEnums.UNLOADED
                    );
                    setModuleState(prev => ({
                        ...prev,
                        [waggonsUnloadingStatusEnums.UNLOADING]: {
                            ...prev[waggonsUnloadingStatusEnums.UNLOADING],
                            data: preparedInProgressWaggons
                        },
                        [waggonsUnloadingStatusEnums.UNLOADED]: {
                            ...prev[waggonsUnloadingStatusEnums.UNLOADED],
                            data: preparedCompletedWaggons
                        }
                    }))
                }
            );
        }
        handleChangeNeedReloadingStatus(false)
    }, deps);
}