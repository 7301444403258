import { MainStyled } from "./MainStyles.styled";
import { Styled as BaseMapStyled } from "./Application/Map/mapModuleBaseStyles.styled";
import { Styled as BaseDirectoryStyled } from "./Application/Directory/directoryModuleBaseStyles.styled";
import { Styled as BaseHomePageStyled } from "./Application/HomePage/homePageModuleBaseStyles";
import { Styled as ApplicationMixinsStyled } from "../library/UI/styleMixins/styleMixins.styled";

export const baseStyles = {
  main: MainStyled,
  mixins: ApplicationMixinsStyled,
  modules: {
    map: BaseMapStyled,
    directory: BaseDirectoryStyled,
    homePage: BaseHomePageStyled
  }
};
