import styled from "styled-components";
import {Button} from "../../../../../../../../../../../library/UI/components/Button/Button";
import {baseStyles} from "../../../../../../../../../../stylesRegistrator";
import {Styled as styleMixins} from "../../../../../../../../../../../library/UI/styleMixins/styleMixins.styled";

const { GetDefaultBeatifiedScrollbar } = styleMixins.other;
const { BaseMapPanelPrimaryTitle } = baseStyles.modules.map;

const LocalMainWrapper = styled.div``;

const LocalHeaderWrapper = styled.div`
  color: ${(props) => props.theme.colors.neutralDarker};
  font-size: ${(props) => props.theme.fontSettings.default};
  padding-bottom: 6px;
`;

const LocalScrollArea = styled.div`
  overflow-y: scroll;
  ${(props) => GetDefaultBeatifiedScrollbar(props)};
`;

const LocalInnerWrapper = styled.div`
  height: 300px;
`;

const LocalSearchPanel = styled.div`
  padding-bottom: 16px;
  width: 100%;
`;

const CardText = styled(BaseMapPanelPrimaryTitle)``;
const CardTextData = styled(BaseMapPanelPrimaryTitle)`
  font-size: 13px;
  padding-top: 5px;
  color: ${(props) => props.theme.colors.neutralDarker};
`;

const CardButton = styled(Button)`
  padding: 4px;
  border-radius: 3px;
  background: none;
  color: ${(props) => props.theme.colors.neutralDarker};
  &:hover {
    color: ${(props) => props.theme.colors.primary};
  }
`;

export const Styled = {LocalMainWrapper, LocalInnerWrapper, LocalScrollArea, LocalHeaderWrapper, LocalSearchPanel, CardText, CardTextData, CardButton}