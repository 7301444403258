import { call, put, takeEvery} from "redux-saga/effects";
import { postToURLSingle } from "../../api/postToURLSingle";
import {makeUrlWithoutIdAtTheEnd} from "../../services/net/makeUrlWithoutIdAtTheEnd";
import {alertTypesEnum} from "../../enums/alertTypesEnum";
import {setGlobalAlertData} from "../../reducers/app/actions/notificationActions";
import {alertStatusesEnum} from "../../enums/alertStatusesEnum";
import {onMapAutoCreateDetourMode_ON} from "../../reducers/map/actions/mapAutoCreateDetourActions";

export const submitObjectSaga = (url, data, initial,redrawDispatch=null,closureId) => ({
  type: SUBMIT_OBJECT_SAGA,
  payload: {
    url,
    data,
    initial,
    redrawDispatch,
    closureId
  }
});
export const SUBMIT_OBJECT_SAGA = "SUBMIT_OBJECT_SAGA";

function* postObjectSaga(action ) {
  try {
    yield put({ type: "START_POSTING_DATA" });
    const { url, data, initial,redrawDispatch, closureId } = action.payload;
    //this fn call can break something in the project
    const preparedUrl = makeUrlWithoutIdAtTheEnd(url)
    let b,r
      yield call(  async () => { b = postToURLSingle.postOrPutData(preparedUrl, data, initial);
      await b.then((res)=>r=res).then(()=>console.log())})
      if (action.payload.closureId) yield call(  () => postToURLSingle.patchData("/roads/closures-segments/" + action.payload.closureId +"/",
          {
            "detour": r.id
          }));
    if (action.payload.data?.road_closure && !action.payload?.data?.id) {
        yield put(
        setGlobalAlertData({
          status: alertStatusesEnum.active,
          type: alertTypesEnum.success,
          title: "",
          message: "",
          // closureId: r.id,
        })
        )
        yield put (onMapAutoCreateDetourMode_ON(r.id))
    }
    if (action.payload.data?.road_work) {yield put(
        setGlobalAlertData({
          status: alertStatusesEnum.active,
          type: alertTypesEnum.success,
          title: "Успешно!",
          message: "Внимание! При необходимости добавьте пути объезда!",
        })
    )}
    if (redrawDispatch){
      yield put(redrawDispatch)
      yield put({
        type: "REFRESH_SUCCESSFUL"
      });
    }
    yield put({
      type: "POST_SUCCESSFUL"
    });
  }
  catch (e) {
    yield put(
        setGlobalAlertData({status: alertStatusesEnum.active,type: alertTypesEnum.error, title: "Ошибка", message: 'Что-то пошло не так'})
    )
  }
}

export function* watchPostObjectSaga() {
  yield takeEvery(SUBMIT_OBJECT_SAGA, postObjectSaga);
}
