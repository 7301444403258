import React, { useRef } from "react";
import { useTranslation } from "react-i18next";
import { commonSelectedObjectProtoUtils } from "../commonUtils/commonSelectedObjectProtoUtils";
import { registeredDirectoryUrlsEnum } from "../../../../enums/registeredDirectoryUrlsEnum";
import PrepareScrollableInfoCard from "../PrepareScrollableInfoCard";

const {
  parseCoordinatesToDesc,
  commonIconSizeForCurrentSection,
} = commonSelectedObjectProtoUtils;

const RailwaySchemeItemSelection = ({
  handleCloseMapSelectedObjectInfo,
  selectedMapItem,
  data,
}) => {
  const handleOpenObjectInNewTab = () => {
    const roadData = selectedMapItem.data.find(
      (dataRecord) => dataRecord.name === "railway"
    );
    const { id } = roadData.data;
    const win = window.open(
      `/app/directory/${registeredDirectoryUrlsEnum.railway_rail_ways}/${id}/`,
      "_blank"
    );
    win.focus();
  };

  const { t } = useTranslation("common");
  let Descriptions = [];
  Descriptions.push({
    key: t("app.menuMap.dataVehicle.coordinates"),
    value: parseCoordinatesToDesc(selectedMapItem),
  });

  const properties = data;
  properties.name &&
    Descriptions.push({
      key: "Наименование дороги",
      value: properties.name,
    });

  properties.total_length &&
    Descriptions.push({
      key: "Протяженность",
      value: `${properties.total_length?.toFixed(2)} км`,
    });

  Descriptions.push({
    key: "Собственник",
    value: properties.owner_organization ?? "Не указан",
  });

  return (
    <PrepareScrollableInfoCard
      title={t("app.menuMap.scrollableInfoCard.header.railwayNetwork")}
      handleCloseCard={handleCloseMapSelectedObjectInfo}
      handleOpenItemInDirectory={handleOpenObjectInNewTab}
      listOfDescriptions={Descriptions}
      openInNewTabBtnDesc={t("app.menuMap.dataVehicle.openInDirectory")}
    />
  );
};

RailwaySchemeItemSelection.propTypes = {};

export default RailwaySchemeItemSelection;
