import React from "react";
import ru from "../../../../i18n/aggrid/ru";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-enterprise";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { useAgGridSettings } from "../../../../customHooks/ag-grid/useAgGridSettings";
import { useRenderCompendiumWithCertainColumns } from "../../../../customHooks/ag-grid/useRenderCompendiumWithCertainColumns";
import { useAgGridSidebarWithoutAdditionalFunctional } from "../../../../customHooks/ag-grid/useAgGridSidebarWithoutAdditionalFunctional";
import {useCertainFilterNamesCollectionForTable} from "../../../../customHooks/layerDataAccessor/dataTable/useCertainFilterNamesCollectionForTable";

const StyledAGGrid = styled(AgGridReact)``;
/***
 * Basic representation of aggrid with autocomplete data
 * @param aggApi is interface for interaction with grid
 * @param data data for representation, may be null
 * @param model model for create columns
 *
 * @param namesCollectionForFiltering is array with names of columns for render in the aggrid by default
 * @param withoutGridSidebarAdditionalFunctional is flag that disable additional functional for Sidebar in the aggrid
 * @param withPagination is flag for activate or deactivate pagination in the grid
 * @param agGridThemeName is string value with scss class for the ag-grid themes
 * @param gridCellSizes is object that define sizes of cell in the aggrid
 * @param rerenderCompendiumIfDataChanged is flag that define filtering hook rerender dependency
 * @param containerCallbacks
 *        @containerCallbacks:{
 *            @handleProvideData onDoubleClickHandler
 *            @handleSelectRow onClickHandler
 *            @handleAGGInit callback for aggrid api reference
 *        }
 * @param showQuickFilter
 * @returns {React.Component}
 */
const Compendium = ({
  aggApi,
  data,
  model,
  containerCallbacks,
  showQuickFilter,
  /*namesCollectionForFiltering,*/
  withPagination,
  agGridThemeName,
  gridCellSizes,
  withoutGridSidebarAdditionalFunctional,
  rerenderCompendiumIfDataChanged
}) => {
  const { t, i18n } = useTranslation("common");
  const { agSettings, columnsData } = useAgGridSettings(
    data,
    model,
    containerCallbacks,
    gridCellSizes
  );
  const [namesCollectionForFiltering] = useCertainFilterNamesCollectionForTable(
      model
  );
  useRenderCompendiumWithCertainColumns(
    namesCollectionForFiltering,
    aggApi,
    columnsData,
    rerenderCompendiumIfDataChanged
  );

  useAgGridSidebarWithoutAdditionalFunctional(
    aggApi,
    withoutGridSidebarAdditionalFunctional
  );

  return (
    <>
      <div
        className={agGridThemeName}
        style={{
          height: "100%"
        }}
      >
        {agSettings.columnDefs && (
          <StyledAGGrid
            columnDefs={agSettings.columnDefs}
            defaultColDef={agSettings.defaultColDef}
            rowData={agSettings.rowData}
            rowSelection="multiple"
            onGridReady={containerCallbacks.handleAGGInit}
            animateRows={true}
            // overlayNoRowsTemplate={agSettings.overlayNoRowsTemplate}
            sideBar={agSettings.sideBar}
            floatingFilter={showQuickFilter}
            onSelectionChanged={containerCallbacks?.handleSetSelectedRows}
            enableRangeSelection={true}
            enableCharts={true}
            groupSelectsChildren={true}
            groupMultiAutoColumn={true}
            paginationAutoPageSize={true}
            pagination={withPagination}
            localeText={i18n.language === "ru-RU" ? agSettings.localeText : ""}
            rowGroupPanelShow={agSettings.rowGroupPanelShow}
            detailCellRendererParams={agSettings.detailCellRendererParams}
            frameworkComponents={agSettings.frameworkComponents}
            suppressDragLeaveHidesColumns={true}
            onFilterChanged={agSettings.onFilterChanged}
            enableCellChangeFlash={true}
          />
        )}
        <div id="myChart" className={`${agGridThemeName} my-chart`} />
      </div>
    </>
  );
};

Compendium.propTypes = {
  containerCallbacks: PropTypes.array,
  data: PropTypes.array,
  model: PropTypes.array.isRequired,
  showQuickFilter: PropTypes.bool,
  withPagination: PropTypes.bool,
  agGridThemeName: PropTypes.oneOf(["ag-theme-custom-base", "ag-theme-custom-base ag-theme-custom-map"]).isRequired
};

Compendium.defaultProps = {
  withPagination: false,
  agGridThemeName: "ag-theme-custom-base"
};

export default Compendium;
