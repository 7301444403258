import { RegistratorPrototype } from "../../../../RegistratorPrototype";
import { dataAccessMethodsEnum } from "../../../../../enums/dataAccessMethodsEnum";

export class LayerPrototype extends RegistratorPrototype {
  mainData = {
    urlData: null,
    dataAccessMethod: dataAccessMethodsEnum.httpGet,
    dataKeyForIds: "id",
  };

  dataAccessor = null;

  relatedData = [];

  card = null;

  customSettings = [];

  layerSchema = [];

  selectedObjectName = null;
  selectedObjectPrototype = null;
  editable = false;

  templates = null;

  constructor({
    name,
    mainData = null,
    dataAccessor = null,
    relatedData = [],
    card = null,
    customSettings = [],
    layerSchema = [],
    selectedObjectName = null,
    selectedObjectPrototype = null,
    editable = false,
    templates = null,
  }) {
    super(name);

    this.mainData = mainData;
    this.dataAccessor = dataAccessor;
    this.relatedData = relatedData;
    this.card = card;
    this.customSettings = customSettings;
    this.layerSchema = layerSchema;
    this.selectedObjectName = selectedObjectName;
    this.selectedObjectPrototype = selectedObjectPrototype;
    this.editable = editable;
    this.templates = templates;
  }

  getIsEditableLayer() {
    return this.editable;
  }

  getMainData() {
    return this.mainData;
  }

  getDataAccessor() {
    return this.dataAccessor;
  }

  getRelatedData() {
    return this.relatedData;
  }

  getCard() {
    return this.card;
  }

  getCustomSettings() {
    return this.customSettings;
  }

  getLayerSchema() {
    return this.layerSchema;
  }

  getSelectedObjectName() {
    return this.selectedObjectName;
  }

  getSelectedObjectPrototype() {
    return this.selectedObjectPrototype;
  }

  getTemplates() {
    return this.templates;
  }
}
