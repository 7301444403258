import { Button } from "../../Button/Button";
import { iconSizesKit } from "../../../utils/iconSizesKit";
import { CloseIcon } from "../../../../icons/actionIcons/CloseIcon";
import React from "react";
import { Styled } from "../TransferCard.styled";

const {LocalHeaderWrap, LocalHeaderContentItem, LocalHeaderContentGroup} = Styled.Header

export function TransferCardHeader({ text, quantity, onCloseCallback }) {
  let CloseButtonConditionalRender = null;

  if (onCloseCallback) {
    CloseButtonConditionalRender = (
      <LocalHeaderContentGroup>
        <Button
          type={"unboundedPrimary"}
          iconSize={iconSizesKit.big}
          icon={<CloseIcon />}
          onClick={onCloseCallback}
          p={0}
        />
      </LocalHeaderContentGroup>
    );
  }

  return (
    <LocalHeaderWrap>
      <LocalHeaderContentGroup>
        <LocalHeaderContentItem>{text}</LocalHeaderContentItem>
        <LocalHeaderContentItem>({quantity})</LocalHeaderContentItem>
      </LocalHeaderContentGroup>
      {CloseButtonConditionalRender}
    </LocalHeaderWrap>
  );
}
