import { mapModesEnum } from "../../../../../../enums/mapModesEnums";

export class LayerSchemaPrototype {
  type;
  id;
  mapMode = "display";
  additionalParameters;

  constructor({ layerToRender, id, mapMode = mapModesEnum.display, ...other }) {
    this.layerToRender = layerToRender;
    this.id = id;
    this.mapMode = mapMode;
    this.additionalParameters = other;
  }

  getAdditionalParameters() {
    return this.additionalParameters;
  }

  getLayerToRender() {
    return this.layerToRender;
  }

  getId() {
    return this.id;
  }

  getMapMode() {
    return this.mapMode;
  }
}
