import { createInputs } from "../../../../../services/application/schemaManipulation/provideInputDirectory";
import React from "react";
import { Styled } from "../AutocompleteForm.styled";

const {
  LocalPanelColumn,
  ExtendedAntdCollapseItem,
  ExtendedAntdCollapse,
} = Styled;

export function preparePanelGroups({ formProps, groups, model, data }) {
  const panelsResult = [];
  let missingGroups = Object.keys(model.scheme);
  for (let group of groups) {
    const inputGroups = createInputs(model, group.fields, data, formProps.form);
    panelsResult.push(
      <LocalPanelColumn key={`uniq_${group.label}`}>
        <ExtendedAntdCollapse
          bordered={false}
          expandIconPosition={"right"}
          defaultActiveKey={["1"]}
        >
          <ExtendedAntdCollapseItem
            header={<span>{group.label}</span>}
            key={"1"}
          >
            {inputGroups}
          </ExtendedAntdCollapseItem>
        </ExtendedAntdCollapse>
      </LocalPanelColumn>
    );
    for (let field of group.fields) {
      missingGroups = missingGroups.filter((el) => el !== field);
    }
  }

  const inputGroups = createInputs(
    model,
    missingGroups,
    data,
    formProps.form,
    true
  );

  panelsResult.push(inputGroups);

  return panelsResult;
}
