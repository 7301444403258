import moment from "moment";

/**
 *
 * @param object
 * @param field
 * @param sort
 * @returns {{headerName: *, field: *, editable: boolean}}
 */
function getBaseColumnTemplateFields(object, field, sort) {
  const template = {
    headerName: object[field].label,
    field: field,
    editable: !object[field].read_only,
  };
  if (sort) {
    template.sort = sort;
  }
  return template;
}
function getHrefBaseColumnTemplateFields(object, field, sort) {
  const template = {
    headerName: "Ссылка на файл",
    field: field,
    editable: !object[field].read_only,
  };
  if (sort) {
    template.sort = sort;
  }
  return template;
}

export const defineColumnsDefinitions = (data) => {
  let result = [];
  let nested = [];
  const object = data?.scheme;
  for (let field in object) {
    switch (object[field].type) {
      case "object": {
        result.push({
          headerName: object[field].label,
          field: field,
          cellRenderer: "objectCellRenderer",
          valueGetter: function (params) {
            if (params.data && params.data[field]) {
              return "Объект=>";
            } else return " ";
          },
          additionalData: field,
          editable: false,
          filtered: false,
          group: false,
          enableValue: false,
          enableRowGroup: false,
          sortable: false,
        });
        break;
      }
      case "field": {
        if (field.includes("_detail")) continue;
        if (field.includes("_name")) continue;
        result.push({
          headerName: object[field].label,
          field: field,
          cellRenderer: "objectCellRenderer",
          valueGetter: function (params) {
            if (params.data && params.data[field]) {
              return "Объект=>";
            } else return " ";
          },
          additionalData: {
            url: object[field + "_detail"],
            id: object[field],
            label: object[field + "_name"],
          },
          editable: false,
          enableValue: false,
          enableRowGroup: true,
          sortable: false,
        });
        break;
      }
      case "datetime": {
        result.push({
          ...getBaseColumnTemplateFields(object, field),
          valueGetter: function (params) {
            if (params.data) {
              const date = moment(params.data[field]);
              return date._isValid
                ? date.format("DD.MM.YY, hh:mm")
                : "Отсутсвует";
            } else return " ";
          },
          filter: "agDateColumnFilter",
          filterParams: {
           comparator: function (filterLocalDateAtMidnight, cellValue) {
              let newDate = new Date(filterLocalDateAtMidnight)
              let dateAsString = cellValue;
              if (dateAsString == "Отсутсвует") return -1;
              let dateParts = dateAsString.split(' ');
              let day = dateParts[0].slice(0, 2)
              let moths = dateParts[0].slice(3, 5)
              let year = "20" + dateParts[0].slice(6, 8)
              let cellDate = new Date(
                  year,
                  moths - 1,
                  day
              );
              if (filterLocalDateAtMidnight.getTime() == cellDate.getTime()) {
                return 0;
              }
              if (cellDate < filterLocalDateAtMidnight) {
                return -1;
              }
              if (cellDate > filterLocalDateAtMidnight) {
                return 1;
              }
            },
            browserDatePicker: true,
            inRangeInclusive:true
          },
          comparator: function (valueA, valueB, nodeA, nodeB, isInverted) {
            const valueAParsed = moment(valueA, "DD.MM.YY, hh:mm");
            const valueBParsed = moment(valueB, "DD.MM.YY, hh:mm");
            const isAfter = valueAParsed.isAfter(valueBParsed);
            const isBefore = valueAParsed.isBefore(valueBParsed);
            //if not date comparators
            if (!valueAParsed._isValid && !valueBParsed._isValid) {
              return -1;
            } else if (!valueAParsed._isValid && valueBParsed._isValid) {
              return -1;
            } else if (valueAParsed._isValid && !valueBParsed._isValid) {
              return 1;
            }
            //if both date comparators
            if (isBefore) {
              return -1;
            } else if (isAfter) {
              return 1;
            } else {
              return 0;
            }
          },
        });
        break;
      }
      case "boolean": {
        result.push({
          ...getBaseColumnTemplateFields(object, field, "asc"),
          chartDataType: "series",
          valueGetter: function (params) {
            if (params.data) {
              return params.data[field] ? 1 : 0;
            } else return " ";
          },
          cellRenderer: "booleanCellRenderer",
        });

        break;
      }
      case "integer": {
        if (field.toLowerCase() === "id") {
          continue;
        } else
          result.push({
            ...getBaseColumnTemplateFields(object, field),
            filter: "agNumberColumnFilter",
            chartDataType: "series",
          });
        break;
      }
      case "float": {
        result.push({
          ...getBaseColumnTemplateFields(object, field),
          filter: "agNumberColumnFilter",
          chartDataType: "series",
        });
        break;
      }
      case "string": {
        if (field.includes("name")) {
          result.push({
            ...getBaseColumnTemplateFields(object, field, "asc"),
            chartDataType: "category",
          });
        } else {
          result.push(getBaseColumnTemplateFields(object, field, "asc"));
        }
        break;
      }
      case "date": {
        result.push({
          ...getBaseColumnTemplateFields(object, field),
          valueGetter: function (params) {
            if (params.data) {
              const date = moment(params.data[field]);
              return date._isValid
                  ? date.format("DD.MM.YY, hh:mm")
                  : "Отсутсвует";
            } else return " ";
          },
          filter: "agDateColumnFilter",
          filterParams: {
            comparator: function (filterLocalDateAtMidnight, cellValue) {
              let newDate = new Date(filterLocalDateAtMidnight)
              let dateAsString = cellValue;
              if (dateAsString == "Отсутсвует") return -1;
              let dateParts = dateAsString.split(' ');
              let day = dateParts[0].slice(0, 2)
              let moths = dateParts[0].slice(3, 5)
              let year = "20" + dateParts[0].slice(6, 8)
              let cellDate = new Date(
                  year,
                  moths - 1,
                  day
              );
              if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
                return 0;
              }
              if (cellDate < filterLocalDateAtMidnight) {
                return -1;
              }
              if (cellDate > filterLocalDateAtMidnight) {
                return 1;
              }
            },
            browserDatePicker: true,
            inRangeInclusive:true
          },
          comparator: function (valueA, valueB, nodeA, nodeB, isInverted) {
            const valueAParsed = moment(valueA, "DD.MM.YY, hh:mm");
            const valueBParsed = moment(valueB, "DD.MM.YY, hh:mm");
            const isAfter = valueAParsed.isAfter(valueBParsed);
            const isBefore = valueAParsed.isBefore(valueBParsed);
            //if not date comparators
            if (!valueAParsed._isValid && !valueBParsed._isValid) {
              return -1;
            } else if (!valueAParsed._isValid && valueBParsed._isValid) {
              return -1;
            } else if (valueAParsed._isValid && !valueBParsed._isValid) {
              return 1;
            }
            //if both date comparators
            if (isBefore) {
              return -1;
            } else if (isAfter) {
              return 1;
            } else {
              return 0;
            }
          },
        });
        break;
      }
      case "point": {
        result.push(getBaseColumnTemplateFields(object, field, "asc"));
        break;
      }
      case "email": {
        result.push(getBaseColumnTemplateFields(object, field, "asc"));
        break;
      }
      case "line string": {
        result.push(getBaseColumnTemplateFields(object, field, "asc"));
        break;
      }
      case "length": {
        result.push(getBaseColumnTemplateFields(object, field, "asc"));
        break;
      }
      case "polygon": {
        result.push(getBaseColumnTemplateFields(object, field, "asc"));
        break;
      }
      case "json": {
        result.push(getBaseColumnTemplateFields(object, field, "asc"));
        break;
      }

      case "href": {
        if (field.toLowerCase() === "errors_href") {
          result.push({
            ...getBaseColumnTemplateFields(object, field, "asc"),
            chartDataType: "series",
            valueGetter: function (params) {
              if (params.data) {
                return data[field];
              } else return " ";
            },
            cellRenderer: "linkCellRenderer",
            additionalData: field,
          });
        }
        break;
      }
      case "snils": {
        result.push(getBaseColumnTemplateFields(object, field, "asc"));
        break;
      }
      case "inn": {
        result.push(getBaseColumnTemplateFields(object, field, "asc"));
        break;
      }
      case "kpp": {
        result.push(getBaseColumnTemplateFields(object, field, "asc"));
        break;
      }
      case "okved": {
        result.push(getBaseColumnTemplateFields(object, field, "asc"));
        break;
      }
      case "okpo": {
        result.push(getBaseColumnTemplateFields(object, field, "asc"));
        break;
      }
      case "ogrn": {
        result.push(getBaseColumnTemplateFields(object, field, "asc"));
        break;
      }
      case "phone": {
        result.push(getBaseColumnTemplateFields(object, field, "asc"));
        break;
      }
      case "url": {
        result.push(getBaseColumnTemplateFields(object, field, "asc"));
        break;
      }
      case "related field": {
        // result.push(getBaseColumnTemplateFields(object, field, 'asc'));
        continue;
      }
      case "string related field": {
        result.push(getBaseColumnTemplateFields(object, field, "asc"));
        break;
      }
      case "inline": {
        result.push(getBaseColumnTemplateFields(object, field, "asc"));
        break;
      }
      case "choice": {
        result.push(getBaseColumnTemplateFields(object, field, "asc"));
        break;
      }
      case "registration number": {
        result.push(getBaseColumnTemplateFields(object, field, "asc"));
        break;
      }
      case "year": {
        result.push(getBaseColumnTemplateFields(object, field, "asc"));
        break;
      }
      case "time": {
        result.push(getBaseColumnTemplateFields(object, field, "asc"));
        break;
      }
      case "nested object": {
        result.push(getBaseColumnTemplateFields(object, field, "asc"));
        break;
      }
      case "color in rgba": {
        result.push(getBaseColumnTemplateFields(object, field, "asc"));
        break;
      }
      case "decimal": {
        result.push(getBaseColumnTemplateFields(object, field, "asc"));
        break;
      }
      case "start value for objects with geometry": {
        result.push(getBaseColumnTemplateFields(object, field, "asc"));
        break;
      }
      case "end value for objects with geometry": {
        result.push(getBaseColumnTemplateFields(object, field, "asc"));
        break;
      }
      case "file upload": {
        result.push(getBaseColumnTemplateFields(object, field, "asc"));
        break;
      }
      case "traffic_safety_requirements": {
        result.push(getBaseColumnTemplateFields(object, field, "asc"));
        break;
      }

      default: {
        result.push({
          headerName: field?.label || field,
          field: field,
          editable: false,
          sort: "asc",
        });
        break;
      }
    }
  }
  return { result, nested };
};
