import React from "react";
import { Icon } from "antd";

const PlayIconSVG = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M8 5V19L19 12L8 5Z" fill="currentColor" />
  </svg>
);

export const PlayIcon = props => <Icon component={PlayIconSVG} {...props} />;
