import React from "react";
import { Icon } from "antd";

const HeadphonesIconSVG = () => (
  <svg
    width="18"
    height="22"
    viewBox="0 0 18 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9 0C6.61305 0 4.32387 0.948212 2.63604 2.63604C0.948211 4.32387 0 6.61305 0 9V16C0 17.66 1.34 19 3 19H6V11H2V9C2 5.13 5.13 2 9 2C12.87 2 16 5.13 16 9V11H12V19H16V20H9V22H15C16.66 22 18 20.66 18 19V9C18 6.61305 17.0518 4.32387 15.364 2.63604C13.6761 0.948212 11.3869 0 9 0Z"
      width="18px"
      height="22px"
      fill="currentColor"
    />
  </svg>
);

export const HeadphonesIcon = props => (
  <Icon component={HeadphonesIconSVG} {...props} />
);
