import {RelatedDataPrototype} from "../prototype/RelatedDataPrototype";

export class ReceptionAreas extends RelatedDataPrototype {
    constructor() {
        super({
            name: 'reception_areas',
            filterLabel: 'Зоны приема',
            urlData: '/railway_network/reception_areas/',
        });
    }
}
