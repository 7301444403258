import CustomTooltipItem from "../../../../../library/UI/components/CustomTooltip/Item/CustomTooltipItem";
import React from "react";
import _ from "underscore";
import styled from "styled-components";

const LocalMarginIcon = styled.div`
  margin-right: 17px;
`;

const LocalDescr = styled.div``;

export const prepareMenuContextItem = ({
  el,
  translateFn,
  dispatchFn,
  action,
}) => {
  const descriptionEdit = translateFn(el?.translatePath);
  const icon = el?.icon || null;
  return (
    <CustomTooltipItem
      onClick={() => {
        if (_.isFunction(action)) {
          const result = action();
          dispatchFn(result);
        } else {
          dispatchFn(action);
        }
      }}
    >
      <LocalMarginIcon>{icon}</LocalMarginIcon>
      <LocalDescr>{descriptionEdit}</LocalDescr>
    </CustomTooltipItem>
  );
};
