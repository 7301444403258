import React from "react";
import { Icon } from "antd";

const MinusIconSVG = () => (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M10.9524 10.9524H16.6667V9.04761H10.9524H9.04766H3.33337V10.9524H9.04766H10.9524Z" fill="currentColor"/>
    </svg>
);

export const MinusIcon = props => <Icon component={MinusIconSVG} {...props} />;
