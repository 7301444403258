import React from 'react';
import PropTypes from 'prop-types';
import {cropPath} from "../../../../../services/net/cropPath";
import {resolveUrl} from "../../../../../registrators/old/directoryRegistrator/directoryRegistrator";
import {useWhyDidYouUpdate} from "react-recipes";


const PathWatcher = ({
                         location,
                         inlineHistory,
                         pushInlineHistory,
                         cleanInlineHistory
                     }) => {
    useWhyDidYouUpdate('PathWatcher')
    const clearUrl = location.pathname.replace('/app/directory/', '')
    const urlParts = clearUrl.split('/');
    let flagProcessing = false
    if (urlParts[0]) {
        const resolved = resolveUrl(urlParts[0], 'urlData')
        if (inlineHistory && inlineHistory[0] && inlineHistory[0].url!==`/app/directory/${urlParts[0]}/`){
            cleanInlineHistory()
            pushInlineHistory({
                type: 'main',
                ids: null,
                urlData: resolved,
                url: `/app/directory/${urlParts[0]}/`,
                field: null,
                // parentID: null,
                // parentKey: null
            })
            flagProcessing = true
        } else if (!inlineHistory.length) {
            pushInlineHistory({
                type: 'main',
                ids: null,
                urlData: resolved,
                url: `/app/directory/${urlParts[0]}/`,
                field: null,
                // parentID: null,
                // parentKey: null
            })
            flagProcessing = true
        }
    }
    if (urlParts[1] && !flagProcessing ) {
        const id = Number.parseInt(urlParts[1])
        const resolved = cropPath(resolveUrl(urlParts[0], 'urlData'))
        if (inlineHistory && inlineHistory[0] && !inlineHistory[1] || inlineHistory[1]?.urlData!==(resolved + `${id}/`)) {
            pushInlineHistory({
                type: "mainObject",
                ids: id,
                urlData: resolved + `${id}/`,
                url: `/app/directory/${urlParts[0]}/${id}/`,
                field: null
            })

        }
    }
    return (
        <>
        </>
    );
};

PathWatcher.propTypes = {};

export default PathWatcher;
