import {axiosInstance} from "../services/net/axiosGuest";

/**
 * Методы обновления и сброса пароля
 * @type {{passwordReset(*): Promise<boolean>, refreshToken(): Promise<boolean>}}
 */
export const authApi = {
    async refreshToken() {
        const response = await axiosInstance.post("/users/token/refresh/",{
                "refresh": localStorage.getItem('token_refresh'),
                "username": localStorage.getItem('user')
            },
            {
                headers: {
                    'Content-Type': 'application/json',
                    Accept: 'application/json',

                }
            });
        if (response.data.message){
            return  false
        } else {
            localStorage.setItem("token_access", response.data.access)
            localStorage.setItem("token_refresh", response.data.refresh)
            return true;
        }

    },
    /**
     *
     * @param email валидный почтовый ящик на который будет отправлен новый пароль
     * @returns {Promise<boolean>}
     */
    async passwordReset(email) {
        const response = await axiosInstance.post('/users/password/reset/',{
            email
            },
            {
                headers: {
                    'Content-Type': 'application/json',
                    Accept: 'application/json',
                }
            }
            );
        if (response.status===200) {
            return true
        }else {
            return false
        }
    }
}
