import React from "react";
import { Icon } from "antd";

const ControlRoomIconSVG = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="currentColor">
        <path d="M17.5 13.3332V3.33317H2.49998V13.3332H17.5ZM17.5 1.6665C17.942 1.6665 18.3659 1.8421 18.6785 2.15466C18.991 2.46722 19.1666 2.89114 19.1666 3.33317V13.3332C19.1666 13.7752 18.991 14.1991 18.6785 14.5117C18.3659 14.8242 17.942 14.9998 17.5 14.9998H11.6666V16.6665H13.3333V18.3332H6.66665V16.6665H8.33331V14.9998H2.49998C2.05795 14.9998 1.63403 14.8242 1.32147 14.5117C1.00891 14.1991 0.833313 13.7752 0.833313 13.3332V3.33317C0.833313 2.40817 1.57498 1.6665 2.49998 1.6665H17.5ZM4.16665 4.99984H11.6666V9.1665H4.16665V4.99984ZM12.5 4.99984H15.8333V6.6665H12.5V4.99984ZM15.8333 7.49984V11.6665H12.5V7.49984H15.8333ZM4.16665 9.99984H7.49998V11.6665H4.16665V9.99984ZM8.33331 9.99984H11.6666V11.6665H8.33331V9.99984Z" fill="#1B3548"/>
    </svg>



);

export const ControlRoomIcon = props => (
    <Icon component={ControlRoomIconSVG} {...props} />
);
