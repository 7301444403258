import React, { useState } from "react";
import { withTheme } from "styled-components";
import PropsPasser from "../../../../../HOC/PropsPasser";
import { Button } from "antd";

const replaceTemplateStrings = (string, data, invoked) => {
  let result = string;
  const pattern = "{.+?}";
  let finished = false;
  do {
    const matches = result.match(pattern);
    const index = result.indexOf(pattern);
    const key = matches?.[0].replace("{", "").replace("}", "");
    if (matches?.[0]) {
      if (key === "value") {
        result = result.replace(matches[0], invoked);
      } else {
        result = result.replace(matches[0], invoked[key]);
      }
    }
    if (index === -1) {
      finished = true;
    } else {
      const matches = result.match(pattern);
    }
  } while (!finished);
  return result;
};

const MapSelectedObjectInfo = React.memo(
  ({
    handleCloseMapSelectedObjectInfo,
    handleOpenObjectInNewTab,
    selectedMapItem,
    reloadMapboxLayerData
  }) => {
    if (selectedMapItem?.data) {
      const [selectedModeIndex, setSelectedModeIndex] = useState(0);
      let renderResult = null;
      const maxIndex = selectedMapItem.data.length - 1;
      const handleChangeToPreviousIndex = () => {
        if (selectedModeIndex > 0) {
          setSelectedModeIndex(selectedModeIndex - 1);
        } else {
          setSelectedModeIndex(maxIndex);
        }
      };

      const handleChangeToNextIndex = () => {
        if (selectedModeIndex < maxIndex) {
          setSelectedModeIndex(selectedModeIndex + 1);
        } else {
          setSelectedModeIndex(0);
        }
      };
      const modeToDisplay = selectedMapItem.data[selectedModeIndex];
      const SelectedObjectPrototype = modeToDisplay.representationPrototype;
      renderResult = (
        <PropsPasser
          component={
            <SelectedObjectPrototype
              selectedMapItem={selectedMapItem}
              data={modeToDisplay.data}
              model={modeToDisplay.model}
              mode={modeToDisplay}
              handleCloseMapSelectedObjectInfo={
                handleCloseMapSelectedObjectInfo
              }
              handleOpenObjectInNewTab={handleOpenObjectInNewTab}
              reloadMapboxLayerData={reloadMapboxLayerData}
            />
          }
        />
      );
      let label = modeToDisplay.label;
      label = replaceTemplateStrings(
        label,
        modeToDisplay.data,
        modeToDisplay.invoked
      );

      return (
        <div style={{ height: "fit-content",   marginBlockEnd: "auto" , marginLeft:"auto"}}>
         {/* <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center"
            }}
          >
            <Button onClick={handleChangeToPreviousIndex}> {"<-"} </Button>
            <p style={{ margin: 0 }}>{label}</p>
            <Button onClick={handleChangeToNextIndex}> {"->"} </Button>
          </div>*/}
          {renderResult}
        </div>
      );
    }
    return null;
  }
);

MapSelectedObjectInfo.propTypes = {};

export default withTheme(MapSelectedObjectInfo);
