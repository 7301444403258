import {RelatedDataPrototype} from "../prototype/RelatedDataPrototype";
import {mapObjectsEnum} from "../../../../../../enums/mapObjectsEnum";

export class TrafficSegments extends RelatedDataPrototype {

    constructor() {
        super({
            name: 'trafficSegments',
            filterLabel: 'Сегменты трафика',
            urlData: '/traffic_jams/traffic_jam_segments/',
            selectedObjectName:  mapObjectsEnum.trafficSegment,
        });
    }
}
