
import {LayerSchemaPrototype} from "../prototype/LayerSchemaPrototype";
import MonitoringCompositeLayer from "../../../../../../library/layers/CompositeLayers/Core/MonitoringCompositeLayer";

export class MonitoringLayerSchema extends LayerSchemaPrototype{
    useZoomDeps = true;
    constructor() {
        super({
            layerToRender: MonitoringCompositeLayer,
            id: 'monitorting-layer',
        });
    }
}
