import React from "react";
import { Icon } from "antd";

const DockIconSVG = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
        <path d="M10 1.6665C9.33696 1.6665 8.70107 1.9299 8.23223 2.39874C7.76339 2.86758 7.5 3.50346 7.5 4.1665C7.49973 4.68371 7.65988 5.18828 7.95838 5.61066C8.25688 6.03303 8.67904 6.35243 9.16667 6.52484V7.49984H6.66667V9.1665H9.16667V16.5998C8.55 16.4915 7.91667 16.3165 7.325 16.0582C6.70833 15.7915 6.16667 15.4665 5.68333 15.0748C5.2 14.6832 4.81667 14.2582 4.53333 13.7915L5.83333 12.4998L2.5 9.99984V12.4998C2.5 13.3082 2.725 14.0665 3.18333 14.7665C3.66492 15.4961 4.28154 16.1268 5 16.6248C5.725 17.1582 6.53333 17.5748 7.4 17.8748C8.275 18.1748 9.14167 18.3332 10 18.3332C10.8583 18.3332 11.725 18.1665 12.6 17.8665C13.4667 17.5665 14.275 17.1498 15 16.6248C15.7667 16.0915 16.3583 15.4748 16.8167 14.7665C17.275 14.0665 17.5 13.3082 17.5 12.4998V9.99984L14.1667 12.4998L15.4667 13.7915C15.1833 14.2582 14.8 14.6832 14.3167 15.0748C13.8333 15.4665 13.2917 15.7915 12.675 16.0582C12.0833 16.3165 11.45 16.4915 10.8333 16.5998V9.1665H13.3333V7.49984H10.8333V6.5165C11.3196 6.34458 11.7408 6.02645 12.0392 5.60573C12.3376 5.185 12.4986 4.68229 12.5 4.1665C12.5 3.50346 12.2366 2.86758 11.7678 2.39874C11.2989 1.9299 10.663 1.6665 10 1.6665ZM10 3.33317C10.221 3.33317 10.433 3.42097 10.5893 3.57725C10.7455 3.73353 10.8333 3.94549 10.8333 4.1665C10.8333 4.38752 10.7455 4.59948 10.5893 4.75576C10.433 4.91204 10.221 4.99984 10 4.99984C9.77899 4.99984 9.56702 4.91204 9.41074 4.75576C9.25446 4.59948 9.16667 4.38752 9.16667 4.1665C9.16667 3.94549 9.25446 3.73353 9.41074 3.57725C9.56702 3.42097 9.77899 3.33317 10 3.33317Z" fill="#1B3548"/>
    </svg>



);

export const DockIcon = props => (
    <Icon component={DockIconSVG} {...props} />
);
