import React, { useRef } from "react";
import { directoryHistoryActions } from "../../../../../../reducers/directory/actions/directoryHistoryActions";
// import { startUpload } from "../../../../../../reducers/directory/actions/directoryHistoryActions";
import { connect } from "react-redux";
import { resolveUrl } from "../../../../../../registrators/old/directoryRegistrator/directoryRegistratorDirectory";
import PropsPasser from "../../../../../../HOC/PropsPasserDirectory";
import { useWhyDidYouUpdate } from "react-recipes";
import { getLastHistoryRecord } from "../../../../../../reducers/directory/directoryHistory";
import { submitObjectSaga } from "../../../../../../sagas/app/postObjectSaga";
import { initReloadLastInlineHistoryDataSaga } from "../../../../../../sagas/directory/inlineHistorySaga";
import moment from "moment";

const {
  postToInlineHistory,
  clearInlineHistory,
  revertToInlineHistoryRecordByType,
} = directoryHistoryActions;

const DirectoryObjectTemplate = (props) => {
  useWhyDidYouUpdate("DirectoryObjectTemplate", props);

  const {
    folder,
    id,
    pushInlineHistory,
    lastInlineHistory,
    cleanInlineHistory,
    revertToInlineHistoryRecordByType,
    initReloadLastInlineHistoryDataSaga,
    startPostWithSaga,
    data,
    model,
    field,
  } = props;

  const formRef = useRef(null);

  const initClose = (ref) => {
    revertToInlineHistoryRecordByType("lastOfType", "main");
  };

  const initSubmitForm = () => {
    formRef.current.props.onSubmit();
  };

  const handleSubmitForm = (submitData) => {
    let { manufacture_date, mobile_phone, work_phone, snils } = submitData;
    submitData.manufacture_date =
      manufacture_date && moment(manufacture_date).format("YYYY-MM-DD");
    submitData.mobile_phone =
      mobile_phone && mobile_phone.replace(/[^0-9.]/g, "");
    submitData.work_phone = work_phone && work_phone.replace(/[^0-9.]/g, "");
    submitData.snils = snils && snils.replace(/[^0-9.]/g, "");
    startPostWithSaga(lastInlineHistory.urlData, submitData, data, () =>
      initReloadLastInlineHistoryDataSaga(lastInlineHistory)
    );
  };
  //

  const goToInline = (event) => {
    const relatedModel = model.scheme[event.key];
    if (relatedModel?.type === "inline") {
      pushInlineHistory({
        type: "inline",
        urlData: relatedModel.url,
        url: lastInlineHistory.url + `${event.key}/`,
        field: event.key,
      });
    }
  };

  const ObjectRendererPrototype = resolveUrl(folder, "objectPanel");

  const ObjectRenderer = (
    <PropsPasser
      component={
        <ObjectRendererPrototype
          data={data}
          model={model}
          goToInline={goToInline}
          initClose={initClose}
          initSubmitForm={initSubmitForm}
          handleSubmitForm={handleSubmitForm}
          referenceToForm={formRef}
          lastInlineFieldName={field}
        />
      }
    />
  );

  return <div>{ObjectRenderer}</div>;
};

const mapStateToProps = (state) => ({
  //DirectoryObjectTemplate should have own lastInlineHistory
  lastInlineHistory: getLastHistoryRecord(state),
});

const mapDispatchToProps = (dispatch) => ({
  pushInlineHistory: (newHistoryItem) =>
    dispatch(postToInlineHistory(newHistoryItem)),
  cleanInlineHistory: () => dispatch(clearInlineHistory()),
  // startUpload: (url) => dispatch(startUpload(url)),
  startPostWithSaga: (url, data, initial, redrawDispatch) =>
    dispatch(submitObjectSaga(url, data, initial, redrawDispatch)),
  initReloadLastInlineHistoryDataSaga: (lastInlineHistory) =>
    dispatch(initReloadLastInlineHistoryDataSaga(lastInlineHistory)),
  revertToInlineHistoryRecordByType: (type, typeName) =>
    dispatch(revertToInlineHistoryRecordByType(type, typeName)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DirectoryObjectTemplate);
