import React from "react";
import PropTypes from "prop-types";
import LayerMainPanel from "./LayerMainPanel";
import { connect } from "react-redux";

const LayerMainPanelContainer = props => {
  return <LayerMainPanel {...props} />;
};

LayerMainPanelContainer.propTypes = {};

const mapStateToProps = state => {
  return {
    isGalleryVisible: state.mapPanels.layersGalleryVisible,
  };
};

const mapDispatchToProps = dispatch => {
  return {};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LayerMainPanelContainer);
