import { call, put, takeEvery } from "redux-saga/effects";
import { initGetRelatedDataForLayer } from "./getRelatedDataForLayerSaga";
import { dataAccessMethodsEnum } from "../../enums/dataAccessMethodsEnum";
import _ from "underscore";
import { addMapboxData } from "../../reducers/map/actions/mapboxDataActions";
import { addLayer } from "../../reducers/map/actions/mapboxLayersActions";
import { getFromURL } from "../../api/getFromURL";

export const INIT_ADD_LAYER_TO_MAP_SAGA = "INIT_ADD_LAYER_TO_MAP_SAGA";

const dispatch = put.resolve;

const consoleErrorOfCustomMainDataGetter = (Layer, dataAccessMethod) => {
  console.group("DEV: Main data access error ");
  console.error(
    `Layer -> ${
      Layer.constructor.name
    } ->have ${dataAccessMethod} data upload method in ${
      Layer.getMainData()?.constructor.name
    } main data block but doesn't overrides getCustomMainDataDownload() method`
  );
  console.error(Layer);
  console.error(Layer.getMainData());
  console.groupEnd();
};

const consoleErrorOfCustomRelatedDataGetter = (Layer, dataAccessMethod) => {
  console.group("DEV: Main data access error ");
  console.error(
    `Layer -> ${
      Layer.constructor.name
    } ->have ${dataAccessMethod} data upload method in ${
      Layer.getRelatedData()?.constructor.name
    } related data block but doesn't overrides getCustomRelatedDataDownload() method`
  );
  console.error(Layer);
  console.error(Layer.getMainData());
  console.groupEnd();
};

function* getMainDataForLayer(Layer) {
  const MainDataClass = Layer.getMainData();
  const {
    httpGet,
    custom,
    httpPost,
    httpGetAll,
    websocket,
  } = dataAccessMethodsEnum;
  let data = [];
  if (MainDataClass) {
    const dataAccessMethod = MainDataClass.getDataAccessMethod();
    switch (dataAccessMethod) {
      case custom:
      case httpPost:
      case websocket: {
        if (
          MainDataClass.getCustomMainDataDownload &&
          _.isFunction(MainDataClass.getCustomMainDataDownload)
        ) {
          if (MainDataClass?.getIsReturnsAC()) {
            const action = MainDataClass.getCustomMainDataDownload(dispatch);
            yield put(action);
          } else {
            data = MainDataClass.getCustomMainDataDownload();
            yield put(addMapboxData(Layer.getName(), data));
          }
        } else {
          consoleErrorOfCustomMainDataGetter(Layer, dataAccessMethod);
        }
        break;
      }
      case httpGet: {
        const urlData = MainDataClass.getUrlData();
        data = yield call(() => getFromURL.getDataFromURL(urlData));
        yield put(addMapboxData(Layer.getName(), data));
        break;
      }
      case httpGetAll: {
        const urlData = MainDataClass.getUrlData();
        data = yield call(() => getFromURL.getAll(urlData));
        yield put(addMapboxData(Layer.getName(), data));
        break;
      }
    }
  }
}

export const initAddLayerToMapSaga = (Layer) => ({
  type: INIT_ADD_LAYER_TO_MAP_SAGA,
  payload: {
    Layer,
  },
});

function* addLayerToMap(action) {
  yield put({ type: "RECEIVED_PUSH_TO_MAP_HISTORY_ATTEMPT" });
  const { Layer } = action.payload;
  yield call(() => getMainDataForLayer(Layer));
  yield put(initGetRelatedDataForLayer(Layer));
  yield put(addLayer(Layer));
}

//ready
export function* watchInitializeAddLayerToMapSaga() {
  yield takeEvery(INIT_ADD_LAYER_TO_MAP_SAGA, addLayerToMap);
}
