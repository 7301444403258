import React from "react";
import PropTypes from "prop-types";
import { Styled } from "./SelectableItem.styled";
import { Checkbox } from "../Checkbox/Checkbox";
import { withTheme } from "styled-components";
import { Button } from "../Button/Button";
import { DeleteIcon } from "../../../icons/actionIcons/DeleteIcon";
import { OptionIcon } from "../../../icons/actionIcons/OptionIcon";

const {
  LocalMainWrapper,
  LocalStatusBarLine,
  LocalInnerSection,
  LocalContentWrap,
} = Styled;

export const SelectableItem = ({
  children,
  status,
  isSelected,
  onSelectChange,
  onDelete,
  theme,
  isStatusBarLine,
  onMoved,
  value,
}) => {
  return (
    <LocalMainWrapper>
      <LocalInnerSection>
        <Checkbox
          checked={isSelected}
          checkboxColor={theme?.colors?.primary}
          onChange={onSelectChange}
          checkboxSizeInPx={24}
          value={value}
        />
        {onMoved && <Button type={"unboundedDanger"} icon={<OptionIcon />} />}
        {isStatusBarLine && <LocalStatusBarLine status={status} />}

        <LocalContentWrap>{children}</LocalContentWrap>
      </LocalInnerSection>
      {onDelete && (
        <Button
          type={"unboundedDanger"}
          onClick={onDelete}
          icon={<DeleteIcon />}
          p={0}
          iconSize={{ width: "20px", height: "20px" }}
        />
      )}
    </LocalMainWrapper>
  );
};

export default withTheme(SelectableItem);

SelectableItem.propTypes = {
  children: PropTypes.any,
  isSelected: PropTypes.bool,
  onDelete: PropTypes.func,
  onSelectChange: PropTypes.func,
  status: PropTypes.oneOf(["danger", "middle", "successful"]),
  theme: PropTypes.any,
  title: PropTypes.string,
};

SelectableItem.defaultProps = {
  isSelected: false,
  title: "24px",
  isStatusBarLine: true,
};
