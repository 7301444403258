import { alertStatusesEnum } from "../../enums/alertStatusesEnum";
import { alertTypesEnum } from "../../enums/alertTypesEnum";
import { promptTypesEnum } from "../../enums/promptTypesEnum";
import { promptStatusesEnum } from "../../enums/promptStatusesEnum";

export const SET_ALERT_DATA = "SET_ALERT_DATA";
export const CLEAR_ALERT_DATA = "CLEAR_ALERT_DATA";
export const SET_NOTIFICATION_DATA = "SET_NOTIFICATION_DATA";
export const CLEAR_NOTIFICATION_DATA = "CLEAR_NOTIFICATION_DATA";

const initialAlertState = {
  status: alertStatusesEnum.disabled,
  type: alertTypesEnum.info,
  title: "",
  message: "",
};

const initialNotificationState = {
  status: promptStatusesEnum.disabled,
  type: promptTypesEnum.default,
  title: "",
  message: "",
};

const initialState = {
  alert: initialAlertState,
  notification: initialNotificationState,
};

export default function app(state = initialState, action) {
  switch (action.type) {
    case SET_ALERT_DATA: {
      const { status, type, title, message, closureId } = action.payload;
      return { ...state, alert: { status, type, title, message, closureId } };
    }
    case CLEAR_ALERT_DATA: {
      return { ...state, alert: initialAlertState };
    }
    case SET_NOTIFICATION_DATA: {
      const { status, type, title, message } = action.payload;
      return { ...state, notification: { status, type, title, message } };
    }
    case CLEAR_NOTIFICATION_DATA: {
      return { ...state, notification: initialNotificationState };
    }
    default:
      return state;
  }
}
