import React from "react";
import { Icon } from "antd";

const ColumnIconSVG = () => (
  <svg
    width="12"
    height="20"
    viewBox="0 0 12 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2 0H10C10.5304 0 11.0391 0.210714 11.4142 0.585786C11.7893 0.960859 12 1.46957 12 2V18C12 18.5304 11.7893 19.0391 11.4142 19.4142C11.0391 19.7893 10.5304 20 10 20H2C1.46957 20 0.960859 19.7893 0.585786 19.4142C0.210714 19.0391 0 18.5304 0 18V2C0 1.46957 0.210714 0.960859 0.585786 0.585786C0.960859 0.210714 1.46957 0 2 0ZM2 8V12H10V8H2ZM2 14V18H10V14H2ZM2 2V6H10V2H2Z"
      fill="currentColor"
    />
  </svg>
);

export const ColumnIcon = props => (
  <Icon component={ColumnIconSVG} {...props} />
);
