import React, { useState } from "react";
import { connect, useSelector } from "react-redux";
import { userLogoutFetch } from "../../../../reducers/app/actions/authActions";
import { Button } from "antd";
import { useTranslation } from "react-i18next";

const Logout = props => {
  const username = localStorage.getItem("user");
  const { t } = useTranslation("common");

  const handleClick = event => {
    props.userLogoutFetch();
    window.location.reload();
  };
  return (
    <>
      {username && (
        <div
          style={{
            display: "flex",
            justifyContent: "center"
          }}
          onClick={handleClick}
        >
          {t("guest.login.logOut")}
        </div>
      )}
    </>
  );
};

const mapStateToProps = state => ({
  user: state.auth.user
});

const mapDispatchToProps = dispatch => ({
  userLogoutFetch: userInfo => dispatch(userLogoutFetch(userInfo))
});

export default connect(mapStateToProps, mapDispatchToProps)(Logout);
