import React, {Suspense} from 'react';
import PropTypes from 'prop-types';
import {connect, useSelector} from "react-redux";
import {getSelectedMapboxLayer} from "../../../../../reducers/map/mapboxLayers";
import GlobalLoader from "../../../../../library/UI/components/GlobalLoader/GlobalLoader";

const LayerDataAccessorBuilder = (props) => {
    const SelectedMapLayer = useSelector(state=>getSelectedMapboxLayer(state))

    let DataAccessor = null
    if (SelectedMapLayer){
        const resolvedDataAccessor = SelectedMapLayer.getDataAccessor()
        if (resolvedDataAccessor?.dataAccessComponent){
            DataAccessor = resolvedDataAccessor.dataAccessComponent
        }
    }
    return (
        <>
            {DataAccessor &&
            <Suspense fallback={<GlobalLoader show={true}/>}>
                <DataAccessor selectedLayerName={SelectedMapLayer?.getName()}/>
            </Suspense>
            }
        </>
    );
};

export default LayerDataAccessorBuilder;
