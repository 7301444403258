import PropTypes from "prop-types";
import React, {useContext, useMemo, useRef} from "react";
import DeckGL, {TextLayer} from "deck.gl";
import _ from "underscore";
import {EditableGeoJsonLayer} from "@nebula.gl/layers";
import {ModifyMode} from "@nebula.gl/edit-modes";
import wkt from "wkt";
import nearestPointOnLine from "@turf/nearest-point-on-line";
import turfLineSlice from "@turf/line-slice";
import {defineDrawKeyOnModel} from "../../../../services/map/defineDrawMapOnModel";
import {resolveEditableObjectDescription} from "../../../../registrators/old/editableObjectsRegistrator/editableObjectsRegistrator";
import {resolveSelectedObjectDescription} from "../../../../registrators/old/mapSelectObjectRegistrator/mapSelectObjectRegistrator";
import {StaticMap} from "react-map-gl";
import {TileLayer} from "@deck.gl/geo-layers";
import {BitmapLayer} from "@deck.gl/layers";
import {LayerPrototype} from "../../../../registrators/map/layers/description/prototype/LayerPrototype";
import {generateUTF8CharacterSet} from "../../../../services/map/generateUTF8CharacterSet";
import {MapModuleContext} from "../moduleContext/withMapModuleContext";

//Ключ Default public token Mapbox
const MAPBOX_TOKEN =
    "pk.eyJ1Ijoic21hcnQtdHJhbnNwb3J0IiwiYSI6ImNrZHNydmZ4ZTFxcGoyd21xOWhuNWdhb3IifQ.fE9c8_eZMFNSYUY5sPpX_g"; // eslint-disable-line

//Индексы выбранных объектов геометрии
let selectedFeatureIndexes = [];

let applyVisibilityFilters = (uploadedData, Layer) => {
    const result = {...uploadedData};
    Layer.getRelatedData()
        .filter((RelatedData) => RelatedData.isHidden)
        .forEach((RelatedData) => {
            const name = RelatedData.getName();
            result[name] = [];
        });
    return result;
};


/**
 *
 * @param updateViewState
 * @param viewState
 * @param style
 * @param mapboxTime
 * @param layersData
 * @param enabledLayers
 * @param onClickItemHandler
 * @param editableLayers
 * @param replaceDrawDataForStandaloneObject
 * @param selectedObject
 * @param mapRulerMode
 * @param onMapClickHandler
 * @param standaloneEditableObject
 * @param rasterTileStyle
 * @param rasterTileEnabled
 * @param props
 * @returns {React.FunctionComponent} ( DeckGL & StaticMap)
 * @constructor
 */
const Deck = ({
                  updateViewState,
                  viewState,
                  style,
                  mapboxTime,
                  layersData,
                  enabledLayers,
                  onClickItemHandler,
                  editableLayers,
                  replaceDrawDataForStandaloneObject,
                  selectedObject,
                  mapRulerMode,
                  onMapClickHandler,
                  standaloneEditableObject,
                  rasterTileStyle,
                  rasterTileEnabled,
              }) => {

    /**
     * При флаге слоя hideOtherLayers спрятать слои переменной layer
     * @type {boolean}
     * @see layers
     */
    let hideOtherBySingleLayer = false;
    /**
     * Переменная отвечающая за рендер дополнительных слоев выбранного объеката
     * @type {(null || CompositeLayer)}
     */
    let singleLayer = null;
    /**
     *  Переменная отвечающая за рендер слоев режима редактирования (по умолчанию EditableGeoJsonLayer)
     * @type {(null || CompositeLayer)}
     * @see EditableGeoJsonLayer
     */
    let editable = null;
    /**
     * Фикс изменений библиотеки символов.
     * @type {Array<char>}
     */
    const characterSet = useMemo(() => generateUTF8CharacterSet(), []);

    const mapsContext = useContext(MapModuleContext);
    /**
     * Генерация ссылок на ноды оверлееи карты
     * @type {(null || DOM.node)}
     */
    const deckRef = useRef(null);
    /**
     * Генерация ссылок на ноды карту(подложка)
     * @type {(null || DOM.node)}
     */
    const mapRef = useRef(null);
    /**
     *  Переменная отвечающая за рендер слоев измерений
     * @type {(null || CompositeLayer)}
     * @see EditableGeoJsonLayer
     */
    let rulers = null;
    /**
     *  Переменная отвечающая за рендер растровых подложек
     * @type {(null || CompositeLayer)}
     * @see EditableGeoJsonLayer
     */
    let additionalTileLayer = null;
    /**
     *  Переменная отвечающая за рендер основной картографической информации включенных слоев
     * @type {(null || CompositeLayer)}
     * @see EditableGeoJsonLayer
     */
    let layers = [];

    /**
     * При прорисовке карты присваивает ссылки на оверлей и канвас карты в контекст MapModuleContext
     * @return {void}
     * @see MapModuleContext
     */
    const onMapLoad = () => {
        const mapCanvasRef = mapRef.current.getMap();
        mapsContext.deckRef = deckRef.current.deck;
        mapsContext.mapRef = mapCanvasRef;
    };

    //Проверка на свойство hideOtherLayers у выбранного объекта и построение композитного слоя, если он имеется
    if (selectedObject && !_.isEmpty(selectedObject)) {
        const ResolvedSingleLayerPrototype = resolveSelectedObjectDescription(
            selectedObject.type,
            "mapLayerPrototype"
        );
        if (
            resolveSelectedObjectDescription(selectedObject.type, "hideOtherLayers")
        ) {
            hideOtherBySingleLayer = true;
        }
        if (ResolvedSingleLayerPrototype) {
            singleLayer = new ResolvedSingleLayerPrototype({
                data: selectedObject.data,
                invoked: selectedObject.selectedObject,
            });
        }
    }

    //Отрисовка включенных слоев, итератор должен быть классом и быть зарегестрирован
    for (let Layer of enabledLayers) {
        /**
         * @type {(null || Array<LayerSettingsPrototype>)}
         * @see LayerSettingsPrototype
         */
        //Если слой отмечен как hidden или присутсвует слой с параметром hideOtherLayers скрываем слой
        if (Layer.hidden || hideOtherBySingleLayer) continue;

        let customSettings;
        if (Layer instanceof LayerPrototype) {
            //Получение доступных настроек слоя
            customSettings = Layer?.getCustomSettings();
        }
        //применение настроек слоя из прототипа слоя
        const settings = {};
        for (let it in customSettings) {
            const record = customSettings[it];
            settings[record.keyForDeck] = record.currentValue;
        }
        const appliedSettings = {};
        //Получение шаблонов композитных слоев схемы
        const template = Layer.getLayerSchema();
        for (let it of template) {
            //Подготовка к рендеру слоя из шаблона
            const Layer_proto = it.layerToRender;
            let layerProps = _.clone(it);
            //После получения типа слоя удаляем запись на его тип для деструктуризации остальных пропсов слоя
            delete layerProps.type;
            //Если есть загруженные данные, то строим слои, иначе ждем их получения
            if (layersData) {
                //Данные для передачи в слой
                let preparedData = layersData[Layer.getName()]?.data;
                if (layerProps.dataWrapper)
                    //Legacy, используется для мониторинга и предстваления данных в массив, т.к. слои по умолчанию должны в data получить []
                    preparedData = layerProps.dataWrapper(preparedData);
                //если слой ссылается на исторические данные, то передаем в слой записи из timeline из редакса (Трекинги)
                if (layerProps?.additionalParameters?.useTimePlayerForLayer) {
                    appliedSettings.getCurrentTimeStamp = mapboxTime.currentTimeStamp;
                    appliedSettings.trailLength = mapboxTime.currentTimeStamp;
                    appliedSettings.currentTime = mapboxTime.currentTime;
                }
                //если слой ссылается на зум карты, то передаем в слой записи зума карты из редакса (Кластеры)
                appliedSettings.zoom = viewState.zoom;

                const relatedData = applyVisibilityFilters(layersData[Layer.getName()]?.relatedData, Layer);

                //Создание нового экземпляра слоя со всеми параметрами и настройками
                const displayed = new Layer_proto({
                    ...layerProps,
                    ...appliedSettings,
                    data: preparedData,
                    onClickHandler: onClickItemHandler,
                    relatedData: relatedData,
                    editModeActivated: !!editableLayers?.enableDraw,
                    selectedObject: selectedObject,
                    ...settings,
                });
                layers.push(displayed);
            }
        }
    }

    //Подготовка редактируемого режима
    if (standaloneEditableObject) {
        let snapGeometry = null;
        //Загрузка привязываемой геометрии слоя
        if (
            //Legacy, ожно получить из инстанса класса
            resolveEditableObjectDescription(
                standaloneEditableObject.selectedInstance,
                "parent"
            )?.snapChildGeometry
        ) {
            //Выбор режима редактирования в зависимотси от типа геометрии
            const parentGeometryField = defineDrawKeyOnModel(
                standaloneEditableObject.parentModel
            );
            snapGeometry = wkt.parse(
                standaloneEditableObject.parentData[parentGeometryField]
            );
        }
        //Исходная геометрия объекта
        const geo = standaloneEditableObject.drawData?.features?.[0]?.geometry;
        const hasGeo = !_.isEmpty(geo);
        let definedMode;
        //Если есть геометрия включаем нужный режим геометрии
        if (!hasGeo) {
            definedMode = standaloneEditableObject.editMode;
            standaloneEditableObject.drawData.features = [];
        } else {
            selectedFeatureIndexes = [0];
            definedMode = ModifyMode;
        }
        editable = new EditableGeoJsonLayer({
            id: "geojson-layer",
            mode: definedMode,
            data: standaloneEditableObject.drawData,
            selectedFeatureIndexes,
            onEdit: (data) => {
                const oldCopy = {...standaloneEditableObject.drawData};
                if (data.editType === "addTentativePosition") {
                    if (snapGeometry) {
                    }
                }
                if (data.editType === "movePosition") {
                    const newGeometry = data.updatedData.features[data.updatedData.features.length - 1];
                    if (snapGeometry && newGeometry?.geometry?.type === "Point") {
                        const closest = nearestPointOnLine(snapGeometry, newGeometry);
                        oldCopy.features = [closest];
                    }

                    if (snapGeometry && newGeometry.geometry.type === "LineString") {
                        const coords = newGeometry.geometry.coordinates;
                        for (let index in coords) {
                            const point = nearestPointOnLine(snapGeometry, coords[index]);
                            newGeometry.geometry.coordinates[index] =
                                point.geometry.coordinates;
                        }
                        oldCopy.features = [newGeometry];
                    } else {
                        oldCopy.features = [newGeometry];
                    }
                    replaceDrawDataForStandaloneObject(oldCopy);
                    return;
                }

                if (data.editType === "addFeature") {
                    const newGeometry =
                        data.updatedData.features[data.updatedData.features.length - 1];
                    if (snapGeometry && newGeometry.geometry.type === "Point") {
                        const closest = nearestPointOnLine(snapGeometry, newGeometry);
                        oldCopy.features = [closest];
                    } else if (
                        snapGeometry &&
                        newGeometry?.geometry?.type === "LineString"
                    ) {
                        const coords = newGeometry.geometry.coordinates;
                        const firstPoint = coords[0];
                        const lastPoint = coords[coords.length - 1];
                        const sliced = turfLineSlice(firstPoint, lastPoint, snapGeometry);
                        oldCopy.features = [sliced];
                    } else {
                        oldCopy.features = [newGeometry];
                    }
                    replaceDrawDataForStandaloneObject(oldCopy);
                    return;
                }

                if (data.editType !== "addFeature") {
                    const oldCopy = {...standaloneEditableObject.drawData};
                    const newGeometry = data.updatedData.features[data.updatedData.features.length - 1];
                    if (newGeometry?.geometry?.type === "Point" && standaloneEditableObject.selectedInstance === "road_sign") {
                        oldCopy.features = [newGeometry];
                    } else if (snapGeometry && newGeometry?.geometry?.type === "Point"){
                        const closest = nearestPointOnLine(snapGeometry, newGeometry);
                        oldCopy.features = [closest];
                    } else {
                        oldCopy.features = [newGeometry];
                    }

                    replaceDrawDataForStandaloneObject(oldCopy);
                }
            },
        });
    }

    //Подключении слоя измерений
    if (mapRulerMode) {
        rulers = new EditableGeoJsonLayer({
            id: "ruler-Layer",
            data: null,
            mode: mapRulerMode,
        });
    }

    //Подключении слоя растровых подложек
    if (rasterTileEnabled) {
        additionalTileLayer = new TileLayer({
            data: rasterTileStyle,

            minZoom: 0,
            maxZoom: 19,
            tileSize: 256,

            renderSubLayers: (props) => {
                const {
                    bbox: {west, south, east, north},
                } = props.tile;

                return new BitmapLayer(props, {
                    data: null,
                    image: props.data,
                    bounds: [west, south, east, north],
                });
            },
        });
    }
    //Фикс бага с character set
    const initialCharLayer = new TextLayer({
        characterSet: characterSet,
    });

    return (
        <DeckGL
            controller
            ref={deckRef}
            layers={[
                initialCharLayer,
                additionalTileLayer,
                ...layers,
                singleLayer,
                editable,
                rulers,
            ]}
            viewState={viewState}
            onViewStateChange={updateViewState}
            onClick={onMapClickHandler}
            glOptions={{preserveDrawingBuffer: true}}
        >
            <StaticMap
                mapboxApiAccessToken={MAPBOX_TOKEN}
                mapStyle={style}
                ref={mapRef}
                onLoad={onMapLoad}
                reuseMaps
                preserveDrawingBuffer={true}
            />
        </DeckGL>
    );
};

export default Deck;

Deck.propTypes = {
    data: PropTypes.any,
    drawData: PropTypes.any,
    editableLayers: PropTypes.any,
    enabledLayers: PropTypes.array,
    handleSaveGeometry: PropTypes.func,
    layersData: PropTypes.object,
    layersHistory: PropTypes.array,
    mapRulerMode: PropTypes.any,
    mapboxTime: PropTypes.object,
    onClickItemHandler: PropTypes.func,
    onMapClickHandler: PropTypes.func,
    rasterTileEnabled: PropTypes.bool,
    rasterTileStyle: PropTypes.string,
    replaceDrawDataForStandaloneObject: PropTypes.func,
    replaceGeometryData: PropTypes.func,
    replaceGeometryDataAndSave: PropTypes.func,
    selectedObject: PropTypes.object,
    standaloneEditableObject: PropTypes.any,
    style: PropTypes.string,
    tile: PropTypes.string.isRequired,
    updateViewState: PropTypes.func,
    viewState: PropTypes.object,
};
