import { RelatedDataPrototype } from "../prototype/RelatedDataPrototype";
import { mapObjectsEnum } from "../../../../../../enums/mapObjectsEnum";
import { dataAccessMethodsEnum } from "../../../../../../enums/dataAccessMethodsEnum";

export class RoadSigns extends RelatedDataPrototype {
  constructor() {
    super({
      name: "road_sign",
      filterLabel: 'Знаки',
      urlData: "/layer_roads/signs-segments/",
      selectedObjectName: mapObjectsEnum.roadSign,
      // dataAccessMethod: dataAccessMethodsEnum.custom // нужно было для проброса даты в запрос
    });
  }
}
