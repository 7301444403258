export const SET_AUTO_CLOSURE_ID = "SET_AUTO_CLOSURE_ID";
export const CLEAR_AUTO_CLOSURE_ID = "CLEAR_AUTO_CLOSURE_ID";

const initialState = {};


const mapAutoCreateDetour = (state = initialState, action) => {
  switch (action.type) {
    case CLEAR_AUTO_CLOSURE_ID: {
      return initialState;
    }
    case SET_AUTO_CLOSURE_ID: {
      const {closureId} = action.payload;
      return {
        ...state,
        closureId
      }
    }
    default:
      return state;
  }
};

export default mapAutoCreateDetour;
