import React, { useEffect, useState } from "react";
import { Styled } from "./DataGRZ.styled";
import SelectableItem from "../../../../../../../../../../library/UI/components/SelectableItem/SelectableItem";
import RepresentationCheckedItem from "../../../../../parts/checkedWidget/RepresentationCheckedItem";
import useGlobal from "../../../../../store";
import { getFromURL } from "../../../../../../../../../../api/getFromURL";

const { LocalSelectWrapper, LocalStyleValue } = Styled;

const DataGRZContainer = ({ data }) => {
  const [globalState, globalActions] = useGlobal();
  const [checkedItems, setCheckedItems] = useState([]);

  useEffect(() => {
    (async () => {
      const response = await getFromURL.getDataFromURL("/common/projects/");
      const reports = await response;
      const data = reports.results.map((el) => {
        return {
          id: el.id,
          value: el.name,
          isChecked: false,
        };
      });
      setCheckedItems(data);
    })();
  }, []);

  const searchCheckedItem = () => {
    const isCheckedAllItem = checkedItems.filter(
      (elem) => elem.isChecked === true
    );
    const selectValues = isCheckedAllItem.map((el) => el.id);
    globalActions.actions.grzActions(selectValues);
  };

  const handleAllChecked = (event) => {
    let selects = checkedItems;
    selects.map((selects) => (selects.isChecked = event.target.checked));
    setCheckedItems([...selects]);
    searchCheckedItem();
  };

  const handleCheckElement = (event) => {
    let selects = checkedItems;
    selects.forEach((selects) => {
      if (selects.value === event.target.value)
        selects.isChecked = event.target.checked;
    });
    setCheckedItems([...selects]);
    searchCheckedItem();
  };

  const groupItems = checkedItems.map((item, index) => {
    return (
      <LocalSelectWrapper isChecked={item.isChecked} key={index}>
        <SelectableItem
          isStatusBarLine={false}
          isChecked={item.isChecked}
          onSelectChange={handleCheckElement}
          isSelected={item.isChecked}
          value={item.value}
          onMoved
        >
          <LocalStyleValue>{item.value}</LocalStyleValue>
        </SelectableItem>
      </LocalSelectWrapper>
    );
  });

  return (
    <RepresentationCheckedItem
      // search={(val) => search(val)}
      groupItems={groupItems}
      handleAllChecked={handleAllChecked}
    />
  );
};

export default DataGRZContainer;
