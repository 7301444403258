import React from "react";
import { Icon } from "antd";

const BlockadesIconSVG = () => (
    <svg width="18" height="16" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0.666687 0.5H17.3334V8.83333H14V15.5H12.3334V8.83333H5.66669V15.5H4.00002V8.83333H0.666687V0.5ZM14.8084 7.16667L15.6667 6.30833V3.95833L12.4584 7.16667H14.8084ZM10.1 7.16667L15.1 2.16667H12.75L7.75002 7.16667H10.1ZM5.38335 7.16667L10.3834 2.16667H8.02502L3.02502 7.16667H5.38335ZM3.31669 2.16667L2.33335 3.15V5.5L5.66669 2.16667H3.31669Z" fill="#1B3548"/>
    </svg>



);

export const BlockadesIcon = props => (
    <Icon component={BlockadesIconSVG} {...props} />
);
