import mapboxView from "./mapboxView";

export const SET_CURRENT_MAP_MODE = 'SET_CURRENT_MAP_MODE'
export const REVERT_TO_DEFAULT_MAP_MODE = 'REVERT_TO_DEFAULT_MAP_MODE'

const initialState = {
    currentMode: 'display'
}

const mapMode = (state = initialState, action) => {
    switch (action.type) {
        case SET_CURRENT_MAP_MODE: {
            return {
                ...state, currentMode: action?.payload?.modeType
            }
        }
        case REVERT_TO_DEFAULT_MAP_MODE: {
            return initialState
        }
        default: {
            return state
        }
    }
}

export default mapMode