import React, {useRef } from "react";
import {Styled} from "./LayerSettings.styled";
import SettingsTypeSwitcherContainer from "./SettingsTypeSwitcher/SettingsTypeSwitcherContainer";
import LayerSettingsContentContainer from "./LayerSettingsContent/LayerSettingsContentContainer";
import {baseStyles} from "../../../../../../../../stylesRegistrator";
import {Button} from "../../../../../../../../../library/UI/components/Button/Button";
import {MenuIcon} from "../../../../../../../../../library/icons/MenuIcon";
import {RefreshIcon} from "../../../../../../../../../library/icons/actionIcons/RefreshIcon";


const {
    BaseMapLayerPanelWrapperSecondary,
    BaseMapPanelTopPart,
    BaseMapPanelHeader,
    BaseMapPanelPrimaryTitle,
    BaseMapPanelItem
} = baseStyles.modules.map;

const {SettingsControlsWrapper } = Styled;

const LayerSettings = ({toggleLayerSettingsVisibility, handleRemoveLayer, item, handleDeleteObject, revertToDefaultLayerSettings}) => {
    const iconSize = useRef({width: "20px", height: "20px"});
    return (
        <>
            <BaseMapLayerPanelWrapperSecondary>
                <BaseMapPanelHeader>
                    <BaseMapPanelTopPart>
                        <Button
                            onClick={toggleLayerSettingsVisibility}
                            p={"0"}
                            type={"unbounded"}
                            icon={<MenuIcon key={'icon'}/>}
                        />
                        <BaseMapPanelPrimaryTitle>Настройки слоя</BaseMapPanelPrimaryTitle>
                        <Button
                            p={"none"}
                            type={"unbounded"}
                            icon={<RefreshIcon/>}
                            iconSize={iconSize.current}
                            onClick={() => revertToDefaultLayerSettings(item.name)}
                        />
                    </BaseMapPanelTopPart>
                    <SettingsTypeSwitcherContainer/>
                </BaseMapPanelHeader>
                <BaseMapPanelItem>
                    <SettingsControlsWrapper>
                        <LayerSettingsContentContainer/>
                    </SettingsControlsWrapper>
                </BaseMapPanelItem>
            </BaseMapLayerPanelWrapperSecondary>
        </>
    );
};

export default LayerSettings;
