import {useEffect, useState} from "react";

//setInputValue should be fn setValue from useContext/react-form-hook
export function useBaseInputPlaceholderControl(fieldVal, object, setInputValue) {
    const [placeholderState, setPlaceholderState] = useState(false);
    const [initialValue, setInitialValue] = useState(undefined);

    if (object && object[fieldVal] && initialValue !== object[fieldVal]) {
        setInitialValue(object[fieldVal]);
    }

    useEffect(() => {
        if (initialValue) {
            setInputValue(fieldVal, initialValue);
            setPlaceholderState(true);
        }
    }, [initialValue]);

    return {
        placeholderState,
        setInitialValue,
        initialValue,
        setPlaceholderState
    }
}