import {RelatedDataPrototype} from "../prototype/RelatedDataPrototype";
import {mapObjectsEnum} from "../../../../../../enums/mapObjectsEnum";

export class Segments extends RelatedDataPrototype {

    constructor() {
        super({
            name: 'segments',
            filterLabel: 'Геометрия',
            urlData: '/layer_roads/segments/',
            selectedObjectName:  mapObjectsEnum.roadSegment,
        });
    }
}
