import React from "react";
import ObjectPanelSider from "./ObjectPanelSider";
import { useLocation } from "react-router-dom";
import { connect } from "react-redux";
import { directoryHistoryActions } from "../../../../reducers/directory/actions/directoryHistoryActions";
import { getLastHistoryRecord } from "../../../../reducers/directory/directoryHistory";

const { selectRowForDirectoryHistory } = directoryHistoryActions;

const ObjectPanelSiderContainer = React.forwardRef((props, ref) => {
  const {
    breadcrumbText,
    setSelectedOption,
    schema,
    initClose,
    goToInline,
    initSubmitForm,
    lastInlineFieldName,
    directoryHistoryLastItemData,
  } = props;
  const location = useLocation();

  return (
    <ObjectPanelSider
      ref={ref}
      breadcrumbText={breadcrumbText}
      breadcrumbsUrl={location.pathname}
      setSelectedOption={setSelectedOption}
      initClose={initClose}
      goToInline={goToInline}
      initSubmitForm={initSubmitForm}
      schema={schema}
      lastInlineFieldName={lastInlineFieldName}
      directoryHistoryLastItemData={directoryHistoryLastItemData}
    />
  );
});

function mapStateToProps(state) {
  return {
    directoryHistoryLastItemData: getLastHistoryRecord(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    // selectSiderItemForDirectoryHistory: (selectedData) => dispatch(selectRowForDirectoryHistory(selectedData))
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ObjectPanelSiderContainer);
