import React, {createContext, useState} from "react";
import { iconSizesKit } from "../../../../library/UI/utils/iconSizesKit";


export const MapModuleContext = createContext(null);

const withMapModuleContext = (WrappedComponent) => {
  return ({ ...props }) => {
    const value = {
      iconSizesKit: iconSizesKit,
      mapRef: null,
      deckRef:null,
  };
    return (
        <MapModuleContext.Provider value={value}>
          <WrappedComponent {...props} />
        </MapModuleContext.Provider>
    );
  };
};
export default withMapModuleContext;
