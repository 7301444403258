import React from 'react';
import PropTypes from 'prop-types';
import {connect} from "react-redux"
import MapStyleSelector from "./MapStyleSelector";
import {onMapStyleChanged} from "../../../../../../../../../../../reducers/map/actions/mapboxActions";

const MapStyleSelectorContainer = ({currentStyle,changeMapStyle}) => {

    const handleMapStyleChange = (newStyle) => {
        changeMapStyle(newStyle)
    }
    return (
        <MapStyleSelector currentStyle={currentStyle} handleMapStyleChange={handleMapStyleChange} />
    );
};

MapStyleSelectorContainer.propTypes = {

};

const mapStateToProps = (state) => {
    return {
        currentStyle : state.mapboxView.style,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        changeMapStyle : (newStyle) => dispatch(onMapStyleChanged(newStyle))
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(MapStyleSelectorContainer);
