import React from "react";
import {baseStyles} from "../../../../../../../../../../../stylesRegistrator";
import {useTranslation} from "react-i18next";

const {
    BaseMapPanelTopPart,
    BaseMapPanelSecondaryTitle,
} = baseStyles.modules.map;

const LayersGalleryHeader = () => {
    return (
        <BaseMapPanelTopPart>
            <BaseMapPanelSecondaryTitle>Какие слои отобразить?</BaseMapPanelSecondaryTitle>
        </BaseMapPanelTopPart>
    );
};

export default LayersGalleryHeader;
