import React from "react";
import { LayerDataAccessorPrototype } from "../prototype/LayerDataAccessorPrototype";
import {mapObjectsEnum} from "../../../../../../enums/mapObjectsEnum";

const FilterFromSelectedDataButton = React.lazy(() =>
    import(
        "../../../../../../library/actionsButtons/NIPIGAZ/Map/dataAccessors/customForLayers/filterButtons/FilterJamsSegmentsOnMapButton"
        )
);

export class TrafficSegmentsDataAccessor extends LayerDataAccessorPrototype {

  actionButtons = [FilterFromSelectedDataButton]

  constructor() {
    super({
      urlData: "/traffic_jams/traffic_jam_segments/",
      i18LabelPath: "listOfSegments",
      selectedType: mapObjectsEnum.roadSegment,
    });
  }
}
