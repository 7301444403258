import React, { useCallback, useRef } from "react";
import { directoryHistoryActions } from "../../../../../../reducers/directory/actions/directoryHistoryActions";
import { connect, useDispatch } from "react-redux";
import { resolveUrl } from "../../../../../../registrators/old/directoryRegistrator/directoryRegistrator";
import PropsPasser from "../../../../../../HOC/PropsPasser";
import { useWhyDidYouUpdate } from "react-recipes";
import {
  getLastHistoryRecord,
  getLastObjectInHistory,
  getLastSiderHistoryRecord,
  getMainObject,
  getMainObjectId,
} from "../../../../../../reducers/directory/directoryHistory";
import ObjectSiderContainer from "../../../../../../library/objectPanels/Common/Sider/ObjectPanelSiderContainer";
import { Layout } from "antd";
import { inlineResolver } from "../../../../../../services/application/schemaManipulation/inlineResolver";
import styled from "styled-components";
import { Styled } from "../../../../../../library/objectPanels/Common/ObjectPanel.styled";
import { useHistory } from "react-router-dom";
import { REVERT_HISTORY_SAGA } from "../../../../../../sagas/directory/inlineHistorySaga";
import { useActionBarRenderer } from "../../../../../../customHooks/directoryTemplates/useActionBarRenderer";
import { Styled as BaseTemplateStyled } from "./TemplateBaseStyle.styled";

const {
  postToInlineHistory,
  revertToInlineHistoryRecordByType,
} = directoryHistoryActions;

const { Content } = Layout;

const StyledDrawer = styled.div``;

const StyledContent = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;

  & > div {
    height: 100%;
  }
`;

const InlineDataListTemplate = React.memo((props) => {
  useWhyDidYouUpdate("InlineDataListTemplate", props);

  const {
    folder,
    id,
    pushInlineHistory,
    lastInlineHistory,
    lastSiderInlineHistory,
    revertToInlineHistoryRecordByType,
    mainObject,
    inlineHistory,
    lastObjectRecord,
  } = props;

  const dispatch = useDispatch();

  const data = lastInlineHistory.data ? lastInlineHistory.data : null;
  const model = lastInlineHistory.model ? lastInlineHistory.model : null;
  const field = lastInlineHistory.field ? lastInlineHistory.field : null;

  //Callbacks for roles object panel
  const history = useHistory();
  const formRef = useRef(null);

  const initClose = (ref) => {
    revertToInlineHistoryRecordByType("includes", "main");
  };

  const initSubmitForm = (ref) => {
    ref.current.props.onSubmit();
  };

  const handleSubmitForm = (data) => {
  };
  //

  const proceedData = useCallback((selectedRow) => {
    const { urlData, url, parentKey, parentId } = lastInlineHistory;
    const itemId = selectedRow.data.id;
    pushInlineHistory({
      type: "inlineObject",
      ids: selectedRow.data.id,
      urlData: urlData + `${itemId}/`,
      url: url + `${itemId}/`,
      field: null,
      parentKey,
      parentId,
    });
  }, []);

  const goToInlineObject = (event) => {
    const relatedModel = mainObject.model.scheme[event.key];
    if (lastInlineHistory?.type === "inline") {
      const modelUrl = lastObjectRecord.model.scheme[event.key].url;
      pushInlineHistory({
        type: "inline",
        urlData: modelUrl,
        url: lastObjectRecord.url + `/${event.key}/`,
        field: event.key,
      });
    } else {
      revertToInlineHistoryRecordByType("includes", "Object");
    }
    // if (relatedModel?.type === "inline") {
    //   pushInlineHistory({
    //     type: "inline",
    //     urlData: relatedModel.url,
    //     url: mainObject.url + `/${event.key}/`,
    //     field: event.key
    //   });
    // } else {
    //   revertToInlineHistoryRecordByType('includes','Object')
    // }
  };

  const handleProvideData = () => {
    goToInlineObject();
  };

  const CompendiumRendererPrototype = resolveUrl(
    lastInlineHistory.urlData,
    "generalRenderer"
  );
  const CompendiumRenderer = (
    <PropsPasser
      component={
        <CompendiumRendererPrototype
          data={data}
          model={model}
          proceedData={proceedData}
          handleProvideData={handleProvideData}
          referenceToForm={formRef}
          initClose={initClose}
          initSubmitForm={initSubmitForm}
          handleSubmitForm={handleSubmitForm}
          lastInlineFieldName={field}
          withPagination={true}
          agGridThemeName={"ag-theme-custom-base"}
        />
      }
    />
  );

  const { ActionBarRenderer } = useActionBarRenderer(lastInlineHistory);

  const siderSchema = inlineResolver(lastSiderInlineHistory.model);
  return (
    <StyledDrawer
      placement={"right"}
      closable={false}
      onClose={() => {}}
      visible={false}
      getContainer={false}
      style={{ top: "60px" }}
      bodyStyle={{ padding: 0 }}
    >
      <Styled.PanelLayout>
        <ObjectSiderContainer
          containerCallbacks={{}}
          ref={formRef}
          schema={siderSchema}
          goToInline={goToInlineObject}
          initClose={initClose}
          initSubmitForm={initSubmitForm}
          handleSubmitForm={handleSubmitForm}
          lastInlineFieldName={field}
        />
        <Content>
          <BaseTemplateStyled.BaseTemplateActionBarWrapper>
            {ActionBarRenderer}
          </BaseTemplateStyled.BaseTemplateActionBarWrapper>
          <StyledContent>{CompendiumRenderer}</StyledContent>
        </Content>
      </Styled.PanelLayout>
    </StyledDrawer>
  );
});

const mapStateToProps = (state) => ({
  mainObject: getMainObject(state),
  lastInlineHistory: getLastHistoryRecord(state),
  lastObjectRecord: getLastObjectInHistory(state),
  lastSiderInlineHistory: getLastSiderHistoryRecord(state),
  inlineHistory: state.directoryHistory,
});

const mapDispatchToProps = (dispatch) => ({
  pushInlineHistory: (newHistoryItem) =>
    dispatch(postToInlineHistory(newHistoryItem)),
  revertToInlineHistoryRecordByType: (type, typeName) =>
    dispatch(revertToInlineHistoryRecordByType(type, typeName)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(InlineDataListTemplate);
