import styled from "styled-components";

const LocalHeaderMainWrapper = styled.div``;

const LocalMainWrapper = styled.div``;

const LocalHeaderWrapper = styled.div`
  padding: 1.1rem;
`;

const LocalInnerWrapper = styled.div``;

const LocalCheckboxWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding-top: 1.1rem;
  & path {
    color: #008291;
    width: 15px;
    height: 15px;
  }
  & span {
    color: #495e5f;
  }
`;

const LocalSelectWrapper = styled.div`
  & :focus {
    background: white;
    border-radius: 8px;
  }
  padding: 1.1rem;
  & button svg {
    width: 13px;
    height: 18px;
  }
`;

const LocalWrapperDivider = styled.div`
  & .ant-divider-horizontal {
    margin: 0;
  }
`;

const LocalScrollableWrapper = styled.div`
  height: auto;
  overflow-y: auto;
  overflow-x: hidden;
`;
export const Styled = {
  LocalHeaderMainWrapper,
  LocalHeaderWrapper,
  LocalMainWrapper,
  LocalInnerWrapper,
  LocalWrapperDivider,
  LocalCheckboxWrapper,
  LocalSelectWrapper,
  LocalScrollableWrapper,
};
