import React from "react";
import { Select } from "antd";
import { Styled } from "./Projects.styled";
const { Option } = Select;

const { LocalOwnerTS } = Styled;

const Projects = ({ handleChange, nameOrganization }) => {
  return (
    <LocalOwnerTS>
      <Select
        style={{ width: "100%" }}
        placeholder="Собственник ТС"
        onChange={(value) => {
          handleChange(value);
        }}
      >
        {nameOrganization.map((el) => (
          <Option key={el} value={el}>
            {el}
          </Option>
        ))}
      </Select>
    </LocalOwnerTS>
  );
};

export default Projects;
