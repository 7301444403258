import React from "react";
import CustomTooltip from "../../../../library/UI/components/CustomTooltip/CustomTooltip";
import CustomTooltipHeader from "../../../../library/UI/components/CustomTooltip/Header/CustomTooltipHeader";

const MapContextMenu = ({ mapContextMenuProperties, groupsRenderProp, closeMapContext, title = null}) => {
  let displayedTitle = mapContextMenuProperties?.selected?.name||title
  if (displayedTitle === "map") displayedTitle="Карта"
  return (
    <>
      {groupsRenderProp && (
        <CustomTooltip
          positionStyleOfTooltip={mapContextMenuProperties}
          tailSide={"left-center"}
          onCloseCallback={closeMapContext}
        >
          <CustomTooltipHeader>
            {displayedTitle}
          </CustomTooltipHeader>
          {groupsRenderProp}
        </CustomTooltip>
      )}
    </>
  );
};

export default MapContextMenu;
