import {LayerCardPrototype} from "../prototype/LayerCardPrototype";

export class MonitoringLayerCard extends LayerCardPrototype {

    constructor() {
        super({
                i18path: 'monitoring',
                linkToPreview: 'MonitoringTS.svg'
            }
        );
    }
}
