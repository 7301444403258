import React from "react";
import { Icon } from "antd";

const LocationDisabledIconSVG = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.166 7.49984C14.166 8.38317 13.841 9.43317 13.3327 10.5165L14.5743 11.7582C15.3077 10.2998 15.8327 8.80817 15.8327 7.49984C15.8327 4.27484 13.2243 1.6665 9.99935 1.6665C8.46602 1.6665 7.08268 2.25817 6.04102 3.2165L7.23268 4.40817C7.96602 3.74984 8.93268 3.33317 9.99935 3.33317C12.2993 3.33317 14.166 5.19984 14.166 7.49984ZM9.99935 5.4165C9.50768 5.4165 9.05768 5.5915 8.69935 5.88317L11.616 8.79984C11.9077 8.4415 12.0827 7.9915 12.0827 7.49984C12.0827 6.9473 11.8632 6.4174 11.4725 6.0267C11.0818 5.636 10.5519 5.4165 9.99935 5.4165ZM2.84102 2.38317L1.66602 3.55817L4.31602 6.20817C4.22435 6.62484 4.16602 7.05817 4.16602 7.49984C4.16602 11.8748 9.99935 18.3332 9.99935 18.3332C9.99935 18.3332 11.391 16.7915 12.816 14.7082L15.6077 17.4998L16.7827 16.3248L2.84102 2.38317ZM9.99935 15.7332C8.32435 13.5832 5.99935 10.1165 5.84935 7.7415L11.616 13.5082C11.0743 14.3248 10.5077 15.0832 9.99935 15.7332Z"
      fill="currentColor"
    />
  </svg>
);

export const LocationDisabledIcon = props => (
  <Icon component={LocationDisabledIconSVG} {...props} />
);
