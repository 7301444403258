import React from "react";
import { Styled } from "../TimePlayer.styled";
import { Button } from "../../../../../../library/UI/components/Button/Button";
import { PauseIcon } from "../../../../../../library/icons/actionIcons/PauseIcon";
import { PlayIcon } from "../../../../../../library/icons/actionIcons/PlayIcon";
import CustomSlider from "../../../../../../library/UI/components/CustomSlider/CustomSlider";

const tipFormatter = (value, startTimeDate) => {
  const valueDate = new Date(startTimeDate);
  valueDate.setSeconds(valueDate.getSeconds() + value);
  let hours = valueDate.getHours();
  let minutes = valueDate.getMinutes();
  let day = valueDate.getDate();
  let month = valueDate.getMonth() + 1;
  let year = valueDate.getFullYear();
  if (hours < 10) hours = "0" + hours;
  if (minutes < 10) minutes = "0" + minutes;
  if (day < 10) day = "0" + day;
  if (month < 10) month = "0" + month;
  return `${day}.${month}.${year} ${hours}:${minutes}`;
};

const TimePlayerContent = ({
  isMapboxPlayerRunning,
  iconSize,
  stopPlayerHandler,
  startPlayerHandler,
  handleSliderDateSelect,
  handleSliderDateAfterChange,
  mapboxTime
}) => {
  return (
    <Styled.TimePlayerContentWrap>
      <Styled.TimePlayerContentControlWrap>
        {isMapboxPlayerRunning ? (
          <Button
            type={"primaryReverseLB"}
            rounded
            hasShadow
            icon={<PauseIcon />}
            iconSize={iconSize}
            onClick={() => stopPlayerHandler()}
          />
        ) : (
          <Button
            type={"primaryReverseLB"}
            rounded
            hasShadow
            icon={<PlayIcon />}
            iconSize={iconSize}
            onClick={() => startPlayerHandler()}
          />
        )}
      </Styled.TimePlayerContentControlWrap>
      <Styled.TimePlayerContentSliderWrap>
        {mapboxTime.endTimeStamp && (
          <CustomSlider
            value={mapboxTime.currentTimeStamp}
            min={0}
            max={mapboxTime.endTimeStamp}
            onChange={handleSliderDateSelect}
            tipFormatter={value =>
              tipFormatter(value, mapboxTime.startDateTime)
            }
            onAfterChange={handleSliderDateAfterChange}
            p={"1rem"}
          />
        )}
      </Styled.TimePlayerContentSliderWrap>
    </Styled.TimePlayerContentWrap>
  );
};

export default TimePlayerContent;
