import { useCallback, useState } from "react";

export function useAgGridControl() {
  const quickMenuInitialState = {
    search: false,
    group: false,
    filters: false
  };
  const [aggApi, setAggApi] = useState(null);
  const [quickMenu, setQuickMenu] = useState(quickMenuInitialState);

  const handleAGGInit = agg => {
      setAggApi(agg);
    };

  const toggleShowQuickFilter = useCallback(() => {
    setQuickMenu({
      ...quickMenu,
      search: !quickMenu.search
    });
  }, [quickMenu, setQuickMenu, aggApi]);

  const toggleShowRowsMenu = useCallback(() => {
    if (!quickMenu.group) {
      aggApi.api.openToolPanel("columns");
      aggApi.api.setSideBarVisible(true);
    } else {
      aggApi.api.setSideBarVisible(false);
      aggApi.api.closeToolPanel();
    }
    setQuickMenu(prevState => ({
      ...quickMenuInitialState,
      group: !prevState.group
    }));
  }, [aggApi, quickMenu]);

  const toggleShowFilterMenu = useCallback(() => {
    if (quickMenu.filters === false) {
      aggApi.api.openToolPanel("filters");
      aggApi.api.setSideBarVisible(true);
    } else {
      aggApi.api.setSideBarVisible(false);
      aggApi.api.closeToolPanel();
    }
    setQuickMenu(prevState => ({
      ...quickMenuInitialState,
      filters: !prevState.filters
    }));
  }, [aggApi, setQuickMenu, quickMenu]);

  return {
    aggApi,
    handleAGGInit,
    quickMenu,
    toggleShowQuickFilter,
    toggleShowRowsMenu,
    toggleShowFilterMenu
  };
}
