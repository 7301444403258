import {END_FETCHING, START_FETCHING, RECALCULATE} from "../app";

export const startAppFetching = () => ({
  type: START_FETCHING
})

export const endAppFetching = () => ({
  type: END_FETCHING
})
export const recalculate = () => ({
  type: RECALCULATE
})