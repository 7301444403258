import styled from "styled-components";
import {Button} from "../../../library/UI/components/Button/Button";



const LocalMainWrapper = styled.div`
      position:absolute;
      top: 0;
      left: 0;
      z-index: 1;
      width: 80%;
`

const LocalMainTitle = styled.h3`
        font-size: 24px;
        line-height: 29px;
        color: ${props => props.theme.colors.defaultDark}    
`

const LocalMainDescription = styled.div`
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        margin-top: 8px;
        margin-bottom: 56px;
        color: ${props => props.theme.colors.paragraph}
`

const LocalTopDescription = styled.div`


`
const LocalDownDescription = styled.div`


`
const LocalTopDescriptionPhone = styled.a`
  color: ${props => props.theme.colors.primary}
`

const LocalTopDescriptionEmail = styled.a`
    color: ${props => props.theme.colors.primary}
`

const LocalButtonArrow = styled(Button)`
    margin-left: -9px;
      & svg {
      transform-origin: center;
      transform: rotate(90deg);
      }
`

export const Styled = {
    LocalMainWrapper,
    LocalMainTitle,
    LocalMainDescription,
    LocalTopDescription,
    LocalTopDescriptionPhone,
    LocalDownDescription,
    LocalTopDescriptionEmail,
    LocalButtonArrow,
}