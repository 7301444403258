import {LayerCardPrototype} from "../prototype/LayerCardPrototype";

export class GeozonesLayerCard extends LayerCardPrototype {

    constructor() {
        super({
                i18path: 'geozones',
                linkToPreview: 'Geozony.svg'
            }
        );
    }
}
