import React from "react";
import { Icon } from "antd";

const DataConcerningIconSVG = () => (
    <svg width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0.666687 0.5V5.5H3.12502L4.79169 10.5H4.00002V15.5H9.00002V11.675L13.5084 7.16667H17.3334V2.16667H12.3334V5.975L7.82502 10.5H6.55002L4.88335 5.5H5.66669V0.5H0.666687ZM2.33335 2.16667H4.00002V3.83333H2.33335V2.16667ZM14 3.83333H15.6667V5.5H14V3.83333ZM16.625 8.83333C16.5 8.83333 16.3917 8.875 16.3 8.96667L15.4584 9.81667L17.1834 11.5417L18.0334 10.7C18.2084 10.525 18.2084 10.225 18.0334 10.05L16.95 8.96667C16.9074 8.92394 16.8568 8.89014 16.801 8.86724C16.7452 8.84435 16.6853 8.83282 16.625 8.83333ZM14.9584 10.3L9.83335 15.425V17.1667H11.575L16.6917 12.0333L14.9584 10.3ZM5.66669 12.1667H7.33335V13.8333H5.66669V12.1667Z" fill="#1B3548"/>
    </svg>

);

export const DataConcerningIcon = props => (
    <Icon component={DataConcerningIconSVG} {...props} />
);
