import React from "react";
import styled from "styled-components";
import BooleanInput from "../../../library/formedInputs/BooleanInput.jsx";
import DefaultInput from "../../../library/formedInputs/DefaultInput.jsx";
import StringInput from "../../../library/formedInputs/StringInput.jsx";
import IntegerInput from "../../../library/formedInputs/IntegerInput.jsx";
import DateTimeInput from "../../../library/formedInputs/DateTimeInput.jsx";
import CustomMaskedInput from "../../../library/formedInputs/CustomMaskedInput.jsx";
import TimeInput from "../../../library/formedInputs/TimeInput.jsx";
import ChoiceInput from "../../../library/formedInputs/ChoiceInput.jsx";
import RelatedFieldInput from "../../../library/formedInputs/RelatedFieldInput.jsx";
import CreatableRelatedFieldInput from "../../../library/formedInputs/CreatableRelatedFieldInput.jsx";
import INNValidator from "../../../library/formedInputs/validators/INNValidator";
import KPPValidator from "../../../library/formedInputs/validators/KPPValidator";
import OGRNValidator from "../../../library/formedInputs/validators/OGRNValidator";
import SNILSValidator from "../../../library/formedInputs/validators/SNILSValidator";
import PhoneValidator from "../../../library/formedInputs/validators/PhoneValidator";
import ColorPickerInput from "../../../library/formedInputs/ColorPickerInput";
import CustomRelatedFieldInput from "../../../library/formedInputs/CustomRelatedFieldInput";

const StyledWrapper = styled.div`
  margin: 0 20px;
  width: calc(100% / 12 * 4 - 40px);
  min-width: 250px;
  justify-content: flex-start;
  position: relative;
  & .ant-form-item {
    width: 100%;
  }
  display: ${(props) => (props.hidden ? "hidden" : "flex")};
`;

const Wrapper = styled.div`
  display: none !important;
`;
/**
 *
 * @param groupFromModel model object recieved from options response
 * @param fields list of fields to drawS
 * @param instance single instance of object with values or empty for creating instance
 * @param referenceToForm link to Antd.Form
 * @param {boolean} wrapComponent true to wrap component with div with paddings
 * @returns {[]} containing input fields in group or single input field with wrapper
 */
export const createInputs = (
  groupFromModel,
  fields,
  instance,
  referenceToForm,
  wrapComponent = false
) => {
  const group = groupFromModel?.scheme;
  let result = [];
  if (group) {
    for (let field of fields) {
      const description = group[field];
      const element = provideInput(
        instance,
        description,
        field,
        referenceToForm,
        group
      );
      result.push(element);
    }
  }

  return result;
};

/**
 *
 * @param object single instance of object. For initial values
 * @param description description of field. Main ones are .type and .help_text on which fields are created
 * @param field key of field
 * @param formProps link to Antd.Form
 * @returns {React.Component} must provide props description={description} object={object} field={field} formProps={formProps}
 */

const provideInput = (
  object,
  description,
  field,
  formProps,
  actionModel = null
) => {
    // custom(когда надо отобразить больше данных) //
  switch (field) {
    case "road_sign": {
      return (
        <StyledWrapper>
          <CustomRelatedFieldInput
            key={`input-${field}`}
            description={description}
            object={object}
            field={field}
            formProps={formProps}
          />
        </StyledWrapper>
      );
    }
  }
// -- //
  switch (description.type) {
    case "datetime": {
      return (
        <StyledWrapper>
          <DateTimeInput
            key={`input-${field}`}
            description={description}
            object={object}
            field={field}
            formProps={formProps}
          />
        </StyledWrapper>
      );
    }
    case "date": {
      return (
        <StyledWrapper>
          <DateTimeInput
            key={`input-${field}`}
            description={description}
            object={object}
            field={field}
            formProps={formProps}
          />
        </StyledWrapper>
      );
    }
    case "time": {
      return (
        <StyledWrapper>
          <TimeInput
            key={`input-${field}`}
            description={description}
            object={object}
            field={field}
            formProps={formProps}
          />
        </StyledWrapper>
      );
    }
    case "integer": {
      if (field === "id")
        return (
          <Wrapper>
            <DefaultInput
              key={`input-${field}`}
              description={description}
              object={object}
              field={field}
              formProps={formProps}
            />
          </Wrapper>
        );
      switch (description.help_text) {
        case "hidden":
          return (
            <StyledWrapper>
              <IntegerInput
                key={`input-${field}`}
                description={description}
                step={1}
                object={object}
                field={field}
                formProps={formProps}
                className={"hidden"}
              />
            </StyledWrapper>
          );
        default:
          return (
            <StyledWrapper>
              <IntegerInput
                key={`input-${field}`}
                description={description}
                step={1}
                object={object}
                field={field}
                formProps={formProps}
              />
            </StyledWrapper>
          );
      }
    }
    case "float": {
      switch (description.help_text) {
        case "hidden":
          return (
            <StyledWrapper>
              <IntegerInput
                key={`input-${field}`}
                description={description}
                step={0.1}
                object={object}
                field={field}
                formProps={formProps}
                className={"hidden"}
              />
            </StyledWrapper>
          );
        default:
          return (
            <StyledWrapper>
              <IntegerInput
                key={`input-${field}`}
                description={description}
                step={1}
                object={object}
                field={field}
                formProps={formProps}
              />
            </StyledWrapper>
          );
      }
    }
    case "snils": {
      return (
        <StyledWrapper>
          <CustomMaskedInput
            mask="111-111-111 11"
            validator={SNILSValidator}
            key={`input-${field}`}
            description={description}
            object={object}
            field={field}
            formProps={formProps}
          />
        </StyledWrapper>
      );
    }
    case "phone": {
      return (
        <StyledWrapper>
          <CustomMaskedInput
            mask="+7 (111) 111-11-11"
            validator={PhoneValidator}
            key={`input-${field}`}
            description={description}
            object={object}
            field={field}
            formProps={formProps}
          />
        </StyledWrapper>
      );
    }
    case "color in rgba": {
      return (
        <StyledWrapper>
          <ColorPickerInput
            key={`input-${field}`}
            description={description}
            object={object}
            field={field}
            formProps={formProps}
          />
        </StyledWrapper>
      );
    }
    case "string": {
      if (field === "username") {
        // return <UsernameInput key={`input-${field}`} description={description} object={object} field={field} formProps={formProps}/>
        return (
          <StyledWrapper>
            <StringInput
              key={`input-${field}`}
              description={description}
              object={object}
              field={field}
              formProps={formProps}
            />
          </StyledWrapper>
        );
      }
      // else {
      //   return (
      //     <StyledWrapper>
      //       <StringInput
      //         key={`input-${field}`}
      //         description={description}
      //         object={object}
      //         field={field}
      //         formProps={formProps}
      //       />
      //     </StyledWrapper>
      //   );
      // }
    }
    case "url": {
      return (
        <StyledWrapper>
          <StringInput
            key={`input-${field}`}
            description={description}
            object={object}
            field={field}
            formProps={formProps}
          />
        </StyledWrapper>
      );
    }
    case "email": {
      // return <EmailInput key={`input-${field}`} description={description} object={object} field={field} formProps={formProps}/>
      return (
        <StyledWrapper>
          <StringInput
            key={`input-${field}`}
            description={description}
            object={object}
            field={field}
            formProps={formProps}
          />
        </StyledWrapper>
      );
    }
    case "registration number": {
      return (
        <StyledWrapper>
          <StringInput
            key={`input-${field}`}
            description={description}
            object={object}
            field={field}
            formProps={formProps}
          />
        </StyledWrapper>
      );
    }
    case "year": {
      return (
        <StyledWrapper>
          <StringInput
            key={`input-${field}`}
            description={description}
            object={object}
            field={field}
            formProps={formProps}
          />
        </StyledWrapper>
      );
    }
    case "boolean": {
      return (
        <StyledWrapper>
          <BooleanInput
            key={`input-${field}`}
            description={description}
            object={object}
            field={field}
            formProps={formProps}
          />
        </StyledWrapper>
      );
    }
    case "inn": {
      return (
        <StyledWrapper>
          <CustomMaskedInput
            mask="1111111111"
            validator={INNValidator}
            key={`input-${field}`}
            description={description}
            object={object}
            field={field}
            formProps={formProps}
          />
        </StyledWrapper>
      );
    }
    case "kpp": {
      return (
        <StyledWrapper>
          <CustomMaskedInput
            mask="1111-11-111"
            validator={KPPValidator}
            key={`input-${field}`}
            description={description}
            object={object}
            field={field}
            formProps={formProps}
          />
        </StyledWrapper>
      );
    }
    case "ogrn": {
      return (
        <StyledWrapper>
          <CustomMaskedInput
            mask="1111111111111"
            validator={OGRNValidator}
            key={`input-${field}`}
            description={description}
            object={object}
            field={field}
            formProps={formProps}
          />
        </StyledWrapper>
      );
    }
    case "okpo": {
      return (
        <StyledWrapper>
          <CustomMaskedInput
            mask="11111111"
            validator={() => true}
            key={`input-${field}`}
            description={description}
            object={object}
            field={field}
            formProps={formProps}
          />
        </StyledWrapper>
      );
    }
    case "okved": {
      return (
        <StyledWrapper>
          <CustomMaskedInput
            mask="111111"
            validator={() => true}
            key={`input-${field}`}
            description={description}
            object={object}
            field={field}
            formProps={formProps}
          />
        </StyledWrapper>
      );
    }
    case "field": {
      if (field.includes("_detail")) return null;
      if (field.includes("_name")) return null;
      return (
        // <ObjectDefaultInput
        //     key={`input-${field}`}
        //     description={description}
        //     object={object}
        //     field={field}
        //     formProps={formProps}
        //     actionModel={actionModel}
        // />
        null
      );
    }
    case "choice": {
      return (
        <StyledWrapper>
          <ChoiceInput
            key={`input-${field}`}
            description={description}
            object={object}
            field={field}
            formProps={formProps}
          />
        </StyledWrapper>
      );
    }
    case "related field": {
      return (
        <StyledWrapper>
          <RelatedFieldInput
            key={`input-${field}`}
            description={description}
            object={object}
            field={field}
            formProps={formProps}
          />
        </StyledWrapper>
      );
    }
    case "creatable related field": {
      return (
        <StyledWrapper>
          <CreatableRelatedFieldInput
            key={`input-${field}`}
            description={description}
            object={object}
            field={field}
            formProps={formProps}
          />
        </StyledWrapper>
      );
    }
    case "inline": {
      return null;
    }
    default: {
      console.warn(
        `${field} AS TYPE ${description.type} >>> doesnt have renderer and is ignored`
      );
      return (
        <Wrapper>
          <DefaultInput
            key={`input-${field}`}
            description={description}
            object={object}
            field={field}
            formProps={formProps}
          />
        </Wrapper>
      );
    }
  }
};
