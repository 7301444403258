import { CompositeLayer} from "@deck.gl/core";
import {
  IconLayer,
  PathLayer,
  ScatterplotLayer,
  TextLayer,
} from "@deck.gl/layers";
// import { getColorInRangeLinear } from "../../../../services/data/getColorInRangeLinear";
import { roadObjectsMapping } from "../../iconMappers/roadObjectsMapping";
import { signsMapperType1 } from "../../iconMappers/signsMapper/signsMapperType1";
import { signsMapperType2 } from "../../iconMappers/signsMapper/signsMapperType2";
import { signsMapperType3 } from "../../iconMappers/signsMapper/signsMapperType3";
import { signsMapperType4 } from "../../iconMappers/signsMapper/signsMapperType4";
import { signsMapperType5 } from "../../iconMappers/signsMapper/signsMapperType5";
import { signsMapperType6 } from "../../iconMappers/signsMapper/signsMapperType6";
import { signsMapperType7 } from "../../iconMappers/signsMapper/signsMapperType7";
import { signsMapperType8 } from "../../iconMappers/signsMapper/signsMapperType8";
import { layerNamesEnum } from "../../../../enums/layerNamesEnum";
import { mapObjectsEnum } from "../../../../enums/mapObjectsEnum";
import { calculateLinePathCenterCoords } from "../../../../services/map/calculateLinePathCenterCoords";
import {
  getCoordsFromParsedGeometry,
} from "../../../../services/map/getCoordsFromParsedGeometry";

import {
  bearing,
  lineString,
  point,
  nearestPointOnLine,
  getCoords,
  destination,
} from "@turf/turf";


// const IMPORTANCE_SET = ["MUNICIPAL", "REGIONAL", "FEDERAL"];

const SIGNS_MAPPER_SET = [
  signsMapperType1,
  signsMapperType2,
  signsMapperType3,
  signsMapperType4,
  signsMapperType5,
  signsMapperType6,
  signsMapperType7,
  signsMapperType8,
];

const ZOOM_PROPERTIES = {
  minZoom: 11,
  maxZoom: 9.5,
  extraZoom: 15.5,
  hardZoom: 14.5,
  optimal: 14,
  primaryIconMinZoom: 13.661197891792085,
  primary: 14.04,
  primaryHard: 14.325677048508915,
};

class RoadSchemaCompositeLayer extends CompositeLayer {
  updateState({ changeFlags, props }) {
    const selectedLayer = this.props.selectedObject?.selectedObject?.layer;
    if (selectedLayer instanceof RoadSchemaCompositeLayer) {
      this.setState({
        selectedObject: this.props.selectedObject?.selectedObject?.object,
      });
    } else {
      this.setState({ selectedObject: null });
    }
    this.setState({
      data: props,
    });

  }

  getPickingInfo(event) {
    if (event.mode === "query") {
      if (this.props.onClickHandler) {
        let selectedObjectType = null;
        let selectedObjectLayerName = layerNamesEnum.roadsSchema;
        if (event.sourceLayer.id.includes("tracking-path-layer"))
          selectedObjectType = mapObjectsEnum.roadSegment;
        if (event.sourceLayer.id.includes("signs-icon"))
          selectedObjectType = mapObjectsEnum.roadSign;
        if (event.sourceLayer.id.includes("road_blocks"))
          selectedObjectType = mapObjectsEnum.roadBlock;
        if (event.sourceLayer.id.includes("road_works"))
          selectedObjectType = mapObjectsEnum.roadWorks;
        if (event.sourceLayer.id.includes("road-overpass-icon"))
          selectedObjectType = mapObjectsEnum.roadOverpass;
        if (event.sourceLayer.id.includes("dock-segment-icon"))
          selectedObjectType = mapObjectsEnum.dockSegment;
        if (event.sourceLayer.id.includes("control-room-icon"))
          selectedObjectType = mapObjectsEnum.controlRoomsSegment;
        if (event.sourceLayer.id.includes("security-checkpoint-icon"))
          selectedObjectType = mapObjectsEnum.securityCheckpoint;
        if (event.sourceLayer.id.includes("speed"))
          selectedObjectType = mapObjectsEnum.roadSign;
        this.props.onClickHandler(
          event.info,
          selectedObjectType,
          selectedObjectLayerName
        );
      } else return null;
    }
  }

  renderLayers() {
    if (!this.props.relatedData) return [];
    let signsLayers = [];
    let roadIconsLayers = [];
    if (
      this?.props?.zoom > ZOOM_PROPERTIES.maxZoom &&
      this?.props?.sizeScale <= 1
    ) {
      roadIconsLayers = this._getRoadIconLayers();
      signsLayers = this._getSignsLayers();
    } else if (this?.props?.sizeScale > 1) {
      roadIconsLayers = this._getRoadIconLayers();
      signsLayers = this._getSignsLayers();
    }
    const pathLayers = this._getRoadPathLayers();

    return [...pathLayers, ...roadIconsLayers, ...signsLayers];
  }

  _getRoadPathLayers() {
    let paths = [];
    paths = [

        new PathLayer(
            this.props,
            this.getSubLayerProps({ id: "tracking-path-layer" }),
            {
                data: this.props.relatedData.segments,
                pickable: true,
                autoHighlight: true,
                widthScale: 3,
                widthMinPixels: 2,
                widthMaxPixels: 12,
                rounded: true,
                billboard: false,
                getPath: (d) => {
                    if (d.line_path) {
                        return getCoordsFromParsedGeometry(d.line_path);
                    }
                    return [0, 0];
                },
                getColor: (d) => {
                    const cat = this?.props?.relatedData?.roadsCategories;
                    const road = this?.props?.relatedData?.roads?.find(
                        (el) => el.id === d.road
                    );
                    let rgbaArray;
                    if (road?.category) {
                        cat.forEach((item) => {
                            if (item.id === road.category) {
                                rgbaArray = item.rgba_color
                                    .match(/\d+/g)
                                    .map((item) => parseInt(item));
                            }
                        });
                        return [
                            rgbaArray?.[0],
                            rgbaArray?.[1],
                            rgbaArray?.[2],
                            rgbaArray?.[3] * 255,
                        ];
                    } else return [180, 180, 180, 100];
                },
                getWidth: () => 5,
                opacity: this?.props?.opacity ?? 0.1,
                onClick: () => {
                    this.props.onClick();
                },
            }
        ),
      new PathLayer(
        this.props,
        this.getSubLayerProps({ id: "road_blocks_path" }),
        {
          data: this.props.relatedData.road_blocks,
          widthScale: 3,
          pickable: true,
          widthMinPixels: 3,
          billboard: false,
          autoHighlight: true,
          opacity: this?.props?.opacity ?? 0.1,
          getPath: (d) => {
            if (d?.line_path) {
              return getCoordsFromParsedGeometry(d.line_path);
            }
            return [0, 0];
          },
          getColor: () => {
            return [80, 80, 80, 255];
          },
          getWidth: () => 5,
        }
      ),

      new PathLayer(
        this.props,
        this.getSubLayerProps({ id: "road_works_path" }),
        {
          data: this.props.relatedData.road_works,
          widthScale: 3,
          pickable: true,
          billboard: false,
          autoHighlight: true,
          widthMinPixels: 3,
          opacity: this?.props?.opacity ?? 0.1,
          getPath: (d) => {
            if (d.line_path) {
              return getCoordsFromParsedGeometry(d.line_path);
            }
            return [0, 0];
          },
          getColor: () => {
            return [120, 120, 120, 255];
          },
          getWidth: () => 5,
        }
      ),
    ];
    return paths;
  }

  _getRoadIconLayers() {
    return [
      new IconLayer(
        this.props,
        this.getSubLayerProps({ id: "road_works-icon" }),
        {
          data: this.props.relatedData.road_works,
          iconAtlas: `${process.env.PUBLIC_URL}/img/textures/road_objects.png`,
          iconMapping: roadObjectsMapping,
          sizeScale: 1.3 * this?.props?.sizeScale ?? 1,
          pickable: true,
          autoHighlight: true,
          fp64: true,
          getPolygonOffset: ({ layerIndex }) => [0, -layerIndex * 10000],
          getPosition: (d) => {
            if (d.line_path) {
              return calculateLinePathCenterCoords(d.line_path);
            } else return [0, 0];
          },
          getIcon: (d) => {
            if (
              this.props?.selectedObject?.type === "road_works" &&
              d.id === this.props?.selectedObject?.selectedObject?.object?.id
            ) {
              return "work_on_road_masked";
            } else {
              return "work_on_road";
            }
          },
          updateTriggers: {
            getIcon: this.state.selectedObject,
          },
          ...this._getIconAdditionalProperties(),
        }
      ),
      new IconLayer(
        this.props,
        this.getSubLayerProps({ id: "road_blocks-icon" }),
        {
          data: this.props.relatedData.road_blocks,
          iconAtlas: `${process.env.PUBLIC_URL}/img/textures/road_objects.png`,
          iconMapping: roadObjectsMapping,
          sizeScale: 1.3 * this?.props?.sizeScale ?? 1,
          pickable: true,
          autoHighlight: true,
          fp64: true,
          getPolygonOffset: ({ layerIndex }) => [0, -layerIndex * 10000],
          getPosition: (d) => {
            if (d.line_path) {
              return calculateLinePathCenterCoords(d.line_path);
            } else return [0, 0];
          },
          updateTriggers: {
            getIcon: this.state.selectedObject,
          },
          getIcon: (d) => {
            if (
              this.props?.selectedObject?.type === "road_block" &&
              d.id === this.props?.selectedObject?.selectedObject?.object?.id
            ) {
              return "closed_road_masked";
            }
            return "closed_road";
          },
          ...this._getIconAdditionalProperties(),
        }
      ),
      new IconLayer(
        this.props,
        this.getSubLayerProps({ id: "road-overpass-icon" }),
        {
          data: this.props.relatedData.road_overpass,
          iconAtlas: `${process.env.PUBLIC_URL}/img/textures/road_objects.png`,
          iconMapping: roadObjectsMapping,
          sizeScale: 1.3 * this?.props?.sizeScale ?? 1,
          pickable: true,
          autoHighlight: true,
          fp64: true,
          getPolygonOffset: ({ layerIndex }) => [0, -layerIndex * 10000],
          getPosition: (d) => {
            if (d.location) {
              return getCoordsFromParsedGeometry(d.location);
            } else return [0, 0];
          },
          updateTriggers: {
            getIcon: this.state.selectedObject,
          },
          getIcon: (d) => {
            if (
              this.props?.selectedObject?.type === "road_overpass" &&
              d.id === this.props?.selectedObject?.selectedObject?.object?.id
            ) {
              return "road_overpass_masked";
            }
            return "road_overpass";
          },
          ...this._getIconAdditionalProperties(),
        }
      ),
      new IconLayer(
        this.props,
        this.getSubLayerProps({ id: "dock-segment-icon" }),
        {
          data: this.props.relatedData.dock_segment,
          iconAtlas: `${process.env.PUBLIC_URL}/img/textures/road_objects.png`,
          iconMapping: roadObjectsMapping,
          sizeScale: 1.3 * this?.props?.sizeScale ?? 1,
          autoHighlight: true,
          pickable: true,
          fp64: true,
          getPolygonOffset: ({ layerIndex }) => [0, -layerIndex * 10000],
          getPosition: (d) => {
            if (d.location) {
              return getCoordsFromParsedGeometry(d.location);
            } else return [0, 0];
          },
          updateTriggers: {
            getIcon: this.state.selectedObject,
          },
          getIcon: (d) => {
            if (
              this.props?.selectedObject?.type === "dock_segment" &&
              d.id === this.props?.selectedObject?.selectedObject?.object?.id
            ) {
              return "dock_segment_masked";
            } else {
              return "dock_segment";
            }
          }, /// проверить причалы!!!
          ...this._getIconAdditionalProperties(),
        }
      ),
      new IconLayer(
        this.props,
        this.getSubLayerProps({ id: "control-room-icon" }),
        {
          data: this.props.relatedData.control_room,
          iconAtlas: `${process.env.PUBLIC_URL}/img/textures/road_objects.png`,
          iconMapping: roadObjectsMapping,
          sizeScale: 1.3 * this?.props?.sizeScale ?? 1,
          autoHighlight: true,
          pickable: true,
          fp64: true,
          getPolygonOffset: ({ layerIndex }) => [0, -layerIndex * 10000],
          getPosition: (d) => {
            if (d.location) {
              return getCoordsFromParsedGeometry(d.location);
            } else return [0, 0];
          },
          updateTriggers: {
            getIcon: this.state.selectedObject,
          },
          getIcon: (d) => {
            if (
              this.props?.selectedObject?.type === "control_room" &&
              d.id === this.props?.selectedObject?.selectedObject?.object?.id
            ) {
              return "control_room_masked";
            } else {
              return "control_room";
            } //
          },
          ...this._getIconAdditionalProperties(),
        }
      ),
      new IconLayer(
        this.props,
        this.getSubLayerProps({ id: "security-checkpoint-icon" }),
        {
          data: this.props.relatedData.security_checkpoint,
          iconAtlas: `${process.env.PUBLIC_URL}/img/textures/road_objects.png`,
          iconMapping: roadObjectsMapping,
          sizeScale: 1.3 * this?.props?.sizeScale ?? 1,
          autoHighlight: true,
          pickable: true,
          fp64: true,
          getPolygonOffset: ({ layerIndex }) => [0, -layerIndex * 10000],
          getPosition: (d) => {
            if (d.location) {
              return getCoordsFromParsedGeometry(d.location);
            } else return [0, 0];
          },
          updateTriggers: {
            getIcon: this.state.selectedObject,
          },
          getIcon: (d) => {
            if (
              this.props?.selectedObject?.type === "security_checkpoint" &&
              d.id === this.props?.selectedObject?.selectedObject?.object?.id
            ) {
              return "security_checkpoint_masked";
            }
            return "security_checkpoint";
          },
          ...this._getIconAdditionalProperties(),
        }
      ),
    ];
  }

  _getIconAdditionalProperties() {
    const additionalProperties = {};
    if (this?.props?.zoom >= ZOOM_PROPERTIES.extraZoom) {
      additionalProperties.getSize = this?.props?.zoom * 5;
      // additionalProperties.radiusMinPixels = this?.props?.zoom * 2.5
    }
    if (
      this?.props?.zoom < ZOOM_PROPERTIES.extraZoom &&
      this?.props?.zoom >= ZOOM_PROPERTIES.primaryIconMinZoom
    ) {
      additionalProperties.getSize = this?.props?.zoom * 3;
      // additionalProperties.radiusMaxPixels = this?.props?.zoom * 1.5;
      // additionalProperties.getPixelOffset = this?.props?.zoom * 2.5
    }
    if (
      this?.props?.zoom <= ZOOM_PROPERTIES.primaryIconMinZoom &&
      this?.props?.zoom >= ZOOM_PROPERTIES.minZoom
    ) {
      additionalProperties.getRadius = this?.props?.zoom * 2;
      // additionalProperties.radiusMaxPixels = this?.props?.zoom * 1.2;
      // additionalProperties.getPixelOffset = this?.props?.zoom * 2.5
    }
    if (this?.props?.zoom < ZOOM_PROPERTIES.minZoom) {
      additionalProperties.getSize = this?.props?.zoom;
      // additionalProperties.radiusMinPixels = this?.props?.zoom * 0.5;
      // additionalProperties.getPixelOffset = this?.props?.zoom * 2.5
    }
    return additionalProperties;
  }

  _getScatterplotLayerAdditionalProperties() {
    const additionalProperties = {};
    if (this?.props?.zoom >= ZOOM_PROPERTIES.extraZoom) {
      additionalProperties.getSize = this?.props?.zoom * 50;
      additionalProperties.radiusMinPixels = this?.props?.zoom * 1.5;
      additionalProperties.lineWidthMinPixels = this?.props?.zoom * 0.2;
      additionalProperties.lineWidthMaxPixels = this?.props?.zoom * 0.2;
    }
    if (
      this?.props?.zoom < ZOOM_PROPERTIES.extraZoom &&
      this?.props?.zoom >= ZOOM_PROPERTIES.primaryIconMinZoom
    ) {
      additionalProperties.getSize = this?.props?.zoom * 3;
      additionalProperties.radiusMaxPixels = this?.props?.zoom * 1.5;
      additionalProperties.lineWidthMinPixels = this?.props?.zoom * 0.2;
      additionalProperties.lineWidthMaxPixels = this?.props?.zoom * 0.2;
      // additionalProperties.getPixelOffset = this?.props?.zoom * 2.5
    }
    if (
      this?.props?.zoom <= ZOOM_PROPERTIES.primaryIconMinZoom &&
      this?.props?.zoom >= ZOOM_PROPERTIES.minZoom
    ) {
      additionalProperties.lineWidthMinPixels = this?.props?.zoom * 0.2;
      additionalProperties.lineWidthMaxPixels = this?.props?.zoom * 0.2;
      additionalProperties.getSize = this?.props?.zoom * 2;
      additionalProperties.radiusMaxPixels = this?.props?.zoom * 1.2;
      // additionalProperties.getPixelOffset = this?.props?.zoom * 2.5
    }
    if (this?.props?.zoom < ZOOM_PROPERTIES.minZoom) {
      additionalProperties.getSize = this?.props?.zoom;
      additionalProperties.radiusMinPixels = this?.props?.zoom * 0.5;
      // additionalProperties.getPixelOffset = this?.props?.zoom * 2.5
    }
    return additionalProperties;
  }

  _getIconTextAdditionalProperties() {
    const additionalPropertiesText = {};
    if (this?.props?.zoom >= ZOOM_PROPERTIES.extraZoom) {
      additionalPropertiesText.sizeScale = this?.props?.zoom * 0.06;
    }
    if (
      this?.props?.zoom < ZOOM_PROPERTIES.extraZoom &&
      this?.props?.zoom >= ZOOM_PROPERTIES.primaryIconMinZoom
    ) {
      additionalPropertiesText.sizeScale = this?.props?.zoom * 0.03;
    }
    if (
      this?.props?.zoom <= ZOOM_PROPERTIES.primaryIconMinZoom &&
      this?.props?.zoom >= ZOOM_PROPERTIES.minZoom
    ) {
      additionalPropertiesText.sizeScale = this?.props?.zoom * 0.03;
    }
    if (this?.props?.zoom < ZOOM_PROPERTIES.minZoom) {
      additionalPropertiesText.sizeScale = this?.props?.zoom * 0.01;
    }
    return additionalPropertiesText;
  }

  _getSignsLayers() {
    const signsLayers = [];
    signsLayers.push(
      // Добавление кастомизированных иконок скоростей и текста к ним
      new ScatterplotLayer(this.props, this.getSubLayerProps({ id: "speed" }), {
        pickable: true,
        data: this.props.relatedData.road_sign,
        autoHighlight: true,
        stroked: true,
        filled: true,
        fp64: true,
        getPolygonOffset: ({ layerIndex }) => [0, -layerIndex * 10000],
        radiusScale: 1.5,
        radiusMinPixels: 12 * this?.props?.sizeScale ?? 1,
        radiusMaxPixels: 1000 * this?.props?.sizeScale ?? 1,
        lineWidthMinPixels: 2,
        lineWidthMaxPixels: 3,
        getPosition: (d) => {
          const { road_sign } = d;
          const getSign = this.props.relatedData.sign.filter(
            (el) => el.id === road_sign
          );
          const { value } = getSign?.[0];
          if (d.location) {
            const currentSegment = this.props.relatedData.segments.find(
              (i) => i.id === d.segment
            );
            const segmentLocation = getCoordsFromParsedGeometry(
              currentSegment.line_path
            );
            const roadSignLocation = getCoordsFromParsedGeometry(d.location);

            const line = lineString(segmentLocation);
            const pt = point(roadSignLocation);
            const snapped = nearestPointOnLine(line, pt);

            const coords = getCoords(snapped);
            const bear = bearing(pt, coords);
            const destGo = destination(snapped, 0.035, bear);
            const destBack = destination(snapped, 0.035, 180 + bear);

            if (d.direction === "F" && value !== null) {
              return getCoords(destGo);
            } else if (d.direction === "B" && value !== null) {
              return getCoords(destBack);
            }
          } else return [0, 0];
        },
        opacity: 1,
        wireframe: true,
        getFillColor: (d) => {
          const { road_sign } = d;
          const getSign = this.props.relatedData.sign.filter(
            (el) => el.id === road_sign
          );
          // тип знака
          const { road_sign_type } = getSign?.[0];
          if (road_sign_type === "MANDATORY") {
            return [0, 0, 255];
          } else if (road_sign_type === "PROHIBITORY") {
            return [255, 255, 255];
          }
        },
        getLineColor: (d) => {
          const { road_sign } = d;
          const getSign = this.props.relatedData.sign.filter(
            (el) => el.id === road_sign
          );

          const { road_sign_type } = getSign?.[0];
          if (road_sign_type === "MANDATORY") {
            return null;
          } else if (road_sign_type === "PROHIBITORY") {
            return [255, 0, 0];
          }
        },
        ...this._getScatterplotLayerAdditionalProperties(),
      }),
      new TextLayer(this.props, this.getSubLayerProps({ id: "speed-text" }), {
        data: this.props.relatedData.road_sign,
        getPosition: (d) => {
          if (d.location) {
            const currentSegment = this.props.relatedData.segments.find(
              (i) => i.id === d.segment
            );
            const segmentLocation = getCoordsFromParsedGeometry(
              currentSegment.line_path
            );
            const roadSignLocation = getCoordsFromParsedGeometry(d.location);

            const line = lineString(segmentLocation);
            const pt = point(roadSignLocation);
            const snapped = nearestPointOnLine(line, pt);

            const coords = getCoords(snapped);
            const bear = bearing(pt, coords);
            const destGo = destination(snapped, 0.035, bear);
            const destBack = destination(snapped, 0.035, 180 + bear);

            if (d.direction === "F") {
              return getCoords(destGo);
            } else if (d.direction === "B") {
              return getCoords(destBack);
            }
          } else return [0, 0];
        },
        getText: (d) => {
          const { road_sign } = d;
          const getSign = this.props.relatedData.sign.filter(
            (el) => el.id === road_sign
          );
          const { id } = getSign?.[0];
          const { value } = getSign?.[0];
          if (id === road_sign) {
            const valueItems = value?.toString();
            return valueItems;
          } else {
            return null;
          }
        },
        getAngle: (d) => {
          const { direction } = d;

          const currentSegment = this.props.relatedData.segments.find(
            (i) => i.id === d.segment
          );
          const segmentLocation = getCoordsFromParsedGeometry(
            currentSegment.line_path
          );
          const roadSignLocation = getCoordsFromParsedGeometry(d.location);

          const line = lineString(segmentLocation);
          const pt = point(roadSignLocation);
          const snapped = nearestPointOnLine(line, pt);
          const coords = getCoords(snapped);
          const bear = bearing(pt, coords);

          if (direction === "F") {
            return 90 - bear;
          } else if (direction === "B") {
            return 270 - bear;
          } else {
            return null;
          }
        },
        getColor: (d) => {
          const { road_sign } = d;
          const getSign = this.props.relatedData.sign.filter(
            (el) => el.id === road_sign
          );

          const { road_sign_type } = getSign?.[0];
          if (road_sign_type === "MANDATORY") {
            return [255, 255, 255];
          } else if (road_sign_type === "PROHIBITORY") {
            return [0, 0, 0];
          }
        },

        fontFamily: "Arial, monospace",
        fp64: true,
        sizeScale: 2 * this?.props?.sizeScale ?? 1,
        getPolygonOffset: ({ layerIndex }) => [0, -layerIndex * 10000],
        billboard: false,
        getOpacity: 1,
        getTextAnchor: "middle",
        getAlignmentBaseline: "center",
        ...this._getIconTextAdditionalProperties(),
      })
    );
    for (let it = 1; it < 9; it++) {
      if (this?.props?.zoom > ZOOM_PROPERTIES.maxZoom) {
        signsLayers.push(
          new IconLayer(
            this.props,
            this.getSubLayerProps({ id: `signs-icon-type-${it}` }),
            {
              data: this.props.relatedData.road_sign,
              iconAtlas: `${process.env.PUBLIC_URL}/img/signs/signs_of_type${it}.png`,
              iconMapping: SIGNS_MAPPER_SET[it - 1],
              autoHighlight: true,
              billboard: false,
              sizeScale: 0.8 * this?.props?.sizeScale ?? 1,
              pickable: true,
              fp64: true,
              getPosition: (d) => {
                if (d.location) {
                  const currentSegment = this.props.relatedData.segments.find(
                    (i) => i.id === d.segment
                  );
                  const segmentLocation = getCoordsFromParsedGeometry(
                    currentSegment.line_path
                  );
                  const roadSignLocation = getCoordsFromParsedGeometry(
                    d.location
                  );

                  const line = lineString(segmentLocation);
                  const pt = point(roadSignLocation);
                  const snapped = nearestPointOnLine(line, pt);

                  const coords = getCoords(snapped);

                  const bear = bearing(pt, coords);
                  const destGo = destination(snapped, 0.035, bear);
                  const destBack = destination(snapped, 0.035, 180 + bear);

                  if (d.direction === "F") {
                    return getCoords(destGo);
                  } else if (d.direction === "B") {
                    return getCoords(destBack);
                  }
                } else return [0, 0];
              },
              getIcon: (d) => {
                let iconArray = [];
                const { road_sign_number } = d;
                iconArray.push(road_sign_number);
                return iconArray;
              },
              getAngle: (d) => {
                const { direction } = d;

                const currentSegment = this.props.relatedData.segments.find(
                  (i) => i.id === d.segment
                );
                const segmentLocation = getCoordsFromParsedGeometry(
                  currentSegment.line_path
                );
                const roadSignLocation = getCoordsFromParsedGeometry(
                  d.location
                );

                const line = lineString(segmentLocation);
                const pt = point(roadSignLocation);
                const snapped = nearestPointOnLine(line, pt);
                const coords = getCoords(snapped);
                const bear = bearing(pt, coords);

                if (direction === "F") {
                  return 90 - bear;
                } else if (direction === "B") {
                  return 270 - bear;
                } else {
                  return null;
                }
              },
              ...this._getIconAdditionalProperties(),
            }
          )
        );
      }
    }

    return signsLayers;
  }
}

const defaultProps = {
  // Inherit all of GeoJsonLayer's props
  ...TextLayer.defaultProps,
  // Label for each feature
  getLabel: { type: "accessor", value: (x) => x },
  // Label size for each feature
  getLabelSize: { type: "accessor", value: 8 },
  // Label color for each feature
  getLabelColor: { type: "accessor", value: [0, 0, 0, 255] },
  getCurrentTimeStamp: { type: "accessor", value: 0 },
  // Label always facing the camera
  billboard: true,
  // Label size units
  labelSizeUnits: "pixels",
  // Label background color
  labelBackground: { type: "color", value: [255, 255, 255], optional: true },
  // Label font
  fontFamily: "Monaco, monospace",
};

RoadSchemaCompositeLayer.layerName = "RoadSchema";
RoadSchemaCompositeLayer.defaultProps = defaultProps;

export default RoadSchemaCompositeLayer;
