import {
    CLEAR_MAP_SELECTED_OBJECT,
    SET_MAP_SELECTED_OBJECT,
    UPDATE_SELECTED_OBJECT
} from "../mapSelectedObject";



export const onMapSelectItem = (data, type, selectedObject) => {
    return{
        type: SET_MAP_SELECTED_OBJECT,
        payload: {data, type, selectedObject}
    }
}

export const clearMapSelectedObject = (newStyle) => {
    return{
        type: CLEAR_MAP_SELECTED_OBJECT,
    }
}

export const onMapSelectedObjectUpdate = (data, type) => ({
    type: UPDATE_SELECTED_OBJECT,
    payload: {data, type}
})
