import React, { useRef } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { commonSelectedObjectProtoUtils } from "../commonUtils/commonSelectedObjectProtoUtils";
import moment from "moment";
import { registeredDirectoryUrlsEnum } from "../../../../enums/registeredDirectoryUrlsEnum";
import PrepareScrollableInfoCard from "../PrepareScrollableInfoCard";

const {
  parseCoordinatesToDescForMonitoring,
  commonIconSizeForCurrentSection,
} = commonSelectedObjectProtoUtils;

const MonitoringVehicleSelection = ({
  handleCloseMapSelectedObjectInfo,
  selectedMapItem,
  data,
  ...other
}) => {
  const handleOpenObjectInNewTab = () => {
    const roadData = selectedMapItem.data.find(
      (dataRecord) => dataRecord.name === "monitored_object"
    );
    const { id } = roadData.data;
    const win = window.open(
      `/app/directory/${registeredDirectoryUrlsEnum.vehicles}/${id}/`,
      "_blank"
    );
    win.focus();
  };

  const { t } = useTranslation("common");
  let Descriptions = [];
  const properties = selectedMapItem?.selectedObject;

  const registrationNumber = properties.registration_number;
  Descriptions.push({
    key: t("app.menuMap.dataVehicle.regNumber"),
    value: registrationNumber,
  });

  if (data?.id === properties.vehicle_id && data?.vehicle_model_name) {
    Descriptions.push({
      key: t("app.menuMap.dataVehicle.vehicleModelName"),
      value: data.vehicle_model_name.toString(),
    });
  }

  const speed = properties.speed;
  Descriptions.push({
    key: t("app.menuMap.dataVehicle.speed"),
    value: speed,
  });

  const timeReceived = properties.time_received;
  const userTZ = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const date = moment
    .tz(timeReceived)
    .tz(userTZ)
    .format("DD.MM.YYYY / HH:mm:ss");
  Descriptions.push({
    key: t("app.menuMap.dataVehicle.timeReceived"),
    value: date,
  });

  Descriptions.push({
    key: t("app.menuMap.dataVehicle.coordinates"),
    value: parseCoordinatesToDescForMonitoring(selectedMapItem),
  });

  if (data?.id === properties.vehicle_id && data?.vehicle_type_name) {
    Descriptions.push({
      key: t("app.menuMap.dataVehicle.vehicleTypeName"),
      value: data.vehicle_type_name.toString(),
    });

    Descriptions.push({
      key: t("app.menuMap.dataVehicle.organizationName"),
      value: data.organization_name.toString(),
    });
  }

  return (
    <PrepareScrollableInfoCard
      title={t("app.menuMap.scrollableInfoCard.header.monitoringLayer")}
      listOfDescriptions={Descriptions}
      handleCloseCard={handleCloseMapSelectedObjectInfo}
      openInNewTabBtnDesc={t("app.menuMap.dataVehicle.openInDirectory")}
      selectedMapItem={selectedMapItem}
      handleOpenItemInDirectory={handleOpenObjectInNewTab}
    />
  );
};

export default MonitoringVehicleSelection;

MonitoringVehicleSelection.propTypes = {
  data: PropTypes.object.isRequired,
  handleCloseMapSelectedObjectInfo: PropTypes.func.isRequired,
  handleOpenObjectInNewTab: PropTypes.func.isRequired,
  selectedMapItem: PropTypes.object.isRequired,
};
