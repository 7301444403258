import React from "react";
import {Styled} from "./ButtonGroup.styled"

const {ButtonGroupWrapper} = Styled

const ButtonGroup = ({ children, vertical }) => {
  return <ButtonGroupWrapper vertical={vertical}>{children}</ButtonGroupWrapper>;
};

export default ButtonGroup
