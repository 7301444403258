import { NumericLayerSetting } from "./NumericLayerSetting";

export class PercentageLayerSetting extends NumericLayerSetting {
  constructor({ currentValue = true, label, keyForDeck }) {
    super({
      currentValue,
      label,
      keyForDeck,
      minimum: 0,
      maximum: 1,
      step: 0.05,
      isRanged: false,
      isPercent: true,
    });
  }
}
