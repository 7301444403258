import { CompositeLayer } from "@deck.gl/core";
import { IconLayer, TextLayer } from "@deck.gl/layers";
import { transportIconMapper } from "../../iconMappers/transportIconMapper";
import { backgroundDirectionMapper } from "../../iconMappers/backgroundDirectionsMapper";
import moment from "moment-timezone";
import Supercluster from "supercluster";
import { roadObjectsMapping } from "../../iconMappers/roadObjectsMapping";
import { mapObjectsEnum } from "../../../../enums/mapObjectsEnum";
import { layerNamesEnum } from "../../../../enums/layerNamesEnum";

export const transportMonitoringSpeedResolverCSV = (d) => {
  let { speed } = d;
  //return rgba in array
  if (speed >= 0 && speed <= 1) {
    return [0, 0, 0];
  }
  if (speed > 1 && speed < 60) {
    return [46, 139, 87];
  } else {
    return [255, 0, 0];
  }
};

function getIconName(size) {
  if (size === 0) {
    return "";
  }
  if (size > 1 && size < 10) {
    return `marker-${size}`;
  }
  if (size < 100) {
    return `marker-${Math.floor(size / 10)}0`;
  }
  return "marker-100";
}

function getIconSize(size) {
  return Math.min(100, size) / 100 + 1;
}

function prepareClusterData(state, zoom) {
  const allWorldBbox = [-180, -85, 180, 85];
  const allData = state.index.getClusters(allWorldBbox, zoom);
  const clustersData = allData
    .map((cluster) => {
      const isRegularCluster = cluster?.id && cluster?.type;
      if (isRegularCluster) {
        return {
          cluster,
          leaves: state.index.getLeaves(cluster.id, 0, 0),
        };
      }
      return null;
    })
    .filter((item) => item !== null);

  return { allData, clustersData };
}

const deconstructGeoJsonItem = (item) => {
  const { geometry, properties } = item;
  return { ...geometry, ...properties };
};

const MAX_DELAY_FOR_LATE_IN_MINUTES = 5;

class MonitoringCompositeLayer extends CompositeLayer {
  updateState({ changeFlags, props, oldProps, ...other }) {
    if (props.data && props.data.length) {
      const { selectedObject } = props;
      const rebuildIndex = changeFlags;
      if (rebuildIndex) {
        const index = new Supercluster({ radius: 60 });
        index.load(
          props.data.map((d) => {
            const selected =
              selectedObject?.data?.properties?.vehicle_id === d.vehicle_id;
            const { coordinates, ...rest } = d;
            return {
              geometry: { coordinates },
              properties: { ...rest, selected },
            };
          })
        );
        this.setState({ index });
      }
      const z = Math.floor(props.zoom);
      if (rebuildIndex) {
        const { allData, clustersData } = prepareClusterData(this.state, z);
        this.setState({
          data: allData,
          clustersData,
          z,
            // selectedObject: this.props.selectedObject?.selectedObject?.object
        });
      }
    }
  }

  getPickingInfo(event) {
    if (event.mode === "query") {
      if (event.sourceLayer.id.includes("cluster")) {
        const clusterId = event.info.object.id;
        const vehiclesInCluster = this.state.index
          .getLeaves(clusterId, 0, 0)
          .map((item) => deconstructGeoJsonItem(item));
        this.props.onClickHandler(
          vehiclesInCluster,
          mapObjectsEnum.monitoringCluster,
          layerNamesEnum.monitoring
        );
      } else if (
        event.sourceLayer.id.includes("monitoring-auto-type-transport-layer")
      ) {
        const singleVehicle = deconstructGeoJsonItem(event.info.object);
        this.props.onClickHandler(
          singleVehicle,
          mapObjectsEnum.monitoringObject,
          layerNamesEnum.monitoring
        );
      } else return null;
    }
  }

  renderLayers() {
    return [
      //number of transport
      new TextLayer(
        this.props,
        this.getSubLayerProps({ id: "monitoring-auto-number-layer" }),
        {
          data: this.state.data,
          // characterSet: "0123456789АБВГДЕЁЖЗИЙКЛМНОПРСТУФХЦЧШЩЪЬЭЮЯ",
          getPosition: (d) => {
            if (!d.properties.cluster) {
              return d.geometry.coordinates;
            } else return [0, 0];
          },
          getText: (d) => {
            if (!d.properties.cluster) {
              return d.properties?.registration_number?.toUpperCase();
            } else {
              return " ";
            }
          },
          getPixelOffset: (d) =>
            d.properties.azimuth < 90 || d.properties.azimuth > 270
              ? [0, 32]
              : [0, -32],
          backgroundColor: [255, 255, 255],
          billboard: true,
          getOpacity: 1,
          sizeScale: 1,
          getSize: 16,
          getAngle: 0,
          getTextAnchor: "middle",
          getAlignmentBaseline: "center",
          sizeMinPixels: 16,
          sizeMaxPixels: 16,
          getPolygonOffset: ({ layerIndex }) => [0, -layerIndex * 10000],
        }
      ),
      //background of cluster
      new IconLayer(
        this.props,
        this.getSubLayerProps({ id: "icon-for-cluster-layer" }),
        {
          pickable: true,
          id: "icon",
          data: this.state.data,
          iconAtlas: `${process.env.PUBLIC_URL}/img/textures/road_objects.png`,
          iconMapping: roadObjectsMapping,
          sizeScale: 60 * this?.props?.sizeScale ?? 1,
          getPosition: (d) => d.geometry.coordinates,
          getIcon: (d) => {
            if (d.properties.cluster) {
              return "cluster";
            } else return " ";
          },
          getSize: (d) =>
            getIconSize(d.properties.cluster ? d.properties.point_count : 1),
          getPolygonOffset: ({ layerIndex }) => [0, -layerIndex * 10000],
        }
      ),
      // //number of items in cluster
      new TextLayer(
        this.props,
        this.getSubLayerProps({ id: "number-of-items-in-cluster-layer" }),
        {
          pickable: true,
          data: this.state.data,
          getPosition: (d) => {
            return d.geometry.coordinates;
          },
          getText: (d) => {
            if (d.properties.cluster) {
              return "" + d.properties.point_count;
            } else {
              return " ";
            }
          },
          billboard: true,
          getColor: [47, 128, 237],
          getOpacity: 1,
          sizeScale: this?.props?.sizeScale ?? 1,
          getSize: 20,
          getAngle: 0,
          getTextAnchor: "middle",
          getAlignmentBaseline: "center",
          sizeMinPixels: 20,
          sizeMaxPixels: 20,
          getPolygonOffset: ({ layerIndex }) => [0, -layerIndex * 10000],
        }
      ),
      //background of direction
      new IconLayer(
        this.props,
        this.getSubLayerProps({ id: "monitoring-auto-background-layer" }),
        {
          pickable: true,
          data: this.state.data,
          iconAtlas: `${process.env.PUBLIC_URL}/img/direction-background.png`,
          iconMapping: backgroundDirectionMapper,
          // getIcon: (d) => layersDataFieldResolver(d,'transportType'),
          getIcon: (d) => {
            if (!d.properties.cluster) {
              return d.properties.speed >= 1 ? "background" : " ";
            } else return " ";
          },
          billboard: false,
          getOpacity: 1,
          sizeScale: this.props.sizeScale || 1,
          getPosition: (d) => d.geometry.coordinates,
          getSize: () => {
            return 70 * this?.props?.iconSize || 1;
          },
          getAngle: (d) => -d.properties.azimuth,
          getColor: (d) => transportMonitoringSpeedResolverCSV(d.properties),
        }
      ),
      // //icon of transport
      new IconLayer(
        this.props,
        this.getSubLayerProps({ id: "monitoring-auto-type-transport-layer" }),
        {
          pickable: true,
          data: this.state.data,
          iconAtlas: `${process.env.PUBLIC_URL}/img/textures/vehiclesTypes.png`,
          iconMapping: transportIconMapper,
          getIcon: (d) => {
            if (!d.properties.cluster) {
                if (this.props?.selectedObject?.selectedObject?.transport_type===d.properties.transport_type && d?.properties?.device_id===this.props?.selectedObject?.selectedObject?.device_id) {
                return d.properties.transport_type + "_selected";
              } else {
                const userTZ = Intl.DateTimeFormat().resolvedOptions().timeZone;
                const date = moment.tz(d.properties.time_received, "UTC");
                date.tz(userTZ);
                const received = date.toDate();
                const delta = ~~((new Date() - new Date(received)) / 1000);
                if (delta > MAX_DELAY_FOR_LATE_IN_MINUTES * 60) {
                  return d.properties.transport_type + "_disabled";
                } else {
                  return d.properties.transport_type;
                }
              }
            } else return " ";
          },
          sizeScale: this.props.sizeScale || 1,
          billboard: true,
          getPosition: (d) => d.geometry.coordinates,
          getSize: () => {
            return 56 * this?.props?.sizeScale ?? 1;
          },
          getPolygonOffset: ({ layerIndex }) => [0, -layerIndex * 10000],
        }
      ),
    ];
  }
}

const defaultProps = {
  // Inherit all of GeoJsonLayer's props
  ...TextLayer.defaultProps,
  // Label for each feature
  getLabel: { type: "accessor", value: (x) => x },
  // Label size for each feature
  getLabelSize: { type: "accessor", value: 8 },
  // Label color for each feature
  getLabelColor: { type: "accessor", value: [0, 0, 0, 255] },
  getCurrentTimeStamp: { type: "accessor", value: 0 },
  // Label always facing the camera
  billboard: true,
  // Label size units
  labelSizeUnits: "pixels",
  // Label background color
  labelBackground: { type: "color", value: [255, 255, 255], optional: true },
  // Label font
  fontFamily: "Monaco, monospace",
};

MonitoringCompositeLayer.layerName = "TrackingLayer";
MonitoringCompositeLayer.defaultProps = defaultProps;

export default MonitoringCompositeLayer;
