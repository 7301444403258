import React from "react";
import { connect } from "react-redux";
import HomePage from "./HomePage";
import { compose } from "redux";
import withContentPadding from "../../../HOC/withContentPadding";

const HomePageContainer = ({ sidebarIsOpened }) => {
  return <HomePage sidebarIsOpened={sidebarIsOpened} />;
};

function mapStateToProps(state) {
  return {
    sidebarIsOpened: state.sidebarPosition.sidebarIsOpened,
  };
}

function mapDispatchToProps(dispatch) {
  return {};
}

const enhance = compose(
  withContentPadding,
  connect(mapStateToProps, mapDispatchToProps)
);

export default enhance(HomePageContainer);
