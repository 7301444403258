
import {LayerSchemaPrototype} from "../prototype/LayerSchemaPrototype";
import MonitoringCompositeLayer from "../../../../../../library/layers/CompositeLayers/Core/MonitoringCompositeLayer";
import GeozonesCompositeLayer from "../../../../../../library/layers/CompositeLayers/NIPIGAZ/GeozonesCompositeLayer";
import CargoTrackingCompositeLayer
    from "../../../../../../library/layers/CompositeLayers/NIPIGAZ/CargoTrackingCompositeLayer";

export class CargoTrackingLayerSchema extends LayerSchemaPrototype{

    constructor() {
        super({
            layerToRender: CargoTrackingCompositeLayer,
            id: 'CargoTracking',
            useZoomDeps : true,
        });
    }
}
