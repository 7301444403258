export const registeredDirectoryUrlsEnum = {
  users: "users",
  terminals: "terminals",
  organizations: "organizations",
  groups: "groups",
  states: "states",
  roads: "roads",
  geozones: "geozones",
  segments: "segments",
  vehicles: "vehicles",
  filters_user_organizations: "filters_user_organizations",
  organizations_forms: "organizations_forms",
  permissions: "permissions",
  //TODO need check railway reg urls
  railway_rail_ways: "railway_rail_ways",
  railway_trains: "railway_trains",
  railway_waggons: "railway_waggons",
  roads_checkpoint_segments: "roads_checkpoint_segments",
  roads_checkpoints: "roads_checkpoints",
  roads_closure_segments: "roads_closure_segments",
  roads_closures: "roads_closures",
  roads_roadworks_segments: "roads_roadworks_segments",
  roads_roadworks: "roads_roadworks",
  roads_segments_scheme_template_items: "roads_segments_scheme_template_items",
  roads_segments_scheme_templates: "roads_segments_scheme_templates",
  roads_segments_traffic_capacity: "roads_segments_traffic_capacity",
  roads_segments_lanes_amount: "roads_segments_lanes_amount",
  roads_segments_speed_limit: "roads_segments_speed_limit",
  roads_signgroups: "roads_signgroups",
  roads_signsegments: "roads_signsegments",
  tmc_tracking: "tmc_tracking",
  tmc_upload_report: "tmc_upload_report",
  tmc_location_point: "tmc_location_point",
  signs: "signs",
  tmc_location_point_names: "tmc_location_point_names",
  road_sign_types: "road_sign_types",
  road_closure_types: "road_closure_types",
  road_security_checkpoints_types: "road_security_checkpoints_types",
  road_work_types: "road_work_types",
  dock_types: "dock_types",
  dock_segments: "dock_segments",
  control_rooms_types: "control_rooms_types",
  overpasses_types: "overpasses_types",
  overpasses_segments: "overpasses_segments",
  roadworks_types: "roadworks_types",
  contracts: "contracts",
  passes: "passes",
  road_categories: "road_categories",
  geozones_types: "types_of_geozones",
  common_projects:"common_projects",
  common_subprojects:"common_subprojects",
  common_warehouses:"common_warehouses",
  etran_cargo:"etran_cargo",
  etran_contract:"etran_contract",
  country_names: "country_names",
  possible_shipper_names:"possible_shipper_names"
};
