import React from 'react';
import DisplayedLayersContainer from "./DisplayedLayers/DisplayedLayersContainer";
import DeckSettingsContentContainer from "./DeckSettingsContent/DeckSettingsContentContainer";

function LayersMenuContent({displayedContent}) {

    return (
        <>
            {displayedContent==='layers' && <DisplayedLayersContainer />}
            {displayedContent==='settings' && <DeckSettingsContentContainer />}
        </>
    );
}

export default LayersMenuContent;
