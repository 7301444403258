import React from "react";
import {Menu, Dropdown, Button} from "antd";
import Logout from "../../../GuestPage/Auth/Logout/Logout";
import styled from "styled-components";
import {Redirect} from "react-router-dom";
import ButtonGroup from "antd/es/button/button-group";
import {useTranslation} from "react-i18next";
import {useDispatch} from "react-redux";
import {Styled} from "../Header.styled"
import {Button as ButtonUI} from "../../../../library/UI/components/Button/Button" ;
import {UserOutlineIcon} from "../../../../library/icons/actionIcons/UserOutlinedIcon";
import {iconSizesKit} from "../../../../library/UI/utils/iconSizesKit";



const menu = (i18n,dispatch) => (
    <Menu>
        <Menu.Item>
            <Logout/>
        </Menu.Item>
        <Menu.Item>
            <ButtonGroup>
                <Button onClick={()=>{
                    localStorage.setItem('locale','ru-RU')
                    i18n.changeLanguage('ru-RU')
                    dispatch({
                        type: 'LANGUAGE_CHANGED',
                        value: 'ru-RU'
                    })

                }}>Русский</Button>
                <Button onClick={()=>{
                    localStorage.setItem('locale','en-EN')
                    i18n.changeLanguage('en-EN')
                    dispatch({
                        type: 'LANGUAGE_CHANGED',
                        value: 'en-EN'
                    })

                }}>Английский</Button>
            </ButtonGroup>
        </Menu.Item>
    </Menu>
);

const ExtendedDropdown = styled(Dropdown)`
  display: flex;
  align-items: center;
  .user-block__avatar{
    fontSize: 18px;
    color: ${props => props.theme.colors.defaultLighter};
  }
  
  .header-link{
    padding: 30px;
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 22px;
    display: flex;
    align-items: center;
    color: rgba(0, 0, 0, 0.85);
  }
`;


const UserSettings = (props) => {
    const {i18n } = useTranslation('common');
    const dispatch = useDispatch()
    const {user, avatar} = props;
    let username = user;
    if (!user) username = localStorage.getItem('user')
    let avatarImg = avatar;
    if (username){
        let usernameChars = username.includes('_') ? username.split('_') : [username[0],username[1]];
        if (usernameChars.length > 1) {
            if (!avatarImg && username) avatarImg = usernameChars[0][0].toUpperCase() + usernameChars[1][0].toUpperCase()
        } else if (usernameChars.length === 1) {
            if (!avatarImg && username) avatarImg = usernameChars[0].toUpperCase() + usernameChars[1].toUpperCase()
        }

        return (
            <ExtendedDropdown overlay={()=>menu(i18n,dispatch)}>
                <div
                    className={'user-block__container'}
                    onClick={e => e.preventDefault()}
                >
                    <Styled.LocalHeaderNotification>
                        <ButtonUI icon={<UserOutlineIcon/>} rounded type={"unbounded"} iconSize={iconSizesKit.largest} />
                    </Styled.LocalHeaderNotification>
                </div>
            </ExtendedDropdown>
        )
    } else return <Redirect to={'/guest/login'}/>

}

export default UserSettings;
