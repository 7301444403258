import styled from "styled-components";

const LocalMainWrapper = styled.div`
  width: 315px;
  height: 435px;
  background: #f1f3f6;
  border-radius: 8px;
`;

const LocalCheckboxWrapper = styled.div`
  & path {
    color: #008291;
    width: 15px;
    height: 15px;
  }
  & span {
    color: #495e5f;
  }
`;

const LocalSelectWrapper = styled.div`
  & :focus {
    background: white;
    border-radius: 8px;
  }

  & button svg {
    width: 13px;
    height: 18px;
  }
`;

const LocalScrollableMainWrapper = styled.div`
  overflow-y: scroll;
  height: 316px;
`;

const LocalScrollableInnerWrapper = styled.div`
  height: 100%;
`;

const LocalTopWrapper = styled.div`
  height: auto;
`;

export const Styled = {
  LocalMainWrapper,
  LocalCheckboxWrapper,
  LocalSelectWrapper,
  LocalScrollableMainWrapper,
  LocalScrollableInnerWrapper,
  LocalTopWrapper,
};
