import React from "react";
import { Styled } from "./ScrollableCard.styled";
import PropTypes from "prop-types";

const {
  ExtendedPanelFooter,
  ExtendedPanelWrapper,
  ExtendedPanelHeader,
  ExtendedPanelContent,
} = Styled;

const ScrollableCard = ({
  headerContent,
  children,
  groupItems,
  footerContent,
  cardHeight,
  headerStyles = {},
  footerStyles = {},
  contentStyles = {},
  wrapperStyles = {},
}) => {
  return (
    <ExtendedPanelWrapper style={wrapperStyles} cardHeight={cardHeight}>
      <ExtendedPanelHeader style={headerStyles}>
        {headerContent}
      </ExtendedPanelHeader>
      {children && (
        <ExtendedPanelContent style={contentStyles}>
          {children}
        </ExtendedPanelContent>
      )}
      {groupItems && (
        <ExtendedPanelContent style={contentStyles}>
          {groupItems}
        </ExtendedPanelContent>
      )}
      <ExtendedPanelFooter style={footerStyles}>
        {footerContent}
      </ExtendedPanelFooter>
    </ExtendedPanelWrapper>
  );
};

export default ScrollableCard;

ScrollableCard.propTypes = {
  cardHeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  children: PropTypes.node,
  footerContent: PropTypes.node,
  headerContent: PropTypes.node,
};

ScrollableCard.defaultProps = {
  cardHeight: "350px",
};
