import styled from "styled-components";

const LocalWrapper = styled.div`
  font-size: ${props => props.theme.fontSettings.default};
  padding: ${props => props.theme.paddings.smallPadding};
  color: ${props => props.fontColor || props.theme.colors.paragraph};
  line-height: 20px;
  display: flex;
  transition: 0.6s;
  width: 100%;
  border-radius: ${props => props.theme.decoration.defaultBorderRadius};
  span {
    margin-left: 20px;
  }

  & > * {
    color: inherit;
  }

  :hover {
    color: ${props => props.fontColor ?? props.theme.colors.paragraph};
    background: ${props => props.theme.colors.defaultLight};
  }
`;

export const Styled = { LocalWrapper };
