import React, { useState } from "react";
import { Collapse } from "react-collapse";
import { Styled } from "./CollapsableItem.styled";
import { ArrowToDown } from "../../../icons/actionIcons/ArrowToDown";
import PropTypes from "prop-types";

const { LocalMainWrapper, LocalTitleGroup, LocalTitleItem } = Styled;

export const CollapsableItem = ({
  titleContent,
  onClick,
  children,
  // isOpened,
  arrowIcon,
  transitionDurationInMs,
  arrowIconOffset,
  defaultOpened,
  rotateDegree
}) => {
  const icon = arrowIcon || <ArrowToDown />;
  const [isOpened, setIsOpened] = useState(defaultOpened);

  return (
    <LocalMainWrapper transitionDurationInMs={transitionDurationInMs}>
      <LocalTitleGroup
        onClick={() => {
          onClick && onClick();
          setIsOpened((prev) => !prev);
        }}
      >
        <LocalTitleItem>{titleContent}</LocalTitleItem>
        <LocalTitleItem rotateDegree={rotateDegree} arrowIconOffset={arrowIconOffset} isOpened={isOpened}>
          {icon}
        </LocalTitleItem>
      </LocalTitleGroup>
      <Collapse onRest={console.log} onWork={console.log} isOpened={isOpened}>
        {children}
      </Collapse>
    </LocalMainWrapper>
  );
};

CollapsableItem.propTypes = {
  arrowIcon: PropTypes.element,
  arrowIconOffset: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  children: PropTypes.any,
  defaultOpened: PropTypes.bool,
  isOpened: PropTypes.bool,
  onClick: PropTypes.func,
  rotateDegree: PropTypes.number,
  titleContent: PropTypes.any,
  transitionDurationInMs: PropTypes.number
}

CollapsableItem.defaultProps = {
  arrowIconOffset: "0.5rem",
  defaultOpened: true,
  transitionDurationInMs: 300,
  rotateDegree: 180
};
