export const mapObjectsEnum = {
  roadSegment: "segment",
  trafficSegment: "trafficSegment",
  road: "road",
  roadSign: "road_sign",
  securityCheckpoint: "security_checkpoint",
  roadBlock: "road_block",
  roadWorks: "road_works",
  deckGLMap: "deckGLMap",
  geozone: "geozone",
  monitoringObject: "monitoringObject",
  monitoringCluster: "monitoringCluster",
  railway: "railway",
  dischargeZone: "discharge_zone",
  receptionArea: "reception_area",
  cargoTrackingPoint: "cargoCityPoints",
  clusterCargos: "clusterCargos",
  trackingVehicle: "trackingVehicle",
  roadOverpass: "road_overpass",
  dockSegment: "dock_segment",
  controlRoomsSegment: "control_room",
  singleCargo: "singleCargo",
  trafficBackgroundRoadSegment: "trafficBackgroundRoadSegment",
  railwayWagons: "railwayWagons",
  signs: "signs",
};
