import React from "react";
import { Icon } from "antd";

const RoadSignsIconSVG = () => (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M4.89167 0.5L0.5 4.89167V11.1083L4.89167 15.5H11.1083C12.5833 14.0333 15.5 11.1083 15.5 11.1083V4.89167L11.1083 0.5H4.89167ZM5.58333 2.16667H10.4167L13.8333 5.58333V10.4167L10.4167 13.8333H5.58333L2.16667 10.4167V5.58333L5.58333 2.16667ZM7.16667 10.5H8.83333V12.1667H7.16667V10.5ZM7.16667 3.83333H8.83333V8.83333H7.16667V3.83333Z" fill="#1B3548"/>
    </svg>

);

export const RoadSignsIcon = props => (
    <Icon component={RoadSignsIconSVG} {...props} />
);
