import React from "react";
import {useDispatch, useSelector} from "react-redux";
import ErrorMessage from "./ErrorMessage";
import {clearGlobalAlertData} from "../../reducers/app/actions/notificationActions";

const ErrorMessageContainer = (props) => {
    const errMessage = useSelector(state => state.app.message);
    const dispatch = useDispatch();

    const handleClose = () => {
        dispatch(clearGlobalAlertData())
    }

    const containerCallbacks = {
        handleClose
    }

    return (
        <>
            {errMessage && <ErrorMessage message={errMessage} containerCallbacks={containerCallbacks}/>}
        </>
    )
};


export default ErrorMessageContainer;
