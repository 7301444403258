import { CompositeLayer } from "@deck.gl/core";
import { PolygonLayer } from "@deck.gl/layers";
import { IconLayer } from "@deck.gl/layers";
import turfCenterOfMass from "@turf/center-of-mass";
import wkt from "wkt";
import { layerNamesEnum } from "../../../../enums/layerNamesEnum";
import { mapObjectsEnum } from "../../../../enums/mapObjectsEnum";
import mapDataAccessorSelectedObjects, {SELECT_OBJECTS_IN_DATA_ACCESSOR} from "../../../../reducers/map/mapDataAccessorSelectedObjects";

class GeozonesCompositeLayer extends CompositeLayer {
  updateState({ changeFlags, props }) {
    // console.log("renderUpdate");

    // если тип класса записи соответсвует текущему классу, то прокинем запись в стейт
    const selectedLayer = this.props.selectedObject?.selectedObject?.layer;
    if (selectedLayer instanceof GeozonesCompositeLayer){
      this.setState({
        selectedObject: this.props.selectedObject?.selectedObject?.object
      })
    } else {
      this.setState({selectedObject:null})
    }
    this.setState({
      geozonesTypes: this.props?.relatedData?.geozonesTypes,
    });

  }

  getPickingInfo(event,other) {
    if (event.mode === "query") {
      if (this.props.onClickHandler) {
        let selectedObjectType = null;
        let selectedObjectLayerName = layerNamesEnum.geozones;
        if (event.sourceLayer.id.includes("geozones")) {
          selectedObjectType = mapObjectsEnum.geozone;
        }
        this.props.onClickHandler(
          event.info,
          selectedObjectType,
          selectedObjectLayerName
        );
      } else return null;
    }

  }


  renderLayers() {
    const ICON_MAPPING = {
      geozones: { x: 0, y: 100, width: 48, height: 43 },
      geozones_selected: { x: 49, y: 100, width: 48, height: 43 },
      parking: { x: 49, y: 2, width: 48, height: 43 },
      parking_selected: { x: 49, y: 51, width: 48, height: 43 },
      warehouse: { x: 0, y: 2, width: 48, height: 43 },
      warehouse_selected: { x: 0, y: 51, width: 48, height: 43 },
    };
    if (!this.props.relatedData) return [];

    return [
      new PolygonLayer(
        this.props,
        this.getSubLayerProps({ id: "geozone-layer" }),
        {
          data: this.props.relatedData.geozones,
          stroked: true,
          filled: true,
          pickable: true,
          wireframe: true,
          lineWidthMinPixels: 1,
          getPolygon: (d) => {
            if (d.polygon) {
              const parsed = wkt.parse(d.polygon);
              return parsed
                ? parsed.coordinates[0]
                : [
                    [0, 0],
                    [0.1, 0.1],
                    [0.11, 0.11],
                  ];
            } else {
              return [
                [0, 0],
                [0.1, 0.1],
                [0.11, 0.11],
              ];
            }
          },
          getFillColor: (d) => {
            const defaultTypeGeozone = [100, 100, 100, 100];
            const { geozone_type } = d; // geozone_type from d
            const isThisLayerActive = this.state.selectedObject?.id === d.id;
            let rgbaArray;
            if (geozone_type) {
              this.state.geozonesTypes.forEach(item=>{
                if (item.id===geozone_type) {
                  rgbaArray=item.rgba_color.match(/\d+/g).map((item) => parseInt(item));
                }
              })
              if (isThisLayerActive) {return [rgbaArray[0],rgbaArray[1],rgbaArray[2], 80]} else {return [rgbaArray[0],rgbaArray[1],rgbaArray[2], 30]}
            }
            else return defaultTypeGeozone
          },
          updateTriggers: {
            getFillColor: this.state.selectedObject,
            getPolygon: this.state.geozonesTypes,
          },
          getLineColor: (d) => {
            const { geozone_type } = d;
            const defaultTypeGeozone = [100, 100, 100, 100];
            let rgbaArray

            if (geozone_type) {
              this.state.geozonesTypes.forEach(item=>{
                if (item.id===geozone_type) {
                  rgbaArray=item.rgba_color.match(/\d+/g).map((item) => parseInt(item));
                }
              })
              return [rgbaArray[0],rgbaArray[1],rgbaArray[2], 250]
            }
            else return defaultTypeGeozone
          },
          getLineWidth: 1,
          lineJointRounded: true,
        }
      ),

      new IconLayer(this.props, this.getSubLayerProps({ id: "icon-layer" }), {
        data: this.props.relatedData.geozones,
        iconMapping: ICON_MAPPING,
        getIcon: (d) => {
          switch (d.geozone_type) {
            case 1: {
              if (this.props?.selectedObject?.selectedObject?.object?.geozone_type===1 && d.id===this.props?.selectedObject?.selectedObject?.object?.id) {return "parking_selected"}
              else {return "parking"}
            }
            case 2: {
              if (this.props?.selectedObject?.selectedObject?.object?.geozone_type===2 && d.id===this.props?.selectedObject?.selectedObject?.object?.id) {return "warehouse_selected"}
              else {return "warehouse"}
            }
            case 3: {
              if (this.props?.selectedObject?.selectedObject?.object?.geozone_type===3 && d.id===this.props?.selectedObject?.selectedObject?.object?.id) {return "geozones_selected"}
              else {return "geozones"}
            }
          }
          return null;
        },
        iconAtlas: `${process.env.PUBLIC_URL}/img/textures/geozoneTypes.png`,
        pickable: true,
        getSize: 40,
        fp64: true,
        getPolygonOffset: ({ layerIndex }) => [0, -layerIndex * 10000],
        sizeScale: 1 * this?.props?.sizeScale ?? 1,
        sizeMinPixels: 10,
        getPosition: (d) => {
          if (d.polygon) {
            const parsed = wkt.parse(d.polygon);
            const center = turfCenterOfMass(parsed);
            return center.geometry.coordinates;
          } else return [0, 0];
        },
      }),
    ];
  }
}

const defaultProps = {
  // Inherit all of GeoJsonLayer's props
  ...PolygonLayer.defaultProps,
  // Label for each feature
  getLabel: { type: "accessor", value: (x) => x },
  // Label size for each feature
  getLabelSize: { type: "accessor", value: 8 },
  // Label color for each feature
  getLabelColor: { type: "accessor", value: [0, 0, 0, 255] },
  getCurrentTimeStamp: { type: "accessor", value: 0 },
  // Label always facing the camera
  billboard: true,
  // Label size units
  labelSizeUnits: "pixels",
  // Label background color
  labelBackground: { type: "color", value: [255, 255, 255], optional: true },
  // Label font
  fontFamily: "Monaco, monospace",
};

GeozonesCompositeLayer.layerName = "GeozonesLayer";
GeozonesCompositeLayer.defaultProps = defaultProps;

export default GeozonesCompositeLayer;
