import React from 'react';
import PropTypes from 'prop-types';
import {connect} from "react-redux"
import MapRasterStyleSelector from "./MapRasterStyleSelector";
import {onMapStyleChanged} from "../../../../../../../../../../../reducers/map/actions/mapboxActions";
import {changeRasterTileStyle} from "../../../../../../../../../../../reducers/map/actions/mapPanelsActions";

const MapRasterStyleSelectorContainer = ({currentStyle,changeMapStyle}) => {

    const handleMapStyleChange = (newStyle) => {
        changeMapStyle(newStyle)
    }
    return (
        <MapRasterStyleSelector currentStyle={currentStyle} handleMapStyleChange={handleMapStyleChange} />
    );
};

MapRasterStyleSelectorContainer.propTypes = {

};

const mapStateToProps = (state) => {
    return {
        currentStyle : state.mapPanels.rasterStyle,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        changeMapStyle : (newStyle) => dispatch(changeRasterTileStyle(newStyle))
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(MapRasterStyleSelectorContainer);
