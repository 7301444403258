import styled from "styled-components";

const TimePlayerMainWrapper = styled.section`
  box-shadow: ${props => props.theme.decoration.defaultBoxShadow};
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
  flex-wrap: wrap;
  width: 35%;
  right: 80px;
  position: absolute;
  z-index: 5;
  bottom: 60px;
`;

const TimePlayerContentWrap = styled.div`
  padding: ${props => props.theme.paddings.defaultPadding};
  border-radius: ${props =>
    props.theme.decoration.defaultBorderRadiusOnlyBottom};
  background: rgba(255, 255, 255, 0.8);
  backdrop-filter: blur(18px);
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin: 0 -10px;
  & > * {
    margin: 0 10px;
  }
  & > *:first-child {
    flex: 1;
  }
  & > *:last-child {
    flex: 20;
  }
`;

const TimePlayerContentControlWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  margin: -7px 0;
  & > * {
    margin: 7px 0;
  }
`;

const TimePlayerContentSliderWrap = styled.div``;

const TimePlayerHeaderWrap = styled.div`
  background: ${props => props.theme.colors.defaultLighter};
  border-radius: ${props => props.theme.decoration.defaultBorderRadiusOnlyTop};
  padding: 1rem;
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
`;

const TimePlayerHeaderInfo = styled.div`
  font-size: ${props => props.theme.fontSettings.large};
  font-weight: bold;
`;

const TimePlayerHeaderControls = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 -10px;
  & > * {
    margin: 0 10px;
  }
  & > button {
    padding: 0;
  }
`;

export const Styled = {
  TimePlayerMainWrapper,
  TimePlayerContentControlWrap,
  TimePlayerContentSliderWrap,
  TimePlayerContentWrap,
  TimePlayerHeaderWrap,
  TimePlayerHeaderInfo,
  TimePlayerHeaderControls
};
