import styled from "styled-components";
import { Link } from "react-router-dom";
import { baseStyles } from "../../../components/stylesRegistrator";

const { CutStringWithEllipsisOnTheEnd } = baseStyles.mixins.text;

const Content = styled.span`
  display: ${(props) => (props.sidebarIsOpened ? "none" : "block")};
  ${CutStringWithEllipsisOnTheEnd}
  color: ${(props) => props.theme.colors.defaultLight};
`;

const Icon = styled.i`
  color: ${(props) => props.theme.colors.defaultLight};
  display: flex;
  align-items: center;
  padding-right: ${(props) => (props.sidebarIsOpened ? "0" : "1rem")};
`;

const Item = styled("button")`
  justify-content: ${(props) => props.sidebarIsOpened && "center"};
  padding: 0.85rem;
  padding-left: 0;
  outline: none;
  display: flex;
  z-index: 1;
  font-size: 1rem;
  position: relative;
  background: transparent;
  border: none;
  cursor: pointer;
  width: 100%;
  transition: all 0.3s;
  &:hover ${Icon}, &:hover ${Content} a {
    color: ${(props) => props.theme.colors.defaultLighter};
  }
  &:after {
    background: ${(props) => props.theme.colors.primary};
    opacity: ${(props) => (props.active ? 1 : 0)};
    width: 105%;
    height: 100%;
    content: "";
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
    border-bottom-left-radius: 25px;
    border-top-left-radius: 25px;
    transition: all 0.2s;
  }
`;

export const Styled = { Item, Content, Icon };
