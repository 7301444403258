import { useEffect } from "react";
import { getWaggonsByUrl } from "../fns/getWaggonsByUrl";
import { makeLocalStateItem } from "../fns/makeLocalStateItem";
import { waggonsUnloadingStatusEnums } from "../../../../../../enums/waggonsUnloadingStatusEnums";

export function useReceptionAreaWaggonsData({
  initialData,
  setModuleState,
  receptionAreasUrl,
  handleChangeFetchingStatus,
  handleChangeNeedReloadingStatus,
  deps = []
}) {
  useEffect(() => {
    getWaggonsByUrl(
      receptionAreasUrl,
      initialData?.reception_area,
      handleChangeFetchingStatus
    ).then(data => {
      const preparedWaggons = data.map(waggon =>
        makeLocalStateItem(waggon, waggonsUnloadingStatusEnums.NOT_UNLOADED)
      );
      setModuleState(prev => ({
        ...prev,
        [waggonsUnloadingStatusEnums.NOT_UNLOADED]: {
          ...prev[waggonsUnloadingStatusEnums.NOT_UNLOADED],
          data: preparedWaggons
        }
      }));
    });
    handleChangeNeedReloadingStatus(false)
  }, deps);
}
