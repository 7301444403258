import React from "react";
import _ from "underscore";
import { connect } from "react-redux";
import { directoryHistoryActions } from "../../../../../reducers/directory/actions/directoryHistoryActions";
import DirectoryDataListTemplate from "./Templates/DirectoryDataListTemplate";
import DirectoryObjectTemplate from "./Templates/DirectoryObjectTemplate";
import InlineDataListTemplate from "./Templates/InlineDataListTemplate";
import { useWhyDidYouUpdate } from "react-recipes";
import { compose } from "redux";
import withContentPadding from "../../../../../HOC/withContentPadding";
import InlineObjectTemplate from "./Templates/InlineObjectTemplate";
import PathWatcherContainer from "./PathWatcherContainer";
import { getLastHistoryRecord } from "../../../../../reducers/directory/directoryHistory";

const { postToInlineHistory, clearInlineHistory } = directoryHistoryActions;

const DirectoryBuilder = (props) => {
  //dev
  useWhyDidYouUpdate("DirectoryBuilder", props);
  const { allInlineHistory, folder, id, lastInHistory = {} } = props;

  const {
    data = null,
    model = null,
    field = null,
    urlData = null,
  } = lastInHistory;

  let ResolvedDirectory = null;

  if (!_.isEmpty(allInlineHistory)) {
    const displayedDirectory = _.last(allInlineHistory) || null;
    if (displayedDirectory?.type) {
      switch (displayedDirectory.type) {
        case "main": {
          ResolvedDirectory = (
            <DirectoryDataListTemplate
              lastInHistory={lastInHistory}
              folder={folder}
              data={data}
              model={model}
              urlData={urlData}
            />
          );
          break;
        }
        case "mainObject": {
          ResolvedDirectory = (
            <DirectoryObjectTemplate
              folder={folder}
              id={id}
              data={data}
              model={model}
              field={field}
            />
          );
          break;
        }
        case "inline": {
          ResolvedDirectory = <InlineDataListTemplate folder={folder} />;
          break;
        }
        case "inlineObject": {
          ResolvedDirectory = <InlineObjectTemplate folder={folder} />;
          break;
        }
      }
    }
  }
  return (
    <>
      <PathWatcherContainer />
      {ResolvedDirectory}
    </>
  );
};

const mapStateToProps = (state) => ({
  allInlineHistory: state.directoryHistory,
  lastInHistory: getLastHistoryRecord(state),
});

const mapDispatchToProps = (dispatch) => ({
  pushInlineHistory: (historyRecord) =>
    dispatch(postToInlineHistory(historyRecord)),
  cleanInlineHistory: () => dispatch(clearInlineHistory()),
});

const enhance = compose(
  withContentPadding,
  connect(mapStateToProps, mapDispatchToProps)
);

export default enhance(DirectoryBuilder);
