import React, { useEffect, useState } from "react";
import { Select } from "antd";
import { getFromURL } from "../../../../../../../../../api/getFromURL";

const { Option } = Select;

const EmailDataPeriodicity = ({ handleSetData }) => {
  const [dataEmail, setData] = useState([]);
  useEffect(() => {
    (async () => {
      const response = await getFromURL.getDataFromURL(
        "reports/reports/%20device_summary/lost_signal_report"
      );
      setData(response.periodicities);
    })();
  }, []);

  function handleChange(value) {
    handleSetData({ periodicities: value });
  }

  return (
    <Select
      style={{ width: "100%" }}
      // open={true}
      // value={options}
      placeholder="Периодичность отправки"
      onChange={(value) => {
        handleChange(value);
      }}
      bordered={false}
    >
      {dataEmail.map((data) => (
        <Option key={data.id}>{data.name}</Option>
      ))}
    </Select>
  );
};

export default EmailDataPeriodicity;
