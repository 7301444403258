import React, {useRef} from "react";
import {useTranslation} from "react-i18next";
import {commonSelectedObjectProtoUtils} from "../commonUtils/commonSelectedObjectProtoUtils";
import {registeredDirectoryUrlsEnum} from "../../../../enums/registeredDirectoryUrlsEnum";
import PrepareScrollableInfoCard from "../PrepareScrollableInfoCard";

const {
    parseCoordinatesToDesc,
    commonIconSizeForCurrentSection,
} = commonSelectedObjectProtoUtils;

const RoadSchemeItemSelection = ({
                                     handleCloseMapSelectedObjectInfo,
                                     selectedMapItem,
                                     data,
                                 }) => {
    const openWindow = (point, id) => {
        const win = window.open(
            `/app/directory/${point}/${id}`,
            "_blank"
        );
        win.focus();
    }

    const searchId = (roadData) => {
        const {id} = roadData[0].data;
        return id
    }

    const dataPoint = (roadData) => {
        const {name} = roadData[0];
        switch (name) {
            case "security checkpoint":
                openWindow(registeredDirectoryUrlsEnum.roads_checkpoint_segments, searchId(roadData))
                break;

            case "road":
                openWindow(registeredDirectoryUrlsEnum.roads, searchId(roadData))
                break;

            case "road sign":
                openWindow(registeredDirectoryUrlsEnum.roads_signsegments, searchId(roadData))
                break;

            case "road works":
                openWindow(registeredDirectoryUrlsEnum.roads_roadworks_segments, searchId(roadData))
                break;

            case "road overpass":
                openWindow(registeredDirectoryUrlsEnum.overpasses_segments, searchId(roadData))
                break;

            case "road block":
                openWindow(registeredDirectoryUrlsEnum.roads_closure_segments, searchId(roadData))
                break;

            case "docks segments":
                openWindow(registeredDirectoryUrlsEnum.dock_segments, searchId(roadData))
                break;
        }
    }

    const handleOpenObjectInNewTab = () => {
        const roadData = selectedMapItem.data.map(dataRecord => {
            return dataRecord
        })
        dataPoint(roadData)
    };
    const POIType = ["road_block", "road_works"]
    const POIDate = selectedMapItem.type;

    const dataSelectedMap = selectedMapItem?.selectedObject?.object

    const {name} =  selectedMapItem?.data[0]
    const {t} = useTranslation("common");

    const titleCardName = () => {
        if(name === 'road sign'){
            return t("app.menuMap.scrollableInfoCard.header.schemaRoadSign")
        }return t("app.menuMap.scrollableInfoCard.header.schemaRoadLayer")
    }

    let Descriptions = [];
    Descriptions.push({
        key: t("app.menuMap.dataVehicle.coordinates"),
        value: parseCoordinatesToDesc(selectedMapItem),
    });

    dataSelectedMap.name && !dataSelectedMap.checkpoint_type &&
        Descriptions.push({
        key: name === 'road_sign' ? "Дорожный знак" : "Наименование дороги",
        value: dataSelectedMap.name
    });

    dataSelectedMap.checkpoint_type_name !== undefined &&
    Descriptions.push({
        key: "Тип КПП",
        value: dataSelectedMap.checkpoint_type_name ? dataSelectedMap.checkpoint_type_name : "Не указан",
    });

    dataSelectedMap.name && dataSelectedMap.checkpoint_type &&
    Descriptions.push({
        key: "Номер КПП",
        value: dataSelectedMap.name,
    });

    !POIType.includes(POIDate) && dataSelectedMap.total_length &&
    Descriptions.push({
        key: "Протяженность дороги",
        value: `${dataSelectedMap.total_length?.toFixed(2)} км`,
    });
debugger
    data.owner_organization_name &&
    Descriptions.push({
        key: "Собственник",
        value: data.owner_organization_name ? data.owner_organization_name : "Не указан",
    });

    dataSelectedMap.name && !dataSelectedMap.checkpoint_type &&
    Descriptions.push({
        key: name === 'road sign' ? "Номер дорожного знака" : "Наименование сегмента" ,
        value:  name === 'road_sign' ? dataSelectedMap.road_sign_number : dataSelectedMap?.name
    });

    dataSelectedMap.start_km >= 0 &&
    Descriptions.push({
        key: "Начало участка",
        value: dataSelectedMap?.start_km ? Number(dataSelectedMap?.start_km.toFixed(3)) : dataSelectedMap?.start_km,
    });

    dataSelectedMap.end_km >= 0 &&
    Descriptions.push({
        key: "Конец участка",
        value: dataSelectedMap?.end_km ? Number(dataSelectedMap?.end_km.toFixed(3)) : dataSelectedMap?.end_km,
    });

    dataSelectedMap.total_length &&
    Descriptions.push({
        key: "Протяженность сегмента",
        value: dataSelectedMap?.total_length ? Number(dataSelectedMap?.total_length.toFixed(3)) : dataSelectedMap?.total_length,
    });

    dataSelectedMap.description &&
    Descriptions.push({
        key: "Описание",
        value: dataSelectedMap.description ?? "-",
    });

    dataSelectedMap.gates_width &&
    Descriptions.push({
        key: "Ширина ворот",
        value: dataSelectedMap?.gates_width +" м" ?? "- м",
    });
    return (
        <PrepareScrollableInfoCard
            title={titleCardName()}
            handleCloseCard={handleCloseMapSelectedObjectInfo}
            handleOpenItemInDirectory={handleOpenObjectInNewTab}
            listOfDescriptions={Descriptions}
            openInNewTabBtnDesc={t("app.menuMap.dataVehicle.openInDirectory")}
        />
    );
};

RoadSchemeItemSelection.propTypes = {};

export default RoadSchemeItemSelection;
