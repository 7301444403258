import React from 'react';
import PropTypes from 'prop-types';
import {setMainMapPanelContent} from "../../../../../../../../../reducers/map/actions/mapPanelsActions";
import {connect} from 'react-redux'
import LayersContentSwitcher from "./LayersContentSwitcher";
import {StackIcon} from "../../../../../../../../../library/icons/objectIcons/StackIcon";
import {DownloadIcon} from "../../../../../../../../../library/icons/actionIcons/DownloadIcon";
import {SettingsIcon} from "../../../../../../../../../library/icons/actionIcons/SettingsIcon";
import ToggleButtonGroup from "../../../../../../../../../library/UI/components/ToggleButtonGroup/ToggleButtonGroup";
import {useTranslation} from "react-i18next";


const SWITCHER_BUTTONS_TEMPLATE = [
    {
        id: 1,
        icon: <StackIcon key={1}/>,
        name: 'app.menuMap.switcherLayer',
        switcher: 'layers'
    },
 /*   {
        id: 2,
        icon: <DownloadIcon key={2}/>,
        name: 'app.menuMap.switcherDownload',
        switcher: 'templates'
    },*/
    {
        id: 3,
        icon: <SettingsIcon key={3}/>,
        name: 'app.menuMap.switcherSettings',
        switcher: 'settings'
    },
]

const LayersContentSwitcherContainer = ({setPanelContext,activeMapPanelContent}) => {

    const handleChangePanelContext = (value) => {
        setPanelContext(value)
    }

    const buttonClickHandler = (element) => {
        handleChangePanelContext(element.switcher)
    }

    return (
        <ToggleButtonGroup
            handleChangePanelContext={handleChangePanelContext}
            buttonClickHandler={buttonClickHandler}
            activePanel={activeMapPanelContent}
            displayedButtons={SWITCHER_BUTTONS_TEMPLATE}
            />
    );
};

LayersContentSwitcherContainer.propTypes = {

};

const mapDispatchToProps = (dispatch) => {
    return {
        setPanelContext : (value) => dispatch(setMainMapPanelContent(value))
    }
}

const mapStateToProps = (state) => {
    return {
        activeMapPanelContent : state.mapPanels.selectedMainContext,
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(LayersContentSwitcherContainer);
