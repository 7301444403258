import { mapObjectsEnum } from "../../../../../../enums/mapObjectsEnum";
import { CommonSelectedItemContextMenuPrototype } from "../../prototypes/CommonSelectedItemContextMenuPrototype";
import { mapContextMenuContentTypesEnum } from "../../../../../../enums/mapContextMenuContentTypesEnum";
import React from "react";
import { groupTypes } from "../../../contentGroups";
import { initializeSingleObjectEditMode } from "../../../../../../sagas/map/standaloneObjectEditableSaga";
import { CheckpointIcon } from "../../../../../../library/icons/contextMenuIcons/CheckpointIcon";
import { RoadWorksIcon } from "../../../../../../library/icons/contextMenuIcons/RoadWorksIcon";
import { RoadSignsIcon } from "../../../../../../library/icons/contextMenuIcons/RoadSignsIcon";
import { BlockadesIcon } from "../../../../../../library/icons/contextMenuIcons/BlockadesIcon";
import {ControlRoomIcon} from "../../../../../../library/icons/contextMenuIcons/ControlRoom";
import {DockIcon} from "../../../../../../library/icons/contextMenuIcons/Dock";
import {OverpassIcon} from "../../../../../../library/icons/contextMenuIcons/Overpass";
const { ADD_GROUP } = groupTypes;

export class RoadSegmentDescription extends CommonSelectedItemContextMenuPrototype {
  initialCoordinate = null;
  constructor(selectedObject, layerName, initialCoordinate) {
    super(selectedObject, mapObjectsEnum.roadSegment, layerName);
    this.initialCoordinate = initialCoordinate;
  }

  actionItems = [
    ...this.actionItems,
    {
      name: mapContextMenuContentTypesEnum.createSecurityCheckpoint,
      translatePath: "Кпп",
      group: ADD_GROUP,
      dispatchedAction: () =>
        initializeSingleObjectEditMode(
          this.selectedObject,
          mapObjectsEnum.securityCheckpoint,
          this.layerName,
          this.initialCoordinate
        ),
      icon: <CheckpointIcon />,
    },
    {
      name: mapContextMenuContentTypesEnum.createRoadSign,
      translatePath: "Дорожный знак",
      group: ADD_GROUP,
      dispatchedAction: () =>
        initializeSingleObjectEditMode(
          this.selectedObject,
          mapObjectsEnum.roadSign,
          this.layerName,
          this.initialCoordinate
        ),
      icon: <RoadSignsIcon />,
    },
    {
      name: mapContextMenuContentTypesEnum.createRoadWorks,
      translatePath: "Дорожные работы",
      group: ADD_GROUP,
      dispatchedAction: () =>
        initializeSingleObjectEditMode(
          this.selectedObject,
          mapObjectsEnum.roadWorks,
          this.layerName,
          this.initialCoordinate
        ),
      icon: <RoadWorksIcon />,
    },
    {
      name: mapContextMenuContentTypesEnum.controlRoomsSegment,
      translatePath: "Диспетчерская",
      group: ADD_GROUP,
      dispatchedAction: () =>
          initializeSingleObjectEditMode(
              this.selectedObject,
              mapObjectsEnum.controlRoomsSegment,
              this.layerName,
              this.initialCoordinate
          ),
      icon: <ControlRoomIcon />,
    },
    {
      name: mapContextMenuContentTypesEnum.createDockSegments,
      translatePath: "Причал",
      group: ADD_GROUP,
      dispatchedAction: () =>
          initializeSingleObjectEditMode(
              this.selectedObject,
              mapObjectsEnum.dockSegment,
              this.layerName,
              this.initialCoordinate
          ),
      icon: <DockIcon />,
    },
    {
      name: mapContextMenuContentTypesEnum.createRoadOverpass,
      translatePath: "Эстакада",
      group: ADD_GROUP,
      dispatchedAction: () =>
          initializeSingleObjectEditMode(
              this.selectedObject,
              mapObjectsEnum.roadOverpass,
              this.layerName,
              this.initialCoordinate
          ),
      icon: <OverpassIcon />,
    },
    {
      name: mapContextMenuContentTypesEnum.createRoadBlock,
      translatePath: "Перекрытие",
      group: ADD_GROUP,
      dispatchedAction: () =>
        initializeSingleObjectEditMode(
          this.selectedObject,
          mapObjectsEnum.roadBlock,
          this.layerName,
          this.initialCoordinate
        ),
      icon: <BlockadesIcon />,
    },
  ];
}
