import React from "react";
import PropTypes from "prop-types";
import { Styled } from "./CustomAlert.styled";
import ScreenPortal from "../ScreenCornerPortal/ScreenPortal";
import { usePortalContext } from "./utils/usePortalContext";
import { alertTypesEnum } from "../../../../enums/alertTypesEnum";
import { Alert,notification } from "antd";


const CustomAlert = ({
  type,
  title,
  message,
  closureId,
  size,
  style: userStyles = {},
  onClose,
  handleClickForAutoCreateDetour,
  ...otherProps
}) => {
  const { enhancedCloseCallback } = usePortalContext(onClose);
  let args = {message: title, description: message, duration: 7, key: "1", placement: "bottomRight", onClose: onClose}
  let alarm;
  if (closureId) {
    args = {message: "Хотите добавить объезд?", description: "Клик по окну - подтверждение, закрытие - отказ ", duration: 15, key: "1", placement: "bottomRight", onClick: handleClickForAutoCreateDetour, onClose: onClose}
    alarm = notification.info(args)
    return (
      <>

      </>
  )
  }
  switch (type) {
    case "error":
      alarm = notification.error(args);
      break;
    case "success":
      alarm = notification.success(args)
      break;
    case "warning":
      alarm = notification.warning(args)
      break;
    case "info":
      alarm = notification.info(args)
    default:
      alarm = notification.info(args)
      break;

  }
  return (
    <Styled.LocalMainWrapper size={size} style={userStyles} {...otherProps}>
      {alarm}
      {/*<Alert*/}
      {/*  type={type}*/}
      {/*  onClose={enhancedCloseCallback}*/}
      {/*  closable*/}
      {/*  showIcon*/}
      {/*  message={title}*/}
      {/*  description={message}*/}
      {/*  {...otherProps}*/}
      {/*/>*/}
    </Styled.LocalMainWrapper>
  );
};

const Usage = ({ isOpen, portalSide, ...otherProps }) => {
  return (
    <ScreenPortal portalSide={portalSide} isOpen={isOpen} {...otherProps}>
      <CustomAlert {...otherProps} />
    </ScreenPortal>
  );
};

// const mapDispatchToProps = (dispatch) => {
//   return {
//     initializeSingleObjectEditMode: (selectedObject, typeOfObject, objectLayerName, initialCoordinate) =>
//         dispatch(initializeSingleObjectEditMode(selectedObject, typeOfObject, objectLayerName, initialCoordinate)),
//   };
// };
//
// export default connect(
//     null,
//     mapDispatchToProps
// )(Usage);
export default Usage;

Usage.propTypes = {
  message: PropTypes.node,
  onClose: PropTypes.func,
  size: PropTypes.object,
  title: PropTypes.node,
  type: PropTypes.oneOf([
    alertTypesEnum.success,
    alertTypesEnum.warning,
    alertTypesEnum.error,
    alertTypesEnum.info,
  ]),
  portalSide: PropTypes.oneOf([
    "top-right",
    "top-center",
    "top-left",
    "bottom-right",
    "bottom-center",
    "bottom-left",
    "middle-left",
    "middle-center",
    "middle-right",
  ]),
  userStyles: PropTypes.object,
};
