export class LayerSettingsPrototype{

    label
    keyForDeck;

    constructor({label,keyForDeck}) {
        this.label = label;
        this.keyForDeck = keyForDeck
    }

    getLabel(){
        return this.label
    }

    getDeckKey(){
        return this.keyForDeck
    }

    getSettingValues(){
        return this
    }


}
