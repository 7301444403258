import {RelatedDataPrototype} from "../prototype/RelatedDataPrototype";

export class Waggons extends RelatedDataPrototype {

    constructor() {
        super({
            name: 'waggons',
            filterLabel: 'Вагоны',
            urlData: '/railway_network/waggons/',
        });
    }
}
