import React from "react";
import { Icon } from "antd";

const RefreshIconSVG = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.7084 5.29159C14.0916 4.67062 13.3581 4.17789 12.55 3.84178C11.7419 3.50567 10.8752 3.33284 10 3.33325C8.23193 3.33325 6.53624 4.03563 5.286 5.28587C4.03575 6.53612 3.33337 8.23181 3.33337 9.99992C3.33337 11.768 4.03575 13.4637 5.286 14.714C6.53624 15.9642 8.23193 16.6666 10 16.6666C13.1084 16.6666 15.7 14.5416 16.4417 11.6666H14.7084C14.3651 12.6412 13.7278 13.4852 12.8845 14.0823C12.0412 14.6793 11.0333 15 10 14.9999C8.67396 14.9999 7.40219 14.4731 6.46451 13.5355C5.52682 12.5978 5.00004 11.326 5.00004 9.99992C5.00004 8.67384 5.52682 7.40207 6.46451 6.46439C7.40219 5.5267 8.67396 4.99992 10 4.99992C11.3834 4.99992 12.6167 5.57492 13.5167 6.48325L10.8334 9.16659H16.6667V3.33325L14.7084 5.29159Z"
      fill="currentColor"
    />
  </svg>
);

export const RefreshIcon = props => (
  <Icon component={RefreshIconSVG} {...props} />
);
