export const SET_SIDEBAR_COLLAPSE = "SET_SIDEBAR_COLLAPSE";

const initialState = {
  sidebarIsOpened: true,
};

const sidebarPosition = (state = initialState, action) => {
  switch (action.type) {
    case SET_SIDEBAR_COLLAPSE: {
      const { visibleStatus } = action.payload;
      return {
        ...state,
        sidebarIsOpened: visibleStatus ?? !state.sidebarIsOpened,
      };
    }
    default:
      return state;
  }
};

export default sidebarPosition;
