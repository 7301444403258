import {APPLY_PERMISSIONS, LOGOUT_USER} from "../../constants/constants.js";
import {LOGIN_USER} from "../../constants/constants";

const initialState = {
    user: null
}

export default function auth(state = initialState, action) {
    switch (action.type) {
        case LOGIN_USER:
            return {...state, user: action.payload}
        case LOGOUT_USER:
            return {...state, user: null}
        case APPLY_PERMISSIONS :
            return {...state,permissions:action.payload}
        default:
            return state;
    }
}
