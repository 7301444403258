import React, { useCallback, useEffect, useState } from "react";
import { connect } from "react-redux";
import Compendium from "./Compendium";
import CompendiumColumnActionPanelContainer from "../../CompendiumButtonBars/Common/CompendiumColumnActionPanel/CompendiumColumnActionPanelContainer";
import { useWhyDidYouUpdate } from "react-recipes";
import styled from "styled-components";
import { useAgGridControl } from "../../../../customHooks/ag-grid/useAgGridControl";
import { selectRowForLayerHistory } from "../../../../reducers/map/mapboxEditableLayersHistory";
import { directoryHistoryActions } from "../../../../reducers/directory/actions/directoryHistoryActions";
import {selectObject} from "../../../../reducers/directory/actions/selectedObjectActions";

const { selectRowForDirectoryHistory } = directoryHistoryActions;

const StyledCompendiumContainerMainWrapper = styled.div`
  position: relative;
  height: calc(100% - 45px);
`;

const StyledActionPanelWrapper = styled.div`
  z-index: 1;
  position: absolute;
  right: 0;
`;

const CompendiumContainer = props => {
  //dev
  useWhyDidYouUpdate("CompendiumContainer", props);

  const {
    proceedData,
    proceedInline,
    selectRowForCommon,
    selectRowForDirectoryHistory,
    additionalCallbacks,
    withPagination,
    agGridThemeName
  } = props;

  const {
    aggApi,
    quickMenu,
    handleAGGInit,
    toggleShowFilterMenu,
    toggleShowQuickFilter,
    toggleShowRowsMenu
  } = useAgGridControl();

  useEffect(() => {
  }, [aggApi, quickMenu]);

  const handleSelectRow = useCallback(
    aggridRow => {
      selectRowForCommon(aggridRow.data);
      selectRowForDirectoryHistory(aggridRow.data);
    },
    [aggApi, quickMenu]
  );

  const handleRefresh = useCallback(() => {}, []);

  const handleProvideData = event => {
    proceedData(event);
  };

  let containerCallbacks = {
    handleAGGInit,
    handleRefresh,
    handleSelectRow,
    toggleShowFilterMenu,
    handleProvideData,
    toggleShowRowsMenu,
    toggleShowQuickFilter
  };

  if (additionalCallbacks)
    containerCallbacks.additionalCallbacks = additionalCallbacks;


  return (
    <StyledCompendiumContainerMainWrapper>
      <>
        <StyledActionPanelWrapper>
          <CompendiumColumnActionPanelContainer
            quickMenuState={quickMenu}
            toggleShowFilterMenu={toggleShowFilterMenu}
            toggleShowQuickFilter={toggleShowQuickFilter}
            toggleShowRowsMenu={toggleShowRowsMenu}
          />
        </StyledActionPanelWrapper>

        <Compendium
          aggApi={aggApi}
          data={props.data}
          model={props.model}
          showQuickFilter={quickMenu.search}
          containerCallbacks={containerCallbacks}
          withPagination={withPagination}
          agGridThemeName={agGridThemeName}
        />
      </>
    </StyledCompendiumContainerMainWrapper>
  );
};

const mapStateToProps = state => {
  return {
    language: state.app.language
  };
};

const mapDispatchToProps = dispatch => ({
  selectRowForCommon: data => dispatch(selectObject(data)),
  selectRowForDirectoryHistory: selectedData =>
    dispatch(selectRowForDirectoryHistory(selectedData))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CompendiumContainer);
