import styled from "styled-components";
import {Modal} from "antd";


const LocalFileContent = styled.div`
  max-height: 210px;
  overflow-y: scroll;
  margin-top: 16px;
`;
const LocalFileItem = styled.div`
  & .ant-select-selection.ant-select-selection--single {
    color: #495e5f;
    background: #f1f3f6;
    border: none;
    margin-top: 3%;
  }
  & > i {
    margin: auto;
  }
  & p {
    margin-block-start: 0.8em;
    margin-block-end: 0em;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  & button {
    background: #f1f3f6;
  }
  //resize: horizontal;
  max-width: ${props => props.maxWidth ? "500px" : "150px"};
  color: ${(props) => props.theme.colors.paragraph};
  margin-right: 10px;
  margin-bottom: 16px;
  display: flex;
  justify-content: space-around;
  background: #f1f3f6;
  border-radius: 8px;
  width: ${props => props.width ? "100%" : "50%"};
  text-align: center;
  height: 40px;

  &.reject {
    border: 3px solid ${(props) => props.theme.colors.danger};
  }
  &.ok {
    border: 2px solid ${(props) => props.theme.colors.success};
  }
  &.pending {
    border: 2px solid ${(props) => props.theme.colors.warning};
  }
`;

const LocalModal = styled(Modal)`
  & .ant-modal-header {
    box-shadow: 0px 0px 8px -5px ${(props) => props.theme.colors.paragraph};
  }
  & .ant-modal-close {
    right: -50px;
    top: -15px;
    color: #ffffff;
  }
  & .ant-modal-content {
    width: 400px;
  }
  & .ant-upload.ant-upload-drag {
    background-color: #ffffff;
  }
  & .ant-modal-title {
    text-align: center;
  }
`;

const StartUploadButton = styled.div`
  font-style: normal;
  font-weight: bold;
  font-size: 13px;
  line-height: 140%;
  & .shitTrader {
    & p {
      margin-block-start: 1em;
      margin-block-end: 1em;
      margin-inline-start: 0px;
      margin-inline-end: 0px;
    }
    height: 40px;

    background-color: ${(props) => props.theme.colors.primary};
    color: #ffffff;
  }
`;

const LocalSpan = styled.span`
  color: ${(props) => props.theme.colors.primary};
`;

const LocalDiv = styled.div`
  margin-left: 15px;
`;

export const Styled = {LocalDiv, LocalSpan, StartUploadButton, LocalFileContent, LocalFileItem, LocalModal}



