import styled from "styled-components";
import { Layout } from "antd";

const { Header, Content } = Layout;

const ApplicationLayoutInner = styled.div`
  width: 100%;
  height: 100%;
`

const ApplicationHeader = styled(Header)`
  //position: absolute;
  //top: 20px;
  //left: 20px;
  //z-index: 500;
  //background: transparent;
`;

const ApplicationContent = styled(Content)`
  //padding-top: 80px;
`;

const ApplicationContentInner = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  & > div {
    height: 100%;
  }
`;

export const Styled = { ApplicationHeader, ApplicationContent, ApplicationContentInner, ApplicationLayoutInner};
