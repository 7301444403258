import React from "react";
import {Button} from "../../../../../../library/UI/components/Button/Button";
import {CalendarIcon} from "../../../../../../library/icons/objectIcons/CalendarIcon";
import {RunningTimeIcon} from "../../../../../../library/icons/objectIcons/RunningTimeIcon";
import { Styled } from "../TimePlayer.styled";
import moment from "moment";
import {CloseIcon} from "../../../../../../library/icons/actionIcons/CloseIcon";

const formatCurrentTime = (startTime, currentTimestamp) => {
    return moment(startTime).add(currentTimestamp,'seconds').format('MMMM Do YYYY, HH:mm:ss')
}

const TimePlayerHeader = (props) => {
    const {mapboxTime,handleClosePlayer} = props
    return (
      <Styled.TimePlayerHeaderWrap>
        <Styled.TimePlayerHeaderInfo>Трек времени</Styled.TimePlayerHeaderInfo>
        <Styled.TimePlayerHeaderInfo>{formatCurrentTime(mapboxTime.startDateTime,mapboxTime.currentTimeStamp)}</Styled.TimePlayerHeaderInfo>
        <Styled.TimePlayerHeaderControls>
          <Button p={'none'} type={"unbounded"} disabled rounded icon={<CalendarIcon />} />
          <Button p={'none'} type={"unbounded"} disabled rounded icon={<RunningTimeIcon />} />
          <Button p={'none'} type={"unbounded"} rounded icon={<CloseIcon />} onClick={handleClosePlayer}/>
        </Styled.TimePlayerHeaderControls>
      </Styled.TimePlayerHeaderWrap>
    );
}

export default TimePlayerHeader
