import styled from "styled-components";
import { Styled as styledMixins } from "../../../library/UI/styleMixins/styleMixins.styled";

const { OpacifyColorInCertainProperty } = styledMixins.color;

const BaseMapMainModuleWrapper = styled.main`
  padding: ${(props) => props.theme.paddings.defaultPadding};
  padding-top: 100px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: space-between;
  overflow: scroll;
`;

const BaseMapContentLayoutArea = styled.section`
  flex: 1;
  display: flex;
  justify-content: flex-end;
`;

const BaseMapContentLayoutAreaInner = styled.div`
  padding-right: ${(props) => props.theme.paddings.smallPadding};
  padding-left: ${(props) => props.theme.paddings.smallPadding};
  flex: 1;
  display: flex;
  justify-content: flex-end;
  & > * {
    z-index: 1;
  }
  flex-direction:column
`;

const BaseMapPanelLayoutArea = styled.section`
  position: relative;
  width: fit-content;
  z-index: 1;
  & > *:not(:last-child) {
    margin-bottom: ${(props) => props.theme.margins.defaultMargin};
  }
`;

const BaseMapPanelLayoutAreaInner = styled.div`
  height: 100%;
  bottom: 0;
  top: 0;
  display: flex;
  margin: 0 -3px;
  & > * {
    margin: 0 3px;
  }
`;

const BaseMapPanel = styled.div`
  ${OpacifyColorInCertainProperty("defaultLighter", "background", 0.85)};
  border-radius: ${(props) => props.theme.decoration.defaultBorderRadius};
  box-shadow: ${(props) => props.theme.decoration.defaultBoxShadow};
  //backdrop-filter: blur(14px);
  max-width: 100%;
  z-index: 1;
  height: fit-content;
  transition: all 0.3s;
  &::-webkit-scrollbar {
    width: 0;
  }
`;

const BaseMapLayerPanelWrapperPrimary = styled(BaseMapPanel)`
  width: 272px;
  height: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
  backdrop-filter: ${(props) => props.theme.decoration.defaultBackgroundFilter};
`;

const BaseMapLayerPanelWrapperSecondary = styled(
  BaseMapLayerPanelWrapperPrimary
)`
  max-width: 100%;
  //backdrop-filter: blur(8px);
  z-index: 0;
`;

const BaseMapPanelItem = styled.div`
  padding: ${(props) => props.theme.paddings.defaultPadding};
`;

const BaseMapPanelTopPart = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const BaseMapPanelPrimaryTitle = styled.h3`
  font-size: ${(props) => props.theme.fontSettings.default};
  color: ${(props) => props.theme.colors.defaultDark};
  margin: 0;
`;

const BaseMapPanelSecondaryTitle = styled(BaseMapPanelPrimaryTitle)`
  color: ${(props) => props.theme.colors.neutralDarker};
`;

const BaseMapPanelHeader = styled(BaseMapPanelItem)`
  box-shadow: ${(props) => props.theme.decoration.defaultBoxShadow};
`;

const BaseMapPanelSection = styled.div`
  margin-top: 1rem;
`;

export const Styled = {
  BaseMapMainModuleWrapper,
  BaseMapContentLayoutArea,
  BaseMapPanelLayoutArea,
  BaseMapPanelLayoutAreaInner,
  BaseMapContentLayoutAreaInner,
  BaseMapPanel,
  BaseMapLayerPanelWrapperPrimary,
  BaseMapLayerPanelWrapperSecondary,
  BaseMapPanelItem,
  BaseMapPanelHeader,
  BaseMapPanelTopPart,
  BaseMapPanelPrimaryTitle,
  BaseMapPanelSecondaryTitle,
  BaseMapPanelSection,
};
