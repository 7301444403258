import styled from "styled-components";
import {Button} from "../../../../../../../../../library/UI/components/Button/Button";
import CustomTooltip from "../../../../../../../../../library/UI/components/CustomTooltip/CustomTooltip";





const SettingsControlsItem = styled.div`

`




const SettingsButtonClose = styled(Button)`
    font-family: HeliosC;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 140%;
    color: #495E5F;
    & i {
    padding-right: 17px;
    }
`

const SettingsButtonDelete = styled(Button)`
    padding-top: 22px;
    font-family: HeliosC;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 140%;
    color: #EB5757;
    & i {
    padding-right: 17px;
    }
`

const SettingsControlsWrapper = styled.div`
  & ${SettingsControlsItem}:not(:first-of-type) {
      margin-top: 8px;
  }
`

export const Styled = {
    SettingsButtonClose,
    SettingsControlsWrapper,
    SettingsControlsItem,
    SettingsButtonDelete
}