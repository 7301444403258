import { eventChannel, END } from "redux-saga";
import { call, put, take, fork, cancel, takeEvery } from "redux-saga/effects";
import {
  ADD_MAPBOX_DATA,
  PUSH_ARRAY_TO_MAPBOX_DATA,
  PUSH_ARRAY_TO_MAPBOX_RELATED_DATA,
  PUSH_TO_MAPBOX_DATA,
  PUSH_TO_MAPBOX_RELATED_DATA,
} from "../../reducers/map/mapboxData";
import _ from "underscore";
import {
  CHECK_SOCKET_STATUS,
  REGISTER_SOCKET_STATUS,
} from "../../reducers/map/socketsStatus";
import { initUpdateMapSelectedObjectSaga } from "./mapSelectedObjectSaga";
import { getFromURL } from "../../api/getFromURL";
import {putResolve} from "@redux-saga/core/effects";

export const SAGA_GET_DATA_BY_SOCKET = "SAGA_GET_DATA_BY_SOCKET";
export const CLOSE_SOCKET_CONNECTION = "CLOSE_SOCKET_CONNECTION";

const dispatch = putResolve; //заменил put.resolve на putResolve и импортировал (видимо API изменился у саги).
// Ошибка в сагах сокета все равно валится, но перестало крашить слои. TODO разобраться после сдачи первой вехи

export const openSocket = ({
  urlData,
  layerName,
  subscribeKey,
  subscribeIds,
}) => {
  return {
    type: SAGA_GET_DATA_BY_SOCKET,
    payload: { urlData, layerName, subscribeKey, subscribeIds },
  };
};

export const closeSocket = () => ({ type: CLOSE_SOCKET_CONNECTION });

function createWebSocketConnection(action, activeCarId) {
  return new Promise((resolve, reject) => {
    const { urlData, subscribeKey, subscribeIds } = action.payload;
    const socket = new WebSocket(urlData);
    socket.onopen = function () {
      if (subscribeIds) {
        socket.send(JSON.stringify({ [subscribeKey]: subscribeIds }));
      } else {
        socket.send(JSON.stringify({ [subscribeKey]: activeCarId }));
      }
    };
    resolve(socket);

    socket.onerror = function (evt) {
      reject(evt);
    };
  });
}

function createSocketChannel(socket) {
  return eventChannel((emit) => {
    socket.onmessage = (event) => {
      emit(event.data);
    };

    socket.onclose = () => {
      emit(END);
    };

    const unsubscribe = () => {
      socket.onmessage = null;
    };

    return unsubscribe;
  });
}

function* listenForSocketMessages(action) {
  let socket;
  let socketChannel;
  const getAllActiveCar = yield call(() => getFromURL.getAll(`terminals/`));
  const activeCarId = getAllActiveCar.results.map((el) => el.id);
  try {
    yield put({
      type: CHECK_SOCKET_STATUS,
      payload: {
        initiatorType: "mapLayer",
        initiatorName: action.payload.layerName,
      },
    });
    socket = yield call(createWebSocketConnection, action, activeCarId);
    socketChannel = yield call(createSocketChannel, socket);
    yield put({ type: "CONNECTIONS_SUCCESS" });
    yield put({
      type: REGISTER_SOCKET_STATUS,
      payload: {
        initiatorType: "mapLayer",
        initiatorName: action.payload.layerName,
        socketInstance: socket,
      },
    });

    while (true) {
      const payload = yield take(socketChannel);
      const message = JSON.parse(payload);
      if (_.isArray(message) && message?.length) {
        yield put({
          type: PUSH_ARRAY_TO_MAPBOX_DATA,
          payload: {
            key: "monitoring",
            value: message,
          },
        });
        yield put({
          type: PUSH_ARRAY_TO_MAPBOX_RELATED_DATA,
          payload: {
            initiatorName: action.payload.layerName,
            relatedDataKey: "vehicles",
            value: message,
          },
        });
        for (let it of message) {
          yield put(initUpdateMapSelectedObjectSaga(it));
        }
      } else if (!_.isArray(message)) {
        yield put({
          type: PUSH_TO_MAPBOX_DATA,
          payload: {
            key: "monitoring",
            value: message,
          },
        });
        yield put({
          type: PUSH_TO_MAPBOX_RELATED_DATA,
          payload: {
            initiatorName: action.payload.layerName,
            relatedDataKey: "vehicles",
            value: message,
          },
        });
        yield put(initUpdateMapSelectedObjectSaga(message));
      }
    }
  } catch (error) {
    yield dispatch({ type: "SOCKET_ERROR" });
  }
}

function* startSocketSaga(action) {
  yield put({
    type: ADD_MAPBOX_DATA,
    payload: {
      key: action.payload.layerName,
      value: [],
    },
  });
  const socketTask = yield fork(() => listenForSocketMessages(action));
  yield take(CLOSE_SOCKET_CONNECTION);
  yield cancel(socketTask);
  yield dispatch({ type: "SOCKET_DISCONNECTED_SUCCESSFUL" });
}

export function* watchOpenSocketSaga() {
  yield takeEvery(SAGA_GET_DATA_BY_SOCKET, startSocketSaga);
}
