import styled from "styled-components";
import PanelHeader from "../Panel/Header/PanelHeader";
import PanelSection from "../Panel/Section/PanelItemSection";
import Panel from "../Panel/Panel";
import { Styled as styleMixins } from "../../styleMixins/styleMixins.styled";

const { GetDefaultBeatifiedScrollbar } = styleMixins.other;

const ExtendedPanelWrapper = styled(Panel)`
  height: ${(props) => props.cardHeight ?? "auto"};
  display: flex;
  flex-direction: column;
  width: 245px;
  margin-bottom:0.6rem;
  max-width: 100%;
`;
const ExtendedPanelHeader = styled(PanelHeader)`
  display: flex;
  justify-content: space-between;
  flex: 0 0 auto;
  font-weight: bold;
`;

const ExtendedPanelContent = styled(PanelSection)`
  ${(props) => GetDefaultBeatifiedScrollbar(props)};
  flex: 1 1 auto;
  overflow-y: scroll;
`;

const ExtendedPanelFooter = styled(PanelHeader)`
  flex: 0 0 auto;
  display: flex;
  justify-content: center;
`;

export const Styled = {
  ExtendedPanelWrapper,
  ExtendedPanelFooter,
  ExtendedPanelHeader,
  ExtendedPanelContent,
};
