import React from "react";
import {Icon} from "antd";

const MenuIconSVG = ({width='1em', height='1em'}) => (
    <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M21 15.61L19.59 17L14.58 12L19.59 7L21 8.39L17.44 12L21 15.61ZM3 6H16V8H3V6ZM3 13V11H13V13H3ZM3 18V16H16V18H3Z" fill='currentColor'/>
    </svg>
);

export const MenuIcon = props => <Icon component={MenuIconSVG} {...props} />;
