import styled from "styled-components";
import { Card } from "antd";

const LocalCardMainWrapper = styled.div`
  margin-left: 20px;
`;
const LocalCardWrapper = styled(Card)`
  .ant-card-body {
    padding: 16px 20px 14px 19px;
  }
  width: 288px;
  height: 122px;
  border-radius: 8px;
  cursor: pointer;
  color: ${(props) => props.theme.colors.defaultDark};
`;

const LocalCardHeader = styled.div`
  display: flex;
  justify-content: space-between;
  text-align: left;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  color: ${(props) => props.theme.colors.defaultDark};
`;

const LocalCardOption = styled.div`
  display: flex;
`;

const LocalCardStar = styled.div`
  margin-right: 13px;
`;

const LocalCardPoint = styled.div``;

const LocalCardDescr = styled.div`
  margin-top: 12px;
  text-align: left;
  font-size: 14px;
  color: ${(props) => props.theme.colors.neutralDarker};
`;

export const Styled = {
  LocalCardMainWrapper,
  LocalCardHeader,
  LocalCardOption,
  LocalCardWrapper,
  LocalCardStar,
  LocalCardPoint,
  LocalCardDescr,
};
