import React from "react";
import {Icon} from "antd";

const CompassIconSVG = () => (
    <svg width="68" height="68" viewBox="0 0 68 68" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g filter="url(#filter0_bd)">
            <circle cx="34" cy="32" r="21" stroke="white" strokeOpacity="0.8" strokeWidth="10"/>
        </g>
        <circle cx="55" cy="32" r="2" fill="#929FB5"/>
        <circle cx="13" cy="32" r="2" fill="#929FB5"/>
        <path d="M33.134 9.5C33.5189 8.83333 34.4811 8.83333 34.866 9.5L36.5981 12.5C36.983 13.1667 36.5019 14 35.7321 14H32.2679C31.4981 14 31.017 13.1667 31.4019 12.5L33.134 9.5Z" fill="#EB5757"/>
        <path d="M34.866 54.5C34.4811 55.1667 33.5189 55.1667 33.134 54.5L31.4019 51.5C31.017 50.8333 31.4981 50 32.2679 50L35.7321 50C36.5019 50 36.983 50.8333 36.5981 51.5L34.866 54.5Z" fill="#1B3548"/>
        <defs>
            <filter id="filter0_bd" x="-10" y="-12" width="88" height="88" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                <feGaussianBlur in="BackgroundImage" stdDeviation="9"/>
                <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur"/>
                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
                <feOffset dy="2"/>
                <feGaussianBlur stdDeviation="4"/>
                <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0.509804 0 0 0 0 0.568627 0 0 0 0.08 0"/>
                <feBlend mode="normal" in2="effect1_backgroundBlur" result="effect2_dropShadow"/>
                <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow" result="shape"/>
            </filter>
        </defs>
    </svg>
);

export const CompassIcon = props => <Icon component={CompassIconSVG} {...props} />;
