import React from "react";
import { Icon } from "antd";

const DropUploadSVGIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="44" height="54" viewBox="0 0 44 54" fill="none">
        <path d="M27.3337 0.333374L43.3337 16.3334V48.3334C43.3337 49.7479 42.7718 51.1044 41.7716 52.1046C40.7714 53.1048 39.4148 53.6667 38.0003 53.6667H6.00033C4.58584 53.6667 3.22928 53.1048 2.22909 52.1046C1.2289 51.1044 0.666992 49.7479 0.666992 48.3334V5.66671C0.666992 4.25222 1.2289 2.89566 2.22909 1.89547C3.22928 0.895277 4.58584 0.333374 6.00033 0.333374H27.3337ZM38.0003 48.3334V19H24.667V5.66671H6.00033V48.3334H38.0003ZM22.0003 27L32.667 37.6667H26.0003V45.6667H18.0003V37.6667H11.3337L22.0003 27Z" fill="#F1F3F6"/>
    </svg>
);

export const DropUploadIcon = props => (
    <Icon component={DropUploadSVGIcon} {...props} />
);
