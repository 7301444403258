import styled from "styled-components";

const BaseTemplateMainWrapper = styled.div`
  padding: ${props => props.theme.paddings.defaultPadding};
  padding-top: 0;
`

const BaseTemplateActionBarWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  & button + button {
    margin-left: 1rem;
  }
  & + div {
    margin-top: 1.5rem;
  }
`

export const Styled = {BaseTemplateMainWrapper, BaseTemplateActionBarWrapper}