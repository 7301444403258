import {RESET_TIMER} from "./mapboxTime";

export const SET_MAIN_MAP_PANEL_CONTEXT = "SET_MAIN_MAP_PANEL_CONTEXT";
export const SET_LAYER_SETTINGS_TO_EDIT = "SET_LAYER_SETTINGS_TO_EDIT";
export const SET_LAYER_SETTINGS_TYPE = "SET_LAYER_SETTINGS_TYPE";
export const SET_LAYER_DATA_ACCESSOR = "SET_LAYER_DATA_ACCESSOR";
export const SET_SHOW_CREATE_MODAL = "SET_SHOW_CREATE_MODAL";
export const CLOSE_ALL_ADDITIONAL_PANELS = "CLOSE_ALL_ADDITIONAL_PANELS"
export const TOGGLE_LAYER_DATA_ACCESSOR_VISIBLE = "TOGGLE_LAYER_DATA_VISIBLE";
export const TOGGLE_MAIN_PANEL_COLLAPSE = "SET_MAIN_PANEL_COLLAPSED"
export const TOGGLE_TIME_PLAYER_VISIBLE = "TOGGLE_TIME_PLAYER_VISIBLE"
export const TOGGLE_LAYER_SETTINGS_VISIBILITY = "TOGGLE_LAYER_SETTINGS_VISIBILITY";
export const TOGGLE_LAYERS_GALLERY_VISIBILITY = "TOGGLE_LAYERS_GALLERY_VISIBILITY";
export const TOGGLE_MAP_SELECTED_OBJECT_INFO_VISIBLE = "TOGGLE_MAP_SELECTED_OBJECT_INFO_VISIBLE"
export const SET_RULER_MODE = "SET_RULER_MODE"
export const SET_MAP_CONTEXT_MENU = "SET_MAP_CONTEXT_MENU"
export const CHANGE_RASTER_STYLE = "CHANGE_RASTER_STYLE"
export const TOGGLE_RASTER_ENABLED = "TOGGLE_RASTER_ENABLED"
export const CLEAR_MAP_CONTEXT_MENU = "CLEAR_MAP_CONTEXT_MENU"
export const TOGGLE_VISIBLE_UNVISIBLE_FILTER = "VISIBLE_UNVISIBLE_FILTER"

function localReducerToggleTemplate(action, state, fieldName) {
    const {newStatus} = action.payload
    if (typeof newStatus === 'boolean') {
        return {
            ...state,
            [fieldName]: newStatus,
        }
    }
    return {
        ...state,
        [fieldName]: !state[fieldName]
    }
}

const initialState = {
    layersGalleryVisible: true,
    selectedMainContext: 'layers',

    layerSettingToEdit: null,
    layerSettingType: 'templates',
    dataAccessor: null,

    layerSettingVisible: false,
    dataAccessorVisible: false,
    timePlayerVisible: false,
    mapSelectedObjectInfoVisible: false,

    mainPanelCollapsed: false,
    showCreateModal: false,
    visibleUnvisibleFilter: false,

    rulerMode: null,
    mapContextMenu: null, //{y: number, x: number, visible: bool}

    rasterStyle: 'https://demo-ng.oits.su/tile/{z}/{x}/{y}.png',
    rasterTileEnabled: false,
};

//GOTO mapPanelsActions.js

const mapPanels = (state = initialState, action) => {

    switch (action.type) {
        case SET_SHOW_CREATE_MODAL: {
            const {value} = action.payload;
            return {
                ...state,
                showCreateModal: value
            }
        }
        case CLEAR_MAP_CONTEXT_MENU:{
            return {
                ...state,
                mapContextMenu: null
            }
        }
        case CLOSE_ALL_ADDITIONAL_PANELS: {
            return {
                ...state,
                layerSettingVisible: false,
                dataAccessorVisible: false,
                timePlayerVisible: false,
            }
        }
        case CHANGE_RASTER_STYLE: {
            const {tileUrl} = action.payload;
            return {
                ...state,
                rasterStyle: tileUrl
            }
        }
        case RESET_TIMER: {
            return {
                ...state,
                timePlayerVisible: false,
            }
        }
        case TOGGLE_RASTER_ENABLED: {
            return {
                ...state,
                rasterTileEnabled: !state.rasterTileEnabled
            }
        }
        case SET_MAIN_MAP_PANEL_CONTEXT: {
            return {
                ...state,
                selectedMainContext: action.payload,
                layerSettingVisible: false,
                layersGalleryVisible: false
            }
        }
        case SET_RULER_MODE: {
            const {mode} = action.payload;
            if (state.rulerMode == mode) {
                return {
                    ...state,
                    rulerMode: null
                }
            } else {
                return {
                    ...state,
                    rulerMode: mode
                }
            }
        }
        case SET_MAP_CONTEXT_MENU: {
            if (!action.payload) {
                return {
                    ...state,
                    mapContextMenu: null
                }
            } else {
                const {x, y, visible,coordinate, clickedObjectName,selected, objectLayerName} = action.payload;
                return {
                    ...state,
                    mapContextMenu: {
                        x,
                        y,
                        visible,
                        coordinate,
                        clickedObjectName,
                        selected,
                        objectLayerName
                    }
                }
            }

        }
        case SET_LAYER_SETTINGS_TYPE: {
            return {
                ...state,
                layerSettingType: action.payload
            }
        }
        case SET_LAYER_DATA_ACCESSOR: {
            return {
                ...state,
                dataAccessor: action.payload,
            }
        }
        case SET_LAYER_SETTINGS_TO_EDIT: {
            return {
                ...state,
                layerSettingToEdit: action.payload
            }
        }
        case TOGGLE_LAYERS_GALLERY_VISIBILITY: {
            return localReducerToggleTemplate(action, state, 'layersGalleryVisible')
        }
        case TOGGLE_TIME_PLAYER_VISIBLE: {
            return localReducerToggleTemplate(action, state, 'timePlayerVisible')
        }
        case TOGGLE_LAYER_SETTINGS_VISIBILITY: {
            return localReducerToggleTemplate(action, state, 'layerSettingVisible')
        }
        case TOGGLE_MAIN_PANEL_COLLAPSE: {
            return localReducerToggleTemplate(action, state, 'mainPanelCollapsed')
        }
        case TOGGLE_LAYER_DATA_ACCESSOR_VISIBLE: {
            return localReducerToggleTemplate(action, state, 'dataAccessorVisible')
        }
        case TOGGLE_MAP_SELECTED_OBJECT_INFO_VISIBLE: {
            return localReducerToggleTemplate(action, state, 'mapSelectedObjectInfoVisible')
        }
        case TOGGLE_VISIBLE_UNVISIBLE_FILTER: {
            return localReducerToggleTemplate(action, state, 'visibleUnvisibleFilter')
        }
        default:
            return state;
    }
};

export default mapPanels
