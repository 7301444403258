import styled from "styled-components";
import { Button } from "../../../../UI/components/Button/Button";

const ActionPanelWrapper = styled.div`
  display: flex;
`;

const ActionPanelButton = styled(Button)`
  padding: 0.6rem;
  opacity: ${props => (props.disabled ? "0.7" : "1")};
  &.active {
    background: ${props => props.theme.colors.primary};
    color: ${props => props.theme.colors.defaultLighter};
  }
`;

export const Styled = { ActionPanelWrapper, ActionPanelButton };
