/**
 * @deprecated
 * @type {{clusterCargos: string, roadSegment: string, deckGLMap: string, monitoredVehicle: string, roadSign: string, cargoTrackingPoint: string, securityCheckpoint: string, roadWorks: string, railwayNetwork: string, roadBlock: string, geozone: string}}
 */
export const editableObjectDescriptionEnum = {
    roadSegment: 'segment',
    roadSign: 'roadSign',
    securityCheckpoint: 'securityCheckpoint',
    roadBlock: 'roadBlock',
    roadWorks: 'roadWorks',
    deckGLMap: 'deckGLMap',
    geozone: 'geozone',
    monitoredVehicle: 'monitoredVehicle',
    railwayNetwork: "railwayNetwork",
    cargoTrackingPoint: 'cargoTrackingPoint',
    clusterCargos: 'clusterCargos'

}
