import React from "react";
import { HeadphonesIcon } from "../../../library/icons/objectIcons/HeadphonesIcon";
import { Styled } from "./WelcomePage.styled";
import { useTranslation } from "react-i18next";
import {withTheme} from "styled-components";

const {
  LocalMainWrapper,
  LocalMainTitle,
  LocalMainDescription,
  LocalButtonHeadIcon
} = Styled;

const WelcomePage = ({ onClick, theme }) => {
  const { t, i18n } = useTranslation("common");
  return (
    <LocalMainWrapper>
      <LocalMainTitle>{t("guest.page.welcomeTitle")}</LocalMainTitle>
      <LocalMainDescription>
        {t("guest.page.descriptionTitle")}
      </LocalMainDescription>
      <LocalButtonHeadIcon
        icon={<HeadphonesIcon />}
        p={"none"}
        type={"unboundedPrimary"}
        onClick={onClick}
        iconSize={{ width: "18px", height: "22px" }}
        iconMargin={1.1}
        isBold={true}
        iconColor={theme.colors.primary}
      >
        {t("guest.page.supportTitle")}
      </LocalButtonHeadIcon>
    </LocalMainWrapper>
  );
};

export default withTheme(WelcomePage);
