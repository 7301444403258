import React from "react";
import { Link } from "react-router-dom";
import { Styled } from "./SidebarItem.styled";

const { Content, Icon, Item } = Styled;

const SidebarItem = ({
  icon,
  onClick,
  disabled,
  children,
  active,
  sidebarIsOpened,
}) => {
  const onClickAction = (e) => {
    if (disabled || !onClick) {
      e.preventDefault();
    } else {
      return onClick(e);
    }
  };
  return (
    <Item
      className={`${active ? "active" : ""}`}
      sidebarIsOpened={sidebarIsOpened}
      active={active}
      disabled={disabled}
      onClick={onClickAction}
    >
      {/*this is temporary solution*/}
      <Icon sidebarIsOpened={sidebarIsOpened}>{icon}</Icon>
      <Content sidebarIsOpened={sidebarIsOpened}>{children}</Content>
    </Item>
  );
};

export default SidebarItem;
