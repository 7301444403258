import React from "react";
import PropTypes from "prop-types";
import { Styled } from "./CustomTooltipHeader.styled";

const CustomTooltipHeader = ({
  children: children,
  color,
  isBold,
  fontSize,
  offsetAfter,
  withBottomShadow
}) => {
  return (
    <Styled.LocalWrapper
      color={color}
      isBold={isBold}
      fontSize={fontSize}
      offsetAfter={offsetAfter}
      withBottomShadow={withBottomShadow}
    >
      {children}
    </Styled.LocalWrapper>
  );
};

export default CustomTooltipHeader;

CustomTooltipHeader.propTypes = {
  children: PropTypes.any,
  color: PropTypes.string,
  isBold: PropTypes.bool,
  fontSize: PropTypes.string,
  offsetAfter: PropTypes.string
};

CustomTooltipHeader.defaultProps = {
  isBold: true
};
