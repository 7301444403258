import React from "react";
import styled from "styled-components";

const ContentPaddingWrapper = styled.section`
  //this is should be header size
  padding-top: 100px;
  height: 100%;
  & > * {
    height: 100%;
  }
`;

export default (WrappedComponent) => {
  const hocComponent = ({ ...props }) => (
    <ContentPaddingWrapper>
      <WrappedComponent {...props} />
    </ContentPaddingWrapper>
  );

  hocComponent.propTypes = {};

  return hocComponent;
};
