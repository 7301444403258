import React from 'react';
import PropTypes from 'prop-types';
import LayerDesignSettingsContainer from "./LayerChangeControlsTab/LayerChangeControlsTabContainer";
import LayerFilterTabContainer from "./LayerFilterTab/LayerFilterTabContainer";
import LayerDataTemplatesContainer from "./LayerTemplatesTab/LayerTemplatesTabContainer";

const LayerSettingsContent = ({settingTypeDisplay}) => {
    return (
        <>
            {settingTypeDisplay==='changeControls' && <LayerDesignSettingsContainer/>}
            {settingTypeDisplay==='filters' && <LayerFilterTabContainer/>}
            {settingTypeDisplay==='templates' && <LayerDataTemplatesContainer/>}
        </>
    );
};

LayerSettingsContent.propTypes = {
  settingTypeDisplay: PropTypes.string.isRequired
}

export default LayerSettingsContent;
