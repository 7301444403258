export const signsMapperType1 = {
    "1.1":{
        "x": 2806,
        "y": 0,
        "width": 834,
        "height": 734
    },
    "1.2":{
        "x": 3641,
        "y": 2205,
        "width": 834,
        "height": 734
    },
    "1.3.1":{
        "x": 835,
        "y": 4429,
        "width": 1083,
        "height": 698
    },
    "1.3.2":{
        "x": 0,
        "y": 3268,
        "width": 1083,
        "height": 1160
    },
    "1.4.1":{
        "x": 2505,
        "y": 601,
        "width": 300,
        "height": 600
    },
    "1.4.2":{
        "x": 2505,
        "y": 1202,
        "width": 300,
        "height": 600
    },
    "1.4.3":{
        "x": 2505,
        "y": 2404,
        "width": 300,
        "height": 600
    },
    "1.4.4":{
        "x": 2505,
        "y": 0,
        "width": 300,
        "height": 600
    },
    "1.4.5":{
        "x": 2505,
        "y": 1803,
        "width": 300,
        "height": 600
    },
    "1.4.6":{
        "x": 1084,
        "y": 3769,
        "width": 300,
        "height": 600
    },
    "1.5":{
        "x": 4476,
        "y": 2940,
        "width": 834,
        "height": 734
    },
    "1.6":{
        "x": 4476,
        "y": 1470,
        "width": 834,
        "height": 734
    },
    "1.7":{
        "x": 0,
        "y": 0,
        "width": 834,
        "height": 734
    },
    "1.8":{
        "x": 3641,
        "y": 3675,
        "width": 834,
        "height": 734
    },
    "1.9":{
        "x": 0,
        "y": 1297,
        "width": 834,
        "height": 734
    },
    "1.10":{
        "x": 1670,
        "y": 735,
        "width": 834,
        "height": 734
    },
    "1.11.1":{
        "x": 4476,
        "y": 2205,
        "width": 834,
        "height": 734
    },
    "1.11.2":{
        "x": 2806,
        "y": 735,
        "width": 834,
        "height": 734
    },
    "1.12.1":{
        "x": 3641,
        "y": 2940,
        "width": 834,
        "height": 734
    },
    "1.12.2":{
        "x": 2806,
        "y": 1470,
        "width": 834,
        "height": 734
    },
    "1.13":{
        "x": 3641,
        "y": 0,
        "width": 834,
        "height": 734
    },
    "1.14":{
        "x": 0,
        "y": 4429,
        "width": 834,
        "height": 734
    },
    "1.15":{
        "x": 1670,
        "y": 0,
        "width": 834,
        "height": 734
    },
    "1.16":{
        "x": 835,
        "y": 2533,
        "width": 834,
        "height": 734
    },
    "1.17":{
        "x": 4476,
        "y": 0,
        "width": 834,
        "height": 734
    },
    "1.18":{
        "x": 0,
        "y": 2533,
        "width": 834,
        "height": 734
    },
    "1.19":{
        "x": 4476,
        "y": 735,
        "width": 834,
        "height": 734
    },
    "1.20.1":{
        "x": 4476,
        "y": 4410,
        "width": 834,
        "height": 734
    },
    "1.20.2":{
        "x": 835,
        "y": 0,
        "width": 834,
        "height": 734
    },
    "1.20.3":{
        "x": 3641,
        "y": 735,
        "width": 834,
        "height": 734
    },
    "1.21":{
        "x": 4476,
        "y": 3675,
        "width": 834,
        "height": 734
    },
    "1.22":{
        "x": 3641,
        "y": 1470,
        "width": 834,
        "height": 734
    },
    "1.23":{
        "x": 1670,
        "y": 2533,
        "width": 834,
        "height": 734
    },
    "1.24":{
        "x": 2806,
        "y": 2205,
        "width": 834,
        "height": 734
    },
    "1.25":{
        "x": 835,
        "y": 1297,
        "width": 834,
        "height": 734
    },
    "1.26":{
        "x": 3189,
        "y": 4429,
        "width": 634,
        "height": 561
    },
    "1.27":{
        "x": 2554,
        "y": 4429,
        "width": 634,
        "height": 561
    },
    "1.28":{
        "x": 1670,
        "y": 1470,
        "width": 634,
        "height": 561
    },
    "1.29":{
        "x": 2020,
        "y": 3769,
        "width": 634,
        "height": 561
    },
    "1.30":{
        "x": 1919,
        "y": 4429,
        "width": 634,
        "height": 561
    },
    "1.31":{
        "x": 1385,
        "y": 3769,
        "width": 634,
        "height": 561
    },
    "1.32":{
        "x": 835,
        "y": 735,
        "width": 634,
        "height": 561
    },
    "1.33":{
        "x": 0,
        "y": 735,
        "width": 634,
        "height": 561
    },
    "1.34.1_A":{
        "x": 0,
        "y": 2032,
        "width": 2250,
        "height": 500
    },
    "1.34.2_B":{
        "x": 2245,
        "y": 3268,
        "width": 1160,
        "height": 500
    },
    "1.34.3_B":{
        "x": 1084,
        "y": 3268,
        "width": 1160,
        "height": 500
    },
    "1.35":{
        "x": 2655,
        "y": 3769,
        "width": 180,
        "height": 180
    }
}
