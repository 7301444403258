import React from "react";
import { Icon } from "antd";

const GeozoneIconSVG = () => (
  <svg
    width="19"
    height="19"
    viewBox="0 0 19 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.49967 0.832031C1.57467 0.832031 0.833008 1.5737 0.833008 2.4987V11.6654C0.833008 12.5904 1.57467 13.332 2.49967 13.332H5.83301V16.6654C5.83301 17.5904 6.57467 18.332 7.49967 18.332H16.6663C17.5913 18.332 18.333 17.5904 18.333 16.6654V7.4987C18.333 6.5737 17.5913 5.83203 16.6663 5.83203H13.333V2.4987C13.333 1.5737 12.5913 0.832031 11.6663 0.832031H2.49967ZM2.49967 2.4987H11.6663V7.4987H16.6663V16.6654H7.49967V11.6654H2.49967V2.4987Z"
      fill="#1B3548"
    />
  </svg>
);

export const GeozoneIcon = (props) => (
  <Icon component={GeozoneIconSVG} {...props} />
);
