import {RelatedDataPrototype} from "../prototype/RelatedDataPrototype";

export class CargoCityPoints extends RelatedDataPrototype {

    constructor() {
        super({
            name: 'cargoCityPoints',
            filterLabel: 'Чекпоинты грузов',
            urlData: '/tmc_tracking/location_point/',
        });
    }
}
