import { groupTypes } from "../../contentGroups";
import { ObjectsPrototype } from "./ObjectsPrototype";
import { initializeSingleObjectEditMode } from "../../../../../sagas/map/standaloneObjectEditableSaga";
import { deleteObject } from "../../../../../sagas/directory/deleteObjectSaga";
import { reloadMapboxLayerData } from "../../../../../reducers/map/actions/mapboxDataActions";
import { clearMapContextMenu } from "../../../../../reducers/map/actions/mapPanelsActions";
import { FolderIcon } from "../../../../../library/icons/objectIcons/FolderIcon";
import React from "react";
import { DataConcerningIcon } from "../../../../../library/icons/contextMenuIcons/DataConcerningIcon";
import { DeleteIcon } from "../../../../../library/icons/actionIcons/DeleteIcon";
import { getRegisteredLayer } from "../../../layers/layersRegistrator";
const { ACTION_GROUP, EDIT_GROUP } = groupTypes;

export class CommonSelectedItemContextMenuPrototype extends ObjectsPrototype {
  layerName = null;
  selectedObject = null;

  constructor(selectedObject, name, layerName) {
    super(name);
    this.layerName = layerName;
    this.selectedObject = selectedObject;
  }

  actionItems = [
    {
      translatePath: "app.menuMap.contextMenu.clickObject.dataConcerning",
      group: EDIT_GROUP,
      comment: "Данные",
      dispatchedAction: () =>
        initializeSingleObjectEditMode(
          this.selectedObject,
          this.name,
          this.layerName,
          this.initialCoordinate
        ),
      icon: <DataConcerningIcon />,
    },
/*    {
      translatePath: "app.menuMap.contextMenu.clickMap.directory",
      group: ACTION_GROUP,
      comment: "Переход в справочник",
      icon: <FolderIcon />,
      dispatchedAction: {
        type: "TEST_ACTION",
      },
    },*/
    {
      translatePath: "app.menuMap.contextMenu.clickMap.delete",
      group: ACTION_GROUP,
      icon: <DeleteIcon />,
      comment: "Удаление выбранного объекта",
      dispatchedAction: () => {
        const relatedData = getRegisteredLayer(this.layerName).getRelatedData();
        const url = relatedData.find((el) => el.name.includes(this.name ))
        const redrawDispatch = reloadMapboxLayerData(
          url.urlData,
          this.layerName
        );
        const finallySideEffectsDispatch = [clearMapContextMenu];
        return deleteObject({
          url: url.urlData,
          id: this.selectedObject.id,
          redrawDispatch,
          finallySideEffectsDispatch,
        });
      },
    },
  ];
}
