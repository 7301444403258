import { CommonSelectedItemContextMenuPrototype } from "../../prototypes/CommonSelectedItemContextMenuPrototype";
import { mapObjectsEnum } from "../../../../../../enums/mapObjectsEnum";
import React from "react";

export class ReceptionAreasDescription extends CommonSelectedItemContextMenuPrototype {
  initialCoordinate = null;
  constructor(selectedObject, layerName, initialCoordinate) {
    super(selectedObject, mapObjectsEnum.receptionArea, layerName);
    this.initialCoordinate = initialCoordinate;
  }
  actionItems = [...this.actionItems];
}
