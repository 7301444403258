import React from "react";
import {Icon} from "antd";

const VehicleIconSVG = () => (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M9 1.5C5.685 1.5 3 1.875 3 4.5V12C3 12.66 3.2925 13.2525 3.75 13.665V15C3.75 15.4125 4.0875 15.75 4.5 15.75H5.25C5.6625 15.75 6 15.4125 6 15V14.25H12V15C12 15.4125 12.3375 15.75 12.75 15.75H13.5C13.9125 15.75 14.25 15.4125 14.25 15V13.665C14.7075 13.2525 15 12.66 15 12V4.5C15 1.875 12.315 1.5 9 1.5ZM13.245 3.7425H4.755C5.1675 3.345 6.2325 3 9 3C11.7675 3 12.8325 3.345 13.245 3.7425ZM13.5 5.2425V7.5H4.5V5.2425H13.5ZM13.245 12.5475L13.0275 12.75H4.9725L4.755 12.5475C4.67569 12.4799 4.61186 12.396 4.56786 12.3015C4.52386 12.2071 4.50072 12.1042 4.5 12V9H13.5V12C13.5 12.2775 13.3425 12.465 13.245 12.5475Z" fill="currentColor"/>
        <path d="M6.375 12C6.99632 12 7.5 11.4963 7.5 10.875C7.5 10.2537 6.99632 9.75 6.375 9.75C5.75368 9.75 5.25 10.2537 5.25 10.875C5.25 11.4963 5.75368 12 6.375 12Z" fill="currentColor"/>
        <path d="M11.625 12C12.2463 12 12.75 11.4963 12.75 10.875C12.75 10.2537 12.2463 9.75 11.625 9.75C11.0037 9.75 10.5 10.2537 10.5 10.875C10.5 11.4963 11.0037 12 11.625 12Z" fill="currentColor"/>
    </svg>
);

export const VehicleIcon = props => <Icon component={VehicleIconSVG} {...props} />;