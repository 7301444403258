import styled from "styled-components";
import { Button } from "../../UI/components/Button/Button";

const LocalDescItemName = styled.p`
  font-weight: bold;
  font-size: ${(props) => props.theme.fontSettings.defaultSmall};
  word-break: break-word;
  color: ${(props) => props.theme.colors.neutralDarker};
  padding-top: 8px;
  margin: 0;
`;

const LocalItem = styled.div`
  & > article {
    text-align: left;
    width: 196px;
    min-height: 60px;
    height: fit-content;
    border-bottom: 1px solid ${(props) => props.theme.colors.defaultLight};
  }
`;

const LocalDescItemContent = styled.p`
  color: ${(props) => props.theme.colors.paragraph};
  word-break: break-word;
  font-size: ${(props) => props.theme.fontSettings.defaultSmall};
  margin: 0;
`;

const ExtendedBtnClose = styled(Button)`
  position: absolute;
  top: 9px;
  right: 12px;
`;

const LocalHeaderWrapper = styled.div``;

const LocalWrapperBtn = styled.div``;

const LocalHeaderTitle = styled.div`
  width: 170px;
  color: ${(props) => props.theme.colors.defaultDark};
  word-break: break-all;
`;

export const Styled = {
  LocalDescItemName,
  LocalDescItemContent,
  LocalHeaderTitle,
  LocalWrapperBtn,
  LocalItem,
  LocalHeaderWrapper,
  ExtendedBtnClose,
};
