import {LayerPrototype} from "../description/prototype/LayerPrototype";
import {layerNamesEnum} from "../../../../enums/layerNamesEnum";
import {relatedLayerData} from "../description/relatedData";
import {layerCards} from "../description/cards";
import {layerSchemas} from "../description/layerSchemaPresets";
import {layerDataAccessors} from "../description/dataAccessors";

export class CargoTrackingLayer extends LayerPrototype{

    constructor() {
        super({
            name:  layerNamesEnum.cargoTracking,
            dataAccessor : new layerDataAccessors.nipigazLayerDataAccessors.CargoRepresentationDataAccessor(),
            relatedData: [
                new relatedLayerData.nipigazRelatedDataForLayers.CargoTransferParts(),
            ],
            card: new layerCards.nipigazLayerCards.CargoTrackingCard(),
            customSettings: [],
            layerSchema : [
                new layerSchemas.nipigazLayersSchema.CargoTrackingLayerSchema(),
            ],
        });
    }
}
