import React from "react";
import { Icon } from "antd";

const OverpassIconSvg = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
        <path d="M8.33335 1.6665L6.66669 6.6665H9.16669V1.6665H8.33335ZM10.8334 1.6665V6.6665H13.3334L11.6667 1.6665H10.8334ZM1.66669 7.49984V8.33317H3.33335V9.1665H5.00002V8.33317H15L15.05 9.1665H16.6667V8.33317H18.3334V7.49984H1.66669ZM5.83335 9.1665L2.78335 18.3332H9.16669V9.1665H5.83335ZM10.8334 9.1665V18.3332H17.2167L14.1667 9.1665H10.8334Z" fill="#1B3548"/>
    </svg>



);

export const OverpassIcon = props => (
    <Icon component={OverpassIconSvg} {...props} />
);
