import React from 'react';
import {Button} from "../../../../../library/UI/components/Button/Button";
import {CloseIcon} from "../../../../../library/icons/actionIcons/CloseIcon";

const StandaloneEditModeControls = ({handleCloseEditMode}) => {
    return (
        <div>
            <Button type={"primaryReverse"} p={0.5} icon={<CloseIcon />} onClick={() => handleCloseEditMode()} hasShadow rounded />
        </div>
    );
};

export default StandaloneEditModeControls;
