import {editableObjectDescriptionEnum} from "../../../../../../enums/editableObjectDescriptionEnum";
import {CommonSelectedItemContextMenuPrototype} from "../../prototypes/CommonSelectedItemContextMenuPrototype";

export class GeozoneSingleDescription extends CommonSelectedItemContextMenuPrototype {

    constructor(selectedObject,layerName, initialCoordinate) {
        super(selectedObject, editableObjectDescriptionEnum.geozone, layerName)
    }

    actionItems = [...this.actionItems]

    getTitle(){
        return this.selectedObject.name
    }
}

