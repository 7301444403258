import React from 'react';
import LayerFilterTab from './LayerFilterTab';
import { connect } from 'react-redux';
import {getSelectedMapboxLayer} from "../../../../../../../../../../../reducers/map/mapboxLayers";
import {recalculate} from "../../../../../../../../../../../reducers/app/actions/appActions";


    const LayerFilterTabContainer = (props) => {
        return <LayerFilterTab {...props} />;
    };

    LayerFilterTabContainer.propTypes = {};

    const mapStateToProps = (state) => ({
        selectedLayer: getSelectedMapboxLayer(state),
        recalculated: state.app.recalculated,

    });

const mapDispatchToProps = (dispatch) => ({
    recalculate: () => dispatch(recalculate()),
});

    export default connect(mapStateToProps, mapDispatchToProps)(LayerFilterTabContainer);