import React from "react";
import { Icon } from "antd";

const UploadFileItemSVGIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="18" viewBox="0 0 14 18" fill="currentColor">
        <path d="M1.99967 0.666748C1.55765 0.666748 1.13372 0.842343 0.821163 1.1549C0.508602 1.46746 0.333008 1.89139 0.333008 2.33341V15.6667C0.333008 16.1088 0.508602 16.5327 0.821163 16.8453C1.13372 17.1578 1.55765 17.3334 1.99967 17.3334H11.9997C12.4417 17.3334 12.8656 17.1578 13.1782 16.8453C13.4907 16.5327 13.6663 16.1088 13.6663 15.6667V5.66675L8.66634 0.666748H1.99967ZM1.99967 2.33341H7.83301V6.50008H11.9997V15.6667H1.99967V2.33341ZM3.66634 9.00008V10.6667H10.333V9.00008H3.66634ZM3.66634 12.3334V14.0001H7.83301V12.3334H3.66634Z" fill="currentColor"/>
    </svg>
);

export const UploadFileItemIcon = props => (
    <Icon component={UploadFileItemSVGIcon} {...props} />
);
