import React, { useRef, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { userLoginFetch } from "../../../../reducers/app/actions/authActions";
import { Redirect } from "react-router-dom";
import { LockOutlined } from "@ant-design/icons";
import PasswordInput from "../../../../library/nonformedInputs/PasswordInput";
import { useTranslation } from "react-i18next";
import { Controller, ErrorMessage, useForm } from "react-hook-form";
import StringInputLogin from "../Input/StringInput";
import { Styled } from "./Login.styled";
import { setGlobalAlertData } from "../../../../reducers/app/actions/notificationActions";
import { alertTypesEnum } from "../../../../enums/alertTypesEnum";
import { alertStatusesEnum } from "../../../../enums/alertStatusesEnum";

const {
  LocalMainWrapper,
  LocalMainInner,
  LocalForm,
  LocalInputLogin,
  LocalPasswordInput,
  ExtendedButton,
  ExtendedNavLink,
  LocalMainTitle,
  LocalPlaceholderLogin,
  LocalPlaceholderPassword,
  LocalPrimaryDescription,
} = Styled;

const Login = (props) => {
  const formRef = useRef(null);
  const { t } = useTranslation("common");
  const [userData] = useState({
    username: "",
    password: "",
  });
  const [focused] = useState({
    username: false,
    password: false,
  });

  const user = useSelector((state) => state.auth.user);
  const dispatch = useDispatch();
  const sessionUser = localStorage.user;

  const handleSubmitWithFetch = (userData) => {
    props.userLoginFetch(userData);
  };

  const checkUser = () => {
    if (sessionUser && !user) {
      dispatch({
        type: "LOGIN_USER",
        payload: sessionUser,
      });
    }
  };

  const onSubmit = (data) => {
/*
    props.setGlobalErrorMessage(null);
    ненужный вызов колбека с пустым значение
*/
    handleSubmitWithFetch(data);
  };

  const { errors, control, handleSubmit } = useForm({
    validateCriteriaMode: "all",
    mode: "onChange",
  });

  checkUser();
  return (
    <LocalMainWrapper>
      <LocalMainInner>
        <LocalMainTitle>{t("guest.login.title")}</LocalMainTitle>
        <LocalForm ref={formRef}>
          <LocalInputLogin>
            <Controller
              as={<StringInputLogin />}
              control={control}
              rules={{
                required: {
                  value: true,
                  message: "Укажите логин пользователя",
                },
                pattern: {
                  value: /^[a-z0-9_-]{3,16}$/,
                  message: "Недопустимые символы",
                },
              }}
              name="username"
            />

            <LocalPlaceholderLogin
              className={`inline-placeholder-login 
                        ${userData.username ? "open" : "close"}
                        ${focused.username ? "focused" : ""}
                        `}
            >
              {t("guest.login.loginForm")}
            </LocalPlaceholderLogin>
          </LocalInputLogin>
          <ErrorMessage name="username" errors={errors} as="p" />
          <LocalPasswordInput>
            <Controller
              as={<PasswordInput />}
              name="password"
              prefix={<LockOutlined />}
              control={control}
              style={{ margin: "0 auto" }}
            />
            <LocalPlaceholderPassword
              className={`inline-placeholder-login 
                        ${userData.password ? "open" : "close"}
                        ${focused.password ? "focused" : ""}
                        `}
            >
              {t("guest.login.password")}
            </LocalPlaceholderPassword>
          </LocalPasswordInput>
          <ExtendedButton
            onClick={handleSubmit((data) => {
              onSubmit(data);
            })}
            type={"primary"}
            hasShadow
            p={"1.3rem"}
            fontSize={"1.15rem"}
            width={"100%"}
          >
            {t("guest.login.logIn")}
          </ExtendedButton>
        </LocalForm>
        <ExtendedNavLink to={"/guest/login/remember"}>
          <LocalPrimaryDescription>
            {t("guest.login.forgotPassword")}
          </LocalPrimaryDescription>
        </ExtendedNavLink>
      </LocalMainInner>

      {sessionUser && (
        <Redirect
          to={{
            pathname: "/app/map",
          }}
        />
      )}
    </LocalMainWrapper>
  );
};
//Todo realization state for input
//TODO style for ...PlaceHolderLogin

const mapDispatchToProps = (dispatch) => ({
  userLoginFetch: (userInfo) => dispatch(userLoginFetch(userInfo)),
  setGlobalErrorMessage: (message = "Что-то пошло не так") =>
    dispatch(
      setGlobalAlertData({
        status: alertStatusesEnum.active,
        type: alertTypesEnum.error,
        title: "Ошибка",
        message: message,
      })
    ),
});

export default connect(null, mapDispatchToProps)(Login);
