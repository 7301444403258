import {mapContextMenuContentTypesEnum} from "../../../../../../enums/mapContextMenuContentTypesEnum";
import React from "react";
import {groupTypes} from "../../../contentGroups";
import {initializeSingleObjectEditMode} from "../../../../../../sagas/map/standaloneObjectEditableSaga";
import {CheckpointIcon} from "../../../../../../library/icons/contextMenuIcons/CheckpointIcon";
import {mapObjectsEnum} from "../../../../../../enums/mapObjectsEnum";
import {CommonSelectedItemContextMenuPrototype} from "../../prototypes/CommonSelectedItemContextMenuPrototype";

const { ADD_GROUP } = groupTypes;

export class TrafficMainSegmentDescription extends CommonSelectedItemContextMenuPrototype {
  initialCoordinate = null;
  constructor(selectedObject, layerName, initialCoordinate) {
    super(selectedObject, mapObjectsEnum.trafficSegment, layerName);
    this.initialCoordinate = initialCoordinate;
  }

  actionItems = [
    ...this.actionItems,
    {
      name: mapContextMenuContentTypesEnum.createTrafficSegment,
      translatePath: "Скоростной режим",
      group: ADD_GROUP,
      dispatchedAction: () =>
        initializeSingleObjectEditMode(
          this.selectedObject,
          mapObjectsEnum.trafficSegment,
          this.layerName,
          this.initialCoordinate
        ),
      icon: <CheckpointIcon />,
    },
  ];
}
