import styled from "styled-components";
import { Layout } from "antd";

const { Sider } = Layout;

const SiderWrapper = styled(Sider)`
  background: transparent;
  height: 100%;
  margin-right: 1.15rem;
  & .ant-menu-item {
    line-height: 2rem;
    font-size: 1rem;
    padding: 0;
    height: auto;
    margin: 0 !important;
    color: ${(props) => props.theme.colors.neutralDarker};
    &:hover {
      color: ${(props) => props.theme.colors.neutralLight};
    }
    &.active {
      background-color: transparent;
      font-weight: bold;
      color: ${(props) => props.theme.colors.primary};
    }
    & + .ant-menu-item {
      margin-top: 1.15rem !important;
    }
  }
  & ul {
    border: none;
  }
`;

const SiderInner = styled("section")`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
`;

const SiderContent = styled("div")`
  width: 100%;
  padding: 1.15rem;
  flex: 1 1 auto;
  background: white;
  border-radius: 6px;
  text-align: left;
`;

const SiderTitle = styled("h3")`
  margin: 0;
  font-size: 1rem;
  & + * {
    margin-top: 1.15rem;
  }
`;

const SiderFooter = styled("footer")`
  width: 100%;
  & button {
    margin-top: 1.15rem;
  }
`;

export const Styled = {
  SiderWrapper,
  SiderInner,
  SiderContent,
  SiderFooter,
  SiderTitle,
};
