import {RelatedDataPrototype} from "../prototype/RelatedDataPrototype";

export class DischargeZones extends RelatedDataPrototype {

    constructor() {
        super({
            name: 'discharge_zones',
            filterLabel: 'Зоны разгрузки',
            urlData: '/railway_network/discharge_zones/',
        });
    }
}
