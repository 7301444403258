import React from "react";
import PropTypes from "prop-types";
import { Styled } from "./PrepareScrollableInfoCard.styled";
import ScrollableCard from "../../UI/components/ScrollableCard/ScrollableCard";
import { CloseIcon } from "../../icons/actionIcons/CloseIcon";
import { Button } from "../../UI/components/Button/Button";

const {
  LocalDescItemName,
  LocalDescItemContent,
  LocalHeaderWrapper,
  ExtendedBtnClose,
  LocalHeaderTitle,
  LocalItem,
} = Styled;

function createDescriptionItem(key, value) {
  return (
    <article>
      <LocalDescItemName>{key}</LocalDescItemName>
      {key=== "Фактическая дата прибытия" ? (<LocalDescItemContent style={{background: 'rgba(0, 151, 166, 0.08)', color: '#0097A6'}}>{value}</LocalDescItemContent>) : (<LocalDescItemContent>{value}</LocalDescItemContent>)}
      {/*<LocalDescItemContent>{value}</LocalDescItemContent>*/}
    </article>
  );
}
const PrepareScrollableInfoCard = ({
  handleCloseCard,
  title,
  openInNewTabBtnDesc,
  listOfDescriptions,
  handleOpenItemInDirectory,
}) => {
  let preparedDescItems = null;
  if (listOfDescriptions) {
    preparedDescItems = listOfDescriptions.map((descItem) => {
      const { key, value } = descItem;
      return createDescriptionItem(key, value);
    });
  }

  return (
    <ScrollableCard
      cardHeight={`458px`}
      headerStyles={{
        padding: `11px 6px 8px 16px`,
        position: "relative",
      }}
      headerContent={
        <LocalHeaderWrapper>
          <LocalHeaderTitle>{title}</LocalHeaderTitle>
          <ExtendedBtnClose
            onClick={handleCloseCard}
            icon={<CloseIcon />}
            type={"unboundedPrimary"}
            p={0}
          />
        </LocalHeaderWrapper>
      }
      contentStyles={{ padding: `0 16px`, marginRight: `4px` }}
      children={<LocalItem>{preparedDescItems}</LocalItem>}
      footerStyles={{ padding: `10px 48px 9px 16px` }}
      footerContent={
        <div>
          <Button
            onClick={handleOpenItemInDirectory}
            type={"unboundedPrimary"}
            p={0}
          >
            {openInNewTabBtnDesc}
          </Button>
        </div>
      }
    />
  );
};

export default PrepareScrollableInfoCard;

PrepareScrollableInfoCard.propTypes = {
  handleCloseCard: PropTypes.func.isRequired,
  handleOpenItemInDirectory: PropTypes.func.isRequired,
  listOfDescriptions: PropTypes.arrayOf(PropTypes.object),
  openInNewTabBtnDesc: PropTypes.string,
  title: PropTypes.string,
};

PrepareScrollableInfoCard.defaultProps = {
  openInNewTabBtnDesc: "Open in new tab",
  title: "Detail information",
  listOfDescriptions: [],
};
