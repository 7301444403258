import React, {useRef} from "react";
import { connect } from "react-redux";
import { directoryHistoryActions } from "../../../reducers/directory/actions/directoryHistoryActions";
import { getLastHistoryRecord } from "../../../reducers/directory/directoryHistory";
import { Button } from "../../UI/components/Button/Button";
import { PlusIcon } from "../../icons/actionIcons/PlusIcon";
import { compose } from "redux";
import { withTheme } from "styled-components";

const {postToInlineHistory} = directoryHistoryActions

const CreateButton = props => {
  const { lastInlineHistory, pushInlineHistory, CRUDHandlers } = props;
  const iconSize = useRef({ width: "20px", height: "20px" });

  // const proceedData = selectedRow => {
  //   pushInlineHistory({
  //     type: "mainObject",
  //     ids: [],
  //     urlData: lastInlineHistory.urlData,
  //     url: `/app/directory/${lastInlineHistory.url}/new/`,
  //     field: lastInlineHistory.field,
  //     newObject: true
  //   });
  // };

  return (
    <Button
      onClick={() => CRUDHandlers.createObject()}
      icon={<PlusIcon />}
      rounded
      hasShadow
      type={"danger"}
      iconColor={props.theme.colors.primary}
      iconSize={iconSize.current}
    />
  );
};

const mapDispatchToProps = dispatch => {
  return {
    pushInlineHistory: historyRecord =>
      dispatch(postToInlineHistory(historyRecord))
  };
};

const mapStateToProps = state => {
  return {
    lastInlineHistory: getLastHistoryRecord(state)
  };
};

export default compose(
  withTheme,
  connect(mapStateToProps, mapDispatchToProps)
)(CreateButton);
