import React, {useRef} from "react";
import { Responsive, WidthProvider } from "react-grid-layout";
import styled from "styled-components";
import { baseStyles } from "../../stylesRegistrator";
import { dispatchNativeResizeEvent } from "../../../services/window/dispatchNativeResizeEvent";
import { useTimeoutFuncCall } from "../../../customHooks/common/useTimeoutFuncCall";

const ResponsiveReactGridLayout = WidthProvider(Responsive);
const { BaseHomePageWidgetCard } = baseStyles.modules.homePage;
const { MSFullHeightWrap } = baseStyles.main;

const StyledResponsiveReactGridLayout = styled(ResponsiveReactGridLayout)`
  height: 100% !important;
  overflow-y: auto;
  width: 100%;

  &::-webkit-scrollbar {
    display: none;
  }
`;
const height = 100;

const layoutLg = [
  { i: "1", x: 0, y: 0, w: 4, h: 2 },
  { i: "2", x: 4, y: 0, w: 4, h: 2 },
  { i: "3", x: 9, y: 0, w: 4, h: 2 },
  { i: "4", x: 0, y: 1, w: 2, h: 2 },
  { i: "5", x: 2, y: 1, w: 2, h: 2 },
  { i: "6", x: 5, y: 1, w: 8, h: 2 },
  { i: "7", x: 0, y: 2, w: 12, h: 4 },
];

const layoutMd = [
  { i: "1", x: 0, y: 0, w: 2, h: 2 },
  { i: "2", x: 2, y: 0, w: 2, h: 2 },
  { i: "3", x: 4, y: 0, w: 6, h: 2 },
  { i: "4", x: 0, y: 0, w: 2, h: 2 },
  { i: "5", x: 2, y: 0, w: 2, h: 2 },
  { i: "6", x: 4, y: 0, w: 6, h: 2 },
  { i: "7", x: 0, y: 0, w: 2, h: 2 },
];

const layoutSM = [
  { i: "1", x: 0, y: 0, w: 6, h: 1 },
  { i: "2", x: 0, y: 0, w: 6, h: 1 },
  { i: "3", x: 0, y: 0, w: 6, h: 1 },
  { i: "4", x: 0, y: 0, w: 6, h: 1 },
  { i: "5", x: 0, y: 0, w: 6, h: 1 },
  { i: "6", x: 0, y: 0, w: 6, h: 1 },
  { i: "7", x: 0, y: 0, w: 6, h: 1 },
];

const layouts = {
  lg: layoutLg,
  md: layoutMd,
  sm: layoutSM,
};

const HomePage = ({ sidebarIsOpened }) => {
  const layoutRef = useRef(null);
  // this fn need for call window resize event after component mounting and change state inside WidthProvider
  // without this fn component will be mounting with default state and grid width will be oversized
  useTimeoutFuncCall(dispatchNativeResizeEvent,[sidebarIsOpened], 500);

  return (
    <MSFullHeightWrap>
      <StyledResponsiveReactGridLayout
        className="layout"
        layouts={layouts}
        breakpoints={{ lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0 }}
        cols={{ lg: 12, md: 10, sm: 6, xs: 4, xxs: 2 }}
        rowHeight={height}
        containerPadding={[24, 0]}
        compactType={"vertical"}
        ref={layoutRef}
      >
        {layoutLg.map((item, index) => (
          <BaseHomePageWidgetCard key={`${index + 1}`}>
            content placeholder
          </BaseHomePageWidgetCard>
        ))}
      </StyledResponsiveReactGridLayout>
    </MSFullHeightWrap>
  );
};

export default HomePage;
