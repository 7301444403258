import { combineReducers } from "redux";
import auth from "./app/auth";
import app from "./app/app";
import directoryHistory from "./directory/directoryHistory";
import selectedObject from "./directory/selectedObject";
import mapboxView from "./map/mapboxView";
import mapboxData from "./map/mapboxData";
import mapboxLayers from "./map/mapboxLayers";
import mapPanels from "./map/mapPanels";
import mapSelectedObject from "./map/mapSelectedObject";
import mapboxTime from "./map/mapboxTime";
import socketsStatus from "./map/socketsStatus";
import mapboxEditableLayersHistory from "./map/mapboxEditableLayersHistory";
import mapboxEditableStandaloneObject from "./map/mapboxEditableStandaloneObject";
import mapMode from "./map/mapMode";
import sidebarPosition from "./sidebar/sidebar";
import mapDataAccessorSelectedObjects from "./map/mapDataAccessorSelectedObjects";
import { connectRouter } from 'connected-react-router'
import notifications from "./app/notifications";
import mapAutoCreateDetour from "./map/mapAutoCreateDetour";

const createRootReducer = (history) => combineReducers({
  auth,
  app,
  notifications,
  directoryHistory,
  selectedObject,
  mapMode,
  mapboxView,
  mapboxLayers,
  mapboxData,
  mapPanels,
  mapSelectedObject,
  mapboxTime,
  mapAutoCreateDetour,
  socketsStatus,
  mapboxEditableLayersHistory,
  mapboxEditableStandaloneObject,
  sidebarPosition,
  mapDataAccessorSelectedObjects,
  router: connectRouter(history)
});

export default createRootReducer;
