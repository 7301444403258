import {put, takeEvery, call} from 'redux-saga/effects'
import {SET_OBJECT_FOR_STANDALONE_EDIT_MODE, SET_STANDALONE_EDIT_MODE_OBJECT} from "../../reducers/map/mapboxEditableStandaloneObject";
import {resolveEditableObjectDescription} from "../../registrators/old/editableObjectsRegistrator/editableObjectsRegistrator";
import {getFromURL} from "../../api/getFromURL";
import {
  defineDrawGeometryTypeOnModel,
  defineDrawKeyOnModel,
  defineDrawModeOnModel
} from "../../services/map/defineDrawMapOnModel";
import wkt from "wkt";
import {point} from '@turf/helpers'
import _ from 'underscore'
import {alertTypesEnum} from "../../enums/alertTypesEnum";
import {setGlobalAlertData} from "../../reducers/app/actions/notificationActions";
import {alertStatusesEnum} from "../../enums/alertStatusesEnum";
import {SET_NOTIFICATION_DATA} from "../../reducers/app/notifications";
import {promptStatusesEnum} from "../../enums/promptStatusesEnum";

export const INIT_SINGLE_EDIT_MAP_MODE_SAGA = 'INIT_SINGLE_EDIT_MAP_MODE_SAGA'
//closureId используется при создании объезда для перекрытия
export const initializeSingleObjectEditMode = (selectedObject, typeOfObject, objectLayerName, initialCoordinate, closureId=null) => {
  return {
    type: INIT_SINGLE_EDIT_MAP_MODE_SAGA,
    payload: {
      selectedObject,
      typeOfObject,
      objectLayerName,
      initialCoordinate,
      closureId,
    }
  }
}

function* initSingleObjectEditableSaga(action) {
  try {
    const {selectedObject, typeOfObject, objectLayerName, initialCoordinate, closureId} = action.payload
    const urlData = resolveEditableObjectDescription(typeOfObject, 'urlData')
    const name = resolveEditableObjectDescription(typeOfObject, 'label')
    const parentInstance =  resolveEditableObjectDescription(typeOfObject, 'parent')
    const layerName = objectLayerName
    const model = yield call(() => getFromURL.getModelFromURL(urlData))
    const parentKey = parentInstance?.key || null
    let parentId = null
    const geometryKey = defineDrawKeyOnModel(model)
    let isNew = false
    if (!selectedObject?.id) {
      isNew = true;
    }
    let selectedKeys =selectedObject ? Object.keys(selectedObject) : [];
    selectedKeys = _.without(selectedKeys,'object_type')
    selectedKeys = _.without(selectedKeys,geometryKey)
    let schemaKeys = Object.keys(model.scheme);
    schemaKeys = _.without(schemaKeys,geometryKey)
    schemaKeys = _.without(schemaKeys,"template") //на беке у эстакад лишнее поле. костыль
    const keysAreSame = _.isEqual(selectedKeys,schemaKeys)
    if (selectedObject && !keysAreSame){
      isNew = true;
    }
    let drawData;
    if (isNew) {
      if (initialCoordinate) {
        const typeOfGeometry = defineDrawGeometryTypeOnModel(model)
        switch (typeOfGeometry) {
          case 'polygon': {
            drawData = [];
            break;
          }
          case 'linestring': {
            drawData = [];
            break;
          }
          case 'point': {
            drawData = point(initialCoordinate).geometry;
            break;
          }
        }
      } else {
        drawData = null;
      }
    } else {
      if (selectedObject?.[geometryKey]) {
        drawData = wkt.parse(selectedObject?.[geometryKey]);
      } else {
        drawData = null;
      }
    }
    const drawDataInTemplate = {
      type: 'FeatureCollection',
      features: [{
        geometry: {...drawData},
        properties: {},
        type: "Feature"
      }
      ],
    }

    const editMode = defineDrawModeOnModel(model)
    if (keysAreSame) {
      if (selectedObject?.[parentKey]){
        parentId = selectedObject?.[parentKey]
      } else parentId = null
    } else {
      parentId = selectedObject ? selectedObject?.id : null
    }
    const instance = isNew ? {} : selectedObject
    const parentModel = parentInstance ? yield call(() => getFromURL.getModelFromURL(parentInstance.urlData)) : null
    let parentData = parentInstance && parentId ? yield call(() => getFromURL.getDataFromURL(parentInstance.urlData+`${parentId}/`)) : null
    if(parentInstance?.creatable) {
      model.scheme[parentKey].type = 'creatable related field'
    } else if (parentInstance && !parentInstance.creatable){
      instance[parentKey] = parentData?.id
      model.scheme[parentKey].read_only = true
    }
    const preparedData = {
      urlData,
      selectedInstance: typeOfObject,
      drawData : drawDataInTemplate,
      parentObjectsDescription: parentModel,
      model,
      geometryKey,
      editMode,
      name,
      isNew,
      instance,
      parentKey,
      parentId,
      layerName,
      parentData,
      parentModel,
      closureId
    }
    //finally set mode to true
    yield  put({
      type: SET_STANDALONE_EDIT_MODE_OBJECT,
      payload: {
        ...preparedData
      }
    })
    yield put({
      type: SET_OBJECT_FOR_STANDALONE_EDIT_MODE,
      payload: {
        value: true,
      }
    })

    yield put({
      type: SET_NOTIFICATION_DATA,
      payload: {
        message: "Нарисуйте геометрию",
        status: promptStatusesEnum.active,
      },
    });
  }
  catch (e) {
    console.error(e)
    yield put(
        setGlobalAlertData({status: alertStatusesEnum.active, type: alertTypesEnum.error, title: "Ошибка", message: 'Что-то пошло не так'})
    )
  }
}


//ready
export function* watchSingleObjectEditableSaga() {
  yield takeEvery(INIT_SINGLE_EDIT_MAP_MODE_SAGA, initSingleObjectEditableSaga)
}
