import {CompositeLayer} from "@deck.gl/core";
import {PathLayer, ScatterplotLayer, TextLayer} from "@deck.gl/layers";
import wkt from "wkt";
import {PathStyleExtension} from "@deck.gl/extensions";
import {layerNamesEnum} from "../../../../enums/layerNamesEnum";
import {mapObjectsEnum} from "../../../../enums/mapObjectsEnum";
import {sliceLineIntoSegments} from "../../../../services/map/sliceLineIntoSegments";
import {cutLineStartAndEndByPercent} from "../../../../services/map/cutLineStartAndEndByPercent";
import {getCoords, length, along} from "@turf/turf";
import {waggonsUnloadingStatusEnums} from "../../../../enums/waggonsUnloadingStatusEnums";

const getDischargeZoneItemLayer = ({
                                       context,
                                       dischargeZone,
                                       waggonCapacity,
                                       itemIndex,
                                   }) => {
    return new PathLayer(
        context.props,
        context.getSubLayerProps({
            id: `discharge-zone-item-layer-${dischargeZone.id}-${itemIndex}`,
        }),
        {
            data: context.props.relatedData.discharge_zones,
            pickable: false,
            widthScale: 3,
            billboard: false,
            rounded: true,
            widthMinPixels: 3,
            getDashArray: [0, 0],
            getOffset: 10,
            highlightColor: [95, 102, 236],
            getPath: (d) => {
                if (dischargeZone?.line_path) {
                    const geometry = wkt.parse(dischargeZone?.line_path);
                    const rawSegments = sliceLineIntoSegments(geometry, waggonCapacity);
                    const percentToCut = 40;
                    const preparedSegment = cutLineStartAndEndByPercent(
                        rawSegments[itemIndex],
                        percentToCut
                    );
                    return getCoords(preparedSegment);
                }
                return [0, 0];
            },
            getColor: (d) => {
                const notUnloadedItemColor = [255, 241, 118];
                const unloadedItemColor = [118, 255, 3];
                const emptyItemColor = [238, 238, 238];
                const itemFilledByWaggon = dischargeZone?.waggons?.[itemIndex];
                if(itemFilledByWaggon) {
                    const isItemInUnloadingProgress = itemFilledByWaggon.status
                    if(isItemInUnloadingProgress === waggonsUnloadingStatusEnums.UNLOADING){
                        return notUnloadedItemColor
                    }else if (isItemInUnloadingProgress ===  waggonsUnloadingStatusEnums.UNLOADED ){
                        return unloadedItemColor
                    }
                }
                return emptyItemColor;
            },
            getWidth: (d) => {
                return 2;
            },
            opacity: 1,
            onClick: (d) => {
                this.props.onClick();
            },
        }
    );
};

function isNeedUpdateDischargeZonesState(props, state) {
    const dischargeZones = props?.relatedData?.discharge_zones;
    const isStateNotEqualProps =
        JSON.stringify(dischargeZones) !== JSON.stringify(state.discharge_zones);
    return (
        dischargeZones && isStateNotEqualProps && Array.isArray(dischargeZones)
    );
}

class RailwayNetworkCompositeLayer extends CompositeLayer {
    updateState({changeFlags, props}) {
        if (props.zoom && this.state.zoom !== props.zoom) {
            const z = Math.floor(props.zoom);
            this.setState({
                zoom: z,
                railwaySize: 0.145
            });
        }
        if (isNeedUpdateDischargeZonesState(props, this.state)) {
            const {discharge_zones} = props.relatedData;
            this.setState({
                discharge_zones: discharge_zones,
            });
            const dischargeZonesItemsData = [];
            for (let i = 0; i < discharge_zones.length; i++) {
                const capacity = discharge_zones[i]?.waggon_capacity;
                if (capacity) {
                    for (let index = 0; index < capacity; index++) {
                        dischargeZonesItemsData.push(
                            getDischargeZoneItemLayer({
                                context: this,
                                waggonCapacity: capacity,
                                dischargeZone: discharge_zones[i],
                                itemIndex: index,
                            })
                        );
                    }
                }
            }
            this.setState({
                discharge_zones_items_data: dischargeZonesItemsData,
            });
        }
    }

    getPickingInfo(event) {
        if (event.mode === "query") {
            if (this.props.onClickHandler) {
                let selectedObjectType = null;
                let selectedObjectLayerName = layerNamesEnum.railwayNetwork;
                if (event.sourceLayer.id.includes("train-way-path-layer")) {
                    selectedObjectType = mapObjectsEnum.railway;
                } else if (event.sourceLayer.id.includes("discharge-zone")) {
                    selectedObjectType = mapObjectsEnum.dischargeZone;
                } else if (event.sourceLayer.id.includes("reception-area")) {
                    selectedObjectType = mapObjectsEnum.receptionArea;
                }
                this.props.onClickHandler(
                    event.info,
                    selectedObjectType,
                    selectedObjectLayerName
                );
            }
        } else return null;
    }

    renderLayers() {
        if (!this.props.relatedData) return [];
        const layers = [
            new PathLayer(
                this.props,
                this.getSubLayerProps({id: "train-way-path-layer-back"}),
                {
                    data: this.props.relatedData.railways,
                    pickable: true,
                    widthScale: 3,
                    billboard: false,
                    widthMinPixels: 3,
                    getPath: (d) => {
                        if (d?.line_path) {
                            const geometry = wkt.parse(d.line_path);
                            return geometry.coordinates;
                        }
                        return [0, 0];
                    },
                    getColor: (d) => {
                        return [255, 255, 255];
                    },
                    getWidth: (d) => this.state.railwaySize*5,
                    opacity: 1,
                    onClick: (d) => {
                        this.props.onClick();
                    },
                }
            ),
            new PathLayer(
                this.props,
                this.getSubLayerProps({id: "train-way-path-layer"}),
                {
                    data: this.props.relatedData.railways,
                    pickable: true,
                    widthScale: 3,
                    widthMinPixels: 3,
                    billboard: false,
                    getDashArray: [5, 5],
                    getOffset: 20,
                    dashJustified: true,
                    getPath: (d) => {
                        if (d?.line_path) {
                            const geometry = wkt.parse(d.line_path);
                            return geometry.coordinates;
                        }
                        return [0, 0];
                    },
                    getColor: (d) => {
                        return [181, 186, 186];
                    },
                    getWidth: (d) => this.state.railwaySize*5,
                    opacity: 1,
                    onClick: (d) => {
                        this.props.onClick();
                    },
                    extensions: [new PathStyleExtension({dash: true})],
                }
            ),
            new PathLayer(
                this.props,
                this.getSubLayerProps({id: "train-way-path-layer-the"}),
                {
                    data: this.props.relatedData.railways,
                    pickable: true,
                    widthScale: 3,
                    autoHighlight: true,
                    widthMinPixels: 3,
                    billboard: false,
                    getDashArray: [5, 5],
                    getOffset: 20,
                    dashJustified: true,
                    getPath: (d) => {
                        if (d?.line_path) {
                            const geometry = wkt.parse(d.line_path);
                            return geometry.coordinates;
                        }
                        return [0, 0];
                    },
                    getColor: (d) => {
                        return [216, 216, 223];
                    },
                    getWidth: (d) => this.state.railwaySize*5,
                    opacity: 0,
                    onClick: (d) => {
                        this.props.onClick();
                    },
                }
            ),
            new PathLayer(
                this.props,
                this.getSubLayerProps({id: "train-discharge-zone-path-layer"}),
                {
                    data: this.props.relatedData.discharge_zones,
                    pickable: true,
                    widthScale: 3,
                    autoHighlight: true,
                    widthMinPixels: 3,
                    billboard: false,
                    getDashArray: [0, 0],
                    getOffset: 20,
                    rounded: true,
                    getPath: (d) => {
                        if (d?.line_path) {
                            const geometry = wkt.parse(d?.line_path);
                            return geometry.coordinates;
                        }
                        return [0, 0];
                    },
                    getColor: (d) => {
                        return [0, 0, 0];
                    },
                    getWidth: (d) => {
                        return this.state.railwaySize*20;
                    },
                    opacity: 0.2,
                    onClick: (d) => {
                        this.props.onClick();
                    },
                }
            ),
            new PathLayer(
                this.props,
                this.getSubLayerProps({id: "train-reception-area-path-layer"}),
                {
                    data: this.props.relatedData.reception_areas,
                    pickable: true,
                    widthScale: 3,
                    autoHighlight: true,
                    widthMinPixels: 3,
                    billboard: false,
                    getDashArray: [0, 0],
                    getOffset: 20,
                    rounded: true,
                    getPath: (d) => {
                        if (d?.line_path) {
                            const geometry = wkt.parse(d?.line_path);
                            return geometry.coordinates;
                        }
                        return [0, 0];
                    },
                    getColor: (d) => {
                        return [235, 87, 87];
                    },
                    getWidth: (d) => {
                        return this.state.railwaySize*30;
                    },
                    opacity: 0.2,
                    onClick: (d) => {
                        this.props.onClick();
                    },
                }
            ),
            new ScatterplotLayer(
                this.props,
                this.getSubLayerProps({id: "train-reception-area-circle-layer"}),
                {
                    data: this.props.relatedData.reception_areas,
                    pickable: true,
                    filled: true,
                    rounded: true,
                    getPosition: (d) => {
                        if (d?.line_path) {
                            const geometry = wkt.parse(d?.line_path);
                            return getCoords(along(geometry, length(geometry) / 2));
                        }
                        return [0, 0];
                    },
                    getRadius: (d) => {
                        return this.state.railwaySize*40;
                    },
                    getColor: (d) => {
                        return [255, 255, 255];
                    },
                    opacity: 0.8,
                    onClick: (d) => {
                        this.props.onClick();
                    },
                }
            ),
            new TextLayer(
                this.props,
                this.getSubLayerProps({id: "train-reception-area-number-layer"}),
                {
                    data: this.props.relatedData.reception_areas,
                    pickable: true,
                    getText: (d) => {
                        if (d?.waggons) {
                            return `${d?.waggons.length}`;
                        }
                    },
                    getPosition: (d) => {
                        if (d?.line_path) {
                            const geometry = wkt.parse(d?.line_path);
                            return getCoords(along(geometry, length(geometry) / 2));
                        }
                        return [0, 0];
                    },
                    getSize: (d) => {
                        return this.state.railwaySize*60;
                    },
                    getColor: (d) => {
                        return [235, 87, 87];
                    },
                    getPolygonOffset: ({layerIndex}) => [0, -layerIndex * 10000],
                    sizeUnits: "meters",
                    getTextAnchor: "middle",
                    getAlignmentBaseline: "center",
                    opacity: 1,
                    onClick: (d) => {
                        this.props.onClick();
                    },
                }
            ),
        ];
        const dischargeZonesItemsData = this.state.discharge_zones_items_data;
        dischargeZonesItemsData &&
        dischargeZonesItemsData.forEach((item) => layers.push(item));
        return layers;
    }
}

RailwayNetworkCompositeLayer.layerName = "RailwayNetwork";

export default RailwayNetworkCompositeLayer;
