import React from "react";
import {
    mapboxTimeActions,
    resetInitialTime,
    togglePlayerRunningStatus
} from "../../../../../reducers/map/actions/mapboxTimeActions";
import {connect} from "react-redux";
import TimePlayer from "./TimePlayer";


const TimePlayerContainer = ({
                                 mapboxTime,
                                 changeCurrentTimestamp,
                                 timePlayerVisible,
                                 startPlayerHandler,
                                 stopPlayerHandler,
                                 className = '',
                                 resetInitialTime,
                                 togglePlayerRunningStatus,
                                 ...otherProps
                             }) => {

    const handleSliderDateSelect = newValue => {
        changeCurrentTimestamp(newValue);
    };

    const handleSliderDateAfterChange = newValue => {
    };

    const handleClosePlayer = () => {
        resetInitialTime();
        togglePlayerRunningStatus(false);
    }


    return (
        <>
            {timePlayerVisible && (
                <TimePlayer
                    className={`${className}`} {...otherProps}
                    mapboxTime={mapboxTime}
                    handleClosePlayer={handleClosePlayer}
                    handleSliderDateSelect={handleSliderDateSelect}
                    handleSliderDateAfterChange={handleSliderDateAfterChange}
                    startPlayerHandler={startPlayerHandler}
                    stopPlayerHandler={stopPlayerHandler}
                />
            )}
        </>
    );
};

TimePlayerContainer.propTypes = {};

const mapStateToProps = state => {
    return {
        timePlayerVisible: state.mapPanels.timePlayerVisible,
        mapboxTime: state.mapboxTime,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        changeCurrentTimestamp: currentTimestamp =>
            dispatch(mapboxTimeActions.setCurrentTimeOnTimePlayer(currentTimestamp)),
        startPlayerHandler: () => dispatch(mapboxTimeActions.startPLayerSaga()),
        stopPlayerHandler: () => dispatch(mapboxTimeActions.stopPlayerSaga()),
        resetInitialTime: () => dispatch(resetInitialTime()),
        togglePlayerRunningStatus: (bool) => dispatch(togglePlayerRunningStatus(bool))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(TimePlayerContainer);
