import {LayerPrototype} from "../description/prototype/LayerPrototype";
import {layerNamesEnum} from "../../../../enums/layerNamesEnum";
import {relatedLayerData} from "../description/relatedData";
import {layerCards} from "../description/cards";
import {layerSchemas} from "../description/layerSchemaPresets";
import {layerDataAccessors} from "../description/dataAccessors";

export class CargoTrackingPointsLayer extends LayerPrototype{

    constructor() {
        super({
            name:  layerNamesEnum.cargoTrackingPoints,
            dataAccessor : new layerDataAccessors.nipigazLayerDataAccessors.CargoLocationPointsDataAccessor(),
            relatedData: [
                new relatedLayerData.nipigazRelatedDataForLayers.CargoCityPoints(),
            ],
            card: new layerCards.nipigazLayerCards.TrackingLocationPointCard(),
            customSettings: [],
            layerSchema : [
                new layerSchemas.nipigazLayersSchema.CargoTrackingPointsLayerSchema(),
            ],
        });
    }
}
