import { RoadBlocks } from "./RoadBlocks";
import { Roads } from "./Roads";
import { RoadSecurityCheckpoints } from "./RoadSecurityCheckpoint";
import { RoadOverpass } from "./RoadOverpass";
import { DockSegment } from "./DockSegment";
import { ControlRoom } from "./ControlRoom";
import { RoadWorks } from "./RoadWorks";
import { Segments } from "./Segments";
import { RoadSigns } from "./RoadSigns";
import { Vehicle } from "./Vehicle";
import { CargoTransferParts } from "./CargoTransferParts";
import { Railways } from "./Railways";
import { Trains } from "./Trains";
import { Waggons } from "./Waggons";
import { DischargeZones } from "./DischargeZones";
import { CargoCityPoints } from "./CargoCityPoints";
import { ReceptionAreas } from "./ReceptionAreas";
import { TrafficSegments } from "./TrafficSegments";
import { RoadsCategories } from "./RoadsCategories";
import { Signs } from "./Signs";

export const nipigazRelatedDataForLayers = {
  RoadBlocks,
  Roads,
  RoadSecurityCheckpoints,
  RoadOverpass,
  DockSegment,
  ControlRoom,
  RoadWorks,
  Segments,
  RoadSigns,
  Vehicle,
  CargoTransferParts,
  Railways,
  Trains,
  TrafficSegments,
  Waggons,
  DischargeZones,
  CargoCityPoints,
  ReceptionAreas,
  RoadsCategories,
  Signs,
};
