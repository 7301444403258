import React from 'react'
import {ArrowIcon} from "../../../library/icons/actionIcons/ArrowIcon";
import {Styled} from "./SupportPage.styled"
import {useTranslation} from "react-i18next";

const {
    LocalMainWrapper,
    LocalMainTitle,
    LocalMainDescription,
    LocalTopDescription,
    LocalTopDescriptionPhone,
    LocalDownDescription,
    LocalTopDescriptionEmail,
    LocalButtonArrow,
} = Styled

const SupportPage = ({onClick}) => {
    const {t, i18n} = useTranslation('common');
    return (
        <LocalMainWrapper>
            <LocalMainTitle>{t('guest.page.supportServiceTitle')}</LocalMainTitle>
            <LocalMainDescription>
                <LocalTopDescription>
                    {t('guest.page.supportPhone')}:
                    <LocalTopDescriptionPhone href="tel:99-99">99-99</LocalTopDescriptionPhone>
                </LocalTopDescription>
                <LocalDownDescription>
                    E-mail:
                    <LocalTopDescriptionEmail href="mailto:hello@gmail.com">hello@gmail.com</LocalTopDescriptionEmail>
                </LocalDownDescription>
            </LocalMainDescription>
            <LocalButtonArrow
                icon={<ArrowIcon/>}
                p={'none'}
                type={'unboundedPrimary'}
                onClick={onClick}
                iconSize={{width: '18px', height: '22px'}}
                iconMargin={1.1}
                isBold={true}

            >{t('guest.page.supportReturn')}
            </LocalButtonArrow>
        </LocalMainWrapper>
    )

}

export default SupportPage
