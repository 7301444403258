import {LayerPrototype} from "../description/prototype/LayerPrototype";
import {layerNamesEnum} from "../../../../enums/layerNamesEnum";
import {mainDataForLayers} from "../description/mainData";
import {coreLayerDataAccessors as layerDataAccessors} from "../description/dataAccessors/core";
import {relatedLayerData} from "../description/relatedData";
import {layerCards} from "../description/cards";
import {layerSettings} from "../description/settings";
import {layerSchemas} from "../description/layerSchemaPresets";

export class TrackingLayer extends LayerPrototype{

    constructor() {
        super({
            name:  layerNamesEnum.tracking,
            mainData : new mainDataForLayers.coreMainDataForLayers.VehicleTrackingMainData(),
            dataAccessor : new layerDataAccessors.TrackingDataAccessor(),
            relatedData: [
                new relatedLayerData.coreRelatedDataForLayers.Vehicle(),
            ],
            card: new layerCards.coreLayerCards.TrackingLayerCard(),
            customSettings: [new layerSettings.OpacitySetting()],
            layerSchema : [
                new layerSchemas.coreLayersSchema.TrackingLayerSchema(),
            ],
        });
    }
}
