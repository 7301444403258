import React from 'react';
import PropTypes from 'prop-types';
import LayersMenuContent from "./LayersMenuContent";
import {connect} from 'react-redux'

LayersMenuContentContainer.propTypes = {

};

function LayersMenuContentContainer({displayedContent}) {
    return (
        <>
            <LayersMenuContent displayedContent={displayedContent}/>
        </>
    );
}

const mapStateToProps = (state) => {
    return {
        displayedContent: state.mapPanels.selectedMainContext
    }
}

export default connect(mapStateToProps,null)(LayersMenuContentContainer);
