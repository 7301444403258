import _, {findIndex} from "underscore";
import {createSelector} from "reselect";

export const SELECT_OBJECT = "SELECT_OBJECT";
export const CLEAR_SELECTION = "CLEAR_SELECTION";

const initialState = null;

const selectedObject = (state = initialState, action) => {
    switch (action.type) {
        case SELECT_OBJECT:
            return action.payload
        case CLEAR_SELECTION:{
            return initialState
        }
        default:
            return state;
    }
};

const getSelectedObject = (state) => {
    return state.selectedObject
}

export const getLastSelectedObject = createSelector(
    [getSelectedObject],
    (lastSelectedObject) => {
        return lastSelectedObject
    }
);

export default selectedObject
