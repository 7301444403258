import React, { useContext } from "react";
import { Styled } from "./MapToolBar.styled";
import PrimaryGroupContainer from "./Groups/PrimaryGroupContainer";
import SecondaryGroupContainer from "./Groups/SecondaryGroupContainer";
import ConditionalItemsGroupContainer from "./Groups/ConditionalItemsGroupContainer";
import { MapModuleContext } from "../moduleContext/withMapModuleContext";

const { LocalMainWrapper, LocalItemsGroup } = Styled;

const MapToolBar = props => {
  const { handlePrint, showTimePlayer } = props;
  const { iconSizesKit } = useContext(MapModuleContext);

  return (
    <LocalMainWrapper className={props.className}>
      {/*  print size other*/}
      <LocalItemsGroup>
        <PrimaryGroupContainer
          iconSize={iconSizesKit.default}
          handlePrint={handlePrint}
        />
      </LocalItemsGroup>
      {/*  zoom 3d*/}
      <LocalItemsGroup>
        <SecondaryGroupContainer iconSize={iconSizesKit.default} iconSizeLarge={iconSizesKit.largest} />
      </LocalItemsGroup>
      {/*  legend timeplayer*/}
      <LocalItemsGroup>
        {" "}
        <ConditionalItemsGroupContainer showTimePlayer={showTimePlayer} iconSize={iconSizesKit.default} iconSizeLarge={iconSizesKit.largest} />
      </LocalItemsGroup>
    </LocalMainWrapper>
  );
};

export default MapToolBar;
