import { prepareMenuContextItem } from "./prepareMenuContextItem";
import CustomTooltipGroup from "../../../../../library/UI/components/CustomTooltip/Group/CustomTooltipGroup";
import React from "react";

export function contextMenuGroupsFactory({
  groupsList,
  certainCollection,
  translateFn,
  dispatchFn
}) {
  return groupsList.map(group => {
    const currentGroupItems = certainCollection.filter(
      el => el.group === group.type
    );

    if (currentGroupItems.length) {
      const preparedGroupItems = currentGroupItems.map(el =>
        prepareMenuContextItem({
          el,
          translateFn,
          dispatchFn,
          action: el.dispatchedAction
        })
      );
      return (
        <CustomTooltipGroup title={group.title}>
          {preparedGroupItems}
        </CustomTooltipGroup>
      );
    }
  });
}
