import {defaultObjectDescription, editableObjectDescription} from "./editableObjectsRegistrator";

export const resolver = (searchValue, resolvedField = null, searchField = 'name', useDefaultIfMissing = false) => {
    if (!searchValue) throw new Error(`${searchValue} is empty for field ${resolvedField}`)
    let result = editableObjectDescription.filter(el => searchValue.includes(el[searchField]));
    const exact = result.filter(el => el[searchField].toLowerCase() === searchValue);
    if (exact && exact.length) result = exact;
    if (resolvedField && result && result[0] && result[0][resolvedField]) {
        return result[0][resolvedField]
    } else if (resolvedField) {
        if (useDefaultIfMissing && defaultObjectDescription[resolvedField]) {
            return defaultObjectDescription[resolvedField]
        } else return null
    } else if (!resolvedField) return result[0];
}
