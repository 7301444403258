import React from "react";
import {Icon} from "antd";

const PlusIconSVG = () => (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M16.6667 10.9523H10.9524V16.6666H9.04766V10.9523H3.33337V9.04754H9.04766V3.33325H10.9524V9.04754H16.6667V10.9523Z" fill="currentColor"/>
    </svg>

);

export const PlusIcon = props => <Icon component={PlusIconSVG} {...props} />;