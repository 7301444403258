import React from "react";
import { dataAccessMethodsEnum } from "../../../../../../enums/dataAccessMethodsEnum";
import { basePath } from "../../../layersRegistrator";

const LayersDataAccessorContainer = React.lazy(() =>
  import(
    "../../../../../../components/Application/Map/MapDisplayMode/LayersDataAccessors/LayersDataAccessorContainer"
  )
);

export class LayerDataAccessorPrototype {
  basePathTemplate = basePath;
  i18LabelPath;
  urlData;
  dataAccessMethod;
  dataAccessComponent;
  dataKeyForIds;
  selectedType;
  isSelectedEditable;
  actionButtons;

  constructor({
    urlData,
    dataAccessMethod = dataAccessMethodsEnum.httpGetAll,
    dataAccessComponent = LayersDataAccessorContainer,
    selectedChild = null,
    dataKeyForIds = "id",
    i18LabelPath,
    selectedType,
    isSelectedEditable = false,
    ...other
  }) {
    this.selectedType = selectedType;
    this.urlData = this.basePathTemplate + urlData;
    this.dataAccessMethod = dataAccessMethod;
    this.dataAccessComponent = dataAccessComponent;
    this.selectedChild = selectedChild;
    this.dataKeyForIds = dataKeyForIds;
    this.additionalProps = other;
    this.i18LabelPath = i18LabelPath;
    this.isSelectedEditable = isSelectedEditable;
  }

  getIfSelectedEditable() {
    return this.isSelectedEditable;
  }

  getDataKeyForIds() {
    return this.dataKeyForIds;
  }

  getSelectedObjectType() {
    return this.selectedType;
  }

  getDataAccessorI18nLabelPath() {
    return this.i18LabelPath;
  }

  getAdditionalProps() {
    return this.additionalProps;
  }

  getDataAccessor() {
    return this;
  }

  getDataAccessorUrlData() {
    return this.urlData;
  }

  getDataAccessorDataGettingMethod() {
    return this.dataAccessMethod;
  }

  getDataAccessorRepresentationComponent() {
    return this.dataAccessComponent;
  }

  getSelectedChildType() {
    return this.selectedChild;
  }
  getActionButtons(){
    return this.actionButtons;
  }

}
