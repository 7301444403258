import _ from 'underscore';
export const START_FETCHING = "START_FETCHING";
export const END_FETCHING = "END_FETCHING";
export const LANGUAGE_CHANGED = "LANGUAGE_CHANGED";
export const START_REDIRECT = "START_REDIRECT";
export const CONFIRM_REDIRECT = "CONFIRM_REDIRECT";
export const RECALCULATE = "RECALCULATE";

const initialState = {
  fetching: false,
  redirectOrder: null,
  recalculated: 'none',
  language: localStorage.getItem("locale")
    ? localStorage.getItem("locale")
    : "en"
};

export default function app(state = initialState, action) {
  switch (action.type) {
    case LANGUAGE_CHANGED:
      return { ...state, language: action.value };
    case START_FETCHING:
      return { ...state, fetching: true };
    case END_FETCHING:
      return { ...state, fetching: false };
    case START_REDIRECT:
      return { ...state, redirectOrder: action.url + action.created };
    case CONFIRM_REDIRECT:
      return { ...state, redirectOrder: null };
      case RECALCULATE:
      return { ...state, recalculated: _.uniqueId()}
    default:
      return state;
  }
}
