import React, {useEffect} from "react";
import { Button } from "../../../../../library/UI/components/Button/Button";
import ButtonGroup from "../../../../../library/UI/components/ButtonGroup/ButtonGroup";
import { PlusIcon } from "../../../../../library/icons/actionIcons/PlusIcon";
import { MinusIcon } from "../../../../../library/icons/actionIcons/MinusIcon";
import CompassButtonContainer from "./CompassButton/CompassButtonContainer";
import { RulerIcon } from "../../../../../library/icons/objectIcons/RulerIcon";
import { MeasureDistanceMode } from "@nebula.gl/edit-modes";
import { MapIcon } from "../../../../../library/icons/objectIcons/MapIcon";
import _ from "underscore";

const SecondaryGroup = ({
  handleZoomIn,
  toggleRuler,
  handleZoomOut,
  iconSize,
  toggleRuller,
  iconSizeLarge,
                            rulerMode
}) => {

    useEffect(()=>{

        let onEsc = (e)=>{
            if (e.keyCode == 27 && rulerMode) {
                toggleRuler(MeasureDistanceMode)
            }}
        document.addEventListener("keyup", onEsc, {once:true});

    },[rulerMode,])

  return (
    <>
      <ButtonGroup vertical>
        <Button
          hasShadow
          type={"primaryReverse"}
          onClick={handleZoomIn}
          icon={<PlusIcon key={_.uniqueId()} />}
          iconSize={iconSize}
          key={_.uniqueId()}
        />
        <Button
          hasShadow
          type={"primaryReverse"}
          onClick={handleZoomOut}
          icon={<MinusIcon key={_.uniqueId()} />}
          iconSize={iconSize}
          key={_.uniqueId()}
        />
      </ButtonGroup>
      <CompassButtonContainer />
      <Button
        hasShadow
        rounded
        type={rulerMode
            ?"reverse"
            :"primaryReverse"}
        onClick={() => toggleRuler(MeasureDistanceMode)}
        icon={<RulerIcon key={_.uniqueId()} />}
        iconSize={iconSize}
        key={_.uniqueId()}
      />
   {/*   <Button
        hasShadow
        rounded
        type={"primaryReverse"}
        icon={<MapIcon key={_.uniqueId()} />}
        iconSize={iconSizeLarge}
        disabled
        p={0.5}
        key={_.uniqueId()}
      />*/}
    </>
  );
};

export default SecondaryGroup;
