import React, { useEffect, useState } from "react";
import { SketchPicker } from 'react-color'
import { Form } from "antd";
import { useFormContext, Controller, ErrorMessage } from "react-hook-form";
import {useBaseInputSettings} from "./utils/hooks/useBaseInputSettings";

const arrayColors = ['#EB5757', '#F2994A', '#F2C94C', '#219653', '#27AE60','#6FCF97','#2F80ED','#2D9CDB','#56CCF2','#9B51E0', '#BB6BD9']
const convertRGBAObjectToString = (rgbaObj) => {
    // const {r,g,b,a} = rgbaObj;
    return `rgba(${rgbaObj?.r},${rgbaObj?.g},${rgbaObj?.b},${rgbaObj?.a})`
}

const convertSTRObjectToRGBA = (str) => {
    const prep = str.replace('rgba(','').replace(')','')
    const [r,g,b,a] = prep;
    return {
        r,g,b,a
    }
}


const ColorPickerInput = props => {
    const { control, errors, setValue } = useFormContext();
    const [placeholderState, setPlaceholderState] = useState(false);
    const [initialValue, setInitialValue] = useState(undefined);
    const [result, setResult] = useState([]);
    const [temp, setTemp] = useState({color: '#333'});
    const { description, object, field, formProps } = props;
    const {required, message} = useBaseInputSettings(description)
    const [col, setCol] = useState({color: '#3331'})


    const handleColorChange = (value) => {
        const {rgba} = value
        setTemp({color: value.hex})
        setValue(convertRGBAObjectToString(rgba))
    }

    function checkInputAndMakeAction(inputValue, actionFn) {
        if (!inputValue) {
            actionFn();
        }
    }
    if (object && object[field] && initialValue !== object[field]) {
        setInitialValue(object[field]);
    }

    useEffect(() => {
        if (initialValue) {
            setValue(field, initialValue);
            setPlaceholderState(true);
        }
    }, [initialValue]);

    return (
        <Form.Item key={"form_" + field} valuePropName="text">
            <Controller
                name={field}
                as={<SketchPicker onChangeComplete={handleColorChange} color={temp.color} presetColors={arrayColors} />}
                control={control}
                rules={{
                    required: { value: required, message: message },
                    validate: value => {
                        const rgbaString = convertRGBAObjectToString(value.rgb)
                        setValue(field,rgbaString,false)
                        return true
                    }
                }}
            />
            <ErrorMessage errors={errors} name={field} />

        </Form.Item>
    );
};

export default ColorPickerInput;
