import {RelatedDataPrototype} from "../prototype/RelatedDataPrototype";
import {mapObjectsEnum} from "../../../../../../enums/mapObjectsEnum";
import {dataAccessMethodsEnum} from "../../../../../../enums/dataAccessMethodsEnum";

export class RoadWorks extends RelatedDataPrototype {

    constructor() {
        super({
            name: 'road_works',
            filterLabel: 'Дорожные работы',
            urlData: '/layer_roads/roadworks-segments/',
            selectedObjectName: mapObjectsEnum.roadWorks,
            // dataAccessMethod: dataAccessMethodsEnum.custom // нужно было для проброса даты в запрос
        });
    }
}
