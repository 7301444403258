import React from "react";
import { Route, withRouter, Switch } from "react-router-dom";
import DirectoryFolderContainer from "./DirectoryFolder/DirectoryFolderContainer";
import { useWhyDidYouUpdate } from "react-recipes";
import _ from "underscore";
import DirectoryBuilder from "./DirectoryBuilder/DirectoryBuilder";

const propsComparator = (prevProps, nextProps) => {
  return _.isEqual(prevProps.match, nextProps.match);
};

const DirectoryRouter = (props) => {
  useWhyDidYouUpdate("DirectoryRouter", props);

  return (
    <Switch>
      <Route
        exact
        path={"/app/directory/:folder/:id/"}
        component={({ match }) => (
          <DirectoryBuilder folder={match.params.folder} id={match.params.id} />
        )}
      />
      <Route
        exact
        path={"/app/directory/:folder/"}
        component={({ match }) => (
          <DirectoryBuilder folder={match.params.folder} id={null} />
        )}
      />
      <Route
        exact
        path={"/app/directory/"}
        component={DirectoryFolderContainer}
      />
    </Switch>
  );
};

export default withRouter(DirectoryRouter);
