import styled from "styled-components";

const LocalMainWrapper = styled.section`
  .ReactCollapse--collapse {
    transition: height
      ${(props) =>
        props.transitionDurationInMs
          ? `${props.transitionDurationInMs}ms`
          : "300ms"};
  }
`;

const LocalTitleItem = styled.div`
  transition: all 0.3s;
  display: flex;
  &:nth-of-type(2) {
    margin-left: ${(props) => props.arrowIconOffset ?? "0"};
    transform: ${(props) =>
      props.isOpened ? "rotate(0)" : `rotate(-${props.rotateDegree}deg)`};
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }
`;

const LocalTitleGroup = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

export const Styled = { LocalMainWrapper, LocalTitleGroup, LocalTitleItem };
