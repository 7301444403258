import {axiosInstance} from "../../../services/net/axiosGuest";
import {APPLY_PERMISSIONS, LOGIN_USER, LOGOUT_USER} from "../../../constants/constants";
import {errorNetworkMessageCreator} from "../../../services/application/alertMessageCreators/networkErrorMessage";
import {setGlobalAlertData} from "./notificationActions";
import {alertTypesEnum} from "../../../enums/alertTypesEnum";
import {alertStatusesEnum} from "../../../enums/alertStatusesEnum";

export const userPostFetch = user => {
    return dispatch => {
        return axiosInstance.post("http://localhost:3000/api/v1/users", {
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
            },
            body: JSON.stringify({user})
        })
            .then(response => response.data)
            .then(data => {
                if (data.message) {
                } else {
                    localStorage.setItem("token", data.jwt)
                    dispatch(loginUser(data.user))
                }
            }).catch(e =>
                dispatch(setGlobalAlertData({status: alertStatusesEnum.active, type: alertTypesEnum.error, title: 'Ошибка', message: errorNetworkMessageCreator(e)}))
            )
    }
}

const loginUser = userObj => ({
    type: LOGIN_USER,
    payload: userObj
})

const applyPermissions = permissions => ({
    type: APPLY_PERMISSIONS,
    payload: permissions
})

export const userLogoutFetch = () => dispatch => {
    localStorage.removeItem("token_access")
    localStorage.removeItem("token_refresh")
    localStorage.removeItem("user")
    dispatch({
        type: LOGOUT_USER
    })
}

export const userLoginFetch = user => {
    return dispatch => {
        return axiosInstance.post("/users/token/", {
                "username": user.username,
                "password": user.password,
            },
            {
                headers: {
                    'Content-Type': 'application/json',
                    Accept: 'application/json',
                }
            })
            .then(resp => resp.data)
            .then(data => {
                if (data.message) {

                } else {
                    localStorage.setItem("token_access", data.access)
                    localStorage.setItem("token_refresh", data.refresh)
                    localStorage.setItem("user", user.username)
                    dispatch(loginUser(user.username))
                    dispatch(applyPermissions(user.permissions))
                    if(sessionStorage.getItem('redirect_to')){
                        window.location = sessionStorage.getItem('redirect_to')
                        sessionStorage.removeItem('redirect_to')
                    }
                }
            }).catch(e => {
                dispatch(setGlobalAlertData({status: alertStatusesEnum.active, type: alertTypesEnum.error, title: 'Ошибка', message: errorNetworkMessageCreator(e)}))
            })
    }
}

export const userPasswordRecovery = user => {

    return dispatch => {
        return axiosInstance.post("/users/password/reset/confirm/", {
                "uid": user.uid,
                "token": user.token,
                "new_password1": user.password,
                "new_password2": user.passwordConfirm
            },
            {
                headers: {
                    'Content-Type': 'application/json',
                    Accept: 'application/json',
                },

            })
            .then(resp => resp.data)
            .then(data => {
                if (data.detail) {

                } else {
                    localStorage.setItem("token", data.jwt)
                    dispatch(loginUser(data.user))
                }
            }).catch(e => {
                dispatch(setGlobalAlertData({status: alertStatusesEnum.active, type: alertTypesEnum.error, title: 'Ошибка', message: errorNetworkMessageCreator(e)}))
            })
    }
}
