import styled from "styled-components";

const LocalFooterWrap = styled.div`
  display: flex;
  flex-direction: column;
`;

const LocalFooterInner = styled.div`
  display: flex;
  margin: 0 -3px;
  & > * {
    margin: 0 3px;
  }
`;

const LocalHeaderWrap = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex: 1;
  width: 100%;
`

const LocalHeaderContentGroup = styled.article`
     display: flex;
    margin: 0 -3px;
    & > * {
      margin: 0 3px;
    }
`

const LocalHeaderContentItem = styled.div``

export const Styled = {
    Header: {
        LocalHeaderWrap,
        LocalHeaderContentGroup,
        LocalHeaderContentItem
    },
    Footer: {
        LocalFooterWrap,
        LocalFooterInner
    },
    Content: {

    }
}