import React from "react";
import {Button} from "../../../../../library/UI/components/Button/Button";
import {ExpandArrowIcon} from "../../../../../library/icons/actionIcons/ExpandArrowsIcon";
import {PrintIcon} from "../../../../../library/icons/actionIcons/PrintIcon";
import {DotsIcon} from "../../../../../library/icons/actionIcons/DotsIcon";
import {EarthOffIcon} from "../../../../../library/icons/actionIcons/EarthOffIcon";
import {EarthOnIcon} from "../../../../../library/icons/actionIcons/EarthOnIcon";
import _ from "underscore"

const PrimaryGroup = ({handlePrint, iconSize,toggleRasterTileEnable, rasterTileEnabled}) => {
    return (
        <>
        {/*    <Button
                rounded
                type={"primaryReverse"}
                disabled
                icon={<ExpandArrowIcon key={_.uniqueId()} />}
                hasShadow
                iconSize={iconSize}
                key={_.uniqueId()}
            />*/}
            <Button
                rounded
                type={"primaryReverse"}
                onClick={handlePrint}
                icon={<PrintIcon key={_.uniqueId()} />}
                hasShadow
                iconSize={iconSize}
                key={_.uniqueId()}
            />
            {rasterTileEnabled && <Button
                rounded
                type={"primaryReverse"}
                onClick={toggleRasterTileEnable}
                icon={<EarthOffIcon key={_.uniqueId()} />}
                hasShadow
                iconSize={iconSize}
                key={_.uniqueId()}
            />}
            {!rasterTileEnabled && <Button
                rounded
                type={"primaryReverse"}
                onClick={toggleRasterTileEnable}
                icon={<EarthOnIcon key={_.uniqueId()} />}
                hasShadow
                iconSize={iconSize}
                key={_.uniqueId()}
            />}
          {/*  <Button rounded type={"primaryReverse"} iconSize={iconSize} hasShadow disabled icon={<DotsIcon key={_.uniqueId()} />} key={_.uniqueId()} />*/}
        </>
    );
};

export default PrimaryGroup
