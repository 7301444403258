import React from "react";
import { connect } from "react-redux";
import MapDisplayMode from "./MapDisplayMode";
import { useFnsCallingOnMountAndUnmount } from "../../../../customHooks/common/useFnsCallingOnMountAndUnmount";
import {
  revertToDefaultMapMode,
  setCurrentMapMode
} from "../../../../reducers/map/actions/mapModeActions";

const MapDisplayModeContainer = ({
  dataAccessPanelVisible,
  layerSettingVisible,
  timePlayerVisible,
  mapSelectedObjectInfoVisible,
  mainPanelCollapsed,
  currentMode,
  setCurrentMapMode,
  revertToDefaultMapMode
}) => {
  useFnsCallingOnMountAndUnmount(
    () => setCurrentMapMode("display"),
    () => revertToDefaultMapMode()
  );
  return (
    <MapDisplayMode
      dataAccessPanelVisible={dataAccessPanelVisible}
      layerSettingVisible={layerSettingVisible}
      timePlayerVisible={timePlayerVisible}
      mapSelectedObjectInfoVisible={mapSelectedObjectInfoVisible}
      mainPanelCollapsed={mainPanelCollapsed}
      currentMode={currentMode}
    />
  );
};

MapDisplayModeContainer.propTypes = {};

const mapStateToProps = state => {
  return {
    dataAccessPanelVisible: state.mapPanels.dataAccessorVisible,
    layerSettingVisible: state.mapPanels.layerSettingVisible,
    timePlayerVisible: state.mapPanels.timePlayerVisible,
    mapSelectedObjectInfoVisible: state.mapPanels.mapSelectedObjectInfoVisible,
    mainPanelCollapsed: state.mapPanels.mainPanelCollapsed,
    currentMode: state.mapMode.currentMode
  };
};

const mapDispatchToProps = dispatch => {
  return {
    setCurrentMapMode: modeType => dispatch(setCurrentMapMode(modeType)),
    revertToDefaultMapMode: () => dispatch(revertToDefaultMapMode())
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MapDisplayModeContainer);
