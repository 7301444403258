import React from 'react';
import PropTypes from 'prop-types';

const Page404 = () => {
    return (
        <div>
            <p>Данные отсутсвуют</p>
        </div>
    );
};

Page404.propTypes = {

};

export default Page404;
