import React, { useEffect, useState } from "react";
import { Styled } from "./DataModelTS.styled";
import RepresentationCheckedItem from "../../../checkedWidget/RepresentationCheckedItem";
import SelectableItem from "../../../../../../../../../library/UI/components/SelectableItem/SelectableItem";
import useGlobal from "../../../../store";
import { getFromURL } from "../../../../../../../../../api/getFromURL";
import {
  getBelongGRZForCar,
  getFilteredDataItems,
} from "../../../../utils/FilteredFunctions";

const {
  LocalMainWrapper,
  LocalSelectWrapper,
  LocalScrollableMainWrapper,
  LocalScrollableInnerWrapper,
  LocalTopWrapper,
} = Styled;

const DataModelTSContainer = ({ updateDate }) => {
  const [globalState, globalActions] = useGlobal();
  const [itemTS, setDataItemTS] = useState([]);
  const [allCars, setAllCars] = useState({
    cars: [],
  });

  const { filteredDataActions } = globalState;

  useEffect(() => {
    if (filteredDataActions) {
      const car = filteredDataActions
        ?.filter((value) => value.isChecked === false)
        .map((value) => value.value);

      const nameSorted = car?.filter((elem, pos) => {
        return car.indexOf(elem) === pos;
      });

      const model = nameSorted?.map((el) => {
        return {
          value: el,
          isChecked: false,
        };
      });

      setDataItemTS(model);
    }
  }, [filteredDataActions]);

  const handleAllChecked = (event) => {
    let selects = itemTS;
    selects.map((selects) => (selects.isChecked = event.target.checked));
    setDataItemTS([...selects]);

    if (event.target.checked) {
      setAllCars({
        cars: allCars?.cars.concat(filteredDataActions),
      });
    } else {
      setAllCars({
        cars: allCars?.cars.filter((val) => val.value !== event.target.value),
      });
    }
  };

  const handleCheckElement = (event) => {
    const value = event.target.value;
    const sortedCarWithGRZ = getBelongGRZForCar(value, filteredDataActions);

    let selects = itemTS;
    selects.forEach((selects) => {
      if (selects.value === event.target.value)
        selects.isChecked = event.target.checked;
    });
    setDataItemTS([...selects]);

    if (event.target.checked) {
      setAllCars({
        cars: allCars?.cars.concat(sortedCarWithGRZ),
      });
    } else {
      setAllCars({
        cars: allCars?.cars.filter((val) => val.value !== event.target.value),
      });
    }
  };
  updateDate(allCars);

  const handleRemoveItems = () => {};

  const items = itemTS?.map((item, index) => {
    return (
      <LocalSelectWrapper key={index} isChecked={item.isChecked}>
        <SelectableItem
          handleRemoveItems={handleRemoveItems}
          isStatusBarLine={false}
          isChecked={item.isChecked}
          onSelectChange={handleCheckElement}
          isSelected={item.isChecked}
          value={item.value}
          onMoved
        >
          {item.value}
        </SelectableItem>
      </LocalSelectWrapper>
    );
  });

  return (
    <RepresentationCheckedItem
      items={items}
      handleAllChecked={handleAllChecked}
    />
  );
};

export default DataModelTSContainer;
