// export const transportIconMapper = {
//     bus: {x: 1, y: 1, width: 56, height: 56},
//     car: {x: 59, y: 1, width: 56, height: 56},
//     dumptrack: {x: 117, y: 1, width: 56, height: 56},
//     towtruck: {x: 175, y: 1, width: 56, height: 56},
//     truck: {x: 233, y: 1, width: 56, height: 56},
//     Автобус: {x: 117, y: 1, width: 56, height: 56},
//     Легковой: {x: 176, y: 1, width: 56, height: 56},
//     Автобус_disabled: {x: 1, y: 1, width: 56, height: 56},
//     Легковой_disabled: {x: 59, y: 1, width: 56, height: 56},
// };


export const transportIconMapper = {
    "towtruck_selected":{
        "x": 0,
        "y": 0,
        "width": 192,
        "height": 192
    },
    "Эвакуатор_selected":{
        "x": 0,
        "y": 0,
        "width": 192,
        "height": 192
    },
    "truck_selected":{
        "x": 0,
        "y": 193,
        "width": 192,
        "height": 192
    },
    "Грузовой_selected":{
        "x": 0,
        "y": 193,
        "width": 192,
        "height": 192
    },
    "bus":{
        "x": 193,
        "y": 0,
        "width": 192,
        "height": 192
    },
    "Автобус":{
        "x": 193,
        "y": 0,
        "width": 192,
        "height": 192
    },
    "car":{
        "x": 193,
        "y": 193,
        "width": 192,
        "height": 192
    },
    "Легковой":{
        "x": 193,
        "y": 193,
        "width": 192,
        "height": 192
    },
    "dumptruck":{
        "x": 0,
        "y": 386,
        "width": 192,
        "height": 192
    },
    "Мусоровоз":{
        "x": 0,
        "y": 386,
        "width": 192,
        "height": 192
    },
    "bus_disabled":{
        "x": 193,
        "y": 386,
        "width": 192,
        "height": 192
    },
    "Автобус_disabled":{
        "x": 193,
        "y": 386,
        "width": 192,
        "height": 192
    },
    "car_disabled":{
        "x": 386,
        "y": 0,
        "width": 192,
        "height": 192
    },
    "Легковой_disabled":{
        "x": 386,
        "y": 0,
        "width": 192,
        "height": 192
    },
    "dumptruck_disabled":{
        "x": 386,
        "y": 193,
        "width": 192,
        "height": 192
    },
    "Мусоровоз_disabled":{
        "x": 386,
        "y": 193,
        "width": 192,
        "height": 192
    },
    "towtruck_disabled":{
        "x": 386,
        "y": 386,
        "width": 192,
        "height": 192
    },
    "Эвакуатор_disabled":{
        "x": 386,
        "y": 386,
        "width": 192,
        "height": 192
    },
    "truck_disabled":{
        "x": 0,
        "y": 579,
        "width": 192,
        "height": 192
    },
    "Грузовой_disabled":{
        "x": 0,
        "y": 579,
        "width": 192,
        "height": 192
    },
    "towtruck":{
        "x": 193,
        "y": 579,
        "width": 192,
        "height": 192
    },
    "Эвакуатор":{
        "x": 193,
        "y": 579,
        "width": 192,
        "height": 192
    },
    "truck":{
        "x": 386,
        "y": 579,
        "width": 192,
        "height": 192
    },
    "Грузовой":{
        "x": 386,
        "y": 579,
        "width": 192,
        "height": 192
    },
    "bus_selected":{
        "x": 579,
        "y": 0,
        "width": 192,
        "height": 192
    },
    "Автобус_selected":{
        "x": 579,
        "y": 0,
        "width": 192,
        "height": 192
    },
    "car_selected":{
        "x": 579,
        "y": 193,
        "width": 192,
        "height": 192
    },
    "Легковой_selected":{
        "x": 579,
        "y": 193,
        "width": 192,
        "height": 192
    },
    "dumptruck_selected":{
        "x": 579,
        "y": 386,
        "width": 192,
        "height": 192
    },
    "Мусоровоз_selected":{
        "x": 579,
        "y": 386,
        "width": 192,
        "height": 192
    }
};



