import styled from "styled-components";

const LocalMainWrapper = styled.div`
  .inline-placeholder-login {
    user-select: none;
    pointer-events: none;
    z-index: 1;
    font-size: 16px;
    color: #bfbfbf;
    position: absolute;
    top: 20px;
    left: 10px;
    transition: all 0.2s;
  }

  .inline-placeholder-login.close.focused {
    position: absolute;
    transform: translate3d(0, -6px, 0);
    font-size: 13px;
    color: ${(props) => props.theme.colors.primary};
  }
  
    .inline-placeholder-login.close {
    color: ${props => props.theme.colors.neutralDarker};
  }

  // .inline-placeholder-login.close {
  //   position: absolute;
  //   transform: translate3d(0, -6px, 0);
  //   font-size: 13px;
  //   color: ${(props) => props.theme.colors.primary};
  // }

  .inline-placeholder-login.open {
    position: absolute;
    transform: translate3d(0, -6px, 0);
    color: ${(props) => props.theme.colors.primary};
    font-size: 13px;
  }
  & button {
    margin: 0 20px 38px;
    background: #9acad1;
    border-radius: 8px;
    width: 314px;
    height: 60px;
    color: white;
    font-size: 14px;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    border: none;
  }

  & a {
    font-family: AvantGardeGothicC;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    color: ${(props) => props.theme.colors.primary};
  }
  // Todo refactoring style
`;

const LocalInputWrapper = styled.div`
  position: relative;
  margin: 0 auto 20px;
  background: ${(props) => props.theme.colors.defaultLighter};
  border-radius: ${(props) => props.theme.decoration.defaultBorderRadius};
  & input:hover {
    border-radius: ${(props) =>
      props.theme.decoration.defaultBorderRadius} !important;
    border: 2px solid ${(props) => props.theme.colors.primaryLighter} !important;
  }

  & .ant-input {
    background: ${(props) => props.theme.colors.defaultLight};
    height: 60px;
    border-radius: 8px;
    padding-top: 25px;
  }
`;

export const Styled = { LocalInputWrapper, LocalMainWrapper };
