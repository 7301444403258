import React from "react";
import styled from "styled-components";
import {useWhyDidYouUpdate} from "react-recipes";
import DirectoryCards from "./DirectoryCardsFolder/DirectoryCards";

const StyledDirectoryFolder = styled.div``

const DirectoryFolder = (props) => {

    //dev
    useWhyDidYouUpdate('DirectoryFolder',props)

    return (
        <StyledDirectoryFolder>
            <DirectoryCards />
        </StyledDirectoryFolder>
    )

}

export default DirectoryFolder;
