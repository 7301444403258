import {LayerSettingsPrototype} from "../LayerSettingsPrototype";

export class BooleanLayerSetting extends LayerSettingsPrototype{

    type=Boolean;
    currentValue;

    constructor({currentValue=true,label,keyForDeck}) {
        super({label,keyForDeck});
    }

    getSettingType(){
        return this.type
    }

    getInitialValue(){
        return this.currentValue
    }

}
