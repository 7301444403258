import React from "react";
import { Icon } from "antd";

const ArrowIconSVG = () => (
    <svg width="10" height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M8.4425 0.442498L5 3.8775L1.5575 0.442499L0.500003 1.5L5 6L9.5 1.5L8.4425 0.442498Z" fill="currentColor"/>
    </svg>
);

export const ArrowIcon = (props) => <Icon component={ArrowIconSVG} {...props} />;
