
import {LayerSchemaPrototype} from "../prototype/LayerSchemaPrototype";
import MonitoringCompositeLayer from "../../../../../../library/layers/CompositeLayers/Core/MonitoringCompositeLayer";
import GeozonesCompositeLayer from "../../../../../../library/layers/CompositeLayers/NIPIGAZ/GeozonesCompositeLayer";

export class GeozonesLayerSchema extends LayerSchemaPrototype{
    constructor() {
        super({
            layerToRender: GeozonesCompositeLayer,
            id: 'geozones-layer',
        });
    }
}
