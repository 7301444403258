export const signsMapperType5 = {
    "5.1": {
        "x": 5508,
        "y": 2052,
        "width": 900,
        "height": 1350
    },
    "5.2": {
        "x": 1402,
        "y": 0,
        "width": 900,
        "height": 1350
    },
    "5.3": {
        "x": 5508,
        "y": 0,
        "width": 900,
        "height": 1350
    },
    "5.4": {
        "x": 0,
        "y": 2454,
        "width": 900,
        "height": 1350
    },
    "5.5": {
        "x": 3505,
        "y": 3805,
        "width": 700,
        "height": 700
    },
    "5.6": {
        "x": 2804,
        "y": 3805,
        "width": 700,
        "height": 700
    },
    "5.7.1": {
        "x": 0,
        "y": 5207,
        "width": 1050,
        "height": 350
    },
    "5.7.2": {
        "x": 1051,
        "y": 2103,
        "width": 1050,
        "height": 350
    },
    "5.8": {
        "x": 0,
        "y": 701,
        "width": 700,
        "height": 700
    },
    "5.9": {
        "x": 2303,
        "y": 0,
        "width": 700,
        "height": 700
    },
    "5.10": {
        "x": 4204,
        "y": 4506,
        "width": 700,
        "height": 700
    },
    "5.11.1": {
        "x": 2103,
        "y": 3805,
        "width": 700,
        "height": 700
    },
    "5.11.2": {
        "x": 3605,
        "y": 1602,
        "width": 700,
        "height": 700
    },
    "5.12.1": {
        "x": 2303,
        "y": 2454,
        "width": 700,
        "height": 700
    },
    "5.12.2": {
        "x": 5508,
        "y": 1351,
        "width": 700,
        "height": 700
    },
    "5.13.1": {
        "x": 701,
        "y": 701,
        "width": 700,
        "height": 700
    },
    "5.13.2": {
        "x": 3605,
        "y": 2303,
        "width": 700,
        "height": 700
    },
    "5.13.3": {
        "x": 5508,
        "y": 3403,
        "width": 700,
        "height": 700
    },
    "5.13.4": {
        "x": 3503,
        "y": 4506,
        "width": 700,
        "height": 700
    },
    "5.14.1": {
        "x": 701,
        "y": 3805,
        "width": 700,
        "height": 700
    },
    "5.14.2": {
        "x": 2802,
        "y": 4506,
        "width": 700,
        "height": 700
    },
    "5.14.3": {
        "x": 0,
        "y": 3805,
        "width": 700,
        "height": 700
    },
    "5.14": {
        "x": 1402,
        "y": 3805,
        "width": 700,
        "height": 700
    },
    "5.15.1": {
        "x": 1401,
        "y": 4506,
        "width": 1400,
        "height": 700
    },
    "5.15.2_B": {
        "x": 901,
        "y": 2454,
        "width": 700,
        "height": 700
    },
    "5.15.3_C": {
        "x": 0,
        "y": 1402,
        "width": 700,
        "height": 700
    },
    "5.15.4_B": {
        "x": 3605,
        "y": 3004,
        "width": 700,
        "height": 700
    },
    "5.15.5": {
        "x": 2303,
        "y": 701,
        "width": 700,
        "height": 700
    },
    "5.15.6": {
        "x": 1602,
        "y": 2454,
        "width": 700,
        "height": 700
    },
    "5.15.7_B": {
        "x": 0,
        "y": 4506,
        "width": 1400,
        "height": 700
    },
    "5.15.8": {
        "x": 701,
        "y": 1402,
        "width": 1400,
        "height": 700
    },
    "5.16": {
        "x": 2303,
        "y": 1402,
        "width": 600,
        "height": 900
    },
    "5.17": {
        "x": 4306,
        "y": 2703,
        "width": 600,
        "height": 900
    },
    "5.18": {
        "x": 3004,
        "y": 2703,
        "width": 600,
        "height": 900
    },
    "5.19.1": {
        "x": 0,
        "y": 0,
        "width": 700,
        "height": 700
    },
    "5.19.2": {
        "x": 701,
        "y": 0,
        "width": 700,
        "height": 700
    },
    "5.20": {
        "x": 3605,
        "y": 0,
        "width": 700,
        "height": 700
    },
    "5.21": {
        "x": 4306,
        "y": 901,
        "width": 600,
        "height": 900
    },
    "5.22": {
        "x": 4306,
        "y": 1802,
        "width": 600,
        "height": 900
    },
    "5.23.1": {
        "x": 3153,
        "y": 5207,
        "width": 1050,
        "height": 350
    },
    "5.23.2": {
        "x": 1952,
        "y": 3155,
        "width": 1050,
        "height": 350
    },
    "5.24.1": {
        "x": 901,
        "y": 3155,
        "width": 1050,
        "height": 350
    },
    "5.24.2": {
        "x": 1051,
        "y": 5207,
        "width": 1050,
        "height": 350
    },
    "5.25": {
        "x": 0,
        "y": 2103,
        "width": 1050,
        "height": 350
    },
    "5.26": {
        "x": 2102,
        "y": 5207,
        "width": 1050,
        "height": 350
    },
    "5.27": {
        "x": 4907,
        "y": 2703,
        "width": 600,
        "height": 900
    },
    "5.28": {
        "x": 4907,
        "y": 0,
        "width": 600,
        "height": 900
    },
    "5.29": {
        "x": 4907,
        "y": 4505,
        "width": 600,
        "height": 900
    },
    "5.30": {
        "x": 4907,
        "y": 1802,
        "width": 600,
        "height": 900
    },
    "5.31": {
        "x": 3004,
        "y": 1802,
        "width": 600,
        "height": 900
    },
    "5.32": {
        "x": 3004,
        "y": 0,
        "width": 600,
        "height": 900
    },
    "5.33": {
        "x": 4306,
        "y": 0,
        "width": 600,
        "height": 900
    },
    "5.34": {
        "x": 3004,
        "y": 901,
        "width": 600,
        "height": 900
    },
    "5.35": {
        "x": 4907,
        "y": 3604,
        "width": 600,
        "height": 900
    },
    "5.36": {
        "x": 4907,
        "y": 901,
        "width": 600,
        "height": 900
    },
    "5.37": {
        "x": 4306,
        "y": 3604,
        "width": 600,
        "height": 900
    },
    "5.38": {
        "x": 3605,
        "y": 701,
        "width": 600,
        "height": 900
    }
}
