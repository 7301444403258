import React, {useCallback} from "react";
import {connect} from "react-redux";
import ConditionalItemsGroup from "./ConditionalItemsGroup";
import {
    toggleTimePlayerVisibility,
    toggleVisibleUnvisibleFilter
} from "../../../../../reducers/map/actions/mapPanelsActions";
import {mapboxTimeActions} from "../../../../../reducers/map/actions/mapboxTimeActions";
import {getSelectedMapboxLayer} from "../../../../../reducers/map/mapboxLayers";

const ConditionalItemsGroupContainer = ({toggleTimePlayerVisibility,
                                            timePlayerVisible,
                                            iconSize,
                                            stopTimePlayerHandler,
                                            showTimePlayer,
                                            toggleVisibleUnvisibleFilter,
                                            visibleUnvisibleFilter,
                                            selectedLayer,
                                            ...other}) => {

    const handleToggleTimePlayerVisibility = useCallback(() => toggleTimePlayerVisibility(), []);

    return (
        <ConditionalItemsGroup
            handleToggleTimePlayerVisibility={handleToggleTimePlayerVisibility}
            timePlayerVisible={timePlayerVisible}
            stopTimePlayerHandler={stopTimePlayerHandler}
            toggleVisibleUnvisibleFilter={toggleVisibleUnvisibleFilter}
            visibleUnvisibleFilter={visibleUnvisibleFilter}
            selectedLayer={selectedLayer}
            iconSize={iconSize}
            showTimePlayer={showTimePlayer}
            {...other}
        />
    );
};


const mapStateToProps = state => {
    return {
        timePlayerVisible: state.mapPanels.timePlayerVisible,
        visibleUnvisibleFilter: state.mapPanels.visibleUnvisibleFilter,
        selectedLayer: getSelectedMapboxLayer(state),
    };
};

const mapDispatchToProps = dispatch => {
    return {
        toggleTimePlayerVisibility: () => dispatch(toggleTimePlayerVisibility()),
        stopTimePlayerHandler: () => dispatch(mapboxTimeActions.stopPlayerSaga()),
        toggleVisibleUnvisibleFilter: () => dispatch(toggleVisibleUnvisibleFilter())
    };
};

ConditionalItemsGroupContainer.propTypes = {};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ConditionalItemsGroupContainer);
