import { Form } from "antd";
import { useFormContext, Controller, ErrorMessage } from "react-hook-form";
import React from "react";
import { useTranslation } from "react-i18next";
import { Styled } from "./styled/BaseInput.styled";
import { checkConditionAndCallFn } from "../../services/common/checkConditionAndCallFn";
import { useBaseInputPlaceholderControl } from "./utils/hooks/useBaseInputPlaceholderControl";

const { BaseInput, BasePlaceholder, BaseErrorMessage } = Styled;

const StringInput = (props) => {
  const { control, errors, setValue } = useFormContext();
  const { description, object, field } = props;
  const { t, i18n } = useTranslation("common");
  const required = description.required;
  const message = `${description.label} ${t(
    "app.fieldRenderer.validation.error.incorrect"
  )}`;

  const {
    placeholderState,
    setPlaceholderState,
  } = useBaseInputPlaceholderControl(field, object, setValue);

  return (
    <Form.Item key={"form_" + field}>
      <Controller
        as={BaseInput}
        key={"input_" + field}
        name={field}
        control={control}
        rules={{ required: { value: required, message: message } }}
        disabled={description.read_only}
        placeholder={description.label}
        onFocus={() => {
          return setPlaceholderState(true);
        }}
        onBlur={(e) =>
          checkConditionAndCallFn(!e[0].target.value, () =>
            setPlaceholderState(false)
          )
        }
      />
      <ErrorMessage as={BaseErrorMessage} errors={errors} name={field} />
      <BasePlaceholder className={`${placeholderState ? "opened" : ""}`}>
        {description.label}
      </BasePlaceholder>
    </Form.Item>
  );
};

export default StringInput;
