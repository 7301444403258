import styled from "styled-components";

const LocalMainWrapper = styled.div`
  overflow-y: scroll;
  width: 315px;
  height: 435px;
  background: #f1f3f6;
  border-radius: 8px;
`;

const LocalCheckboxWrapper = styled.div`
  padding: 1.1rem;

  & path {
    color: #008291;
    width: 15px;
    height: 15px;
  }
  & span {
    color: #495e5f;
  }
`;

const LocalSelectWrapper = styled.div`
  & :focus {
    background: white;
    border-radius: 8px;
  }
  & button svg {
    width: 13px;
    height: 18px;
  }
`;

const LocalStyleValue = styled.div`
  & > article {
    line-height: 2.5;
  }
`;

export const Styled = {
  LocalMainWrapper,
  LocalCheckboxWrapper,
  LocalSelectWrapper,
  LocalStyleValue,
};
