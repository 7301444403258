import {RelatedDataPrototype} from "../prototype/RelatedDataPrototype";
import {dataAccessMethodsEnum} from "../../../../../../enums/dataAccessMethodsEnum";

export class CargoTransferParts extends RelatedDataPrototype {

    constructor() {
        super({
            name: 'cargoTransferParts',
            filterLabel: 'Слежение за ТМЦ',
            urlData: '/layer_tmc_tracking/transfer_parts/',
            dataAccessMethod: dataAccessMethodsEnum.httpGet
        });
    }
}
