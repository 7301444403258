import {
  CLEAR_ALERT_DATA,
  CLEAR_NOTIFICATION_DATA,
  SET_ALERT_DATA,
  SET_NOTIFICATION_DATA,
} from "../notifications";
import {INIT_SINGLE_EDIT_MAP_MODE_SAGA} from "../../../sagas/map/standaloneObjectEditableSaga";

export const setGlobalAlertData = ({ status, title, type, message, closureId }) => ({
  type: SET_ALERT_DATA,
  payload: {
    status,
    title,
    message,
    type,
    closureId,
  },
});

export const clearGlobalAlertData = () => ({
  type: CLEAR_ALERT_DATA,
});

export const init = () => ({
  type: INIT_SINGLE_EDIT_MAP_MODE_SAGA,
});


export const setPromptData = ({ status, title, type, message }) => ({
  type: SET_NOTIFICATION_DATA,
  payload: {
    status,
    title,
    message,
    type,
  },
});

export const clearPromptData = () => ({
  type: CLEAR_NOTIFICATION_DATA,
});
