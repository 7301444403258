import * as axios from 'axios'
import {CURRENT_APP_URL} from "../../constants/constants";

export const axiosInstance = axios.create({
    baseURL : CURRENT_APP_URL,
    headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
    }
});
