import React from "react";
import { Icon } from "antd";

const DocumentIconSVG = () => (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M3.75 2.25C2.9175 2.25 2.25 2.9175 2.25 3.75V14.25C2.25 15.0825 2.9175 15.75 3.75 15.75H14.25C15.0825 15.75 15.75 15.0825 15.75 14.25V3.75C15.75 2.9175 15.0825 2.25 14.25 2.25H3.75ZM3.75 3.75H14.25V14.25H3.75V3.75ZM5.25 5.25V6.75H12.75V5.25H5.25ZM5.25 8.25V9.75H12.75V8.25H5.25ZM5.25 11.25V12.75H10.5V11.25H5.25Z" fill="currentColor"/>
    </svg>
);

export const DocumentIcon = (props) => <Icon component={DocumentIconSVG} {...props} />;
