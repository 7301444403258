import React from "react";
import AutocompleteForm from "./AutocompleteForm/AutocompleteForm.jsx";
import styled from "styled-components";
import ObjectSiderContainer from "./Sider/ObjectPanelSiderContainer";
import { inlineResolver } from "../../../services/application/schemaManipulation/inlineResolver";
import ScrollableBoxArea from "../../areas/scrollableBoxArea/ScrollableBoxArea";
import { Styled } from "./ObjectPanel.styled";

const StyledDrawer = styled.div``;

const { PanelLayout } = Styled;

const ObjectPanel = React.forwardRef((props, ref) => {
  const {
    model,
    data,
    initClose,
    initSubmitForm,
    handleSubmitForm,
    goToInline,
    lastInlineFieldName,
  } = props;
  const schema = inlineResolver(model);
  const inlineDrawer = () => {
    return (
      <AutocompleteForm
        initClose={initClose}
        initSubmitForm={initSubmitForm}
        handleSubmitForm={handleSubmitForm}
        model={model}
        data={data}
        ref={ref}
      />
    );
  };

  const DisplayedInline = inlineDrawer();
  return (
    <StyledDrawer
      placement={"right"}
      closable={false}
      visible={false}
      getContainer={false}
      bodyStyle={{ padding: 0 }}
    >
      <PanelLayout>
        <ObjectSiderContainer
          goToInline={goToInline}
          initClose={initClose}
          initSubmitForm={initSubmitForm}
          handleSubmitForm={handleSubmitForm}
          ref={ref}
          schema={schema}
          lastInlineFieldName={lastInlineFieldName}
        />
        <ScrollableBoxArea>{DisplayedInline}</ScrollableBoxArea>
      </PanelLayout>
    </StyledDrawer>
  );
});

export default ObjectPanel;
