import React, { useCallback, useRef } from "react";
import { connect } from "react-redux";
import Compendium from "./Compendium";
import { useAgGridControl } from "../../../../customHooks/ag-grid/useAgGridControl";
import { initSetMapSelectedObjectSaga } from "../../../../sagas/map/mapSelectedObjectSaga";
import { Button } from "../../../UI/components/Button/Button";
import { CloseIcon } from "../../../icons/actionIcons/CloseIcon";
import { iconSizesKit } from "../../../UI/utils/iconSizesKit";
import { clearMapSelectedObject } from "../../../../reducers/map/actions/mapSelectedObjectActions";
import WindowedDataTable from "../../../UI/components/WindowedDataTable/WindowedDataTable";
import WindowedDataTableHeader from "../../../UI/components/WindowedDataTable/Header/WindowedDataTableHeader";
import CompendiumColumnActionPanelContainer from "../../CompendiumButtonBars/Common/CompendiumColumnActionPanel/CompendiumColumnActionPanelContainer";

const CompendiumSelectedContainer = ({
  data,
  model,
  initSetMapSelectedObjectSaga,
  mapSelectedObject,
  clearMapSelectedObject,
  ...other
}) => {
  const {
    aggApi,
    quickMenu,
    handleAGGInit,
    toggleShowFilterMenu,
    toggleShowQuickFilter,
    toggleShowRowsMenu,
  } = useAgGridControl();

  const handleSelectRow = useCallback(
    (aggridRow) => {
    },
    [aggApi, quickMenu]
  );

  const handleRefresh = useCallback(() => {}, []);

  const handleProvideData = (event) => {
    initSetMapSelectedObjectSaga(event.data, other.mode.childNext, "display");
  };

  let containerCallbacks = {
    handleAGGInit,
    handleRefresh,
    handleSelectRow,
    toggleShowFilterMenu,
    handleProvideData,
    toggleShowRowsMenu,
    toggleShowQuickFilter,
  };

  const panelLabel = useRef(mapSelectedObject?.data?.[0]?.label);
  return (
    <WindowedDataTable
      headerContent={
        <WindowedDataTableHeader
          titleContent={panelLabel.current || "object information"}
          actionsContent={
            <>
              <CompendiumColumnActionPanelContainer
                quickMenuState={quickMenu}
                toggleShowFilterMenu={containerCallbacks.toggleShowFilterMenu}
                toggleShowQuickFilter={containerCallbacks.toggleShowQuickFilter}
                toggleShowRowsMenu={containerCallbacks.toggleShowRowsMenu}
                iconSizeType={"small"}
              />
              <Button
                type={"unboundedPrimary"}
                p={0}
                icon={<CloseIcon />}
                iconSize={iconSizesKit.huge}
                rounded
                onClick={() => {
                  clearMapSelectedObject();
                }}
              />
            </>
          }
        />
      }
    >
      <Compendium
        aggApi={aggApi}
        data={data}
        model={model}
        showQuickFilter={quickMenu.search}
        containerCallbacks={containerCallbacks}
        withPagination={false}
        agGridThemeName={"ag-theme-custom-base ag-grid-theme-custom-map"}
      />
    </WindowedDataTable>
  );
};

CompendiumSelectedContainer.propTypes = {};

const mapStateToProps = (state) => {
  return {
    language: state.app.language,
    mapSelectedObject: state.mapSelectedObject,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    initSetMapSelectedObjectSaga: (data, type, mapMode) =>
      dispatch(initSetMapSelectedObjectSaga(data, type, mapMode)),
    clearMapSelectedObject: () => dispatch(clearMapSelectedObject()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CompendiumSelectedContainer);
