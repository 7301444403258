import styled from "styled-components";
import {Styled as styleMixins} from "../../styleMixins/styleMixins.styled"

const {HideScrollbar} = styleMixins.other
const {OpacifyColorInCertainProperty} = styleMixins.color
const {AddMarginsProps, AddPaddingsProps} = styleMixins.offset

const BaseWrapper = styled.div`
  ${OpacifyColorInCertainProperty("defaultLighter", "background", 0.85)};
  ${HideScrollbar};
  ${(props) => AddMarginsProps(props)};
  ${(props) => AddPaddingsProps(props)};
  border-radius: ${(props) => props.theme.decoration.defaultBorderRadius};
  box-shadow: ${(props) => props.theme.decoration.defaultBoxShadow};
  //backdrop-filter: blur(14px);
  max-width: 100%;
  z-index: 1;
  height: fit-content;
  transition: all 0.3s;
  
`;

const LocalWrapperPrimary = styled(BaseWrapper)`
  width: 272px;
  height: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
  backdrop-filter: ${(props) => props.theme.decoration.defaultBackgroundFilter};
`;

const LocalWrapperSecondary = styled(
    LocalWrapperPrimary
)`
  max-width: 100%;
  //backdrop-filter: blur(8px);
  z-index: 0;
`;

const LocalSectionWrap = styled.div`
  padding: ${(props) => props.theme.paddings.defaultPadding};
  ${(props) => AddMarginsProps(props)};
  ${(props) => AddPaddingsProps(props)};
`;

const LocalHeaderWrap = styled(LocalSectionWrap)`
  box-shadow: ${props => props.theme.decoration.defaultBoxShadow};
  ${(props) => AddMarginsProps(props)};
  ${(props) => AddPaddingsProps(props)};
`;

const LocalSpaceBetweenPartWrap = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const LocalItemWrap = styled.div`
  margin-top: 1rem;
`;

export const  Styled = {LocalWrapperPrimary, LocalWrapperSecondary, LocalSectionWrap, LocalHeaderWrap, LocalItemWrap, LocalSpaceBetweenPartWrap}