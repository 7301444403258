import {MonitoringLayerCard} from "./MonitoringLayerCard";
import {TrackingLayerCard} from "./TrackingLayerCard";
import {GeozonesLayerCard} from "./GeozonesLayerCard";


export const coreLayerCards = {
    MonitoringLayerCard,
    TrackingLayerCard,
    GeozonesLayerCard
}

