import React from "react";
import LayerChangeControlsTab from "./LayerChangeControlsTab";
import { connect } from "react-redux";
import { changeLayerSetting } from "../../../../../../../../../../../reducers/map/actions/mapboxLayersActions";

const LayerChangeControlsTabContainer = ({
  enabledLayers,
  changeLayerSetting,
}) => {
  const filteredLayer = enabledLayers.filter(
    (item) => item.isSelected === true
  );
  return (
    <LayerChangeControlsTab
      selectedLayer={filteredLayer[0]}
      changeLayerSetting={changeLayerSetting}
    />
  );
};

const mapStateToProps = (state) => {
  return {
    enabledLayers: state.mapboxLayers,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    changeLayerSetting: (layerKey, settingKey, val) =>
      dispatch(
        changeLayerSetting({
          keyOfLayer: layerKey,
          keyOfSetting: settingKey,
          value: val,
        })
      ),
  };
};

LayerChangeControlsTabContainer.propTypes = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LayerChangeControlsTabContainer);
