import { getFromURL } from "../../../../api/getFromURL";
import { Select as AntdSelect } from "antd";
import React from "react";

export const makeRepresentationDataRequest = async ({ inputDescription }) => {
  let options = null;
  if (inputDescription?.url) {
    const prepUrl = inputDescription?.url.replace("/api", "")
    const pageResponse = await getFromURL.getDataFromURL(prepUrl);
    const {count}=pageResponse
    const result = await getFromURL.getRepresentationData(
      prepUrl, count)

    if (Array.isArray(result?.results) && result?.results.length) {
      options = result.results.map((el) => (
        <AntdSelect.Option value={el.key}>
          {el.value ? el.value : "no data"}
        </AntdSelect.Option>
      ));
    }
  }
  return options;
};
