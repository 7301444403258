import React from "react";
import PropTypes from "prop-types";
import { Styled } from "./CustomTooltipGroup.styled";

const CustomTooltipGroup = ({
  title,
  titleColor,
  isTitleBold,
  children,
  offsetAfterTitle,
}) => {
  return (
    <Styled.LocalWrapper>
      {title && (
        <Styled.LocalTitle
          offsetAfterTitle={offsetAfterTitle}
          titleColor={titleColor}
          isTitleBold={isTitleBold}
        >
          {title}
        </Styled.LocalTitle>
      )}
      <Styled.LocalItem>{children}</Styled.LocalItem>
    </Styled.LocalWrapper>
  );
};

export default CustomTooltipGroup;

CustomTooltipGroup.propTypes = {
  children: PropTypes.any,
  isTitleBold: PropTypes.bool,
  title: PropTypes.string,
  titleColor: PropTypes.string,
  offsetAfterTitle: PropTypes.string,
};

CustomTooltipGroup.defaultProps = {
  isTitleBold: true,
};
