export class LayerCardPrototype {
  i18path;
  i18pathTemplate = "labels.";
  linkToPreviewPathTemplate = `${process.env.PUBLIC_URL}/img/layerImages/`;
  linkToPreview;
  dataSize;

  constructor({ i18path, linkToPreview, dataSize = "5mb" }) {
    this.i18path = this.i18pathTemplate + i18path;
    this.linkToPreview = this.linkToPreviewPathTemplate + linkToPreview;
    this.dataSize = dataSize;
  }

  getI18Path() {
    return this.i18path;
  }

  getLinkToPreview() {
    return this.linkToPreview;
  }

  getDataSize() {
    return this.dataSize;
  }
}