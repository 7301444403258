import React, {useCallback} from "react";
import SecondaryGroup from "./SecondaryGroup";
import {zoomIn, zoomOut} from "../../../../../reducers/map/actions/mapboxActions";
import {connect} from "react-redux";
import {setRulerMode} from "../../../../../reducers/map/actions/mapPanelsActions";


const SecondaryGroupContainer = ({ zoomIn,setRulerMode, zoomOut, viewState, rulerMode, ...other }) => {
  const handleZoomIn = useCallback(() => zoomIn(), []);
  const handleZoomOut = useCallback(() => zoomOut(), []);
  const toggleRuler = (useCallback((mode)=>setRulerMode(mode),[]))
  return (
    <SecondaryGroup
      handleZoomIn={handleZoomIn}
      handleZoomOut={handleZoomOut}
      viewState={viewState}
      toggleRuler={toggleRuler}
      rulerMode={rulerMode}
      {...other}
    />
  );
};

SecondaryGroupContainer.propTypes = {};

const mapStateToProps = state => {
  return {
    viewState : state.mapboxView.viewState,
    rulerMode: state.mapPanels.rulerMode
  };
};

const mapDispatchToProps = dispatch => {
  return {
    zoomIn: () => dispatch(zoomIn()),
    zoomOut: () => dispatch(zoomOut()),
    setRulerMode: (mode) => dispatch(setRulerMode(mode))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SecondaryGroupContainer);
