import {MainDataHttpPrototype} from "../prototype/MainDataHttpPrototype";
import {dataAccessMethodsEnum} from "../../../../../../enums/dataAccessMethodsEnum";

export class VehicleTrackingMainData extends MainDataHttpPrototype{

    data = null;
    constructor() {
        super({
            // urlData:'/layer_track/',
            // dataKeyForIds:'vehicle_id',
            dataAccessMethod: dataAccessMethodsEnum.useLayerCallback,
            // dataWrapper: Array,
            // postRequestNeedsTime: true,
        });
    }

    setMainData(data){
        this.data = data
    }

    getMainData(){
        return this.data
    }

}
