import React from "react";
import { Styled } from "./RepresentationCheckedItem.styled";
import { Checkbox } from "../../../../../../../library/UI/components/Checkbox/Checkbox";
import PanelScrollableCard from "../../../../../../../library/UI/components/ScrollableCard/ScrollableCard";
import DraggableOneColumnGrid from "../../../../../../../library/UI/components/DraggableOneColumnGrid/DraggableOneColumnGrid";
import { Divider } from "antd";
import SearchComponent from "../../utils/SearchComponent";
import DeleteButton from "../../../../../../../library/actionsButtons/common/DeleteButton";
import { DeleteIcon } from "../../../../../../../library/icons/actionIcons/DeleteIcon";
import { Button } from "../../../../../../../library/UI/components/Button/Button";

const {
  LocalMainWrapper,
  LocalHeaderMainWrapper,
  LocalCheckboxWrapper,
  LocalWrapperDivider,
  LocalHeaderWrapper,
  LocalInnerWrapper,
} = Styled;

const RepresentationCheckedItem = ({
  items,
  handleAllChecked,
  groupItems,
  handleRemoveItems,
    isDeletedItems
}) => {
  return (
    <LocalMainWrapper>
      <LocalInnerWrapper>
        <PanelScrollableCard
          headerStyles={{ boxShadow: `none`, padding: `0px` }}
          footerStyles={{ padding: 0 }}
          contentStyles={{
            marginRight: `4px`,
            padding: `0.675rem`,
            lineHeight: 2.5,
          }}
          wrapperStyles={{
            background: `#F1F3F6`,
            width: "315px",
            height: "435px",
          }}
          headerContent={
            <LocalHeaderMainWrapper style={{ width: `100%` }}>
              <LocalHeaderWrapper>
                {/*<SearchComponent itemTS={itemTS} />*/}
                <LocalCheckboxWrapper>
                  <Checkbox onClick={handleAllChecked}>Выбрать всё</Checkbox>
                  {/*<DeleteButton></DeleteButton>*/}
                  {isDeletedItems &&
                  <Button
                      type={"unbounded"}
                      onClick={handleRemoveItems}
                      icon={<DeleteIcon />}
                  /> }

                </LocalCheckboxWrapper>
              </LocalHeaderWrapper>
              <LocalWrapperDivider>
                <Divider />
              </LocalWrapperDivider>
            </LocalHeaderMainWrapper>
          }
          children={items}
          groupItems={groupItems}
        />
      </LocalInnerWrapper>
    </LocalMainWrapper>
  );
};

export default RepresentationCheckedItem;
