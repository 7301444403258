import {parseNumber} from "../data/parseNumber";

export function sliceIdFromEndOfUrl(url) {
    const urlItems = url.split("/").filter(el => el !== "");
    let resultId = null
    const isLastUrlItemNumber =
        typeof parseNumber(urlItems[urlItems.length - 1]) === "number";
    if (isLastUrlItemNumber) {
        resultId = urlItems[urlItems.length - 1]
    }
    return resultId;
}