import {RelatedDataPrototype} from "../prototype/RelatedDataPrototype";
import {mapObjectsEnum} from "../../../../../../enums/mapObjectsEnum";
import {dataAccessMethodsEnum} from "../../../../../../enums/dataAccessMethodsEnum";

export class RoadSecurityCheckpoints extends RelatedDataPrototype {

    constructor() {
        super({
            name: 'security_checkpoint',
            filterLabel: 'КПП',
            urlData: '/layer_roads/checkpoints-segments/',
            selectedObjectName:  mapObjectsEnum.securityCheckpoint,
            // dataAccessMethod: dataAccessMethodsEnum.custom // нужно было для проброса даты в запрос
        });
    }
}
