import { call, put, all, takeEvery } from "redux-saga/effects";

import securedAxiosInstance from "../../services/net/axiosSecured";
import {
  addMapboxMultipleRelatedData,
} from "../../reducers/map/actions/mapboxDataActions";
import { dataAccessMethodsEnum } from "../../enums/dataAccessMethodsEnum";
import { alertTypesEnum } from "../../enums/alertTypesEnum";
import {endAppFetching, startAppFetching} from "../../reducers/app/actions/appActions"
import {setGlobalAlertData} from "../../reducers/app/actions/notificationActions";
import {alertStatusesEnum} from "../../enums/alertStatusesEnum";

const INIT_GET_RELATED_DATA_FOR_LAYER = "INIT_GET_RELATED_DATA_FOR_LAYER";

export const initGetRelatedDataForLayer = (Layer) => ({
  type: INIT_GET_RELATED_DATA_FOR_LAYER,
  payload: {
    Layer,
  },
});

const getModel = (urlData) => {
  return securedAxiosInstance.options(urlData);
};

function* httpGetAllRequestSaga(dataSource) {
  const model = yield call(() => getModel(dataSource.urlData));
  const pageResponse = yield call(() =>
    securedAxiosInstance.get(dataSource.urlData + "?page_size=1")
  );
  const { count } = pageResponse.data;
  const resultResponse = yield call(() =>
    securedAxiosInstance.get(dataSource.urlData + `?page_size=${count}`)
  );
  return {
    requestType: dataAccessMethodsEnum.httpGetAll,
    model,
    resultData: resultResponse?.data?.results,
    dataSourceName: dataSource.name,
  };
}

function* otherRequestSaga(dataSource) {
  const model = yield call(() => getModel(dataSource.urlData));
  const response = yield call(() =>
    securedAxiosInstance.get(dataSource.urlData)
  );
  const responseData = response?.data;
  const resultData = responseData?.results
    ? responseData.results
    : responseData;
  return {
    requestType: "other",
    model,
    resultData,
    dataSourceName: dataSource.name,
  };
}

function* getRelatedDataForLayerSaga(action) {

  yield put(startAppFetching())

  try {
    const { Layer } = action.payload;
    const layerRelatedData = Layer.getRelatedData();

    if (layerRelatedData && Array.isArray(layerRelatedData)) {
      const dataSourcesCollection = layerRelatedData.map((dataSource) =>
        dataSource.getDataAccessMethod() === dataAccessMethodsEnum.httpGetAll
          ? call(() => httpGetAllRequestSaga(dataSource))
          : call(() => otherRequestSaga(dataSource))
      );
      const results = yield all(dataSourcesCollection);

      if (results && Array.isArray(results)) {
        const arrayOfRelatedData = [];
        for (let item of results) {
          arrayOfRelatedData.push({
            parentKey: Layer.getName(),
            childKey: item?.dataSourceName,
            data: item?.resultData,
          });
          arrayOfRelatedData.push({
            parentKey: Layer.getName(),
            childKey: item?.dataSourceName + "_model",
            data: item?.model?.data,
          });
        }
        yield put(addMapboxMultipleRelatedData(arrayOfRelatedData));
      }
    } else {
      console.warn(
        `Layer ${Layer.getName()} doesn't have related data or related data is not an array`
      );
      yield put(
        setGlobalAlertData({
          status: alertStatusesEnum.active,
          type: alertTypesEnum.warning,
          title: "Warning",
          message: `Layer ${Layer.getName()} doesn't have related data or related data is not an array`,
        })
      );
    }
  } catch (e) {
    console.error(e);
    yield put(
      setGlobalAlertData({
        status: alertStatusesEnum.active,
        type: alertTypesEnum.error,
        title: "Ошибка",
        message: "При загрузке данных слоя произошла ошибка",
      })
    );
  }
  finally {
    yield put(endAppFetching())
  }
}

export function* watchGetRelatedDataForLayerSaga() {
  yield takeEvery(INIT_GET_RELATED_DATA_FOR_LAYER, getRelatedDataForLayerSaga);
}
