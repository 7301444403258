import { CompositeLayer } from "@deck.gl/core";
import { ArcLayer, ScatterplotLayer, TextLayer } from "@deck.gl/layers";
import wkt from "wkt";
import { layerNamesEnum } from "../../../../enums/layerNamesEnum";
import { mapObjectsEnum } from "../../../../enums/mapObjectsEnum";

class CargoTrackingCompositeLayer extends CompositeLayer {
  updateState({ changeFlags, props, oldProps, ...other }) {
    if (props?.relatedData?.cargoTransferParts?.length) {
      const data = props?.relatedData?.cargoTransferParts;
      const countOfCargosHere = {};
      const countOfCargosWent = {};
      const countOfCargosNotBeenYet = {};
      const setOfPoints = [];
      let setOfDestination = [];
      for (let it of data) {
        const startPoint = it.current;
        if (countOfCargosHere?.[startPoint.name]) {
          countOfCargosHere[startPoint.name].sum += it.current_count;
        } else {
          setOfPoints.push(startPoint.name);
          countOfCargosHere[startPoint.name] = {};
          countOfCargosHere[startPoint.name].sum = it.current_count;
          countOfCargosHere[startPoint.name].location = startPoint.location;
        }
        const endPoint = it.next;

        if (countOfCargosWent?.[startPoint.name]) {
          countOfCargosWent[startPoint.name].sum += it.next_count;
        } else {
          countOfCargosWent[startPoint.name] = {};
          countOfCargosWent[startPoint.name].sum = it.next_count;
          countOfCargosWent[startPoint.name].location = startPoint.location;
        }

        if (countOfCargosNotBeenYet?.[startPoint.name]) {
          countOfCargosNotBeenYet[startPoint.name].sum += it.prev_count;
        } else {
          countOfCargosNotBeenYet[startPoint.name] = {};
          countOfCargosNotBeenYet[startPoint.name].sum = it.prev_count;
          countOfCargosNotBeenYet[startPoint.name].location =
            startPoint.location;
        }

        if (
          setOfDestination.findIndex((el) => el?.name === endPoint.name) === -1
        ) {
          setOfDestination.push({
            name: endPoint.name,
            location: endPoint.location,
          });
        }
      }
      setOfDestination = setOfDestination.filter((el) => {
        return setOfPoints.findIndex((inPoint) => inPoint === el.name) < 0;
      });
      this.setState({
        countOfCargosHere,
        setOfPoints,
        setOfDestination,
        countOfCargosWent,
        countOfCargosNotBeenYet,
      });
    }
  }

  getPickingInfo(event) {
    if (event.mode === "query") {
      if (this.props.onClickHandler) {
        let selectedObjectType = null;
        let selectedObjectLayerName = layerNamesEnum.cargoTracking;
        if (event.sourceLayer.id.includes("cargo-point")) {
          selectedObjectType = mapObjectsEnum.clusterCargos;
          const locationName = event.info.object;
          this.props.onClickHandler(
            locationName,
            selectedObjectType,
            selectedObjectLayerName
          );
        }
      } else return null;
    }
  }
  renderLayers() {
    if (!this.state) {
      return [];
    }
    return [
      new ScatterplotLayer(
        this.props,
        this.getSubLayerProps({ id: "cargo-point" }),
        {
          pickable: true,
          data: this.state.setOfPoints,
          stroked: true,
          filled: true,
          radiusScale: 2,
          radiusMinPixels: 20,
          radiusMaxPixels: 40,
          lineWidthMinPixels: 1,
          getPosition: (d) => {
            const selectable = this.state.countOfCargosHere[d];
            if (selectable?.location) {
              const parsed = wkt.parse(selectable?.location);
              if (parsed) {
                return parsed.coordinates;
              } else {
                return [100, 100];
              }
            } else {
              return [100, 100];
            }
          },
          getRadius: (d) => {
            const selectable = this.state.countOfCargosHere[d];
            return selectable.sum;
          },
          opacity: 0.8,
          wireframe: true,
          getFillColor: (d) => {
            const selectable = this.state.countOfCargosHere[d];
            if (selectable?.sum) {
              return [47, 128, 237, 100];
            } else {
              return [194, 192, 191];
            }
          },
          getLineColor: (d) => {
            const selectable = this.state.countOfCargosHere[d];
            if (selectable?.sum) {
              return [47, 128, 237, 100];
            } else {
              return [194, 192, 191];
            }
          },
        }
      ),
      new TextLayer(
        this.props,

        this.getSubLayerProps({ id: "name-of-points" }),
        {
          data: this.state.setOfPoints,
          getPixelOffset: [0, 42],
          fp64: true,
          getPolygonOffset: ({ layerIndex }) => [0, -layerIndex * 10000],
          getPosition: (d) => {
            const selectable = this.state.countOfCargosHere[d];
            if (selectable?.location) {
              const parsed = wkt.parse(selectable?.location);
              if (parsed) {
                return parsed.coordinates;
              } else {
                return [100, 100];
              }
            } else {
              return [100, 100];
            }
          },
          getText: (d) => d.toUpperCase(),
          billboard: true,
          backgroundColor: [255, 255, 255, 90],
          getColor: [193, 203, 216],
          getOpacity: 1,
          sizeScale: 1,
          getSize: 20,
          getAngle: 0,
          getTextAnchor: "middle",
          getAlignmentBaseline: "center",
          sizeMinPixels: 20,
          sizeMaxPixels: 20,
        }
      ),
      new TextLayer(
        this.props,
        this.getSubLayerProps({ id: "number-of-cargos" }),
        {
          data: this.state.setOfPoints,
          getPixelOffset: [0, 75],
          getPosition: (d) => {
            const selectable = this.state.countOfCargosHere[d];
            if (selectable?.location) {
              const parsed = wkt.parse(selectable?.location);
              if (parsed) {
                return parsed.coordinates;
              } else {
                return [100, 100];
              }
            } else {
              return [100, 100];
            }
          },
          fp64: true,
          getPolygonOffset: ({ layerIndex }) => [0, -layerIndex * 10000],
          getText: (d) => {
            const selectable = this.state.countOfCargosHere[d];
            return "" + selectable.sum;
          },
          billboard: true,
          backgroundColor: [255, 255, 255],
          getColor: [47, 128, 237],
          getOpacity: 1,
          sizeScale: 1,
          getSize: 20,
          getAngle: 0,
          getTextAnchor: "middle",
          getAlignmentBaseline: "center",
          sizeMinPixels: 20,
          sizeMaxPixels: 20,
        }
      ),
      new ArcLayer({
        id: "arc-layer",
        data: this.props?.relatedData?.cargoTransferParts,
        getWidth: (d) => ~~(this.state?.countOfCargosHere?.[d]?.sum / 10),
        widthMinPixels: 2,
        widthMaxPixels: 2,
        getHeight: 0.05,
        getSourcePosition: (d) => {
            if (d?.current?.location?.length) {
                const wktstr = d.current.location;
                const parsed = wkt.parse(wktstr);
                return parsed.coordinates;
            } else {
                return [0,0]
            }


        },
        getTargetPosition: (d) => {
            if (d?.current?.location?.length) {
                const wktstr = d.next.location;
                const parsed = wkt.parse(wktstr);
                return parsed.coordinates;
            } else {
                return [0,0]
            }

        },
        getSourceColor: (d) => {
          const countOfCargosWent = this.state?.countOfCargosWent?.[
            d?.current?.name
          ]?.sum;
          if (countOfCargosWent) {
            return [47, 128, 237];
          }
          else {
            return [47, 128, 237,50];
          }
        },
        getTargetColor: (d) => {
          const countOfCargosNotBeenYet = this.state?.countOfCargosNotBeenYet?.[
            d?.current?.name
          ]?.sum;
          if (countOfCargosNotBeenYet) {
            return [155, 81, 224];
          }
          else {
            return [155, 81, 224,50];
          }
        },
      }),
      new ScatterplotLayer(
        this.props,
        this.getSubLayerProps({ id: "point-last" }),
        {
          pickable: true,
          data: this.state.setOfDestination,
          stroked: true,
          filled: true,
          radiusScale: 2,
          radiusMinPixels: 20,
          radiusMaxPixels: 40,
          lineWidthMinPixels: 1,
          getPosition: (d) => {
            const wktstr = d.location;
            const parsed = wkt.parse(wktstr);
            return parsed.coordinates;
          },
          getRadius: () => 20,
          opacity: 0.8,
          wireframe: true,
          getFillColor: [47, 128, 237, 100],
          getLineColor: [47, 128, 237, 100],
        }
      ),
      new TextLayer(this.props, this.getSubLayerProps({ id: "name-of-last" }), {
        data: this.state.setOfDestination,
        getPixelOffset: [0, 42],
        fp64: true,
        getPolygonOffset: ({ layerIndex }) => [0, -layerIndex * 10000],
        getPosition: (d) => {
          const wktstr = d.location;
          const parsed = wkt.parse(wktstr);
          return parsed.coordinates;
        },
        getText: (d) => d.name.toUpperCase(),
        billboard: true,
        getColor: [47, 128, 237],
        getOpacity: 1,
        sizeScale: 1,
        getSize: 20,
        getAngle: 0,
        getTextAnchor: "middle",
        getAlignmentBaseline: "center",
        sizeMinPixels: 20,
        sizeMaxPixels: 20,
      }),
    ];
  }
}

const defaultProps = {
  // Inherit all of GeoJsonLayer's props
  ...TextLayer.defaultProps,
  // Label for each feature
  getLabel: { type: "accessor", value: (x) => x },
  // Label size for each feature
  getLabelSize: { type: "accessor", value: 8 },
  // Label color for each feature
  getLabelColor: { type: "accessor", value: [0, 0, 0, 255] },
  getCurrentTimeStamp: { type: "accessor", value: 0 },
  // Label always facing the camera
  billboard: true,
  // Label size units
  labelSizeUnits: "pixels",
  // Label background color
  labelBackground: { type: "color", value: [255, 255, 255], optional: true },
  // Label font
  fontFamily: "Monaco, monospace",
};

CargoTrackingCompositeLayer.layerName = "CargoTrackingLayer";
CargoTrackingCompositeLayer.defaultProps = defaultProps;

export default CargoTrackingCompositeLayer;
