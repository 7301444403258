import {LayerSchemaPrototype} from "../prototype/LayerSchemaPrototype";
import TrafficSegmentsCompositeLayer
    from "../../../../../../library/layers/CompositeLayers/NIPIGAZ/TrafficSegmentsCompositeLayer";

export class TrafficSegmentsLayerSchema extends LayerSchemaPrototype{

    constructor() {
        super({
            layerToRender: TrafficSegmentsCompositeLayer,
            id: 'TrafficSegments',
        });
    }
}
