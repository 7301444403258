import {NumericLayerSetting} from "./prototype/types/NumericLayerSetting";

export class IconSizeScale extends NumericLayerSetting{

    constructor() {
        super({
            label: 'Размер иконок',
            keyForDeck: 'sizeScale',
            type: Number,
            minimum: 0.1,
            currentValue: 1,
            maximum: 5,
            step: 0.1,
            isRanged: false,
            isPercent: true,
        });
    }

}
