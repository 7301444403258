import React from "react";
import LayerCard from "./LayerCard";
import { selectMapboxLayer } from "../../../../../../../../../../../../reducers/map/actions/mapboxLayersActions";
import { connect } from "react-redux";
import { getDataForLayer } from "../../../../../../../../../../../../reducers/map/actions/mapboxDataActions";
import { getSelectedMapboxLayer } from "../../../../../../../../../../../../reducers/map/mapboxLayers";
import { initGetRelatedDataForLayer } from "../../../../../../../../../../../../sagas/map/getRelatedDataForLayerSaga";
import { getRegisteredLayer } from "../../../../../../../../../../../../registrators/map/layers/layersRegistrator";
import { initAddLayerToMapSaga } from "../../../../../../../../../../../../sagas/map/addLayerToMapSaga";

const LayerCardContainer = ({
  addNewLayer,
  addDataSource,
  selectMapboxLayer,
  getRelatedDataForLayer,
  card,
  isSelected,
  name,
  ...other
}) => {
  const addLayerWithSideEffects = () => {
    const LayerInstance = getRegisteredLayer(name);
    addNewLayer(LayerInstance);
  };

  return (
    <LayerCard
      {...other}
      card={card}
      isSelected={isSelected}
      addLayerWithSideEffects={addLayerWithSideEffects}
    />
  );
};

const mapStateToProps = (state) => {
  return {
    selectedMapboxLayer: getSelectedMapboxLayer(state),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    addNewLayer: (Layer) => dispatch(initAddLayerToMapSaga(Layer)),
    addDataSource: (Layer) => dispatch(getDataForLayer(Layer)),
    getRelatedDataForLayer: (Layer) =>
      dispatch(initGetRelatedDataForLayer(Layer)),
    selectMapboxLayer: (Layer) => dispatch(selectMapboxLayer(Layer)),
  };
};

LayerCardContainer.propTypes = {};

export default connect(mapStateToProps, mapDispatchToProps)(LayerCardContainer);
