
import {LayerSchemaPrototype} from "../prototype/LayerSchemaPrototype";
import MonitoringCompositeLayer from "../../../../../../library/layers/CompositeLayers/Core/MonitoringCompositeLayer";
import GeozonesCompositeLayer from "../../../../../../library/layers/CompositeLayers/NIPIGAZ/GeozonesCompositeLayer";
import CargoTrackingCompositeLayer
    from "../../../../../../library/layers/CompositeLayers/NIPIGAZ/CargoTrackingCompositeLayer";
import RailwayNetworkCompositeLayer
    from "../../../../../../library/layers/CompositeLayers/NIPIGAZ/RailwayNetworkCompositeLayer";

export class RailwayNetworkLayerSchema extends LayerSchemaPrototype{

    constructor() {
        super({
            layerToRender: RailwayNetworkCompositeLayer,
            id: 'RailwayNetwork',
            useZoomDeps : true,
        });
    }
}
