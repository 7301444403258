import { createSelector } from "reselect";
import {makeObjectDeepCopy} from "../../services/data/makeObjectDeepCopy";

export const ADD_MAPBOX_LAYER = "ADD_MAPBOX_LAYER";
export const REMOVE_MAPBOX_LAYER = "REMOVE_MAPBOX_LAYER";
export const CLEAR_MAPBOX_LAYERS = "CLEAR_MAPBOX_LAYERS";
export const TOGGLE_HIDE_LAYER = "TOGGLE_HIDE_LAYER";
export const TOGGLE_LAYER_SETTINGS = "TOGGLE_LAYER_SETTINGS";
export const CHANGE_LAYER_ORDER = "CHANGE_LAYER_ORDER";
export const CHANGE_LAYER_DATA_SELECTION_VISIBLE =
  "CHANGE_LAYER_DATA_SELECTION_VISIBLE";
export const CHANGE_LAYER_SETTING = "CHANGE_LAYER_SETTING";
export const SELECT_MAPBOX_LAYER = "SELECT_MAPBOX_LAYER";
export const REVERT_TO_DEFAULT_LAYER_SETTINGS =
  "REVERT_TO_DEFAULT_LAYER_SETTINGS";

const initialState = [];
const initialLayerSettings = [];

function addInitialLayerSettings(Layer, initialSettingsStorage) {
  const name = Layer.getName()
  const customSettings = Layer.getCustomSettings()
  const defaultSettings = customSettings.map(Setting => makeObjectDeepCopy(Setting.getSettingValues()))
  const match = initialSettingsStorage.filter(el => el.name === name);
  if (!match.length) {
    initialSettingsStorage.push({ name, defaultSettings });
  }
}

//selectors area
export const selectMapboxLayers = state => {
  return state.mapboxLayers;
};

export const getSelectedMapboxLayer = createSelector(
  [selectMapboxLayers],
  mapboxLayers => {
    return mapboxLayers.find(item => item.isSelected === true);
  }
);

//

const mapboxLayers = (state = initialState, action) => {
  switch (action.type) {
    case ADD_MAPBOX_LAYER: {
      if (state.length === 0) {
          action.payload.Layer.isSelected = true;
      }
      if (action.payload.Layer) {
        const {Layer} = action.payload
        const comparedField = Layer.getName();
        const match = state.filter(item => item?.name === comparedField);
        if (!match.length) {
          addInitialLayerSettings(Layer, initialLayerSettings);
          const prepare = [...state, Layer]
          return prepare.map(item => { //делаем слой сразу активным
            if (item.getName() === Layer.getName()) {
              item.isSelected = true;
              return item;
            } else {
              item.isSelected = false;
              return item;
            }
          })
        }
        return [...state];
      }
      return state;
    }
    case REMOVE_MAPBOX_LAYER: {
      if (action.payload) {
        const {name} = action.payload;
        return state.filter(item => item?.name !== name);
      }
      return state;
    }
    case SELECT_MAPBOX_LAYER: {
      if (action.payload) {
        const {Layer} = action.payload;

        return state.map(item => {
          if (item.getName() === Layer.getName()) {
            item.isSelected = true;
            return item;
          } else {
            item.isSelected = false;
            return item;
          }
        })

      }
      return state;
    }
    case CHANGE_LAYER_SETTING: {
      if (action.payload) {
        const { keyOfLayer, keyOfSetting, value } = action.payload;
        let copyOfState = [...state];
        const layerIndex = copyOfState.findIndex(el => el.name === keyOfLayer);
        if (layerIndex !== -1) {
          const settingIndex = copyOfState[layerIndex].customSettings.findIndex(
            el => el.keyForDeck === keyOfSetting
          );
          if (settingIndex !== -1) {
            copyOfState[layerIndex].customSettings[
              settingIndex
            ].currentValue = value;
          }
          return copyOfState;
        }
        return copyOfState;
      }
      return state;
    }
    case REVERT_TO_DEFAULT_LAYER_SETTINGS: {
      if(action.payload) {
        const {keyOfLayer} = action.payload
        const matchedDefaultLayerSettings = initialLayerSettings.filter(item => item.name === keyOfLayer)
        if(matchedDefaultLayerSettings.length) {
          const {defaultSettings} = matchedDefaultLayerSettings[0]
          const stateCopy = [...state]
          const currentLayer = stateCopy.findIndex((el) => el.name === keyOfLayer)
          stateCopy[currentLayer].customSettings = defaultSettings.map(item => makeObjectDeepCopy(item))
          return stateCopy
        }
      }
      return state
    }
    case CLEAR_MAPBOX_LAYERS: {
      return initialState;
    }
    case TOGGLE_HIDE_LAYER: {
      const layer = action.payload;
      let copyOfState = [...state];
      const layerIndex = copyOfState.findIndex(el => el.name === layer.name);
      if (copyOfState[layerIndex].hidden) {
        copyOfState[layerIndex].hidden = !copyOfState[layerIndex].hidden;
      } else {
        copyOfState[layerIndex].hidden = true;
      }
      return copyOfState;
    }
    case TOGGLE_LAYER_SETTINGS: {
      const layer = action.payload;
      let copyOfState = [...state];
      const layerIndex = copyOfState.findIndex(el => el.name === layer.name);
      if (copyOfState[layerIndex].showSettings) {
        copyOfState[layerIndex].showSettings = !copyOfState[layerIndex]
          .showSettings;
      } else {
        copyOfState[layerIndex].showSettings = true;
      }
      return copyOfState;
    }
    case CHANGE_LAYER_DATA_SELECTION_VISIBLE: {
      const { layer } = action.payload;
      let copyOfState = [...state];
      const layerIndex = copyOfState.findIndex(el => el.name === layer.name);
      if (copyOfState[layerIndex].showDataSelection) {
        copyOfState[layerIndex].showDataSelection = !copyOfState[layerIndex]
          .showDataSelection;
      } else {
        copyOfState[layerIndex].showDataSelection = true;
      }
      return copyOfState;
    }
    case CHANGE_LAYER_ORDER: {
      //TODO add logic
      return state;
    }
    default:
      return state;
  }
};

export default mapboxLayers;
