import {CURRENT_APP_URL} from "../constants/constants";

export class RegistratorPrototype {
    name = null

    static basePath = CURRENT_APP_URL;
    static baseWSPath = `wss://api.demo-ng.oits.su`;

    constructor(name) {
        this.name=name
    }
    getName() {
        return this.name
    }
}
