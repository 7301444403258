import React from "react";
import DisplayedLayerItem from "./DisplayedLayerItem";
import { connect } from "react-redux";
import {
  changeLayerOrder,
  removeLayer,
  selectMapboxLayer,
  toggleLayerVisibility
} from "../../../../../../../../../../../reducers/map/actions/mapboxLayersActions";
import {
  closeAllAdditionalPanels,
  setLayerDataAccessor, setSelectedLayerSettingsType,
  toggleLayerDataAccessorVisibility,
  toggleLayerSettingsVisibility
} from "../../../../../../../../../../../reducers/map/actions/mapPanelsActions";
import { closeSocketConnection } from "../../../../../../../../../../../reducers/map/socketsStatus";
import { getSelectedMapboxLayer } from "../../../../../../../../../../../reducers/map/mapboxLayers";
import {initGetRelatedDataForLayer} from "../../../../../../../../../../../sagas/map/getRelatedDataForLayerSaga";
import {getRegisteredLayer} from "../../../../../../../../../../../registrators/map/layers/layersRegistrator";

const DisplayedLayerItemContainer = ({
  item,
  toggleLayerVisibility,
  toggleLayerSettingsVisibility,
  changeLayerOrder,
  getRelatedDataForLayer,
  removeLayer,
  closeSocket,
  selectMapboxLayer,
  isSettingsPanelVisible,
  setLayerDataAccessor,
  toggleLayerDataAccessorVisibility,
  isLayerDataAccessorVisible,
  itemKey,
  closeAllAdditionalPanels,
  currentDataAccessor,
  selectedMapboxLayer,
                                       setSelectedLayerSettings
}) => {
  const isCurrentDataAccessorForThisItem = currentDataAccessor?.selectedType === item?.dataAccessor.selectedType;
  const isSelectedMapboxLayerForThisItem =
    selectedMapboxLayer?.getName() === item.name;
  const toggleLayerVisible = () => {
    toggleLayerVisibility(item);
  };

  const toggleLayerSettingsVisible = () => {
    toggleLayerSettingsVisibility();
  };

  const switchLayerDisplayedOrder = () => {
    changeLayerOrder(item);
  };

  const selectDataForLayer = () => {
    if (isCurrentDataAccessorForThisItem) {
      toggleLayerDataAccessorVisibility();
    } else {
      setLayerDataAccessor(item.getDataAccessor());
      toggleLayerDataAccessorVisibility(true);
    }
  };

  const handleRemoveLayer = layer => {
    removeLayer(layer.name);
    closeAllAdditionalPanels();
    //initiatorType, initiatorName
    const LayerInstance = getRegisteredLayer(layer.name)
    const dataGetterType = LayerInstance?.getMainData()?.getDataAccessMethod()

    if (dataGetterType === "ws") {
      closeSocket("mapLayer", layer.name);
    }
  };

  const onItemWrapperClick = () => {
    selectMapboxLayer(item)
  }

  const selectMapboxLayerWithSideEffect = layer => {
    if (isSelectedMapboxLayerForThisItem) {
      toggleLayerSettingsVisibility();
    }
    else {
        selectMapboxLayer(layer);
        toggleLayerSettingsVisibility(true);
    }
  };

  return (
    <DisplayedLayerItem
      itemKey={itemKey}
      item={item}
      toggleLayerVisible={toggleLayerVisible}
      toggleLayerSettingsVisible={toggleLayerSettingsVisible}
      switchLayerDisplayedOrder={switchLayerDisplayedOrder}
      selectDataForLayer={selectDataForLayer}
      handleRemoveLayer={handleRemoveLayer}
      onItemWrapperClick={onItemWrapperClick}
      isSelected={isSelectedMapboxLayerForThisItem}
      selectMapboxLayerWithSideEffect={selectMapboxLayerWithSideEffect}
      isSettingsPanelVisible={isSettingsPanelVisible}
      isLayerDataAccessorVisible={isLayerDataAccessorVisible}
      isCurrentDataAccessorForThisItem={isCurrentDataAccessorForThisItem}
      setSelectedLayerSettings={setSelectedLayerSettings}
    />
  );
};

DisplayedLayerItemContainer.propTypes = {};

const mapStateToProps = state => {
  return {
    enabledLayers: state.mapboxLayers,
    isSettingsPanelVisible: state.mapPanels?.layerSettingVisible,
    currentDataAccessor: state.mapPanels.dataAccessor,
    isLayerDataAccessorVisible: state.mapPanels?.dataAccessorVisible,
    selectedMapboxLayer: getSelectedMapboxLayer(state)
  };
};

const mapDispatchToProps = dispatch => {
  return {
    toggleLayerVisibility: layer => dispatch(toggleLayerVisibility(layer)),
    toggleLayerSettingsVisibility: (bool) =>
      dispatch(toggleLayerSettingsVisibility(bool)),
    changeLayerOrder: layer => dispatch(changeLayerOrder(layer)),
    getRelatedDataForLayer: layer => dispatch(initGetRelatedDataForLayer(layer)),
    removeLayer: layer => dispatch(removeLayer(layer)),
    closeSocket: (type, name) => dispatch(closeSocketConnection(type, name)),
    selectMapboxLayer: layer => dispatch(selectMapboxLayer(layer)),
    setLayerDataAccessor: layer => dispatch(setLayerDataAccessor(layer)),
    toggleLayerDataAccessorVisibility: bool =>
      dispatch(toggleLayerDataAccessorVisibility(bool)),
    closeAllAdditionalPanels: () => dispatch(closeAllAdditionalPanels()),
    setSelectedLayerSettings: (type) => dispatch(setSelectedLayerSettingsType(type))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DisplayedLayerItemContainer);
