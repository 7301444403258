import React, { useState } from "react";
import { Input } from "antd";
import { useTranslation } from "react-i18next";
import { Styled } from "./SearchPanel.styled";

const { Search } = Input;

const { ExtendedStyledInput } = Styled;

const SearchPanel = ({ color, ...props }) => {
  const { t } = useTranslation("common");


  return (
    <ExtendedStyledInput color={color}>
      <>
        <Search
          placeholder="Поиск..."
          onChange={({ target: { value } }) => props.search(value)}
        />
      </>
    </ExtendedStyledInput>
  );
};

export default SearchPanel;
