import React from "react";
import Deck from "./Deck";
import {connect} from "react-redux";
import {onMapStateChanged} from "../../../../reducers/map/actions/mapboxActions";
import {clearMapSelectedObject} from "../../../../reducers/map/actions/mapSelectedObjectActions";
import {
    getLastItemInEditableLayersHistory,
    replaceGeometryData,
} from "../../../../reducers/map/mapboxEditableLayersHistory";
import {
    setMapContextMenu,
    toggleMapSelectedObjectInfoVisibility,
} from "../../../../reducers/map/actions/mapPanelsActions";
import {initSetMapSelectedObjectSaga} from "../../../../sagas/map/mapSelectedObjectSaga";
import {replaceDrawDataForStandaloneObject} from "../../../../reducers/map/actions/mapboxEditableStandaloneObjectActions";

/**
 *
 * @type {React.Memo<React.Component>}
 * @param {Function} updateViewState Экшн на изменение контролируемых свойств карты
 * @param {Object} layersData Redux стейт mapboxData !!проверить на ненужность!!
 * @param {Object} enabledLayers Redux Список включенных слоев !!возможно убрать из зависимости и просчитывать в компоненте!!
 * @param {Object} drawData Reudx Объект редактируемой геометрии текущего выбранного объекта
 * @param {Object<Deck.viewState>} viewState Redux Объект контролируемых свойств карты
 * @param {Object<mapboxDefaultMapStylesEnum>} style Redux Объект стиля подложки
 * @param {Object} mapboxTime Redux Объект таймлайна
 * @param {Function} replaceDrawDataForStandaloneObject Колбэк перерисовки геометрии после сохранения
 * @param {Function} reloadMapboxLayerData
 * @param {Object} selectedObject
 * @param {Function} initSetMapSelectedObjectSaga
 * @param {Object} mapRulerMode Режим измерений
 * @param {Function} setContextMenu
 * @param {Object} standaloneEditableObject
 * @param {String} currentMode
 * @param {Boolean} rasterTileEnabled
 * @param {String<rasterTileStylesEnum>} rasterTileStyle
 * @returns {React.Component} <Deck />
 * @see Deck
 */
//Переменная нужна для отслеживания блокировки глобального эффекта клика по карте (баблится при клике на объект слоя)
var stoppedEvent = false;

const DeckContainer = React.memo(
    ({
         updateViewState,
         layersData,
         enabledLayers,
         drawData,
         viewState,
         style,
         mapboxTime,
         replaceDrawDataForStandaloneObject,
         replaceGeometryData,
         selectedObject,
         initSetMapSelectedObjectSaga,
         mapRulerMode,
         setContextMenu,
         standaloneEditableObject,
         currentMode,
         rasterTileEnabled,
         rasterTileStyle,
         toggleMapSelectedObjectInfoVisibility,
         clearMapSelectedObject,
         recalculateId




     }) => {


        const onMapClickHandler = (layerEvent, mouseEvent) => {
            if (!stoppedEvent) {
                if (mouseEvent.leftButton) {
                    clearMapSelectedObject()
                    toggleMapSelectedObjectInfoVisibility(false)

                } else if (mouseEvent.rightButton) {
                    const {x, y} = mouseEvent.center;
                    const activeLayer = enabledLayers.filter((el) => el.isSelected);
                    setContextMenu({
                        x: x - 230,
                        y,
                        visible: true,
                        clickedObjectName: "map",
                        selected: null,
                        coordinate: null,
                        objectLayerName: activeLayer?.[0]?.name,
                    });
                }
            }
            stoppedEvent = false;
        };


        const onClickItemHandler = (
            event,
            currentLayerObjectType,
            currentLayerObjectName
        ) => {
            const isLeftMouseClick = window.event.button === 0;
            const isRightMouseClick = window.event.button === 2;
            if (isLeftMouseClick) {
                initSetMapSelectedObjectSaga(
                    event,
                    currentLayerObjectType,
                    currentMode
                );
            } else if (isRightMouseClick) {
                const {layerX, layerY} = window.event;
                setContextMenu({
                    x: layerX + 20,
                    y: layerY,
                    visible: true,
                    clickedObjectName: currentLayerObjectType,
                    coordinate: event.coordinate,
                    selected: event.object,
                    objectLayerName: currentLayerObjectName,
                });
            }
            stoppedEvent = true;
        };

        return (
            <Deck
                updateViewState={updateViewState}
                viewState={viewState}
                style={style}
                layersData={layersData}
                enabledLayers={enabledLayers}
                onClickItemHandler={onClickItemHandler}
                mapboxTime={mapboxTime}
                replaceGeometryData={replaceGeometryData}
                drawData={drawData}
                selectedObject={selectedObject}
                mapRulerMode={mapRulerMode}
                onMapClickHandler={onMapClickHandler}
                standaloneEditableObject={standaloneEditableObject}
                replaceDrawDataForStandaloneObject={replaceDrawDataForStandaloneObject}
                rasterTileEnabled={rasterTileEnabled}
                rasterTileStyle={rasterTileStyle}
                recalculateId={recalculateId}
            />
        );
    }
);

const mapStateToProps = (state) => {
    return {
        viewState: state?.mapboxView.viewState,
        style: state?.mapboxView.style,
        layersData: state.mapboxData,
        layersHistory: state.mapboxEditableLayersHistory.history,
        selectedObject: state.mapSelectedObject,
        enabledLayers: state.mapboxLayers,
        mapboxTime: state.mapboxTime,
        drawData: getLastItemInEditableLayersHistory(state)?.drawData,
        standaloneEditableObject: state.mapboxEditableStandaloneObject,
        mapRulerMode: state.mapPanels.rulerMode,
        currentMode: state.mapMode.currentMode,
        rasterTileEnabled: state.mapPanels.rasterTileEnabled,
        rasterTileStyle: state.mapPanels.rasterStyle,
        recalculateId: state.app.recalculated
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        updateViewState: (newState) => dispatch(onMapStateChanged(newState)),
        replaceGeometryData: (data) => dispatch(replaceGeometryData(data)),
        toggleMapSelectedObjectInfoVisibility: (bool) =>
            dispatch(toggleMapSelectedObjectInfoVisibility(bool)),
        clearMapSelectedObject: () => dispatch(clearMapSelectedObject()),
        initSetMapSelectedObjectSaga: (data, type, mapMode) =>
            dispatch(initSetMapSelectedObjectSaga(data, type, mapMode)),
        setContextMenu: ({
                             x,
                             y,
                             visible,
                             clickedObjectName,
                             selected,
                             coordinate,
                             objectLayerName,
                         }) =>
            dispatch(
                setMapContextMenu({
                    x,
                    y,
                    visible,
                    clickedObjectName,
                    selected,
                    coordinate,
                    objectLayerName,
                })
            ),
        replaceDrawDataForStandaloneObject: (data) =>
            dispatch(replaceDrawDataForStandaloneObject(data)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(DeckContainer);
