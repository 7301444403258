import {RelatedDataPrototype} from "../prototype/RelatedDataPrototype";
import {mapObjectsEnum} from "../../../../../../enums/mapObjectsEnum";
import {dataAccessMethodsEnum} from "../../../../../../enums/dataAccessMethodsEnum";

export class DockSegment extends RelatedDataPrototype {

    constructor() {
        super({
            name: 'dock_segment',
            filterLabel: 'Причал',
            urlData: '/layer_roads/docks-segments/',
            selectedObjectName:  mapObjectsEnum.dockSegment,
            // dataAccessMethod: dataAccessMethodsEnum.custom // нужно было для проброса даты в запрос

        });
    }
}
