import React, {useContext} from "react";
import MapToolBar from "./MapToolBar";
import { saveAs } from 'file-saver';
import printJS from 'print-js'
import {MapModuleContext} from "../moduleContext/withMapModuleContext";

const MapToolBarContainer = ({showTimePlayer}) => {
    const mapsContext = useContext(MapModuleContext)
    const handlePrint = () => {
        const mapRef = mapsContext.mapRef
        const mapCanvas = document.querySelector('.mapboxgl-canvas')
        // const mapCanvas = mapRef.getCanvas()
        const deckCanvas = document.querySelector('#deckgl-overlay')
        const uploadedCanvas = document.createElement('canvas')
        uploadedCanvas.width = mapCanvas.width
        uploadedCanvas.height = mapCanvas.height
        const uploadedCanvasContext = uploadedCanvas.getContext('2d');
        uploadedCanvasContext.drawImage(mapCanvas,0,0)
        uploadedCanvasContext.drawImage(deckCanvas,0,0)
        // option to save as file
        // uploadedCanvas.toBlob((blob) => {
        //     saveAs(blob, "merged.png");
        // })
        printJS({
            printable:uploadedCanvas.toDataURL(),
            type: 'image',
            showModal: true
        })
    }
    return (
        <MapToolBar showTimePlayer={showTimePlayer} handlePrint={handlePrint} />
    )
}

export default MapToolBarContainer
