import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import PathWatcher from "./PathWatcher";
import { Switch, useLocation } from "react-router-dom";
import { directoryHistoryActions } from "../../../../../reducers/directory/actions/directoryHistoryActions";
const { postToInlineHistory, clearInlineHistory } = directoryHistoryActions;

const PathWatcherContainer = ({
  inlineHistory,
  pushInlineHistory,
  cleanInlineHistory,
}) => {
  const location = useLocation();
  return (
    <PathWatcher
      location={location}
      inlineHistory={inlineHistory}
      pushInlineHistory={pushInlineHistory}
      cleanInlineHistory={cleanInlineHistory}
    />
  );
};

PathWatcherContainer.propTypes = {};

const mapStateToProps = (state) => {
  return {
    inlineHistory: state.directoryHistory,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    pushInlineHistory: (historyRecord) =>
      dispatch(postToInlineHistory(historyRecord)),
    cleanInlineHistory: () => dispatch(clearInlineHistory()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PathWatcherContainer);
