import { dataAccessMethodsEnum } from "../../../../../../enums/dataAccessMethodsEnum";
import { basePath } from "../../../layersRegistrator";

export class MainDataHttpPrototype {
  basePathTemplate = basePath;

  dataAccessMethod = dataAccessMethodsEnum.httpGetAll;

  dataKeyForIds;

  urlData = null;

  additionalParameters;

  constructor({
    urlData,
    dataKeyForIds = "id",
    dataAccessMethod = dataAccessMethodsEnum.httpGetAll,
    ...other
  }) {
    this.urlData = this.basePathTemplate + urlData;
    this.dataKeyForIds = dataKeyForIds;
    this.dataAccessMethod = dataAccessMethod;
    this.additionalParameters = other;
  }

  getAdditionalParameters() {
    return this.additionalParameters;
  }

  getDataAccessMethod() {
    return this.dataAccessMethod;
  }

  getDataKeyForIds() {
    return this.dataKeyForIds;
  }

  getUrlData() {
    return this.urlData;
  }
}
