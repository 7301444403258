import {CargoRepresentationDataAccessor} from "./CargoRepresentationDataAccessor";
import {RoadsDataAccessor} from "./RoadsDataAccessor";
import {CargoLocationPointsDataAccessor} from "./CargoLocationPointsDataAccessor";
import {RailwayNetworkDataAccessor} from "./RailwayNetworkDataAccessor";
import {TrafficSegmentsDataAccessor} from "./TrafficSegmentsDataAccessor";


export const nipigazLayerDataAccessors = {
    CargoRepresentationDataAccessor,
    RoadsDataAccessor,
    CargoLocationPointsDataAccessor,
    RailwayNetworkDataAccessor,
    TrafficSegmentsDataAccessor
}

