import React from "react";
import useGlobalHook from "use-global-hook";
import * as actions from "../actions";

const initialState = {
  name: null,
  email: null,
  headers: null,
  owner: null,
  modelId: null,
  grzId: null,
  select: null,
  timeStart: null,
  filteredBelongGRZToCar: null,
};

const useGlobal = useGlobalHook(React, initialState, actions);

export default useGlobal;
