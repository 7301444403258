import styled from "styled-components";

const MSFullHeightWrap = styled.section`
  height: 100%;
`;

export const Styled = {
  MSFullHeightWrap,
};

export { Styled as MainStyled };
