import styled from "styled-components";

const LocalMainWrapper = styled.section`
  & > *:first-of-type {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
  & > *:not(:first-of-type):not(:last-of-type) {
    border-radius: 0;
  }
  & > *:last-of-type {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  } 
`

const LocalCardHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex: 1;
  width: 100%;
`

const LocalCardHeaderContentGroup = styled.article`
    display: flex;
    margin: 0 -3px;
    & > * {
      margin: 0 3px;
    }
`

const LocalCardHeaderContentItem = styled.div``

export const Styled = {LocalMainWrapper, LocalCardHeader, LocalCardHeaderContentGroup, LocalCardHeaderContentItem}