import React from "react";
import { Icon } from "antd";

const MapIconSVG = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.5 2.5L2.8 4.08333C2.625 4.14167 2.5 4.29167 2.5 4.48333V17.0833C2.5 17.1938 2.5439 17.2998 2.62204 17.378C2.70018 17.4561 2.80616 17.5 2.91667 17.5L3.05 17.475L7.5 15.75L12.5 17.5L17.2 15.9167C17.375 15.8583 17.5 15.7083 17.5 15.5167V2.91667C17.5 2.80616 17.4561 2.70018 17.378 2.62204C17.2998 2.5439 17.1938 2.5 17.0833 2.5L16.95 2.525L12.5 4.25L7.5 2.5ZM6.66667 4.54167V14.2917L4.16667 15.2583V5.38333L6.66667 4.54167ZM8.33333 4.55833L11.6667 5.725V15.4417L8.33333 14.275V4.55833ZM15.8333 4.75V14.6167L13.3333 15.4583V5.71667L15.8333 4.75ZM6.21667 5.25L4.64167 5.80833V7.6L6.21667 7.04167V5.25ZM6.21667 7.54167L4.64167 8.1V9.89167L6.21667 9.33333V7.54167ZM6.21667 9.83333L4.64167 10.3917V12.1833L6.21667 11.625V9.83333ZM6.21667 12.125L4.64167 12.6833V14.475L6.21667 13.9167V12.125Z"
      fill="currentColor"
    />
  </svg>
);

export const MapIcon = props => <Icon component={MapIconSVG} {...props} />;
