import styled from "styled-components";
import { Input } from "antd";
import { Select } from "antd";
import { Styled as styledMixins } from "../../UI/styleMixins/styleMixins.styled";

const { CutStringWithEllipsisOnTheEnd } = styledMixins.text;

function getBaseStylesForInput(inputType) {
  return styled(inputType)`
    color: ${props => props.theme.colors.defaultDarker};
    background: ${props => props.theme.colors.backgroundNeutral};
    border: none;
    height: 4.2rem;
    border-radius: 4px;
    padding-top: 1.85rem;
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    &::placeholder {
      color: transparent;
    }
    &:focus,
    &:hover {
      border-color: transparent;
      outline: none;
      box-shadow: none;
    }
  `;
}

const BaseInput = getBaseStylesForInput(Input);

const BasePlaceholder = styled("span")`
  color: ${props => props.theme.colors.paragraph};
  text-align: left;
  user-select: none;
  pointer-events: none;
  z-index: 100;
  font-size: 1.15rem;
  position: absolute;
  top: -24px;
  left: 10px;
  transition: all 0.2s ease;
  width: 90%;
  ${CutStringWithEllipsisOnTheEnd}
  &.opened {
    transform: translateY(-9px);
    font-size: 0.85rem;
  }
`;

const BaseErrorMessage = styled("p")`
  color: ${props => props.theme.colors.danger};
  text-align: left;
  font-size: 0.9rem;
  line-height: 1.5rem;
  padding: 0;
  margin: 0;
  height: auto;
`;

const BaseSelectInput = styled(Select)`
  height: 60px;
  background: ${props => props.theme.colors.backgroundNeutral};
  border-radius: 4px;
  border: none;

  .antd-options {
    top: 20px;
  }

  .ant-select-selection {
    height: 60px;
    background: ${props => props.theme.colors.backgroundNeutral};
    border-radius: 4px;
    border: none;

    &-selected-value {
      padding-top: 10px;
    }
    &__rendered {
      height: 60px;
      padding-top: 1rem;
    }
    &__placeholder {
      color: transparent;
    }
  }

  .ant-select-search {
  }

  .ant-input.ant-select-search__field {
    height: 60px;
    background: ${props => props.theme.colors.backgroundNeutral};
    border-radius: 4px;

    &::placeholder {
      color: transparent;
    }
  }
`;

const BaseSelectPlaceholder = styled(BasePlaceholder)`
  top: 18px;
  line-height: 1.6rem;
  width: 80%;
  text-align: left;
  ${CutStringWithEllipsisOnTheEnd}
`;

const BaseSelectInputWrap = styled.div`
  position: relative;
  .custom-dropdown {
    top: 60px !important;
  }
`;

export const Styled = {
  BaseInput,
  BasePlaceholder,
  getBaseStylesForInput,
  BaseErrorMessage,
  BaseSelectInput,
  BaseSelectPlaceholder,
  BaseSelectInputWrap
};
