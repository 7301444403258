import React from "react";
import LayerTemplatesTab from "./LayerTemplatesTab";
import { connect } from "react-redux";

// const LayerTemplatesTabContainer = ({}) => {
const LayerTemplatesTabContainer = () => {
  return (
    <div>
      <LayerTemplatesTab />
    </div>
  );
};

LayerTemplatesTabContainer.propTypes = {};

const mapStateToProps = () => {
  return {};
};

const mapDispatchToProps = () => {
  return {};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LayerTemplatesTabContainer);
