import React from "react";
import { Icon } from "antd";

const VisibilityIconSVG = () => (
  <svg
    width="22"
    height="16"
    viewBox="0 0 22 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M21.0828 7.39531C18.861 2.71484 15.5024 0.359375 11 0.359375C6.49533 0.359375 3.13908 2.71484 0.917201 7.39766C0.828081 7.58637 0.78186 7.79247 0.78186 8.00117C0.78186 8.20987 0.828081 8.41598 0.917201 8.60469C3.13908 13.2852 6.49767 15.6406 11 15.6406C15.5047 15.6406 18.861 13.2852 21.0828 8.60234C21.2633 8.22266 21.2633 7.78203 21.0828 7.39531ZM11 13.9531C7.21954 13.9531 4.45158 12.0359 2.49923 8C4.45158 3.96406 7.21954 2.04688 11 2.04688C14.7805 2.04688 17.5485 3.96406 19.5008 8C17.5508 12.0359 14.7828 13.9531 11 13.9531ZM10.9063 3.875C8.62814 3.875 6.78126 5.72188 6.78126 8C6.78126 10.2781 8.62814 12.125 10.9063 12.125C13.1844 12.125 15.0313 10.2781 15.0313 8C15.0313 5.72188 13.1844 3.875 10.9063 3.875ZM10.9063 10.625C9.45548 10.625 8.28126 9.45078 8.28126 8C8.28126 6.54922 9.45548 5.375 10.9063 5.375C12.357 5.375 13.5313 6.54922 13.5313 8C13.5313 9.45078 12.357 10.625 10.9063 10.625Z"
      fill="currentColor"
    />
  </svg>
);

export const VisibilityIcon = props => <Icon component={VisibilityIconSVG} {...props} />;
