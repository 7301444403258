import React from "react";
import DisplayedLayers from "./DisplayedLayers";
import { connect } from "react-redux";

const DisplayedLayersContainer = ({
  enabledLayers,
  ...others
}) => {
  return (
    <>
      <DisplayedLayers
        {...others}
        enabledLayers={enabledLayers}
      />
    </>
  );
};

const mapStateToProps = state => {
  return {
    enabledLayers: state.mapboxLayers,
  };
};

const mapDispatchToProps = dispatch => {
  return {};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DisplayedLayersContainer);
