import { useEffect, useState } from "react";
import * as AllModules from "ag-grid-enterprise";
import ru from "../../i18n/aggrid/ru";
import ObjectCellRenderer from "../../library/compendiumsRenderers/ObjectCellRenderer";
import BooleanCellRenderer from "../../library/compendiumsRenderers/BooleanCellRenderer";
import { defineColumnsDefinitions } from "../../services/application/schemaManipulation/defineColumnsDefinitions";
import LinkCellRenderer from "../../library/compendiumsRenderers/LinkCellRenderer";

export function useAgGridSettings(
  data,
  model,
  containerCallbacks,
  cellSizes = { minWidth: 70, width: 200, maxWidth: 600 }
) {
  const settings = {
    modules: AllModules,
    defaultColDef: {
      resizable: true,
      enableValue: true,
      enableRowGroup: true,
      enablePivot: true,
      sortable: true,
      filter: true,
      minWidth: cellSizes.minWidth,
      maxWidth: cellSizes.maxWidth,
      width: cellSizes.width,
      onCellDoubleClicked: containerCallbacks?.handleProvideData,
      onCellClicked: containerCallbacks?.handleSelectRow
    },
    columnDefs: null,
    rowData: null,
    pivotRowTotals: "before",
    rowGroupPanelShow: "always",
    localeText: ru,
    singleClickEdit: true,
    context: { componentParent: this },
    onFilterChanged: containerCallbacks?.onFilterChanged,
    frameworkComponents: {
      objectCellRenderer: ObjectCellRenderer,
      booleanCellRenderer: BooleanCellRenderer,
      linkCellRenderer: LinkCellRenderer,
    },
    ...containerCallbacks.additionalCallbacks
  };
  const [columnsData, setColumnsData] = useState(null);
  const [agSettings, setAgSettings] = useState(settings);

  useEffect(() => {
    const definedColumns = defineColumnsDefinitions(model);
    setAgSettings({
      ...agSettings,
      columnDefs: definedColumns.result,
      rowData: data,
      detailCellRendererParams: {
        detailGridOptions: {
          columnDefs: definedColumns.nested,
          defaultColDef: {
            resizable: true
          }
        }
      },
      sideBar: {
        toolPanels: [
          {
            id: "columns",
            labelDefault: "Columns",
            labelKey: "columns",
            iconKey: "columns",
            toolPanel: "agColumnsToolPanel"
          },
          {
            id: "filters",
            labelDefault: "Filters",
            labelKey: "filters",
            iconKey: "filter",
            toolPanel: "agFiltersToolPanel"
          }
        ],
        defaultToolPanel: "filters",
        hiddenByDefault: true
      }
    });
    setColumnsData(definedColumns);
  }, [data, containerCallbacks, model]);

  return {
    agSettings,
    setAgSettings,
    columnsData
  };
}
