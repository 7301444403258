import wkt from "wkt";
import {getCoords} from "@turf/turf";

export function getCoordsFromParsedGeometry(linePath) {
    //linePath should be geojson object
    try {
        const geometry = wkt.parse(linePath);
        return getCoords(geometry);
    }
    catch (e) {
        console.log(e, ' error in getCoordsFromParsedGeometry(), maybe input data is not valid')
    }
}

export function getCoordsFromParsedGeometryNearRoad(linePath) {
    //linePath should be geojson object
    console.log(linePath)
    try {
        const geometry = wkt.parse(linePath);
        console.log(geometry)
        console.log(geometry.coordinates)
        const getFirstCoords = geometry.coordinates[0]
        const getSecondCoords = geometry.coordinates[1]
        const getXCoordsWithNear = getFirstCoords + 10
        return getCoords(geometry);
    }
    catch (e) {
        console.log(e, ' error in getCoordsFromParsedGeometry(), maybe input data is not valid')
    }
}