import React from "react";
import { addMapboxData } from "../../../../../reducers/map/actions/mapboxDataActions";
import { connect } from "react-redux";
import LayersBar from "./LayersBar";

const LayersBarContainer = () => {
  //TODO async REST handler

  return <LayersBar />;
};

const mapStateToProps = () => {
  return {};
};

const mapDispatchToProps = dispatch => {
  return {
    addDataSource: (layer, data) => dispatch(addMapboxData(layer, data))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LayersBarContainer);
