import React from "react";
import "react-resizable/css/styles.css"
import "react-grid-layout/css/styles.css"
import "ag-grid-community/dist/styles/ag-grid.css";
import {Redirect, Route, Switch, withRouter,} from "react-router-dom";
import {Provider} from "react-redux";
import {compose} from "redux";
import {history, store} from "../../store/configureStore";
import storeProvider from "../../store/storeProvider";
import {ThroughProvider} from "react-through";
import Application from "../Application/Application";
import {ThemeProvider} from "styled-components";
import {theme} from "../../library/themes/rootTheme";
import GuestPage from "../GuestPage/GuestPage";
import Page404 from "../Page404/Page404";
import {ConnectedRouter} from 'connected-react-router';

/**
 * Базовый роутинг приложения
 * Подключает один из модулей приложения, а также сожержит редиректы на main и guest
 * @return Application || GuestPage || Page404
 * @see Application
 * @see GuestPage
 * @see Page404
 */
const App = () => {
  return (
    <div className="App" onContextMenu={event => event.preventDefault()}>
      <Switch className={"content"}>
        <Route path="/app" component={Application} />
        <Route path="/404" component={Page404} />
        <Route path="/guest" component={GuestPage} />
        <Redirect
          exact
          from="/password_recovery/:uid/:token"
          to={"guest/password_recovery/:uid/:token"}
        />
        <Redirect exact from={"/"} to={"/guest"} />
        <Redirect exact from={""} to={"/guest"} />
      </Switch>
    </div>
  );
};

let AppContainer = compose(
  withRouter
)(App);

/**
 * Подключение глобальных провайдеров к роутеру
 * @see App
 */
const AppEntryPoint = () => {
  storeProvider.init(store);
  return (
       // Redux
    <Provider store={store}>
        {/*React-Router*/}
      <ConnectedRouter history={history}>
          {/*React through for breadcrumbs*/}
      <ThroughProvider>
          {/*Styled components styles and themes */}
          <ThemeProvider theme={theme}>
            <AppContainer />
          </ThemeProvider>
      </ThroughProvider>
      </ConnectedRouter>
    </Provider>
  );
};

export default AppEntryPoint;
