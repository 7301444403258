import { LayerDataAccessorPrototype } from "../prototype/LayerDataAccessorPrototype";
import { dataAccessMethodsEnum } from "../../../../../../enums/dataAccessMethodsEnum";
import React from "react";

const LazyGetTrackingInfoDataAccessButton = React.lazy(() =>
    import(
        "../../../../../../library/actionsButtons/NIPIGAZ/Map/dataAccessors/customForLayers/GetTrackingInfoDataAccessButton"
        )
);
const RefreshDataButtonOnMap = React.lazy(() => import('../../../../../../library/actionsButtons/NIPIGAZ/Map/dataAccessors/customForLayers/refreshButtons/RefreshTrackingSchemaOnMapButton'))
export class TrackingDataAccessor extends LayerDataAccessorPrototype {
  actionButtons = [LazyGetTrackingInfoDataAccessButton, RefreshDataButtonOnMap]

  constructor() {
    super({
      urlData: "/vehicles/",
      dataAccessMethod: dataAccessMethodsEnum.httpGetAll,
      dataKeyForIds: "vehicle_id",
      i18LabelPath: "vehicles",
    });
  }

}
