import React from 'react';
import PropTypes from 'prop-types';

const LayersSearchBar = props => {
    return (
        <div>

        </div>
    );
};

LayersSearchBar.propTypes = {

};

export default LayersSearchBar;
