import styled from "styled-components";

const LocalMainWrapper = styled.div`
  //width: 315px;
  //height: 435px;
  //background: #f1f3f6;
  //border-radius: 8px;
`;

const LocalInnerWrapper = styled.div`
  width: 315px;
  height: 435px;
`;

const LocalCheckboxWrapper = styled.div`
  padding: 1.1rem;

  & path {
    color: #008291;
    width: 15px;
    height: 15px;
  }
  & span {
    color: #495e5f;
  }
`;

const LocalSelectWrapper = styled.div`
  & :focus {
    background: white;
    border-radius: 8px;
  }
 
  & button svg {
    width: 13px;
    height: 18px;
  }
`;

const LocalScrollableWrapper = styled.div`
  height: auto;
  overflow-y: auto;
`;
export const Styled = {
  LocalMainWrapper,
  LocalInnerWrapper,
  LocalCheckboxWrapper,
  LocalSelectWrapper,
  LocalScrollableWrapper,
};
