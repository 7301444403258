import React from "react";
import styled from "styled-components";
import {useTranslation} from "react-i18next";


const LocalMainWrapper = styled.div`
    display: flex;
    align-items: center;
    margin: 17px 22px 13px 22px;
`

const NamePage = (props) => {
    const { router } = props;
    const { pathname } = router?.location || '';

    const paths = pathname.split('/').filter(i => i);
    const secondPath = paths[1];

    const { t } = useTranslation("common");
    let title = t('app.header.searchNamePage');

    if (secondPath) {
      switch (secondPath) {
        case 'map':
          title = t('app.header.namePageMaps');
          break;
        case 'directory':
          title = t('app.header.namePageDirectory');
          break;
        default:
          title = t('app.header.searchNamePage');
          break;
      }
    } else {
      title = t('app.header.searchNamePage');
    }

    return (
        <LocalMainWrapper>
            <div>
                <h1>{title}</h1>
            </div>
        </LocalMainWrapper>
    )
}

export default NamePage
