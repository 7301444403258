import {RelatedDataPrototype} from "../prototype/RelatedDataPrototype";
import {mapObjectsEnum} from "../../../../../../enums/mapObjectsEnum";


export class Geozones extends RelatedDataPrototype {

    constructor() {
        super({
            name: 'geozones',
            filterLabel: 'Геозоны',
            urlData: '/geozones/',
            selectedObjectName: mapObjectsEnum.geozone,
        });
    }
}
