import React, {useRef, useState} from "react";
import StandaloneEditModeFormPanel from "./StandaloneEditModeFormPanel";
import { connect } from "react-redux";
import { clearStandaloneEditModeObject } from "../../../../../reducers/map/actions/mapboxEditableStandaloneObjectActions";
import { cropPath } from "../../../../../services/net/cropPath";
import { submitObjectSaga } from "../../../../../sagas/app/postObjectSaga";
import { reloadMapboxLayerData } from "../../../../../reducers/map/actions/mapboxDataActions";
import moment from "moment";
import turfLength from "@turf/length";
import turfArea from "@turf/area";
import wkt from "wkt";
import { resolveEditableObjectDescription } from "../../../../../registrators/old/editableObjectsRegistrator/editableObjectsRegistrator";
import {
  DrawLineStringMode,
  DrawPointMode,
  DrawPolygonMode,
} from "@nebula.gl/edit-modes";
import {
  defineDrawKeyOnModel,
  defineDrawModeOnModel,
} from "../../../../../services/map/defineDrawMapOnModel";
import turfLineSlice from "@turf/line-slice";
import turfNearestPoint from "@turf/nearest-point-on-line";
import _ from "underscore";
import {
  clearPromptData,
  setGlobalAlertData,
} from "../../../../../reducers/app/actions/notificationActions";
import { alertTypesEnum } from "../../../../../enums/alertTypesEnum";
import { alertStatusesEnum } from "../../../../../enums/alertStatusesEnum";
import { clearMapContextMenu } from "../../../../../reducers/map/actions/mapPanelsActions";
import {
  initGetRelatedDataForLayer,
  watchGetRelatedDataForLayerSaga
} from "../../../../../sagas/map/getRelatedDataForLayerSaga";
import {getRegisteredLayer} from "../../../../../registrators/map/layers/layersRegistrator";

let errorMessage = "Произошла ошибка при отправке формы, проверьте правильность заполненных данных"

function localResolveAdditionalGeometryData(
  geometryKey,
  geometryValue,
  editableStandaloneObject
) {
  const result = [];
  const { model } = editableStandaloneObject;
  const { scheme } = model;
  for (let key in scheme) {
    const { type } = scheme[key];
    switch (type) {
      case "start value for objects with geometry": {
        const { coordinates } = geometryValue.geometry;
        const firstPoint = _.first(coordinates);
        if (editableStandaloneObject?.parentData) {
          const { parentData } = editableStandaloneObject;
          const { line_path } = parentData;
          const line = wkt.parse(line_path);
          const nearest = turfNearestPoint(line, firstPoint);
          const { location } = nearest.properties;
          result.push({
            key,
            value: location,
          });
        }
        break;
      }
      case "end value for objects with geometry": {
        const { coordinates } = geometryValue.geometry;
        const lastPoint = _.last(coordinates);
        if (editableStandaloneObject?.parentData) {
          const { parentData } = editableStandaloneObject;
          const { line_path } = parentData;
          const line = wkt.parse(line_path);
          const nearest = turfNearestPoint(line, lastPoint);
          const { location } = nearest.properties;
          result.push({
            key,
            value: location,
          });
        }
        break;
      }
    }
  }

  if (geometryKey === "line_path" && geometryValue) {
    result.push({
      key: "total_length",
      value: turfLength(geometryValue).toFixed(3),
    });
  } else if (geometryKey === "polygon" && geometryValue) {
    //area in square meters
    result.push({ key: "area_size", value: turfArea(geometryValue) });
  }
  return result;
}

const StandaloneEditModeFormPanelContainer = ({
  clearMapContextMenu,
  editableStandaloneObject,
  clearStandaloneEditModeObject,
  startPostWithSaga,
  reloadMapboxLayerData,
  setGlobalAlertData,
  clearPromptData,
                                                initGetRelatedDataForLayer,

}) => {

  const [dateTime, setTime] = useState()
  const formRef = useRef(null);
  let handleRejectObjectEditing = () => {
    clearStandaloneEditModeObject();
    clearMapContextMenu();
    clearPromptData();
  };


  const writeTimetoState = (value) => {
    setTime(value)
  }
  const initSubmitForm = () => {
    formRef.current.props.onSubmit();
    clearMapContextMenu();
  };
  const handleSubmitForm = (data) => {
    errorMessage = "Произошла ошибка при отправке формы, проверьте правильность заполненных данных"
    handleRejectObjectEditing = () => {
      clearStandaloneEditModeObject();
      clearMapContextMenu();
      clearPromptData();
    }
    if (editableStandaloneObject.drawData?.features?.length===0) {
      errorMessage = "Необходимо добавить геометрию"
      handleRejectObjectEditing = () => {
        clearPromptData();
      }
    }
      try {
        let writableGeometry = editableStandaloneObject.drawData.features[0];
        const isSnap = resolveEditableObjectDescription(
            editableStandaloneObject.selectedInstance,
            "parent"
        )?.snapChildGeometry;
        if (isSnap) {
          const mode = editableStandaloneObject.editMode;
          const parentLinePath = editableStandaloneObject.parentData;
          const parentGeometryKey = defineDrawKeyOnModel(
              editableStandaloneObject.parentModel
          );
          if (mode == DrawLineStringMode) {
            const parentMode = defineDrawModeOnModel(
                editableStandaloneObject.parentModel
            );
            // if parent mode is linestring
            if (typeof parentMode === typeof DrawLineStringMode) {
              const coords = writableGeometry.geometry.coordinates;
              const snapGeometry = wkt.parse(
                  editableStandaloneObject.parentData[parentGeometryKey]
              );
              const firstPoint = _.first(coords);
              const lastPoint = _.last(coords);
              writableGeometry = turfLineSlice(
                  firstPoint,
                  lastPoint,
                  snapGeometry
              );
            }
          }
          if (mode == DrawPointMode) {
          }
          if (mode == DrawPolygonMode) {
          }
        }
        const {geometryKey} = editableStandaloneObject;
        const geometryValue = writableGeometry;
        const additionalGeometryData = localResolveAdditionalGeometryData(
            geometryKey,
            editableStandaloneObject.drawData.features[0],
            editableStandaloneObject
        );

        const userTZ = Intl.DateTimeFormat().resolvedOptions().timeZone;
        const startDate = moment.tz(dateTime.datetime_start, userTZ)
        const endDate = moment.tz(dateTime.datetime_end, userTZ)


        const dataToPost = {
          ...data,
          [geometryKey]: wkt.stringify(geometryValue),
          id: editableStandaloneObject?.instance?.id,
          // Todo may be need refactoring
          datetime_start: startDate,
          datetime_end: endDate

        };

        if (additionalGeometryData && additionalGeometryData.length) {
          for (let record of additionalGeometryData) {
            const {key, value} = record;
            dataToPost[key] = value;
          }
        }

        // bad method reload data
        const LayerInstance = getRegisteredLayer("roadsSchema");
        initGetRelatedDataForLayer(LayerInstance)
        // bad method reload data
        startPostWithSaga(
            cropPath(editableStandaloneObject.urlData),
            dataToPost,
            null,
            () =>
                reloadMapboxLayerData(
                    editableStandaloneObject.urlData,
                    editableStandaloneObject.layerName
                ),
            editableStandaloneObject.closureId,
        );


      } catch (e) {
        console.error("form submit error: ", e);
        setGlobalAlertData({
          status: alertStatusesEnum.active,
          type: alertTypesEnum.error,
          title: "Произошла ошибка",
          message:
          errorMessage,
        });
      } finally {
        handleRejectObjectEditing();
      }

  };
  return (
    <>
      <StandaloneEditModeFormPanel
          writeTimetoState={writeTimetoState}
        ref={formRef}
        initSubmitForm={initSubmitForm}
        editableStandaloneObject={editableStandaloneObject}
        handleSubmitForm={handleSubmitForm}
        handleRejectObjectEditing={handleRejectObjectEditing}
      />
    </>
  );
};

function mapStateToProps(state) {
  return {
    editableStandaloneObject: state.mapboxEditableStandaloneObject,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    clearStandaloneEditModeObject: () =>
      dispatch(clearStandaloneEditModeObject()),
    startPostWithSaga: (url, data, initial, redrawDispatch, test) =>
      dispatch(submitObjectSaga(url, data, initial, redrawDispatch, test)),
    reloadMapboxLayerData: (urlData, layerName) =>
      dispatch(reloadMapboxLayerData(urlData, layerName)),
    setGlobalAlertData: ({ status, message, title, type }) =>
      dispatch(setGlobalAlertData({ status, message, title, type })),
    clearMapContextMenu: () => {
      dispatch(clearMapContextMenu());
    },
    clearPromptData: () => dispatch(clearPromptData()),
    initGetRelatedDataForLayer: (Layer) => dispatch(initGetRelatedDataForLayer(Layer))
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(StandaloneEditModeFormPanelContainer);
