import {
  CLEAR_STANDALONE_EDIT_MODE_OBJECT,
  REPLACE_DRAW_DATA_IN_STANDALONE_EDIT_MODE,
  TOGGLE_STANDALONE_EDIT_MODE,
} from "../mapboxEditableStandaloneObject";
import { CLEAR_MAP_CONTEXT_MENU } from "../mapPanels";
import { CLEAR_NOTIFICATION_DATA } from "../../app/notifications";

export const replaceDrawDataForStandaloneObject = (data) => {
  return (dispatch, getState) => {
    const isSetRedrawLine = () =>
      getState().mapboxEditableStandaloneObject.drawData.features;
    dispatch({
      type: REPLACE_DRAW_DATA_IN_STANDALONE_EDIT_MODE,
      payload: {
        data,
      },
    });

    if (isSetRedrawLine().length) {
      dispatch({
        type: CLEAR_NOTIFICATION_DATA,
      });
    }
  };
};

export const toggleStandaloneEditMode = () => ({
  type: TOGGLE_STANDALONE_EDIT_MODE,
});

export const clearStandaloneEditModeObject = () => {
  return (dispatch, getState) => {

    const { mapPanels } = getState();
    dispatch({
      type: CLEAR_STANDALONE_EDIT_MODE_OBJECT
    })

    !!mapPanels?.mapContextMenu &&
    dispatch({
      type: CLEAR_MAP_CONTEXT_MENU,
    })

  }
}


