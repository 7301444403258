import {
  ADD_MAPBOX_LAYER,
  CHANGE_LAYER_ORDER,
  TOGGLE_HIDE_LAYER,
  TOGGLE_LAYER_SETTINGS,
  REMOVE_MAPBOX_LAYER,
  SELECT_MAPBOX_LAYER,
  CHANGE_LAYER_SETTING, REVERT_TO_DEFAULT_LAYER_SETTINGS
} from "../mapboxLayers";

export const addLayer = Layer => ({
  type: ADD_MAPBOX_LAYER,
  payload: {Layer}
});

export const removeLayer = layer => ({
  type: REMOVE_MAPBOX_LAYER,
  payload: {
    name:layer
  }
});

export const toggleLayerVisibility = layer => ({
  type: TOGGLE_HIDE_LAYER,
  payload: layer
});

export const toggleLayerSettings = layer => ({
  type: TOGGLE_LAYER_SETTINGS,
  payload: layer
});

export const changeLayerOrder = (layer, newIndex) => ({
  type: CHANGE_LAYER_ORDER,
  payload: {
    layer,
    newIndex
  }
});

export const toggleDataSelectionVisible = (layer, newIndex) => ({
  type: CHANGE_LAYER_ORDER,
  payload: {
    layer,
    newIndex
  }
});

export const selectMapboxLayer = Layer => ({
  type: SELECT_MAPBOX_LAYER,
  payload: {
    Layer
  }
});

export const changeLayerSetting = ({keyOfLayer, keyOfSetting, value}) => ({
  type: CHANGE_LAYER_SETTING,
  payload: {
    keyOfLayer,
    keyOfSetting,
    value
  }
});

export const revertToDefaultLayerSettings = (keyOfLayer) => ({
  type: REVERT_TO_DEFAULT_LAYER_SETTINGS,
  payload: {
    keyOfLayer
  }
})
