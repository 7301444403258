import StringInput from "../../../../library/nonformedInputs/StringInput";
import {UserOutlined} from "@ant-design/icons";
import React from "react";

const StringInputLogin = (props) => {

    return (
        <StringInput
            name='username'
            placeholder="Логин"
            prefix={<UserOutlined/>}
            {...props}
        />
    )
}

export default StringInputLogin

