import React from "react";
import DeckContainer from "./Deck/DeckContainer";
import MapDisplayModeContainer from "./MapDisplayMode/MapDisplayModeContainer";
import withMapModuleContext from "./moduleContext/withMapModuleContext";
import MapStandaloneEditModeContainer from "./MapStandaloneEditMode/MapStandaloneEditModeContainer";

/**
 * Компонент подготовки карты и режимов карты
 * @param {boolean} standaloneEditModeEnabled Включен ли режим редактирования
 * @returns {(MapStandaloneEditModeContainer | MapDisplayModeContainer) & DeckContainer}
 * @see MapStandaloneEditModeContainer
 * @see MapDisplayModeContainer
 * @see DeckContainer
 */
const Mapbox = ({ standaloneEditModeEnabled }) => {
  const currentMapModuleToRender = standaloneEditModeEnabled ? (
    <MapStandaloneEditModeContainer />
  ) : (
    <MapDisplayModeContainer />
  );
  return (
      <div>
        {currentMapModuleToRender}
        <DeckContainer />
      </div>
  );
};

export default withMapModuleContext(Mapbox);
