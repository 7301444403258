import {useEffect} from "react";

/**
 * эффект вызова функций при монтировании и размонитровании компонента
 * @callback fnOnMount функция для вызова при монтировании
 * @callback fnOnUnmount функция для вызова при размонтировании
 */
export function useFnsCallingOnMountAndUnmount(fnOnMount, fnOnUnmount) {
    useEffect(() => {
        fnOnMount()
        return function cleanup() {
            fnOnUnmount()
        }
    }, [])
}
