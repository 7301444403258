import React from "react";
import {Alert} from "antd";
import styled from "styled-components";

const StyledAlert = styled.div`
 position:absolute;
 top: 71%;
 left: -19%;
 width: 354px;
 height: 97px;
 
 @media screen and (max-height: 900px) { 
 top: 90%;
 }
 

.ant-alert-banner {
 box-shadow: 0px 18px 40px rgba(235, 87, 87, 0.08);
 border-radius: 8px;
}

`;

const ErrorMessage = (props) => {

    const {containerCallbacks, message} = props;
    const {handleClose} = containerCallbacks;
    return(

        <StyledAlert>
        <Alert
            message={message}
            banner
            closable
            onClose={handleClose}
        />
        </StyledAlert>
    )
}

export default ErrorMessage;
