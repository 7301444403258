import React, { useCallback, useEffect, useRef, useState } from "react";
import LayersDataAccessor from "./LayersDataAccessor";
import { connect } from "react-redux";
import {
  addMapboxRelatedData,
  getDataForLayer,
  setActiveMapboxDataReplacingForcedStatus,
} from "../../../../../reducers/map/actions/mapboxDataActions";
import {
  toggleMapSelectedObjectInfoVisibility,
} from "../../../../../reducers/map/actions/mapPanelsActions";
import { useAgGridControl } from "../../../../../customHooks/ag-grid/useAgGridControl";
import { changeMapStateZoomForCurrentLocation } from "../../../../../reducers/map/actions/mapboxActions";
import { initMapTableApplyFilterSaga } from "../../../../../sagas/map/mapTableApplyFilterSaga";
import {
  initSetMapSelectedObjectSaga,
  initUpdateMapSelectedObjectSaga,
} from "../../../../../sagas/map/mapSelectedObjectSaga";
import { initializeSingleObjectEditMode } from "../../../../../sagas/map/standaloneObjectEditableSaga";
import { getSelectedMapboxLayer } from "../../../../../reducers/map/mapboxLayers";
import { dataAccessMethodsEnum } from "../../../../../enums/dataAccessMethodsEnum";
import { getFromURL } from "../../../../../api/getFromURL";
import { getRegisteredLayer } from "../../../../../registrators/map/layers/layersRegistrator";
import { setSelectedDataInDataAccessor } from "../../../../../reducers/map/actions/mapDataAccessorSelectedObjectsActions";

const LayersDataAccessorContainer = (props) => {
  const {
    dataAccessPanelVisible,
    // dataAccessPanelRelation,
    allData,
    setLayerDataAccessorVisibility,
    setDateTimeValues,
    selectedMapItem,
    handleSetActiveMapboxDataReplacingForcedStatus,
    isDataReplacingForced,
    initMapTableApplyFilterSaga,
    initSetMapSelectedObjectSaga,
    initializeSingleObjectEditMode,
    selectedMapLayer,
    selectedLayerName,
    setSelectedDataInDataAccessor,
  } = props;

  const [data, setData] = useState(null);
  const [model, setModel] = useState(null);
  const dataAccessPanelRelation = selectedMapLayer.getDataAccessor();

  const {
    quickMenu,
    aggApi,
    handleAGGInit,
    toggleShowFilterMenu,
    toggleShowQuickFilter,
    toggleShowRowsMenu,
  } = useAgGridControl();

  const handleSelectRow = (aggridRow) => {
    if (aggridRow.data && dataAccessPanelRelation) {
      const resolvedAccessor = getRegisteredLayer(
        selectedLayerName
      ).getDataAccessor();
      if (resolvedAccessor.getSelectedChildType()) {
        initSetMapSelectedObjectSaga(
          aggridRow.data,
          resolvedAccessor.getSelectedChildType(),
          selectedLayerName
        );
      } else if (resolvedAccessor.getSelectedObjectType()) {
        initSetMapSelectedObjectSaga(
          aggridRow.data,
          resolvedAccessor.getSelectedObjectType(),
          selectedLayerName
        );
      }
    }
  };

  const handleGoToEditMode = (
    selectedObject,
    typeOfObject,
    objectLayerName,
    initialCoordinate
  ) => {
    initializeSingleObjectEditMode(
      selectedObject,
      typeOfObject,
      objectLayerName,
      initialCoordinate
    );
  };

  const resetFilter = () => {
    aggApi.api.setFilterModel(null)
  }

  const handleRefresh = useCallback(() => {}, []);

  const onFilterChanged = useCallback((ev) => {
    if (ev?.api?.rowModel?.rowsToDisplay?.length) {
      setSelectedDataInDataAccessor(ev?.api?.rowModel?.rowsToDisplay.map(e => e.data));
    }
  }, [setSelectedDataInDataAccessor]);

  const handleApplyData = () => {
    const filteredNodes = aggApi.api.getModel().rootNode.childrenAfterFilter;
    //call saga
    initMapTableApplyFilterSaga(filteredNodes);
  };

  const handleCloseDataPanel = () => {
    setLayerDataAccessorVisibility(false);
  };

  const handleDateRangeChange = (value) => {
    setDateTimeValues(value[0], value[1]);
  };

  const handleSetSelectedRows = (aggApi) => {
    const selectedRows = aggApi.api.getSelectedRows();
    setSelectedDataInDataAccessor(selectedRows);
  };

  let containerCallbacks = {
    handleAGGInit,
    handleRefresh,
    handleSelectRow,
    toggleShowFilterMenu,
    toggleShowRowsMenu,
    toggleShowQuickFilter,
    onFilterChanged,
    handleApplyData,
    handleCloseDataPanel,
    handleSetSelectedRows,
  };

  useEffect(() => {
    if (isDataReplacingForced === true) {
      handleApplyData();
    }
  }, [isDataReplacingForced]);

  useEffect(() => {
    const resolvedDataAccessor = getRegisteredLayer(
      selectedLayerName
    ).getDataAccessor();
    const { dataAccessMethod } = resolvedDataAccessor;
    const { urlData } = resolvedDataAccessor;
    switch (dataAccessMethod) {
      case dataAccessMethodsEnum.httpGetAll: {
        const getDataAsync = async () => {
          const data = await getFromURL.getAll(urlData);
          const model = await getFromURL.getModelFromURL(urlData);
          setData(data?.results || data);
          setModel(model);
        };
        getDataAsync();
        break;
      }
      case dataAccessMethodsEnum.useReduxData: {
        if (resolvedDataAccessor.observeMainData) {
          setData(allData?.data);
          const getModelAsync = async () => {
            const model = await getFromURL.getModelFromURL(
              resolvedDataAccessor.modelUrl
            );
            setModel(model);
          };
          getModelAsync();
        } else {
          setData(
            allData?.[selectedLayerName]?.relatedData?.[
              resolvedDataAccessor.observeRelatedData
            ]
          );
          setModel(
            allData?.[selectedLayerName]?.relatedData?.[
              resolvedDataAccessor.observeRelatedData + "_model"
            ]
          );
        }
      }
    }
  }, [selectedLayerName]);

  return (
    <LayersDataAccessor
      isVisible={dataAccessPanelVisible}
      dataAccessorClass={dataAccessPanelRelation}
      data={data}
      resetFilter={resetFilter}
      model={model}
      aggApi={aggApi}
      quickMenu={quickMenu}
      containerCallbacks={containerCallbacks}
      setLayerDataAccessorVisibility={setLayerDataAccessorVisibility}
      handleDateRangeChange={handleDateRangeChange}
      selectedMapItem={selectedMapItem}
      handleSetActiveMapboxDataReplacingForcedStatus={
        handleSetActiveMapboxDataReplacingForcedStatus
      }
      handleGoToEditMode={handleGoToEditMode}
      selectedMapLayer={selectedMapLayer}
    />
  );
};

LayersDataAccessorContainer.propTypes = {};

const mapStateToProps = (state) => {
  return {
    dataAccessPanelVisible: state.mapPanels.dataAccessorVisible,
    // dataAccessPanelRelation: state.mapPanels.dataAccessor,
    allData: state.mapboxData,
    mapboxTime: state.mapboxTime,
    selectedMapItem: state.mapSelectedObject,
    isDataReplacingForced: state.mapboxData.isDataReplacingForced,
    selectedMapLayer: getSelectedMapboxLayer(state),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setSelectedDataInDataAccessor: (rows) =>
      dispatch(setSelectedDataInDataAccessor(rows)),
    addMapboxRelatedData: (parentKey, childKey, value) =>
      dispatch(addMapboxRelatedData(parentKey, childKey, value)),
    getDataForLayer: (layerName, filteredIds, filteredTime) =>
      dispatch(getDataForLayer(layerName, filteredIds, filteredTime)),
    handleChangeZoomForCurrentLocation: (val) =>
      dispatch(changeMapStateZoomForCurrentLocation(val)),
    handleSetActiveMapboxDataReplacingForcedStatus: () =>
      dispatch(setActiveMapboxDataReplacingForcedStatus()),
    initMapTableApplyFilterSaga: (data) =>
      dispatch(initMapTableApplyFilterSaga(data)),
    toggleMapSelectedObjectInfoVisibility: (bool) =>
      dispatch(toggleMapSelectedObjectInfoVisibility(bool)),
    initSetMapSelectedObjectSaga: (data, type) =>
      dispatch(initSetMapSelectedObjectSaga(data, type)),
    initUpdateMapSelectedObjectSaga: (data, type) =>
      dispatch(initUpdateMapSelectedObjectSaga(data, type)),
    initializeSingleObjectEditMode: (
      selectedObject,
      typeOfObject,
      objectLayerName,
      initialCoordinate
    ) => {
      dispatch(
        initializeSingleObjectEditMode(
          selectedObject,
          typeOfObject,
          objectLayerName,
          initialCoordinate
        )
      );
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LayersDataAccessorContainer);
