import React from 'react';
import {Styled} from "./Panel.styled";
import PropTypes from "prop-types"

const {LocalWrapperPrimary, LocalWrapperSecondary} = Styled

const Panel = ({children, wrapperType, ...other}) => {
    const ResultTag = wrapperType === 'primary' ? LocalWrapperPrimary : LocalWrapperSecondary
    return (
        <ResultTag {...other}>
            {children}
        </ResultTag>
    );
};

export default Panel;

Panel.propTypes = {
  children: PropTypes.any,
  wrapperType: PropTypes.oneOf(["primary", "secondary"])
}

Panel.defaultProps = {
  wrapperType: "primary"
}