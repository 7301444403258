import * as axios from "axios";
import { axiosInstance } from "./axiosGuest";
import { doCleanup } from "../application/auth/doCleanup";
import { CURRENT_APP_URL } from "../../constants/constants";

let axiosTemplate = axios.create({
  baseURL: CURRENT_APP_URL,
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
  },
});

axiosTemplate.interceptors.request.use(
  (config) => {
    if (localStorage.getItem("token_access") !== "undefined") {
      config.headers.Authorization = `Bearer ${localStorage.getItem(
        "token_access"
      )}`;
      return config;
    } else {
      doCleanup();
      return Promise.reject("unauthorised");
    }
  },
  (error) => {
    return Promise.reject(error);
  }
);

const successHandler = (response) => {
  //TODO success messages
  return response;
};

const errorHandler = (error) => {
  if (error?.response?.status == 401) {
    axiosInstance
      .post(
        "/users/token/refresh/",
        {
          refresh: localStorage.getItem("token_refresh"),
          username: localStorage.getItem("user"),
        },
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      )
      .then((response) => {
        localStorage.setItem("token_refresh", response.data.access);
        error.config.Authorization = `Bearer ${localStorage.getItem(
          "token_access"
        )}`;
        securedAxiosInstance(error);
      })
      .catch((error) => {
        doCleanup();
        sessionStorage.setItem("redirect_to", window.location);
        window.location = "/guest/login";

        return Promise.reject(error);
      });
  } else {
  }
};

axiosTemplate.interceptors.response.use(
  (response) => successHandler(response),
  (error) => errorHandler(error)
);

const securedAxiosInstance = axiosTemplate;

export default securedAxiosInstance;
