import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Styled } from "./DischargeZonesSelection.styled";
import { withTheme } from "styled-components";
import { waggonsUnloadingStatusEnums } from "../../../../enums/waggonsUnloadingStatusEnums";
import { useReceptionAreaWaggonsData } from "./utils/hooks/useReceptionAreaWaggonsData";
import Card from "./Parts/Card.jsx";
import { useDischargeZoneWaggonsData } from "./utils/hooks/useDischargeZoneWaggonsData";
import _ from "underscore";
import { postToURLSingle } from "../../../../api/postToURLSingle";
import { layerNamesEnum } from "../../../../enums/layerNamesEnum";

const { LocalMainWrapper } = Styled;

const moduleURLs = {
  receptionAreas: "/railway_network/reception_areas/",
  dischargeZones: "/railway_network/discharge_zones/",
  updateWaggonsData: "/railway_network/waggons/bulk_update_waggons/",
  deleteWaggonsData: "/railway_network/waggons/bulk_delete_waggons/"
};

const initialState = {
  isFetching: false,
  needReloading: false,
  [waggonsUnloadingStatusEnums.NOT_UNLOADED]: {
    data: []
  },
  [waggonsUnloadingStatusEnums.UNLOADING]: {
    data: []
  },
  [waggonsUnloadingStatusEnums.UNLOADED]: {
    data: []
  }
};

const DischargeZonesSelection = props => {
  const {
    theme,
    data,
    handleCloseMapSelectedObjectInfo,
    mode,
    model,
    selectedMapItem,
    reloadMapboxLayerData
  } = props;
  const [moduleState, setModuleState] = useState(initialState);

  const handleChangeFetchingStatus = (bool = false) =>
    setModuleState(prev => ({ ...prev, isFetching: bool }));

  const handleChangeNeedReloadingStatus = bool =>
    setModuleState(prev => ({ ...prev, needReloading: bool }));

  const handleChangeItemSelectStatus = (stateGroup, itemId) => {
    const changedGroupData = moduleState[stateGroup].data.map(item => {
      return item.id === itemId
        ? { ...item, isSelected: !item.isSelected }
        : item;
    });
    setModuleState(prev => ({
      ...prev,
      [stateGroup]: {
        ...prev[stateGroup],
        data: [...changedGroupData]
      }
    }));
  };

  const getSelectedStateItemsId = useCallback(
    desiredUnloadStatus => {
      return moduleState[
        waggonsUnloadingStatusEnums[desiredUnloadStatus]
      ].data.map(item => item.isSelected && item.id);
    },
    [data, moduleState]
  );

  const makeCertainRequestWithSideEffects = useCallback(
    async (url, requestData) => {
      await postToURLSingle.postOrPutData(url, requestData);
      await handleChangeNeedReloadingStatus(true);
      reloadMapboxLayerData(
        moduleURLs.dischargeZones,
        layerNamesEnum.railwayNetwork
      );
      reloadMapboxLayerData(
        moduleURLs.receptionAreas,
        layerNamesEnum.railwayNetwork
      );
    },
    [handleChangeNeedReloadingStatus]
  );

  const handleChangeWaggonsStatusByDischargeZone = (
    prevUnloadStatus,
    newUnloadStatus
  ) => {
    const selectedItemsIds = getSelectedStateItemsId(prevUnloadStatus);
    const requestData = {
      waggon_id: selectedItemsIds,
      discharge_zone_id: data.id,
      status: newUnloadStatus
    };
    makeCertainRequestWithSideEffects(
      moduleURLs.updateWaggonsData,
      requestData
    );
  };

  const handleChangeWaggonsStatusByReceptionArea = (
    prevUnloadStatus,
    newUnloadStatus
  ) => {
    const selectedItemsIds = getSelectedStateItemsId(prevUnloadStatus);
    const requestData = {
      waggon_id: selectedItemsIds,
      reception_area_id: data.reception_area,
      status: newUnloadStatus
    };
    makeCertainRequestWithSideEffects(
      moduleURLs.updateWaggonsData,
      requestData
    );
  };
  const handleDeleteWaggonsByDischargeZone = unloadStatus => {
    const selectedItemsIds = getSelectedStateItemsId(unloadStatus);
    const requestData = {
      status:waggonsUnloadingStatusEnums.FREE,
      waggon_id: selectedItemsIds
    };
    makeCertainRequestWithSideEffects(
        moduleURLs.updateWaggonsData,
        requestData
    );
  };

  useReceptionAreaWaggonsData({
    initialData: data,
    setModuleState,
    receptionAreasUrl: moduleURLs.receptionAreas,
    handleChangeFetchingStatus,
    handleChangeNeedReloadingStatus,
    deps: [data, moduleState.needReloading]
  });

  useDischargeZoneWaggonsData({
    initialData: data,
    handleChangeFetchingStatus,
    handleChangeNeedReloadingStatus,
    dischargeZonesUrl: moduleURLs.dischargeZones,
    setModuleState,
    deps: [data, moduleState.needReloading]
  });

  const cardsPresets = useMemo(
    () => [
      {
        cardHeight: "260px",
        unloadStatus: waggonsUnloadingStatusEnums.NOT_UNLOADED,
        header: {
          text: "Неразгруженные",
          callbacks: {
            onCloseCallback: () => handleCloseMapSelectedObjectInfo()
          }
        },
        content: {
          callbacks: {
            onItemSelectChange: (stateGroup, itemId) =>
              handleChangeItemSelectStatus(stateGroup, itemId)
          }
        },
        footer: {
          callbacks: {
            toDownCallback: () =>
              handleChangeWaggonsStatusByDischargeZone(
                waggonsUnloadingStatusEnums.NOT_UNLOADED,
                waggonsUnloadingStatusEnums.UNLOADING
              )
          }
        }
      },
      {
        cardHeight: "260px",
        unloadStatus: waggonsUnloadingStatusEnums.UNLOADING,
        header: {
          text: "Разгружаются",
          callbacks: {}
        },
        content: {
          callbacks: {
            onItemSelectChange: (stateGroup, itemId) =>
              handleChangeItemSelectStatus(stateGroup, itemId)
          }
        },
        footer: {
          callbacks: {
            toUpCallback: () =>
              handleChangeWaggonsStatusByReceptionArea(
                waggonsUnloadingStatusEnums.UNLOADING,
                waggonsUnloadingStatusEnums.NOT_UNLOADED
              ),
            toDownCallback: () =>
              handleChangeWaggonsStatusByDischargeZone(
                waggonsUnloadingStatusEnums.UNLOADING,
                waggonsUnloadingStatusEnums.UNLOADED
              )
          }
        }
      },
      {
        cardHeight: "260px",
        unloadStatus: waggonsUnloadingStatusEnums.UNLOADED,
        header: {
          text: "Разгружены",
          callbacks: {}
        },
        content: {
          callbacks: {
            onItemSelectChange: (stateGroup, itemId) =>
              handleChangeItemSelectStatus(stateGroup, itemId)
          }
        },
        footer: {
          callbacks: {
            toUpCallback: () =>
              handleChangeWaggonsStatusByDischargeZone(
                waggonsUnloadingStatusEnums.UNLOADED,
                waggonsUnloadingStatusEnums.UNLOADING
              ),
            deleteCallback: () =>
              handleDeleteWaggonsByDischargeZone(
                waggonsUnloadingStatusEnums.UNLOADED
              )
          }
        }
      }
    ],
    [data, moduleState]
  );
  return (
    <LocalMainWrapper>
      {cardsPresets.map(card => (
        <Card
          key={_.uniqueId()}
          styleTheme={theme}
          cardHeight={card.cardHeight}
          moduleState={moduleState}
          headerText={card.header.text}
          unloadStatus={card.unloadStatus}
          footerCallbacks={card.footer.callbacks}
          contentCallbacks={card.content.callbacks}
          headerCallbacks={card.header.callbacks}
        />
      ))}
    </LocalMainWrapper>
  );
};

export default withTheme(DischargeZonesSelection);
