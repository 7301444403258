import {LayerCardPrototype} from "../prototype/LayerCardPrototype";

export class RailwayNetworkCard extends LayerCardPrototype {

    constructor() {
        super({
                i18path: 'railwayNetwork',
                linkToPreview: 'JDSetPredpriyatiya.svg'
            }
        );
    }
}
