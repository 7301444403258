import React from "react";
import LayersGalleryHeader from "./Header/LayerGalleryHeader";
import LayersGalleryContent from "./Content/LayersGalleryContent";
import { baseStyles } from "../../../../../../../../../../stylesRegistrator";

// const {} = baseStyles.modules.map;

const LayersGallery = props => {
  const { items, toggleLayersGalleryVisibility } = props;
  return (
    <>
      <LayersGalleryHeader
        toggleVisibility={toggleLayersGalleryVisibility}
        title={"app.menuMap.galleryLayers"}
      />
      <LayersGalleryContent cards={items} />
    </>
  );
};

export default LayersGallery;
