import React, {Component} from "react";
import {Tag} from "antd";
import {withTranslation} from "react-i18next";
import {Button} from "../UI/components/Button/Button";

class LinkCellRenderer extends Component {
    constructor(props) {
        super(props);

        this.invokeParentMethod = this.invokeParentMethod.bind(this);
    }

    invokeParentMethod() {
        const field = this.props.colDef.additionalData;
        const data = this.props.data[field];
    }

    render() {
        let drawElement = null;
        const {t} = this.props;
        const field = this.props.colDef.additionalData;
        const data = this.props.data[field];
        function onClickHandler() {
            window.location.replace(data)
        }

        drawElement = <Button href={`${data}`} onClick={onClickHandler}>Открыть</Button>
        return (
            <>
                {drawElement}
            </>
        );
    }
};

export default withTranslation('common')(LinkCellRenderer)
