import React, { useEffect, useState } from "react";
import Movement from "./Movement";
import { compose } from "redux";
import withContentPadding from "../../../../../../../HOC/withContentPadding";
import { connect } from "react-redux";
import useGlobal from "../../store";
import { postToURLSingle } from "../../../../../../../api/postToURLSingle";
import { getFromURL } from "../../../../../../../api/getFromURL";

const MovementContainer = (props) => {
  const [globalState, globalActions] = useGlobal();
  const [response, setResponse] = useState([]);

  const sendData = () => {
    async function postData() {
      const response = await getFromURL.getDataFromURL(
        "/reports/device_summary/movement_report/"
      );
      const json = await response;
      const rewriteJson = { ...json };
      const {
        email,
        carId,
        grzId,
        extensions,
        timeStart,
        timeEnd,
      } = globalState;
      rewriteJson.email = email;
      rewriteJson.mime_type = extensions;
      rewriteJson.filters[2].value = grzId;
      rewriteJson.filters[0].value = [timeStart, timeEnd];
      const postResult = await postToURLSingle.postOrPutData(
        "reports/device_summary/movement_report/",
        rewriteJson
      );
      if (extensions === "text/html") {
        const win = window.open(`about:blank`, "_blank");
        win.document.write(postResult);
        win.focus();
      }
      if (
        extensions ===
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
      ) {
        const result = await postResult;
        const resultId = result.result_id;
        const url = `http://api.test-ng.oits.su/api/reports/result/` + resultId;
        window.open(url);
      }
    }

    postData();
  };

  return (
    <div>
      <Movement sendData={sendData} />
    </div>
  );
};

const enhance = compose(withContentPadding, connect(null, null));
export default enhance(MovementContainer);
