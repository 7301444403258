import { Select } from "antd";
import { Form } from "antd";
import { useFormContext, Controller, ErrorMessage } from "react-hook-form";
import React, {useEffect, useRef, useState} from "react";
import { Styled } from "./styled/BaseInput.styled";
import {useBaseInputPlaceholderControl} from "./utils/hooks/useBaseInputPlaceholderControl";
import {checkConditionAndCallFn} from "../../services/common/checkConditionAndCallFn";
import {useBaseInputSettings} from "./utils/hooks/useBaseInputSettings";

const { Option } = Select;
const {
  BaseErrorMessage,
  BaseSelectInput,
  BaseSelectPlaceholder,
  BaseSelectInputWrap
} = Styled;

const ChoiceInput = props => {
  const inputWrapRef = useRef(null)
  const { control, errors, setValue } = useFormContext();
  const { description, object, field, mode } = props;
  const [optionsToChoose, setOptionsToChoose] = useState(null);
  const {required, message} = useBaseInputSettings(description)

  useEffect(() => {
    if (description?.choices) {
      const options = description.choices.map(el => (
        <Option value={el?.value}>{`${el?.display_name}`}</Option>
      ));
      setOptionsToChoose(options);
    }
  }, []);

  const {
    placeholderState,
    setPlaceholderState,
  } = useBaseInputPlaceholderControl(field, object, setValue)

  return (
    <Form.Item>
      <BaseSelectInputWrap ref={inputWrapRef}>
        <Controller
          dropdownClassName={"custom-dropdown"}
          allowClear={true}
          name={field}
          as={<BaseSelectInput>{optionsToChoose}</BaseSelectInput>}
          control={control}
          mode={mode || null}
          showSearch
          optionFilterProp="children"
          rules={{ required: { value: required, message: message } }}
          onFocus={() => {
            setPlaceholderState(true);
          }}
          onBlur={e => {
            checkConditionAndCallFn(!e[0], () => setPlaceholderState(false));
          }}
        />
        <ErrorMessage errors={errors} name={field} />
        <BaseSelectPlaceholder
          as={BaseErrorMessage}
          className={`${placeholderState ? "opened" : ""}`}
        >
          {description.label}
        </BaseSelectPlaceholder>
      </BaseSelectInputWrap>
    </Form.Item>
  );
};

export default ChoiceInput;
