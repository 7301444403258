import React, { useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { userLoginFetch } from "../../reducers/app/actions/authActions";
import { ReactSVG } from "react-svg";
import PasswordRemember from "./Auth/PasswordRemember/PasswordRemember";
import Login from "./Auth/Login/Login";
import { compose } from "redux";
import { Redirect, useRouteMatch, withRouter } from "react-router-dom";
import PasswordReset from "./Auth/PasswordReset/PasswordReset";
import ErrorMessageContainer from "../ErrorMessage/ErrorMessageContainer";
import { Styled } from "./GuestPage.styled";
import WelcomePage from "./WelcomePage/WelcomePage";
import SupportPage from "./SupportPage/SupportPage";
import { theme } from "../../library/themes/rootTheme";

const {
  LocalMainWrapper,
  LocalBackgroundLeftSide,
  LocalLogoNipigaz,
  LocalGreetingSpriteInner,
  LocalLogoITS,
  LocalBackgroundRightSide,
  LocalBackgroundCenter,
  LocalGreetingSpriteWrapper,
} = Styled;

const GuestPage = () => {
  const [isSupportInfoVisible, setSupportInfoVisible] = useState(true);

  const showWelcomePage = () => {
    setSupportInfoVisible(false);
  };
  const showSupportPage = () => {
    setSupportInfoVisible(true);
  };

  const isItNipigazProject = process.env.REACT_APP_TYPE === "PROD";

  const user = useSelector(state => state.auth.user);
  const dispatch = useDispatch();
  const sessionUser = localStorage.user;
  const matchHomePage = useRouteMatch("/guest");
  const matchLoginPage = useRouteMatch("/guest/login");
  const matchLoginRemember = useRouteMatch("/guest/login/remember");
  const matchPasswordRecovery = useRouteMatch(
      "/guest/password_recovery/:uid/:token"
  );

  const checkUser = () => {
    if (sessionUser && !user) {
      dispatch({
        type: "LOGIN_USER",
        payload: sessionUser
      });
    }
  };
  checkUser();

  return (
      <LocalMainWrapper imgSrc={theme.auth.bgImgSrcLink}>
        <LocalBackgroundLeftSide>
          <LocalLogoNipigaz>
            <theme.logo.auth />
          </LocalLogoNipigaz>
          <LocalGreetingSpriteWrapper>
            <LocalGreetingSpriteInner>
              <ReactSVG
                  className={"greeting-clipPath"}
                  src="/img/pathBackground.svg"
              />
              {isSupportInfoVisible ? (
                  <WelcomePage onClick={showWelcomePage} />
              ) : null}
              {!isSupportInfoVisible ? (
                  <SupportPage onClick={showSupportPage} />
              ) : null}
            </LocalGreetingSpriteInner>
          </LocalGreetingSpriteWrapper>

          {isItNipigazProject && (
              <LocalLogoITS>
                <theme.logo.itsPrimary />
              </LocalLogoITS>
          )}

        </LocalBackgroundLeftSide>
        <LocalBackgroundCenter>
          {matchHomePage && matchHomePage.isExact && (
              <Redirect to={"/guest/login"} />
          )}
          {matchLoginPage && matchLoginPage.isExact && <Login />}
          {matchLoginRemember && matchLoginRemember.isExact && (
              <PasswordRemember />
          )}
          {matchPasswordRecovery && matchPasswordRecovery.isExact && (
              <PasswordReset
                  uid={matchPasswordRecovery.params.uid}
                  token={matchPasswordRecovery.params.token}
              />
          )}
          <ErrorMessageContainer />
        </LocalBackgroundCenter>
        <LocalBackgroundRightSide />
      </LocalMainWrapper>
  );
};

const mapStateToProps = () => ({});

const mapDispatchToProps = dispatch => ({
  userLoginFetch: userInfo => dispatch(userLoginFetch(userInfo))
});

export default compose(
    withRouter,
    connect(mapStateToProps, mapDispatchToProps)
)(GuestPage);