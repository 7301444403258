import { mapObjectsEnum } from "../../../../old/editableObjectsRegistrator/editableObjectsRegistrator";
import { ObjectsPrototype } from "./ObjectsPrototype";

export class LayersPrototype extends ObjectsPrototype {
  layerName = null;
  constructor(layerName) {
    super("map");
    this.layerName = layerName;
  }
}
