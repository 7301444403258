import React from "react";
import UserSettings from "./UserSettings";
import {useSelector} from "react-redux";

const UserSettingsContainer  = (props) => {
    const user = useSelector(state=>state.auth.user)
    return (
        <UserSettings user={user}/>
    )
}

export default UserSettingsContainer;
