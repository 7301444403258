export const SELECT_OBJECTS_IN_DATA_ACCESSOR =
  "SELECT_OBJECTS_IN_DATA_ACCESSOR";

const initialState = {
  selected: [],
};

export const getSelectedRowsInDataAccessor = (state) => {
  return state.mapDataAccessorSelectedObjects.selected;
};

const mapDataAccessorSelectedObjects = (state = initialState, action) => {
  switch (action.type) {
    case SELECT_OBJECTS_IN_DATA_ACCESSOR: {
      const { selectedData } = action.payload;
      if (action.payload.selectedData.length===0) {return state}
      else {
      return {
        ...state,
        selected: [...selectedData],
      }};
    }
    default: {
      return state;
    }
  }
};

export default mapDataAccessorSelectedObjects;
