export const rasterTileStylesEnum = {
    collection: [
        {
            label: 'АГПЗ – Ортофотоплан',
            value: 'https://demo-ng.oits.su/tile/{z}/{x}/{y}.png'
        },
        {
            label: 'OCM Спутник',
            value: 'https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}'
        },
        {
            label: 'Спутник',
            value: 'http://tiles.maps.sputnik.ru/{z}/{x}/{y}.png'
        },
        {
            label: 'ТФ цикл 25',
            value: 'https://a.tile.thunderforest.com/cycle/{z}/{x}/{y}.png?apikey=13efc496ac0b486ea05691c820824f5f'
        },
        {
            label: 'ТФ плохая транспортировка  25',
            value: 'https://b.tile.thunderforest.com/transport-dark/{z}/{x}/{y}.png?apikey=13efc496ac0b486ea05691c820824f5f'
        },
        {
            label: 'ТФ на открытом воздухе 25',
            value: 'https://b.tile.thunderforest.com/outdoors/{z}/{x}/{y}.png?apikey=13efc496ac0b486ea05691c820824f5f'
        },
        {
            label: 'мрачная тема 25',
            value: 'https://{s}.basemaps.cartocdn.com/dark_all/{z}/{x}/{y}{r}.png'
        },
        {
            label: 'велосипед 20',
            value: 'https://tiles.wmflabs.org/hikebike/{z}/{x}/{y}.png'
        },
        {
            label: 'Стандарт ОСМ 20',
            value: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
        },
        {
            label: 'карта Википедии 20',
            value: 'https://maps.wikimedia.org/osm-intl/{z}/{x}/{y}.png'
        },
        {
            label: 'ОСМ 20',
            value: 'http://a.tile.openstreetmap.fr/hot/{z}/{x}/{y}.png'
        },
        {
            label: 'ОСМ 18',
            value: 'https://b.tile.openstreetmap.se/hydda/full/{z}/{x}/{y}.png'
        },
        {
            label: 'OTM 17',
            value: 'https://b.tile.opentopomap.org/{z}/{x}/{y}.png'
        },


    ],
    selectItem(enumerate,value) {
        const suitable = enumerate.find(el=>el.value===value)
        if (suitable) return suitable.label
        return 'none'
    }
}
