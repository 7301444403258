import React, {useEffect, useState} from "react";
import { Responsive, WidthProvider } from "react-grid-layout";

const ResponsiveGridLayout = WidthProvider(Responsive);

function oneColumnGridItemsFactory(newItems) {
    return newItems.map((el, index) => {
        return <div data-grid={{ x: 0, y: index, w: 2, h: 1 }} key={`${index}`}>
            {el}
        </div>
    })

}

const DraggableOneColumnGrid = ({children}) => {
    const [gridItems, setGridItems] = useState([]);
    useEffect(() => {
        setGridItems(oneColumnGridItemsFactory(children))
    }, [children])
    return (
            <ResponsiveGridLayout
                cols={{ lg: 2, md: 2, sm: 2, xs: 2, xxs: 2 }}
                className="layout"
                isResizable={false}
                rowHeight={children.length ? 40 : 0}
                isDroppable={true}
            >
                {gridItems}
            </ResponsiveGridLayout>
    );
}

export default DraggableOneColumnGrid