import React from "react";
import { compose } from "redux";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { registeredReportsUrls } from "../../../../../../registrators/reportsRegistrator/reportsRegistrator";
import { arrayGroupBy } from "../../../../../../services/data/arrayGroupBy";
import withContentPadding from "../../../../../../HOC/withContentPadding";
import SingleSubjectCard from "../../../../../../library/UI/components/SingleSubjectCard/SingleSubjectCard";
import styled from "styled-components";
import {CollapsableItem} from "../../../../../../library/UI/components/CollapsableItem/CollapsableItem";

const LocalMainWrapper = styled.div`
padding: 30px;
`;
const LocalInnerWrapper = styled.div`
  padding-top: 30px;
  display: flex;
  background-color: ${(props) => props.theme.colors.defaultLight};
  flex-wrap: wrap;
`;

const ReportCards = () => {
  const { t } = useTranslation("common");
  const confirmedAccess = registeredReportsUrls.map((el) => el);
  let resultList;
  const uniqueFolderKeys = [
    ...new Set(confirmedAccess.map((item) => item.folderKey)),
  ];

  for (let folderKey of uniqueFolderKeys) {
    resultList = arrayGroupBy(confirmedAccess, (rule) => rule.folderKey);
  }
  const cardsGroupsForRender = [];
  for (let folderKey of uniqueFolderKeys) {
    const oneCardsGroup = [];
    const itemsCollection = resultList?.get(folderKey);
    oneCardsGroup.push(
      itemsCollection.map((el) => (
        <Link to={`/app/reports/${el.url}`}>
          <SingleSubjectCard
            titleContent={t(el.i18LabelPath)}
            titleDescr={t(el.description)}
            url={el.urlData}
            to={el.url}
          />
        </Link>
      ))
    );
    cardsGroupsForRender.push({
      name: t(folderKey),
      cardsGroup: oneCardsGroup,
    });
  }

  return (
    <LocalMainWrapper>
      {cardsGroupsForRender.map((el) => {
        return (
          <CollapsableItem titleContent={el.name}>
            <LocalInnerWrapper>{el.cardsGroup}</LocalInnerWrapper>
          </CollapsableItem>
        );
      })}
    </LocalMainWrapper>
  );
};

const enhance = compose(withContentPadding);

export default enhance(ReportCards);
