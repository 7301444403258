export function makeLocalStateItem(initialItemData, status) {
  if (initialItemData && initialItemData?.id) {
    return {
      id: initialItemData.id,
      description: `Вагон №${initialItemData?.number}`,
      initialData: initialItemData,
      isSelected: false,
      itemStatus: status,
    };
  } else {
    console.error("wrong data in makeLocalStateItem fn");
    return null;
  }
}
