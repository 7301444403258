import React, { useState } from "react";
import styled from "styled-components";
import { Styled } from "./PassedGeozones.style";
import ScrollableBoxArea from "../../../../../../../library/areas/scrollableBoxArea/ScrollableBoxArea";
import ReportSider from "../../parts/Sider/ReportSider";
import DataTSContainer from "../../parts/DataTS/DataTSContainer";
import TimePeriodicityData from "../../parts/PeriodicityData/TimePeriodicityData";
import EmailDataContainer from "../../parts/EmailData/EmailDataContainer";
import ExtensionData from "../../parts/ExtensionData/ExtensionData";
import SelectGeneralData from "../../parts/SelectGeneralData/SelectGeneralData";
import DataGeozones from "./DataGeozones/DataGeozones";

const StyledDrawer = styled.div``;
const {
  PanelLayout,
  LocalInnerWrapper,
  LocalTitle,
  LocalReportData,
  LocalDataTS,
  LocalExtensionData,
} = Styled;

const PassedGeozones = ({ sendData }) => {
  return (
    <StyledDrawer
      placement={"right"}
      closable={false}
      visible={false}
      getContainer={false}
      bodyStyle={{ padding: 0 }}
    >
      <PanelLayout>
        <ReportSider sendData={sendData} />
        <ScrollableBoxArea>
          <LocalTitle>Отчет "Пересечение геозон"</LocalTitle>
          <LocalInnerWrapper>
            <LocalDataTS>
              <DataTSContainer />
            </LocalDataTS>
            <LocalReportData>
              <TimePeriodicityData />
              {/*<EmailDataContainer />*/}
              {/*<SelectGeneralData />*/}
            </LocalReportData>
            <LocalExtensionData>{<ExtensionData />}</LocalExtensionData>
          </LocalInnerWrapper>
        </ScrollableBoxArea>
      </PanelLayout>
    </StyledDrawer>
  );
};

export default PassedGeozones;
