import React, { useState } from "react";
import { Button } from "../../../../../../../../../../../library/UI/components/Button/Button";
import { TableIcon } from "../../../../../../../../../../../library/icons/objectIcons/TableIcon";
import { SettingsIcon } from "../../../../../../../../../../../library/icons/actionIcons/SettingsIcon";
import { VisibilityOffIcon } from "../../../../../../../../../../../library/icons/actionIcons/VisibilityOffIcon";
import { VisibilityIcon } from "../../../../../../../../../../../library/icons/actionIcons/VisibilityIcon";
import { withTheme } from "styled-components";
import { OptionIcon } from "../../../../../../../../../../../library/icons/actionIcons/OptionIcon";
import PanelSlimCardTitle from "../../../../../../../../../../../library/UI/components/PanelSlimCard/Title/PanelSlimCardTitle";
import { Styled } from "./DisplayedLayerItem.styled";
import { DeleteIcon } from "../../../../../../../../../../../library/icons/actionIcons/DeleteIcon";
import { ArrowIcon } from "../../../../../../../../../../../library/icons/actionIcons/ArrowIcon";
import { useTranslation } from "react-i18next";
import _ from "underscore";
import {getRegisteredLayer} from "../../../../../../../../../../../registrators/map/layers/layersRegistrator";
import {localeDirectoriesEnum} from "../../../../../../../../../../../enums/localizationJSONEnum";
import {iconSizesKit} from "../../../../../../../../../../../library/UI/utils/iconSizesKit";

const DisplayedLayerItem = ({
  item,
  theme,
  toggleLayerVisible,
  switchLayerDisplayedOrder,
  selectDataForLayer,
  handleRemoveLayer,
  toggleLayerSettingsVisible,
  selectMapboxLayerWithSideEffect,
  isSettingsPanelVisible,
  isLayerDataAccessorVisible,
  itemKey,
  onItemWrapperClick,
  isCurrentDataAccessorForThisItem,
  isSelected,
                              setSelectedLayerSettings
}) => {
  const [isCardActive, setIsCardActive] = useState(false);
  const { t } = useTranslation(localeDirectoriesEnum.layerCards);
  const LayerResolved = getRegisteredLayer(item.name)
  const Card = LayerResolved.getCard()
  return (
    <>
      <Styled.ExtendedPanelCard
        active={isCardActive}
        mt={"0.5rem"}
        onClick={onItemWrapperClick}
        key={itemKey}
        isSelected={isSelected}
        p={'0.7rem'}
      >
        <Styled.CustomisedPanelCardContent>
          <PanelSlimCardTitle m={0} isSelected={isSelected}>{t(Card.getI18Path())}</PanelSlimCardTitle>
          <Button
            p={0}
            type={"unbounded"}
            iconSize={iconSizesKit.big}
            icon={<OptionIcon key={_.uniqueId()} />}
            key={_.uniqueId()}
          />
        </Styled.CustomisedPanelCardContent>
        <Styled.CustomisedPanelCardActionGroup
          active={isCardActive}
          onMouseEnter={() => setIsCardActive(true)}
          onMouseLeave={() => setIsCardActive(false)}
        >
          <Button
            type={"unbounded"}
            className={"moved-action-item"}
            p={0}
            iconSize={iconSizesKit.big}
            iconColor={theme.colors.danger}
            icon={<DeleteIcon key={_.uniqueId()} />}
            onClick={() => handleRemoveLayer(item)}
            key={_.uniqueId()}
          />
          <Button
            type={"unbounded"}
            className={`moved-action-item ${
              isCurrentDataAccessorForThisItem && isLayerDataAccessorVisible
                ? "active"
                : ""
            }`}
            p={0}
            iconSize={iconSizesKit.big}
            onClick={selectDataForLayer}
            icon={<TableIcon key={_.uniqueId()} />}
            key={_.uniqueId()}
          />
          <Button
            type={"unbounded"}
            className={`moved-action-item ${!item.hidden ? "active" : ""}`}
            p={0}
            iconSize={iconSizesKit.big}
            onClick={toggleLayerVisible}
            key={_.uniqueId()}
            icon={!item.hidden ? <VisibilityOffIcon key={_.uniqueId()} /> : <VisibilityIcon key={_.uniqueId()} />}
          />
          <Styled.LocalIconWrap>
            <ArrowIcon />
          </Styled.LocalIconWrap>
          <Button
            type={"unbounded"}
            className={`${
              item.isSelected && isSettingsPanelVisible ? "active" : ""
            }`}
            p={0}
            iconSize={iconSizesKit.big}
            onClick={() => {
              selectMapboxLayerWithSideEffect(item)
              setSelectedLayerSettings("changeControls")}}
            icon={<SettingsIcon key={_.uniqueId()} />}
            key={_.uniqueId()}
          />
        </Styled.CustomisedPanelCardActionGroup>
      </Styled.ExtendedPanelCard>
    </>
  );
};

DisplayedLayerItem.propTypes = {};

export default withTheme(DisplayedLayerItem);
