import styled from "styled-components";
import { Button } from "../../../../library/UI/components/Button/Button";
import { NavLink } from "react-router-dom";

const LocalMainWrapper = styled.div`
  .inline-placeholder-login {
    user-select: none;
    pointer-events: none;
    z-index: 1500;
    font-family: Open Sans,sans-serif;
    font-size: 16px;
    color: #bfbfbf;
    position: absolute;
    top: 16px;
    left: 31px;
    transition: all 0.2s;
  }

  .inline-placeholder-login.close.focused {
    position: absolute;
    transform: translate3d(0, -6px, 0);
    font-size: 13px;
  }

  .inline-placeholder-login.open {
    position: absolute;
    transform: translate3d(0, -6px, 0);
    font-size: 13px;
  }

  & a {
    font-family: AvantGardeGothicC,sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    color: ${(props) => props.theme.colors.primary};
  }
  // Todo refactoring style
`;

const LocalLoginForm = styled.div`
  padding: 25px 20px;
  width: 354px;
  height: 352px;
  border-radius: ${(props) => props.theme.decoration.defaultBorderRadius};
  top: 50%;
  position: absolute;
  left: 32%;
  z-index: 1;
  transform: translate(-50%, -50%);
  background-color: ${(props) => props.theme.colors.defaultLighter};
  box-shadow: ${(props) => props.theme.decoration.darkBoxShadow};
`;

const LocalLoginFormTitle = styled.h3`
  margin: 0;
  text-align: center;
  font-style: normal;
  font-weight: bold;
  line-height: 29px;
  width: 100%;
  align-items: center;
  font-size: 1.7rem;
  color: ${(props) => props.theme.colors.defaultDark};
  & + * {
    margin-top: 35px;
  }
`;

const LocalFormContentWrapper = styled.form``;

const LocalInputWrapper = styled.div`
  position: relative;
  width: fit-content;
  margin: 0 auto 20px;
  background: ${(props) => props.theme.colors.defaultLighter};
  border-radius: ${(props) => props.theme.decoration.defaultBorderRadius};
  margin-bottom: 36px;
  & input:hover {
    border-radius: ${(props) =>
      props.theme.decoration.defaultBorderRadius} !important;
    border: 2px solid ${(props) => props.theme.colors.primaryLighter} !important;
  }
`;

const LocalPrimaryDescription = styled.small`
  display: block;
  font-size: ${(props) => props.theme.fontSettings.defaultLarge};
  color: ${(props) => props.theme.colors.primary};
  text-align: center;
  line-height: 19px;
  opacity: 0.8;
  transition: all 0.3s;
  &:hover {
    opacity: 1;
  }
`;

const ExtendedNavLink = styled(NavLink)`
  display: block;
`;

const ExtendedButton = styled(Button)`
  & + ${ExtendedNavLink} {
    margin-top: 30px;
  }
`;





export const Styled = {
  LocalMainWrapper,
  LocalLoginForm,
  LocalLoginFormTitle,
  LocalFormContentWrapper,
  LocalInputWrapper,
  LocalPrimaryDescription,
  ExtendedButton,
  ExtendedNavLink,
};
