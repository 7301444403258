export const errorNetworkMessageCreator = err => {
  let message = "";
  if (err.response) {
    switch (err.response.status) {
      case 500: {
        message += "Сервер временно недоступен, попробуйте зайти позже";
        break;
      }
      case 401: {
        message +=
          "Ошибка авторизации пользователя, необходимо заново войти в систему";
        break;
      }
      default: {
        message = "Ошибка сетевого сообщения";
        break;
      }
    }
  }
  return message;
};
