import styled from "styled-components";
import {Button} from "../../../library/UI/components/Button/Button";

const LocalMainWrapper = styled.div`
      position:absolute;
      top: 0;
      left: 0;
      z-index: 1;
      width: 80%;

`

const LocalMainTitle = styled.h3`
        margin: 0;
        font-size: 24px;
        line-height: 29px;
        color: ${props => props.theme.colors.defaultDark}   
`

const LocalMainDescription = styled.div`
        font-size: 14px;
        margin-top: 8px;
        margin-bottom: 56px;
        color: ${props => props.theme.colors.paragraph}


`

const LocalButtonHeadIcon = styled(Button)`
    margin-left: -5px; 
      & svg {
      transform-origin: center;
      }

`



export const Styled = {
    LocalMainWrapper,
    LocalMainTitle,
    LocalMainDescription,
    LocalButtonHeadIcon

}