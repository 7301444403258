// TODO REFACTOR!!! THIS IS MOCKED DATA NOT A ENUM
export const mapboxDefaultMapStylesEnum = {
    collection: [
        { label: 'Улицы В10', value: 'mapbox://styles/mapbox/streets-v10' },
        { label: 'Улицы В11', value: 'mapbox://styles/mapbox/streets-v11' },
        { label: 'На улице В10', value: 'mapbox://styles/mapbox/outdoors-v10' },
        { label: 'Свет В9', value: 'mapbox://styles/mapbox/light-v9' },
        { label: 'Темный В9', value: 'mapbox://styles/mapbox/dark-v9' },
        { label: 'Спутник В9', value: 'mapbox://styles/mapbox/satellite-v9' },
        {
            label: 'Спутниковая улица В10',
            value: 'mapbox://styles/mapbox/satellite-streets-v10'
        },
        {
            label: 'День просмотра навигации В4',
            value: 'mapbox://styles/mapbox/navigation-preview-day-v4'
        },
        {
            label: 'Навигация Ночь В4',
            value: 'mapbox://styles/mapbox/navigation-preview-night-v4'
        },
        {
            label: 'День навигации В4',
            value: 'mapbox://styles/mapbox/navigation-guidance-day-v4'
        },
        {
            label: 'Навигационное руководство Ночью В4',
            value: 'mapbox://styles/mapbox/navigation-guidance-night-v4'
        }
    ],
    selectItem(enumerate,value) {
        const suitable = enumerate.find(el=>el.value===value)
        if (suitable) return suitable.label
        return 'none'
    }
}
