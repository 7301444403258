import { resolver } from "./resolver";
import { mapObjectsEnum } from "../../../enums/mapObjectsEnum";
import RoadSchemeItemSelection from "../../../library/layers/SelectedObjectsRepresentations/RoadSchemeItemSelection/RoadSchemeItemSelection";
import MonitoringVehicleSelection from "../../../library/layers/SelectedObjectsRepresentations/MonitoringVehicleSelection/MonitoringVehicleSelection";
import { dataAccessMethodsEnum } from "../../../enums/dataAccessMethodsEnum";
import CompendiumSelectedContainer from "../../../library/compendiums/Common/Compendium/CompendiumSelectedContainer.jsx";
import CargoItemSelectedItemDescription from "../../../library/layers/SelectedObjectsRepresentations/CargoItem/CargoItemSelectedItemDescription";
import SingleCargoLayer from "../../../library/layers/CompositeLayers/NIPIGAZ/SingleMode/SingleCargoCompositeLayer";
import GeozonesItemSelection from "../../../library/layers/SelectedObjectsRepresentations/GeozonesItemSelection/GeozonesItemSelection";
import CheckpointsItemSelection from "../../../library/layers/SelectedObjectsRepresentations/CheckpointsItemSelection/CheckpointsItemSelection";
import DischargeZonesSelection from "../../../library/layers/SelectedObjectsRepresentations/DischargeZonesSelection/DischargeZonesSelection.jsx";
import RailwaySchemeItemSelection from "../../../library/layers/SelectedObjectsRepresentations/RailwaySchemeItemSelection/RailwaySchemeItemSelection";
export const resolveSelectedObjectDescription = resolver;

export const selectionMapObjectRegistrator = [
  {
    name: mapObjectsEnum.roadSegment,
    modes: [
      {
        name: "road",
        label: "Дорога",
        urlData: "/roads/",
        accessMethod: dataAccessMethodsEnum.httpGet,
        idKey: "road",
        representationPrototype: RoadSchemeItemSelection,
      },
      {
        name: "segment",
        label: "Сегмент",
        urlData: "/roads/segments/",
        accessMethod: "http",
        idKey: "id",
        representationPrototype: RoadSchemeItemSelection,
      },
    ],
  },
  {
    name: mapObjectsEnum.securityCheckpoint,
    modes: [
      {
        name: "security checkpoint",
        label: "КПП",
        urlData: "/roads/checkpoints-segments/",
        accessMethod: dataAccessMethodsEnum.httpGet,
        idKey: "id",
        representationPrototype: RoadSchemeItemSelection,
      },
    ],
  },
  {
    name: mapObjectsEnum.roadSign,
    modes: [
      {
        name: "road sign",
        label: "Дорожный знак",
        urlData: "/roads/signs-segments/",
        accessMethod: dataAccessMethodsEnum.httpGet,
        idKey: "id",
        representationPrototype: RoadSchemeItemSelection,
      },
    ],
  },
  // {
  //   name: mapObjectsEnum.signs,
  //   modes: [
  //     {
  //       name: "sign",
  //       label: "Дорожный знак",
  //       urlData: "/roads/signs-segments/",
  //       accessMethod: dataAccessMethodsEnum.httpGet,
  //       idKey: "id",
  //       representationPrototype: RoadSchemeItemSelection,
  //     },
  //   ],
  // },
  {
    name: mapObjectsEnum.roadOverpass,
    modes: [
      {
        name: "road overpass",
        label: "Эстакада",
        urlData: "/roads/overpasses-segments/",
        accessMethod: dataAccessMethodsEnum.httpGet,
        idKey: "id",
        representationPrototype: RoadSchemeItemSelection,
      },
    ],
  },
  {
    name: mapObjectsEnum.roadBlock,
    modes: [
      {
        name: "road block",
        label: "Перекрытие",
        urlData: "/roads/closures-segments/",
        accessMethod: dataAccessMethodsEnum.httpGet,
        idKey: "id",
        representationPrototype: RoadSchemeItemSelection,
      },
    ],
  },
  {
    name: mapObjectsEnum.roadWorks,
    modes: [
      {
        name: "road works",
        label: "Дорожные работы",
        urlData: "/roads/roadworks-segments/",
        accessMethod: dataAccessMethodsEnum.httpGet,
        idKey: "id",
        representationPrototype: RoadSchemeItemSelection,
      },
    ],
  },
  {
    name: mapObjectsEnum.dockSegment,
    modes: [
      {
        name: "docks segments",
        label: "Причалы",
        urlData: "/roads/docks-segments/",
        accessMethod: dataAccessMethodsEnum.httpGet,
        idKey: "id",
        representationPrototype: RoadSchemeItemSelection,
      },
    ],
  },
  {
    name: mapObjectsEnum.controlRoomsSegment,
    modes: [
      {
        name: "control rooms segments",
        label: "Диспетчерские",
        urlData: "/roads/control_rooms-segments/",
        accessMethod: dataAccessMethodsEnum.httpGet,
        idKey: "id",
        representationPrototype: RoadSchemeItemSelection,
      },
    ],
  },
  {
    name: mapObjectsEnum.geozone,
    modes: [
      {
        name: "geozone",
        label: "Геозона",
        urlData: "/geozones/",
        accessMethod: dataAccessMethodsEnum.httpGet,
        idKey: "id",
        representationPrototype: GeozonesItemSelection,
      },
    ],
  },
  {
    name: mapObjectsEnum.monitoringCluster,
    modes: [
      {
        name: "monitoring_vehicles_cluster",
        label: "Группа транспортных средств",
        urlData: "/layer_monitoring/vehicles/",
        accessMethod: dataAccessMethodsEnum.httpGetWithFilters,
        params: [
          {
            name: "vehicle_id",
            value: "value",
          },
        ],
        idKey: "vehicle_id",
        representationPrototype: CompendiumSelectedContainer,
        childNext: mapObjectsEnum.monitoringObject,
      },
    ],
  },
  {
    name: mapObjectsEnum.monitoringObject,
    modes: [
      {
        name: "monitored_object",
        label: "транспорт",
        urlData: "/layer_monitoring/vehicles/",
        accessMethod: dataAccessMethodsEnum.httpGetById,
        idKey: "vehicle_id",
        representationPrototype: MonitoringVehicleSelection,
      },
    ],
  },
  {
    name: mapObjectsEnum.railway,
    modes: [
      {
        name: "railway",
        label: "Ж/Д путь",
        urlData: "/railway_network/railways/",
        accessMethod: dataAccessMethodsEnum.httpGet,
        idKey: "id",
        representationPrototype: RailwaySchemeItemSelection,
      },
    ],
  },
  {
    name: mapObjectsEnum.dischargeZone,
    modes: [
      {
        name: "discharge_zone",
        label: "Ж/Д зона разгрузки",
        urlData: "/railway_network/discharge_zones/",
        accessMethod: dataAccessMethodsEnum.httpGet,
        idKey: "id",
        representationPrototype: DischargeZonesSelection,
      },
    ],
  },
  {
    name: mapObjectsEnum.receptionArea,
    modes: [
      {
        name: "reception_area",
        label: "Ж/Д зона приема",
        urlData: "/railway_network/reception_areas/",
        accessMethod: dataAccessMethodsEnum.httpGet,
        idKey: "id",
        representationPrototype: RoadSchemeItemSelection,
      },
    ],
  },
  {
    name: mapObjectsEnum.clusterCargos,
    modes: [
      {
        name: "cargos_in_point",
        label: "Грузы в точке отправления:",
        urlData: "/layer_tmc_tracking/cargo/representation/",
        accessMethod: dataAccessMethodsEnum.httpGetWithParams,
        params: [
          {
            name: "location_name",
            value: "value",
          },
        ],
        idKey: "vehicle_id",
        representationPrototype: CompendiumSelectedContainer,
        childNext: mapObjectsEnum.singleCargo,
      },
    ],
  },
  {
    name: mapObjectsEnum.singleCargo,
    modes: [
      {
        name: "cargo",
        label: "{invoice}",
        labelTemplateStrings: {
          location_name: "location_name",
        },
        urlData: "/layer_tmc_tracking/cargo/",
        accessMethod: dataAccessMethodsEnum.httpGetById,
        idKey: "id",
        representationPrototype: CargoItemSelectedItemDescription,
      },
    ],
    mapLayerPrototype: SingleCargoLayer,
    hideOtherLayers: true,
  },

  {
    name: mapObjectsEnum.trafficBackgroundRoadSegment,
    modes: [
      {
        name: "roadSegment",
        label: "{invoice}",
        urlData: "/roads/segments/",
        accessMethod: dataAccessMethodsEnum.httpGetById,
        idKey: "id",
      },
    ],
  },
  {
    name: mapObjectsEnum.trafficSegment,
    modes: [
      {
        name: "traffic segment",
        label: "Скоростной режим",
        urlData: "/traffic_jams/traffic_jam_segments/",
        accessMethod: dataAccessMethodsEnum.httpGet,
        idKey: "id",
        representationPrototype: RoadSchemeItemSelection,
      },
    ],
  },
  {
    name: mapObjectsEnum.cargoTrackingPoint,
    modes: [
      {
        name: "cargoTrackingPoint",
        label: "Местоположение груза",
        urlData: "tmc_tracking/location_point/",
        accessMethod: dataAccessMethodsEnum.httpGet,
        idKey: "id",
        representationPrototype: CheckpointsItemSelection,
      },
    ],
  },
];

export const defaultObjectDescription = {
  permissionToShow: [],
  actions: [],
};
