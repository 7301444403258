import styled from "styled-components";

const LocalMainWrapper = styled.section`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  padding: ${(props) => (!props.sidebarIsOpened ? "1.4rem" : 0)};
  padding-top: 1.6rem;
`;

const LocalLogoWrap = styled.div`
  width: ${(props) => (!props.sidebarIsOpened ? "auto" : "100%")};
  display: flex;
  justify-content: center;
`;

export const Styled = { LocalMainWrapper, LocalLogoWrap };
