import styled from "styled-components";
import Panel from "../Panel/Panel";
import PanelHeader from "../Panel/Header/PanelHeader";
import {Styled as styleMixins} from "../../styleMixins/styleMixins.styled"

const {CutStringWithEllipsisOnTheEnd} = styleMixins.text

const LocalMainWrapper = styled.section`
  height: fit-content;
  position:relative;
  width:100%;
`;

const ExtendedMainInner = styled(Panel)`
  height: fit-content;
  background: rgba(255, 255, 255, 0.8);
  backdrop-filter: blur(18px);
  margin-left: 6px;
  width: 100%;
`;

const ExtendedHeaderWrap = styled(PanelHeader)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${props => props.theme.paddings.smallPadding};
`;

const LocalHeaderGroup = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 0px -5px;
  & > * {
    margin: 0 5px;
  }
`

const LocalHeaderTitle = styled.h4`
  font-size: ${(props) => props.theme.fontSettings.default};
  color: ${(props) => props.theme.colors.defaultDark};
  margin: 0;
  ${CutStringWithEllipsisOnTheEnd}
`

export const Styled = {
  main: { LocalMainWrapper, ExtendedMainInner },
  header: { ExtendedHeaderWrap, LocalHeaderGroup, LocalHeaderTitle },
};
