/**
 * @deprecated
 * @type {{railwayDischarge: string, roadsSchema: string, trafficSegments: string, geozones: string, railwayNetwork: string, monitoring: string, cargoTracking: string, cargoTrackingPoints: string, tracking: string, trafficRoadsSections: string}}
 */
export const layerNamesEnum = {
  monitoring: "monitoring",
  roadsSchema: "roadsSchema",
  tracking: "tracking",
  geozones: "geozones",
  cargoTracking: "cargoTracking",
  railwayNetwork: "railwayNetwork",
  railwayDischarge: "railwayDischarge",
  cargoTrackingPoints: "cargoTrackingPoints",
  trafficRoadsSections: "trafficRoadsSections",
  trafficSegments: "trafficSegments",

};
