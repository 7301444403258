import {RelatedDataPrototype} from "../prototype/RelatedDataPrototype";
import {mapObjectsEnum} from "../../../../../../enums/mapObjectsEnum";
import {dataAccessMethodsEnum} from "../../../../../../enums/dataAccessMethodsEnum";

export class Signs extends RelatedDataPrototype {
  constructor() {
    super({
      name: "sign",
      urlData: "/roads/signs/",
      selectedObjectName: mapObjectsEnum.signs,
    });
  }
}
