import React, { useCallback, useEffect, useState } from "react";
import { connect } from "react-redux";
import { useAgGridControl } from "../../../customHooks/ag-grid/useAgGridControl";
import Compendium from "../../../library/compendiums/Common/Compendium/Compendium";
import { getFromURL } from "../../../api/getFromURL";
import { Button } from "../../../library/UI/components/Button/Button";
import { ArrowLongIcon } from "../../../library/icons/actionIcons/ArrowLongIcon";
import styled from 'styled-components'
import CompendiumColumnActionPanelContainer
  from "../../../library/compendiums/CompendiumButtonBars/Common/CompendiumColumnActionPanel/CompendiumColumnActionPanelContainer";
import {sliceIdFromEndOfUrl} from "../../../services/net/sliceIdFromEndOfUrl";
import Panel from "../../../library/UI/components/Panel/Panel";

const StyledMainInner = styled(Panel)`
  height: fit-content;
  background: rgba(255, 255, 255, 0.8);
  backdrop-filter: blur(18px);
  margin-left: 6px;
`

const LocalInner = styled.div`
  height: fit-content !important;
  margin-top: 5.5rem;
  display: flex;
  justify-content: space-between;
  padding: 0 ${props => props.theme.paddings.defaultPadding};
`

const baseReportErrorUrl = "tmc_tracking/excel_parser_upload_files_errors/";

const errorReportRequest = async (id, setData, setModel) => {
  const responseModel = {};
  responseModel.scheme = {
    message: {
      label: "Сообщение об ошибке",
      read_only: true,
      required: false,
      type: "string"
    },
    position: {
      label: "Позиция",
      read_only: true,
      required: false,
      type: "string"
    },
    sheet_name: {
      label: "Лист с ошибкой",
      read_only: true,
      required: false,
      type: "string"
    },
    value: {
      label: "Значение",
      read_only: true,
      required: false,
      type: "string"
    }
  };
  const responseData = await getFromURL.getById(baseReportErrorUrl, id);
  setModel(responseModel);
  setData(responseData?.errors);
};

const FileUploaderErrorReport = () => {
  const {
    aggApi,
    quickMenu,
    handleAGGInit,
    toggleShowFilterMenu,
    toggleShowQuickFilter,
    toggleShowRowsMenu
  } = useAgGridControl();

  const [data, setData] = useState(null);
  const [model, setModel] = useState(null);

  const currentUrl = window.location.href;
  const reportId = sliceIdFromEndOfUrl(currentUrl)

  useEffect(() => {
    errorReportRequest(reportId, setData, setModel);
  }, []);

  const handleSelectRow = useCallback(
    aggridRow => {
    },
    [aggApi, quickMenu]
  );

  const handleRefresh = useCallback(() => {}, []);

  const handleProvideData = () => {};

  let containerCallbacks = {
    handleAGGInit,
    handleRefresh,
    handleSelectRow,
    toggleShowFilterMenu,
    handleProvideData,
    toggleShowRowsMenu,
    toggleShowQuickFilter
  };

  const renderContent = !!(data && model);
  function onGoToHomeHandler() {
    window.location.replace('/app/directory/')
  }

  return (
    <>
      {renderContent && (
        <>
          <LocalInner >
            <Button
                width={"220px"}
                p={0.5}
                icon={<ArrowLongIcon />}
                iconSize={{ width: "18px", height: "18px" }}
                iconMargin={0.7}
                type={"primary"}
                onClick={onGoToHomeHandler}
            >
              Вернуться на главную
            </Button>
            <CompendiumColumnActionPanelContainer
                quickMenuState={quickMenu}
                toggleShowFilterMenu={toggleShowFilterMenu}
                toggleShowQuickFilter={toggleShowQuickFilter}
                toggleShowRowsMenu={toggleShowRowsMenu}
                iconSizeType={"large"}
            />
          </LocalInner>
          <StyledMainInner style={{ marginTop: "0.5rem", width: '100%' }}>
            <Compendium
              aggApi={aggApi}
              data={data}
              model={model}
              showQuickFilter={quickMenu.search}
              containerCallbacks={containerCallbacks}
              withPagination={false}
              agGridThemeName={"ag-theme-custom-base"}
              gridCellSizes={{ minWidth: 100, width: 450, maxWidth: 1200 }}
            />
          </StyledMainInner>
        </>
      )}
    </>
  );
};

FileUploaderErrorReport.propTypes = {};

const mapStateToProps = state => {
  return {
    language: state.app.language
  };
};

const mapDispatchToProps = () => {
  return {};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FileUploaderErrorReport);
