import { UploadIcon } from "../../../icons/actionIcons/UploadIcon";
import React, { useRef, useState } from "react";
import { connect } from "react-redux";
import { directoryHistoryActions } from "../../../../reducers/directory/actions/directoryHistoryActions";
import { getLastHistoryRecord } from "../../../../reducers/directory/directoryHistory";
import { Button } from "../../../UI/components/Button/Button";
import { compose } from "redux";
import { withTheme } from "styled-components";
import { baseStyles } from "../../../../components/stylesRegistrator";
import { Styled } from "./UploadTMCFileButton.styled";
import { TMCUploadModal } from "./UploadTMCFileModal";

const { postToInlineHistory } = directoryHistoryActions;

const { LocalDiv } = Styled;

// const {} = baseStyles.mixins;

const UploadTMCFileButton = (props) => {
  // const { lastInlineHistory, pushInlineHistory, CRUDHandlers } = props;
  const iconSize = useRef({ width: "20px", height: "20px" });
  const [isOpenModal, setOpenModal] = useState(false);

  return (
    <LocalDiv>
      <Button
        onClick={() => setOpenModal(true)}
        icon={<UploadIcon />}
        rounded
        hasShadow
        type={"danger"}
        iconColor={props.theme.colors.primary}
        iconSize={iconSize.current}
      />
      <TMCUploadModal isOpenModal={isOpenModal} setOpenModal={setOpenModal} />
    </LocalDiv>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    pushInlineHistory: (historyRecord) =>
      dispatch(postToInlineHistory(historyRecord)),
  };
};

const mapStateToProps = (state) => {
  return {
    lastInlineHistory: getLastHistoryRecord(state),
  };
};

export default compose(
  withTheme,
  connect(mapStateToProps, mapDispatchToProps)
)(UploadTMCFileButton);
