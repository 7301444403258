import React from "react";
import ScrollableCard from "../ScrollableCard/ScrollableCard";
import PropTypes from "prop-types";

const TransferCard = ({
  cardHeight,
  footerContent,
  headerContent,
  children,
}) => {
  return (
    <ScrollableCard
      cardHeight={cardHeight}
      footerContent={footerContent}
      headerContent={headerContent}
    >
      {children}
    </ScrollableCard>
  );
};

ScrollableCard.propTypes = {
  cardHeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  children: PropTypes.node,
  footerContent: PropTypes.node,
  headerContent: PropTypes.node,
};

ScrollableCard.defaultProps = {
  cardHeight: "350px",
};

export default TransferCard;
