import { Button } from "../../../UI/components/Button/Button";
import { DropUploadIcon } from "../../../icons/actionIcons/DropUploadIcon";
import React, { useState } from "react";
import { Styled } from "./UploadTMCFileButton.styled";
import { UploadFileItemIcon } from "./UploadFileItemIcon";
import { DeleteIcon } from "../../../icons/actionIcons/DeleteIcon";
import { message, Select, Upload } from "antd";
import { typesUploadedTMCReportsEnum } from "../../../../enums/reports/typesUploadedTMCReportsEnum";
import _ from "underscore";
import { basePath } from "../../../../registrators/map/layers/layersRegistrator";
import { postToURLSingle } from "../../../../api/postToURLSingle";

const { Option } = Select;
const { Dragger } = Upload;

const {
    LocalSpan,
    StartUploadButton,
    LocalFileContent,
    LocalFileItem,
    LocalModal,
} = Styled;

const ReportTypes = _.keys(typesUploadedTMCReportsEnum).map((key) => {
    const value = typesUploadedTMCReportsEnum[key];
    return <Option value={key}>{value}</Option>;
});
export const VehiclesModal = (props) => {
    const{parsPoint} = props;
    const [btnDisable, setBtnDisable] = useState(false)
    const [fileList, setFileList] = useState([]);
    const [uploadStatys, setUploadStatys] = useState([]);
    const units = [
        "байт",
        "килобайт",
        "мегабайт",
        "гигабайт",
        "TB",
        "PB",
        "EB",
        "ZB",
        "YB",
    ];

    const niceBytes = (x) => {
        let l = 0,
            n = parseInt(x, 10) || 0;
        while (n >= 1024 && ++l) {
            n = n / 1024;
        }
        return n.toFixed(n < 10 && l > 0 ? 1 : 0) + " " + units[l];
    };
    const uploadSettings = {
        onRemove: (file) => {
            const removedItem = fileList.filter((x) => x.uid != file.uid);
            const newStatus = uploadStatys.filter((x) => file.uid !== x.uid);
            setUploadStatys(newStatus);
            setFileList(removedItem);
            return { fileList: removedItem };
        },
        beforeUpload: (file) => {
            const MAX_FILE_SIZE = 21000000;
            if (file.size > MAX_FILE_SIZE) {
                message.error(
                    `${file.name} вес файла не должен превышать ${niceBytes(
                        MAX_FILE_SIZE
                    )}`
                );
                return;
            }
            setUploadStatys((prevState) => [
                ...prevState,
                { uid: file.uid, status: null },
            ]);
            const duplicatedFiles = fileList.filter((x) => x.name === file.name);
            setFileList((prevState) => {
                return [...prevState.filter((x) => !duplicatedFiles.includes(x)), file];
            });
            return false;
        },
        name: "file",
        /*action: basePath + "/documents/relay_sheet_parser/",*/
        action: basePath + `${parsPoint}`,
        headers: {},
        showUploadList: false,
        uploading: false,
        multiple: true,
        onChange(info) {
            if (info.file.status === "done") {
                message.success(`${info.file.name} отпрвлен на обработку`);
            } else if (info.file.status === "error") {
                message.error(`${info.file.name} ошибка при загрузке`);
            }
        },
    };

    const updateStatus = (uid, status) => {
        const index = uploadStatys.findIndex((x) => x.uid === uid);
        const copy = [...uploadStatys];
        copy[index].status = status;
        setUploadStatys(copy);
    };
    const startUpload = (el) => {
        if (!fileList) {
            message.error(`Отсутствуют файлы`);
        }
        setBtnDisable(true)
        let arrayPromise = fileList.map((file) => {
            const formData = new FormData();
            if (file.project) {
                formData.append("project", file.project);
            }
            formData.append("file", file);
            const url = basePath + `${parsPoint}`;
            updateStatus(file.uid, "pending");
            return postToURLSingle.sendFile(url, formData)
               .then((response) => {
                const status = response ? "ok" : "reject";
                updateStatus(file.uid, status);
            });
        });
        Promise.all(arrayPromise)
            .then((response)=>{
                setBtnDisable(false)
                props.setOpenModal(false)
            })
    };
    const uploadItemsContent = fileList?.map((item) => {
        const status = uploadStatys.find((x) => x.uid === item.uid);
        const className = status?.status ? status.status : "";
        return (
            <div>
                <LocalFileItem
                    width
                    maxWidth
                    uploading={item.uploading}
                    key={_.uniqueId()}
                    className={className}
                >
                    <Button iconSize={{ width: "18px", height: "18px" }}>
                        <UploadFileItemIcon style={{ color: "#0097A6" }} />
                    </Button>
                    <p>{item.name}</p>
                    <Button
                        iconSize={{ width: "20px", height: "20px" }}
                        icon={<DeleteIcon style={{ color: "#EB5757" }} />}
                        onClick={() => uploadSettings.onRemove(item)}
                    />
                </LocalFileItem>
            </div>
        );
    });
    let disable = btnDisable ? "disable" : "shitTrader"
    return (
        <LocalModal
            title="Загрузка файлов"
            visible={props.isOpenModal}
            onOk={() => props.setOpenModal(false)}
            onCancel={() => props.setOpenModal(false)}
            centered={true}
            footer={
                <StartUploadButton>
                    <Button disabled={btnDisable} className={disable} width={"100%"} onClick={startUpload}>
                        <p>Загрузить в справочник</p>
                    </Button>
                </StartUploadButton>
            }
        >
            <Dragger {...uploadSettings}>
                <p className="ant-upload-drag-icon">
                    <DropUploadIcon />
                </p>
                <p className="ant-upload-hint">Перетащите ваши файлы сюда</p>
                <p className="ant-upload-hint">
                    Или <LocalSpan>Выберите</LocalSpan>
                </p>
            </Dragger>
            <LocalFileContent>{uploadItemsContent}</LocalFileContent>
        </LocalModal>
    );
};
