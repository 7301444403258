import styled from "styled-components";

function getBackgroundColor(props = {}) {
  const { color = null } = props;
  switch (color) {
    case "white": {
      return props.theme.colors.defaultLighter;
    }
    default: {
      return props.theme.colors.defaultLight;
    }
  }
}

const ExtendedStyledInput = styled.div`
  width: 100%;
  & .ant-input-suffix {
    right: 90%;
  }

  & .ant-input-suffix i {
    color: rgba(27, 53, 72, 0.9);
  }

  & .ant-input {
    background: ${(props) => getBackgroundColor(props)};
    padding-left: 35px;
    border-radius: ${(props) => props.theme.decoration.defaultBorderRadius};
    color: ${(props) => props.theme.colors.neutralDarker};
    height: 40px;
    border: none;
  }
`;

export const Styled = { ExtendedStyledInput };
