import React from 'react';
import PropTypes from 'prop-types';
import {connect} from "react-redux";
import CompassButton from "./CompassButton";
import {setMapBearing, setMapToNorth, toggle3D} from "../../../../../../reducers/map/actions/mapboxActions";

const CompassButtonContainer = ({viewState,toggle3D,setMapToNorth,setMapBearing}) => {

    const toggle3DHandler = (event) => {
        event.stopPropagation()
        toggle3D()
    }
    const toggleBearingHandler = (event) => {
        event.stopPropagation()
        setMapToNorth()
    }

    const setMapBearingHandler = (event) => {
        event.preventDefault()
        const initialY = 675;
        const deltaBearing = event.clientY - initialY;
        setMapBearing(deltaBearing)
    }

    return (
        <>
            <CompassButton viewState={viewState} toggle3DHandler={toggle3DHandler} toggleBearingHandler={toggleBearingHandler} setMapBearingHandler={setMapBearingHandler}/>
        </>
    );
};

const mapStateToProps = (state) => {
    return {
        viewState: state.mapboxView.viewState
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        toggle3D: () => dispatch(toggle3D()),
        setMapToNorth: () => dispatch(setMapToNorth()),
        setMapBearing: (value) => dispatch(setMapBearing(value))
    }
}

CompassButtonContainer.propTypes = {
  viewState: PropTypes.object
}

export default connect(mapStateToProps,mapDispatchToProps)(CompassButtonContainer);
