import React from "react";
import { Styled } from "./GlobalLoader.styled";
import LogoNipigazAnimation from "../../../icons/preloaders/LogoNipigazAnimation";

const GlobalLoader = ({ show }) => {
  if (show) {
    return (
      <Styled.LocalMainWrapper>
        <Styled.LocalMainInner>
          <LogoNipigazAnimation />
        </Styled.LocalMainInner>
      </Styled.LocalMainWrapper>
    );
  }
  return null;
};

export default GlobalLoader;
