import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {getFromURL} from "../../../../api/getFromURL";
import PrepareScrollableInfoCard from "../PrepareScrollableInfoCard";
import {registeredDirectoryUrlsEnum} from "../../../../enums/registeredDirectoryUrlsEnum";


const CargoItemSelectedItemDescription = ({
                                            handleCloseMapSelectedObjectInfo,
                                            selectedMapItem,
                                            data,
                                          }) => {
  const [currentLocation, setCurrentLocation] = useState(null);

  useEffect(() => {
    if (data.last_transfer_part_id) {
      getFromURL
          .getById("/tmc_tracking/transfer_part/", data.last_transfer_part_id)
          .then((res) => {
            setCurrentLocation(res);
          });
    }
  }, []);

  const handleOpenObjectInNewTab = () => {
    const roadData = selectedMapItem.data.find(
        (dataRecord) => dataRecord.name === "cargo"
    );
    const { id } = roadData.data;
    const win = window.open(
        `/app/directory/${registeredDirectoryUrlsEnum.tmc_tracking}/${id}/`,
        "_blank"
    );
    win.focus();
  };

  const { t } = useTranslation("common");
  let Descriptions = [];
  data?.vendor &&
  Descriptions.push({
    key: t("Поставщик"),
    value: data.vendor,
  });

  data?.name &&
  Descriptions.push({
    key: t("Описание"),
    value: data.name.length > 100 ? `${data.name.substring(0,100)}...`: data.name,
  }); //если длина более 100, режем и добавляем многоточие

  data?.invoice &&
  Descriptions.push({
    key: t("Инвойс"),
    value: data.invoice,
  });

  data?.packing_list_number &&
  Descriptions.push({
    key: t("Номер упаковочного"),
    value: data.packing_list_number,
  });

  data?.transfer_parts[data.transfer_parts.length-1]?.truck?.registration_number &&
  Descriptions.push({
    key: t("ТС на последнем плече"),
    value: data.transfer_parts[data.transfer_parts.length-1].truck.registration_number,
  });

  !data?.transfer_parts[data.transfer_parts.length-1]?.truck?.registration_number &&
    Descriptions.push({
      key: t("ТС на последнем плече"),
      value: "-",
    });


  data?.unload_date &&
  Descriptions.push({
    key: t("Дата отправления"),
    value: data.unload_date,
  });
  //


  Descriptions.push({
    key: t("Прогнозируемая дата прибытия"),
    value: data.forecasted_warehouse_arrival_date || "-"
  });


  Descriptions.push({
    key: t("Фактическая дата прибытия"),
    value: data.factual_warehouse_arrival_date || "-"
  });

  // data?.name &&
  // Descriptions.push({
  //   key: t("Товар"),
  //   value: data.name,
  // });
  //
  // currentLocation?.location_point?.name &&
  // Descriptions.push({
  //   key: t("Текущее местоположение"),
  //   value: currentLocation?.location_point?.name,
  // });
  //
  // data?.order_number &&
  // Descriptions.push({
  //   key: t("Номер заказа"),
  //   value: data.order_number,
  // });
  //
  // data?.packing_list_number &&
  // Descriptions.push({
  //   key: t("Складской номер"),
  //   value: data.packing_list_number,
  // });
  //
  //
  //
  // data?.country_name &&
  // Descriptions.push({
  //   key: t("Страна отправления"),
  //   value: data.country_name,
  // });
  //
  // data?.departure_place_name &&
  // Descriptions.push({
  //   key: t("Город отправления"),
  //   value: data.departure_place_name,
  // });
  //
  // data?.net_weight &&
  // Descriptions.push({
  //   key: t("Вес груза"),
  //   value: data.net_weight,
  // });
  //
  // data?.volume &&
  // Descriptions.push({
  //   key: t("Объём"),
  //   value: data.volume,
  // });
  //
  // if (data?.transfer_parts) {
  //   const parts = data.transfer_parts.sort((a, b) => a.order - b.order);
  //   let index = 1;
  //   for (let part of parts) {
  //     Descriptions.push({
  //       key: t(`Точка следования ${index}`),
  //       value: part.location_point_from?.name,
  //     });
  //     index++;
  //   }
  // }
  //
  // if (data?.transfer_parts) {
  //   Descriptions.push({
  //     key: t(`Точка следования 4`),
  //     value: data?.transfer_parts?.[2]?.location_point_to?.name,
  //   });
  // }

  return (
      <PrepareScrollableInfoCard
          title={t(
              "app.menuMap.scrollableInfoCard.header.trackingTMC"
          )}
          handleCloseCard={handleCloseMapSelectedObjectInfo}
          handleOpenItemInDirectory={handleOpenObjectInNewTab}
          listOfDescriptions={Descriptions}
          openInNewTabBtnDesc={t("app.menuMap.dataVehicle.openInDirectory")}
      />
  );
};

CargoItemSelectedItemDescription.propTypes = {};

export default CargoItemSelectedItemDescription;
