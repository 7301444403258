import React from "react";
import LayerDataTable from "./LayerDataTable/LayerDataTable";

const LayersDataAccessor = ({
  isVisible,
  dataAccessorClass,
  data,
  resetFilter,
  model,
  quickMenu,
  containerCallbacks,
  aggApi,
  handleDateRangeChange,
  setLayerDataAccessorVisibility,
  selectedMapItem,
  handleSetActiveMapboxDataReplacingForcedStatus,
  handleGoToEditMode,
  selectedMapLayer,
}) => {
  return (
    <>
      {isVisible && (
        <LayerDataTable
          className="box"
          data={data}
          resetFilter={resetFilter}
          model={model}
          aggApi={aggApi}
          dataAccessorClass={dataAccessorClass}
          containerCallbacks={containerCallbacks}
          handleDateRangeChange={handleDateRangeChange}
          quickMenu={quickMenu}
          setLayerDataAccessorVisibility={setLayerDataAccessorVisibility}
          selectedMapItem={selectedMapItem}
          handleSetActiveMapboxDataReplacingForcedStatus={
            handleSetActiveMapboxDataReplacingForcedStatus
          }
          handleGoToEditMode={handleGoToEditMode}
          selectedMapLayer={selectedMapLayer}
        />
      )}
    </>
  );
};

LayersDataAccessor.propTypes = {};

export default LayersDataAccessor;
