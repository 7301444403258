import {CompositeLayer} from "@deck.gl/core"
import {IconLayer, TextLayer} from "@deck.gl/layers"
import {TripsLayer} from "@deck.gl/geo-layers"
import _ from "underscore";
import {transportIconMapper} from "../../iconMappers/transportIconMapper";
import {getColorInRangeLinear} from "../../../../services/data/getColorInRangeLinear";


const getActiveVehicles = (vehicleList, deviceTracks) => {
    return deviceTracks.map(track => vehicleList.find(vehicle => vehicle.id === track[0].vehicle_id))
}

const getRegistrationNumbers = (vehicleList, deviceTracks) => {
    return deviceTracks.map(track => {
        const car = vehicleList.find(vehicle => vehicle?.id === track[0]?.vehicle_id)
        return car?.registration_number.replace(' ', '').toUpperCase()
    })
}

const getCarType = (vehicleList, deviceTracks) => {
    return deviceTracks.map(track => {
        const car = vehicleList.find(vehicle => vehicle?.id === track[0]?.vehicle_id)
        return car?.vehicle_type_name
    })
}

const selectPositionOnTimestamp = (deviceTrack, currentTimeStamp) => {
    const initialTime = new Date(deviceTrack[0].datetime)
    const suitablePoint = _.find(deviceTrack, (el => {
        const momentThis = new Date(el.datetime)
        const delta = ~~(momentThis - initialTime) / 1000;
        return delta >= currentTimeStamp
    }))
    if (suitablePoint) {
        return [suitablePoint.longitude, suitablePoint.latitude]
    } else {
        const other = _.filter(deviceTrack, (el => {
            const momentThis = new Date(el.datetime)
            const delta = ~~(momentThis - initialTime) / 1000;
            return delta <= currentTimeStamp
        }))
        const lastMark = _.last(other)
        return [lastMark.longitude, lastMark.latitude]
    }
}

export const getPath = (d) => {

    const pathMap = d?.map(p => {
        return [p.longitude, p.latitude]
    })
    return pathMap
}

export const getTimestamps = (d, i) => {
    if (d) {
        const initialDate = new Date(d?.[0]?.datetime)

        const timestamps = d?.map(p => {
            const currentDate = new Date(p.datetime)
            return ~~((currentDate - initialDate) / 1000)
        })
        return timestamps
    }

    return null
}

const getColorOnSpeed = (data) => {
    const {speed} = data;
    if (speed > 90) {
        return [217, 51, 2, 72]
    } else if (speed > 60 && speed <= 90) {
        return [219, 214, 61, 86]
    } else if (speed > 10 && speed <= 60) {
        return [29, 184, 65, 94]
    } else {
        return [108, 216, 240, 94]
    }
}

export const getColorOnIndex = (d, i) => {
    const {index} = i;
    const size = i.data.length;
    if (size === 1) {
        return getColorOnSpeed(d)
    } else if (d) {
        return getColorInRangeLinear(index, size)
    }
    return null
}

class TrackingCompositeLayer extends CompositeLayer {
    updateState({changeFlags}) {
        if (!this.props.relatedData?.vehicles) return;
        const currentTimeStamp = this.props.getCurrentTimeStamp
        const {data, relatedData} = this.props;
        const activeVehicles = getActiveVehicles(relatedData.vehicles, data);
        const registrationNumbers = getRegistrationNumbers(activeVehicles, data)
        const carTypes = getCarType(activeVehicles, data)
        const locations = data.map(track => selectPositionOnTimestamp(track, currentTimeStamp))
        const labels = [];
        for (let it in registrationNumbers) {
            labels.push({
                registrationNumber: registrationNumbers[it],
                location: locations[it],
                carType: carTypes[it]
            })
        }
        this.setState({labels})


        this.setState({currentTimeStamp})
        const preparedData = this.props.data;
        for (let carIndex in preparedData){
            for (let markIndex in preparedData[carIndex]){
                preparedData[carIndex][markIndex].color = getColorOnSpeed(preparedData[carIndex][markIndex])
            }

        }
        this.setState({preparedData})
    }

    renderLayers() {
        if (!this.props.relatedData) return []
        return [
            new TripsLayer(this.props, this.getSubLayerProps({id: 'tracking-path-layer'}), {
                data: this.state.preparedData,
                getPath: d => getPath(d),
                getTimestamps: (d, i) => getTimestamps(d, i),
                currentTime: this.state.currentTimeStamp,
                getLineWidth: 5,
                opacity: 0.8,
                widthMinPixels: 5,
                rounded: true,
                dataAccessor: null,
                getColor: (d, index) => d.map(el=>el.color)
                // currentTime: this.props.data.currentTimeStamp
            }),
            new TextLayer(this.props, this.getSubLayerProps({id: 'text-layer'}), {
                data: this.state.labels,
                billboard: true,
                sizeUnits: this.props.labelSizeUnits,
                backgroundColor: this.props.labelBackground,
                getPosition: d => d.location,
                getText: d => d.registrationNumber,
                getTextAnchor: 'middle',
                getAlignmentBaseline: 'center',
                sizeMinPixels: 16,
                sizeMaxPixels: 16,
                getPixelOffset: () => [0, 32],
                // getText:d=>'АПРПАОВ',
                getSize: this.getSubLayerAccessor(this.props.getLabelSize),
                getColor: this.getSubLayerAccessor(this.props.getLabelColor),
            }),
            new IconLayer(this.props, this.getSubLayerProps({id: 'transport-type-icon-layer'}), {
                pickable: true,
                data: this.state.labels,
                iconAtlas: `${process.env.PUBLIC_URL}/img/textures/vehiclesTypes.png`,
                iconMapping: transportIconMapper,
                getIcon: (d) => d.carType,
                sizeScale: 1,
                billboard: true,
                getPosition: d => d.location,
                getSize: () => 56,
                sizeMinPixels: 56,
                sizeMaxPixels: 56,
            }),

        ]
    }

}

const defaultProps = {
    // Inherit all of GeoJsonLayer's props
    ...TextLayer.defaultProps,
    // Label for each feature
    getLabel: {type: 'accessor', value: x => x},
    // Label size for each feature
    getLabelSize: {type: 'accessor', value: 8},
    // Label color for each feature
    getLabelColor: {type: 'accessor', value: [0, 0, 0, 255]},
    getCurrentTimeStamp: {type: 'accessor', value: 0},
    // Label always facing the camera
    billboard: true,
    // Label size units
    labelSizeUnits: 'pixels',
    // Label background color
    labelBackground: {type: 'color', value: [255, 255, 255], optional: true},
    // Label font
    fontFamily: 'Monaco, monospace',
}

TrackingCompositeLayer.layerName = 'TrackingLayer';
TrackingCompositeLayer.defaultProps = defaultProps;

export default TrackingCompositeLayer


