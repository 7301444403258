import React from 'react';
import {Select} from "antd";
import {mapboxDefaultMapStylesEnum} from "../../../../../../../../../../../enums/mapboxDefaultMapStylesEnum";
import {rasterTileStylesEnum} from "../../../../../../../../../../../enums/rasterTileStylesEnum";

const {Option} = Select

const {collection, selectItem} = rasterTileStylesEnum

const RASTER_TILE_STYLES = collection

const MapRasterStyleSelector = ({currentStyle,handleMapStyleChange}) => {
    return (
            <Select
                // style={{width:'100%'}}
                // open={true}
                value={selectItem(RASTER_TILE_STYLES,currentStyle)}
                onChange={value => {
                    handleMapStyleChange(value)
                }}
                bordered={false}
            >
                {RASTER_TILE_STYLES.map(style => (
                    <Option key={style.value} value={style.value}>
                        {style.label}
                    </Option>
                ))}
            </Select>
    );
};

export default MapRasterStyleSelector;
