import React, {Component} from "react";
import {Link} from "react-router-dom";

export default class ObjectCellRenderer extends Component {
    constructor(props) {
        super(props);

        this.invokeParentMethod = this.invokeParentMethod.bind(this);
    }

    invokeParentMethod() {
        const field = this.props.colDef.additionalData;
        const data = this.props.data[field];
    }

    render() {
        const field = this.props.colDef?.additionalData?.url?.label;
        if (this.props.data && field && this.props.data[field+'_detail'].length){
            const link = this.props.data[field+'_detail'].split('/api/')[1]
            return (
                <Link  to={'/app/directory/'+link}  style={{height: 20, lineHeight: 0.5}} onClick={this.invokeParentMethod} className="btn btn-info">{this.props.data[field+'_name']}</Link>
            );
        } else return null

    }
};
