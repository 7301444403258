import {length, lineSliceAlong} from "@turf/turf"
import {getArrayFromNumber} from "../common/getArrayFromNumber";

export const sliceLineIntoSegments = (lineGeometry, segmentsQuantity = 1) => {
    if(lineGeometry) {
        const commonLength = length(lineGeometry)
        const oneSegmentLength = commonLength / segmentsQuantity
        return getArrayFromNumber(segmentsQuantity).map((number, index) => {
            return (
                lineSliceAlong(lineGeometry, oneSegmentLength * index, oneSegmentLength * (index + 1))
            );
        })

    }
    else {
        console.error('wrong data in sliceLineIntoSegments fn')
        return null
    }
}