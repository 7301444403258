import React, {useState} from "react";
import {connect} from 'react-redux'
import {userPasswordRecovery} from '../../../../reducers/app/actions/authActions'
import {Button, Input} from "antd";
import {LockOutlined, UserOutlined} from "@ant-design/icons";
import { useHistory } from "react-router-dom";
import PasswordInput from "../../../../library/nonformedInputs/PasswordInput";
import {useTranslation} from "react-i18next";
import "./PasswordReset.style";
import {Styled} from "../PasswordRemember/PasswordRemember.style";

const {
    LocalMainWrapper,
    LocalFormWrapper,
    LocalFormTitle,
    LocalFormSubmit,
    LocalInputWrapperPassword,
    LocalInputWrapperRepeatPassword,
    ExtendedButton,

} = Styled

const PasswordReset = (props) => {
    const {uid, token} = props;
    const [userData, setUserData] = useState({
        uid,
        token,
        password: "",
        passwordConfirm: ""
    });
    const [focused, setFocused] = useState({
        password: false,
        passwordConfirm: false,
    });
    const [errMessage,setErrMessage] = useState(null)
    const { t } = useTranslation('common');
    const history = useHistory();
    const handleFocus = event => {
        setFocused({
            ...userData,
            [event.target.name]: true
        })
    }

    const handleBlur = event => {
        setFocused({
            ...userData,
            [event.target.name]: false
        })
    }

    const handleChange = event => {
        setUserData({
            ...userData,
            [event.target.name]: event.target.value
        })
    }

    const handleSubmit = event => {
        event.preventDefault()
        const result = props.userPasswordRecovery(userData)
        if (result){
            history.replace('/')
            setErrMessage(null)
        } else {
            setErrMessage('Что-то пошло не так')
        }
    }

    return (
        <LocalMainWrapper>
                {(uid && token) &&
                <LocalFormWrapper>
                    <LocalFormTitle className={'login-form__title'}>
                        {t('guest.login.titleReset')}
                    </LocalFormTitle>
                    <LocalFormSubmit onSubmit={handleSubmit}>
                        <LocalInputWrapperPassword>
                            <PasswordInput
                                name='password'
                                placeholder="Пароль"
                                prefix={<LockOutlined/>}
                                onChange={handleChange}
                                onFocus={handleFocus}
                                onBlur={handleBlur}
                                style={{margin: '0 auto'}}
                            />
                            <span className={`inline-placeholder-login 
                        ${userData.password ? 'open' : 'close'}
                        ${focused.password ? 'focused' : ''}
                        `}>{t('guest.login.passwordNew')}</span>
                        </LocalInputWrapperPassword>
                        <LocalInputWrapperRepeatPassword>
                            <PasswordInput
                                name='passwordConfirm'
                                placeholder="Повторите пароль"
                                prefix={<LockOutlined/>}
                                onChange={handleChange}
                                onFocus={handleFocus}
                                onBlur={handleBlur}
                                style={{margin: '0 auto'}}
                            />
                            <span className={`inline-placeholder-login 
                        ${userData.passwordConfirm ? 'open' : 'close'}
                        ${focused.passwordConfirm ? 'focused' : ''}
                        `}>{t('guest.login.passwordNewConfirm')}</span>
                        </LocalInputWrapperRepeatPassword>
                        <ExtendedButton type='submit' onClick={handleSubmit}>
                            {t('guest.login.passwordNewSubmit')}
                        </ExtendedButton>
                    </LocalFormSubmit>
                    {errMessage && <p>{errMessage}</p>}
                </LocalFormWrapper>
                }
                {(!uid || !token) &&
                <div className={'login-form'}>
                    <h3 className={'login-form__title'}>{t('guest.errors.notValidLink')}</h3>
                    <Button type="link" href={'/login'}>{t('guest.errors.backLink')}</Button>
                </div>
                }
        </LocalMainWrapper>
    )
}

// Todo style and functional
const mapStateToProps = state => ({})

const mapDispatchToProps = dispatch => ({
    userPasswordRecovery: userInfo => dispatch(userPasswordRecovery(userInfo))
})

export default connect(mapStateToProps, mapDispatchToProps)(PasswordReset)
