import React, { useState } from "react";
import { Layout } from "antd";
import { Redirect, useRouteMatch } from "react-router-dom";
import HeaderContainer from "./Header/HeaderContainer";
import ErrorMessageContainer from "../ErrorMessage/ErrorMessageContainer";
import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";
import { useTranslation } from "react-i18next";
import { ColorThemeProvider } from "./ColorThemeProvider";
import protect from "../../HOC/protect";
import { useWhyDidYouUpdate } from "react-recipes";
import DirectoryRouter from "./Directory/DirectoryRouter/DirectoryRouter";
import MapboxContainer from "./Map/MapboxContainer";
import SidebarContainer from "../../library/Sidebar/SidebarContainer";
import { Styled } from "./Application.styled";
import HomePageContainer from "./HomePage/HomePageContainer";
import { connect } from "react-redux";
import { toggleSidebarPosition } from "../../reducers/sidebar/actions/sidebarActions";
import GlobalLoader from "../../library/UI/components/GlobalLoader/GlobalLoader";
import CustomAlert from "../../library/UI/components/CustomAlert/CustomAlert";
import { clearGlobalAlertData } from "../../reducers/app/actions/notificationActions";
import ReportsRouter from "./Reports/ReportsRouter/ReportsRouter";
import FileUploaderErrorReport from "./FileUploaderErrorReport/FileUploaderErrorReport";
import { alertStatusesEnum } from "../../enums/alertStatusesEnum";
import PromptNotifications from "../../library/UI/components/Alert/PromptNotifications/PromptNotifications";
import { promptStatusesEnum } from "../../enums/promptStatusesEnum";
import {initializeSingleObjectEditMode} from "../../sagas/map/standaloneObjectEditableSaga";
import {onMapAutoCreateDetourMode_OFF} from "../../reducers/map/actions/mapAutoCreateDetourActions";

const { Sider } = Layout;
const {
  ApplicationContent,
  ApplicationContentInner,
  ApplicationHeader,
} = Styled;

/**
 * Initial component for providing urls of directories root folders
 * @param sidebarIsOpened - Redux статус открытия боковой панели
 * @param toggleSidebarPosition - Action на изменение статуса открытия боковой панели
 * @param props
 * @param isFetching -
 * @param alertData
 * @param promptData
 * @param clearGlobalAlertData
 * @returns component for directory work.
 * @constructor none
 */

const Application = ({
  sidebarIsOpened,
  toggleSidebarPosition,
  props,
  isFetching,
  alertData,
  clearGlobalAlertData,
  initializeSingleObjectEditMode,
  promptData,
  closureId,
  mapAutoCreateDetourOff,
}) => {
  //dev
  useWhyDidYouUpdate("Application", props);

  const [urlForCompendium, setUrlToCompendium] = useState(null);
  const { t } = useTranslation("common");

  const matchOpenDirectory = useRouteMatch("/app/directory");
  const matchOpenMap = useRouteMatch("/app/map");
  const matchOpenReport = useRouteMatch("/app/reports");
  const mathOpenFileUploaderErrorReport = useRouteMatch(
    "/app/tmc_report_upload_errors/:id"
  );
  const mathOpenHomePage = useRouteMatch("/app");
  if (matchOpenDirectory && urlForCompendium !== matchOpenDirectory.params.url)
    setUrlToCompendium(matchOpenDirectory.params.url);

  const userSession = localStorage.getItem("user");

  const onCloseHandler = () => {
    if (closureId?.closureId) mapAutoCreateDetourOff();
    clearGlobalAlertData()
  }

  const handleClickForAutoCreateDetour = () => {
    initializeSingleObjectEditMode(null, 'segment', 'roadsSchema', null, closureId?.closureId);
    mapAutoCreateDetourOff();
    alertData.title="Режим создания объезда"
  }

  return (
    <Layout style={{ position: "relative" }}>
      {!userSession && <Redirect to={"guest/login"} />}
      <Sider
        collapsed={sidebarIsOpened}
        collapsible
        trigger={null}
        width={"13%"}
        collapsedWidth={70}
        style={{ height: "calc(100vh)", zIndex: 5000 }}
      >
        <SidebarContainer
          toggleSidebarPosition={toggleSidebarPosition}
          sidebarIsOpened={sidebarIsOpened}
        />
      </Sider>
      <Layout>
        <GlobalLoader show={isFetching} />
        <CustomAlert
          portalSide={"bottom-right"}
          mb={"2rem"}
          mr={"2rem"}
          isOpen={alertData.status === alertStatusesEnum.active}
          type={alertData.type}
          title={alertData.title}
          message={alertData.message}
          closureId={closureId?.closureId} //это отправляем в компонент, чтобы отрисовалось окно с предложением создать объезд
          size={{ width: "400px" }}
          onClose={onCloseHandler}
          handleClickForAutoCreateDetour={handleClickForAutoCreateDetour}

        />
        <PromptNotifications
          mt={"7.5rem"}
          portalSide={"top-center"}
          promptData={promptData}
          isOpen={promptData.status === promptStatusesEnum.active}
        />
        <ApplicationHeader>
          <HeaderContainer
            toggleSidebarPosition={toggleSidebarPosition}
            sidebarIsOpened={sidebarIsOpened}
          />
        </ApplicationHeader>
        <ApplicationContent>
          <ApplicationContentInner>
            <ErrorMessageContainer />
            <BreadcrumbsItem to="/">
              {t("app.breadcrumbs.index")}
            </BreadcrumbsItem>
            {!matchOpenDirectory &&
            !matchOpenMap &&
            !matchOpenReport &&
            !mathOpenFileUploaderErrorReport &&
            mathOpenHomePage ? (
              <HomePageContainer />
            ) : (
              ""
            )}
            {matchOpenDirectory && (
              <DirectoryRouter sidebarIsOpened={sidebarIsOpened} />
            )}
            {matchOpenMap && (
              <MapboxContainer sidebarIsOpened={sidebarIsOpened} />
            )}
            {matchOpenReport && (
              <ReportsRouter sidebarIsOpened={sidebarIsOpened} />
            )}
            {mathOpenFileUploaderErrorReport && <FileUploaderErrorReport />}
          </ApplicationContentInner>
        </ApplicationContent>
      </Layout>
    </Layout>
  );
};

const mapStateToProps = (state) => {
  return {
    sidebarIsOpened: state.sidebarPosition.sidebarIsOpened,
    isFetching: state.app.fetching,
    alertData: state.notifications.alert,
    promptData: state.notifications.notification,
    closureId: state.mapAutoCreateDetour,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    toggleSidebarPosition: () => dispatch(toggleSidebarPosition()),
    clearGlobalAlertData: () => dispatch(clearGlobalAlertData()),
    mapAutoCreateDetourOff: () => dispatch(onMapAutoCreateDetourMode_OFF()),
    initializeSingleObjectEditMode: (selectedObject, typeOfObject, objectLayerName, initialCoordinate, closureId) => dispatch (initializeSingleObjectEditMode (selectedObject, typeOfObject, objectLayerName, initialCoordinate, closureId))
  };
};

let ApplicationContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(Application);

const ThemedApplication = () => {
  return (
    <ColorThemeProvider>
      <ApplicationContainer />
    </ColorThemeProvider>
  );
};

export default protect(ThemedApplication);
