import styled from "styled-components";

const LocalInnerWrapper = styled.div`
  padding-top: 30px;
`;

const LocalMainWrapper = styled.div`
  padding-top: 60px;
`;
export const Styled = { LocalMainWrapper, LocalInnerWrapper };
