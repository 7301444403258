import React, {useEffect, useState} from "react";
import {userSettingApi} from "../../api/userSettingApi";
import {ThemeProvider} from "styled-components";

const defaultColorScheme = {
    mainColor: 'red',
    secondaryColor: '#zzz',

}

const getColorScheme = async () => {
    const data =await userSettingApi.getSettingByField('app.colors')
    // const created =await userSettingApi.createSettingByField('app.colors',defaultColorScheme)
    // const data =await userSettingApi.updateSettingByField('app.colors',defaultColorScheme)
    // const deleteTest =await userSettingApi.deleteUserSettings()
    return data?.data ?? defaultColorScheme;
}

export const ColorThemeProvider = ({children}) => {
    const [theme,setTheme] = useState({});
    useEffect(()=>{
        getColorScheme().then(
            result => setTheme(result)
        )
    },[])
    return (
        <ThemeProvider theme={theme}>
            {children}
        </ThemeProvider>
    )
}

