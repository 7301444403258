import React from "react";
import Mapbox from "./Mapbox";
import {connect} from 'react-redux'

/**
 * Контейнерная компонента для Mapbox
 * @param dataAccessor - проверить необходимость
 * @param standaloneEditModeEnabled - проверить необходимость
 * @return {React.JSXElement} Mapbox
 * @see Mapbox
 */
const MapboxContainer = ({dataAccessor, standaloneEditModeEnabled}) => {

    return (
        <Mapbox dataAccessor={dataAccessor} standaloneEditModeEnabled={standaloneEditModeEnabled}/>
    )
}

const mapStateToProps = (state) => {
    return {
        dataAccessor: state.mapPanels.dataAccessor,
        standaloneEditModeEnabled: state.mapboxEditableStandaloneObject?.enabled
    }
}
export default connect(mapStateToProps,null)(MapboxContainer)
