import {RelatedDataPrototype} from "../prototype/RelatedDataPrototype";

export class Roads extends RelatedDataPrototype {

    constructor() {
        super({
            name: 'roads',
            filterLabel: 'Дороги',
            urlData: '/roads/',
        });
    }
}
