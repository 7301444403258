import SidebarItem from "../SidebarItem/SidebarItem";
import { HomeIcon } from "../../icons/objectIcons/HomeIcon";
import React from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { LocationIcon } from "../../icons/objectIcons/LocationIcon";
import { Styled } from "./SidebarCategory.styled";
import { FolderIcon } from "../../icons/objectIcons/FolderIcon";
import { useHistory } from "react-router-dom";
import { DocumentIcon } from "../../icons/objectIcons/DocumentIcon";

const SidebarCategory = ({ sidebarIsOpened }) => {
  const { t } = useTranslation("common");
  const location = useLocation();
  const history = useHistory();
  return (
    <Styled.CategoryWrapper>
     {/* <SidebarItem
        partition={"/app"}
        key={`home`}
        url={"app"}
        to={"/"}
        icon={<HomeIcon />}
        onClick={() => history.replace("/app/")}
        sidebarIsOpened={sidebarIsOpened}
        active={location.pathname === `/app` || location.pathname === `/app/`}
      >
        {t("app.breadcrumbs.index")}
      </SidebarItem>*/}
      <SidebarItem
        partition={"/app"}
        key={`map-main`}
        url={"/map"}
        to={"map"}
        icon={<LocationIcon />}
        onClick={() => history.replace("/app/map/")}
        sidebarIsOpened={sidebarIsOpened}
        active={location.pathname === `/app/map/`}
      >
        {t("app.breadcrumbs.maps")}
      </SidebarItem>
      <SidebarItem
        partition={"/app"}
        to={"/directory"}
        key={`directory`}
        url={"directory"}
        icon={<FolderIcon />}
        onClick={() => history.replace("/app/directory/")}
        sidebarIsOpened={sidebarIsOpened}
        active={location.pathname === `/app/directory/`}
      >
        {t("app.breadcrumbs.directory")}
      </SidebarItem>
      <SidebarItem
        partition={"/app"}
        to={"/reports"}
        key={`reports`}
        url={"reports"}
        icon={<DocumentIcon />}
        onClick={() => history.replace("/app/reports/")}
        sidebarIsOpened={sidebarIsOpened}
        active={location.pathname === `/app/reports/`}
      >
        {t("app.breadcrumbs.reports")}
      </SidebarItem>
    </Styled.CategoryWrapper>
  );
};

export default SidebarCategory;
