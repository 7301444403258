export const signsMapperType6 = {
    "6.1": {
        "x": 2803,
        "y": 2001,
        "width": 1500,
        "height": 2250
    },
    "6.2": {
        "x": 2202,
        "y": 3307,
        "width": 600,
        "height": 600
    },
    "6.3.1": {
        "x": 0,
        "y": 601,
        "width": 700,
        "height": 700
    },
    "6.3.2": {
        "x": 4304,
        "y": 1882,
        "width": 700,
        "height": 700
    },
    "6.4": {
        "x": 701,
        "y": 601,
        "width": 600,
        "height": 600
    },
    "6.5": {
        "x": 1601,
        "y": 2706,
        "width": 600,
        "height": 600
    },
    "6.6": {
        "x": 2202,
        "y": 2706,
        "width": 600,
        "height": 600
    },
    "6.7": {
        "x": 701,
        "y": 0,
        "width": 600,
        "height": 600
    },
    "6.8.1": {
        "x": 1601,
        "y": 3307,
        "width": 600,
        "height": 600
    },
    "6.8.2": {
        "x": 5005,
        "y": 1882,
        "width": 600,
        "height": 600
    },
    "6.8.3": {
        "x": 0,
        "y": 0,
        "width": 600,
        "height": 600
    },
    "6.9.1_D": {
        "x": 4304,
        "y": 2934,
        "width": 1750,
        "height": 1000
    },
    "6.9.2_C": {
        "x": 4304,
        "y": 981,
        "width": 1600,
        "height": 900
    },
    "6.9.3": {
        "x": 4304,
        "y": 3935,
        "width": 1200,
        "height": 1200
    },
    "6.10.1_A": {
        "x": 4304,
        "y": 0,
        "width": 1780,
        "height": 980
    },
    "6.10.2_A": {
        "x": 1051,
        "y": 2004,
        "width": 1420,
        "height": 300
    },
    "6.11_B": {
        "x": 3238,
        "y": 4707,
        "width": 1050,
        "height": 350
    },
    "6.12_A": {
        "x": 0,
        "y": 4356,
        "width": 2186,
        "height": 801
    },
    "6.13": {
        "x": 2472,
        "y": 2004,
        "width": 300,
        "height": 200
    },
    "6.14.1_C": {
        "x": 0,
        "y": 1302,
        "width": 700,
        "height": 350
    },
    "6.14.2_A": {
        "x": 2187,
        "y": 4707,
        "width": 1050,
        "height": 350
    },
    "6.15.1": {
        "x": 0,
        "y": 1653,
        "width": 1050,
        "height": 350
    },
    "6.15.2": {
        "x": 2187,
        "y": 4356,
        "width": 1050,
        "height": 350
    },
    "6.15.3": {
        "x": 3238,
        "y": 4356,
        "width": 1050,
        "height": 350
    },
    "6.16": {
        "x": 1601,
        "y": 2355,
        "width": 1050,
        "height": 350
    },
    "6.17": {
        "x": 0,
        "y": 2355,
        "width": 1600,
        "height": 2000
    },
    "6.18.1": {
        "x": 0,
        "y": 2004,
        "width": 1050,
        "height": 350
    },
    "6.18.2": {
        "x": 4304,
        "y": 2583,
        "width": 1050,
        "height": 350
    },
    "6.18.3": {
        "x": 1601,
        "y": 3908,
        "width": 1050,
        "height": 350
    },
    "6.19.1": {
        "x": 1302,
        "y": 0,
        "width": 1500,
        "height": 2000
    },
    "6.19.2": {
        "x": 2803,
        "y": 0,
        "width": 1500,
        "height": 2000
    }
}
