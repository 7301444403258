import React from "react";
import { Icon } from "antd";

const FilterIconSVG = () => (
  <svg
    width="17"
    height="19"
    viewBox="0 0 17 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11 16.88C11.04 17.18 10.94 17.5 10.71 17.71C10.6175 17.8027 10.5076 17.8762 10.3866 17.9264C10.2657 17.9766 10.136 18.0024 10.005 18.0024C9.87404 18.0024 9.74436 17.9766 9.62338 17.9264C9.50241 17.8762 9.39252 17.8027 9.30001 17.71L5.29001 13.7C5.18094 13.5934 5.09802 13.463 5.04771 13.319C4.9974 13.175 4.98107 13.0213 5.00001 12.87V7.75L0.210009 1.62C0.0476172 1.41153 -0.0256572 1.14726 0.00619692 0.88493C0.038051 0.622602 0.172444 0.383546 0.380009 0.22C0.570009 0.08 0.780009 0 1.00001 0H15C15.22 0 15.43 0.08 15.62 0.22C15.8276 0.383546 15.962 0.622602 15.9938 0.88493C16.0257 1.14726 15.9524 1.41153 15.79 1.62L11 7.75V16.88ZM3.04001 2L7.00001 7.06V12.58L9.00001 14.58V7.05L12.96 2H3.04001Z"
      fill="currentColor"
    />
  </svg>
);

export const FilterIcon = props => (
  <Icon component={FilterIconSVG} {...props} />
);
