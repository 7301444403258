import styled from "styled-components";
import { Button } from "../../../../library/UI/components/Button/Button";
import { NavLink } from "react-router-dom";

const LocalMainWrapper = styled.div`
  .ant-input {
    border-radius: ${(props) => props.theme.decoration.defaultBorderRadius};
    background: ${(props) => props.theme.colors.defaultLight} !important;
    border: 2px solid ${(props) => props.theme.colors.primaryLighter};
    color: ${(props) => props.theme.colors.defaultDark};
    &:hover {
      border: 2px solid ${(props) => props.theme.colors.primaryLighter} !important;
    }
    &:focus {
      border-radius: ${(props) => props.theme.decoration.defaultBorderRadius};
    }
  }

  .ant-input-affix-wrapper .ant-input-prefix :not(.anticon),
  .ant-input-affix-wrapper .ant-input-suffix :not(.anticon) {
    color: ${(props) => props.theme.colors.primary};
  }

  .inline-placeholder-login.close {
    color: ${(props) => props.theme.colors.paragraph};
  }
`;

const LocalMainTitle = styled.h3`
  margin: 0;
  text-align: center;
  font-style: normal;
  font-weight: bold;
  line-height: 29px;
  width: 100%;
  align-items: center;
  font-size: 1.7rem;
  color: ${(props) => props.theme.colors.defaultDark};
`;

const LocalMainInner = styled.div`
  padding: 25px 20px;
  position: absolute;
  top: 28%;
  right: 18%;
  width: 354px;
  height: 448px;
  background-color: ${(props) => props.theme.colors.defaultLighter};
  border-radius: ${(props) => props.theme.decoration.defaultBorderRadius};
  box-shadow: ${(props) => props.theme.decoration.darkBoxShadow};
  & p {
    position: absolute;
    top: 37%;
    left: 6%;
  }

  & a {
    font-family: Open Sans,sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    color: ${(props) => props.theme.colors.primary};
  }

  & .error-message {
    position: absolute;
    top: 59%;
    left: 7%;
    color: ${(props) => props.theme.colors.danger};
    text-align: left;
    background: none;
  }

  & .ant-btn.ant-btn-link {
    font-family: Open Sans,sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    margin-top: 38px;
    color: ${(props) => props.theme.colors.primary};
  }

  .inline-placeholder-login {
    user-select: none;
    pointer-events: none;
    z-index: 1500;
    font-family: Open Sans,sans-serif;
    font-size: 16px;
    color: #bfbfbf;
    //Todo add color in theme??
    position: absolute;
    top: 16px;
    left: 31px;
    transition: all 0.2s;
  }

  .inline-placeholder-login.close.focused {
    position: absolute;
    transform: translate3d(0, -6px, 0);
    font-size: 13px;
  }

  .inline-placeholder-login.open {
    position: absolute;
    transform: translate3d(0, -6px, 0);
    font-size: 13px;
  }
  .inline-placeholder-login.open.focused {
    color: ${(props) => props.theme.colors.paragraph};
  }
`;

const LocalForm = styled.form`
  & + * {
    margin-top: 30px;
  }
`;

const LocalInputLogin = styled.div`
  position: relative;
  width: fit-content;
  margin: 36px auto 36px;
  background: #f1f3f6;
  border-radius: 8px;

  & input:hover {
    border-radius: 8px !important;
  }
`;

const LocalPasswordInput = styled.div`
    position: relative;
    width: fit-content;
    margin: 36px auto 36px;
    background: ${(props) => props.theme.colors.defaultLight};
    border-radius: ${(props) => props.theme.decoration.defaultBorderRadius};

    & input:hover {
      border-radius: ${(props) =>
        props.theme.decoration.defaultBorderRadius}; !important;
    }

`;

const ErrorMessageItem = styled.p``;

const LocalPlaceholderLogin = styled.span`
  &.inline-placeholder-login {
    user-select: none;
    pointer-events: none;
    z-index: 1500;
    font-family: Open Sans,sans-serif;
    font-size: 12px;
    color: #bfbfbf;
    position: absolute;
    top: 16px;
    left: 31px;
    transition: all 0.2s;
  }

  .inline-placeholder-login.close.focused {
    position: absolute;
    transform: translate3d(0, -6px, 0);
    font-size: 13px;
  }

  .inline-placeholder-login.open {
    position: absolute;
    transform: translate3d(0, -6px, 0);
    font-size: 13px;
  }
  .inline-placeholder-login.open.focused {
    color: ${(props) => props.theme.colors.paragraph};
  }
`;

const LocalPlaceholderPassword = styled.span`
  &.inline-placeholder-login {
    user-select: none;
    pointer-events: none;
    z-index: 1500;
    font-family: Open Sans,sans-serif;
    font-size: 12px;
    color: #bfbfbf;
    position: absolute;
    top: 16px;
    left: 31px;
    transition: all 0.2s;
  }
`;

const ExtendedButton = styled(Button)``;

const ExtendedNavLink = styled(NavLink)`
  display: block;
`;


const LocalPrimaryDescription = styled.small`
  display: block;
  font-size: ${(props) => props.theme.fontSettings.defaultLarge};
  color: ${(props) => props.theme.colors.primary};
  text-align: center;
  line-height: 19px;
  opacity: 0.8;
  transition: all 0.3s;
  &:hover {
    opacity: 1;
  }
`;

export const Styled = {
  LocalMainWrapper,
  LocalMainInner,
  LocalForm,
  LocalInputLogin,
  LocalPasswordInput,
  LocalMainTitle,
  LocalPlaceholderLogin,
  LocalPlaceholderPassword,
  LocalPrimaryDescription,
  ExtendedButton,
  ExtendedNavLink,
  ErrorMessageItem,
};
