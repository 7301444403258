import React from "react";
import PropTypes from "prop-types";
import LayersBarContainer from "./LayersBar/LayersBarContainer";
import MapToolBarContainer from "../MapToolBar/MapToolBarContainer";
import MapSelectedObjectInfoContainer from "./MapSelectedObjectInfo/MapSelectedObjectInfoContainer";
import TimePlayerContainer from "./TimePlayer/TimePlayerContainer";
import LayersDataAccessorContainer from "./LayersDataAccessors/LayersDataAccessorContainer";
import { baseStyles } from "../../../stylesRegistrator";
import MapContextMenuContainer from "../ContextMenu/MapContextMenuContainer";
import LayerDataAccessorBuilder from "./LayersDataAccessors/LayerDataAccessorBuilder";

const {
  BaseMapPanelLayoutArea,
  BaseMapMainModuleWrapper,
  BaseMapContentLayoutArea,
  BaseMapContentLayoutAreaInner
} = baseStyles.modules.map;

const MapDisplayMode = ({
  dataAccessPanelVisible,
  layerSettingVisible,
  timePlayerVisible,
  mapSelectedObjectInfoVisible,
  mainPanelCollapsed,
  currentMode
}) => {
  return (
    <>
      <BaseMapMainModuleWrapper>
        <BaseMapPanelLayoutArea>
          <LayersBarContainer />
        </BaseMapPanelLayoutArea>
        <BaseMapContentLayoutArea>
          <BaseMapContentLayoutAreaInner>
            <MapSelectedObjectInfoContainer />
            {/*{dataAccessPanelVisible && <LayersDataAccessorContainer />}*/}
            {dataAccessPanelVisible && <LayerDataAccessorBuilder />}
            <TimePlayerContainer />
            {/*<MapWidgetsComplexGrid*/}
            {/*  dataAccessPanelVisible={dataAccessPanelVisible}*/}
            {/*  layerSettingVisible={layerSettingVisible}*/}
            {/*  timePlayerVisible={timePlayerVisible}*/}
            {/*  mapSelectedObjectInfoVisible={mapSelectedObjectInfoVisible}*/}
            {/*  mainPanelCollapsed={mainPanelCollapsed}*/}
            {/*/>*/}
          </BaseMapContentLayoutAreaInner>
          <MapToolBarContainer showTimePlayer={true} />
        </BaseMapContentLayoutArea>
      </BaseMapMainModuleWrapper>
      <MapContextMenuContainer mode={currentMode} />
    </>
  );
};

MapDisplayMode.propTypes = {
  dataAccessPanelVisible: PropTypes.bool
};

export default MapDisplayMode;
