import styled from "styled-components";

const CheckboxPart = styled.div`
  transition: all 0.2s;
  position: absolute;
  left: 0;
  top: 0;
  &:last-child {
    opacity: 0;
  }
`;

const TextWrapper = styled.span`
  color: ${props => props.fontColor ? props.fontColor : 'black'};
  font-weight: ${props => props.boldText ? 'bold' : 'normal'};
  text-align: left;
  line-height: 1.3rem;
`;

const CheckboxWrapper = styled.span`
  color: ${props => props.checkboxColor ? props.checkboxColor : 'black'};
  position: relative;
  transition: all 0.2s;
  display: block;
  width: ${props => `${props.checkboxSizeInPx}px` ?? '24px'};
  height: ${props => `${props.checkboxSizeInPx}px` ?? '24px'};
  & input[type="checkbox"] {
    display: none;
    &:checked + ${CheckboxPart} {
      opacity: 1;
    }
  }
  & + ${TextWrapper} {
    margin-left: 0.8rem;
  }
`;

const Label = styled.label`
  cursor: ${props => props.disabled ? 'not-allowed' : 'pointer'};
  width: fit-content;
  display: flex;
  align-items: center;
`;

export const Styled = { Label, CheckboxWrapper, CheckboxPart, TextWrapper };
