import React from "react";
import { Icon } from "antd";

const CalendarIconSVG = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M18.4 4.63636H17.6V3H16V4.63636H8V3H6.4V4.63636H5.6C4.72 4.63636 4 5.37273 4 6.27273V19.3636C4 20.2636 4.72 21 5.6 21H18.4C19.28 21 20 20.2636 20 19.3636V6.27273C20 5.37273 19.28 4.63636 18.4 4.63636ZM18.4 19.3636H5.6V8.72727H18.4V19.3636Z"
      fill="currentColor"
    />
  </svg>
);

export const CalendarIcon = props => (
  <Icon component={CalendarIconSVG} {...props} />
);
