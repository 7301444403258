import React from "react";
import { connect, useDispatch } from "react-redux";
import { setMapContextMenu } from "../../../../reducers/map/actions/mapPanelsActions";
import { initializeSingleObjectEditMode } from "../../../../sagas/map/standaloneObjectEditableSaga";
import { useTranslation } from "react-i18next";
import MapContextMenu from "./MapContextMenu";
import { contextMenuGroupsFactory } from "./utils/contextMenuGroupsFactory";
import {
  contextMenuContentGroupList,
  resolveContextMenuContent,
} from "../../../../registrators/map/itemContextMenuRegistrator/contextMenuContentRegistrator";

const MapContextMenuContainer = ({
  mode,
  mapContextMenuProperties,
  closeMapContext,
}) => {
  const { t } = useTranslation("common");
  const dispatch = useDispatch();
  let contentItemsCollection = null;
  let ContextDescriptionInstance = null
  const ContentDescriptionClass = resolveContextMenuContent(
    mode,
    mapContextMenuProperties?.clickedObjectName,
    mapContextMenuProperties?.objectLayerName
  );
  if (ContentDescriptionClass) {
    ContextDescriptionInstance = new ContentDescriptionClass(
      mapContextMenuProperties?.selected,
      mapContextMenuProperties?.objectLayerName,
      mapContextMenuProperties?.coordinate
    );
  }

  contentItemsCollection = ContextDescriptionInstance?.getActionItemsList()

  let preparedTooltipGroups = null;
  if (contentItemsCollection) {
    preparedTooltipGroups = contextMenuGroupsFactory({
      groupsList: contextMenuContentGroupList,
      certainCollection: contentItemsCollection,
      translateFn: t,
      dispatchFn: dispatch,
    });
  }
  const title = ContextDescriptionInstance?.getTitle()
  return (
    <>
      {mapContextMenuProperties ? (
        <MapContextMenu
          mapContextMenuProperties={mapContextMenuProperties}
          groupsRenderProp={preparedTooltipGroups}
          closeMapContext={closeMapContext}
          title={title}
        />
      ) : null}
    </>
  );
};
const mapStateToProps = (state) => {
  return {
    mapContextMenuProperties: state.mapPanels.mapContextMenu,
    mapLayers: state.mapboxLayers,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    closeMapContext: () => dispatch(setMapContextMenu()),
    initializeSingleObjectEditMode: (selectedObject, typeOfObject) =>
      dispatch(initializeSingleObjectEditMode(selectedObject, typeOfObject)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MapContextMenuContainer);
