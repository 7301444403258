import React from "react";
import { Icon } from "antd";

const EarthOffIconSVG = () => (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M18.3334 4.39175L17.0834 5.62508C17.8834 6.90008 18.3334 8.39175 18.3334 10.0001C18.3334 12.2102 17.4555 14.3298 15.8927 15.8926C14.3299 17.4554 12.2103 18.3334 10.0001 18.3334C8.40011 18.3334 6.90011 17.8834 5.62511 17.0834L4.39178 18.3334L3.33345 17.2667L17.2668 3.33341L18.3334 4.39175ZM14.9168 14.4917C16.0001 13.3084 16.6668 11.7334 16.6668 10.0001C16.6668 8.85842 16.3834 7.78342 15.8751 6.85008L12.3584 10.3667C12.4501 10.5001 12.5001 10.6584 12.5001 10.8334V13.3334H13.3334C14.0751 13.3334 14.7001 13.8251 14.9168 14.4917ZM9.16678 16.6084V15.0001C8.75011 15.0001 8.39178 14.8584 8.10845 14.6167L6.85011 15.8751C7.55845 16.2501 8.33345 16.5001 9.16678 16.6084ZM12.5001 3.82508V4.16675C12.5001 4.60878 12.3245 5.0327 12.012 5.34526C11.6994 5.65782 11.2755 5.83341 10.8334 5.83341H9.16678V7.50008C9.16678 7.7211 9.07898 7.93306 8.9227 8.08934C8.76642 8.24562 8.55446 8.33342 8.33345 8.33342H6.66678V10.0001H8.48345L6.74178 11.7417L3.50845 8.50842C3.40011 8.98342 3.33345 9.48342 3.33345 10.0001C3.33345 11.4501 3.80011 12.8001 4.58345 13.8917L3.40011 15.0834C2.2717 13.6296 1.6616 11.8404 1.66678 10.0001C1.66678 7.78994 2.54475 5.67033 4.10756 4.10752C5.67036 2.54472 7.78998 1.66675 10.0001 1.66675C11.9168 1.66675 13.6751 2.30841 15.0834 3.40008L13.8918 4.58341C13.4591 4.27719 12.992 4.02268 12.5001 3.82508V3.82508Z" fill="currentColor"/>
    </svg>
);

export const EarthOffIcon = props => (
    <Icon component={EarthOffIconSVG} {...props} />
);
