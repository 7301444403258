import React from "react";
import { Styled } from "./DataTSContainer.styled";
import GeneralDataModelTS from "./GeneralDataModelTS/GeneralDataModelTS";

import OwnerTSContainer from "./OwnerTS/OwnerTSContainer";
import { CollapsableItem } from "../../../../../../../../library/UI/components/CollapsableItem/CollapsableItem";

const { LocalMainWrapper, LocalModelTSWrapper } = Styled;

const DataGeozones = ({ handleSetData, updateDate }) => {
  return (
    <CollapsableItem titleContent={"Данные о Геозонах"}>
      <LocalMainWrapper>
        {/*<OwnerTSContainer handleSetData={handleSetData} />*/}
        <LocalModelTSWrapper>
          <GeneralDataModelTS updateDate={updateDate} />
        </LocalModelTSWrapper>
      </LocalMainWrapper>
    </CollapsableItem>
  );
};

export default DataGeozones;
