import React from "react";
import { registeredUrls } from "../../../../../../registrators/old/directoryRegistrator/directoryRegistrator";
import { compose } from "redux";
import withContentPadding from "../../../../../../HOC/withContentPadding";
import { arrayGroupBy } from "../../../../../../services/data/arrayGroupBy";
import { Styled } from "./DirectoryCards.styled";
import { useTranslation } from "react-i18next";
import { VehicleIcon } from "../../../../../../library/icons/objectIcons/VehicleIcons";
import { Link } from "react-router-dom";
import { StarIcon } from "../../../../../../library/icons/actionIcons/StarIcon";
import { OptionIcon } from "../../../../../../library/icons/actionIcons/OptionIcon";
import ScrollableBoxArea from "../../../../../../library/areas/scrollableBoxArea/ScrollableBoxArea"
import {CollapsableItem} from "../../../../../../library/UI/components/CollapsableItem/CollapsableItem";

const {
  LocalCardMainWrapper,
  LocalCardHeader,
  LocalCardOption,
  LocalCardWrapper,
  LocalCardStar,
  LocalCardPoint,
  LocalMainWrapper,
  LocalCardDescr,
  LocalCardsWrapper,
    ExtendedCollapseItem,
} = Styled;
const DirectoryCards = () => {
  const { t } = useTranslation("common");
  const confirmedAccess = registeredUrls.map((el) => el);
  let resultList;
  const uniqueFolderKeys = [
    ...new Set(confirmedAccess.map((item) => item.folderKey)),
  ];

  for (let folderKey of uniqueFolderKeys) {
    resultList = arrayGroupBy(confirmedAccess, (rule) => rule.folderKey);
  }
  const cardsGroupsForRender = [];
  for (let folderKey of uniqueFolderKeys) {
    const oneCardsGroup = [];
    const itemsCollection = resultList?.get(folderKey);
    oneCardsGroup.push(
      itemsCollection.map((el) => (
        <Link to={`/app/directory/${el.url}`}>
          <LocalCardMainWrapper>
            <LocalCardWrapper url={el.urlData} to={el.url} onClick={() => {}}>
              <LocalCardHeader>
                {t(el.i18LabelPath)}
                <LocalCardOption>
                  <LocalCardStar>
                    <StarIcon />
                  </LocalCardStar>
                  <LocalCardPoint>
                    <OptionIcon />
                  </LocalCardPoint>
                </LocalCardOption>
              </LocalCardHeader>
              <LocalCardDescr>{t(el.description)}</LocalCardDescr>
            </LocalCardWrapper>
          </LocalCardMainWrapper>
        </Link>
      ))
    );
    cardsGroupsForRender.push({
      name: t(folderKey),
      cardsGroup: oneCardsGroup,
    });
  }

  return (
    <LocalMainWrapper>
      <ScrollableBoxArea>
        <LocalCardsWrapper>
          {cardsGroupsForRender.map((el) => {
            return (
                <ExtendedCollapseItem >
                  <CollapsableItem rotateDegree={90} titleContent={<> <VehicleIcon/><div> {el.name}</div> </> }>
                  <div style={{display: 'flex', backgroundColor: '#f1f3f6', flexWrap: 'wrap'}}>
                    {el.cardsGroup}
                    </div>
                </CollapsableItem>
                </ExtendedCollapseItem>
            );
          })}
        </LocalCardsWrapper>
      </ScrollableBoxArea>
    </LocalMainWrapper>
  );
};

const enhance = compose(withContentPadding);

export default enhance(DirectoryCards);
