import React from "react";
import { Styled } from "./PanelSlimCardActionGroup.styled";

const PanelSlimCardActionGroup = props => {
  return (
    <Styled.ActionsWrapper {...props}>{props.children}</Styled.ActionsWrapper>
  );
};

export default PanelSlimCardActionGroup;
