import React from "react";
import { Styled } from "./DataTSContainer.styled";
import GeneralDataModelTS from "./GeneralDataModelTS/GeneralDataModelTS";
import { CollapsableItem } from "../../../../../../../library/UI/components/CollapsableItem/CollapsableItem";
import OwnerTSContainer from "./OwnerTS/OwnerTSContainer";

const { LocalMainWrapper, LocalModelTSWrapper } = Styled;

const DataTSContainer = ({ handleSetData, updateDate }) => {
  return (
    <CollapsableItem titleContent={"Данные о ТС"}>
      <LocalMainWrapper>
        <OwnerTSContainer handleSetData={handleSetData} />
        <LocalModelTSWrapper>
          <GeneralDataModelTS updateDate={updateDate} />
        </LocalModelTSWrapper>
      </LocalMainWrapper>
    </CollapsableItem>
  );
};

export default DataTSContainer;
