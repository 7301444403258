import React from "react";
import { Icon } from "antd";

const UploadSVGIcon = () => (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M11.6666 1.66675L16.6666 6.66675V16.6667C16.6666 17.1088 16.491 17.5327 16.1784 17.8453C15.8659 18.1578 15.4419 18.3334 14.9999 18.3334H4.99992C4.55789 18.3334 4.13397 18.1578 3.82141 17.8453C3.50885 17.5327 3.33325 17.1088 3.33325 16.6667V3.33341C3.33325 2.89139 3.50885 2.46746 3.82141 2.1549C4.13397 1.84234 4.55789 1.66675 4.99992 1.66675H11.6666ZM14.9999 16.6667V7.50008H10.8333V3.33341H4.99992V16.6667H14.9999ZM9.99992 10.0001L13.3333 13.3334H11.2499V15.8334H8.74992V13.3334H6.66658L9.99992 10.0001Z" fill="#008291"/>
    </svg>
);

export const UploadIcon = props => (
    <Icon component={UploadSVGIcon} {...props} />
);
