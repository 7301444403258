import styled from "styled-components";
import {baseStyles} from "../../stylesRegistrator";

// const {} = baseStyles.mixins

const LocalMainWrapper = styled.div`
  display: flex;
  margin: 0;
  border-radius: 8px;
  justify-content: space-between;
  height: 52px;
  background-color: ${props => props.theme.colors.defaultLighter};
  .header-burger{
    width: 60px;
    height: 60px;
    padding: 18px;
    background-color: #E0E0E0;
  }
  .header-divider{
    position: relative;
    top: 12.5%;
    display: inline-block;
    width: 2px;
    height: 75%;
    margin: 0 8px;
    vertical-align: middle;
  }
`

const LocalHeaderNotification = styled.div`

    width: 39px;
    height: 39px;
    margin: 9px;
    background: ${props => props.theme.colors.defaultLighter};
    display: flex;
    text-align: center;
    flex-direction: column;
    justify-content: center;
    border-radius: 19px;
    align-items: center;
 & > button:hover{
  & i{
    color: #008291;
  }
  }
`

const LocalMainInner = styled.section`
  display: flex;
  flex-wrap: wrap;
`

const LocalControlGroup = styled.div`
  display: flex;
  align-items: center;
`

export const Styled = {LocalMainWrapper, LocalMainInner, LocalControlGroup, LocalHeaderNotification}