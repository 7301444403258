import React, {useRef} from "react";
import { connect } from "react-redux";
import { deleteObject } from "../../../sagas/directory/deleteObjectSaga";
import { Button } from "../../UI/components/Button/Button";
import { DeleteIcon } from "../../icons/actionIcons/DeleteIcon";
import {getLastHistoryRecord} from "../../../reducers/directory/directoryHistory";
import {getLastSelectedObject} from "../../../reducers/directory/selectedObject";
import {RELOAD_HISTORY_INLINE_SAGA} from "../../../sagas/directory/inlineHistorySaga";

const DeleteButton = ({selectedObject, deleteObject, lastInlineHistory}) => {
  const redrawDispatch = {
    type: RELOAD_HISTORY_INLINE_SAGA,
    payload: {
      lastInlineHistory
    }
  }
  const iconSize = useRef({ width: "20px", height: "20px" });
  const urlData = lastInlineHistory?.urlData
  const id = selectedObject?.id
  return (
    <Button
      type={"danger"}
      hasShadow
      rounded
      icon={<DeleteIcon />}
      onClick={() => {deleteObject(urlData, id, redrawDispatch)}}
      iconSize={iconSize.current}
    />
  );
};

const mapStateToProps = state => {
  return {
    selectedObject: getLastSelectedObject(state),
    lastInlineHistory: getLastHistoryRecord(state)
  };
};

const mapDispatchToProps = dispatch => {
  return {
    deleteObject: (url, id, redrawDispatch) => dispatch(deleteObject({url: url, id, redrawDispatch}))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DeleteButton);
