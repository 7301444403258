import styled from "styled-components";

const AreaWrapper = styled.div`
  width:100%;  
  display: flex;
  flex-flow: column;
  height: 100%;
`;

const AreaContent = styled.div`
  background: ${(props) =>
    props.areaBackground ? props.areaBackground : "#fff"};
  flex: 1 1 auto;
  border-radius: 6px;
  overflow-y: scroll;
  &::-webkit-scrollbar {
    display: none;
  }
`;

export const Styled = { AreaWrapper, AreaContent };
