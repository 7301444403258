import React from "react";
import DisplayedLayerItemContainer from "./Item/DisplayedLayerItemContainer";
import styled from "styled-components";
import { baseStyles } from "../../../../../../../../../stylesRegistrator";
import { useTranslation } from "react-i18next";
import DraggableOneColumnGrid from "../../../../../../../../../../library/UI/components/DraggableOneColumnGrid/DraggableOneColumnGrid";
import LayersGalleryContainer from "./LayersGallery/LayersGalleryContainer";
import { Divider } from "antd";

const { BaseMapPanelSecondaryTitle, BaseMapPanelItem } = baseStyles.modules.map;

const DisplayedLayers = ({ enabledLayers }) => {
  const Layers = enabledLayers.map((el, index) => (
    <DisplayedLayerItemContainer key={`item-${index}`} item={el} />
  ));
  const { t } = useTranslation("common");
  return (
    <StyledPanelItem>
      <BaseMapPanelSecondaryTitle>
        {t("app.menuMap.activeLayers")}
      </BaseMapPanelSecondaryTitle>
      {!Layers.length && <p>{t("app.menuMap.activeLayersDescription")}</p>}
      <DraggableOneColumnGrid>{Layers}</DraggableOneColumnGrid>

      <Divider />

      <LayersGalleryContainer />
    </StyledPanelItem>
  );
};

export default DisplayedLayers;

const StyledPanelItem = styled(BaseMapPanelItem)`
  text-align: left;
`;
