const ru = {
    page: "страница",
    more: "больше",
    to: " до ",
    of: "из",
    next: "следующий",
    last: "последний",
    first: "первый",
    previous: "предыдущий",
    loadingOoo: "загружается...",
    selectAll: "выбрать все",
    searchOoo: "Поиск ...",
    blanks: "пустой",
    filterOoo: "фильтровать...",
    applyFilter: "применить фильтр...",
    equals: "равно",
    notEqual: "не равно",
    lessThan: "меньше, чем",
    greaterThan: "больше, чем",
    lessThanOrEqual: "меньше, чем",
    greaterThanOrEqual: "больше или равно",
    inRange: "в диапазоне",
    contains: "содержит",
    notContains: "не содержит",
    startsWith: "начинается",
    endsWith: "заканчиватся здесь",
    andCondition: "Логическое И",
    orCondition: "Логическое ИЛИ",
    group: "Группа",
    columns: "Колонки",
    filters: "Фильтры",
    rowGroupColumns: "laPivot Cols",
    rowGroupColumnsEmptyMessage: "Перетащите столбец для группировки",
    valueColumns: "Значение Колонки",
    pivotMode: "Сводные таблицы",
    groups: "Группы",
    values: "Агрегация",
    pivots: "Углы",
    valueColumnsEmptyMessage: "Перетащите столбец для агрегации",
    pivotColumnsEmptyMessage: "Перетащите столбец для группировки",
    toolPanelButton: "Кнопка панели управления",
    noRowsToShow: "Данные отстутсвуют",
    enabled: "Включено",
    pinColumn: "Прикрепить колонку",
    valueAggregation: "Среднее значение",
    autosizeThiscolumn: "Авторазмер этой колонки",
    autosizeAllColumns: "Авторазмер всех колонок",
    groupBy: "Группировать по",
    ungroupBy: "Разгруппировать по",
    resetColumns: "По умолчанию",
    expandAll: "Развернуть",
    collapseAll: "Свернуть",
    toolPanel: "Панель инструментов",
    export: "Экспорт",
    csvExport: "Экспорт в CSV",
    excelExport: "Экспорт в Эксель (.xlsx)",
    excelXmlExport: "Экспорт в Эксель (.xml)",
    pivotChartAndPivotMode: "Сводная диаграмма и точки пересечений",
    pivotChart: "Сводная диаграмма",
    chartRange: "Построить диаграмму",
    columnChart: "Столбцовая диаграмма",
    groupedColumn: "Построить по значению",
    stackedColumn: "Построить накоплением",
    normalizedColumn: "Построить с нормализацией",
    barChart: "Линейчатая диаграмма",
    groupedBar: "Построить по значению",
    stackedBar: "Построить накоплением",
    normalizedBar: "Построить с нормализацией",
    pieChart: "Круговая диаграмма",
    pie: "Круг",
    doughnut: "Пончик",
    line: "Линия",
    xyChart: "Диаграмма Х-У",
    scatter: "Точечная дигарамма",
    bubble: "Пузырьковая диаграмма",
    areaChart: "Диаграмма по площади",
    area: "Постороить по значению",
    stackedArea: "Построить накоплением",
    normalizedArea: "Построить с нормализацией",
    pinLeft: "Прикрепить слева",
    pinRight: "Прикрепить справа",
    noPin: "Открепить",
    sum: "Сумма",
    min: "Минимум",
    max: "Максимум",
    none: "Не",
    count: "Количество",
    average: "Среднее",
    filteredRows: "Фильтрованные строки",
    selectedRows: "Выбранные строки",
    totalRows: "Всего строк",
    totalAndFilteredRows: "Всего фильтрованных строк",
    copy: "Копировать",
    copyWithHeaders: "Копировать с загаловками",
    ctrlC: "ctrl + C",
    paste: "Встваить",
    ctrlV: "ctrl + V",
    pivotChartTitle: "Угол загаловка диаграммы",
    rangeChartTitle: "Название диаграммы",
    settings: "Настройки",
    data: "Данные",
    format: "Формат",
    categories: "Поля данных",
    series: "Значения",
    xyValues: "Значения Х У",
    paired: "Сдвоенный режим",
    axis: "Ось",
    color: "Цвет",
    thickness: "Толщина",
    xRotation: "Наклон по Х",
    yRotation: "Наклон по У",
    ticks: "Шкала",
    width: "Высота",
    length: "Ширина",
    padding: "Отступ",
    chart: "Диаграмма",
    title: "Название",
    background: "Фон",
    font: "Шрифт",
    top: "Верх",
    right: "Справа",
    bottom: "Снизу",
    left: "Слева",
    labels: "Названия",
    size: "Размер",
    minSize: "Минимальный размер",
    maxSize: "Максимальный размер",
    legend: "История",
    position: "Позиция",
    markerSize: "Размер маркера",
    markerStroke: "Обводка маркера",
    markerPadding: "Отступ маркера",
    itemPaddingX: "Отступ элемента по X",
    itemPaddingY: "Отступ элемента по Y",
    strokeWidth: "Ширина обводки",
    offset: "Смещение",
    offsets: "Смещения",
    tooltips: "Подсказки",
    callout: "Отзыв",
    markers: "Маркеры",
    shadow: "Тень",
    blur: "Размытие",
    xOffset: "Смещение по Х",
    yOffset: "Смещение по У",
    lineWidth: "Высота строки",
    normal: "Нормально",
    bold: "Жирный",
    italic: "Наклон",
    boldItalic: "Жирный с наклоном",
    predefined: "Предопределенный",
    fillOpacity: "Прозрачность заливки",
    strokeOpacity: "Прозрачность линии",
    columnGroup: "Столбцовые диаграммы",
    barGroup: "Линейчатые диаграммы",
    pieGroup: "Круговые диаграммы",
    lineGroup: "Линейные диаграммы",
    scatterGroup: "Точечные диаграммы",
    areaGroup: "Диаграммы по площади",
    groupedColumnTooltip: "Подсказка колонки по группе",
    stackedColumnTooltip: "Подсказка колонки с накоплением",
    normalizedColumnTooltip: "Подсказка колонки с нормализацией",
    groupedBarTooltip: "Групповая диаграмма",
    stackedBarTooltip: "Вертикальная диаграмма",
    normalizedBarTooltip: "Нормализованная вертикальная диаграмма",
    pieTooltip: "Пирог",
    doughnutTooltip: "Пончик",
    lineTooltip: "Линейная диаграмма",
    groupedAreaTooltip: "По значению",
    stackedAreaTooltip: "С накоплением",
    normalizedAreaTooltip: "С нормализацией",
    scatterTooltip: "Точечная диаграмма",
    bubbleTooltip: "Пузырьковая диаграмма",
    noDataToChart: "Нет доступных данных для диаграммы.",
    pivotChartRequiresPivotMode: "Сводная диаграмма требует включения сводного режима"
}

export default ru;
