import {LayerCardPrototype} from "../prototype/LayerCardPrototype";

export class TrackingLocationPointCard extends LayerCardPrototype {

    constructor() {
        super({
                i18path: 'trackingLocationPoints',
                linkToPreview: 'MonitoringPassajirskogoTransporta.svg'
            }
        );
    }
}
