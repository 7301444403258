import React from 'react';
import PropTypes from 'prop-types';
import DeckSettingsContent from "./DeckSettingsContent";

const DeckSettingsContentContainer = props => {
    return (
        <DeckSettingsContent/>
    );
};

DeckSettingsContentContainer.propTypes = {

};

export default DeckSettingsContentContainer;
