import React, { useRef } from "react";
import { connect, useDispatch } from "react-redux";
import { RELOAD_HISTORY_INLINE_SAGA } from "../../../sagas/directory/inlineHistorySaga";
import { getLastHistoryRecord } from "../../../reducers/directory/directoryHistory";
import { Button } from "../../UI/components/Button/Button";
import { compose } from "redux";
import { withTheme } from "styled-components";
import {RefreshIcon} from "../../icons/actionIcons/RefreshIcon";

const RefreshButton = ({ lastInlineHistory, theme }) => {
  const dispatch = useDispatch();

  const refreshHandler = () => {
    dispatch({
      type: RELOAD_HISTORY_INLINE_SAGA,
      payload: {
        lastInlineHistory
      }
    });
  };
  const iconSize = useRef({ width: "20px", height: "20px" });

  return (
    <Button
      rounded
      hasShadow
      type={"danger"}
      icon={<RefreshIcon />}
      iconColor={theme.colors.primary}
      iconSize={iconSize.current}
      onClick={refreshHandler}
    />
  );
};

const mapStateToProps = state => {
  return {
    lastInlineHistory: getLastHistoryRecord(state)
  };
};

export default compose(
  withTheme,
  connect(mapStateToProps, null)
)(RefreshButton);
