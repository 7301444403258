import { useEffect, useState } from "react";

export function useCertainFilterNamesCollectionForTable(currentAccessorName) {
  const [
    namesForFilteringCollection,
    setNamesForFilteringCollection,
  ] = useState([]);
  useEffect(() => {
    if (currentAccessorName) {
      switch (currentAccessorName.name) {
        case "Waggon List": {
          setNamesForFilteringCollection([
            "name",
            "train_name",
            "number",
            "type_name",
            "discharge_zone_name",
            "reception_area_name",
            "status_name",
            "location",
            "type_name",
            "vendor",
          ]);
          break
        }
        case "Tmc Cargo List": {
          setNamesForFilteringCollection([
            "vendor",
            "invoice",
            "packing_list_number",
            "name",
            "net_weight",
            "country_name",
            "unload_date",
             "forecasted_unload_date"
          ]);
          break;
        }
        case "Layer Tmc Cargo Representation List":{
          setNamesForFilteringCollection([
            "vendor",
            "invoice",
            "packing_list_number",
            "name",
            "net_weight",
            "country_name",
            "unload_date",
            "forecasted_unload_date"
          ]);
          break;
        }case "Pass List":{
          setNamesForFilteringCollection([
            "date_of_issue",
            "expiration_date",
            "id",
            "number",
            "pass_status_name",
            "withdrawal_date",
          ]);
          break
        }
        case "Vehicle Read Only List":{
          setNamesForFilteringCollection([
            "vehicle_model_name",
            "year_of_issue",
            "vehicle_type_name",
            "vehicle_passports",
            "time_received",
            "speed",
            "registration_number",
            "point",
            "persons_vehicles",
            "ownership_reason_name",
            "organization_name",
            "max_speed",
            "maintenances",
            "id",
            "fuel_type_name",
            "extra",
            "engine_power",
            "emission_standard_name",
            "devices_vehicles",
            "comment",
            "color_name",
            "azimuth"
          ]);
          break
        }
        case "Etran Cargo List":{
          setNamesForFilteringCollection([
            "arrival_forecast",
            "cargo_ownership",
            "cargo_status",
            "contract_number",
            "current_station",
            "cus_submission_actual_date",
            "cus_submission_planned_date",
            "destination_station_arrival_fact",
            "destination_station_distance",
            "discharge_date",
            "discharge_zone",
            "discharge_zone_name",
            "etran_delivery_date",
            "forwarder",
            "id",
            "invoice",
            "name",
            "operation",
            "operation_date",
            "operation_time",
            "packing_list_number",
            "project_name",
            "reception_area",
            "reception_area_name",
            "shipper",
            "shipping_station",
            "subproject_name",
            "track_off_date",
            "train",
            "train_number",
            "waggon",
            "waggon_number",
            "waybill_date",
            "waybill_number",
            "weight",

          ]);
          break
        }

        case "tracking": {
          break;
        }
        case "geozones": {
          break;
        }
      }
    }

  }, [currentAccessorName]);
  return [namesForFilteringCollection, setNamesForFilteringCollection];
}
