import React from 'react';
import {Select} from "antd";
import {mapboxDefaultMapStylesEnum} from "../../../../../../../../../../../enums/mapboxDefaultMapStylesEnum";

const {Option} = Select

const {collection, selectItem} = mapboxDefaultMapStylesEnum

const MAPBOX_DEFAULT_MAPSTYLES = collection

const MapStyleSelector = ({currentStyle,handleMapStyleChange}) => {
    return (
            <Select
                // style={{width:'100%'}}
                // open={true}
                value={selectItem(MAPBOX_DEFAULT_MAPSTYLES,currentStyle)}
                onChange={value => {
                    handleMapStyleChange(value)
                }}
                bordered={false}
            >
                {MAPBOX_DEFAULT_MAPSTYLES.map(style => (
                    <Option key={style.value} value={style.value}>
                        {style.label}
                    </Option>
                ))}
            </Select>
    );
};

export default MapStyleSelector;
