import React, {createContext, useContext} from "react";
import LayerMainPanelContainer from "./LayersPanels/LayerMainPanel/LayerMainPanelContainer";
import {baseStyles} from "../../../../../stylesRegistrator";
import { Button } from "../../../../../../library/UI/components/Button/Button";
import { BurgerIcon } from "../../../../../../library/icons/actionIcons/BurgerMenuIcon";
import LayerSettingsContainer from "./LayersPanels/LayersAdditionalPanels/LayerSettings/LayerSettingsContainer";
import {MapModuleContext} from "../../../moduleContext/withMapModuleContext";
import {getAllRegisteredLayers} from "../../../../../../registrators/map/layers/layersRegistrator";


export const LayersItemsContext = createContext(null);

const {BaseMapPanelLayoutAreaInner} = baseStyles.modules.map

const LayersMenu = ({
  showLayersSettings,
  toggleMainPanelCollapse,
  mainPanelCollapseStatus
}) => {
  const {iconSizesKit} = useContext(MapModuleContext)
  const allLayersLink = getAllRegisteredLayers();
  let layersItems = [];

  for (let key in allLayersLink){
      const Layer = allLayersLink[key]
      layersItems.push( {
          name: Layer.getName(),
          customSettings: Layer.getCustomSettings(),
          card: Layer.getCard(),
          isSelected: false
      } )
  }




  if (mainPanelCollapseStatus) {
    return (
      <Button
        onClick={toggleMainPanelCollapse}
        rounded
        type={"primaryReverse"}
        icon={<BurgerIcon />}
        hasShadow
        iconSize={iconSizesKit.default}
      />
    );
  } else {
    return (
      <LayersItemsContext.Provider value={layersItems}>
        <BaseMapPanelLayoutAreaInner>
          <LayerMainPanelContainer />
          {showLayersSettings && <LayerSettingsContainer/>}
        </BaseMapPanelLayoutAreaInner>
      </LayersItemsContext.Provider>
    );
  }
};

export default LayersMenu;
