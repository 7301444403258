import React, { useRef } from "react";
import { useTranslation } from "react-i18next";
import { commonSelectedObjectProtoUtils } from "../commonUtils/commonSelectedObjectProtoUtils";
import { registeredDirectoryUrlsEnum } from "../../../../enums/registeredDirectoryUrlsEnum";
import PrepareScrollableInfoCard from "../PrepareScrollableInfoCard";

const {
  parseCoordinatesToDesc,
  commonIconSizeForCurrentSection,
} = commonSelectedObjectProtoUtils;

const CheckpointsItemSelection = ({
  handleCloseMapSelectedObjectInfo,
  selectedMapItem,
}) => {
  const { t } = useTranslation("common");

  const handleOpenObjectInNewTab = () => {
    const roadData = selectedMapItem.data.find(
      (dataRecord) => dataRecord.name === "cargoTrackingPoint"
    );
    const { id } = roadData.data;
    const win = window.open(
      `/app/directory/${registeredDirectoryUrlsEnum.tmc_location_point}/${id}/`,
      "_blank"
    );
    win.focus();
  };
  let Descriptions = [];
  const properties = selectedMapItem?.data[0]?.data;
  properties.name &&
    Descriptions.push({
      key: "Имя точки",
      value: properties.name,
    });

  Descriptions.push({
    key: t("app.menuMap.dataVehicle.coordinates"),
    value: parseCoordinatesToDesc(selectedMapItem),
  });

  const iconSize = useRef(commonIconSizeForCurrentSection);
  return (
    <PrepareScrollableInfoCard
      iconSize={iconSize.current}
      title={t(
        "app.menuMap.scrollableInfoCard.header.trackingCargoCheckpoints"
      )}
      handleCloseCard={handleCloseMapSelectedObjectInfo}
      handleOpenItemInDirectory={handleOpenObjectInNewTab}
      listOfDescriptions={Descriptions}
      openInNewTabBtnDesc={t("app.menuMap.dataVehicle.openInDirectory")}
    />
  );
};

CheckpointsItemSelection.propTypes = {};

export default CheckpointsItemSelection;
