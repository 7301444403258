import React from "react";
import styled from "styled-components";

const StyledLocalMainWrapper = styled.div`
  #arrow {
    animation: arrow 1s infinite linear;
    transform-origin: center;
    transform-box: fill-box;
  }

  @keyframes arrow {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
`;

const LogoNipigazAnimation = ({color = 'white'}) => {
  return (
    <StyledLocalMainWrapper>
      <svg
        width="40"
        height="40"
        viewBox="0 0 40 40"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g id="MENU MINI 1">
          <path
            id="Vector"
            d="M20.0884 31.0999C20.0884 31.0999 20.0884 31.0001 20.0884 31.0999C20.0884 31.0001 20.0884 31.0001 20.0884 31.0999C21.3789 29.5999 22.2728 28.1997 22.7688 26.6997C23.4638 24.7001 23.6621 22.6999 23.5627 20.6997C23.4638 19.1997 23.2649 17.8001 22.9671 16.4997C22.8683 15.8997 22.6694 15.4001 22.5705 14.8999C22.4711 14.6997 22.4711 14.5001 22.3716 14.2999C22.1733 13.6001 21.975 13.1997 21.975 13.1997C21.975 13.1997 22.1733 13.6001 22.3716 14.2999C22.0408 13.2095 21.643 12.1408 21.1806 11.0997C20.4856 9.59971 19.9895 8.69971 19.9895 8.69971C19.9895 8.69971 19.394 9.49991 18.699 10.7997C18.4012 11.3997 18.1035 11.9997 17.8051 12.7001L17.2096 14.2001C17.309 14.2999 17.309 14.3997 17.4085 14.5999C17.309 14.3997 17.2096 14.2999 17.2096 14.2001C17.0113 14.8001 16.813 15.4001 16.7135 16.0001V16.0999C16.5152 17.2999 16.3169 18.5997 16.3169 20.2001V20.6997C16.5152 23.5001 17.4085 26.8001 19.5923 30.4001C19.6917 30.5997 19.89 30.8997 19.9889 31.0999V31.0001C19.7906 30.5997 19.4928 29.5999 19.2945 28.3001C19.0956 26.6997 19.0956 24.4999 19.9895 22.3001V22.1997C19.9895 22.3001 20.0884 22.3001 20.0884 22.3999C20.8828 24.1999 20.9816 25.9999 20.8828 27.4001C20.7833 28.1997 20.6839 28.9999 20.4862 29.5999C20.3861 29.8999 20.2873 30.1999 20.2873 30.4001C20.1878 30.7999 20.0884 31.0001 20.0884 31.0999C20.0884 31.0999 19.9895 31.0999 20.0884 31.0999Z"
            fill={color}
          />
          <g id="arrow">
            <path
              id="Vector_2"
              d="M27.1369 6.8998C24.9525 5.6998 22.5698 5 19.8894 5C11.6508 5 5 11.6998 5 20C5 28.3002 11.6508 35 19.89 35C28.1291 35 34.7799 28.3002 34.7799 20C34.7799 17.3 34.085 14.8002 32.8945 12.7002L31.3056 13.6002C32.3978 15.5998 32.9934 17.8002 32.9934 20.3C32.9934 27.8 26.938 33.8998 19.4927 33.8998C12.0481 33.8998 5.99275 27.8 5.99275 20.3C5.99275 12.8 12.0481 6.7002 19.4927 6.7002C21.9749 6.7002 24.2582 7.4 26.2431 8.5002L25.2503 10.4L30.5118 10.3002L27.7319 5.8002L27.1369 6.8998Z"
              fill={color}
            />
          </g>
        </g>
      </svg>
    </StyledLocalMainWrapper>
  );
};

export default LogoNipigazAnimation;
