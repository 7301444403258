import {useCallback, useContext} from "react";
import {PortalContext} from "../../ScreenCornerPortal/ScreenPortal";

export function usePortalContext(onClose) {
    const { closePortal, isOpen } = useContext(PortalContext);
    const enhancedCloseCallback = useCallback(() => {
        onClose();
        closePortal();
    }, [onClose, closePortal]);
    return { closePortal, isOpen, enhancedCloseCallback };
}