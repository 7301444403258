import { MainDataSocketPrototype } from "../prototype/MainDataSocketPrototype";
import { openSocket } from "../../../../../../sagas/map/socketDataGetterSaga";
import { addMapboxData } from "../../../../../../reducers/map/actions/mapboxDataActions";
import _ from "underscore";
import { layerNamesEnum } from "../../../../../../enums/layerNamesEnum";

export class VehicleMonitoringMarksMainData extends MainDataSocketPrototype {
  _returnAC = true;
  constructor() {
    super({
      urlData: "/monitoring/",
      dataKeyForIds: "device_ids",
    });
  }

  getIsReturnsAC() {
    return this._returnAC;
  }

  getCustomMainDataDownload(dispatch) {
    return openSocket({
      urlData: this.getUrlData(),
      layerName: layerNamesEnum.monitoring,
      subscribeKey: this.getDataKeyForIds(),
      subscribeIds: null,
    });
  }
}
