import React from "react";
import Header from "./Header";
import { connect, useSelector } from "react-redux";
import { getLastHistoryRecordTitle } from "../../../reducers/directory/directoryHistory";

const HeaderContainer = (props) => {
  return (
    <Header {...props}/>
  );

  // return null
};

const mapStateToProps = (state) => {
  return {
    title: getLastHistoryRecordTitle(state),
    router: state.router
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(HeaderContainer);
