import React from "react";
import LayerCardContainer from "../Card/LayerCardContainer";
import {baseStyles} from "../../../../../../../../../../../stylesRegistrator";
import _ from "underscore";

const { BaseMapPanelSection } = baseStyles.modules.map;

const LayersGalleryContent = ({ cards }) => {
  const LayerCards = cards.map(el => el && <LayerCardContainer key={_.uniqueId()} {...el} />);
  return (
    <>
      <BaseMapPanelSection>{LayerCards}</BaseMapPanelSection>
    </>
  );
};

export default LayersGalleryContent;
