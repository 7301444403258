//auth constants
export const LOGIN_USER = "LOGIN_USER";
export const LOGOUT_USER = "LOGOUT_USER";
export const APPLY_PERMISSIONS = "APPLY_PERMISSIONS";

//auth sagas constants

//request constants
export const CURRENT_APP_URL = `${
  process.env.REACT_APP_TYPE === "PROD" || process.env.REACT_APP_TYPE === "TEST"
    ? process.env.REACT_APP_URL
    : "http://127.0.0.1:8000/api"
}`;

export const CURRENT_APP_URL_SOCKET = `${
    process.env.REACT_APP_TYPE === "PROD" || process.env.REACT_APP_TYPE === "TEST"
        ? process.env.REACT_APP_URL_SOCKET
        : "http://127.0.0.1:8000/api"
}`;

export const CURRENT_APP_MODE = process.env.NODE_ENV;
