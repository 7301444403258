import styled from "styled-components";
import { Card } from "antd";
import { Styled as StyledCollapsableItem } from "../../../../../../library/UI/components/CollapsableItem/CollapsableItem.styled";

const LocalCardsWrapper = styled.div`
  .ant-collapse > .ant-collapse-borderless > .ant-collapse-icon-position-left {
    padding-top: 100px;
  }
  .ant-collapse
    > .ant-collapse-item
    > .ant-collapse-header
    .ant-collapse-arrow {
    left: 150px;
  }
  .ant-collapse-icon-position-left > .ant-collapse-item > .ant-collapse-header {
    text-align: left;
    padding-left: 31px;
    background-color: #f1f3f6;
  }
  .ant-collapse
    > .ant-collapse-item
    > .ant-collapse-header
    .ant-collapse-extra {
    float: left;
    //custom color icon(need delete)
    color: #2f80ed;
    margin-right: 20px;
  }
  .ant-collapse-borderless
    > .ant-collapse-item
    > .ant-collapse-content
    > .ant-collapse-content-box {
    display: flex;
    background-color: ${(props) => props.theme.colors.defaultLight};
    flex-wrap: wrap;
  }
`;

const LocalMainWrapper = styled.div`
  height: calc(100vh - 100px);
`;

// Styled TemplateItemCard

const LocalCardMainWrapper = styled.div`
  margin-left: 20px;
  padding-top: 20px;
`;
const LocalCardWrapper = styled(Card)`
  .ant-card-body {
    padding: 16px 20px 14px 19px;
  }
  width: 288px;
  height: 122px;
  border-radius: 8px;
  cursor: pointer;
  color: ${(props) => props.theme.colors.defaultDark};
`;

const LocalCardHeader = styled.div`
  display: flex;
  justify-content: space-between;
  text-align: left;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  color: ${(props) => props.theme.colors.defaultDark};
`;

const LocalCardOption = styled.div`
  display: flex;
`;

const LocalCardStar = styled.div`
  margin-right: 13px;
`;

const LocalCardPoint = styled.div``;

const LocalCardDescr = styled.div`
  margin-top: 12px;
  text-align: left;
  font-size: 14px;
  color: ${(props) => props.theme.colors.neutralDarker};
`;

const ExtendedCollapseItem = styled(StyledCollapsableItem.LocalMainWrapper)`
  padding: 12px 16px 12px 40px;
  background-color: #f1f3f6;
  & ${StyledCollapsableItem.LocalTitleGroup} {
  font-weight: bold;
    & ${StyledCollapsableItem.LocalTitleItem}:first-of-type {
      & i {
        color: #2f80ed;
        padding-right: 15px;
      }
    }
  }
`;


export const Styled = {
  LocalCardDescr,
  LocalCardWrapper,
  LocalMainWrapper,
  LocalCardMainWrapper,
  LocalCardHeader,
  LocalCardOption,
  LocalCardStar,
  LocalCardPoint,
  LocalCardsWrapper,
  ExtendedCollapseItem,
};
