export const TOGGLE_STANDALONE_EDIT_MODE = "TOGGLE_EDIT_MODE_FOR_SINGLE_OBJECT";
export const SET_OBJECT_FOR_STANDALONE_EDIT_MODE =
  "SET_EDIT_MODE_FOR_SINGLE_OBJECT";
export const SET_STANDALONE_EDIT_MODE_OBJECT =
  "SET_STANDALONE_EDIT_MODE_OBJECT";
export const REPLACE_DRAW_DATA_IN_STANDALONE_EDIT_MODE =
  "REPLACE_DRAW_DATA_IN_STANDALONE_EDIT_MODE";
export const CLEAR_STANDALONE_EDIT_MODE_OBJECT =
  "CLEAR_STANDALONE_EDIT_MODE_OBJECT";

//// example structure of state
// {
//     enabled:false,
//     isNew: false,
//     instance: null,
//     layerName: full,
//     model: null,
//     name: null,
//     parentId: null,
//     parentKey: null,
//     drawData: null,
//     urlData: null,
//     selectedInstance: null,
//     parentObjectsDescription: [],
// };

const initialState = null;

const mapboxEditableStandaloneObject = (state = initialState, action) => {
  switch (action.type) {
    case TOGGLE_STANDALONE_EDIT_MODE: {
      return {
        ...state,
        enabled: !state.enabled,
      };
    }
    case REPLACE_DRAW_DATA_IN_STANDALONE_EDIT_MODE: {
      const { data } = action.payload;
      return {
        ...state,
        drawData: data,
      };
    }
    case SET_OBJECT_FOR_STANDALONE_EDIT_MODE: {
      const { value } = action.payload;
      return {
        ...state,
        enabled: value,
      };
    }
    case SET_STANDALONE_EDIT_MODE_OBJECT: {
      const { payload } = action;
      return {
        ...state,
        ...payload,
      };
    }
    case CLEAR_STANDALONE_EDIT_MODE_OBJECT: {
      return initialState;
    }
    default:
      return state;
  }
};

export default mapboxEditableStandaloneObject;
