import styled from "styled-components";
import {Styled as styleMixins} from "../../../styleMixins/styleMixins.styled"

const {AddPaddingsProps} = styleMixins.offset

const ActionsWrapper = styled.div`
  display: flex;
  width: fit-content;
  margin: 0px -2px;
  //paddings section
  ${(props) => AddPaddingsProps(props)}
  & button {
    margin: 0 2px;
    color: ${props => props.theme.colors.neutralDarker};
    width: 100%;
    transition: 0.3s;
    &.active {
      color: ${props => props.theme.colors.primary};
      &:hover {
        color: ${props => props.theme.colors.primary};
      }
    }
    &:hover {
      color: ${props => props.theme.colors.neutralDark};
    }
  }
`;

export const Styled = { ActionsWrapper };
