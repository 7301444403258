export function getFilteredDataItems(value, data) {
  return data.filter((el) => el.nameOrganization === value);
}

export function getBelongGRZForCar(value, filteredDataActions) {
  return filteredDataActions?.filter((elem) => elem.value === value);
}

export function getAllGRZForCar(data) {
  return data.map((el) => el.grz);
}
