import React from "react";
import {CloseIcon} from "../../../icons/actionIcons/CloseIcon";
import {iconSizesKit} from "../../../UI/utils/iconSizesKit"
import {toggleLayerDataAccessorVisibility} from "../../../../reducers/map/actions/mapPanelsActions";
import {useDispatch} from "react-redux";
import {Button} from "../../../UI/components/Button/Button";


export const CloseDataAccessorButton = () => {
    const dispatch = useDispatch()
    const handleClose = () => {
        dispatch(toggleLayerDataAccessorVisibility(false))
    }
    return (
        <Button
            type={"unboundedPrimary"}
            rounded
            icon={<CloseIcon />}
            p={"none"}
            iconSize={iconSizesKit.huge}
            onClick={() => handleClose()}
        />
    )
}
