import {RoadSchemaLayer} from "./RoadSchemaLayer";
import {CargoTrackingLayer} from "./CargoTrackingLayer";
import {RailwayNetworkLayer} from "./RailwayNetworkLayer";
import {CargoTrackingPointsLayer} from "./CargoTrackingPointsLayer";
import {RoadJamSectorsLayer} from "./RoadJamSectorsLayer";

export const nipigazLayers = {
    RoadSchemaLayer,
    CargoTrackingLayer,
    RailwayNetworkLayer,
    CargoTrackingPointsLayer,
    RoadJamSectorsLayer
}
