import React from 'react';
import PropTypes from 'prop-types';
import {Select} from "antd";
import styled from 'styled-components';
import MapStyleSelectorContainer from "./MapStyleSelector/MapStyleSelectorContainer";
import {useTranslation} from "react-i18next";
import MapRasterStyleSelectorContainer from "./MapRasterStyleSelector/MapRasterStyleSelectorContainer";
const {Option} = Select;



const StyledSettingsContent = styled.div`
    .ant-select-selection {
        width: 236px;
        height: 40px;
        margin: 6px 16px 0px 16px;
        border: 2px solid ${props => props.theme.colors.primary};
        box-shadow: none;
        border-radius: 8px;
        background: ${props => props.theme.colors.defaultLight};
        color: ${props => props.theme.colors.paragraph};;
        display: flex;
        align-items: center;
        &:hover {
            border: 2px solid ${props => props.theme.colors.primary} !important;  
        }
        &:active{
            border: 2px solid ${props => props.theme.colors.primary} !important;
        }
        .ant-select-selection__rendered{
          width: 100%;
          margin: 0;
          }
        .ant-select-selection-selected-value{
          width: 100%;
          padding: 0 15px 0 5px;
          overflow: hidden;
          text-overflow: ellipsis;
          }
            
    };
    .ant-select-arrow .ant-select-arrow-icon svg {
        color: ${props => props.theme.colors.primary};
        width: 9px;
        height: 6px;
    }
    
    p {
        text-align: left;
        color: ${props => props.theme.colors.neutralDarker};
        margin: 12px 0px 6px 16px;
        font-style: normal;
        font-weight: bold;
        font-size: 14px;
        line-height: 140%;
    };
    
`

const DeckSettingsContent = props => {
    const { t } = useTranslation("common");
    return (
        <StyledSettingsContent>
            <p>{t('app.menuMap.viewMap')}</p>
            <MapStyleSelectorContainer />
            <p>{t('app.menuMap.viewMapRaster')}</p>
            <MapRasterStyleSelectorContainer />
            <p>{t('app.menuMap.hiddenObject')}</p>
            <p>{t('app.menuMap.effectsMap')}</p>
        </StyledSettingsContent>
    );
};

DeckSettingsContent.propTypes = {

};

export default DeckSettingsContent;
