import React, { useRef } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Styled } from "./ToggleButtonGroup.styled";
import { Button } from "../Button/Button.tsx";
import { useTranslation } from "react-i18next";
import _ from "underscore";

const { BasePanelSection } = Styled;

const StyledButton = styled(Button)`
  &:hover {
    color: ${(props) => props.theme.colors.primaryDark};
    text-decoration: ${(props) => props.href && "underline"};
  }
  &.inactive {
    background: ${(props) => props.theme.colors.defaultLight};
    color: ${(props) => props.theme.colors.primary};
    height: 40px;
    width: 100%;
    align-items: center;
    font-size: 0px;
  }

  &.active {
    background: ${(props) => props.theme.colors.defaultLighter};
    color: ${(props) => props.theme.colors.primary};
    height: 40px;
    //width: 140px;
    //line-height: 0.5%;
  }
`;

const StyledWrapper = styled(BasePanelSection)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: ${(props) => props.theme.colors.defaultLight};
  border-radius: 8px;
  width: 240px;
  height: 40px;
`;

const ToggleButtonGroup = ({
  handleChangePanelContext,
  buttonClickHandler,
  displayedButtons,
  activePanel,
}) => {
  const iconSize = useRef({ width: "14px", height: "14px" });
  const { t } = useTranslation("common");
  const Buttons = displayedButtons.map((element) => {
    const isActive = element.switcher === activePanel ? "active" : "inactive";
    if (element.ignore) return null;
    return (
      <StyledButton
        // type={"primaryReverse"}
        // width={"100%"}
        iconSize={iconSize.current}
        icon={element.icon}
        iconMargin={0.8}
        className={isActive}
        key={_.uniqueId()}
        onClick={() => buttonClickHandler(element)}
      >
        {t(element.name)}
      </StyledButton>
    );
  });

  return <StyledWrapper>{Buttons}</StyledWrapper>;
};

ToggleButtonGroup.propTypes = {
  activePanel: PropTypes.string.isRequired,
  buttonClickHandler: PropTypes.func.isRequired,
  displayedButtons: PropTypes.array.isRequired,
  handleChangePanelContext: PropTypes.func.isRequired,
};

export default ToggleButtonGroup;
