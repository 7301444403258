import React from "react";
import { LayerDataAccessorPrototype } from "../prototype/LayerDataAccessorPrototype";
import {mapObjectsEnum} from "../../../../../../enums/mapObjectsEnum";

const FilterFromSelectedDataButton = React.lazy(() =>
    import(
        "../../../../../../library/actionsButtons/NIPIGAZ/Map/dataAccessors/customForLayers/filterButtons/FilterWagonsOnMapButton"
        )
);
const RefreshDataButtonOnMap = React.lazy(() => import('../../../../../../library/actionsButtons/NIPIGAZ/Map/dataAccessors/customForLayers/refreshButtons/RefreshRailwaySchemaOnMapButton'))

export class RailwayNetworkDataAccessor extends LayerDataAccessorPrototype {

  actionButtons = [FilterFromSelectedDataButton, RefreshDataButtonOnMap]

  constructor() {
    super({
      urlData: "/railway_network/waggons/",
      i18LabelPath: "listOfRailways",
      selectedType: mapObjectsEnum.railwayWagons,
      isSelectedEditable: false
    });
  }
}
