import { resolver } from "./resolver";
import { groupsList, groupTypes } from "./contentGroups";
import { RoadSchema } from "./itemsCollections/layers/RoadSchema";
import { RoadSegmentDescription } from "./itemsCollections/objects/roadsSchema/RoadSegmentDescription";
import { RailwayDescription } from "./itemsCollections/objects/railwayNetwork/RailwayDescription";
import { layerNamesEnum } from "../../../enums/layerNamesEnum";
import { Geozone } from "./itemsCollections/layers/Geozone";
import { RailwayNetwork } from "./itemsCollections/layers/RailwayNetwork";
import { GeozoneSingleDescription } from "./itemsCollections/objects/geozones/GeozoneSingle";
import { mapObjectsEnum } from "../../../enums/mapObjectsEnum";
import { DischargeZoneDescription } from "./itemsCollections/objects/railwayNetwork/DischargeZoneDescription";
import { ReceptionAreasDescription } from "./itemsCollections/objects/railwayNetwork/ReceptionAreasDescription";
import { RoadOverpassDescription } from "./itemsCollections/objects/roadsSchema/RoadOverpassDescription";
import { DockSegmentDescription } from "./itemsCollections/objects/roadsSchema/DockSegmentDescription";
import { ControlRoomDescription } from "./itemsCollections/objects/roadsSchema/ControlRoomDescription";
import { SecurityCheckpointDescription } from "./itemsCollections/objects/roadsSchema/SecurityCheckpointDescription";
import { RoadWorksDescription } from "./itemsCollections/objects/roadsSchema/RoadWorksDescription";
import { TrafficMainSegmentDescription } from "./itemsCollections/objects/trafficSegments/TrafficMainSegmentDescription";
import { CargoTrackingPoints } from "./itemsCollections/layers/CargoTrackingPoints";
import { CargoTrackingPointsDescription } from "./itemsCollections/objects/cargoTrackingPoints/CargoTrackingPointsDescription";
import { RoadBlockDescription } from "./itemsCollections/objects/roadsSchema/RoadBlockDescription";
import { RoadSignDescription } from "./itemsCollections/objects/roadsSchema/RoadSignDescription";
import { SignsDescription } from "./itemsCollections/objects/roadsSchema/SignsDescription";

export const contextMenuContentGroupList = groupsList;
export const contextMenuContentGroupTypes = groupTypes;

export const resolveContextMenuContent = resolver;

export const registeredMenuContent = {
  display: {
    map: {
      [layerNamesEnum.roadsSchema]: RoadSchema,
      [layerNamesEnum.geozones]: Geozone,
      [layerNamesEnum.railwayNetwork]: RailwayNetwork,
      [layerNamesEnum.cargoTrackingPoints]: CargoTrackingPoints,
    },
    [mapObjectsEnum.roadSegment]: RoadSegmentDescription,
    [mapObjectsEnum.roadSign]: RoadSignDescription,
    [mapObjectsEnum.railway]: RailwayDescription,
    [mapObjectsEnum.geozone]: GeozoneSingleDescription,
    [mapObjectsEnum.dischargeZone]: DischargeZoneDescription,
    [mapObjectsEnum.roadOverpass]: RoadOverpassDescription,
    [mapObjectsEnum.dockSegment]: DockSegmentDescription,
    [mapObjectsEnum.controlRoomsSegment]: ControlRoomDescription,
    [mapObjectsEnum.receptionArea]: ReceptionAreasDescription,
    [mapObjectsEnum.securityCheckpoint]: SecurityCheckpointDescription,
    [mapObjectsEnum.roadOverpass]: RoadOverpassDescription,
    [mapObjectsEnum.roadWorks]: RoadWorksDescription,
    [mapObjectsEnum.trafficBackgroundRoadSegment]: TrafficMainSegmentDescription,
    [mapObjectsEnum.cargoTrackingPoint]: CargoTrackingPointsDescription,
    [mapObjectsEnum.roadBlock]: RoadBlockDescription,
    [mapObjectsEnum.signs]: SignsDescription,
    default: null,
  },
  standaloneEdit: {
    default: null,
  },
};
