import React from 'react';
import {Checkbox} from 'antd';


const LayerFilterTab = ({selectedLayer, recalculate}) => {
    const handleChange = (e, RelatedData) => {
        RelatedData.toggleVisible(!e.target.checked);
        recalculate();
    };
    const layerRelatedData = selectedLayer.getRelatedData();
    const filterCheckBox = layerRelatedData.map((RelatedData) => (
        <Checkbox checked={!RelatedData.getIsHidden()} onChange={(e) => handleChange(e, RelatedData)}>
            {RelatedData.getFilterLabel()}
        </Checkbox>
    ));

    return <div>{filterCheckBox}</div>;
};

LayerFilterTab.propTypes = {};

export default LayerFilterTab;

