import React, { useRef } from "react";
import { SearchIcon } from "../../../../icons/actionIcons/SearchIcon";
import { ColumnIcon } from "../../../../icons/objectIcons/ColumnIcon";
import { FilterIcon } from "../../../../icons/actionIcons/FilterIcon";
import { Styled } from "./CompendiumActionPanel.styled";
import PropTypes from 'prop-types'

const { ActionPanelWrapper, ActionPanelButton } = Styled;

const CompendiumColumnActionPanel = ({
  quickMenuState,
  handleSearchObject,
  handleGroupObject,
  handleFilterGrid,
  iconSizeType
}) => {
  const localIconSizedKit = useRef({
    large: {width: "18px", height: "18px"},
    default: { width: "16px", height: "16px" },
    small: { width: "14px", height: "14px" }
  });
  return (
    <ActionPanelWrapper>
     {/* {handleSearchObject && (
        <ActionPanelButton
          className={`${quickMenuState.search ? "active" : ""}`}
          active={quickMenuState.search}
          disabled
          iconSize={localIconSizedKit.current[iconSizeType]}
          rounded
          icon={<SearchIcon />}
          type={"unbounded"}
          onClick={handleSearchObject}
        />
      )}*/}
      {handleGroupObject && (
        <ActionPanelButton
          className={`${quickMenuState.group ? "active" : ""}`}
          active={quickMenuState.group}
          iconSize={localIconSizedKit.current[iconSizeType]}
          rounded
          icon={<ColumnIcon />}
          type={"unbounded"}
          onClick={handleGroupObject}
        />
      )}
      {handleFilterGrid && (
        <ActionPanelButton
          className={`${quickMenuState.filters ? "active" : ""}`}
          active={quickMenuState.filters}
          iconSize={localIconSizedKit.current[iconSizeType]}
          rounded
          icon={<FilterIcon />}
          type={"unbounded"}
          onClick={handleFilterGrid}
        />
      )}
    </ActionPanelWrapper>
  );
};

CompendiumColumnActionPanel.defaultProps = {
  iconSizeType: 'default'
}

export default CompendiumColumnActionPanel;

CompendiumColumnActionPanel.propTypes = {
  handleFilterGrid: PropTypes.any,
  handleGroupObject: PropTypes.any,
  handleSearchObject: PropTypes.any,
  iconSizeType: PropTypes.oneOf(['large', 'default', 'small']),
  quickMenuState: PropTypes.any
}