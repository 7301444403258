import { call, put, takeEvery } from "redux-saga/effects";
import { postToURLSingle } from "../../api/postToURLSingle";
import { cropPath } from "../../services/net/cropPath";
import { alertTypesEnum } from "../../enums/alertTypesEnum";
import {
  endAppFetching,
  startAppFetching,
} from "../../reducers/app/actions/appActions";
import { setGlobalAlertData } from "../../reducers/app/actions/notificationActions";
import { alertStatusesEnum } from "../../enums/alertStatusesEnum";

export const DELETE_OBJECT_DATA = "DELETE_OBJECT_DATA";

export const deleteObject = ({
  url,
  id,
  redrawDispatch = null,
  successSideEffectsDispatch = [],
  finallySideEffectsDispatch = [],
  errorSideEffectsDispatch = [],
}) => ({
  type: DELETE_OBJECT_DATA,
  payload: {
    id,
    url,
    redrawDispatch,
    finallySideEffectsDispatch,
      errorSideEffectsDispatch,
      successSideEffectsDispatch
  },
});

function* deleteObjectSaga(action) {
  yield put({ type: "START_DELETING_DATA" });
  yield put(startAppFetching());

  try {
    const { url, id, redrawDispatch } = action.payload;

    yield call(() => postToURLSingle.deleteData(cropPath(url), id));

    yield put(
      setGlobalAlertData({
        status: alertStatusesEnum.active,
        type: alertTypesEnum.success,
        title: "Успешно",
        message: "Объект был успешно удален",
      })
    );

    if (redrawDispatch) {
      yield put(redrawDispatch);
      yield put({
        type: "REFRESH_SUCCESSFUL",
      });
    }
  } catch (e) {
    console.error(e)
    yield put(
      setGlobalAlertData({
        status: alertStatusesEnum.active,
        type: alertTypesEnum.error,
        title: "Ошибка",
        message: "Что-то пошло не так",
      })
    );
  } finally {
    const { finallySideEffectsDispatch } = action.payload;

    for (let sideEffect of finallySideEffectsDispatch) {
      yield put(sideEffect());
    }

    yield put(endAppFetching());
    yield put({ type: "END_DELETING_DATA" });
  }
}

export function* watchDeleteObjectSaga() {
  yield takeEvery(DELETE_OBJECT_DATA, deleteObjectSaga);
}
