import React from "react";
import { Styled } from "./CustomSlider.styled";

const {
  ExtendedAntdSlider,
  SliderWrapper,
  SliderLabel,
  SliderInner,
  SliderInfoWindow,
} = Styled;

const CustomSlider = ({
  label,
  infoPostfix,
  width,
  showInfoPanel,
  p,
  ...other
}) => {
  return (
    <SliderWrapper p={p} width={width}>
      <SliderInner>
        <SliderLabel>{label}</SliderLabel>
        {showInfoPanel && (
          <SliderInfoWindow>{`${other.value || other.defaultValue || 0}${
            infoPostfix ? infoPostfix : ""
          }`}</SliderInfoWindow>
        )}
      </SliderInner>
      {/*this is styled antd Slider*/}
      <ExtendedAntdSlider {...other} />
    </SliderWrapper>
  );
};

export default CustomSlider;
