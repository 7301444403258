export const signsMapperType8 = {
    "8.1.1": {
        "x": 1202,
        "y": 2623,
        "width": 600,
        "height": 300
    },
    "8.1.2": {
        "x": 1503,
        "y": 0,
        "width": 700,
        "height": 700
    },
    "8.1.3": {
        "x": 1803,
        "y": 2623,
        "width": 600,
        "height": 300
    },
    "8.1.4": {
        "x": 1503,
        "y": 2275,
        "width": 600,
        "height": 300
    },
    "8.2.1": {
        "x": 0,
        "y": 3024,
        "width": 600,
        "height": 300
    },
    "8.2.2": {
        "x": 2905,
        "y": 0,
        "width": 300,
        "height": 600
    },
    "8.2.3": {
        "x": 2905,
        "y": 601,
        "width": 300,
        "height": 600
    },
    "8.2.4": {
        "x": 2905,
        "y": 1202,
        "width": 300,
        "height": 600
    },
    "8.2.5": {
        "x": 601,
        "y": 3024,
        "width": 600,
        "height": 300
    },
    "8.2.6": {
        "x": 1202,
        "y": 3024,
        "width": 600,
        "height": 300
    },
    "8.3.1": {
        "x": 1803,
        "y": 3024,
        "width": 600,
        "height": 300
    },
    "8.3.2": {
        "x": 0,
        "y": 3325,
        "width": 600,
        "height": 300
    },
    "8.3.3": {
        "x": 601,
        "y": 3325,
        "width": 600,
        "height": 300
    },
    "8.4.1": {
        "x": 1202,
        "y": 3325,
        "width": 600,
        "height": 300
    },
    "8.4.3.1": {
        "x": 1503,
        "y": 1222,
        "width": 700,
        "height": 350
    },
    "8.4.3": {
        "x": 1503,
        "y": 1573,
        "width": 700,
        "height": 350
    },
    "8.4.4": {
        "x": 1803,
        "y": 3325,
        "width": 600,
        "height": 300
    },
    "8.4.5": {
        "x": 2404,
        "y": 3325,
        "width": 600,
        "height": 300
    },
    "8.4.6": {
        "x": 3206,
        "y": 0,
        "width": 600,
        "height": 300
    },
    "8.4.7": {
        "x": 1503,
        "y": 1924,
        "width": 700,
        "height": 350
    },
    "8.4.8": {
        "x": 3206,
        "y": 301,
        "width": 600,
        "height": 300
    },
    "8.4.9": {
        "x": 2204,
        "y": 952,
        "width": 600,
        "height": 400
    },
    "8.4.10": {
        "x": 2204,
        "y": 1353,
        "width": 600,
        "height": 400
    },
    "8.4.11": {
        "x": 2204,
        "y": 1754,
        "width": 600,
        "height": 400
    },
    "8.4.12": {
        "x": 2204,
        "y": 2155,
        "width": 600,
        "height": 400
    },
    "8.4.13": {
        "x": 0,
        "y": 2623,
        "width": 600,
        "height": 400
    },
    "8.4.14": {
        "x": 601,
        "y": 2623,
        "width": 600,
        "height": 400
    },
    "8.4.15": {
        "x": 1503,
        "y": 701,
        "width": 700,
        "height": 520
    },
    "8.5.1": {
        "x": 3206,
        "y": 602,
        "width": 600,
        "height": 300
    },
    "8.5.2": {
        "x": 3206,
        "y": 903,
        "width": 600,
        "height": 300
    },
    "8.5.3": {
        "x": 3206,
        "y": 1204,
        "width": 600,
        "height": 300
    },
    "8.5.4": {
        "x": 3206,
        "y": 1505,
        "width": 600,
        "height": 300
    },
    "8.5.5": {
        "x": 3206,
        "y": 1806,
        "width": 600,
        "height": 300
    },
    "8.5.6": {
        "x": 3206,
        "y": 2107,
        "width": 600,
        "height": 300
    },
    "8.5.7": {
        "x": 3206,
        "y": 2408,
        "width": 600,
        "height": 300
    },
    "8.6.1": {
        "x": 3206,
        "y": 2709,
        "width": 600,
        "height": 300
    },
    "8.6.2": {
        "x": 3206,
        "y": 3010,
        "width": 600,
        "height": 300
    },
    "8.6.3": {
        "x": 3206,
        "y": 3311,
        "width": 600,
        "height": 300
    },
    "8.6.4": {
        "x": 0,
        "y": 3626,
        "width": 600,
        "height": 300
    },
    "8.6.5": {
        "x": 601,
        "y": 3626,
        "width": 600,
        "height": 300
    },
    "8.6.6": {
        "x": 1202,
        "y": 3626,
        "width": 600,
        "height": 300
    },
    "8.6.7": {
        "x": 1803,
        "y": 3626,
        "width": 600,
        "height": 300
    },
    "8.6.8": {
        "x": 2404,
        "y": 3626,
        "width": 600,
        "height": 300
    },
    "8.6.9": {
        "x": 3005,
        "y": 3626,
        "width": 600,
        "height": 300
    },
    "8.7": {
        "x": 0,
        "y": 3927,
        "width": 600,
        "height": 300
    },
    "8.9.1": {
        "x": 601,
        "y": 3927,
        "width": 600,
        "height": 300
    },
    "8.9.2": {
        "x": 0,
        "y": 1701,
        "width": 1417,
        "height": 921
    },
    "8.9": {
        "x": 1202,
        "y": 3927,
        "width": 600,
        "height": 300
    },
    "8.10": {
        "x": 1803,
        "y": 3927,
        "width": 600,
        "height": 300
    },
    "8.11": {
        "x": 2404,
        "y": 3927,
        "width": 600,
        "height": 300
    },
    "8.12": {
        "x": 3005,
        "y": 3927,
        "width": 600,
        "height": 300
    },
    "8.13(1)": {
        "x": 2204,
        "y": 351,
        "width": 600,
        "height": 600
    },
    "8.14": {
        "x": 3807,
        "y": 0,
        "width": 600,
        "height": 300
    },
    "8.15": {
        "x": 3807,
        "y": 301,
        "width": 600,
        "height": 300
    },
    "8.16": {
        "x": 3807,
        "y": 602,
        "width": 600,
        "height": 300
    },
    "8.17": {
        "x": 3807,
        "y": 903,
        "width": 600,
        "height": 300
    },
    "8.18": {
        "x": 3807,
        "y": 1204,
        "width": 600,
        "height": 300
    },
    "8.19": {
        "x": 3807,
        "y": 1505,
        "width": 600,
        "height": 300
    },
    "8.20.2": {
        "x": 3807,
        "y": 1806,
        "width": 600,
        "height": 300
    },
    "8.21.1": {
        "x": 3807,
        "y": 2107,
        "width": 600,
        "height": 300
    },
    "8.21.2": {
        "x": 3807,
        "y": 2408,
        "width": 600,
        "height": 300
    },
    "8.21.3": {
        "x": 2204,
        "y": 0,
        "width": 700,
        "height": 350
    },
    "8.22.1": {
        "x": 0,
        "y": 0,
        "width": 500,
        "height": 1700
    },
    "8.22.2": {
        "x": 501,
        "y": 0,
        "width": 500,
        "height": 1700
    },
    "8.22.3": {
        "x": 1002,
        "y": 0,
        "width": 500,
        "height": 1700
    },
    "8.23": {
        "x": 3807,
        "y": 2709,
        "width": 600,
        "height": 300
    },
    "8.24": {
        "x": 3807,
        "y": 3010,
        "width": 600,
        "height": 300
    },
    "8.25": {
        "x": 3807,
        "y": 3311,
        "width": 600,
        "height": 300
    },
    "8.4.2": {
        "x": 3807,
        "y": 3612,
        "width": 600,
        "height": 300
    },
    "8.13(2)": {
        "x": 0,
        "y": 4228,
        "width": 600,
        "height": 600
    },
    "8.13(3)": {
        "x": 601,
        "y": 4228,
        "width": 600,
        "height": 600
    },
    "8.13(4)": {
        "x": 1202,
        "y": 4228,
        "width": 600,
        "height": 600
    },
}
