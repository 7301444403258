import {CargoTrackingLayerSchema} from "./CargoTrackingLayerSchema";
import {RailwayNetworkLayerSchema} from "./RailwayNetworkLayerSchema";
import {RoadSchemaLayerSchema} from "./RoadSchemaLayerSchema";
import {CargoTrackingPointsLayerSchema} from "./CargoTrackingPointsLayerSchema";
import {TrafficSegmentsLayerSchema} from "./TrafficSegmentsLayerSchema";

export const nipigazLayersSchema = {
    CargoTrackingLayerSchema,
    RoadSchemaLayerSchema,
    CargoTrackingPointsLayerSchema,
    RailwayNetworkLayerSchema,
    TrafficSegmentsLayerSchema
}
