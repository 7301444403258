import securedAxiosInstance from "../services/net/axiosSecured";

export const userSettingApi = {
    async getSettingByField(field) {
        const response =  await securedAxiosInstance.get('user_settings/',{
            params:{
                path:field,
            }
        })
        return response?.data?.data ?? null
    },
    async createSettingByField(pathToRecord,data) {
        const transcriptPathToObject = (pathToRecord,data) =>{
            let splitedPath = pathToRecord.split('.');
            let path = ''
            for (let it=0;it<splitedPath.length;it++){
                path+=`{"${splitedPath[it]}":`
            }
            path+=JSON.stringify(data);
            for (let it=0;it<splitedPath.length;it++){
                path+=`}`
            }
            return JSON.parse(path)
        }

        const response = await securedAxiosInstance.post('user_settings/',{
                data:transcriptPathToObject(pathToRecord,data)
        })
        return response
    },
    async deleteUserSettings() {
        return securedAxiosInstance.delete('user_settings/')
    },
    async updateSettingByField(pathToRecord,data) {
        const response = securedAxiosInstance.put(
            'user_settings/',
            {
                data:data
            },
            {
                params: {
                    path:pathToRecord,
                }
            })
        return response
    },
};
