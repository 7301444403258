import React, { useEffect, useState } from "react";
import OwnerTS from "./OwnerTS";
import useGlobal from "../../../store";
import { getFilteredDataItems } from "../../../utils/FilteredFunctions";
import { getFromURL } from "../../../../../../../../api/getFromURL";

const OwnerTSContainer = () => {
  const [globalState, globalActions] = useGlobal();
  const [ownerTS, setData] = useState([]);

  useEffect(() => {
    (async () => {
      const response = await getFromURL.getDataFromURL("/vehicles/");
      const reports = await response;
      setData(reports.results);
    })();
  }, []);

  const handleChange = (value) => {
    const filteredData = getFilteredDataItems(value, data);
    globalActions.actions.filteredDataActions(filteredData);
  };

  const data = ownerTS.map((data) => {
    return {
      nameOrganization: data.organization_name,
      grz: data.registration_number,
      value: data.vehicle_model_name,
      id: data.id,
      isChecked: false,
    };
  });

  // Todo: refactoring code filter data

  const organization = data.map((data) => data.nameOrganization);

  const nameOrganization = organization.filter((elem, pos) => {
    return organization.indexOf(elem) == pos;
  });
  return (
    <OwnerTS handleChange={handleChange} nameOrganization={nameOrganization} />
  );
};

export default OwnerTSContainer;
