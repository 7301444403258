export const signsMapperType7 = {
    "7.1": {
        "x": 0,
        "y": 0,
        "width": 700,
        "height": 1050
    },
    "7.2": {
        "x": 701,
        "y": 0,
        "width": 700,
        "height": 1050
    },
    "7.3": {
        "x": 0,
        "y": 1051,
        "width": 700,
        "height": 1050
    },
    "7.4": {
        "x": 701,
        "y": 1051,
        "width": 700,
        "height": 1050
    },
    "7.5": {
        "x": 1402,
        "y": 0,
        "width": 700,
        "height": 1050
    },
    "7.6": {
        "x": 1402,
        "y": 1051,
        "width": 700,
        "height": 1050
    },
    "7.7": {
        "x": 2103,
        "y": 0,
        "width": 700,
        "height": 1050
    },
    "7.8": {
        "x": 2103,
        "y": 1051,
        "width": 700,
        "height": 1050
    },
    "7.9": {
        "x": 0,
        "y": 2102,
        "width": 700,
        "height": 1050
    },
    "7.10": {
        "x": 701,
        "y": 2102,
        "width": 700,
        "height": 1050
    },
    "7.11": {
        "x": 1402,
        "y": 2102,
        "width": 700,
        "height": 1050
    },
    "7.12": {
        "x": 2103,
        "y": 2102,
        "width": 700,
        "height": 1050
    },
    "7.13": {
        "x": 2804,
        "y": 0,
        "width": 700,
        "height": 1050
    },
    "7.14.1": {
        "x": 2804,
        "y": 1051,
        "width": 700,
        "height": 1050
    },
    "7.14": {
        "x": 2804,
        "y": 2102,
        "width": 700,
        "height": 1050
    },
    "7.15": {
        "x": 0,
        "y": 3153,
        "width": 700,
        "height": 1050
    },
    "7.16": {
        "x": 701,
        "y": 3153,
        "width": 700,
        "height": 1050
    },
    "7.17": {
        "x": 1402,
        "y": 3153,
        "width": 700,
        "height": 1050
    },
    "7.18": {
        "x": 2103,
        "y": 3153,
        "width": 700,
        "height": 1050
    },
    "7.19": {
        "x": 2804,
        "y": 3153,
        "width": 700,
        "height": 1050
    },
    "7.20": {
        "x": 3505,
        "y": 0,
        "width": 700,
        "height": 1050
    },
    "7.21": {
        "x": 3505,
        "y": 1051,
        "width": 700,
        "height": 1050
    }
}
