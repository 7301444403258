import React from "react";
import {Input} from "antd";
import styled from "styled-components";

const StyledPasswordInput = styled(Input.Password)`
  .ant-input {
    width: 315px;
    height: 60px;
    background: #F2F2F2;
    border-radius: 4px;
    padding-left: 10px;
    padding-top: 25px;
  }
  
    .ant-input::placeholder {
    color: transparent;
  }

  .ant-input:focus {
    background: #F2F2F2;
    border: 2px solid #BDBDBD;
    box-sizing: border-box;
    border-radius: 4px;
    box-shadow: none;
  }

  .ant-input:hover {
    background: #F2F2F2;
    border: 2px solid #BDBDBD;
    box-sizing: border-box;
    border-radius: 4px;
    box-shadow: none;
  }
`

const PasswordInput = (props) => {
    return (
        <StyledPasswordInput {...props}>
        </StyledPasswordInput>
    )
}

export default PasswordInput;
