import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import ToggleButtonGroup from "../../../../../../../../../../library/UI/components/ToggleButtonGroup/ToggleButtonGroup";
import { setSelectedLayerSettingsType } from "../../../../../../../../../../reducers/map/actions/mapPanelsActions";
import { PaletteIcon } from "../../../../../../../../../../library/icons/actionIcons/PaletteIcon";
import { FilterIcon } from "../../../../../../../../../../library/icons/actionIcons/FilterIcon";
import { HistoryWatchIcon } from "../../../../../../../../../../library/icons/objectIcons/HistoryWatchIcon";

const SWITCHER_BUTTONS_TEMPLATE = [
  {
    id: 1,
    icon: <PaletteIcon />,
    name: "Дизайн",
    switcher: "changeControls",
  },
/*  {
    id: 2,
    icon: <FilterIcon />,
    name: "Фильтры",
    switcher: "filters",
  },
  {
    id: 3,
    icon: <HistoryWatchIcon />,
    name: "Шаблоны",
    switcher: "templates",
    requiredLayerKeyValue: { editable: true },
  },*/
];

const SettingsTypeSwitcherContainer = ({
  setPanelContext,
  layerSettingType,
  includedLayers,
}) => {
  const handleChangePanelContext = (value) => {
    setPanelContext(value);
  };

  const buttonClickHandler = (element) => {
    handleChangePanelContext(element.switcher);
  };

  const currentLayer = includedLayers.find((el) => el.isSelected);
  const copyOfTemplate = [...SWITCHER_BUTTONS_TEMPLATE];
  for (let item of copyOfTemplate) {
    if (item.requiredLayerKeyValue && currentLayer) {
      const key = Object.keys(item.requiredLayerKeyValue)[0];
      const value = Object.values(item.requiredLayerKeyValue)[0];
    }
  }

  return (
    <ToggleButtonGroup
      handleChangePanelContext={handleChangePanelContext}
      buttonClickHandler={buttonClickHandler}
      activePanel={layerSettingType}
      displayedButtons={copyOfTemplate}
    />
  );
};

SettingsTypeSwitcherContainer.propTypes = {
  layerSettingType: PropTypes.string,
  setPanelContext: PropTypes.func.isRequired,
};

const mapDispatchToProps = (dispatch) => {
  return {
    setPanelContext: (value) => dispatch(setSelectedLayerSettingsType(value)),
  };
};

const mapStateToProps = (state) => {
  return {
    layerSettingType: state.mapPanels.layerSettingType,
    includedLayers: state.mapboxLayers,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SettingsTypeSwitcherContainer);
