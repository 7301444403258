import {CLEAR_SELECTION, SELECT_OBJECT} from "../selectedObject";

export const selectObject = (data)  => ({
    type:SELECT_OBJECT,
    payload:data
})

export const clearSelection = ()  => ({
    type:CLEAR_SELECTION,
})
