import {RelatedDataPrototype} from "../prototype/RelatedDataPrototype";

export class Railways extends RelatedDataPrototype {

    constructor() {
        super({
            name: 'railways',
            filterLabel: 'Железные дороги',
            urlData: '/railway_network/railways/',
        });
    }
}
