import { LayerDataAccessorPrototype } from "../prototype/LayerDataAccessorPrototype";
import React from "react";
import {mapObjectsEnum} from "../../../../../../enums/mapObjectsEnum";

// Внимание к этому пути
const FilterFromSelectedDataButton = React.lazy(() =>
    import(
        "../../../../../../library/actionsButtons/NIPIGAZ/Map/dataAccessors/customForLayers/filterButtons/FilterCargoLocationPointsOnMapButton"
        )
);

const RefreshCargoTrackingDataButton = React.lazy(() =>
    import(
        "../../../../../../library/actionsButtons/NIPIGAZ/Map/dataAccessors/customForLayers/refreshButtons/RefreshCargoTrackingOnMapButton"
        )
);

export class CargoLocationPointsDataAccessor extends LayerDataAccessorPrototype {

  actionButtons = [FilterFromSelectedDataButton, RefreshCargoTrackingDataButton]

  constructor() {
    super({
      urlData: "/tmc_tracking/location_point/",
      useReduxData: true,
      i18LabelPath: "departurePoint",
      selectedType: mapObjectsEnum.cargoTrackingPoint,
      isSelectedEditable: true
    });
  }
}
