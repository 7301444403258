import React from "react";
import { baseStyles } from "../../../stylesRegistrator";
import MapToolBarContainer from "../MapToolBar/MapToolBarContainer";
import StandaloneEditModeControlsContainer from "./controls/StandaloneEditModeControlsContainer";
import StandaloneEditModeFormPanelContainer from "./panels/StandaloneEditModeFormPanelContainer";
import MapContextMenuContainer from "../ContextMenu/MapContextMenuContainer";
const {
  BaseMapPanelLayoutArea,
  BaseMapMainModuleWrapper,
  BaseMapContentLayoutArea,
  BaseMapContentLayoutAreaInner
} = baseStyles.modules.map;

const MapStandaloneEditMode = ({currentMode}) => {
  return (
    <>
      <BaseMapMainModuleWrapper>
        <BaseMapPanelLayoutArea>
          <StandaloneEditModeControlsContainer />
        </BaseMapPanelLayoutArea>
        <BaseMapContentLayoutArea>
          <BaseMapContentLayoutAreaInner>
            <StandaloneEditModeFormPanelContainer />
          </BaseMapContentLayoutAreaInner>
          <MapToolBarContainer showTimePlayer={false} />
        </BaseMapContentLayoutArea>
      </BaseMapMainModuleWrapper>
      <MapContextMenuContainer mode={currentMode} />
    </>
  );
};

export default MapStandaloneEditMode;
