import {LayerCardPrototype} from "../prototype/LayerCardPrototype";

export class RoadSchemaCard extends LayerCardPrototype {

    constructor() {
        super({
                i18path: 'roadsSchema',
                linkToPreview: 'SchemaDorog.svg'
            }
        );
    }
}
