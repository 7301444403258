import styled from "styled-components";
import { Layout } from "antd";
import ScrollableBoxArea from "../../areas/scrollableBoxArea/ScrollableBoxArea";

const PanelLayout = styled(Layout)`
  height: calc(100vh - 115px);
  padding: 0 20px 20px 20px;
  & ${ScrollableBoxArea} {
    margin-left: 20px !important;
  }
`;

export const Styled = {
  PanelLayout,
};
