import React from "react";
import CompendiumColumnActionPanel from "./CompendiumColumnActionPanel";

const CompendiumColumnActionPanelContainer = ({
  quickMenuState,
  toggleShowQuickFilter,
  toggleShowFilterMenu,
  toggleShowRowsMenu,
  iconSizeType
}) => {
  const handleSearchObject = () => toggleShowQuickFilter();

  const handleGroupObject = () => toggleShowRowsMenu();

  const handleFilterGrid = () => toggleShowFilterMenu();

  return (
    <CompendiumColumnActionPanel
      quickMenuState={quickMenuState}
      handleSearchObject={handleSearchObject}
      handleGroupObject={handleGroupObject}
      handleFilterGrid={handleFilterGrid}
      iconSizeType={iconSizeType}
    />
  );
};

export default CompendiumColumnActionPanelContainer;
