import { LayerDataAccessorPrototype } from "../prototype/LayerDataAccessorPrototype";
import { dataAccessMethodsEnum } from "../../../../../../enums/dataAccessMethodsEnum";
import {mapObjectsEnum} from "../../../../../../enums/mapObjectsEnum";
import React from "react";

const FilterFromSelectedDataButton = React.lazy(() =>
  import(
    "../../../../../../library/actionsButtons/NIPIGAZ/Map/dataAccessors/customForLayers/filterButtons/FilterGeozonesOnMapButton"
    )
);

const RefreshDataButtonOnMap = React.lazy(() => import('../../../../../../library/actionsButtons/NIPIGAZ/Map/dataAccessors/customForLayers/refreshButtons/RefreshGeozonesOnMapButton'))


export class GeozonesDataAccessor extends LayerDataAccessorPrototype {

  actionButtons = [FilterFromSelectedDataButton, RefreshDataButtonOnMap]

  constructor() {
    super({
      urlData: "/geozones/",
      dataAccessMethod: dataAccessMethodsEnum.httpGetAll,
      i18LabelPath: "geozones",
      selectedType: mapObjectsEnum.geozone,
      isSelectedEditable: true
    });
  }
}
