export const signsMapperType4 = {
  "4.1.1": {
    x: 1903,
    y: 1502,
    width: 600,
    height: 600,
  },
  "4.1.2": {
    x: 601,
    y: 1502,
    width: 600,
    height: 600,
  },
  "4.1.3": {
    x: 0,
    y: 0,
    width: 600,
    height: 600,
  },
  "4.1.4": {
    x: 1302,
    y: 2103,
    width: 600,
    height: 600,
  },
  "4.1.5": {
    x: 601,
    y: 0,
    width: 600,
    height: 600,
  },
  "4.1.6": {
    x: 701,
    y: 2103,
    width: 600,
    height: 600,
  },
  "4.2.1": {
    x: 1202,
    y: 1502,
    width: 600,
    height: 600,
  },
  "4.2.2": {
    x: 1903,
    y: 2103,
    width: 600,
    height: 600,
  },
  "4.2.3": {
    x: 0,
    y: 1502,
    width: 600,
    height: 600,
  },
  "4.3": {
    x: 1903,
    y: 0,
    width: 600,
    height: 600,
  },
  "4.4.1": {
    x: 0,
    y: 2804,
    width: 700,
    height: 700,
  },
  "4.4.2": {
    x: 1202,
    y: 0,
    width: 700,
    height: 700,
  },
  "4.5.1": {
    x: 2103,
    y: 2804,
    width: 700,
    height: 700,
  },
  "4.5.2": {
    x: 2504,
    y: 0,
    width: 700,
    height: 700,
  },
  "4.5.3": {
    x: 0,
    y: 2103,
    width: 700,
    height: 700,
  },
  "4.5.4": {
    x: 2504,
    y: 2103,
    width: 700,
    height: 700,
  },
  "4.5.5": {
    x: 2504,
    y: 1402,
    width: 700,
    height: 700,
  },
  "4.5.6": {
    x: 1402,
    y: 2804,
    width: 700,
    height: 700,
  },
  "4.5.7": {
    x: 1202,
    y: 701,
    width: 700,
    height: 700,
  },
  // "4.6": {
  //     "x": 2504,
  //     "y": 701,
  //     "width": 700,
  //     "height": 700
  // },
  "4.7": {
    x: 701,
    y: 2804,
    width: 700,
    height: 700,
  },
  "4.8.1": {
    x: 0,
    y: 601,
    width: 600,
    height: 900,
  },
  "4.8.2": {
    x: 601,
    y: 601,
    width: 600,
    height: 900,
  },
  "4.8.3": {
    x: 1903,
    y: 601,
    width: 600,
    height: 900,
  },
};
