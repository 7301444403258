import { CompositeLayer } from "@deck.gl/core";
import { PathLayer, TextLayer } from "@deck.gl/layers";
import wkt from "wkt";
import { layerNamesEnum } from "../../../../enums/layerNamesEnum";
import { mapObjectsEnum } from "../../../../enums/mapObjectsEnum";

const IMPORTANCE_SET = ["MUNICIPAL", "REGIONAL", "FEDERAL"];

class TrafficSegmentsCompositeLayer extends CompositeLayer {
  updateState({ changeFlags, props }) {
    this.setState({
      data: props,
    });
  }
  getPickingInfo(event) {
    if (event.mode === "query") {
      if (this.props.onClickHandler) {
        let selectedObjectType = null;
        let selectedObjectLayerName = layerNamesEnum.trafficRoadsSections;
        if (event.sourceLayer.id.includes("segments_background_layer"))
          selectedObjectType = mapObjectsEnum.trafficBackgroundRoadSegment;
        if (event.sourceLayer.id.includes("traffic_parts_path"))
          selectedObjectType = mapObjectsEnum.trafficSegment;
        this.props.onClickHandler(
          event.info,
          selectedObjectType,
          selectedObjectLayerName
        );
      } else return null;
    }
  }
  renderLayers() {
    if (!this.props.relatedData) return [];
    const signsLayers = [];

    return [
      new PathLayer(
        this.props,
        this.getSubLayerProps({ id: "segments_background_layer" }),
        {
          data: this.props.relatedData.segments,
          pickable: true,
          autoHighlight: true,
          widthScale: 3,
          billboard: false,
          widthMinPixels: 3,
          getPath: (d) => {
            if (d?.line_path) {
              const geometry = wkt.parse(d.line_path);
              return geometry.coordinates;
            }
            return [0, 0];
          },
          getColor: [0, 0, 0],
          getWidth: (d) => 5,
          opacity: this?.props?.opacity ?? 0.1,
          onClick: (d) => {
            this.props.onClick();
          },
        }
      ),

      new PathLayer(
        this.props,
        this.getSubLayerProps({ id: "traffic_parts_path" }),
        {
          data: this.props.relatedData.trafficSegments,
          widthScale: 3,
          pickable: true,
          billboard: false,
          widthMinPixels: 3,
          opacity: this?.props?.opacity ?? 0.1,
          getPath: (d) => {
            if (d?.line_path) {
              const geometry = wkt.parse(d.line_path);
              return geometry.coordinates;
            }
            return [0, 0];
          },
          getColor: (d) => {
            return [67, 148, 37];
          },
          getWidth: (d) => 5,
        }
      ),
    ];
  }
}

const defaultProps = {
  // Inherit all of GeoJsonLayer's props
  ...TextLayer.defaultProps,
  // Label for each feature
  getLabel: { type: "accessor", value: (x) => x },
  // Label size for each feature
  getLabelSize: { type: "accessor", value: 8 },
  // Label color for each feature
  getLabelColor: { type: "accessor", value: [0, 0, 0, 255] },
  getCurrentTimeStamp: { type: "accessor", value: 0 },
  // Label always facing the camera
  billboard: true,
  // Label size units
  labelSizeUnits: "pixels",
  // Label background color
  labelBackground: { type: "color", value: [255, 255, 255], optional: true },
  // Label font
  fontFamily: "Monaco, monospace",
};

TrafficSegmentsCompositeLayer.layerName = "TrafficSegments";
TrafficSegmentsCompositeLayer.defaultProps = defaultProps;

export default TrafficSegmentsCompositeLayer;
