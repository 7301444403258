import styled from "styled-components";

const LocalMainWrapper = styled.div`
  display: grid;
  grid-template-columns: 450px 350px auto;
  grid-template-areas:
    "a b c c";

  background-image: url(${props => props.imgSrc});
  background-size: contain;
  background-position: right;
  background-repeat: no-repeat;

  .greeting-clipPath {
    position: absolute;
    top: -100px;
    left: -40px;
    z-index: 0;
  }
`;

const LocalBackgroundLeftSide = styled.div`
  grid-area: a;
  height: 100vh;
  //width: 442px;
  background: ${props => props.theme.colors.defaultLighter};
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 40px;
  padding-right: 0;
  z-index: 0;
`;



const LocalLogoNipigaz = styled.div`
  position: absolute;
  top: 5%;
`;

const LocalGreetingSpriteWrapper = styled.div`
        position: relative;
        width: 430px;
        height: 216px;
        text-align: left;
        z-index: 1;
        top: 0;
`;

const LocalGreetingSpriteInner = styled.div`
        position: absolute;
        width: 430px;
        height: 216px;
        text-align: left;
        z-index: 1;
`;

const LocalLogoITS = styled.div`
  position: absolute;
  bottom: 6%;
  left: 2%;
`;

const LocalBackgroundCenter = styled.div`
        grid-area: b;
        height: 100vh;
        //width: 354px;
        position: relative;
`


const LocalBackgroundRightSide = styled.div`
  grid-area: c;
  height: 100vh;
  width: 100%;
  overflow: hidden;
`;

const LocalBackgroundIMG = styled.img`
  //width: 100%;
  height: 100%;
  display: inline;
  pointer-events: none;
  objectfit: "fill";
`;




export const Styled = {
  LocalMainWrapper,
  LocalBackgroundLeftSide,
  LocalLogoNipigaz,
  LocalLogoITS,
  LocalBackgroundRightSide,
  LocalBackgroundIMG,
  LocalBackgroundCenter,
  LocalGreetingSpriteInner,
  LocalGreetingSpriteWrapper,
};
