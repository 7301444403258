import React from 'react'

const PropsPasser = ({component, ...other}) => {
    if (component) {
        const RenderedComponent = React.cloneElement(component, other);
        return (
            <>
                {React.cloneElement(component, other)}
            </>
        )
    } else {
        return null
    }
}

export default PropsPasser
