import React from "react";
import { Menu } from "antd";
import { useTranslation } from "react-i18next";
import { Styled } from "./ReportSider.styled";
import { Button } from "../../../../../../../library/UI/components/Button/Button";
import { useHistory } from "react-router-dom";

const {
  SiderWrapper,
  SiderInner,
  SiderContent,
  SiderFooter,
  SiderTitle,
} = Styled;

const ReportSider = ({ sendData }) => {
  const history = useHistory();
  const { t } = useTranslation("common");
  return (
    <SiderWrapper width={"16%"}>
      <SiderInner>
        <SiderContent>
          <SiderTitle>Данные</SiderTitle>
          <Menu onClick={""}>
            <Menu.Item>Конструктор отчета</Menu.Item>
          </Menu>
        </SiderContent>
        <SiderFooter>
          <Button onClick={sendData} type={"primary"} width={"100%"}>
            {t("Сформировать отчет")}
          </Button>
          <Button
            onClick={() => history.replace("/app/reports/")}
            type={"danger"}
            width={"100%"}
          >
            {t("app.detailedObject.quit")}
          </Button>
        </SiderFooter>
      </SiderInner>
    </SiderWrapper>
  );
};

export default ReportSider;
