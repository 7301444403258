import React, { useEffect, useState } from "react";
import { Select } from "antd";
import { Styled } from "./GeneralDataModelTS.styled";
import DataGRZContainer from "./GRZ/DataGRZContainer";
import DataModelTSContainer from "./ModelTS/DataModelTSContainer";
import useGlobal from "../../../store";

const { Option } = Select;

const {
  LocalModelTSWrapper,
  LocalSelectedWrapperGRZ,
  LocalSelectedModelTS,
} = Styled;

const GeneralDataModelTS = ({ updateDate }) => {
  const [data, setData] = useState();

  updateDate = (value) => {
    setData(value);
  };

  return (
    <LocalModelTSWrapper>
      <LocalSelectedModelTS>
        <DataModelTSContainer updateDate={updateDate} />
      </LocalSelectedModelTS>
      <LocalSelectedWrapperGRZ>
        <DataGRZContainer data={data} />
      </LocalSelectedWrapperGRZ>
    </LocalModelTSWrapper>
  );
};

export default GeneralDataModelTS;
