import { CompositeLayer } from "@deck.gl/core";
import {
  ArcLayer,
  IconLayer,
  ScatterplotLayer,
  TextLayer,
} from "@deck.gl/layers";
import wkt from "wkt";

const selectColorForPoint = (transferPart) => {
  if (transferPart?.actual_port_arrival_date) {
    return [47, 128, 237];
  } else {
    return [146, 159, 181];
  }
};
const selectColorForLinePoint = (transferPart) => {
  if (transferPart?.actual_port_arrival_date) {
    return [47, 128, 237, 80];
  } else {
    return [146, 159, 181, 80];
  }
};

class SingleCargoCompositeLayer extends CompositeLayer {
  updateState({ changeFlags, props, oldProps, ...other }) {
    const dataPrepared = props.data[0].data;
    const transfer_parts = dataPrepared?.transfer_parts
      ? [...dataPrepared?.transfer_parts].sort((a, b) => a.order - b.order)
      : [];
    dataPrepared.transfer_parts = transfer_parts;
    this.setState({ data: [dataPrepared] });
  }

  getPickingInfo(event) {
    if (event.mode === "query") {
      if (this.props.onClickHandler) {
      } else return null;
    }
  }

  renderLayers() {
    const ICON_MAPPING = {
      letterA: { x: 0, y: 0, width: 88, height: 88 },
      letterB: { x: 0, y: 89, width: 88, height: 88 },
      actualCargoPoint: { x: 0, y: 267, width: 32, height: 32 },
    };
    if (!this.state) return [];
    return [
      new ScatterplotLayer(
        this.props,
        this.getSubLayerProps({ id: "cargo-point-first" }),
        {
          pickable: true,
          data: this.state.data,
          stroked: true,
          filled: true,
          radiusScale: 1,
          radiusMinPixels: 20,
          radiusMaxPixels: 30,
          lineWidthMaxPixels: 6,
          lineWidthMinPixels: 6,
          getPosition: (d) => {
            const location =
              d.transfer_parts?.[0]?.location_point_from?.location;
            if (location) {
              const parsed = wkt.parse(location);
              if (parsed) {
                return parsed.coordinates;
              } else {
                return [100, 100];
              }
            } else {
              return [100, 100];
            }
          },
          getRadius: 20,
          opacity: 0.8,
          wireframe: true,
          getLineWidth: 6,
          getFillColor: (d) => selectColorForPoint(d.transfer_parts?.[0].actual_port_arrival_date !== null
              ? d.transfer_parts?.[0]
              : d.transfer_parts?.[d.transfer_parts.length-1]),
          getLineColor: (d) => selectColorForLinePoint(d.transfer_parts?.[0].actual_port_arrival_date !== null
              ? d.transfer_parts?.[0]
              : d.transfer_parts?.[d.transfer_parts.length-1]),
        }
      ),
      new TextLayer(
        this.props,
        this.getSubLayerProps({ id: "name-of-points-first" }),
        {
          data: this.state.data,
          getPixelOffset: [0, 42],
          backgroundColor: [255, 255, 255, 90],
          fp64: true,
          getPolygonOffset: ({ layerIndex }) => [0, -layerIndex * 10000],
          getPosition: (d) => {
            const location =
              d.transfer_parts?.[0]?.location_point_from?.location;
            if (location) {
              const parsed = wkt.parse(location);
              if (parsed) {
                return parsed.coordinates;
              } else {
                return [100, 100];
              }
            } else {
              return [100, 100];
            }
          },
          getText: (d) =>
            d.transfer_parts?.[0]?.location_point_from?.name?.toUpperCase(),
          billboard: true,
          getColor: [193, 203, 216],
          getOpacity: 1,
          sizeScale: 1,
          getSize: 20,
          getAngle: 0,
          getTextAnchor: "middle",
          getAlignmentBaseline: "center",
          sizeMinPixels: 20,
          sizeMaxPixels: 20,
        }
      ),
      new IconLayer(
        this.props,
        this.getSubLayerProps({ id: "icon-layer-first-point" }),
        {
          data: this.state.data,
          iconMapping: ICON_MAPPING,
          getIcon: (d) => {
            const startLocation =
              d.transfer_parts?.[0]?.location_point_from?.name;
            if (startLocation) return "letterA";
          },
          iconAtlas: `${process.env.PUBLIC_URL}/img/textures/lettersWithPoints.png`,
          pickable: true,
          getSize: 40,
          fp64: true,
          getPolygonOffset: ({ layerIndex }) => [0, -layerIndex * 10000],
          sizeScale: 1,
          sizeMinPixels: 10,
          sizeMaxPixels: 60,
          getPosition: (d) => {
            const location =
              d.transfer_parts?.[0]?.location_point_from?.location;
            if (location) {
              const parsed = wkt.parse(location);
              if (parsed) {
                return parsed.coordinates;
              } else {
                return [100, 100];
              }
            } else {
              return [100, 100];
            }
          },
        }
      ),
      new ScatterplotLayer(
        this.props,
        this.getSubLayerProps({ id: "cargo-point-second" }),
        {
          pickable: true,
          data: this.state.data,
          stroked: true,
          filled: true,
          radiusScale: 1,
          radiusMinPixels: 20,
          radiusMaxPixels: 30,
          lineWidthMaxPixels: 6,
          lineWidthMinPixels: 6,
          getPosition: (d) => {
            const location = (d.transfer_parts?.length == 3 || d.transfer_parts?.length == 2)
                ? d.transfer_parts?.[1]?.location_point_from?.location
                : null
            if (location) {
              const parsed = wkt.parse(location);
              if (parsed) {
                return parsed.coordinates;
              } else {
                return [100, 100];
              }
            } else {
              return [100, 100];
            }
          },
          getRadius: 20,
          opacity: 0.8,
          wireframe: true,
          getFillColor: (d) => selectColorForPoint(d.transfer_parts?.[0].actual_port_arrival_date !== null
              ? d.transfer_parts?.[0]
              : d.transfer_parts?.[d.transfer_parts.length-1]),
          getLineColor: (d) => selectColorForLinePoint(d.transfer_parts?.[0].actual_port_arrival_date !== null
              ? d.transfer_parts?.[0]
              : d.transfer_parts?.[d.transfer_parts.length-1]),
        }
      ),
      new TextLayer(
        this.props,
        this.getSubLayerProps({ id: "name-of-points-second" }),
        {
          data: this.state.data,
          getPixelOffset: [0, 42],
          backgroundColor: [255, 255, 255],

          // sdf: true,
          fp64: true,
          getPolygonOffset: ({ layerIndex }) => [0, -layerIndex * 10000],
          getPosition: (d) => {
            const location = d.transfer_parts?.length == 3
                ? d.transfer_parts?.[1]?.location_point_from?.location
                : null
            if (location) {
              const parsed = wkt.parse(location);
              if (parsed) {
                return parsed.coordinates;
              } else {
                return [100, 100];
              }
            } else {
              return [100, 100];
            }
          },
          getText: (d) => d.transfer_parts?.length == 3
                  ? d.transfer_parts?.[1]?.location_point_from?.name?.toUpperCase()
                  : null,
          billboard: true,
          getColor: [193, 203, 216],
          getOpacity: 1,
          sizeScale: 1,
          getSize: 20,
          getAngle: 0,
          getTextAnchor: "middle",
          getAlignmentBaseline: "center",
          sizeMinPixels: 20,
          sizeMaxPixels: 20,
        }
      ),
      new ScatterplotLayer(
        this.props,
        this.getSubLayerProps({ id: "cargo-point-third" }),
        {
          pickable: true,
          data: this.state.data,
          stroked: true,
          radiusScale: 1,
          radiusMinPixels: 20,
          radiusMaxPixels: 30,
          lineWidthMaxPixels: 6,
          lineWidthMinPixels: 6,
          getPosition: (d) => {
            const location = (d.transfer_parts?.length == 3 || d.transfer_parts?.length == 2)
                ? d.transfer_parts?.[d.transfer_parts.length-1]?.location_point_from?.location
                : null
            if (location) {
              const parsed = wkt.parse(location);
              if (parsed) {
                return parsed.coordinates;
              } else {
                return [100, 100];
              }
            } else {
              return [100, 100];
            }
          },
          getRadius: 20,
          opacity: 0.8,
          wireframe: true,
          getFillColor: (d) => selectColorForPoint(d.transfer_parts?.[1].actual_port_arrival_date !== null
              ? d.transfer_parts?.[1]
              : d.transfer_parts?.[d.transfer_parts.length-1]),
          getLineColor: (d) => selectColorForLinePoint(d.transfer_parts?.[1].actual_port_arrival_date !== null
              ? d.transfer_parts?.[1]
              : d.transfer_parts?.[d.transfer_parts.length-1]),
        }
      ),
      new TextLayer(
        this.props,
        this.getSubLayerProps({ id: "name-of-points-third" }),
        {
          data: this.state.data,
          getPixelOffset: [0, 42],
          backgroundColor: [255, 255, 255],
          fp64: true,
          getPolygonOffset: ({ layerIndex }) => [0, -layerIndex * 10000],
          getPosition: (d) => {
            const location =
              d.transfer_parts?.[d.transfer_parts.length-1]?.location_point_from?.location;
            if (location) {
              const parsed = wkt.parse(location);
              if (parsed) {
                return parsed.coordinates;
              } else {
                return [100, 100];
              }
            } else {
              return [100, 100];
            }
          },
          getText: (d) =>
            d.transfer_parts?.[d.transfer_parts.length-1]?.location_point_from?.name?.toUpperCase(),
          billboard: true,
          getColor: [193, 203, 216],
          getOpacity: 1,
          sizeScale: 1,
          getSize: 20,
          getAngle: 0,
          getTextAnchor: "middle",
          getAlignmentBaseline: "center",
          sizeMinPixels: 20,
          sizeMaxPixels: 20,
        }
      ),
      new IconLayer(
        this.props,
        this.getSubLayerProps({ id: "icon-layer-current-point" }),
        {
          data: this.state.data,
          iconMapping: ICON_MAPPING,
          getIcon: (d) => {
             const filtercurrentLocation = d.transfer_parts?.filter(f => f.actual_port_arrival_date !== null)
            const currentLocation = filtercurrentLocation[filtercurrentLocation.length-1]
            if (currentLocation) return "actualCargoPoint";
          },
          iconAtlas: `${process.env.PUBLIC_URL}/img/textures/lettersWithPoints.png`,
          pickable: true,
          getSize: 40,
          fp64: true,
          getPolygonOffset: ({ layerIndex }) => [0, -layerIndex * 10000],
          sizeScale: 1,
          sizeMinPixels: 10,
          sizeMaxPixels: 20,
          getPosition: (d) => {
                //проверка нахождения груза
              let filtercurrentLocation = d.transfer_parts?.filter(f => f.actual_port_arrival_date !== null)
              let currentLocation = filtercurrentLocation[filtercurrentLocation.length-1]

              let location =d.transfer_parts?.[d.transfer_parts.length-1]?.actual_port_arrival_date
                  ? null
                  : currentLocation?.location_point_to?.location

            /*
                d.transfer_parts?.length == 3
                ? d.transfer_parts?.[d.transfer_parts-1]?.location_point_from?.location
                : null*/
            if (location) {
              const parsed = wkt.parse(location);
              if (parsed) {
                return parsed.coordinates;
              } else {
                return [100, 100];
              }
            } else {
              return [100, 100];
            }
          },
        }
      ),
      new ScatterplotLayer(
        this.props,
        this.getSubLayerProps({ id: "cargo-end-point" }),
        {
          pickable: true,
          data: this.state.data,
          stroked: true,
          filled: true,
          radiusScale: 1,
          radiusMinPixels: 20,
          radiusMaxPixels: 30,
          lineWidthMaxPixels: 6,
          lineWidthMinPixels: 6,
          getPosition: (d) => {
            const location = d.transfer_parts?.[d.transfer_parts.length-1]?.location_point_to?.location;
            if (location) {
              const parsed = wkt.parse(location);
              if (parsed) {
                return parsed.coordinates;
              } else {
                return [100, 100];
              }
            } else {
              return [100, 100];
            }
          },
          getRadius: 20,
          opacity: 0.8,
          wireframe: true,
          getFillColor: (d) => selectColorForPoint(d.transfer_parts?.[d.transfer_parts.length-1]),
          getLineColor: (d) => selectColorForLinePoint(d.transfer_parts?.[d.transfer_parts.length-1]),
        }
      ),
      new TextLayer(
        this.props,
        this.getSubLayerProps({ id: "name-of-end-points" }),
        {
          data: this.state.data,
          getPixelOffset: [0, 42],
          fp64: true,
          getPolygonOffset: ({ layerIndex }) => [0, -layerIndex * 10000],
          getPosition: (d) => {
            const location = d.transfer_parts?.[d.transfer_parts.length-1]?.location_point_to?.location;
            if (location) {
              const parsed = wkt.parse(location);
              if (parsed) {
                return parsed.coordinates;
              } else {
                return [100, 100];
              }
            } else {
              return [100, 100];
            }
          },
          getText: (d) => d.transfer_parts?.[d.transfer_parts.length-1]?.location_point_to?.name?.toUpperCase(),
          billboard: true,
          getColor: [193, 203, 216],
          backgroundColor: [255, 255, 255],
          getOpacity: 1,
          sizeScale: 1,
          getSize: 20,
          getAngle: 0,
          getTextAnchor: "middle",
          getAlignmentBaseline: "center",
          sizeMinPixels: 20,
          sizeMaxPixels: 20,
        }
      ),
      new IconLayer(
        this.props,
        this.getSubLayerProps({ id: "icon-layer-first-point" }),
        {
          data: this.state.data,
          iconMapping: ICON_MAPPING,
          getIcon: (d) => {
            const endLocation = d.transfer_parts?.[d.transfer_parts.length-1]?.location_point_to?.name;
            if (endLocation) return "letterB";
          },
          iconAtlas: `${process.env.PUBLIC_URL}/img/textures/lettersWithPoints.png`,
          pickable: true,
          getSize: 40,
          fp64: true,
          getPolygonOffset: ({ layerIndex }) => [0, -layerIndex * 10000],
          sizeScale: 1,
          sizeMinPixels: 10,
          sizeMaxPixels: 60,
          getPosition: (d) => {
            const location =
                d.transfer_parts?.[d.transfer_parts.length-1]?.location_point_to?.location;
            if (location) {
              const parsed = wkt.parse(location);
              if (parsed) {
                return parsed.coordinates;
              } else {
                return [100, 100];
              }
            } else {
              return [100, 100];
            }
          },
        }
      ),

      new ArcLayer({
        id: "arc-layer-first",
        data: this.state.data,
        getHeight: 0.5,
        getWidth: 10,
        getSourcePosition: (d) => {
          const location = d.transfer_parts?.[0]?.location_point_from?.location;
          if (location) {
            const parsed = wkt.parse(location);
            if (parsed) {
              return parsed.coordinates;
            } else {
              return [100, 100];
            }
          } else {
            return [100, 100];
          }
        },
        getTargetPosition: (d) => {
          const location = d.transfer_parts?.[0]?.location_point_to?.location;
          if (location) {
            const parsed = wkt.parse(location);
            if (parsed) {
              return parsed.coordinates;
            } else {
              return [100, 100];
            }
          } else {
            return [100, 100];
          }
        },
        getSourceColor: (d) => selectColorForPoint(d.transfer_parts?.[0].actual_port_arrival_date !== null
            ? d.transfer_parts?.[0]
            : d.transfer_parts?.[d.transfer_parts.length-1]),
        getTargetColor: (d) => selectColorForPoint(d.transfer_parts?.[0].actual_port_arrival_date !== null
            ? d.transfer_parts?.[0]
            : d.transfer_parts?.[d.transfer_parts.length-1]),
      }),
      new ArcLayer({
        id: "arc-layer-second",
        data: this.state.data,
        getHeight: 0.5,
        getWidth: 10,
        getSourcePosition: (d) => {
          const location = d.transfer_parts?.length == 3
              ? d.transfer_parts?.[1]?.location_point_from?.location
              : null
          if (location) {
            const parsed = wkt.parse(location);
            if (parsed) {
              return parsed.coordinates;
            } else {
              return [100, 100];
            }
          } else {
            return [100, 100];
          }
        },
        getTargetPosition: (d) => {
          const location = d.transfer_parts?.length == 3
              ? d.transfer_parts?.[1]?.location_point_to?.location
              : null
          if (location) {
            const parsed = wkt.parse(location);
            if (parsed) {
              return parsed.coordinates;
            } else {
              return [100, 100];
            }
          } else {
            return [100, 100];
          }
        },
        getSourceColor: (d) => selectColorForPoint(d.transfer_parts?.[1].actual_port_arrival_date !== null
            ? d.transfer_parts?.[1]
            : d.transfer_parts?.[d.transfer_parts.length-1]),
        getTargetColor: (d) => selectColorForPoint(d.transfer_parts?.[1].actual_port_arrival_date !== null
            ? d.transfer_parts?.[1]
            : d.transfer_parts?.[d.transfer_parts.length-1]),
      }),
      new ArcLayer({
        id: "arc-layer-third",
        getHeight: 0.5,
        data: this.state.data,
        getWidth: 10,
        getDashArray: [3, 2],
        dashJustified: true,
        getSourcePosition: (d) => {
          const location = d.transfer_parts?.length == 3
              ? d.transfer_parts?.[2]?.location_point_from?.location
              : d.transfer_parts?.[1]?.location_point_from?.location
          if (location) {
            const parsed = wkt.parse(location);
            if (parsed) {
              return parsed.coordinates;
            } else {
              return [100, 100];
            }//дополнительная проверка построения дуги
          }  else {
            return [100, 100];
          }
        },
        getTargetPosition: (d) => {
          const location = d.transfer_parts?.length == 3
              ? d.transfer_parts?.[2]?.location_point_to?.location
              : d.transfer_parts?.[1]?.location_point_to?.location
          if (location) {
            const parsed = wkt.parse(location);
            if (parsed) {
              return parsed.coordinates;
            } else {
              return [100, 100];
            }
          } else {
            return [100, 100];
          }
        },
        getSourceColor: (d) => selectColorForPoint(d.transfer_parts?.[d.transfer_parts.length-1]),
        getTargetColor: (d) => selectColorForPoint(d.transfer_parts?.[d.transfer_parts.length-1]),
      }),
    ];
  }
}

const defaultProps = {
  // Inherit all of GeoJsonLayer's props
  ...TextLayer.defaultProps,
  // Label for each feature
  getLabel: { type: "accessor", value: (x) => x },
  // Label size for each feature
  getLabelSize: { type: "accessor", value: 8 },
  // Label color for each feature
  getLabelColor: { type: "accessor", value: [0, 0, 0, 255] },
  getCurrentTimeStamp: { type: "accessor", value: 0 },
  // Label always facing the camera
  billboard: true,
  // Label size units
  labelSizeUnits: "pixels",
  // Label background color
  labelBackground: { type: "color", value: [255, 255, 255], optional: true },
  // Label font
  fontFamily: "Monaco, monospace",
};

SingleCargoCompositeLayer.layerName = "CargoTrackingLayer";
SingleCargoCompositeLayer.defaultProps = defaultProps;

export default SingleCargoCompositeLayer;
