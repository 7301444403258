import React from "react";
import { Icon } from "antd";

const RoadWorksIconSVG = () => (
    <svg width="18" height="17" viewBox="0 0 18 17" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M10.6192 1.22234C10.5618 1.05979 10.4554 0.91904 10.3146 0.819491C10.1739 0.719942 10.0057 0.666491 9.83335 0.666504H8.16669C7.9943 0.666491 7.82615 0.719942 7.68541 0.819491C7.54466 0.91904 7.43826 1.05979 7.38085 1.22234L2.57669 14.8332H0.666687V16.4998H17.3334V14.8332H15.4234L10.6192 1.22234ZM11.8917 9.83317H6.10835L6.99169 7.33317H11.0084L11.8917 9.83317ZM8.75585 2.33317H9.24419L10.4209 5.6665H7.57919L8.75585 2.33317ZM5.52085 11.4998H12.4792L13.6559 14.8332H4.34419L5.52085 11.4998Z" fill="#1B3548"/>
    </svg>


);

export const RoadWorksIcon = props => (
    <Icon component={RoadWorksIconSVG} {...props} />
);
