import React from "react";
import { Button } from "../../../../../library/UI/components/Button/Button";
import { PlayIcon } from "../../../../../library/icons/actionIcons/PlayIcon";
import { CloseIcon } from "../../../../../library/icons/actionIcons/CloseIcon";
import { LocationIcon } from "../../../../../library/icons/objectIcons/LocationIcon";
import _ from "underscore";
import {LocationDisabledIcon} from "../../../../../library/icons/objectIcons/LocationDisabledIcon";
import VisibleUnvisibleFilterPanelContainer from "./VisibleUnvisibleFilter/VisibleUnvisibleFilterPanelContainer";

const ConditionalItemsGroup = ({
                                   handleToggleTimePlayerVisibility,
                                   timePlayerVisible,
                                   iconSize,
                                   stopTimePlayerHandler,
                                   showTimePlayer,
                                   iconSizeLarge,
                                   toggleVisibleUnvisibleFilter,
                                   visibleUnvisibleFilter,
                                   selectedLayer
                               }) => {
    const onClickHandlerCombination = () => {
        handleToggleTimePlayerVisibility();
        timePlayerVisible && stopTimePlayerHandler();
    };
    const iconType = !timePlayerVisible ? <PlayIcon key={_.uniqueId()}/> : <CloseIcon key={_.uniqueId()}/>;
    return (
        <>
          {/*  {showTimePlayer && (
                <Button
                    hasShadow
                    rounded
                    type={"primaryReverse"}
                    onClick={() => onClickHandlerCombination()}
                    icon={iconType}
                    iconSize={iconSize}
                    disabled
                    key={_.uniqueId()}
                />
            )}*/}
            <Button
                hasShadow
                rounded
                type={"primaryReverse"}
                icon={visibleUnvisibleFilter && selectedLayer ? <LocationDisabledIcon key={_.uniqueId()}/> :
                    <LocationIcon key={_.uniqueId()}/>}
                p={0.5}
                key={_.uniqueId()}
                iconSize={iconSizeLarge}
                onClick={toggleVisibleUnvisibleFilter}
            />
            {selectedLayer && visibleUnvisibleFilter  && <VisibleUnvisibleFilterPanelContainer/>}

            </>
    );
};

export default ConditionalItemsGroup;
