import React, {useState} from "react";
import { baseStyles } from "../../../../stylesRegistrator";
import { Button } from "../../../../../library/UI/components/Button/Button";
import { Styled } from "./StandaloneEditModeFormPanel.styled";
import AutocompleteForm from "../../../../../library/objectPanels/Common/AutocompleteForm/AutocompleteForm.jsx";

const {
  BaseMapPanelTopPart,
  BaseMapPanelPrimaryTitle,
  BaseMapPanelItem
} = baseStyles.modules.map;

const StandaloneEditModeFormPanel = React.forwardRef(
  ({ handleSubmitForm, editableStandaloneObject, initSubmitForm, handleRejectObjectEditing, writeTimetoState },  ref) => {
      const [ dateTime, setDateTime ] = useState({
          datetime_start: null,
          datetime_end: null
      });
      // may be need refactoring
    const handleSendData = () => {
        writeTimetoState(dateTime)
    }
      handleSendData()
      return (
          <Styled.ExtendedPanelWrapper>
              <Styled.ExtendedPanelHeader>
                  <BaseMapPanelTopPart>
                      <BaseMapPanelPrimaryTitle>
                          {editableStandaloneObject.name}
                      </BaseMapPanelPrimaryTitle>
                  </BaseMapPanelTopPart>
              </Styled.ExtendedPanelHeader>
              <BaseMapPanelItem>
                  <AutocompleteForm
                      model={editableStandaloneObject.model}
                      data={editableStandaloneObject.instance}
                      handleSubmitForm={handleSubmitForm}
                      initClose={() => console.log("init close")}
                      ref={ref}
                      hideTitle
                      form={{
                          showTime: true,
                          dateTime,
                          setDateTime,
                          ref
                      }}
                  />
              </BaseMapPanelItem>
              <BaseMapPanelItem>
                  <Button
                      type={"primary"}
                      onClick={() => initSubmitForm()}
                      width={"100%"}
                      hasShadow
                  >
                      Сохранить изменения
                  </Button>
                  <Button onClick={() => handleRejectObjectEditing()} type={"danger"} width={"100%"} hasShadow>
                      Отменить
                  </Button>
              </BaseMapPanelItem>
          </Styled.ExtendedPanelWrapper>
    );
  }
);

export default StandaloneEditModeFormPanel;
