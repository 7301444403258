import React, { Suspense, useEffect } from "react";
import Compendium from "../../../../../../library/compendiums/Common/Compendium/Compendium";
import { useTranslation } from "react-i18next";
import { useCertainFilterNamesCollectionForTable } from "../../../../../../customHooks/layerDataAccessor/dataTable/useCertainFilterNamesCollectionForTable";
import WindowedDataTable from "../../../../../../library/UI/components/WindowedDataTable/WindowedDataTable";
import WindowedDataTableHeader from "../../../../../../library/UI/components/WindowedDataTable/Header/WindowedDataTableHeader";
import CompendiumColumnActionPanelContainer from "../../../../../../library/compendiums/CompendiumButtonBars/Common/CompendiumColumnActionPanel/CompendiumColumnActionPanelContainer";
import { EditObjectDataAccessorButton } from "../../../../../../library/actionsButtons/NIPIGAZ/Map/EditObjectDataAccessorButton";
import { CloseDataAccessorButton } from "../../../../../../library/actionsButtons/common/map/CloseDataAccessorButton";

const LayerDataTable = ({
  data,
  resetFilter,
  model,
  setLayerDataAccessorVisibility,
  handleDateRangeChange,
  quickMenu,
  containerCallbacks,
  aggApi,
  selectedMapItem,
  handleSetActiveMapboxDataReplacingForcedStatus,
  dataAccessorClass,
  handleGoToEditMode,
  selectedMapLayer,
}) => {
  useEffect(() => {
    if (aggApi && selectedMapItem) {
      let id = null;
      //TODO need refactoring of condition in the LayerDataTable select-item effect
      if (
        selectedMapItem.data?.properties?.object_type === "monitoringObject"
      ) {
        id = selectedMapItem.data?.properties?.vehicle_id;
      } else {
        id = selectedMapItem.data?.properties?.id;
      }

      const savedSort = aggApi.api.getSortModel();
      //refresh
      aggApi.api.refreshCells();
      //set sort settings again
      aggApi.api.setSortModel(savedSort);
      //set selected row and autoscroll before this row
      aggApi.api.forEachNode(function (node) {
        node.setSelected(node.data.id === id);
      });
      aggApi.api.ensureNodeVisible((node) => node.data.id === id);
    }
  });
  const [namesForFilteringCollection] = useCertainFilterNamesCollectionForTable(
    dataAccessorClass
  );

  const { t } = useTranslation("dataAccessors");
  const i18path = dataAccessorClass?.getDataAccessorI18nLabelPath();
  const CustomButtons = dataAccessorClass?.getActionButtons()?.map((Button) => (
    <Suspense fallback={<p>s</p>}>
      <Button resetFilter={resetFilter} />
    </Suspense>
  ));

  return (
    <WindowedDataTable
      headerContent={
        <WindowedDataTableHeader
          titleContent={t(`titles.${i18path}`)}
          actionsContent={
            <>
              <CompendiumColumnActionPanelContainer
                quickMenuState={quickMenu}
                toggleShowFilterMenu={containerCallbacks.toggleShowFilterMenu}
                toggleShowQuickFilter={containerCallbacks.toggleShowQuickFilter}
                toggleShowRowsMenu={containerCallbacks.toggleShowRowsMenu}
                iconSizeType={"small"}
              />
              {CustomButtons}
              <EditObjectDataAccessorButton />
              <CloseDataAccessorButton />
            </>
          }
        >
        </WindowedDataTableHeader>
      }
    >
      <Compendium
        aggApi={aggApi}
        data={data}
        model={model}
        showQuickFilter={quickMenu.search}
        containerCallbacks={containerCallbacks}
        gridCellSizes={{ minWidth: 70, width: 150, maxWidth: 350 }}
        agGridThemeName={"ag-theme-custom-base ag-grid-theme-custom-map"}
        namesCollectionForFiltering={namesForFilteringCollection}
        rerenderCompendiumIfDataChanged={false}
        withoutGridSidebarAdditionalFunctional
      />
    </WindowedDataTable>
  );
};

export default LayerDataTable;
