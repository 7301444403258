import {put, takeEvery} from "redux-saga/effects";
import {setDisableMapboxDataReplacingForcedStatus} from "../../reducers/map/actions/mapboxDataActions";

const INIT_MAP_TABLE_APPLY_FILTER_SAGA = "INIT_MAP_TABLE_APPLY_FILTER_SAGA";
export const initMapTableApplyFilterSaga = filteredTableNodes => ({
  type: INIT_MAP_TABLE_APPLY_FILTER_SAGA,
  payload: {
    filteredTableNodes
  }
});

function* tempNameSaga(action) {
  yield put({
    type: "SAGA_STARTED"
  });
  //saga content

  const items = action.payload.filteredTableNodes;
  yield put(setDisableMapboxDataReplacingForcedStatus());

  //
  yield put({
    type: "SAGA_ENDED"
  });
}

export function* watchMapTableApplyFilterSaga() {
  yield takeEvery(INIT_MAP_TABLE_APPLY_FILTER_SAGA, tempNameSaga);
}
