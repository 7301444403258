import {parseNumber} from "../data/parseNumber";

export function makeUrlWithoutIdAtTheEnd(url) {
    const urlItems = url.split("/").filter(el => el !== "");
    const isLastUrlItemNumber =
        typeof parseNumber(urlItems[urlItems.length - 1]) === "number";
    if (isLastUrlItemNumber) {
        urlItems.pop();
    }
    return `/${urlItems.join("/")}/`;
}