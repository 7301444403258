import React, { useContext } from "react";
import { Styled } from "./TimePlayer.styled";
import TimePlayerContent from "./TimePlayerContent/TimePlayerContent";
import TimePlayerHeader from "./TimePlayerHeader/TimePlayerHeader";
import { MapModuleContext } from "../../moduleContext/withMapModuleContext";

const TimePlayer = (
  {
    mapboxTime,
    handleSliderDateSelect,
    handleSliderDateAfterChange,
    startPlayerHandler,
    stopPlayerHandler,
      handleClosePlayer,
      ...other
  }
) => {

  const { iconSizesKit } = useContext(MapModuleContext);
  const { playerRunning: isMapboxPlayerRunning } = mapboxTime;

  return (
    <Styled.TimePlayerMainWrapper className={other.className ? other.className : ''}>
      <TimePlayerHeader
          mapboxTime={mapboxTime}
          handleClosePlayer={handleClosePlayer}
      />
      <TimePlayerContent
        mapboxTime={mapboxTime}
        stopPlayerHandler={stopPlayerHandler}
        isMapboxPlayerRunning={isMapboxPlayerRunning}
        handleSliderDateSelect={handleSliderDateSelect}
        startPlayerHandler={startPlayerHandler}
        iconSize={iconSizesKit.default}
        handleSliderDateAfterChange={handleSliderDateAfterChange}
      />
    </Styled.TimePlayerMainWrapper>
  );
};

TimePlayer.propTypes = {};

export default TimePlayer;
