import React from "react";
import PropTypes from "prop-types";
import { Styled } from "../../LayerTemplatesTab.styled";
import { useTranslation } from "react-i18next";
import { withTheme } from "styled-components";
import TemplateItemCard from "../card/TemplateItemCard";
import SearchPanel from "../../../../../../../../../../../../../library/UI/components/SearchPanel/SearchPanel";

const {
  LocalMainWrapper,
  LocalHeaderWrapper,
  LocalInnerWrapper,
  LocalScrollArea,
  LocalSearchPanel,
} = Styled;

const TemplateItemsGroup = ({ title = "", enableSearch }) => {
  // const {} = useTranslation("layerCards");
  return (
    <LocalMainWrapper>
      <LocalHeaderWrapper>{title}</LocalHeaderWrapper>
      {enableSearch && (
        <LocalSearchPanel>
          <SearchPanel />
        </LocalSearchPanel>
      )}
      <LocalScrollArea>
        <LocalInnerWrapper>
          <TemplateItemCard />
        </LocalInnerWrapper>
      </LocalScrollArea>
    </LocalMainWrapper>
  );
};

export default withTheme(TemplateItemsGroup);

TemplateItemsGroup.propTypes = {
  enableSearch: PropTypes.bool,
  title: PropTypes.string,
};

TemplateItemsGroup.defaultProps = {
  title: "",
};
