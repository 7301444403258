import React from "react";
import LayerSettings from "./LayerSettings";
import {connect} from "react-redux";
import {theme} from "../../../../../../../../../library/themes/rootTheme";
import {
    removeLayer,
    revertToDefaultLayerSettings
} from "../../../../../../../../../reducers/map/actions/mapboxLayersActions";
import {closeSocket} from "../../../../../../../../../sagas/map/socketDataGetterSaga";
import {
    closeAllAdditionalPanels,
    toggleLayerSettingsVisibility
} from "../../../../../../../../../reducers/map/actions/mapPanelsActions";
import {getSelectedMapboxLayer} from "../../../../../../../../../reducers/map/mapboxLayers";
import { deleteObject } from "../../../../../../../../../sagas/directory/deleteObjectSaga";
import {reloadLastInlineData} from "../../../../../../../../../sagas/map/editableLayersHistorySaga";
import _ from "underscore";
import {getRegisteredLayer} from "../../../../../../../../../registrators/map/layers/layersRegistrator";


const LayerSettingsContainer = (
    {selectedLayer,
        toggleLayerSettingsVisibility,
        removeLayer,
        closeSocket,
        historyEditableLayers,
        selectRowForLayerHistory,
        closeAllAdditionalPanels,
        revertToDefaultLayerSettings
    }) => {
    const handleRemoveLayer = layer => {
        removeLayer(layer);

        //initiatorType, initiatorName
        const LayerProto = getRegisteredLayer(layer.name)
        const dataGetterType = new LayerProto().getDataAccessMethod()

        if (dataGetterType === 'ws'){
            closeSocket('mapLayer',layer.name);
        }

    };
    const lastHistoryRecord = _.last(historyEditableLayers)
    const handleDeleteObject = () => {
        const redrawDispatch = reloadLastInlineData(lastHistoryRecord)
        historyEditableLayers(null);
        deleteObject({url: lastHistoryRecord.urlData, id: lastHistoryRecord.selectedInstance.id, redrawDispatch: redrawDispatch})
        selectRowForLayerHistory(null);
    }


    //should be one element
    // const filteredLayer = enabledLayers.filter(item => item.isSelected === true);
    return (
        <>
            <LayerSettings theme={theme}
                           item={selectedLayer[0]}
                           handleRemoveLayer={handleRemoveLayer}
                           toggleLayerSettingsVisibility={toggleLayerSettingsVisibility}
                           handleDeleteObject={handleDeleteObject}
                           revertToDefaultLayerSettings={revertToDefaultLayerSettings}
            />
        </>
    );
};

const mapStateToProps = state => {
    return {
        selectedLayer:  getSelectedMapboxLayer(state),
        historyEditableLayers: state.mapboxEditableLayersHistory.history,



    };
};

const mapDispatchToProps = dispatch => {
    return {
        removeLayer: layer => dispatch(removeLayer(layer)),
        closeSocket: () => dispatch(closeSocket()),
        closeAllAdditionalPanels: () => dispatch(closeAllAdditionalPanels()),
        toggleLayerSettingsVisibility: layer =>
            dispatch(toggleLayerSettingsVisibility(layer)),
        revertToDefaultLayerSettings: keyOfLayer => dispatch(revertToDefaultLayerSettings(keyOfLayer))
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(LayerSettingsContainer);
