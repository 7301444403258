import React from 'react';
import {Styled} from "./VisibleUnvisibleFilterPanel.styled";
import {VisibilityOffIcon} from "../../../../../../library/icons/actionIcons/VisibilityOffIcon";
import {VisibilityIcon} from "../../../../../../library/icons/actionIcons/VisibilityIcon";
import {Button} from "../../../../../../library/UI/components/Button/Button";
import _ from "underscore";
import {iconSizesKit} from "../../../../../../library/UI/utils/iconSizesKit";
import {RoadWorksIcon} from "../../../../../../library/icons/contextMenuIcons/RoadWorksIcon";
import {OverpassIcon} from "../../../../../../library/icons/contextMenuIcons/Overpass";
import {BlockadesIcon} from "../../../../../../library/icons/contextMenuIcons/BlockadesIcon";
import {ControlRoomIcon} from "../../../../../../library/icons/contextMenuIcons/ControlRoom";
import {RoadSignsIcon} from "../../../../../../library/icons/contextMenuIcons/RoadSignsIcon";
import {DockIcon} from "../../../../../../library/icons/contextMenuIcons/Dock";
import {CheckpointIcon} from "../../../../../../library/icons/contextMenuIcons/CheckpointIcon";
import {DataConcerningIcon} from "../../../../../../library/icons/contextMenuIcons/DataConcerningIcon";


const VisibleUnvisibleFilterPanel = ({selectedLayer, recalculate, toggleLayerVisibility}) => {
    //масив с иконками SVG
    let pois = [
        {
            name: 'dock_segment',
            svg: <DockIcon/>
        },
        {
            name: 'security_checkpoint',
            svg: <CheckpointIcon/>
        },
        {
            name: 'road_blocks',
            svg: <BlockadesIcon/>
        },
        {
            name: 'road_overpass',
            svg: <OverpassIcon/>
        },
        {
            name: 'road_works',
            svg: <RoadWorksIcon/>
        },
        {
            name: 'control_room',
            svg: <ControlRoomIcon/>
        },
        {
            name: 'cargoCityPoints',
            svg: <RoadWorksIcon/>
        },
        {
            name: 'cargoTransferParts',
            svg: <RoadWorksIcon/>
        },
        {
            name: 'discharge_zones',
            svg: <RoadWorksIcon/>
        },

        {
            name: 'railways',
            svg: <RoadWorksIcon/>
        },
        {
            name: 'reception_areas',
            svg: <RoadWorksIcon/>
        },
        {
            name: 'roads',
            svg: <RoadWorksIcon/>
        },
        {
            name: 'roadsCategories',
            svg: <RoadWorksIcon/>
        },

        {
            name: 'segments',
            svg: <DataConcerningIcon/>
        },
        {
            name: 'road_sign',
            svg: <RoadSignsIcon/>
        },
        {
            name: 'trafficSegments',
            svg: <RoadWorksIcon/>
        },
        {
            name: 'trains',
            svg: <RoadWorksIcon/>
        },
        {
            name: 'vehicles',
            svg: <RoadWorksIcon/>
        },
        {
            name: 'waggons',
            svg: <RoadWorksIcon/>
        },
    ]
//подбираем иконку к сущности
    let iconCreator = (poiName) => {
        let result = pois.filter(f => poiName == f.name)
        return result.length !== 0 ? result[0].svg : null
    }

    const handleChange = (e, RelatedData,) => {
        //Заглушка для слоя мониторинг и скрытие всего слоя(убрать условие)
        selectedLayer.name == 'monitoring'
            ? toggleLayerVisibility(selectedLayer)
            : RelatedData.toggleVisible(!RelatedData.isHidden);
        recalculate();
    };
    const layerRelatedData = selectedLayer.getRelatedData();
    const filterCheckBox = layerRelatedData.map((RelatedData) => (


        <Styled.MainItem>
            <Styled.SubIcon>
                {iconCreator(RelatedData.name)}
            </Styled.SubIcon>

            <Styled.SubItem>
                {RelatedData.getFilterLabel()}
            </Styled.SubItem>
            <Styled.Buttom>
                <Button
                    type={RelatedData.isHidden ? "unbounded" : "primaryReverseLB"}
                    icon={RelatedData.isHidden ? <VisibilityOffIcon fill={'#000'} key={_.uniqueId()}/> :
                        <VisibilityIcon key={_.uniqueId()}/>}
                    p={0.5}
                    key={_.uniqueId()}
                    iconSize={iconSizesKit.big}
                    onClick={(e) => {
                        handleChange(e, RelatedData)
                    }}
                >
                </Button>
            </Styled.Buttom>
        </Styled.MainItem>

    ));

    return <Styled.MainWraper>
        <Styled.MainHeader>Скрыть / Показать объекты</Styled.MainHeader>

        { //Убраны неактивные елементы(убрать условие)
            selectedLayer.name == 'roadsSchema'
            ? filterCheckBox.slice(1, filterCheckBox.length - 1)
            : selectedLayer.name == 'geozones'
                ? filterCheckBox.slice(0, filterCheckBox.length - 1)
                : selectedLayer.name == 'railwayNetwork'
                    ? filterCheckBox.slice(0, 1).concat(filterCheckBox.slice(3, 5))
                    :filterCheckBox}

    </Styled.MainWraper>


};

VisibleUnvisibleFilterPanel.propTypes = {};

export default VisibleUnvisibleFilterPanel;
