import React from "react";
import SidebarHeader from "./SidebarHeader/SidebarHeader";
import { ThemeProvider } from "styled-components";
import SidebarCategory from "./SidebarCategory/SidebarCategory";

import { registeredUrls } from "../../registrators/old/directoryRegistrator/directoryRegistrator";
import { Styled } from "./SidebarContainer.styled";

const SidebarContainer = ({
  className,
  sidebarIsOpened,
  toggleSidebarPosition,
}) => {
  const confirmedAccess = registeredUrls.map((el) => el);
  const expandedTheme = (theme) => ({
    ...theme,
    sidebarElemPaddingLeft: "15%",
  });
  return (
    <ThemeProvider theme={expandedTheme}>
      <Styled.Wrapper as={"aside"} className={`sidebar-wrapper ${className}`}>
        <SidebarHeader
          sidebarIsOpened={sidebarIsOpened}
          toggleSidebarPosition={toggleSidebarPosition}
        />
        <SidebarCategory
          confirmedAccess={confirmedAccess}
          sidebarIsOpened={sidebarIsOpened}
        />
      </Styled.Wrapper>
    </ThemeProvider>
  );
};

export default SidebarContainer;
