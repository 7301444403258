import React from "react";
import { TransferCardItem } from "../Item/TransferCardItem.jsx";
import PropTypes from "prop-types";

const TransferCardList = ({
  itemsData,
  styleTheme,
  groupItemsStatus,
  onSelectChange,
  loading,
  loaderRender,
  emptyDataRender,
}) => {
  let renderContent = null;
  if (loading) {
    renderContent = loaderRender;
  } else if (itemsData.length === 0) {
    renderContent = emptyDataRender;
  } else {
    renderContent = itemsData.map((item) => (
      <TransferCardItem
        styleTheme={styleTheme}
        itemStatus={groupItemsStatus}
        isSelected={item.isSelected}
        onSelectChange={() => onSelectChange(item.id)}
        id={item.id}
        key={item.id}
      >
        {item.description}
      </TransferCardItem>
    ));
  }

  return <>{renderContent}</>;
};

export default TransferCardList;

TransferCardList.propTypes = {
  groupItemsStatus: PropTypes.oneOf(["danger", "middle", "successful"]),
  itemsData: PropTypes.shape({
    id: PropTypes.number,
    description: PropTypes.string,
    isSelected: PropTypes.bool,
    initialData: PropTypes.object,
    itemStatus: PropTypes.string,
  }),
  loaderRender: PropTypes.node,
  loading: PropTypes.bool,
  onItemSelectChange: PropTypes.func,
  styleTheme: PropTypes.object,
};

TransferCardList.defaultProps = {
  loaderRender: "...loading",
  emptyDataRender: "нет данных",
  styleTheme: {},
};
