import {
  ADD_MAPBOX_DATA, ADD_MAPBOX_MULTIPLE_RELATED_DATA,
  ADD_MAPBOX_RELATED_DATA,
  PUSH_TO_MAPBOX_DATA,
  REPLACE_ALL_MAPBOX_RELATED_DATA,
  REPLACE_ALL_MAPBOX_SIMPLY_DATA,
  REPLACE_CERTAIN_FIELD_IN_RELATED_DATA,
  RESET_MAPBOX_STORE,
  SET_ACTIVE_DATA_REPLACING_FORCED_STATUS,
  SET_DISABLE_DATA_REPLACING_FORCED_STATUS,
} from "../mapboxData";
import securedAxiosInstance from "../../../services/net/axiosSecured";
import moment from "moment";
import { openSocket } from "../../../sagas/map/socketDataGetterSaga";
import _ from "underscore";
import { cropPath } from "../../../services/net/cropPath";
import { getFromURL } from "../../../api/getFromURL";
import { dataAccessMethodsEnum } from "../../../enums/dataAccessMethodsEnum";
import { getRegisteredLayer } from "../../../registrators/map/layers/layersRegistrator";

export const addMapboxData = (key, value) => ({
  type: ADD_MAPBOX_DATA,
  payload: {
    key,
    value,
  },
});

export const cleanUpData = (key) => ({
  type: ADD_MAPBOX_DATA,
  payload: {
    key,
    value: [],
  },
});
export const pushToMapboxData = (key, value) => ({
  type: PUSH_TO_MAPBOX_DATA,
  payload: {
    key,
    value,
  },
});
export const resetMapboxStore = () => ({
  type: RESET_MAPBOX_STORE,
});

export const addMapboxRelatedData = (parentKey, childKey, data) => ({
  type: ADD_MAPBOX_RELATED_DATA,
  payload: {
    parentKey,
    childKey,
    data,
  },
});

export const addMapboxMultipleRelatedData = (arrayOfRelatedData) => ({
  type: ADD_MAPBOX_MULTIPLE_RELATED_DATA,
  payload: {
    arrayOfRelatedData
  },
});

export const replaceAllRelatedMapboxData = (layerName, newDataArray) => ({
  type: REPLACE_ALL_MAPBOX_RELATED_DATA,
  payload: {
    layerName,
    newDataArray,
  },
});

export const setActiveMapboxDataReplacingForcedStatus = () => ({
  type: SET_ACTIVE_DATA_REPLACING_FORCED_STATUS,
});

export const setDisableMapboxDataReplacingForcedStatus = () => ({
  type: SET_DISABLE_DATA_REPLACING_FORCED_STATUS,
});

export const replaceAllSimplyMapboxData = (layerName, newDataArray) => ({
  type: REPLACE_ALL_MAPBOX_SIMPLY_DATA,
  payload: {
    layerName,
    newDataArray,
  },
});

//thunks section
export const getDataForLayer = (layerName, filteredIds, filteredTime) => (
  dispatch
) => {
  const Layer = getRegisteredLayer(layerName);
  const mainData = Layer.getMainData();
  if (mainData) {
    if (mainData.dataAccessMethod === dataAccessMethodsEnum.httpGet) {
      securedAxiosInstance.get(mainData.urlData).then((response) => {
        return dispatch(addMapboxData(layerName, response?.data));
      });
    } else if (mainData.dataAccessMethod === dataAccessMethodsEnum.httpPost) {
      securedAxiosInstance
        .post(mainData.urlData, {
          vehicle_id: [22],
          datetime_from: moment(
            "10.05.2020 10:00Z",
            "DD.MM.YYYY hh:mm"
          ).format(),
          datetime_to: moment("10.05.2020 11:00Z", "DD.MM.YYYY hh:mm").format(),
        })
        .then((response) => {
          if (response) {
            const indexed = _.groupBy(response?.data?.results, "vehicle_id");
            const result = [];
            for (let keys in indexed) {
              result.push(indexed[keys]);
            }
            return dispatch(addMapboxData(layerName, result));
          }
        });
    } else if (mainData.dataAccessMethod === dataAccessMethodsEnum.httpGetAll) {
      securedAxiosInstance.get(mainData.urlData).then((response) => {
        if (response) {
          const indexed = _.groupBy(response?.data?.results, "vehicle_id");
          const result = [];
          for (let keys in indexed) {
            result.push(indexed[keys]);
          }
          return dispatch(addMapboxData(layerName, result));
        }
      });
    } else if (mainData.dataAccessMethod === dataAccessMethodsEnum.websocket) {
      const keyForSubscribe = mainData.dataKeyForIds;
      return dispatch(
        openSocket(
          mainData.urlData,
          layerName,
          keyForSubscribe,
          filteredIds,
          dispatch(addMapboxData)
        )
      );
    }
  }
  return { type: "EMPTY" };
};

export const reloadMapboxLayerData = (urlData, layerName) => (dispatch) => {
  setTimeout(async() => {
    if (layerName) {

      const relatedDescriptions = getRegisteredLayer(
        layerName
      ).getRelatedData();

      const relatedRecord = _.isArray(relatedDescriptions)
        ? relatedDescriptions.find(
            (el) => cropPath(el.urlData) === cropPath(urlData)
          )
        : null;

      const { count } = await getFromURL.getDataFromURL(cropPath(urlData));
      const response = await getFromURL.getDataFromURL(cropPath(`${urlData}?page_size=${count}`));

      dispatch({
        type: REPLACE_CERTAIN_FIELD_IN_RELATED_DATA,
        payload: {
          layerName,
          relatedKey: relatedRecord,
          data: response.results,
        },
      });
    }
  }, 1000);
};
