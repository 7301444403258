import _, { findIndex } from "underscore";

export const REGISTER_SOCKET_STATUS = "REGISTER_SOCKET_STATUS";
export const CHECK_SOCKET_STATUS = "CHECK_SOCKET_STATUS";
export const REFRESH_SOCKET_STATUS = "REFRESH_SOCKET_STATUS";
export const CLOSE_SOCKET_STATUS = "CLOSE_SOCKET_STATUS";
export const CLEAN_UP_SOCKETS_STATUSES = "CLEAN_UP_SOCKETS_STATUSES";

const initialState = {};

export const closeSocketConnection = (initiatorType, initiatorName) => ({
  type: CLOSE_SOCKET_STATUS,
  payload: {
    initiatorType,
    initiatorName,
  },
});

const socketsStatus = (state = initialState, action) => {
  switch (action.type) {
    case REGISTER_SOCKET_STATUS: {
      const { initiatorType, initiatorName, socketInstance } = action.payload;
      const checkIfAlreadyCreated = state?.[initiatorType]?.[initiatorName];
      if (checkIfAlreadyCreated) checkIfAlreadyCreated.close();
      return {
        ...state,
        [initiatorType]: {
          ...state[initiatorType],
          [initiatorName]: socketInstance,
        },
      };
    }
    case CHECK_SOCKET_STATUS: {
      const { initiatorType, initiatorName } = action.payload;
      const checkIfAlreadyCreated = state?.[initiatorType]?.[initiatorName];
      if (checkIfAlreadyCreated) {
        checkIfAlreadyCreated.close();
        const stateCopy = { ...state };
        delete stateCopy[initiatorType][initiatorName];
        return { ...stateCopy };
      }
      return state;
    }
    case CLOSE_SOCKET_STATUS: {
      const { initiatorType, initiatorName } = action.payload;
      const socketInstance = state[initiatorType][initiatorName];
      socketInstance.close();
      const stateCopy = { ...state };
      delete stateCopy[initiatorType][initiatorName];
      return { ...stateCopy };
    }
    case CLEAN_UP_SOCKETS_STATUSES: {
      return initialState;
    }
    default:
      return state;
  }
};

export default socketsStatus;
