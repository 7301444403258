import {LayerCardPrototype} from "../prototype/LayerCardPrototype";

export class TrackingLayerCard extends LayerCardPrototype {

    constructor() {
        super({
                i18path: 'tracking',
                linkToPreview: 'Treking.svg'
            }
        );
    }
}
