import { Select } from "antd";
import { Form } from "antd";
import { useFormContext, Controller, ErrorMessage } from "react-hook-form";
import React, { useEffect, useRef, useState } from "react";
import { getFromURL } from "../../api/getFromURL";
import { Styled } from "./styled/BaseInput.styled";
import { useBaseInputPlaceholderControl } from "./utils/hooks/useBaseInputPlaceholderControl";
import { checkConditionAndCallFn } from "../../services/common/checkConditionAndCallFn";
import { useBaseInputSettings } from "./utils/hooks/useBaseInputSettings";

const { Option } = Select;
const {
    BaseErrorMessage,
    BaseSelectInput,
    BaseSelectPlaceholder,
    BaseSelectInputWrap,
} = Styled;

const CustomRelatedFieldInput = (props) => {
    const inputWrapRef = useRef(null);
    const { control, errors, setValue } = useFormContext();
    const { description, object, field, mode } = props;
    const [optionsToChoose, setOptionsToChoose] = useState(null);
    const { required, message } = useBaseInputSettings(description);

    console.log(field)
    console.log(object)

    const {
        placeholderState,
        setPlaceholderState,
    } = useBaseInputPlaceholderControl(field, object, setValue);

    useEffect(() => {
        if (description?.url) {
            const getData = async () => {
                const pageResponse = await getFromURL.getDataFromURL(
                    description.url.replace("/api", "")
                );
                const { count } = pageResponse;
                const result = await getFromURL.getAll(
                    description.url.replace("/api", ""),
                    count
                );
                if (Array.isArray(result?.results) && result?.results.length) {
                  const options = result.results.map((el) => (
                    <Option value={el.id}>
                        {el.number} {el.name} {el.value}
                    </Option>
                  ));
                  setOptionsToChoose(options);
                } else setOptionsToChoose(null);
            };
            getData();
        }
    }, []);

    return (
        <Form.Item>
            <BaseSelectInputWrap ref={inputWrapRef}>
                <Controller
                    dropdownClassName={"custom-dropdown"}
                    allowClear={true}
                    name={field}
                    as={<BaseSelectInput>{optionsToChoose}</BaseSelectInput>}
                    control={control}
                    disabled={!!description.read_only}
                    mode={mode || null}
                    showSearch
                    optionFilterProp="children"
                    rules={{ required: { value: required, message: message } }}
                    getPopupContainer={() => inputWrapRef.current}
                    onFocus={() => {
                        setPlaceholderState(true);
                    }}
                    onBlur={(e) => {
                        checkConditionAndCallFn(!e[0], () => setPlaceholderState(false));
                    }}
                />
                <ErrorMessage as={BaseErrorMessage} errors={errors} name={field} />
                <BaseSelectPlaceholder
                    className={`${placeholderState ? "opened" : ""}`}
                >
                    {description.label}
                </BaseSelectPlaceholder>
            </BaseSelectInputWrap>
        </Form.Item>
    );
};

export default CustomRelatedFieldInput;
