import { call, put, select, takeEvery } from "redux-saga/effects";
import {
  clearMapSelectedObject,
  onMapSelectedObjectUpdate,
  onMapSelectItem,
} from "../../reducers/map/actions/mapSelectedObjectActions";
import { toggleMapSelectedObjectInfoVisibility } from "../../reducers/map/actions/mapPanelsActions";
import { resolveSelectedObjectDescription } from "../../registrators/old/mapSelectObjectRegistrator/mapSelectObjectRegistrator";
import { getFromURL } from "../../api/getFromURL";
import { dataAccessMethodsEnum } from "../../enums/dataAccessMethodsEnum";
import { alertTypesEnum } from "../../enums/alertTypesEnum";
import { setGlobalAlertData } from "../../reducers/app/actions/notificationActions";
import { alertStatusesEnum } from "../../enums/alertStatusesEnum";

const INIT_SET_MAP_SELECTED_OBJECT_SAGA = "INIT_MAP_SELECTED_OBJECT_SAGA";
const INIT_UPDATE_MAP_SELECTED_OBJECT_SAGA =
  "INIT_UPDATE_MAP_SELECTED_OBJECT_SAGA";

export const initSetMapSelectedObjectSaga = (data, type, mapMode) => ({
  type: INIT_SET_MAP_SELECTED_OBJECT_SAGA,
  payload: {
    data,
    type,
    mapMode,
  },
});

export const initUpdateMapSelectedObjectSaga = (data, type) => ({
  type: INIT_UPDATE_MAP_SELECTED_OBJECT_SAGA,
  payload: {
    data,
    type,
  },
});

const defaultCoords = {
  latitude: 51.5,
  longitude: 128.5,
};

// const getClusterData = (data) =>  !!data?.object?.properties?.cluster

const localMakeURL = (currentItemMode = {}, itemEventData) => {
  const { urlData = "", idKey = "" } = currentItemMode;
  const hasItemEventDataProperties = !!itemEventData?.object?.properties;
  if (hasItemEventDataProperties) {
    return urlData + itemEventData?.object?.properties[idKey];
  }
  return urlData + itemEventData?.object[idKey];
};

function* setMapSelectedObjectSaga(action) {
  //always need clear selected object
  yield put(clearMapSelectedObject());
  yield put(toggleMapSelectedObjectInfoVisibility(false));
  const { data, type, mapMode = "display" } = action.payload;
  let resolvedSelectedModes = resolveSelectedObjectDescription(type, "modes");
  try { //на слое жд в гриде по клику на элемент валится ошибка и перестает работать переключение слоев. try проблему решил, но надо разобраться потом, почему на этих объектах крашится
  for (let index in resolvedSelectedModes) {
    const mode = resolvedSelectedModes[index];
    switch (mode.accessMethod) {
      case dataAccessMethodsEnum.httpGet: {
        const responseData = yield call(() =>
          getFromURL.getDataFromURL(localMakeURL(mode, data))
        );
        const model = yield call(() =>
          getFromURL.getModelFromURL(localMakeURL(mode, data))
        );
        mode.data = responseData;
        mode.model = model;
        mode.invoked = data;
        mode.child = mode?.childNext;
        break;
      }
      case dataAccessMethodsEnum.httpGetWithParams: {
        let paramsObject = {};
        for (let param of mode.params) {
          if (param.value === "value") {
            paramsObject[param.name] = data;
          } else {
            paramsObject[param.name] = data[param.value];
          }
        }
        const responseData = yield call(() =>
          getFromURL.getWithParams(mode.urlData, paramsObject)
        );
        const model = yield call(() =>
          getFromURL.getModelFromURL(mode.urlData)
        );
        mode.data = responseData.results;
        mode.model = model;
        mode.invoked = data;
        mode.child = mode?.childNext;
        break;
      }
      case dataAccessMethodsEnum.httpGetById: {
        const responseData = yield call(() =>
          getFromURL.getById(mode.urlData, data[mode.idKey])
        );
        const model = yield call(() =>
          getFromURL.getModelFromURL(mode.urlData)
        );
        mode.data = responseData;
        mode.model = model;
        mode.invoked = data;
        mode.child = mode?.childNext;
        break;
      }
      case dataAccessMethodsEnum.httpGetWithFilters: {
        const requestFilters = [
          {
            field: "id",
            operator: "all",
            data: data.map((item) => item[mode.idKey]),
          },
        ];
        const responseData = yield call(() =>
          getFromURL.getDataFromURLWithFilters(mode.urlData, requestFilters)
        );
        const model = yield call(() =>
          getFromURL.getModelFromURL(mode.urlData)
        );
        mode.data = responseData;
        mode.model = model;
        mode.invoked = data;
        mode.child = mode?.childNext;
        break;
      }
    }
  }}
  catch (e) {
  console.log(e)}
  if (action.type === "INIT_MAP_SELECTED_OBJECT_SAGA") {

    switch (action.payload.mapMode) {
      case "display": {
        yield put(toggleMapSelectedObjectInfoVisibility(true));
        yield put(onMapSelectItem(resolvedSelectedModes, type, data));
        break;
      }
      case !"display": {
        yield put(toggleMapSelectedObjectInfoVisibility(true));//при этом включенном работает фильтр
        break;
      }
      case undefined: { //кастомный костыль, чтобы в по клику на тмц в гриде выводилось окно "данные об объекте"
        if (action.payload.type === "singleCargo") {
        yield put(toggleMapSelectedObjectInfoVisibility(true));
        yield put(onMapSelectItem(resolvedSelectedModes, type, data));
          break;
        }
        break;
      }
    }
  }
}

function* updateMapSelectedObjectSaga(action) {
  //this place is not completed
  const state = yield select((state) => state.mapSelectedObject);
  const { data } = action.payload;
  if (data && state) {
    try {
      const longitude = data?.point?.longitude || defaultCoords.longitude;
      const latitude = data?.point?.latitude || defaultCoords.latitude;
      const preparedData = {
        geometry: {
          coordinates: [longitude, latitude],
        },
        properties: {
          speed: data.speed,
          azimuth: data.azimuth,
          timeReceived: data.timeReceived,
          vehicle_id: data.vehicle_id,
        },
      };
      if (
        preparedData?.properties?.vehicle_id &&
        preparedData?.properties.vehicle_id ===
          state?.data?.properties?.vehicle_id
      ) {
        //TODO need realization for zomming on the updated object

        // yield put(
        //   goToMapCoords(
        //     preparedData?.geometry?.coordinates[1],
        //     preparedData?.geometry?.coordinates[0]
        //   )
        // );
        yield put(onMapSelectedObjectUpdate(preparedData));
      }
    } catch (e) {
      yield put(
        setGlobalAlertData({
          status: alertStatusesEnum,
          type: alertTypesEnum.error,
          title: "Ошибка",
          message: "Что-то пошло не так",
        })
      );
    }
  }
}

export function* watchMapSelectedObjectSaga() {
  yield takeEvery(INIT_SET_MAP_SELECTED_OBJECT_SAGA, setMapSelectedObjectSaga);
  yield takeEvery(
    INIT_UPDATE_MAP_SELECTED_OBJECT_SAGA,
    updateMapSelectedObjectSaga
  );
}
