import "react-hot-loader";
import React from "react";
import ReactDOM from "react-dom";
import AppEntryPoint from "./components/EntryPoint/App";
import "antd/dist/antd.css";
import "./index.scss";
import { I18nextProvider } from "react-i18next";
import i18next from "i18next";
//ru import block
import common_ru from "./i18n/ru/common.json";
import layerCards_ru from "./i18n/ru/layerCards.json";
import dataAccessors_ru from "./i18n/ru/dataAccessors.json";
//en import block
import common_en from "./i18n/en/common.json";
import layerCards_en from "./i18n/en/layerCards.json";
import dataAccessors_en from "./i18n/en/dataAccessors.json";

let locale = navigator.language;
/**
 * Подключение json интернационализации
 */
i18next.init({
  interpolation: { escapeValue: false }, // React already does escaping
  lng: "ru-RU",
  // lng: locale,  // Базовый язык определяется локалью браузера
  fallbackLng: 'ru-RU', //Язык используемый по умолчанию при отсутсвии пресета
  resources: {
    "en-EN": {
      common: common_en, // 'common' is our custom namespace 'common_en' is imported json file
      layerCards: layerCards_en,
      dataAccessors: dataAccessors_en,
    },
    "en_EN": {
      common: common_en, // 'common' is our custom namespace 'common_en' is imported json file
      layerCards: layerCards_en,
      dataAccessors: dataAccessors_en,
    },
    "ru-RU": {
      common: common_ru,
      layerCards: layerCards_ru,
      dataAccessors: dataAccessors_ru,
    },
    "ru_RU": {
      common: common_ru,
      layerCards: layerCards_ru,
      dataAccessors: dataAccessors_ru,
    },
  },
});

ReactDOM.render(
  <I18nextProvider i18n={i18next}>
    <AppEntryPoint />
  </I18nextProvider>,
  document.getElementById("root")
);
