export const signsMapperType2 = {
    "2.1": {
        "x": 1937,
        "y": 812,
        "width": 811,
        "height": 811
    },
    "2.2": {
        "x": 1937,
        "y": 0,
        "width": 811,
        "height": 811
    },
    "2.3.1": {
        "x": 0,
        "y": 562,
        "width": 634,
        "height": 561
    },
    "2.3.2": {
        "x": 0,
        "y": 1124,
        "width": 634,
        "height": 561
    },
    "2.3.3": {
        "x": 635,
        "y": 1686,
        "width": 634,
        "height": 561
    },
    "2.3.4": {
        "x": 0,
        "y": 0,
        "width": 634,
        "height": 561
    },
    "2.3.5": {
        "x": 0,
        "y": 1686,
        "width": 634,
        "height": 561
    },
    "2.3.6": {
        "x": 1270,
        "y": 1686,
        "width": 634,
        "height": 561
    },
    "2.3.7": {
        "x": 1937,
        "y": 1624,
        "width": 634,
        "height": 561
    },
    "2.4": {
        "x": 635,
        "y": 1124,
        "width": 634,
        "height": 561
    },
    "2.5": {
        "x": 635,
        "y": 0,
        "width": 700,
        "height": 700
    },
    "2.6": {
        "x": 1336,
        "y": 0,
        "width": 600,
        "height": 600
    },
    "2.7": {
        "x": 1336,
        "y": 601,
        "width": 600,
        "height": 600
    }
}
