import React from "react";
import PropTypes from "prop-types";
import { Styled } from "./LayerCard.styled";
import { PlusIcon } from "../../../../../../../../../../../../library/icons/actionIcons/PlusIcon";
import { useTranslation } from "react-i18next";
import { withTheme } from "styled-components";
import { LayerCardPrototype } from "../../../../../../../../../../../../registrators/map/layers/description/cards/prototype/LayerCardPrototype";
import { PanelDefaultCard } from "../../../../../../../../../../../../library/UI/components/PanelDefaultCard/PanelDefaultCard";
import Img from "react-image";

const {
  CardButton,
  CardText,
} = Styled;

const LayerCard = ({ addLayerWithSideEffects, card, theme }) => {
  const iconSize = { width: "14px", height: "14px" };
  const { t } = useTranslation("layerCards");
  const cardTitle = t(card?.getI18Path())
  //оттестировано на названиях не более чем в 3 слова
  const arr = cardTitle.split(' ')
  let first, second, third=''
  first = arr[0]
  second = arr[1] ? arr[1] : ' '
  third = arr[2] ? arr[2] : ' '
  if (cardTitle.length<12) {first=cardTitle; second=' '; third=' '}
  if (arr.length>1&&(first.length+second.length)<12) {first=arr[0] + ' ' + arr[1]; second='';}
  return (

    <PanelDefaultCard.Main>
      <PanelDefaultCard.Content>
        <PanelDefaultCard.PresentationArea >
            <Img src={card?.getLinkToPreview()}/>
        </PanelDefaultCard.PresentationArea>
        <PanelDefaultCard.DescriptionArea>
          <CardText>{first}</CardText>
          <CardText>{second}</CardText>
          <CardText>{third}</CardText>
        </PanelDefaultCard.DescriptionArea>
        <PanelDefaultCard.ActionArea>
          <CardButton
            iconColor={theme.colors.defaultLighter}
            padding={"none"}
            iconSize={iconSize}
            icon={<PlusIcon key={1} />}
            onClick={addLayerWithSideEffects}
          />
        </PanelDefaultCard.ActionArea>
      </PanelDefaultCard.Content>
    </PanelDefaultCard.Main>
  );
};

LayerCard.propTypes = {
  addLayerWithSideEffects: PropTypes.func,
  card: PropTypes.oneOf([LayerCardPrototype]).isRequired,
};

export default withTheme(LayerCard);
