import {RelatedDataPrototype} from "../prototype/RelatedDataPrototype";

export class Vehicle extends RelatedDataPrototype {

    constructor() {
        super({
            name: 'vehicles',
            filterLabel: 'Транспортное средство',
            urlData: '/layer_monitoring/vehicles/',
        });
    }
}
