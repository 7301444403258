import { registeredMenuContent } from "./contextMenuContentRegistrator";

export function resolver(mode, objectType, layer=null) {
  const modeKeys = Object.keys(registeredMenuContent);
  const currentMode = modeKeys.filter(el => el === mode);
  if (currentMode.length && objectType) {
    const typeKeys = Object.keys(registeredMenuContent[currentMode]);
    const currentType = typeKeys.filter(el => el === objectType);
    if (currentType) {
        const resolvedRecord = registeredMenuContent[currentMode][currentType];
        if (resolvedRecord && Object.keys(resolvedRecord)?.length && layer){
          return resolvedRecord[layer]
        } else if (resolvedRecord && Object.keys(resolvedRecord)?.length && !layer){
            return null
        }
        return resolvedRecord
    } else {
      return null;
    }
  } else {
    return null;
  }
}
