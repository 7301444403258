import {defaultRenderers, registeredUrls} from "./directoryRegistrator";
import {cropPath} from "../../../services/net/cropPath";

/***
 *  function resolves prototype from registered urls or return default prototype
 * @param url - searched url to resolve prototype
 * @param field - searched field to resolve prototype
 * @param urlField - switch if look up for path from not url, by default = 'url'
 * @param useDefaultIfMissing - return default prototype for field if not exists in registrators, by default = True
 * @param onlyExact
 * @returns {null|React.Element}  (not found prototype) | (found prototype)
 */

export const resolver = (url, field = null, urlField = 'url',useDefaultIfMissing = true,onlyExact = false) => {
    if (!url) throw new Error(`${url} is empty for field ${field}`)
    let result = registeredUrls.filter(el => url.includes(cropPath(el[urlField])));
    const exact = result.filter(el => el[urlField].toLowerCase() === url);
    if (exact && exact.length) {
        result = exact;
    } else if(onlyExact){
        return null
    }

    if (field && result && result[0] && result[0][field]) {
        return result[0][field]
    } else if (field ) {
        if (useDefaultIfMissing && defaultRenderers[field]){
            return defaultRenderers[field]
        } else return null
    } else if (!field) return result[0];
}
