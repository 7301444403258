import React from "react";
import { ResizableBox } from "react-resizable";
import { Styled } from "./WindowedDataTable.styled";
import PropTypes from "prop-types"

const { LocalMainWrapper, ExtendedMainInner } = Styled.main;

const WindowedDataTable = ({
  headerContent,
  children,
  resizableBoxSettings,
}) => {
  return (
    <LocalMainWrapper>
      <ExtendedMainInner>
        {headerContent}
        <ResizableBox
          width="100%"
          height={resizableBoxSettings.height}
          minConstraints={resizableBoxSettings.minConstraints}
          maxConstraints={resizableBoxSettings.maxConstraints}
          resizeHandles={resizableBoxSettings.resizeHandlers}
          axis={resizableBoxSettings.axis}
        >
          {children}
        </ResizableBox>
      </ExtendedMainInner>
    </LocalMainWrapper>
  );
};

export default WindowedDataTable;

WindowedDataTable.propTypes = {
  children: PropTypes.node,
  headerContent: PropTypes.node,
  resizableBoxSettings: PropTypes.shape({
    height: PropTypes.number,
    maxConstraints: PropTypes.arrayOf(PropTypes.number),
    minConstraints: PropTypes.arrayOf(PropTypes.number),
    resizeHandlers: PropTypes.arrayOf(PropTypes.string),
    width: PropTypes.number,
  }),
};

WindowedDataTable.defaultProps = {
  resizableBoxSettings: {
    width: 450,
    height: 320,
    minConstraints: [100, 100],
    maxConstraints: [1000, 1000],
    resizeHandlers: [],
    axis: "none"
  },
};
