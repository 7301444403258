import React from "react";
import { Route, withRouter, Switch } from "react-router-dom";
import { useWhyDidYouUpdate } from "react-recipes";
import ReportFolderContainer from "./ReportsFolder/ReportFolderContainer";
import LostSignalContainer from "./ReportsBuilder/Templates/LostSignal/LostSignalContainer";
import StartAndStopContainer from "./ReportsBuilder/Templates/StartAndStop/StartAndStopContainer";
import PassedTriggerzoneContainer from "./ReportsBuilder/Templates/Movement/MovementContainer";
import VehicleMobilization from "./ReportsBuilder/Templates/VehicleMobilization/VehicleMobilization";
import VehicleMobilizationContainer from "./ReportsBuilder/Templates/VehicleMobilization/VehicleMobilizationContainer";
import PassedGeozonesContainer from "./ReportsBuilder/Templates/PassedGeozones/PassedGeozonesContainer";

const ReportsRouter = (props) => {
  useWhyDidYouUpdate("ReportsRouter", props);

  return (
    <Switch>
      {/*<Route*/}
      {/*  exact*/}
      {/*  path={"/app/reports/mobilizationTS/"}*/}
      {/*  render={() => <LostSignalContainer />}*/}
      {/*/>*/}
      <Route
        exact
        path={"/app/reports/startAndStop/"}
        render={() => <StartAndStopContainer />}
      />
      <Route
        exact
        path={"/app/reports/signalLoss/"}
        render={() => <LostSignalContainer />}
      />
      <Route
        exact
        path={"/app/reports/movementReport/"}
        render={() => <PassedTriggerzoneContainer />}
      />
      <Route
        exact
        path={"/app/reports/vehicleMobilization/"}
        render={() => <VehicleMobilizationContainer />}
      />
      <Route
        exact
        path={"/app/reports/passedGeozones/"}
        render={() => <PassedGeozonesContainer />}
      />
      <Route exact path={"/app/reports/"} component={ReportFolderContainer} />
    </Switch>
  );
};

export default withRouter(ReportsRouter);
