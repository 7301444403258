import React from 'react';
import {Styled} from "../Panel.styled"
import PropTypes from "prop-types"

const {LocalHeaderWrap} = Styled

const PanelHeader = ({children, ...other}) => {
    return (
        <LocalHeaderWrap {...other}>
            {children}
        </LocalHeaderWrap>
    );
};

export default PanelHeader;

PanelHeader.propTypes = {
  children: PropTypes.any,
  hasBottomShadow: PropTypes.bool
}

PanelHeader.defaultProps = {
  hasBottomShadow: true
}