import {useEffect} from "react";

export const useAgGridSidebarWithoutAdditionalFunctional = (aggApi, doIt) => {
    useEffect(() => {
        if (aggApi && doIt) {
            const columnToolPanel = aggApi.api.getToolPanelInstance("columns");
            columnToolPanel.setPivotModeSectionVisible(false);
            columnToolPanel.setValuesSectionVisible(false);
            columnToolPanel.setPivotSectionVisible(false);
            columnToolPanel.setRowGroupsSectionVisible(false);
        }
    });
}