import { Form } from "antd";
import { Checkbox } from "../UI/components/Checkbox/Checkbox";
import React, { useEffect, useState } from "react";
import styled, {withTheme} from "styled-components";
import { useFormContext, Controller, ErrorMessage } from "react-hook-form";

const StyledCheckbox = styled(Checkbox)``;

const StyledCheckboxWrapper = styled.div`
  height: auto;
  border: none;
  box-sizing: border-box;
  margin-bottom: 1.85rem;
  display: flex;
  align-items: center;
  & .ant-form-item {
    margin-bottom:  0;
  }
`;

const BooleanInput = (props) => {
  const { control, errors, setValue } = useFormContext();
  const { description, object, field} = props;
  const [initialValue, setInitialValue] = useState(false);

  if (object && object[field] && initialValue !== object[field]) {
    setInitialValue(object[field]);
  }

  useEffect(() => {
    if (initialValue) {
      setValue(field, initialValue);
    }
  }, [initialValue]);

  return (
    <StyledCheckboxWrapper>
      <Form.Item key={"form_" + field} name={field}>
        <Controller
          key={"input_" + field}
          name={field}
          as={<StyledCheckbox checkboxColor={props?.theme?.colors?.primary}>{description.label}</StyledCheckbox>}
          control={control}
        />
      </Form.Item>
      <ErrorMessage errors={errors} name={field} />
    </StyledCheckboxWrapper>
  );
};

export default withTheme(BooleanInput);