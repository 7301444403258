import * as React from "react";
import { connect, useSelector } from "react-redux";
import { Redirect } from "react-router-dom";
import storeProvider from "../store/storeProvider";

function protect(Component) {
  class AuthenticatedComponent extends React.Component {
    render() {
      const localUser = localStorage.user;
      return (
        <div>
          {localUser ? (
            <Component {...this.props} />
          ) : (
            <Redirect to={"/login"} />
          )}
        </div>
      );
    }
  }

  const mapStateToProps = state => {
    return {
      auth: state.auth.user
    };
  };

  return connect(mapStateToProps, null)(AuthenticatedComponent);
}

export default protect;
