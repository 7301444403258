import {MonitoringLayerSchema} from "./MonitoringLayerSchema";
import {TrackingLayerSchema} from "./TrackingLayerSchema";
import {GeozonesLayerSchema} from "./GeozonesLayerSchema";

export const coreLayersSchema = {
    MonitoringLayerSchema,
    TrackingLayerSchema,
    GeozonesLayerSchema,

}
