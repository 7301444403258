import {GeozonesLayer} from "./GeozonesLayer";
import {MonitoringLayer} from "./MonitoringLayer";
import {TrackingLayer} from "./TrackingLayer";


export const coreLayers = {
    GeozonesLayer,
    MonitoringLayer,
    TrackingLayer

}
