import {mapObjectsEnum} from "../../../../../../enums/mapObjectsEnum";
import {CommonSelectedItemContextMenuPrototype} from "../../prototypes/CommonSelectedItemContextMenuPrototype";

export class SecurityCheckpointDescription extends CommonSelectedItemContextMenuPrototype {

    constructor(selectedObject,layerName,initialCoordinate) {
        super(selectedObject, mapObjectsEnum.securityCheckpoint, layerName)
    }

    actionItems = [...this.actionItems]

}
