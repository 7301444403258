import securedAxiosInstance from "../../../services/net/axiosSecured";
import {
    CLEAN_UP_EDITABLE_LAYERS_HISTORY,
    DISABLE_MAP_EDIT_MODE,
    REMOVE_LAST_ITEM_IN_EDITABLE_LAYERS_HISTORY,
    TOGGLE_MAP_EDIT_COLLAPSE_STATUS,
    TOGGLE_MAP_EDIT_FORM_PANEL_ACTIVE_STATUS,
    UPDATE_MAP_EDIT_LAYER_HISTORY_LAST_ITEM_DATA
} from "../mapboxEditableLayersHistory";


export const removeLastItemInEditableLayerHistory = () => ({
    type: REMOVE_LAST_ITEM_IN_EDITABLE_LAYERS_HISTORY
})

export const updateMapEditLayerHistoryLastItemData = (newData) => ({
    type: UPDATE_MAP_EDIT_LAYER_HISTORY_LAST_ITEM_DATA,
    payload: {
        newData
    }
})


export const disableMapEditMode = () => ({
    type: DISABLE_MAP_EDIT_MODE,
})

export const cleanUpEditableLayersHistory = () => ({
    type: CLEAN_UP_EDITABLE_LAYERS_HISTORY,
})

export const toggleEditableLayerCollapseStatus = (newStatus) => ({
    type: TOGGLE_MAP_EDIT_COLLAPSE_STATUS,
    payload: {
        newStatus: newStatus
    }
})

export const toggleEditableLayerFormPanelActiveStatus = (newStatus) => {
    return {
        type: TOGGLE_MAP_EDIT_FORM_PANEL_ACTIVE_STATUS,
        payload: {
            newStatus: newStatus
        }
    }
}
