import { CommonSelectedItemContextMenuPrototype } from "../../prototypes/CommonSelectedItemContextMenuPrototype";
import React from "react";
import { groupTypes } from "../../../contentGroups";
import { mapObjectsEnum } from "../../../../../../enums/mapObjectsEnum";
import { mapContextMenuContentTypesEnum } from "../../../../../../enums/mapContextMenuContentTypesEnum";
import { initializeSingleObjectEditMode } from "../../../../../../sagas/map/standaloneObjectEditableSaga";
import { CheckpointIcon } from "../../../../../../library/icons/contextMenuIcons/CheckpointIcon";

const { ADD_GROUP } = groupTypes;

export class RailwayDescription extends CommonSelectedItemContextMenuPrototype {
  initialCoordinate = null;
  constructor(selectedObject, layerName, initialCoordinate) {
    super(selectedObject, mapObjectsEnum.railway, layerName);
    this.initialCoordinate = initialCoordinate;
  }
  actionItems = [
    ...this.actionItems,
    {
      name: mapContextMenuContentTypesEnum.createDischargeZone,
      translatePath: "Зона разгрузки",
      group: ADD_GROUP,
      dispatchedAction: () =>
        initializeSingleObjectEditMode(
          this.selectedObject,
          mapObjectsEnum.dischargeZone,
          this.layerName,
          this.initialCoordinate
        ),
      icon: <CheckpointIcon />
    },
    {
      name: mapContextMenuContentTypesEnum.createReceptionArea,
      translatePath: "Зона приема",
      group: ADD_GROUP,
      dispatchedAction: () =>
          initializeSingleObjectEditMode(
              this.selectedObject,
              mapObjectsEnum.receptionArea,
              this.layerName,
              this.initialCoordinate
          ),
      icon: <CheckpointIcon />
    }
  ];
}
