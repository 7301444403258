// import "date-fns";
// import React from "react";
// import DateFnsUtils from "@date-io/date-fns";
// import {
//   MuiPickersUtilsProvider,
//   DatePicker,
//   KeyboardDateTimePicker,
//   DateTimePicker,
// } from "@material-ui/pickers";
// import styled from "styled-components";
// import { ArrowIcon } from "../../../icons/actionIcons/ArrowIcon";
//
// class RuLocalizedUtils extends DateFnsUtils {
//   getCalendarHeaderText(date) {
//     return format(date, "LLLL", { locale: this.locale });
//   }
//
//   getDatePickerHeaderText(date) {
//     return format(date, "dd MMMM", { locale: this.locale });
//   }
// }
//
// class FrLocalizedUtils extends DateFnsUtils {
//   getDatePickerHeaderText(date) {
//     return format(date, "d MMM yyyy", { locale: this.locale });
//   }
// }
//
// export default function SelectTime() {
//   // The first commit of Material-UI
//   const [selectedDate, setSelectedDate] = React.useState(new Date());
//
//   const handleDateChange = (date) => {
//     setSelectedDate(date);
//   };
//
//   return (
//     <MuiPickersUtilsProvider utils={DateFnsUtils}>
//       <LocalMainWrapper>
//         <LocalInputWrapper>
//           <ExtendedTimeStart
//             ampm={false}
//             variant="inline"
//             label="Начало"
//             value={selectedDate}
//             onChange={handleDateChange}
//             format="dd.MM.yyyy HH:mm"
//           />
//         </LocalInputWrapper>
//         <LocalIcon>
//           <ArrowIcon />
//         </LocalIcon>
//         <LocalInputWrapper>
//           <ExtendedTimeEnd
//             variant="inline"
//             label="Конец"
//             value={selectedDate}
//             onChange={handleDateChange}
//             format="dd.MM.yyyy HH:mm"
//           />
//         </LocalInputWrapper>
//       </LocalMainWrapper>
//     </MuiPickersUtilsProvider>
//   );
// }

// import React, { useState } from "react";
// import { makeStyles } from "@material-ui/core/styles";
// import TextField from "@material-ui/core/TextField";
//
// const useStyles = makeStyles((theme) => ({
//   container: {
//     display: "flex",
//     flexWrap: "wrap",
//   },
//   textField: {
//     marginLeft: theme.spacing(1),
//     marginRight: theme.spacing(1),
//     width: 200,
//   },
// }));
//
// export default function DateAndTimePickers() {
//   const classes = useStyles();
//   const [selectedDate, setSelectedDate] = useState(new Date());
//   const handleDateChange = (date) => {
//     setSelectedDate(date);
//   };
//
//   return (
//     <form className={classes.container} noValidate>
//       <TextField
//         id="datetime-local"
//         label="Next appointment"
//         type="datetime-local"
//         defaultValue={selectedDate}
//         onChange={handleDateChange}
//         className={classes.textField}
//         InputLabelProps={{
//           shrink: true,
//         }}
//       />
//     </form>
//   );
// }

import format from "date-fns/format";
import ruLocale from "date-fns/locale/ru";
import enLocale from "date-fns/locale/en-US";
import React, {useState, useCallback, useEffect} from "react";
import { Styled } from "./SelectTime.styled";
import { ArrowIcon } from "../../../icons/actionIcons/ArrowIcon";
import moment from "moment";
import "date-fns";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  DatePicker,
  KeyboardDateTimePicker,
  DateTimePicker,
} from "@material-ui/pickers";

const {
  LocalMainWrapper,
  LocalInputWrapper,
  ExtendedTimeStart,
  ExtendedTimeEnd,
  LocalIcon,
} = Styled;

const localeMap = {
  en: enLocale,
  ru: ruLocale,
};

class RuLocalizedUtils extends DateFnsUtils {
  getCalendarHeaderText(date) {
    return format(date, "LLLL", { locale: this.locale });
  }

  getDatePickerHeaderText(date) {
    return format(date, "dd MMMM", { locale: this.locale });
  }
}

const localeUtilsMap = {
  en: DateFnsUtils,
  ru: RuLocalizedUtils,
};

const localeFormatMap = {
  en: "dd.MM.yyyy HH:mm",
  ru: "dd.MM.yyyy HH:mm",
};

const localeCancelLabelMap = {
  en: "cancel",
  ru: "отмена",
};

const SelectTime = (props) => {
  const [locale, setLocale] = useState("ru");
  const [selectedDateStart, handleDateChangeStart] = useState(new Date());
  const [selectedDateEnd, handleDateChangeEnd] = useState(new Date());

  const dateStringStart = selectedDateStart.toJSON();
  const dateStringEnd = selectedDateEnd.toJSON();

  const userTZ = Intl.DateTimeFormat().resolvedOptions().timeZone;

  let dateStart = moment
    .tz(dateStringStart, userTZ)
    .format("YYYY-MM-DD HH:mm:ssZ");
  let dateEnd = moment.tz(dateStringEnd, userTZ).format("YYYY-MM-DD HH:mm:ssZ");

  useEffect(() => {
    props.updateDataStart(dateStart)
    props.updateDataEnd(dateEnd)
  }, [])

  return (
    <LocalMainWrapper>
      <MuiPickersUtilsProvider
        utils={localeUtilsMap[locale]}
        locale={localeMap[locale]}
      >
        <LocalInputWrapper>
          <DateTimePicker
            value={selectedDateStart}
            onChange={handleDateChangeStart}
            format={localeFormatMap[locale]}
            cancelLabel={localeCancelLabelMap[locale]}
            shouldDisableDate={false}
            label="Начало"
            variant="inline"
            ampm={false}
            onClose={() => props.updateDataStart(dateStart)}
          />
        </LocalInputWrapper>
        <LocalIcon>
          <ArrowIcon />
        </LocalIcon>
        <LocalInputWrapper>
          <DateTimePicker
            value={selectedDateEnd}
            onChange={handleDateChangeEnd}
            format={localeFormatMap[locale]}
            cancelLabel={localeCancelLabelMap[locale]}
            label="Конец"
            variant="inline"
            ampm={false}
            onClose={() => props.updateDataEnd(dateEnd)}
          />
        </LocalInputWrapper>
      </MuiPickersUtilsProvider>
    </LocalMainWrapper>
  );
};

export default SelectTime;
