import {LayerPrototype} from "../description/prototype/LayerPrototype";
import {layerNamesEnum} from "../../../../enums/layerNamesEnum";
import {layerSettings} from "../description/settings";
import {mainDataForLayers} from "../description/mainData";
import {relatedLayerData} from "../description/relatedData";
import {layerCards} from "../description/cards";
import {layerSchemas} from "../description/layerSchemaPresets";
import {layerDataAccessors} from "../description/dataAccessors";
import MonitoringVehicleSelection
    from "../../../../library/layers/SelectedObjectsRepresentations/MonitoringVehicleSelection/MonitoringVehicleSelection";

export class MonitoringLayer extends LayerPrototype{

    constructor() {
        super({
            name:  layerNamesEnum.monitoring,
            mainData : new mainDataForLayers.coreMainDataForLayers.VehicleMonitoringMarksMainData(),
            dataAccessor : new layerDataAccessors.coreLayerDataAccessors.MonitoringDataAccessor(),
            relatedData: [
               new relatedLayerData.coreRelatedDataForLayers.Vehicle(),
            ],
            card: new layerCards.coreLayerCards.MonitoringLayerCard(),
            customSettings: [
                new layerSettings.OpacitySetting(),
                new layerSettings.IconSizeScale()
            ],
            layerSchema : [
                new layerSchemas.coreLayersSchema.MonitoringLayerSchema(),
            ],
            selectedObjectPrototype: MonitoringVehicleSelection,
        });
    }



}
