import React from "react";
import StandaloneEditModeControls from "./StandaloneEditModeControls";
import { connect } from "react-redux";
import { clearStandaloneEditModeObject } from "../../../../../reducers/map/actions/mapboxEditableStandaloneObjectActions";

const StandaloneEditModeControlsContainer = ({
  clearStandaloneEditModeObject
}) => {
  const handleCloseEditMode = () => {
    clearStandaloneEditModeObject();
  };

  return (
    <>
      <StandaloneEditModeControls handleCloseEditMode={handleCloseEditMode} />
    </>
  );
};

function mapStateToProps() {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    clearStandaloneEditModeObject: () =>
      dispatch(clearStandaloneEditModeObject())
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(StandaloneEditModeControlsContainer);
