import React from "react";
import { Styled } from "./Button.styled";
import LoadingOutlined from "@ant-design/icons/lib/icons/LoadingOutlined";
import {ButtonProps, ButtonType} from './Button.model'

const {
  Item,
  PrimaryItem,
  PrimaryReverseItem,
  DangerItem,
  DangerReverseItem,
  UnboundedItem,
  UnboundedPrimaryItem,
  PrimaryLCItem,
  PrimaryReverseLBItem,
  UnboundedDangerItem
} = Styled;

export const Button = (props : ButtonProps) => {
  const { children,
    type,
    disabled,
    onClick,
    className,
    icon,
    loading,
    width,
    iconColor,
    iconMargin,
    isBold,
    iconSize = { width: "24px", height: "24px" },
    rounded,
    hasShadow,
    fontSize,
    ...otherAttrs} = props;
  const onClickAction = (event : Event) => {
    if (type === "submit") return;
    if (disabled || !onClick || loading) {
      event.preventDefault();
    } else {
      return onClick(event);
    }
  };

  const itemProps : ButtonProps = {
    as: otherAttrs.href ? "a" : "button",
    className: `${className}`,
    onClick: onClickAction,
    disabled,
    iconColor,
    iconMargin,
    isBold,
    type,
    width,
    iconSize,
    rounded,
    hasShadow,
    fontSize,
    ...otherAttrs
  };
  const itemChildren = loading ? <LoadingOutlined /> : [icon, children];

  const resolveTypeOfBtn = (type : ButtonType, children: any, props: ButtonProps) => {
    switch (type) {
      case "primary":
        return <PrimaryItem {...props}>{children}</PrimaryItem>;
      case "primaryLC":
        return <PrimaryLCItem {...props}>{children}</PrimaryLCItem>;
      case "primaryReverse":
        return <PrimaryReverseItem {...props}>{children}</PrimaryReverseItem>;
      case "primaryReverseLB":
        return (
          <PrimaryReverseLBItem {...props}>{children}</PrimaryReverseLBItem>
        );
      case "danger":
        return <DangerItem {...props}>{children}</DangerItem>;
      case "dangerReverse":
        return <DangerReverseItem {...props}>{children}</DangerReverseItem>;
      case "unbounded":
        return <UnboundedItem {...props}>{children}</UnboundedItem>;
      case "unboundedPrimary":
        return (
          <UnboundedPrimaryItem {...props}>{children}</UnboundedPrimaryItem>
        );
      case "unboundedDanger":
        return (
          <UnboundedDangerItem {...props}>{children}</UnboundedDangerItem>
        );
      default:
        return <Item {...props}>{children}</Item>;
    }
  };

  return resolveTypeOfBtn(type as ButtonType, itemChildren, itemProps);
};

Button.defaultProps = {
  iconSize: { width: "24px", height: "24px" }
};
