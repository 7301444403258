import styled from "styled-components";
import {Button} from "antd";

const LocalMainWrapper = styled.div`

  
  .inline-placeholder-login{
    user-select: none;
    pointer-events: none;
    z-index: 1500;
    font-family: Open Sans;
    font-size: 16px;
    color: #bfbfbf;
    position: absolute;
    top: 16px;
    left: 31px;
    transition: all .2s
  }
  
  .inline-placeholder-login.close.focused {
    position: absolute;
    transform: translate3d(0, -6px, 0);
    font-size: 13px;
  }

  .inline-placeholder-login.open {
    position: absolute;
    transform: translate3d(0, -6px, 0);
    font-size: 13px;
  }
  
`;


const LocalInputWrapperPassword = styled.div`
    position: relative;
    width: fit-content;
    margin: 0 auto 20px;
`

const LocalInputWrapperRepeatPassword = styled.div`
    position: relative;
    width: fit-content;
    margin: 0 auto 20px;
`

const LocalFormWrapper = styled.div`
    width: 354px;
    top: 50%;
    position: absolute;
    left: 50%;
    height: 451px;
    z-index: 1;
    transform: translate(-50%, -50%);
    background-color: #FFFFFF;
`

const LocalFormTitle = styled.div`
    font-family: Open Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 33px;
    width: 100%;
    align-items: center;
    margin-top: 36px;
    color: rgba(0, 0, 0, 0.85);
`

const LocalFormSubmit = styled.form`

`

const ExtendedButton = styled(Button)`

`
// Todo style and functional
export const Styled = {
    LocalMainWrapper,
    LocalFormWrapper,
    LocalFormTitle,
    LocalFormSubmit,
    LocalInputWrapperPassword,
    LocalInputWrapperRepeatPassword,
    ExtendedButton,


};

