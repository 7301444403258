import React, { useRef, useEffect } from "react";
import { Styled } from "./CustomTooltip.styled";
import { useDispatch } from "react-redux";
import { setEditableLayerTooltip } from "../../../../reducers/map/mapboxEditableLayersHistory";
import PropTypes from "prop-types";
import { gotoElementByTag } from "../../../../services/dom/gotoElementByTag";

function useOutsideAlerter(ref, dispatch, onCloseCallback) {
  useEffect(() => {
    function handleClickOutside(event) {
      const parent = gotoElementByTag(event.target, "div");
      if (event.rightButton) {
        return;
      }
      if (!("rightButton" in event) && event?.button === 2) {
        return;
      }
      if (parent.classList.contains("ant-popover-buttons")) {
        return;
      }
      if (ref.current && !ref.current.contains(event.target)) {
        if (!onCloseCallback) {
          dispatch(setEditableLayerTooltip(null));
        } else onCloseCallback();
      }
    }

    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);
}

const CustomTooltip = ({
  positionStyleOfTooltip,
  children,
  onCloseCallback,
  tooltipWidth,
  tailSide
}) => {
  const wrapperRef = useRef(null);
  const dispatch = useDispatch();
  useOutsideAlerter(wrapperRef, dispatch, onCloseCallback);

  // быстрое решение проблемы отклонения координат при клике на карту в отличие клика на обьект
  const { clickedObjectName } = positionStyleOfTooltip;
  if (clickedObjectName === 'map') {
    positionStyleOfTooltip.x += 180;
  }

  return (
    <Styled.LocalMainWrapper
      ref={wrapperRef}
      tooltipWidth={tooltipWidth}
      positionStyleOfTooltip={positionStyleOfTooltip}
      tailSide={tailSide}
    >
      {children}
    </Styled.LocalMainWrapper>
  );
};

CustomTooltip.propTypes = {
  children: PropTypes.any,
  onCloseCallback: PropTypes.func,
  positionStyleOfTooltip: PropTypes.object.isRequired,
  tooltipWidth: PropTypes.string,
  tailSide: PropTypes.oneOf([
    "top-center",
    "top-left",
    "top-right",
    "left-center",
    "left-top",
    "left-bottom",
    "right-top",
    "right-center",
    "right-bottom",
    "bottom-left",
    "bottom-center",
    "bottom-right"
  ])
};

export default CustomTooltip;

CustomTooltip.defaultProps = {
  positionStyleOfTooltip: { y: 0, x: 0, visible: true }
};
