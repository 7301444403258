import { LayerDataAccessorPrototype } from "../prototype/LayerDataAccessorPrototype";
import { dataAccessMethodsEnum } from "../../../../../../enums/dataAccessMethodsEnum";
import {mapObjectsEnum} from "../../../../../../enums/mapObjectsEnum";
import React from "react";

const FilterFromSelectedDataButton = React.lazy(() =>
    import(
        "../../../../../../library/actionsButtons/NIPIGAZ/Map/dataAccessors/customForLayers/filterButtons/FilterMonitoringOnMapButton"
        )
);

const RefreshDataButtonOnMap = React.lazy(() => import('../../../../../../library/actionsButtons/NIPIGAZ/Map/dataAccessors/customForLayers/refreshButtons/RefreshMonitoringOnMapButton'))

export class MonitoringDataAccessor extends LayerDataAccessorPrototype {
  observeMainData = false;
  observeRelatedData = "vehicles";

  modelUrl = this.basePathTemplate + "/layer_monitoring/vehicles/";

  actionButtons = [FilterFromSelectedDataButton,RefreshDataButtonOnMap]

  constructor() {
    super({
      urlData: "/layer_monitoring/vehicles/",
      dataAccessMethod: dataAccessMethodsEnum.useReduxData,
      i18LabelPath: "vehicles",
      selectedType: mapObjectsEnum.monitoringObject,
    });
  }
}
