import { RelatedDataPrototype } from "../prototype/RelatedDataPrototype";

export class GeozonesTypes extends RelatedDataPrototype {
  constructor() {
    super({
      name: "geozonesTypes",
      filterLabel: 'Тип геозоны',
      urlData: "/geozones/types/",
    });
  }
}
