import React, { createContext } from "react";
import PropTypes from "prop-types";
import { Portal } from "react-portal";
import { Styled } from "./ScreenPortal.styled";

export const PortalContext = createContext(null);

const ScreenPortal = ({ portalSide, closePortal = () => {}, isOpen, children }) => {
  return (
    <PortalContext.Provider value={{ closePortal, isOpen }}>
      {isOpen ? (
        <Portal>
          <Styled.LocalContentWrap portalSide={portalSide}>{children}</Styled.LocalContentWrap>
        </Portal>
      ) : null}
    </PortalContext.Provider>
  );
};

export default ScreenPortal;

ScreenPortal.propTypes = {
  children: PropTypes.node,
  closePortal: PropTypes.func,
  openPortal: PropTypes.func,
  isOpen: PropTypes.bool.isRequired,
  portalSide: PropTypes.oneOf([
    "top-right",
    "top-center",
    "top-left",
    "bottom-right",
    "bottom-center",
    "bottom-left",
    "middle-left",
    "middle-center",
    "middle-right",
  ]),
};
