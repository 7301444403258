import React from "react";
import { Button } from "../../Button/Button";
import { ArrowToDown } from "../../../../icons/actionIcons/ArrowToDown";
import { iconSizesKit } from "../../../utils/iconSizesKit";
import { DeleteIcon } from "../../../../icons/actionIcons/DeleteIcon";
import { ArrowToUp } from "../../../../icons/actionIcons/ArrowToUp";
import PropTypes from "prop-types"
import {Styled} from "../TransferCard.styled";

const {LocalFooterInner, LocalFooterWrap} = Styled.Footer


const TransferCardFooter = ({ loading, unBlockControls, toUpCallback, toDownCallback, deleteCallback }) => {
  const upButtonRender = toUpCallback && (
    <Button
      type={"primary"}
      onClick={toUpCallback}
      p={"0.5rem"}
      icon={<ArrowToUp />}
      iconSize={iconSizesKit.default}
      disabled={loading || !unBlockControls}
    />
  );
  const downButtonRender = toDownCallback && (
    <Button
      type={"primary"}
      onClick={toDownCallback}
      p={"0.5rem"}
      icon={<ArrowToDown />}
      iconSize={iconSizesKit.default}
      disabled={loading || !unBlockControls}
    />
  );
  const deleteButtonRender = deleteCallback && (
    <Button
      type={"dangerReverse"}
      onClick={deleteCallback}
      p={"0.5rem"}
      icon={<DeleteIcon />}
      iconSize={iconSizesKit.small}
      disabled={loading || !unBlockControls}
    />
  );
  return (
    <LocalFooterWrap>
      <LocalFooterInner>
        {upButtonRender}
        {downButtonRender}
        {deleteButtonRender}
      </LocalFooterInner>
    </LocalFooterWrap>
  );
};

export default TransferCardFooter;

TransferCardFooter.propTypes = {
  unBlockControls: PropTypes.bool,
  deleteCallback: PropTypes.func,
  loading: PropTypes.bool,
  toDownCallback: PropTypes.func,
  toUpCallback: PropTypes.func
}