import React from "react";
import { Styled } from "./Checkbox.styled";
import {CheckboxEmptySVG} from "./Parts/CheckboxEmptySVG";
import {CheckboxFillSVG} from "./Parts/CheckboxFillSVG";

const { Label, CheckboxWrapper, CheckboxPart, TextWrapper } = Styled;

export const Checkbox = ({
  children,
  disabled,
  onChange,
  className,
  checked,
  checkboxColor,
  fontColor,
  boldText,
  onClick,
  value,
  checkboxSizeInPx = 24,
  ...otherAttrs
}) => {
  const onChangeAction = (e) => {
    if (disabled) {
      e.preventDefault();
    } else {
      return onChange && onChange(e);
    }
  };

  return (
    <Label
      {...otherAttrs}
      disabled={disabled}
      onChange={e => onChangeAction(e)}
    >
      <CheckboxWrapper
        checkboxSizeInPx={checkboxSizeInPx}
        checkboxColor={checkboxColor}
      >
        <CheckboxPart>
          <CheckboxEmptySVG width={checkboxSizeInPx} height={checkboxSizeInPx} />
        </CheckboxPart>
        <input
          type={"checkbox"}
          checked={checked}
          onClick={onClick}
          value={value}
        />
        <CheckboxPart>
          <CheckboxFillSVG width={checkboxSizeInPx} height={checkboxSizeInPx} />
        </CheckboxPart>
      </CheckboxWrapper>
      {children && (
        <TextWrapper boldText={boldText} fontColor={fontColor}>
          {children}
        </TextWrapper>
      )}
    </Label>
  );
};
