import { PenIcon } from "../../../icons/objectIcons/PenIcon";
import React from "react";
import { iconSizesKit } from "../../../UI/utils/iconSizesKit"
import { useDispatch, useSelector } from "react-redux";
import { initializeSingleObjectEditMode } from "../../../../sagas/map/standaloneObjectEditableSaga";
import { getSelectedMapboxLayer } from "../../../../reducers/map/mapboxLayers";
import _ from "underscore";
import {Button} from "../../../UI/components/Button/Button";

export const EditObjectDataAccessorButton = () => {
  const dispatch = useDispatch();
  const selectedObject = useSelector((state) => state.mapSelectedObject);
  const SelectedMapLayer = useSelector((state) =>
    getSelectedMapboxLayer(state)
  );
  const selectedMapObjectType = SelectedMapLayer.getDataAccessor().getSelectedObjectType();
  const isEditable = SelectedMapLayer.getDataAccessor().getIfSelectedEditable();

  const handleStartEdit = () =>
    dispatch(
      initializeSingleObjectEditMode(
        selectedObject,
        selectedMapObjectType,
        SelectedMapLayer.getName(),
        null
      )
    );
  return (
    <>
    {/*  {isEditable && (
        <Button
          type={"unboundedPrimary"}
          rounded
          icon={<PenIcon />}
          p={"none"}
          iconSize={iconSizesKit.huge}
          onClick={handleStartEdit}
          disabled={_.isEmpty(selectedObject)}
        />
      )}*/}
    </>
  );
};
