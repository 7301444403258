export const signsMapperType3 = {
  "3.1": {
    x: 0,
    y: 2103,
    width: 600,
    height: 600,
  },
  "3.2": {
    x: 601,
    y: 2103,
    width: 600,
    height: 600,
  },
  "3.3": {
    x: 1202,
    y: 2103,
    width: 600,
    height: 600,
  },
  "3.4": {
    x: 2103,
    y: 0,
    width: 600,
    height: 600,
  },
  "3.5": {
    x: 2103,
    y: 601,
    width: 600,
    height: 600,
  },
  "3.6": {
    x: 2103,
    y: 1202,
    width: 600,
    height: 600,
  },
  "3.7": {
    x: 2103,
    y: 1803,
    width: 600,
    height: 600,
  },
  "3.8": {
    x: 0,
    y: 2704,
    width: 600,
    height: 600,
  },
  "3.9": {
    x: 0,
    y: 0,
    width: 700,
    height: 700,
  },
  "3.10": {
    x: 0,
    y: 701,
    width: 700,
    height: 700,
  },
  "3.11": {
    x: 601,
    y: 2704,
    width: 600,
    height: 600,
  },
  "3.12": {
    x: 1202,
    y: 2704,
    width: 600,
    height: 600,
  },
  "3.14": {
    x: 1803,
    y: 2704,
    width: 600,
    height: 600,
  },
  "3.15": {
    x: 2704,
    y: 0,
    width: 600,
    height: 600,
  },
  "3.16": {
    x: 2704,
    y: 601,
    width: 600,
    height: 600,
  },
  "3.17.1": {
    x: 701,
    y: 0,
    width: 700,
    height: 700,
  },
  "3.17.2": {
    x: 701,
    y: 701,
    width: 700,
    height: 700,
  },
  "3.17.3": {
    x: 0,
    y: 1402,
    width: 700,
    height: 700,
  },
  "3.18.1": {
    x: 2704,
    y: 1202,
    width: 600,
    height: 600,
  },
  "3.18.2": {
    x: 2704,
    y: 1803,
    width: 600,
    height: 600,
  },
  "3.19": {
    x: 2704,
    y: 2404,
    width: 600,
    height: 600,
  },
  "3.20": {
    x: 0,
    y: 3305,
    width: 600,
    height: 600,
  },
  "3.21": {
    x: 601,
    y: 3305,
    width: 600,
    height: 600,
  },
  "3.22": {
    x: 1202,
    y: 3305,
    width: 600,
    height: 600,
  },
  "3.23": {
    x: 1803,
    y: 3305,
    width: 600,
    height: 600,
  },
  // "3.24": {
  //   x: 2404,
  //   y: 3305,
  //   width: 600,
  //   height: 600,
  // },
  "3.25": {
    x: 3305,
    y: 0,
    width: 600,
    height: 600,
  },
  "3.26": {
    x: 3305,
    y: 601,
    width: 600,
    height: 600,
  },
  "3.27": {
    x: 701,
    y: 1402,
    width: 700,
    height: 700,
  },
  "3.28": {
    x: 1402,
    y: 0,
    width: 700,
    height: 700,
  },
  "3.29": {
    x: 1402,
    y: 701,
    width: 700,
    height: 700,
  },
  "3.30": {
    x: 1402,
    y: 1402,
    width: 700,
    height: 700,
  },
  "3.31": {
    x: 3305,
    y: 1202,
    width: 600,
    height: 600,
  },
  "3.32": {
    x: 3305,
    y: 1803,
    width: 600,
    height: 600,
  },
  "3.33": {
    x: 3305,
    y: 2404,
    width: 600,
    height: 600,
  },
  "3.13": {
    x: 0,
    y: 3906,
    width: 600,
    height: 600,
  },
};
