import React from "react";
import UserSettingsContainer from "./UserSettings/UserSettingsContainer";
import {WidgetMenuIcon} from "../../../library/icons/WidgetMenuIcon";
import NamePage from "./NamePage/NamePage";
import {Styled} from "./Header.styled";
import {BellOutlinedIcon} from "../../../library/icons/actionIcons/BellOutlinedIcon";
import {Button} from "../../../library/UI/components/Button/Button";
import {iconSizesKit} from "../../../library/UI/utils/iconSizesKit";

const {LocalMainWrapper, LocalMainInner, LocalControlGroup, LocalHeaderNotification} = Styled

const Header = (props) => {
    return (
        <LocalMainWrapper>
            <NamePage {...props} />
            <LocalMainInner>
                <LocalControlGroup>
                   {/* <LocalHeaderNotification>
                        <Button icon={<WidgetMenuIcon/>} rounded type={"unbounded"} iconSize={iconSizesKit.default} />
                    </LocalHeaderNotification>
                    <LocalHeaderNotification>
                        <Button icon={<BellOutlinedIcon/>} rounded type={"unbounded"} iconSize={iconSizesKit.huge} />
                    </LocalHeaderNotification>*/}
                </LocalControlGroup>
                <UserSettingsContainer/>
            </LocalMainInner>
        </LocalMainWrapper>
    )
}

export default Header;
