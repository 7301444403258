import { Form, InputNumber } from "antd";
import React from "react";
import { Controller, ErrorMessage, useFormContext } from "react-hook-form";
import styled from "styled-components";
import { Styled } from "./styled/BaseInput.styled";
import { useBaseInputPlaceholderControl } from "./utils/hooks/useBaseInputPlaceholderControl";
import { checkConditionAndCallFn } from "../../services/common/checkConditionAndCallFn";
import { useBaseInputSettings } from "./utils/hooks/useBaseInputSettings";

const { getBaseStylesForInput, BasePlaceholder, BaseErrorMessage } = Styled;

let StyledInputNumber = getBaseStylesForInput(InputNumber);
const StyledPlaceholder = styled(BasePlaceholder)`
  &.opened {
    transform: translateY(-22px);
  }
`;

const IntegerInput = (props) => {
  const { control, errors, setValue } = useFormContext();
  const { description, object, field, className, step } = props;
  const { required, message } = useBaseInputSettings(description);
  const {
    placeholderState,
    setPlaceholderState,
  } = useBaseInputPlaceholderControl(field, object, setValue);
  return (
    <Form.Item key={"form_" + field} name={field} className={className}>
      <Controller
        as={StyledInputNumber}
        name={field}
        step={step}
        key={"input_" + field}
        disabled={description.read_only}
        control={control}
        rules={{
          required: { value: required, message: message },
        }}
        onFocus={() => {
          setPlaceholderState(true);
        }}
        onBlur={(e) =>
          checkConditionAndCallFn(!e[0].target.value, () =>
            setPlaceholderState(false)
          )
        }
      />
      <ErrorMessage as={BaseErrorMessage} errors={errors} name={field} />
      <StyledPlaceholder className={`${placeholderState ? "opened" : ""}`}>
        {description.label}
      </StyledPlaceholder>
    </Form.Item>
  );
};

export default IntegerInput;
