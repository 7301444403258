import React from "react";
import CompendiumContainer from "../../../library/compendiums/Common/Compendium/CompendiumContainer";
import ObjectPanelContainer from "../../../library/objectPanels/Common/ObjectPanelContainerDirectory";
import CreateButton from "../../../library/actionsButtons/common/CreateButton";
import RefreshButton from "../../../library/actionsButtons/common/RefreshButton";
import DeleteButton from "../../../library/actionsButtons/common/DeleteButton";
import { resolver } from "./resolver";
import { registeredDirectoryUrlsEnum } from "../../../enums/registeredDirectoryUrlsEnum";
import UploadTMCFileButton from "../../../library/actionsButtons/NIPIGAZ/directory/UploadTMCFileButton";
import UploadVehiclesFileButton from "../../../library/actionsButtons/NIPIGAZ/directory/UploadVehiclesFileButton";

const basePath = `${
  process.env.REACT_APP_TYPE === "PROD" || process.env.REACT_APP_TYPE === "TEST"
    ? process.env.REACT_APP_URL
    : "http://127.0.0.1:8000/api"
}`;
// const BASE_DIRECTORY_PATH = "/app/directory";
export const resolveUrl = resolver;

const REGISTERED_APP_URLS = registeredDirectoryUrlsEnum;

/***
 *register urls for application and custom renderers
 * register null if render is unnecessary
 */
export const registeredUrls = [
  {
    url: REGISTERED_APP_URLS.users,
    urlData: basePath + "/users/",
    folderKey: "app.directories.administration",
    i18LabelPath: "app.links.directories.users",
    permissionToShow: [],
    description: "app.links.directories.description.descriptionUsers",
  },
/*  {
    url: REGISTERED_APP_URLS.terminals,
    urlData: basePath + "/terminals/",
    folderKey: "app.directories.transport",
    i18LabelPath: "app.links.directories.terminals",
    permissionToShow: [],
    description: "app.links.directories.description.descriptionTerminals",
  },*/
  {
    url: REGISTERED_APP_URLS.organizations,
    i18LabelPath: "app.links.directories.organizations",
    urlData: basePath + "/organizations/",
    folderKey: "app.directories.general",
    permissionToShow: [],
    description: "app.links.directories.description.descriptionOrg",
  },
  {
    url: REGISTERED_APP_URLS.groups,
    urlData: basePath + "/permissions/groups/",
    i18LabelPath: "app.links.directories.groups",
    folderKey: "app.directories.administration",
    // generalRenderer: RolesCompendiumContainer,
    // objectPanel:  RolesObjectPanelContainer,
    permissionToShow: [],
    description: "app.links.directories.description.descriptionGroups",
  },
  {
    url: REGISTERED_APP_URLS.states,
    urlData: basePath + "/states/",
    i18LabelPath: "app.links.directories.states",
    folderKey: "app.directories.general",
    schemaModifier: null,
    permissionToShow: [],
    description: "app.links.directories.description.descriptionStates",
  },
  {
    url: REGISTERED_APP_URLS.roads,
    urlData: basePath + "/roads/",
    i18LabelPath: "app.links.directories.roads",
    folderKey: "app.directories.roads",
    schemaModifier: null,
    permissionToShow: [],
    description: "app.links.directories.description.descriptionRoads",
  },
  {
    url: REGISTERED_APP_URLS.segments,
    urlData: basePath + "/roads/segments/",
    folderKey: "app.directories.roads",
    i18LabelPath: "app.links.directories.segments",
    schemaModifier: null,
    permissionToShow: [],
    description: "app.links.directories.description.descriptionSegments",
  },
  {
    url: REGISTERED_APP_URLS.vehicles,
    urlData: basePath + "/vehicles/",
    i18LabelPath: "app.links.directories.vehicles",
    folderKey: "app.directories.transport",
    schemaModifier: null,
    permissionToShow: [],
    description: "app.links.directories.description.descriptionVehicles",
    actions: [CreateButton, RefreshButton, DeleteButton, UploadVehiclesFileButton]
  },
  {
    url: REGISTERED_APP_URLS.filters_user_organizations,
    urlData: basePath + "/filters/user_organizations/",
    i18LabelPath: "app.links.directories.filtersUserOrganizations",
    folderKey: "app.directories.general",
    schemaModifier: null,
    permissionToShow: [],
    description:
      "app.links.directories.description.descriptionFiltersUserOrganizations",
  },
  {
    url: REGISTERED_APP_URLS.organizations_forms,
    urlData: basePath + "/organizations/forms/",
    i18LabelPath: "app.links.directories.organizationsForms",
    folderKey: "app.directories.administration",
    schemaModifier: null,
    permissionToShow: [],
    description:
      "app.links.directories.description.descriptionOrganizationsForms",
  },
  {
    url: REGISTERED_APP_URLS.permissions,
    urlData: basePath + "/permissions/",
    i18LabelPath: "app.links.directories.permissions",
    folderKey: "app.directories.administration",
    schemaModifier: null,
    permissionToShow: [],
    description: "app.links.directories.description.descriptionPermissions",
  },
  {
    url: REGISTERED_APP_URLS.railway_rail_ways,
    urlData: basePath + "/railway_network/railways/",
    i18LabelPath: "app.links.directories.railways",
    schemaModifier: null,
    folderKey: "app.directories.train",
    permissionToShow: [],
    description: "app.links.directories.description.descriptionRailways",
  },
  {
    url: REGISTERED_APP_URLS.railway_trains,
    urlData: basePath + "/railway_network/trains/",
    i18LabelPath: "app.links.directories.trains",
    schemaModifier: null,
    folderKey: "app.directories.train",
    permissionToShow: [],
    description: "app.links.directories.description.descriptionTrains",
  },
  {
    url: REGISTERED_APP_URLS.railway_waggons,
    urlData: basePath + "/railway_network/waggons/",
    i18LabelPath: "app.links.directories.waggons",
    schemaModifier: null,
    folderKey: "app.directories.train",
    permissionToShow: [],
    description: "app.links.directories.description.descriptionWaggons",
  },
  {
    url: REGISTERED_APP_URLS.roads_checkpoint_segments,
    urlData: basePath + "/roads/checkpoints-segments/",
    i18LabelPath: "app.links.directories.checkpointsSegments",
    folderKey: "app.directories.administration",
    schemaModifier: null,
    permissionToShow: [],
    description:
      "app.links.directories.description.descriptionCheckpointsSegments",
  },
  {
    url: REGISTERED_APP_URLS.roads_checkpoints,
    urlData: basePath + "/roads/checkpoints-segments/",
    i18LabelPath: "app.links.directories.checkpoints",
    folderKey: "app.directories.roads",
    schemaModifier: null,
    permissionToShow: [],
    description: "app.links.directories.description.descriptionCheckpoints",
  },
  {
    url: REGISTERED_APP_URLS.roads_closure_segments,
    urlData: basePath + "/roads/closures-segments/",
    folderKey: "app.directories.roads",
    i18LabelPath: "app.links.directories.closureSegments",
    schemaModifier: null,
    permissionToShow: [],
    description: "app.links.directories.description.descriptionClosureSegments",
  },
  {
    url: REGISTERED_APP_URLS.roads_closures,
    urlData: basePath + "/roads/closures-segments/",
    folderKey: "app.directories.roads",
    i18LabelPath: "app.links.directories.closures",
    schemaModifier: null,
    permissionToShow: [],
    description: "app.links.directories.description.descriptionClosures",
  },
  {
    url: REGISTERED_APP_URLS.roads_roadworks_segments,
    urlData: basePath + "/roads/roadworks-segments/",
    i18LabelPath: "app.links.directories.roadworksSegments",
    folderKey: "app.directories.roads",
    schemaModifier: null,
    permissionToShow: [],
    description:
      "app.links.directories.description.descriptionRoadWorksSegments",
  },
  {
    url: REGISTERED_APP_URLS.roads_roadworks,
    urlData: basePath + "/roads/roadworks/",
    i18LabelPath: "app.links.directories.roadworks",
    folderKey: "app.directories.roads",
    schemaModifier: null,
    permissionToShow: [],
    description: "app.links.directories.description.descriptionRoadWorks",
  },
  {
    url: REGISTERED_APP_URLS.roads_segments_scheme_template_items,
    urlData: basePath + "/roads/segments/scheme_template_items/",
    i18LabelPath: "app.links.directories.schemeTemplateItems",
    folderKey: "app.directories.roads",
    schemaModifier: null,
    permissionToShow: [],
    description:
      "app.links.directories.description.descriptionSchemeTemplateItems",
  },
  {
    url: REGISTERED_APP_URLS.roads_segments_scheme_templates,
    urlData: basePath + "/roads/segments/scheme_templates/",
    i18LabelPath: "app.links.directories.schemeTemplates",
    folderKey: "app.directories.roads",
    schemaModifier: null,
    permissionToShow: [],
    description: "app.links.directories.description.descriptionSchemeTemplates",
  },
  {
    url: REGISTERED_APP_URLS.roads_signgroups,
    urlData: basePath + "/roads/sign-groups/",
    folderKey: "app.directories.roads",
    i18LabelPath: "app.links.directories.signGroups",
    schemaModifier: null,
    permissionToShow: [],
    description: "app.links.directories.description.descriptionSignGroups",
  },
/*  {
    url: REGISTERED_APP_URLS.roads_signsegments,
    urlData: basePath + "/roads/signs-segments/",
    i18LabelPath: "app.links.directories.signSegments",
    folderKey: "app.directories.roads",
    schemaModifier: null,
    permissionToShow: [],
    description: "app.links.directories.description.descriptionSignSegments",
  },*/
  {
    url: REGISTERED_APP_URLS.tmc_tracking,
    urlData: basePath + "/tmc_tracking/cargo/",
    i18LabelPath: "app.links.directories.tmcTracking",
    folderKey: "app.directories.tmc",
    schemaModifier: null,
    permissionToShow: [],
    description: "app.links.directories.description.descriptionTmcTracking",
    actions: [CreateButton, RefreshButton, DeleteButton, UploadTMCFileButton],
  },
  {
    url: REGISTERED_APP_URLS.tmc_upload_report,
    urlData: basePath + "/tmc_tracking/excel_parser_upload_files/",
    i18LabelPath: "app.links.directories.tmcTrackingErrorReport",
    folderKey: "app.directories.tmc",
    schemaModifier: null,
    permissionToShow: [],
    description: "app.links.directories.description.tmcTrackingErrorReport",
  },
  {
    url: REGISTERED_APP_URLS.country_names,
    urlData: basePath + "/tmc_tracking/country_names",
    i18LabelPath: "app.links.directories.country_names",
    folderKey: "app.directories.tmc",
    schemaModifier: null,
    permissionToShow: [],
    description: "app.links.directories.description.descriptionCountry_names",
  },
  {
    url: REGISTERED_APP_URLS.tmc_location_point,
    urlData: basePath + "/tmc_tracking/location_point/",
    i18LabelPath: "app.links.directories.tmcLocationPoint",
    folderKey: "app.directories.tmc",
    schemaModifier: null,
    permissionToShow: [],
    description: "app.links.directories.description.tmcLocationPoint",
  },
  {
    url: REGISTERED_APP_URLS.tmc_location_point_names,
    urlData: basePath + "/tmc_tracking/location_point_names/",
    i18LabelPath: "app.links.directories.tmcLocationPointNames",
    folderKey: "app.directories.tmc",
    schemaModifier: null,
    permissionToShow: [],
    description: "app.links.directories.description.tmcLocationPointNames",
  },
  {
    url: REGISTERED_APP_URLS.signs,
    urlData: basePath + "/roads/signs/",
    i18LabelPath: "app.links.directories.signs",
    folderKey: "app.directories.roads",
    schemaModifier: null,
    permissionToShow: [],
    description: "app.links.directories.description.descriptionSigns",
  },

  {
    url: REGISTERED_APP_URLS.road_closure_types,
    urlData: basePath + "/roads/closures/",
    i18LabelPath: "app.links.directories.road_closure_types",
    folderKey: "app.directories.roads",
    schemaModifier: null,
    permissionToShow: [],
    description: "app.links.directories.description.road_closure_types",
  },
  {
    url: REGISTERED_APP_URLS.road_security_checkpoints_types,
    urlData: basePath + "/roads/checkpoint_types/",
    i18LabelPath: "app.links.directories.road_security_checkpoints_types",
    folderKey: "app.directories.roads",
    schemaModifier: null,
    permissionToShow: [],
    description:
      "app.links.directories.description.road_security_checkpoints_types",
  },
  {
    url: REGISTERED_APP_URLS.dock_types,
    urlData: basePath + "/roads/docks/",
    i18LabelPath: "app.links.directories.docks_types",
    folderKey: "app.directories.roads",
    schemaModifier: null,
    permissionToShow: [],
    description: "app.links.directories.description.docks_types",
  },
  {
    url: REGISTERED_APP_URLS.dock_segments,
    urlData: basePath + "/roads/docks-segments/",
    i18LabelPath: "app.links.directories.docks_segments",
    folderKey: "app.directories.roads",
    schemaModifier: null,
    permissionToShow: [],
    description: "app.links.directories.description.descriptionDocksSegments",
  },
  {
    url: REGISTERED_APP_URLS.control_rooms_types,
    urlData: basePath + "/roads/control_rooms/",
    i18LabelPath: "app.links.directories.control_rooms_types",
    folderKey: "app.directories.roads",
    schemaModifier: null,
    permissionToShow: [],
    description: "app.links.directories.description.control_rooms_types",
  },
  {
    url: REGISTERED_APP_URLS.overpasses_types,
    urlData: basePath + "/roads/overpasses/",
    i18LabelPath: "app.links.directories.overpasses_types",
    folderKey: "app.directories.roads",
    schemaModifier: null,
    permissionToShow: [],
    description: "app.links.directories.description.overpasses_types",
  },
/*  {
    url: REGISTERED_APP_URLS.roads_segments_traffic_capacity,
    urlData: basePath + "/roads/segments/traffic_capacity/",
    i18LabelPath: "app.links.directories.roadTrafficCapacitySegments",
    folderKey: "app.directories.roads",
    schemaModifier: null,
    permissionToShow: [],
    description: "app.links.directories.description.descriptionRoadTrafficCapacity",
  },*/
/*  {
    url: REGISTERED_APP_URLS.roads_segments_lanes_amount,
    urlData: basePath + "/roads/segments/lanes_amount/",
    i18LabelPath: "app.links.directories.roadLanesAmountSegments",
    folderKey: "app.directories.roads",
    schemaModifier: null,
    permissionToShow: [],
    description: "app.links.directories.description.descriptionRoadLanesAmount",
  },*/
/*  {
    url: REGISTERED_APP_URLS.roads_segments_speed_limit,
    urlData: basePath + "/roads/segments/speed_limit/",
    i18LabelPath: "app.links.directories.roadSpeedLimitSegments",
    folderKey: "app.directories.roads",
    schemaModifier: null,
    permissionToShow: [],
    description: "app.links.directories.description.descriptionRoadSpeedLimit",
  },*/
  {
    url: REGISTERED_APP_URLS.overpasses_segments,
    urlData: basePath + "/roads/overpasses-segments/",
    i18LabelPath: "app.links.directories.overpasses_segments",
    folderKey: "app.directories.roads",
    schemaModifier: null,
    permissionToShow: [],
    description: "app.links.directories.description.descriptionOverpassesSegments",
  },
  {
    url: REGISTERED_APP_URLS.road_sign_types,
    urlData: basePath + "/roads/signs/",
    i18LabelPath: "app.links.directories.road_sign_types",
    folderKey: "app.directories.roads",
    schemaModifier: null,
    permissionToShow: [],
    description: "app.links.directories.description.road_sign_types",
  },
  {
    url: REGISTERED_APP_URLS.roadworks_types,
    urlData: basePath + "/roads/roadworks/",
    i18LabelPath: "app.links.directories.roadworks_types",
    folderKey: "app.directories.roads",
    schemaModifier: null,
    permissionToShow: [],
    description: "app.links.directories.description.roadworks_types",
  },
  {
    url: REGISTERED_APP_URLS.contracts,
    urlData: basePath + "/documents/contracts/",
    i18LabelPath: "app.links.directories.сontracts",
    folderKey: "app.directories.general",
    schemaModifier: null,
    permissionToShow: [],
    description: "app.links.directories.description.descriptionContracts",
  },
  {
    url: REGISTERED_APP_URLS.passes,
    urlData: basePath + "/documents/passes/",
    i18LabelPath: "app.links.directories.passes",
    folderKey: "app.directories.transport",
    schemaModifier: null,
    permissionToShow: [],
    description: "app.links.directories.description.descriptionPasses",
  },
  {
    url: REGISTERED_APP_URLS.geozones,
    urlData: basePath + "/geozones/",
    i18LabelPath: "app.links.directories.geozones",
    folderKey: "app.directories.general",
    schemaModifier: null,
    permissionToShow: [],
    description: "app.links.directories.description.geozones",
  },
  {
    url: REGISTERED_APP_URLS.etran_cargo,
    urlData: basePath + "/railway_network/etran_cargo/",
    i18LabelPath: "app.links.directories.wagon_dislocations",
    folderKey: "app.directories.general",
    schemaModifier: null,
    permissionToShow: [],
    description: "app.links.directories.description.descriptionWagonDislocations",
    actions: [CreateButton, RefreshButton, DeleteButton, UploadVehiclesFileButton],
  },
  {
    url: REGISTERED_APP_URLS.etran_contract,
    urlData: basePath + "/railway_network/etran_contract/",
    i18LabelPath: "app.links.directories.etran_contract",
    folderKey: "app.directories.general",
    schemaModifier: null,
    permissionToShow: [],
    description: "app.links.directories.description.descriptionEtranContract",
  },
  {
    url: REGISTERED_APP_URLS.geozones_types,
    urlData: basePath + "/geozones/types/",
    i18LabelPath: "app.links.directories.geozonesTypes",
    folderKey: "app.directories.general",
    schemaModifier: null,
    permissionToShow: [],
    description: "app.links.directories.description.geozonesTypes",
  },
  {
    url: REGISTERED_APP_URLS.common_projects,
    urlData: basePath + "/common/projects/",
    i18LabelPath: "app.links.directories.commonProjects",
    folderKey: "app.directories.general",
    schemaModifier: null,
    permissionToShow: [],
    description: "app.links.directories.description.descriptionCommonProjects",
  },
  {
    url: REGISTERED_APP_URLS.common_subprojects,
    urlData: basePath + "/common/subprojects/",
    i18LabelPath: "app.links.directories.commonSubProjects",
    folderKey: "app.directories.general",
    schemaModifier: null,
    permissionToShow: [],
    description: "app.links.directories.description.descriptionCommonSubProjects",
  },
  {
    url: REGISTERED_APP_URLS.common_warehouses,
    urlData: basePath + "/common/warehouses/",
    i18LabelPath: "app.links.directories.commonWarehouses",
    folderKey: "app.directories.general",
    schemaModifier: null,
    permissionToShow: [],
    description: "app.links.directories.description.descriptionCommonWareHouses",
  },
  {
    url: REGISTERED_APP_URLS.road_categories,
    urlData: basePath + "/roads/road_categories/",
    i18LabelPath: "app.links.directories.road_categories",
    folderKey: "app.directories.roads",
    schemaModifier: null,
    permissionToShow: [],
    description: "app.links.directories.description.road_categories",
  },
];

export const defaultRenderers = {
  generalRenderer: CompendiumContainer,
  objectPanel: ObjectPanelContainer,
  permissionToShow: [],
  actions: [CreateButton, RefreshButton, DeleteButton],
};
