import _ from "underscore";

export const ADD_MAPBOX_DATA = "ADD_MAPBOX_DATA";
export const PUSH_TO_MAPBOX_DATA = "PUSH_TO_MAPBOX_DATA";
export const PUSH_ARRAY_TO_MAPBOX_DATA = "PUSH_ARRAY_TO_MAPBOX_DATA";
export const PUSH_TO_MAPBOX_RELATED_DATA = "PUSH_TO_MAPBOX_RELATED_DATA";
export const PUSH_ARRAY_TO_MAPBOX_RELATED_DATA =
  "PUSH_ARRAY_TO_MAPBOX_RELATED_DATA";
export const ADD_MAPBOX_RELATED_DATA = "ADD_MAPBOX_RELATED_DATA";
export const ADD_MAPBOX_MULTIPLE_RELATED_DATA = "ADD_MAPBOX_MULTIPLE_RELATED_DATA";
export const REPLACE_CERTAIN_FIELD_IN_RELATED_DATA =
  "REPLACE_CERTAIN_FIELD_IN_RELATED_DATA";
export const REPLACE_ALL_MAPBOX_RELATED_DATA = "REPLACE_ALL_RELATED_DATA";
export const REPLACE_ALL_MAPBOX_SIMPLY_DATA = "REPLACE_ALL_RELATED_DATA";
export const RESET_MAPBOX_STORE = "RESET_MAPBOX_STORE";
export const SET_ACTIVE_DATA_REPLACING_FORCED_STATUS =
  "SET_ACTIVE_DATA_REPLACING_FORCED_STATUS";
export const SET_DISABLE_DATA_REPLACING_FORCED_STATUS =
  "SET_DISABLE_DATA_REPLACING_FORCED_STATUS";

const initialState = { isDataReplacingForced: false };

const mapboxData = (state = initialState, action) => {
  switch (action.type) {
    case RESET_MAPBOX_STORE: {
      return initialState;
    }
    case ADD_MAPBOX_DATA: {
      return {
        ...state,
        [action.payload.key]: {
          ...state[action.payload.key],
          data: action.payload.value,
        },
      };
    }
    case REPLACE_CERTAIN_FIELD_IN_RELATED_DATA: {
      const { layerName, relatedKey, data } = action.payload;
      return {
        ...state,
        [layerName]: {
          ...state[layerName],
          relatedData: {
            ...state[layerName]?.relatedData,
            [relatedKey?.name]: data,
          },
        },
      };
    }
    case REPLACE_ALL_MAPBOX_RELATED_DATA: {
      const { layerName, newDataArray } = action.payload;
      if (layerName && newDataArray) {
        return {
          ...state,
          [layerName]: {
            ...state[layerName],
            relatedData: {
              ...newDataArray,
            },
          },
        };
      }
      return state;
    }
    case REPLACE_ALL_MAPBOX_SIMPLY_DATA: {
      const { layerName, newDataArray } = action.payload;
      if (layerName && newDataArray) {
        return {
          ...state,
          [layerName]: {
            ...state[layerName],
            data: {
              ...newDataArray,
            },
          },
        };
      }
      return state;
    }
    case PUSH_TO_MAPBOX_RELATED_DATA: {
      const { initiatorName, relatedDataKey, value } = action.payload;
      if (state?.[initiatorName]?.relatedData?.[relatedDataKey]) {
        const relatedData = [
          ...state?.[initiatorName]?.relatedData?.[relatedDataKey],
        ];
        const incomeRegistrationNumber = value.registration_number;
        const updatedRelateDataIndex = relatedData.findIndex(
          (el) => el.registration_number === incomeRegistrationNumber
        );
        for (let key in value) {
          relatedData[updatedRelateDataIndex][key] = value[key];
        }

        return {
          ...state,
          [initiatorName]: {
            ...state[initiatorName],
            relatedData: {
              ...state[initiatorName].relatedData,
              [relatedDataKey]: [...relatedData],
            },
          },
        };
      } else {
        return state;
      }
      return state;
    }
    case PUSH_TO_MAPBOX_DATA: {
      const { key, value } = action.payload;
      const valueCopy = { ...value };
      try {
        const coordinates = [
          valueCopy?.point.longitude,
          valueCopy?.point.latitude,
        ];
      } catch (e) {}
      const coordinates = [
        valueCopy?.point.longitude,
        valueCopy?.point.latitude,
      ];
      delete valueCopy.point;
      const properties = { ...valueCopy };

      const dataIndex = state?.[key]?.data?.findIndex(
        (el) => el.device_id == properties.device_id
      );
      if (dataIndex >= 0) {
        const dataCopy = state?.[key].data;
        const objOld = _.clone(dataCopy[dataIndex]);
        delete objOld.timeReceived;
        const objNew = {
          coordinates: coordinates,
          ...properties,
        };
        delete objNew.timeReceived;
        if (_.isEqual(objOld, objNew)) {
          return state;
        }
        dataCopy[dataIndex] = {
          coordinates: coordinates,
          ...properties,
        };
        return {
          ...state,
          [action.payload.key]: {
            ...state[action.payload.key],
            data: [...dataCopy],
          },
        };
      } else if (state?.[key]?.data) {
        return {
          ...state,
          [action.payload.key]: {
            ...state[action.payload.key],
            data: [
              ...state[action.payload.key].data,
              {
                coordinates: coordinates,
                ...properties,
              },
            ],
          },
        };
      } else {
        return {
          ...state,
          [action.payload.key]: {
            ...state[action.payload.key],
            data: [
              {
                coordinates: coordinates,
                ...properties,
              },
            ],
          },
        };
      }
    }

    case PUSH_ARRAY_TO_MAPBOX_DATA: {
      const { key, value } = action.payload;
      const valueCopy = [...value];
      let newStateBranch = [...state[key].data];
      for (let item of valueCopy) {
        const coordinates = [item?.point.longitude, item?.point.latitude];
        delete item.point;
        const properties = { ...item };
        const dataIndex = newStateBranch?.findIndex(
          (el) => el?.device_id == properties?.device_id
        );

        if (dataIndex >= 0) {
          const objOld = _.clone(newStateBranch[dataIndex]);
          // delete objOld.timeReceived;
          const objNew = {
            coordinates: coordinates,
            ...properties,
          };
          // delete objNew.timeReceived;
          // if (_.isEqual(objOld, objNew)) {
          //     continue;
          // }
          newStateBranch[dataIndex] = {
            coordinates: coordinates,
            ...properties,
          };
        } else if (state?.[key]?.data) {
          newStateBranch = [
            ...newStateBranch,
            {
              coordinates: coordinates,
              ...properties,
            },
          ];
        } else {
          newStateBranch = [
            {
              coordinates: coordinates,
              ...properties,
            },
          ];
        }
      }
      return {
        ...state,
        [key]: {
          ...state[key],
          data: [...newStateBranch],
        },
      };
    }

    case PUSH_ARRAY_TO_MAPBOX_RELATED_DATA: {
      const { initiatorName, relatedDataKey, value } = action.payload;

      let newRelatedDataBranch = null;
      if (state?.[initiatorName]?.relatedData?.[relatedDataKey]) {
        newRelatedDataBranch = [
          ...state?.[initiatorName]?.relatedData?.[relatedDataKey],
        ];
        for (let item of value) {
          const incomeRegistrationNumber = item.registration_number;
          const updatedRelateDataIndex = newRelatedDataBranch.findIndex(
            (el) => el.registration_number === incomeRegistrationNumber
          );
          for (let keyOfItem in item) {
            newRelatedDataBranch[updatedRelateDataIndex][keyOfItem] =
              item[keyOfItem];
          }
        }
        return {
          ...state,
          [initiatorName]: {
            ...state[initiatorName],
            relatedData: {
              ...state[initiatorName].relatedData,
              [relatedDataKey]: [...newRelatedDataBranch],
            },
          },
        };
      } else {
        return state;
      }
    }
    case ADD_MAPBOX_RELATED_DATA: {
      const { parentKey, childKey, data } = action.payload;
      if (state?.[parentKey]?.relatedData) {
        return {
          ...state,
          [parentKey]: {
            ...state[parentKey],
            relatedData: {
              ...state[parentKey]["relatedData"],
              [childKey]: data,
            },
          },
        };
      } else {
        return {
          ...state,
          [parentKey]: {
            ...state[parentKey],
            relatedData: {
              [childKey]: data,
            },
          },
        };
      }
    }
    case ADD_MAPBOX_MULTIPLE_RELATED_DATA: {
      const {arrayOfRelatedData} = action.payload;
      let stateCopy = {...state};
      for (let value of arrayOfRelatedData) {
        const {parentKey, childKey, data} = value;
        if (stateCopy?.[parentKey]?.relatedData) {
          stateCopy = {
            ...stateCopy,
            [parentKey]: {
              ...stateCopy[parentKey],
              relatedData: {
                ...stateCopy[parentKey]["relatedData"],
                [childKey]: data,
              },
            },
          };
        } else {
          stateCopy = {
            ...stateCopy,
            [parentKey]: {
              ...stateCopy[parentKey],
              relatedData: {
                [childKey]: data,
              },
            },
          };
        }
      }
      return {...stateCopy}
    }
    case SET_ACTIVE_DATA_REPLACING_FORCED_STATUS: {
      return { ...state, isDataReplacingForced: true };
    }
    case SET_DISABLE_DATA_REPLACING_FORCED_STATUS: {
      return { ...state, isDataReplacingForced: false };
    }
    default:
      return state;
  }
};

export default mapboxData;
