import React from "react";
import { Icon } from "antd";

const VisibilityOffIconSVG = () => (
  <svg
    width="22"
    height="19"
    viewBox="0 0 22 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M21.0828 9.3955C20.2589 7.65972 19.2785 6.24409 18.1414 5.14862L16.9489 6.34112C17.9213 7.27042 18.7684 8.48612 19.5008 10.0002C17.5508 14.0361 14.7828 15.9533 11 15.9533C9.86455 15.9533 8.81869 15.7785 7.86244 15.4288L6.57033 16.7209C7.89845 17.3342 9.37501 17.6408 11 17.6408C15.5047 17.6408 18.8656 15.2947 21.0828 10.6025C21.172 10.4138 21.2182 10.2077 21.2182 9.99902C21.2182 9.79032 21.172 9.58421 21.0828 9.3955ZM19.5929 1.8805L18.5938 0.880186C18.5764 0.862753 18.5557 0.848924 18.5329 0.839488C18.5101 0.830052 18.4858 0.825195 18.4611 0.825195C18.4365 0.825195 18.4121 0.830052 18.3893 0.839488C18.3665 0.848924 18.3459 0.862753 18.3285 0.880186L15.7651 3.44237C14.3518 2.7205 12.7635 2.35956 11 2.35956C6.49533 2.35956 3.13439 4.70566 0.917201 9.39784C0.828081 9.58655 0.78186 9.79266 0.78186 10.0014C0.78186 10.2101 0.828081 10.4162 0.917201 10.6049C1.80298 12.4705 2.86939 13.9659 4.11642 15.091L1.63626 17.5705C1.60113 17.6057 1.58139 17.6533 1.58139 17.703C1.58139 17.7527 1.60113 17.8004 1.63626 17.8356L2.63681 18.8361C2.67197 18.8713 2.71964 18.891 2.76935 18.891C2.81906 18.891 2.86673 18.8713 2.90189 18.8361L19.5929 2.14581C19.6103 2.1284 19.6242 2.10772 19.6336 2.08496C19.643 2.06219 19.6479 2.0378 19.6479 2.01316C19.6479 1.98851 19.643 1.96412 19.6336 1.94135C19.6242 1.91859 19.6103 1.89791 19.5929 1.8805ZM2.49923 10.0002C4.45158 5.96425 7.21955 4.04706 11 4.04706C12.2783 4.04706 13.4406 4.26644 14.495 4.71245L12.8474 6.36011C12.067 5.94377 11.1736 5.78926 10.2988 5.91936C9.42397 6.04946 8.61413 6.45728 7.98875 7.08267C7.36336 7.70805 6.95553 8.51789 6.82543 9.3927C6.69533 10.2675 6.84985 11.161 7.26619 11.9413L5.31103 13.8964C4.22892 12.9414 3.29611 11.6474 2.49923 10.0002ZM8.28126 10.0002C8.28167 9.5869 8.37957 9.17954 8.56699 8.81119C8.75442 8.44285 9.02609 8.12391 9.35993 7.88029C9.69378 7.63666 10.0804 7.47522 10.4883 7.40908C10.8963 7.34294 11.3141 7.37397 11.7078 7.49964L8.40572 10.8018C8.32295 10.5426 8.28097 10.2722 8.28126 10.0002Z"
      fill="currentColor"
    />
  </svg>
);

export const VisibilityOffIcon = props => (
  <Icon component={VisibilityOffIconSVG} {...props} />
);
