import React from "react";
import { StarIcon } from "../../../icons/actionIcons/StarIcon";
import { OptionIcon } from "../../../icons/actionIcons/OptionIcon";
import { Styled } from "./SingleSubjectCard.style";

const {
  LocalCardMainWrapper,
  LocalCardHeader,
  LocalCardOption,
  LocalCardWrapper,
  LocalCardStar,
  LocalCardPoint,
  LocalCardDescr,
} = Styled;

const SingleSubjectCard = ({ url, to, titleContent, titleDescr }) => {
  return (
    <LocalCardMainWrapper url={url} to={to}>
      <LocalCardWrapper>
        <LocalCardHeader>
          {titleContent}
          <LocalCardOption>
            <LocalCardStar>
              <StarIcon />
            </LocalCardStar>
            <LocalCardPoint>
              <OptionIcon />
            </LocalCardPoint>
          </LocalCardOption>
        </LocalCardHeader>
        <LocalCardDescr>{titleDescr}</LocalCardDescr>
      </LocalCardWrapper>
    </LocalCardMainWrapper>
  );
};

export default SingleSubjectCard;
