import styled from "styled-components";
import { Layout } from "antd";
import ScrollableBoxArea from "../../../../../../../library/areas/scrollableBoxArea/ScrollableBoxArea";
import React from "react";

const PanelLayout = styled(Layout)`
  height: calc(100vh - 115px);
  padding: 0 20px 20px 20px;
  & ${ScrollableBoxArea} {
    margin-left: 20px !important;
  }
`;

const LocalInnerWrapper = styled.div`
  display: flex;
`;

const LocalTopWrapper = styled.div`
  width: 100%;
  display: flex;
`;

const LocalTitle = styled.div`
  text-align: left;
  padding: 40px 40px 0;
  width: 100%;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
`;

const LocalReportData = styled.div`
  flex-direction: column;
  padding: 40px;
`;
const LocalDataTS = styled.div`
  padding: 40px;
`;

const LocalExtensionData = styled.div`
  padding: 40px;
`;

const LocalBottomWrapper = styled.div`
  display: flex;
  width: 100%;
`;

const LocalSelectData = styled.div`
  padding: 40px;
`;

const LocalEmailData = styled.div`
  padding: 40px;
`;

export const Styled = {
  PanelLayout,
  LocalInnerWrapper,
  LocalTopWrapper,
  LocalTitle,
  LocalReportData,
  LocalDataTS,
  LocalExtensionData,
  LocalBottomWrapper,
  LocalSelectData,
  LocalEmailData,
};
