import {RelatedDataPrototype} from "../prototype/RelatedDataPrototype";
import {mapObjectsEnum} from "../../../../../../enums/mapObjectsEnum";
import {dataAccessMethodsEnum} from "../../../../../../enums/dataAccessMethodsEnum";

export class RoadOverpass extends RelatedDataPrototype {

    constructor() {
        super({
            name: 'road_overpass',
            filterLabel: 'Эстакада',
            urlData: '/layer_roads/overpasses-segments/',
            selectedObjectName:  mapObjectsEnum.roadOverpass,
            // dataAccessMethod: dataAccessMethodsEnum.custom // нужно было для проброса даты в запрос
        });
    }
}
