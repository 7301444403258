import {REVERT_TO_DEFAULT_MAP_MODE, SET_CURRENT_MAP_MODE} from "../mapMode";

export const setCurrentMapMode = (modeType) => ({
    type: SET_CURRENT_MAP_MODE,
    payload: {
        modeType
    }
})

export const revertToDefaultMapMode = () => ({
    type: REVERT_TO_DEFAULT_MAP_MODE
})