import React from "react";
import MapSelectedObjectInfo from "./MapSelectedObjectInfo";
import { connect } from "react-redux";
import { clearMapSelectedObject } from "../../../../../reducers/map/actions/mapSelectedObjectActions";
import { goToMapCoords } from "../../../../../reducers/map/actions/mapboxActions";
import { toggleMapSelectedObjectInfoVisibility } from "../../../../../reducers/map/actions/mapPanelsActions";
import { reloadMapboxLayerData } from "../../../../../reducers/map/actions/mapboxDataActions";

const MapSelectedObjectInfoContainer = ({
  clearMapSelectedObject,
  selectedMapItem,
  isMapSelectedObjectInfoVisible,
  toggleMapSelectedObjectInfoVisibility,
  reloadMapboxLayerData,
}) => {
  const handleCloseMapSelectedObjectInfo = () => {
    clearMapSelectedObject();
    toggleMapSelectedObjectInfoVisibility(false);
  };
  const handleOpenObjectInNewTab = () => {
    const { device_id } = selectedMapItem.selectedObject;
    const win = window.open(
      `/app/directory/layer_tmc_tracking/cargo/`,
      "_blank"
    );
    win.focus();
  }; // Todo may be need delete

  return (
    <>
      {isMapSelectedObjectInfoVisible && (
        <MapSelectedObjectInfo
          handleCloseMapSelectedObjectInfo={handleCloseMapSelectedObjectInfo}
          handleOpenObjectInNewTab={handleOpenObjectInNewTab}
          selectedMapItem={selectedMapItem}
          isMapSelectedObjectInfoVisible={isMapSelectedObjectInfoVisible}
          reloadMapboxLayerData={reloadMapboxLayerData}
        />
      )}
    </>
  );
};

MapSelectedObjectInfoContainer.propTypes = {};

const mapStateToProps = (state) => {
  return {
    selectedMapItem: state.mapSelectedObject,
    isMapSelectedObjectInfoVisible:
      state.mapPanels?.mapSelectedObjectInfoVisible,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    clearMapSelectedObject: () => dispatch(clearMapSelectedObject()),
    toggleMapSelectedObjectInfoVisibility: (bool) =>
      dispatch(toggleMapSelectedObjectInfoVisibility(bool)),
    goToMapCoords: (latitude, longitude) =>
      dispatch(goToMapCoords(latitude, longitude)),
    reloadMapboxLayerData: (urlData, layerName) =>
      dispatch(reloadMapboxLayerData(urlData, layerName)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MapSelectedObjectInfoContainer);
