import {resolver} from "./resolver";
import {mapObjectsEnum} from "../../../enums/mapObjectsEnum";

export const resolveEditableObjectDescription = resolver

export const editableObjectDescription = [
    {
        name:mapObjectsEnum.roadSegment,
        urlData: '/roads/segments/',
        accessMethod: 'http',
        label: 'Сегмент',
        parent: {
            urlData: '/roads/',
            key: 'road',
            childKey: 'segment',
            creatable: true,
            snapChildGeometry: false
        },
    },
    {
        name:mapObjectsEnum.roadSign,
        urlData: '/layer_roads/signs-segments/',
        accessMethod: 'http',
        label: 'Дорожный знак',
        parent: {
            urlData: '/roads/segments/',
            key: 'segment',
            childKey: 'signs-segments',
            creatable: false,
            snapChildGeometry: true
        },
    },
    {
        name:mapObjectsEnum.securityCheckpoint,
        urlData: '/layer_roads/checkpoints-segments/',
        accessMethod: 'http',
        label: 'КПП',
        parent: {
            urlData: '/roads/segments/',
            key: 'segment',
            childKey: 'signs-segments',
            creatable: false,
            snapChildGeometry: true
        },
    },
    {
        name:mapObjectsEnum.dockSegment,
        urlData: '/layer_roads/docks-segments/',
        accessMethod: 'http',
        label: 'Причал',
        parent: {
            urlData: '/roads/segments/',
            key: 'segment',
            childKey: 'signs-segments',
            creatable: false,
            snapChildGeometry: true
        },
    },
    {
        name:mapObjectsEnum.controlRoomsSegment,
        urlData: '/layer_roads/control_rooms-segments/',
        accessMethod: 'http',
        label: 'Диспетчерская',
        parent: {
            urlData: '/roads/segments/',
            key: 'segment',
            childKey: 'signs-segments',
            creatable: false,
            snapChildGeometry: true
        },
    },
    {
        name:mapObjectsEnum.roadOverpass,
        urlData: '/layer_roads/overpasses-segments/',
        accessMethod: 'http',
        label: 'Эстакада',
        parent: {
            urlData: '/roads/segments/',
            key: 'segment',
            childKey: 'signs-segments',
            creatable: false,
            snapChildGeometry: true
        },
    },
    {
        name:mapObjectsEnum.roadBlock,
        urlData: '/layer_roads/closures-segments/',
        accessMethod: 'http',
        label: 'Перекрытие дороги',
        parent: {
            urlData: '/roads/segments/',
            key: 'segment',
            childKey: 'signs-segments',
            creatable: false,
            snapChildGeometry: true
        },
    },
    {
        name:mapObjectsEnum.roadWorks,
        urlData: '/layer_roads/roadworks-segments/',
        accessMethod: 'http',
        label: 'Ремонтные работы',
        parent: {
            urlData: '/roads/segments/',
            key: 'segment',
            childKey: 'signs-segments',
            creatable: false,
            snapChildGeometry: true
        },
    },
    {
        name:mapObjectsEnum.geozone,
        urlData: '/geozones/',
        accessMethod: 'http',
        label: 'Геозоны',
        parent: null,
    },
    {
        name:mapObjectsEnum.railway,
        urlData: '/railway_network/railways/',
        accessMethod: 'http',
        label: 'ж/д пути',
        parent: null,
    },
    {
        name:mapObjectsEnum.dischargeZone,
        urlData: '/railway_network/discharge_zones/',
        accessMethod: 'http',
        label: 'Зоны разгрузки',
        parent: {
            urlData: '/railway_network/railways/',
            key: 'railway',
            childKey: 'railway-elements',
            creatable: false,
            snapChildGeometry: true
        },
    },
    {
        name:mapObjectsEnum.receptionArea,
        urlData: '/railway_network/reception_areas/',
        accessMethod: 'http',
        label: 'Зоны приема',
        parent: {
            urlData: '/railway_network/railways/',
            key: 'railway',
            childKey: 'railway-elements',
            creatable: false,
            snapChildGeometry: true
        },
    },
    {
        name:mapObjectsEnum.cargoTrackingPoint,
        urlData: '/tmc_tracking/location_point/',
        accessMethod: 'http',
        label: 'точка следования груза',
        parent: null,
    },
    {
        name:mapObjectsEnum.trafficSegment,
        urlData: '/traffic_jams/traffic_jam_segments/',
        accessMethod: 'http',
        label: 'Скоростной режим',
        parent: {
            urlData: '/roads/segments/',
            key: 'segment',
            childKey: 'traffic_jam_segments',
            creatable: false,
            snapChildGeometry: true
        },
    },
]

export const defaultObjectDescription =
    {
        permissionToShow: [],
        actions: []
    }
