import React, { useRef } from "react";
import { useTranslation } from "react-i18next";
import { commonSelectedObjectProtoUtils } from "../commonUtils/commonSelectedObjectProtoUtils";
import { registeredDirectoryUrlsEnum } from "../../../../enums/registeredDirectoryUrlsEnum";
import PrepareScrollableInfoCard from "../PrepareScrollableInfoCard";

const {
  parseCoordinatesToDesc,
  commonIconSizeForCurrentSection,
} = commonSelectedObjectProtoUtils;

const GeozonesItemSelection = ({
  handleCloseMapSelectedObjectInfo,
  selectedMapItem,
}) => {
  const { t } = useTranslation("common");
  const handleOpenObjectInNewTab = () => {
    const roadData = selectedMapItem.data.find(
      (dataRecord) => dataRecord.name === "geozone"
    );
    const { id } = roadData.data;
    const win = window.open(
      `/app/directory/${registeredDirectoryUrlsEnum.geozones}/${id}/`,
      "_blank"
    );
    win.focus();
  };
  let Descriptions = [];
  Descriptions.push( {
    key: t("app.menuMap.dataVehicle.coordinates"),
    value: parseCoordinatesToDesc(selectedMapItem),
  });

  const properties = selectedMapItem?.data[0]?.data
  properties.object_type &&
    Descriptions.push({
      key: "Тип объекта",
      value: properties.geozone_type,
    });

  properties.name &&
    Descriptions.push({
      key: "Имя геозоны",
      value: properties.name,
    });

  properties.description &&
    Descriptions.push({
      key: "Описание",
      value: properties.description,
    });

  properties.perimeter &&
  Descriptions.push({
    key: "Периметр",
    value: properties.perimeter + " км",
  });

  properties.total_area &&
  Descriptions.push({
    key: "Площадь",
    value: properties.total_area + " км. квадратных",
  });

  return (
    <PrepareScrollableInfoCard
      title={t("app.menuMap.scrollableInfoCard.header.geozonesLayer")}
      listOfDescriptions={Descriptions}
      handleCloseCard={handleCloseMapSelectedObjectInfo}
      openInNewTabBtnDesc={t("app.menuMap.dataVehicle.openInDirectory")}
      selectedMapItem={selectedMapItem}
      handleOpenItemInDirectory={handleOpenObjectInNewTab}
    />
  );
};

GeozonesItemSelection.propTypes = {};

export default GeozonesItemSelection;
