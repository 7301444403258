import { all } from "redux-saga/effects";
import { watchAuthSaga } from "./app/authSaga";
import { watchPostObjectSaga } from "./app/postObjectSaga";
import { watchDeleteObjectSaga } from "./directory/deleteObjectSaga";
import {
  watchInlineHistorySaga,
  watchReloadLastInlineHistoryDataSaga,
  watchRevertHistoryByType
} from "./directory/inlineHistorySaga";
import { watchOpenSocketSaga } from "./map/socketDataGetterSaga";
import { watchTimePlayerSaga } from "./map/timePlayerSaga";
import {
  watchEditableLayersHistorySaga,
  watchInitializeMapEditModeSaga,
  watchReloadLastEditableLayersHistoryDataSaga,
  watchRevertEditableLayersHistoryByType
} from "./map/editableLayersHistorySaga";
import { watchMapTableApplyFilterSaga } from "./map/mapTableApplyFilterSaga";
import { watchMapSelectedObjectSaga } from "./map/mapSelectedObjectSaga";
import { watchSingleObjectEditableSaga } from "./map/standaloneObjectEditableSaga";
import {watchGetRelatedDataForLayerSaga} from "./map/getRelatedDataForLayerSaga";
import {watchInitializeAddLayerToMapSaga} from "./map/addLayerToMapSaga";

export default function* rootSaga() {
  yield all([
    watchAuthSaga(),
    watchPostObjectSaga(),
    watchDeleteObjectSaga(),

    watchInlineHistorySaga(),
    watchReloadLastInlineHistoryDataSaga(),
    watchRevertHistoryByType(),

    watchEditableLayersHistorySaga(),
    watchReloadLastEditableLayersHistoryDataSaga(),
    watchRevertEditableLayersHistoryByType(),
    watchInitializeMapEditModeSaga(),

    watchOpenSocketSaga(),
    watchTimePlayerSaga(),
    watchMapTableApplyFilterSaga(),
    watchMapSelectedObjectSaga(),

    watchSingleObjectEditableSaga(),
    watchGetRelatedDataForLayerSaga(),
    watchInitializeAddLayerToMapSaga()
  ]);
}
