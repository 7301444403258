import React, {useCallback, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components'
import {Button} from "../../../../../../../../../library/UI/components/Button/Button";
import {baseStyles} from "../../../../../../../../stylesRegistrator";
import _ from "underscore";


const {BaseMapPanelSection} = baseStyles.modules.map
// const StyledButtonsGroup = styled.div`
// background: #C1CBD8;
// width: 100%
// border-radius: 8px;
// font-size: 0px;
//     .jJBiiP {
//         font-size: 0px;
//
//     }
//
//
// `

const StyledButton = styled(Button)`
    &:hover {
    color: ${props => props.theme.colors.primaryDark};
    text-decoration: ${props => props.href && "underline"};
  }
  &.inactive {
    background: #C1CBD8;
    color: white;
    font-size: 0px;

}

   &.active {
    background: #F1F3F6;
    color: #008291;
    width: auto;

}
`


const StyledWrapper = styled(BaseMapPanelSection)`
    display: flex;
    align-items: center;
    background: #C1CBD8;
    border-radius: 8px;
    width: 240px;

`

const LayersContentSwitcher = ({handleChangePanelContext, buttonClickHandler, displayedButtons, activePanel}) => {

    const iconSize = useRef({width: '14px', height: '14px'})


    const Buttons = displayedButtons.map((element) => {
            const isActive = element.switcher === activePanel ? 'active' : 'inactive';
            return (
                <StyledButton
                // type={"primaryReverse"}
                width={'100%'}
                iconSize={iconSize.current}
                icon={element.icon}
                iconMargin={0.3}
                className={isActive}
                onClick={() => buttonClickHandler(element)}
                key={_.uniqueId()}
            >
                {element.name}
            </StyledButton>)
        }
    )

    return (
        <StyledWrapper>
            {Buttons}
        </StyledWrapper>
    );
};

LayersContentSwitcher.propTypes = {
  activePanel: PropTypes.string.isRequired,
  buttonClickHandler: PropTypes.func.isRequired,
  displayedButtons: PropTypes.array.isRequired,
  handleChangePanelContext: PropTypes.func.isRequired
}

export default LayersContentSwitcher;
