import styled from "styled-components";
import {Styled as styleMixins} from "../../styleMixins/styleMixins.styled";

const {OpacifyColorInCertainProperty} = styleMixins.color

const BasePanel = styled.div`
  background: ${(props) => props.theme.colors.defaultLighter};
  border-radius: ${(props) => props.theme.decoration.defaultBorderRadius};
  box-shadow: ${(props) => props.theme.decoration.defaultBoxShadow};
  max-width: 100%;
  z-index: 1;
  height: fit-content;
  &::-webkit-scrollbar {
    width: 0;
  }
`;

const BaseLayerPanelWrapperPrimary = styled(BasePanel)`
  width: 272px;
  height: 100%;
  overflow-y: scroll;
`;

const BaseLayerPanelWrapperSecondary = styled(BaseLayerPanelWrapperPrimary)`
  ${OpacifyColorInCertainProperty("defaultLighter", "background", 0.85)};
  width: 252px;
  max-width: 100%;
  backdrop-filter: blur(18px);
  z-index: 0;
`;

const BasePanelItem = styled.div`
  padding: ${(props) => props.theme.paddings.defaultPadding};
`;

const BasePanelTopPart = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const BasePanelPrimaryTitle = styled.h3`
  font-size: ${(props) => props.theme.fontSettings.default};
  color: ${(props) => props.theme.colors.defaultDark};
  margin: 0;
`;

const BasePanelSecondaryTitle = styled(BasePanelPrimaryTitle)`
  color: ${(props) => props.theme.colors.neutralDarker};
`;

const BasePanelHeader = styled(BasePanelItem)`
  box-shadow: ${(props) => props.theme.decoration.defaultBoxShadow};
`;

const BasePanelSection = styled.div`
  margin-top: 1rem;
`;

export const Styled = {
  BasePanel,
  BaseLayerPanelWrapperPrimary,
  BaseLayerPanelWrapperSecondary,
  BasePanelItem,
  BasePanelHeader,
  BasePanelTopPart,
  BasePanelPrimaryTitle,
  BasePanelSecondaryTitle,
  BasePanelSection,
};
