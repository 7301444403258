import React from "react";
import { Styled } from "./PanelDefaultCard.styled";

const { LocalMainWrapper, LocalContentWrap } = Styled.main;
const { LocalActionAreaWrap } = Styled.actionArea;
const { LocalDescriptionAreaWrap } = Styled.descriptionArea;
const { LocalPresentationAreaWrap } = Styled.presentationArea;

const ActionArea = ({ children, ...otherProps }) => {
  return <LocalActionAreaWrap {...otherProps}>{children}</LocalActionAreaWrap>;
};

const DescriptionArea = ({ children, ...otherProps }) => {
  return (
    <LocalDescriptionAreaWrap {...otherProps}>
      {children}
    </LocalDescriptionAreaWrap>
  );
};

const PresentationArea = ({ children, otherProps }) => {
  return (
    <LocalPresentationAreaWrap {...otherProps}>
      {children}
    </LocalPresentationAreaWrap>
  );
};

const Content = ({ children, ...otherProps }) => {
  return <LocalContentWrap {...otherProps}>{children}</LocalContentWrap>;
};

const Main = ({ children, ...otherProps }) => {
  return <LocalMainWrapper {...otherProps}>{children}</LocalMainWrapper>;
};

export const PanelDefaultCard = {
  Main,
  Content,
  PresentationArea,
  DescriptionArea,
  ActionArea,
};
