export const mapContextMenuContentTypesEnum = {
    dataConcerning: "Data concerning",
    roadSection: "Road section",
    //TODO maybe we have to rename 'checkpoint' property at the 'securityCheckpoint'?
    checkpoint: "Checkpoint",
    roadSigns: "Road signs",
    roadWorks: "roadWorks",
    blockades: "Blockades",
    openInNewDirectory: "Open in directory",
    deleteLand: "Delete land",
    dataAndLocation: "Data and location",
    deleteItem: "Delete",
    rulerLength: "rulerLength",
    rulerArea: "rulerArea",
    createSegment: 'create segment',
    createCargoTrackingPoint: 'createCargoTrackingPoint',
    createTrafficSegment: 'create traffic segment',
    createSecurityCheckpoint: 'create security checkpoint',
    createRoadSign: 'create road sign',
    createRoadBlock: 'create road block',
    createRoadWorks: 'create road work',
    createDischargeZone: 'create discharge zone',
    createReceptionArea: 'create reception area',
    createRoadOverpass: 'create road overpass',
    createDockSegments: 'create dock segment',
    controlRoomsSegment: 'create control rooms segment',
};
