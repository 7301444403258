import {LayerCardPrototype} from "../prototype/LayerCardPrototype";

export class TrafficSegmentsCard extends LayerCardPrototype {

    constructor() {
        super({
                i18path: 'trafficSegment',
                linkToPreview: 'Checkpointy.svg'
            }
        );
    }
}
