import {RelatedDataPrototype} from "../prototype/RelatedDataPrototype";
import {mapObjectsEnum} from "../../../../../../enums/mapObjectsEnum";
import {dataAccessMethodsEnum} from "../../../../../../enums/dataAccessMethodsEnum";

export class ControlRoom extends RelatedDataPrototype {

    constructor() {
        super({
            name: 'control_room',
            filterLabel: 'Диспетчерская',
            urlData: '/layer_roads/control_rooms-segments/',
            selectedObjectName:  mapObjectsEnum.controlRoomsSegment,
            // dataAccessMethod: dataAccessMethodsEnum.custom // нужно было для проброса даты в запрос
        });
    }
}
