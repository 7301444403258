import React from "react";
import { Styled } from "./CustomTooltipItem.styled";

const CustomTooltipItem = ({ children, fontColor, onClick, p }) => {
  const onClickHandler = event => {
    event.stopPropagation();
    onClick();
  };

  return (
    <Styled.LocalWrapper p={p} fontColor={fontColor} onClick={onClickHandler}>
      {children}
    </Styled.LocalWrapper>
  );
};

export default CustomTooltipItem;
