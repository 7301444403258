import React from "react";
import { Menu } from "antd";

import { useTranslation } from "react-i18next";
import { Styled } from "./ReportSider.styled";
import { Button } from "../../../UI/components/Button/Button";

const {
  SiderWrapper,
  SiderInner,
  SiderContent,
  SiderFooter,
  SiderTitle,
} = Styled;

const createMenuItems = (
  schema,
  lastInlineFieldName,
  isItemsDisabled = false
) => {
  let result = [];
  result.push(
    <Menu.Item
      className={`${lastInlineFieldName === null ? "active" : ""}`}
      key={"main"}
    >
      Основные
    </Menu.Item>
  );
  for (let inline of schema.inlines) {
    const field = Object.keys(inline)[0];
    result.push(
      <Menu.Item
        className={`${lastInlineFieldName === field ? "active" : ""}`}
        key={field}
        disabled={isItemsDisabled}
      >
        {inline[field].label}
      </Menu.Item>
    );
  }
  return result;
};

const ObjectSider = React.forwardRef((props, ref) => {
  const {
    schema,
    initClose,
    goToInline,
    initSubmitForm,
    lastInlineFieldName,
    directoryHistoryLastItemData,
  } = props;
  const { t, i18n } = useTranslation("common");
  let isSiderItemsDisabled = !directoryHistoryLastItemData?.data?.id;
  if (directoryHistoryLastItemData.type === "inline")
    isSiderItemsDisabled = false;
  const MenuItems = createMenuItems(
    schema,
    lastInlineFieldName,
    isSiderItemsDisabled
  );
  const onSelectMenuItem = (event) => {
    goToInline(event);
  };

  return (
    <SiderWrapper width={"16%"}>
      <SiderInner>
        <SiderContent>
          <SiderTitle>Данные</SiderTitle>
          <Menu onClick={onSelectMenuItem}>{MenuItems}</Menu>
        </SiderContent>
        <SiderFooter>
          <Button
            onClick={() => initSubmitForm(ref)}
            type={"primary"}
            width={"100%"}
          >
            {t("app.detailedObject.saveChanges")}
          </Button>
          <Button
            onClick={() => {
              initClose(ref);
            }}
            type={"danger"}
            width={"100%"}
          >
            {t("app.detailedObject.quit")}
          </Button>
        </SiderFooter>
      </SiderInner>
    </SiderWrapper>
  );
});

export default ObjectSider;
