import { dataAccessMethodsEnum } from "../../../../../../enums/dataAccessMethodsEnum";
import { baseWSPath } from "../../../layersRegistrator";

export class MainDataSocketPrototype {
  baseWSPathTemplate = baseWSPath;

  dataAccessMethod = dataAccessMethodsEnum.websocket;

  dataKeyForIds;

  urlData = null;

  constructor({ urlData, dataKeyForIds = "id" }) {
    this.urlData = this.baseWSPathTemplate + urlData;
    this.dataKeyForIds = dataKeyForIds;
  }

  getDataAccessMethod() {
    return this.dataAccessMethod;
  }

  getDataKeyForIds() {
    return this.dataKeyForIds;
  }

  getUrlData() {
    return this.urlData;
  }
}
