import { LayerPrototype } from "../description/prototype/LayerPrototype";
import { layerNamesEnum } from "../../../../enums/layerNamesEnum";
import { layerSettings } from "../description/settings";
import { relatedLayerData } from "../description/relatedData";
import { layerCards } from "../description/cards";
import { layerSchemas } from "../description/layerSchemaPresets";
import { layerDataAccessors } from "../description/dataAccessors";

export class GeozonesLayer extends LayerPrototype {
  constructor() {
    super({
      name: layerNamesEnum.geozones,
      dataAccessor: new layerDataAccessors.coreLayerDataAccessors.GeozonesDataAccessor(),
      relatedData: [
        new relatedLayerData.coreRelatedDataForLayers.Geozones(),
        new relatedLayerData.coreRelatedDataForLayers.GeozonesTypes(),
      ],
      card: new layerCards.coreLayerCards.GeozonesLayerCard(),
      customSettings: [
        new layerSettings.OpacitySetting(),
        new layerSettings.IconSizeScale(),
      ],
      layerSchema: [new layerSchemas.coreLayersSchema.GeozonesLayerSchema()],
    });
  }
}
