import {createSelector} from "reselect";
import _ from 'underscore'
import securedAxiosInstance from "../../services/net/axiosSecured";
import {geometryApi} from "../../api/patchObjectGeometry";
import {defineDrawKeyOnModel} from "../../services/map/defineDrawMapOnModel";
import wkt from "wkt";
import {delay} from "redux-saga/effects";
import {RELOAD_EDITABLE_LAYERS_HISTORY_SAGA} from "../../sagas/map/editableLayersHistorySaga";
import {REPLACE_CERTAIN_FIELD_IN_RELATED_DATA} from "./mapboxData";

export const HANDLE_EDIT_FIELDS_OF_INSTANCE = 'HANDLE_EDIT_FIELDS_OF_INSTANCE'
export const HANDLE_CREATE_INSTANCE_IN_EDITABLE_LAYER = 'HANDLE_CREATE_INSTANCE_IN_EDITABLE_LAYER'
export const HANDLE_CLEAN_UP_INSTANCE_IN_EDITABLE_LAYER = 'HANDLE_CLEAN_UP_INSTANCE_IN_EDITABLE_LAYER'

export const SET_DRAW_ENABLED_STATUS = 'SET_DRAW_ENABLED_STATUS'
export const PUSH_TO_EDITABLE_LAYERS_HISTORY =
    "PUSH_TO_EDITABLE_LAYERS_HISTORY";
export const REMOVE_LAST_ITEM_IN_EDITABLE_LAYERS_HISTORY =
    "REMOVE_LAST_ITEM_IN_EDITABLE_LAYERS_HISTORY";
export const CLEAN_UP_EDITABLE_LAYERS_HISTORY =
    "CLEAN_UP_EDITABLE_LAYERS_HISTORY";
export const SELECT_INSTANCE_IN_LAYERS_HISTORY =
    "SELECT_INSTANCE_IN_LAYERS_HISTORY";
export const RELOAD_DATA_IN_LAYERS_HISTORY = "RELOAD_DATA_IN_LAYERS_HISTORY";
export const ENABLE_MAP_EDIT_MODE = "ENABLE_MAP_EDIT_MODE";
export const DISABLE_MAP_EDIT_MODE = "DISABLE_MAP_EDIT_MODE";
export const TOGGLE_MAP_EDIT_COLLAPSE_STATUS =
    "TOGGLE_MAP_EDIT_COLLAPSE_STATUS";
export const TOGGLE_MAP_EDIT_FORM_PANEL_ACTIVE_STATUS =
    "TOGGLE_MAP_EDIT_FORM_PANEL_ACTIVE_STATUS";
export const SET_ACTION_TOOLTIP = "SET_ACTION_TOOLTIP";
export const REPLACE_EDIT_GEOMETRY_DATA = "REPLACE_EDIT_GEOMETRY_DATA";
export const UPDATE_MAP_EDIT_LAYER_HISTORY_LAST_ITEM_DATA = 'UPDATE_MAP_EDIT_LAYER_HISTORY_LAST_ITEM_DATA'

const initialState = {
    app: {
        enabled: false,
        addMode: null,
        isCollapsed: false,
        actionsTooltip: null, //in init {y: number, x: number, visible: bool}
        isFormPanelActive: false
    },
    history: []
};

//selectors area
export const selectEditableLayersHistory = state => {
    return state.mapboxEditableLayersHistory.history;
};

export const getLastItemInEditableLayersHistory = state => {
    return  _.last(state.mapboxEditableLayersHistory.history)
};

export const getEditableLayerHistoryArraySize = createSelector(
    [selectEditableLayersHistory],
    history => history.length
);

export const getEditableLayerDrawData = createSelector(
    [getLastItemInEditableLayersHistory],
    lastHistory => lastHistory?.drawData
);
//

export const selectRowForLayerHistory = data => ({
    type: SELECT_INSTANCE_IN_LAYERS_HISTORY,
    payload: {
        selected: data
    }
});

export const setEditableLayerTooltip = value => ({
    type: SET_ACTION_TOOLTIP,
    payload: {
        value
    }
});

export const replaceGeometryDataAndSave = (data,urlData,id,relatedField,historyData) => dispatch => {
    geometryApi.patchObjectGeometry(urlData,id,relatedField,data).then(
        ()=>{
            dispatch( {
                type: REPLACE_EDIT_GEOMETRY_DATA,
                payload: {
                    data
                }
            });
        }
    ).then(()=>{
        dispatch({
            type:RELOAD_EDITABLE_LAYERS_HISTORY_SAGA,
            payload:{
                lastHistoryRecord:historyData,
            }
        });
    })
};

export const replaceGeometryData = (data) => ({
    type: REPLACE_EDIT_GEOMETRY_DATA,
    payload: {
        data
    }
})

export const setDrawEnabled = (value) => {
    return {
        type:SET_DRAW_ENABLED_STATUS,
        payload:{
            value
        }
    }
}

const historyRecordStructureExample = [
    {
        name: "roads",
        parentId: null,
        parentKey: null,
        drawData: "data to editable layer",
        urlData: "someUrl/roads/",
        layerObjectModel: "urlData.options()",
        layerObjectData: "urlData.get()",
        selectedInstance: "roads/1/ instance",
        nestedObjectsDescription: [],
        actionsTooltip: null
    },
    {
        name: "road",
        parentId: 1,
        parentKey: "id",
        drawData: "data to editable layer",
        urlData: "someUrl/roads/1/",
        layerObjectModel: "urlData.options()",
        layerObjectData: "urlData.get()",
        selectedInstance: "roads/1/segments/2/ instance"
    },
    {
        name: "segment",
        parentId: 2,
        parentKey: "id",
        drawData: "data to editable layer",
        urlData: "someUrl/roads/1/segments/2",
        layerObjectModel: "urlData.options()",
        layerObjectData: "urlData.get()",
        selectedInstance: "roads/1/segments/2/objects/3/ instance"
    }
];

const mapboxEditableLayersHistory = (state = initialState, action) => {
    switch (action.type) {
        case PUSH_TO_EDITABLE_LAYERS_HISTORY: {
            const copyOfHistory = [...state.history];
            const newRecord = action.payload;
            copyOfHistory.push(newRecord);
            return {
                ...state,
                app: {
                    ...state.app,
                    enabled: true,
                    actionsTooltip: null
                },
                history: [...copyOfHistory]
            };
        }
        case REMOVE_LAST_ITEM_IN_EDITABLE_LAYERS_HISTORY: {
            const copyOfHistory = [...state.history];
            copyOfHistory.pop();
            return {
                ...state,
                app: {
                    ...state.app,
                    actionsTooltip: null
                },
                history: [...copyOfHistory]
            };
        }
        case UPDATE_MAP_EDIT_LAYER_HISTORY_LAST_ITEM_DATA: {
            const {newData} = action.payload
            const copyOfHistory = [...state.history];
            copyOfHistory[copyOfHistory.length - 1] = newData
            return {
                ...state, history: [...copyOfHistory]
            }
        }
        case CLEAN_UP_EDITABLE_LAYERS_HISTORY: {
            return {
                ...initialState
            };
        }
        case SET_DRAW_ENABLED_STATUS: {
            const {value} = action.payload;
            const copyOfHistory = [...state.history];
            const lastHistory = copyOfHistory[copyOfHistory.length - 1];
            const field = defineDrawKeyOnModel(lastHistory.layerObjectModel)

            copyOfHistory[copyOfHistory.length - 1].enableDraw = value;
            if (lastHistory.selectedInstance[field]){
                const parsedData = wkt.parse(lastHistory.selectedInstance[field])
                copyOfHistory[copyOfHistory.length - 1].hasGeometry = !!parsedData
                copyOfHistory[copyOfHistory.length - 1].drawData = {
                    type: 'FeatureCollection',
                    features: [{
                        geometry: {...parsedData},
                        properties: {},
                        type: "Feature"
                    }
                    ],
                }
            } else {
                copyOfHistory[copyOfHistory.length - 1].hasGeometry = false
                copyOfHistory[copyOfHistory.length - 1].drawData = {
                    type: 'FeatureCollection',
                    features: [
                    ],
                }
            }

            return {
                ...state,
                history: [...copyOfHistory],
                app: {
                    ...state.app,
                    actionsTooltip: null
                }
            };
        }
        case SET_ACTION_TOOLTIP: {
            const {value} = action.payload;
            return {
                ...state,
                app: {
                    ...state.app,
                    actionsTooltip: value
                }
            };
        }
        case TOGGLE_MAP_EDIT_COLLAPSE_STATUS: {
            const {newStatus} = action.payload;
            const copyOfAppObj = {...state.app};
            if (newStatus) {
                copyOfAppObj.isCollapsed = newStatus;
                return {
                    ...state,
                    app: copyOfAppObj
                };
            }
            copyOfAppObj.isCollapsed = !copyOfAppObj.isCollapsed;
            return {
                ...state,
                app: copyOfAppObj
            };
        }
        case REPLACE_EDIT_GEOMETRY_DATA: {
            const {data} = action.payload;
            const copyOfHistory = [...state.history];
            copyOfHistory[copyOfHistory.length - 1].drawData = data;
            return {
                ...state,
                history: [...copyOfHistory]
            };
        }
        case TOGGLE_MAP_EDIT_FORM_PANEL_ACTIVE_STATUS: {
            const {newStatus} = action.payload;
            const copyOfAppObj = {...state.app};
            if (newStatus) {
                copyOfAppObj.isFormPanelActive = newStatus;
                return {
                    ...state,
                    app: copyOfAppObj
                };
            }
            copyOfAppObj.isFormPanelActive = !copyOfAppObj.isFormPanelActive;
            return {
                ...state,
                app: copyOfAppObj
            };
        }
        case RELOAD_DATA_IN_LAYERS_HISTORY: {
            const {value} = action.payload;
            const copyOfHistory = state.history;
            copyOfHistory[copyOfHistory.length - 1].layerObjectData = value;
            return {
                ...state,
                history: [...copyOfHistory]
            };
        }
        case SELECT_INSTANCE_IN_LAYERS_HISTORY: {
            const {selected} = action.payload;
            const copyOfHistory = state.history;
            copyOfHistory[copyOfHistory.length - 1].selectedInstance = selected;
            return {
                ...state,
                history: [...copyOfHistory]
            };
        }
        case ENABLE_MAP_EDIT_MODE: {
            return {
                ...state,
                app: {
                    ...state.app,
                    enabled: true
                }
            };
        }
        case DISABLE_MAP_EDIT_MODE: {
            return {
                ...state,
                app: {
                    ...state.app,
                    enabled: false
                }
            };
        }
        default:
            return state;
    }
};

export default mapboxEditableLayersHistory;
