import React from 'react';
import CustomSlider from "../../../../../../../../../../../library/UI/components/CustomSlider/CustomSlider";
import {Checkbox} from "../../../../../../../../../../../library/UI/components/Checkbox/Checkbox";
import {theme} from "../../../../../../../../../../../library/themes/rootTheme";
import {Styled} from "../../LayerSettings.styled";
import _ from "underscore";

const {SettingsControlsItem} = Styled;

const LayerSettingsItemFactory = (settingObj, layerName, changeSetting) => {
    const {type, keyForDeck, currentValue, label} = settingObj;
    switch (type) {
        case Number: {
            const {minimum, maximum, step, isPercent} = settingObj;
            return (
                <SettingsControlsItem key={_.uniqueId()}>
                    <CustomSlider
                        key={keyForDeck}
                        min={minimum}
                        max={maximum}
                        step={step}
                        value={currentValue}
                        onChange={val => {
                            changeSetting(layerName, keyForDeck, val)
                        }}
                        width={"100%"}
                        label={label}
                        showInfoPanel={true}
                    />
                </SettingsControlsItem>
            );
        }
        case Boolean: {
            return (
                <SettingsControlsItem>
                    <Checkbox
                        checkboxColor={theme.colors.primary}
                        fontColor={theme.colors.paragraph}
                        checked={currentValue}
                        onChange={() => changeSetting(layerName, keyForDeck, !currentValue)}
                    >
                        {label}
                    </Checkbox>
                </SettingsControlsItem>
            );
        }
        default: {
            return;
        }
    }
};


const LayerChangeControlsTab = ({selectedLayer,changeLayerSetting}) => {
    const settings = selectedLayer?.customSettings.map(item =>
        LayerSettingsItemFactory(item, selectedLayer.name, changeLayerSetting)
    );
    return (
        <>
            {settings}
        </>
    );
};

LayerChangeControlsTab.propTypes = {

};

export default LayerChangeControlsTab;
