import securedAxiosInstance from "../services/net/axiosSecured";
import {removeNullFields} from "../services/data/removeNullFields";

export const postToURLSingle = {
    async sendFile(url,file) {
        const response = await securedAxiosInstance.post(url,
            file,
            {
                headers: {
                    'Accept-Language': localStorage.getItem('locale'),
                    'Content-Type': 'multipart/form-data;',
                }
            })

        return response?.data ? response?.data : response
    },
    async postOrPutData(url, data, initial = null) {
        try {
            if ((data && data.id) || (initial && initial.id)) {
                const id = data.id || initial.id;
                const path = url + id + '/'
                const response = await securedAxiosInstance.put(path, {...removeNullFields(data)})
                return response.data
            } else {
                const response = await securedAxiosInstance.post(url, {...removeNullFields(data)}, {})
                return response.data
            }
        } catch (e) {
            console.error(e)
        }
    },
    async patchData(url, data,) {
        try {
            const response = await securedAxiosInstance.patch(url, {...removeNullFields(data)}, {})
            return response.data
        } catch (e) {
            console.error(e)
        }

    },
    async deleteData(url, id) {
        try {
            if (id) {
                const response = await securedAxiosInstance.delete(url + id + '/', {})
                return response.data
            } else {
                return null;
            }
        } catch (e) {
            console.error(e)
        }
    },
}
