export default function OGRNValivator(ogrn) {
    let result = null;
    let localOgrn = ogrn.toString().replace(/[^0-9.]/g, "")
    if (/[^0-9]/.test(localOgrn)) {
        result = 'OGRN must contain only digits';
    } else if (localOgrn.length !== 13 && localOgrn.length !== 15) {
        result = 'OGRN must contain 13 or 15 digits';
    } else {
        let n13 = parseInt((parseInt(localOgrn.slice(0, -1)) % 11).toString().slice(-1));
        if (n13 === parseInt(localOgrn[12])) {
            result = true;
        } else {
            result = 'failed check control sum';
        }
    }
    return result;
}