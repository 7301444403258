import React, { useEffect, useState } from "react";
import { Styled } from "./DataGRZ.styled";
import RepresentationCheckedItem from "../../../checkedWidget/RepresentationCheckedItem";
import SelectableItem from "../../../../../../../../../library/UI/components/SelectableItem/SelectableItem";
import useGlobal from "../../../../store";

const { LocalSelectWrapper, LocalStyleValue } = Styled;

const DataGRZContainer = ({ data }) => {
  const [globalState, globalActions] = useGlobal();
  const [checkedItems, setCheckedItems] = useState([]);

  useEffect(() => {
    if (data?.cars.length) {
      setCheckedItems([...data.cars]);
    }
  }, [data]);

  const searchCheckedItem = () => {
    const isCheckedAllItem = checkedItems.filter(
      (elem) => elem.isChecked === true
    );
    const selectValues = isCheckedAllItem.map((el) => el.id);
    globalActions.actions.grzActions(selectValues);
  };

  const handleAllChecked = (event) => {
    let selects = checkedItems;
    selects.map((selects) => (selects.isChecked = event.target.checked));
    setCheckedItems([...selects]);
    searchCheckedItem();
  };

  const handleCheckElement = (event) => {
    let selects = checkedItems;
    selects.forEach((selects) => {
      if (selects.grz === event.target.value)
        selects.isChecked = event.target.checked;
    });
    setCheckedItems([...selects]);
    searchCheckedItem();
  };

  const handleRemoveItems = () => {
    setCheckedItems([]);
  };

  const groupItems = checkedItems.map((item, index) => {
    return (
      <LocalSelectWrapper isChecked={item.isChecked} key={index}>
        <SelectableItem
          isStatusBarLine={false}
          isChecked={item.isChecked}
          onSelectChange={handleCheckElement}
          isSelected={item.isChecked}
          value={item.grz}
          onMoved
        >
          <LocalStyleValue>{item.grz}</LocalStyleValue>
        </SelectableItem>
      </LocalSelectWrapper>
    );
  });

  return (
    <RepresentationCheckedItem
      isDeletedItems
      // search={(val) => search(val)}
      handleRemoveItems={handleRemoveItems}
      groupItems={groupItems}
      handleAllChecked={handleAllChecked}
    />
  );
};

export default DataGRZContainer;
