import React from "react";
import LayersMenuHeader from "./LayersMenuHeader";
import { connect } from "react-redux";
import {clearMapSelectedObject} from "../../../../../../../../../reducers/map/actions/mapSelectedObjectActions";
import {toggleMainPanelCollapse} from "../../../../../../../../../reducers/map/actions/mapPanelsActions";

const LayersMenuHeaderContainer = props => {
  return <LayersMenuHeader {...props} />;
};

const mapStateToProps = state => {
  return {};
};

const mapDispatchToProps = dispatch => {
  return {
      toggleMainPanelCollapse: () => dispatch(toggleMainPanelCollapse()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LayersMenuHeaderContainer);
