import React from "react";
import LayersMenu from "./LayersMenu";
import { connect } from "react-redux";
import { toggleMainPanelCollapse } from "../../../../../../reducers/map/actions/mapPanelsActions";

const LayersMenuContainer = ({
  enabledLayers,
  isGalleryVisible,
  showGallery,
  showLayersSettings,
  dataAccessor,
  mainPanelCollapseStatus,
  toggleMainPanelCollapse,
  ...others
}) => {
  return (
    <LayersMenu
      {...others}
      enabledLayers={enabledLayers}
      isGalleryVisible={isGalleryVisible}
      showGallery={showGallery}
      showLayersSettings={showLayersSettings}
      dataAccessor={dataAccessor}
      mainPanelCollapseStatus={mainPanelCollapseStatus}
      toggleMainPanelCollapse={toggleMainPanelCollapse}
    />
  );
};

const mapStateToProps = state => {
  return {
    enabledLayers: state.mapboxLayers,
    isGalleryVisible: state.mapPanels.layersGalleryVisible,
    showGallery: state.mapPanels.layersGalleryVisible,
    showLayersSettings: state.mapPanels.layerSettingVisible,
    dataAccessor: state.mapPanels.dataAccessor,
    mainPanelCollapseStatus: state.mapPanels.mainPanelCollapsed
  };
};

const mapDispatchToProps = dispatch => {
  return {
    toggleMainPanelCollapse: () => dispatch(toggleMainPanelCollapse())
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LayersMenuContainer);
