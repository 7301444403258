import { LayerPrototype } from "../description/prototype/LayerPrototype";
import { layerNamesEnum } from "../../../../enums/layerNamesEnum";
import { nipigazLayerCards } from "../description/cards/nipigaz";
import { layerSettings } from "../description/settings";
import RoadSchemeItemSelection from "../../../../library/layers/SelectedObjectsRepresentations/RoadSchemeItemSelection/RoadSchemeItemSelection";
import { layerDataAccessors } from "../description/dataAccessors";
import { relatedLayerData } from "../description/relatedData";
import { layerSchemas } from "../description/layerSchemaPresets";
import { layerTemplates } from "../description/templates";

export class RoadSchemaLayer extends LayerPrototype {
  constructor() {
    super({
      name: layerNamesEnum.roadsSchema,
      editable: true,
      dataAccessor: new layerDataAccessors.nipigazLayerDataAccessors.RoadsDataAccessor(),
      relatedData: [
        new relatedLayerData.nipigazRelatedDataForLayers.Roads(),
        new relatedLayerData.nipigazRelatedDataForLayers.RoadBlocks(),
        new relatedLayerData.nipigazRelatedDataForLayers.RoadWorks(),
        new relatedLayerData.nipigazRelatedDataForLayers.RoadSecurityCheckpoints(),
        new relatedLayerData.nipigazRelatedDataForLayers.ControlRoom(),
        new relatedLayerData.nipigazRelatedDataForLayers.RoadOverpass(),
        new relatedLayerData.nipigazRelatedDataForLayers.Segments(),
        new relatedLayerData.nipigazRelatedDataForLayers.RoadSigns(),
        new relatedLayerData.nipigazRelatedDataForLayers.DockSegment(),
        new relatedLayerData.nipigazRelatedDataForLayers.RoadsCategories(),
        new relatedLayerData.nipigazRelatedDataForLayers.Signs(),
      ],
      card: new nipigazLayerCards.RoadSchemaCard(),
      customSettings: [
        new layerSettings.OpacitySetting(),
        new layerSettings.IconSizeScale(),
      ],
      layerSchema: [
        new layerSchemas.nipigazLayersSchema.RoadSchemaLayerSchema(),
      ],
      templates: new layerTemplates.RoadsSchemaLayerTemplates(),
      selectedObjectPrototype: RoadSchemeItemSelection,
    });
  }
}
