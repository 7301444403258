import securedAxiosInstance from "../services/net/axiosSecured";
import {applyParamsFilter} from "../services/net/applyParamsFilter";

export const getFromURL = {
    async getAll(url) {
        const responseBasic = await securedAxiosInstance.get(url,{
            headers: {
                'Accept-Language': localStorage.getItem('locale')

            }
        })
        const response = await securedAxiosInstance.get(url,{
            headers: {
                'Accept-Language': localStorage.getItem('locale')

            },
            params:{
                page_size: responseBasic.data.count
            }
        })
        return response?.data ? response?.data : response
    },
    async getDataFromURL(url, count='') {
        let prepUrl
        if (count !== "") {prepUrl = url+'?representation=true'+`&page_size=${count}`} else prepUrl = url
        const response = await securedAxiosInstance.get(prepUrl,{
          headers: {
                    'Accept-Language': localStorage.getItem('locale')
                }
            })
        return response?.data ? response?.data : response


    },
    async getWithParams(url,params = {}) {
        const response = await securedAxiosInstance.get(url,{
            headers: {
                'Accept-Language': localStorage.getItem('locale')
            },
            params: params
        })
        return response?.data ? response?.data : response
    },
    async getRepresentationData(url,count='') {

        if (count!=='') {
            const response = await securedAxiosInstance.get(url+'?representation=true'+`&page_size=${count}`,{
                headers: {
                    'Accept-Language': localStorage.getItem('locale')

                }
            })
            return response?.data ? response?.data : response
        }
        else {
            const response = await securedAxiosInstance.get(url+'?representation=true',{
                headers: {
                    'Accept-Language': localStorage.getItem('locale')

                }
            })
            return response?.data ? response?.data : response
        }

    },
    async getById(url,id) {
        const response = await securedAxiosInstance.get(url+`${id}/`,{
            headers: {
                'Accept-Language': localStorage.getItem('locale')

            }
        })
        return response?.data ? response?.data : response
    },
    async getDataFromURLWithFilters(url,filters) {
        const query = applyParamsFilter({filters});
        if (query){
            const response = await securedAxiosInstance.get(url+query,{
                headers: {
                    'Accept-Language': localStorage.getItem('locale')

                },
            })
            return response.data.results
        } else return await null

    },
    async getModelFromURL(url) {
        const response = await securedAxiosInstance.options(url,{
            headers: {
                'Accept-Language': localStorage.getItem('locale')
            }
        })
        return response?.data ? response?.data : response
    },
    async getDataByParent(url,parentKey,parentId) {
        let searchParam = `?${parentKey}__id=${parentId}`;
        const response = await securedAxiosInstance.get(url+searchParam,{
            headers: {
                'Accept-Language': localStorage.getItem('locale')
            }
        })
        return response?.data ? response?.data : response
    },
};
