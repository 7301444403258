import {TrackingDataAccessor} from "./TrackingDataAccessor";
import {MonitoringDataAccessor} from "./MonitoringDataAccessor";
import {GeozonesDataAccessor} from "./GeozonesDataAccessor";


export const coreLayerDataAccessors = {
    TrackingDataAccessor,
    MonitoringDataAccessor,
    GeozonesDataAccessor,

}

