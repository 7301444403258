import {LayerPrototype} from "../description/prototype/LayerPrototype";
import {layerNamesEnum} from "../../../../enums/layerNamesEnum";
import {layerSettings} from "../description/settings";
import {relatedLayerData} from "../description/relatedData";
import {layerCards} from "../description/cards";
import {layerSchemas} from "../description/layerSchemaPresets";
import {layerDataAccessors} from "../description/dataAccessors";

export class RailwayNetworkLayer extends LayerPrototype{

    constructor() {
        super({
            name:  layerNamesEnum.railwayNetwork,
            dataAccessor : new layerDataAccessors.nipigazLayerDataAccessors.RailwayNetworkDataAccessor,
            relatedData: [
                new relatedLayerData.nipigazRelatedDataForLayers.Railways(),
                new relatedLayerData.nipigazRelatedDataForLayers.Trains(),
                new relatedLayerData.nipigazRelatedDataForLayers.Waggons(),
                new relatedLayerData.nipigazRelatedDataForLayers.DischargeZones(),
                new relatedLayerData.nipigazRelatedDataForLayers.ReceptionAreas()
            ],
            card: new layerCards.nipigazLayerCards.RailwayNetworkCard(),
            customSettings: [new layerSettings.OpacitySetting()],
            layerSchema : [
                new layerSchemas.nipigazLayersSchema.RailwayNetworkLayerSchema(),
            ],
        });
    }
}
